import React, { useEffect, useState } from "react";
import {
  IconButton,
  OutlinedInput,
  InputLabel,
  FormControl,
  InputAdornment,
} from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import RecordNotFound from "src/components/RecordNotFound";
import DocumentCard from "src/components/ProgrammesList/ProgrammeDocuments/DocumentCard";
import {
  delete_programme_document_api,
  programme_document_list_api,
} from "src/DAL/Programme/ProgrammeDocuments/ProgrammeDocuments";
import { programme_detail_api } from "src/DAL/Programme/Programme";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import PageLoader from "src/components/GeneralComponents/PageLoader";

export const ProgrammeDocuments = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [documentList, setDocumentList] = useState([]);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [deleteDoc, setDeleteDoc] = React.useState("");
  const [programName, setProgramName] = useState("");
  const { state } = useLocation();
  const [query, setQuery] = useState("");
  const getFilteredData = (query) => {
    let dataToFilter = [...documentList];
    if (query) {
      let resultFilter = dataToFilter.filter((post) =>
        post.title.toLowerCase().includes(query.toLowerCase())
      );
      return resultFilter;
    } else {
      return dataToFilter;
    }
  };
  console.log(state, "statestatestatestate");
  const handleChangeSearch = (e) => {
    setQuery(e.target.value);
  };

  const handleEdit = (value) => {
    navigate(
      `/programmes/programm-document/${params.programme_slug}/edit-document/${value.document_slug}`,
      {
        state: value,
      }
    );
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_programme_document_api(deleteDoc.document_slug);
    if (result.code === 200) {
      setDocumentList((prev) => {
        return prev.filter((data) => data._id !== deleteDoc._id);
      });
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getProgrammeDetail = async () => {
    setIsLoading(true);
    const result = await programme_detail_api(params.programme_slug);
    if (result.code === 200) {
      setProgramName(result?.program?.title);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const getDocument = async () => {
    let result = await programme_document_list_api(params.programme_slug);
    if (result.code == 200) {
      setIsLoading(false);
      setDocumentList(result.program_document);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleNavigate = () => {
    navigate(
      `/programmes/programm-document/${params.programme_slug}/add-document`
    );
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  let breadCrumbMenu = [
    {
      title: "Programmes",
      navigation: `/programmes`,
      active: false,
    },

    {
      title: state ? state?.title : programName ? programName : "",
      navigation: `/programmes/programmes-detail/${params.programme_slug}`,
      active: false,
    },
    {
      title: "Programme Documents",
      navigation: ``,
      active: true,
    },
  ];
  useEffect(() => {
    getDocument();
    getProgrammeDetail();
  }, []);
  useEffect(() => {
    if (params.programme_slug) {
      if (state) {
      } else {
        getProgrammeDetail();
      }
    }
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this document?"}
        handleAgree={handleDelete}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 mb-4">
            <span className="">
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
          </div>
          <div className="col-lg-5 col-md-8 ">
            <h2>Programme Documents</h2>
          </div>

          <div
            className="col-lg-7 col-md-4 text-end"
            style={{ display: "flex", justifyContent: "end" }}
          >
            <div>
              <FormControl variant="outlined" style={{ marginTop: "2px" }}>
                <InputLabel htmlFor="outlined-adornment-password">
                  Search
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type="text"
                  variant="small"
                  onChange={handleChangeSearch}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Search"
                />
              </FormControl>
            </div>
            <button
              onClick={handleNavigate}
              className="ms-2 mt-1 small-contained-button"
            >
              Add Document
            </button>
          </div>

          <div className="col-lg-12 col-sm-12 text-end"></div>
          {getFilteredData(query).length < 1 ? (
            <RecordNotFound title="Document" />
          ) : (
            getFilteredData(query)?.map((value, i) => {
              return (
                <DocumentCard
                  key={value._id}
                  value={value}
                  MENU_OPTIONS={MENU_OPTIONS}
                />
              );
            })
          )}
        </div>
      </div>
    </>
  );
};
