import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  CircularProgress,
  Tooltip,
  OutlinedInput,
  InputAdornment,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
import { useSnackbar } from "notistack";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  delete_detail_short_link_api,
  short_links_list_api,
} from "src/DAL/ShortLinks/ShortLinks";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PublicIcon from "@mui/icons-material/Public";
import moment from "moment";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  height: 44,
  color: "#fff",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    boxShadow: theme.customShadows.z8,
    border: "1px solid #e4c073",
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const ShortLinksList = () => {
  const observer = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [linkList, setLinkList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [loadMorePath, setLoadMorePath] = useState("");
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const load_more_path = "short_links/list/short_links?page=0&limit=15";

  const getShortLInkListing = async (path, search, loading) => {
    if (!loading) {
      setIsLoading(true);
      path = load_more_path;
    }

    const result = await short_links_list_api(path, search);
    if (result.code == 200) {
      setLoadMorePath(result.load_more);
      setTotalPages(result.total_pages);
      if (loading) {
        let new_array = linkList.concat(result.short_links);
        setLinkList(new_array);
        setPageNumber((prev) => prev + 1);
      } else {
        setLinkList(result.short_links);
        setPageNumber(1);
      }
      setIsLoading(false);
      setIsLoadingMore(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsLoadingMore(false);
    }
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_detail_short_link_api(deleteDoc?._id);
    if (result.code === 200) {
      setLinkList((prev) => prev.filter((item) => item._id !== deleteDoc._id));
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleEdit = (value) => {
    navigate(`/short-links/edit-short-link/${value?._id}`, {
      state: value,
    });
  };
  const handleNavigateEngagements = (value) => {
    navigate(`/short-links/engagements/${value?._id}`, {
      state: value,
    });
  };

  const handleOpenLong = (value) => {
    window.open(value.long_url);
  };

  const handleCopyLong = async (e) => {
    await navigator.clipboard.writeText(e.long_url);
    enqueueSnackbar("Destination Copied to clipboard", { variant: "success" });
  };

  const handleNavigate = () => {
    navigate(`/short-links/add-short-link`);
  };

  const handleCopyMessage = async (message) => {
    enqueueSnackbar(message, { variant: "success" });
  };

  const handleSearchText = (event) => {
    setSearchText(event.target.value);
    localStorage.setItem("short_link_search_text", event.target.value);
  };

  const handleOpenLink = (link) => {
    window.open(link);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "Preview Destination",
      icon: "akar-icons:edit",
      handleClick: handleOpenLong,
    },
    {
      label: "Copy Destination",
      icon: "akar-icons:edit",
      handleClick: handleCopyLong,
    },
  ];

  const loadMoreData = () => {
    setIsLoadingMore(true);
    getShortLInkListing(loadMorePath, searchText, true);
  };

  useEffect(() => {
    if (!isLoading) {
      const timeoutId = setTimeout(() => {
        getShortLInkListing(loadMorePath, searchText);
      }, 800);
      return () => clearTimeout(timeoutId);
    }
  }, [searchText]);

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && totalPages >= pageNumber) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, totalPages, pageNumber, loadMorePath, observer]
  );

  useEffect(() => {
    let find_search = localStorage.getItem("short_link_search_text");
    if (find_search) {
      setSearchText(find_search);
    }
    getShortLInkListing(load_more_path, find_search);
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-lg-4 col-sm-12">
            <h2>Short Links</h2>
          </div>
          <div className="col-lg-8 col-sm-12 text-end">
            <SearchStyle
              className="ms-auto"
              value={searchText}
              onChange={handleSearchText}
              placeholder="Search"
              startAdornment={
                <InputAdornment position="start">
                  <Box
                    component={Icon}
                    icon={searchFill}
                    sx={{ color: "text.disabled" }}
                  />
                </InputAdornment>
              }
            />
            <button
              onClick={handleNavigate}
              className="small-contained-button ms-3"
            >
              Create Link
            </button>
          </div>
        </div>
        {linkList.length > 0 ? (
          <>
            {linkList.map((item, index) => {
              return (
                <div className="card mb-3" key={index}>
                  <div className="card-body">
                    <div className="d-flex justify-content-between flex-wrap">
                      <div className="d-flex">
                        <Avatar sx={{ bgcolor: "#ffda0014" }}>
                          <PublicIcon />
                        </Avatar>
                        <div className="ms-3">
                          <h5
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            {item.title}
                          </h5>
                          <p
                            style={{
                              fontSize: "14px",
                              marginBottom: "5px",
                            }}
                          >
                            <a
                              className="anchor-style"
                              style={{
                                color: "#e4c073",
                              }}
                              href={item.short_link}
                              target="_blank"
                            >
                              {item.short_link}
                            </a>
                          </p>
                          <p
                            style={{
                              fontSize: "14px",
                              marginBottom: "10px",
                              opacity: "0.9",
                            }}
                          >
                            <a
                              className="anchor-style"
                              href={item.long_url}
                              target="_blank"
                            >
                              {item.long_url}
                            </a>
                          </p>
                        </div>
                      </div>
                      <div className="mt-4 mt-md-0 ms-auto">
                        <div className="d-flex mb-4">
                          <div
                            className="me-2 link-chip"
                            onClick={() => handleNavigateEngagements(item)}
                          >
                            View Engagements
                          </div>
                          <CopyToClipboard
                            text={item?.short_link}
                            onCopy={() =>
                              handleCopyMessage("URL Copied to clipboard")
                            }
                          >
                            <Tooltip title={item?.short_link}>
                              <div className="me-2 link-chip">
                                <ContentCopyIcon
                                  style={{
                                    fontSize: "14px",
                                  }}
                                />{" "}
                                Copy
                              </div>
                            </Tooltip>
                          </CopyToClipboard>
                          <Tooltip title={item?.short_link}>
                            <div
                              className="me-3 link-chip"
                              onClick={() => {
                                handleOpenLink(item?.short_link);
                              }}
                            >
                              Open
                            </div>
                          </Tooltip>
                          <div className="link-menu">
                            <CustomPopoverSection
                              menu={MENU_OPTIONS}
                              data={item}
                            />
                          </div>
                        </div>
                        <p
                          style={{
                            fontSize: "14px",
                            marginBottom: "0px",
                            textAlign: "end",
                            marginRight: "16%",
                          }}
                        >
                          <DateRangeOutlinedIcon
                            style={{
                              fontSize: "20px",
                              marginRight: "6px",
                              marginTop: "-4px",
                            }}
                          />
                          {moment(item.createdAt).format("DD-MMMM-YYYY")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <div className="card mb-3">
            <div className="card-body text-center">
              <h4>No Data Exist</h4>
            </div>
          </div>
        )}
        {totalPages >= pageNumber ? (
          <div className="text-center mt-3">
            <span
              ref={lastBookElementRef}
              className="mt-3"
              onClick={loadMoreData}
              id="load-more-feed"
            >
              {isLoadingMore ? (
                <CircularProgress color="primary" size="1.5rem" />
              ) : (
                "Load More"
              )}
            </span>
          </div>
        ) : (
          ""
        )}
        <CustomConfirmation
          openDelete={openDelete}
          setOpenDelete={setOpenDelete}
          title={"Are you sure you want to delete ?"}
          handleAgree={handleDelete}
        />
      </div>
    </>
  );
};

export default ShortLinksList;
