import { Card, Chip, Tooltip } from "@mui/material";
import React from "react";
import { dummyImage, wheelIcon } from "src/assets";
import { s3baseUrl } from "src/config/config";
import {
  dd_date_format,
  get_short_string,
  show_proper_words,
} from "src/utils/constant";
import moment from "moment";
import HistoryIcon from "@mui/icons-material/History";
import { htmlDecode } from "src/utils/convertHtml";
import Label from "../Label";
import { get_kmb_number } from "src/utils/formatNumber";
import CopyToClipboard from "react-copy-to-clipboard";
import { useSnackbar } from "notistack";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useState } from "react";
import History from "src/pages/MembersList/History";
import LeadStatusHistory from "src/pages/MembersList/LeadStatusHistory";
import WhatsappPopUpModel from "../GeneralComponents/WhatsappPopUpModel";
import MemberMenuOptions from "./MemberMenuOptions";
import { useNavigate } from "react-router-dom";

const affiliate_info = (data) => {
  const affiliate_info = data?.affiliate_user_info;
  if (affiliate_info) {
    return affiliate_info.first_name + " " + affiliate_info.last_name;
  }
  return "Master Link";
};

const get_nurture_member = (data) => {
  if (data.nurture) {
    return data.nurture.first_name + " " + data.nurture.last_name;
  }
};

const get_delegate_member = (data) => {
  if (data.consultant) {
    return data.consultant.first_name + " " + data.consultant.last_name;
  } else {
    return "N/A";
  }
};

const lead_status_change = (data) => {
  let lead_status = data.lead_status;
  if (lead_status) {
    return (
      <Tooltip title={lead_status.title}>
        <Chip
          label={get_short_string(htmlDecode(lead_status.title), 18)}
          style={{
            backgroundColor: lead_status.background_color,
            color: lead_status.text_color,
            height: "25px",
          }}
        />
      </Tooltip>
    );
  }
  return "N/A";
};

const membership_expiry = (data) => {
  if (data.membership_purchase_expiry) {
    return dd_date_format(data.membership_purchase_expiry);
  }
  return "N/A";
};

const community_level = (data) => {
  let level = "N/A";
  if (data?.membership_level_badge_info) {
    level = data?.membership_level_badge_info?.membership_level_badge_title;
  }

  return level;
};

const is_wheel_of_life = (data) => {
  if (data.is_wheel_of_life) {
    return (
      <span className="me-2">
        <Tooltip title={"Wheel of life is completed"}>
          <img
            src={wheelIcon}
            style={{ maxWidth: "8%", display: "inline-block" }}
          />
        </Tooltip>
      </span>
    );
  }
  return "N/A";
};

const goal_statement_text = (data) => {
  let goal_statement_status = data.goal_statement_completed_status;
  if (goal_statement_status && data.save_and_close_status) {
    return "Responded (" + dd_date_format(data.save_and_close_date) + ")";
  } else if (goal_statement_status && !data.save_and_close_status) {
    return (
      "Completed (" + dd_date_format(data.goal_statement_completed_date) + ")"
    );
  }
  return "Incomplete";
};

const replace_user_type = (data) => {
  let replace_user_type = "IOS";
  if (data.member_created_by_platform !== "ios") {
    return show_proper_words(data.member_created_by_platform);
  }
  return replace_user_type;
};

const handle_goal_unlock_string = (data) => {
  const { program_info, accees_from_info, goal_statement_unlock_date } = data;

  let return_string = "Unlock Date: " + goal_statement_unlock_date;
  return_string += " || Program: " + program_info?.title;

  if (accees_from_info.type === "payment_request") {
    return_string += " || Payment Request: " + accees_from_info.request_title;
  } else if (accees_from_info.type === "plan_subscription") {
    return_string += " || Sale Page: " + accees_from_info.sale_page_title;
    return_string += " || Plan: " + accees_from_info.plan_title;
  } else if (accees_from_info.type === "program_assigned_from_admin_portal") {
    return_string += " || Admin: " + accees_from_info.name;
    return_string += " (" + accees_from_info.email + ")";
  }

  return return_string;
};

export default function MemberBasicInfoCard(props) {
  const { memberDetail, setIsLoading, setMemberDetail } = props;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [changeStatusOpen, setChangeStatusOpen] = useState(false);
  const [changeLeadStatus, setChangeLeadStatus] = useState(false);
  const [openMarketingAffliliate, setOpenMarketingAffliliate] = useState(false);
  const [referredPop, setReferredPop] = useState(false);
  const [changeDelegateOpen, setChangeDelegateOpen] = useState(false);
  const [leadStatusData, setleadStatusData] = useState({});

  const completed_challenges = memberDetail.challange?.reduce(
    (count, challenge) => {
      return challenge.is_complete ? count + 1 : count;
    },
    0
  );

  const show_history = (value) => {
    setleadStatusData(value);
    setChangeStatusOpen(true);
  };

  const show_history_delegate = (value) => {
    setleadStatusData(value);
    setChangeDelegateOpen(true);
  };

  const show_history_reffered = (value) => {
    setleadStatusData(value);
    setReferredPop(true);
  };

  const show_marketing_history_reffered = (value) => {
    setleadStatusData(value);
    setOpenMarketingAffliliate(true);
  };

  const show_history_lead_status = (value) => {
    setleadStatusData(value);
    setChangeLeadStatus(true);
  };

  const handleCopiedUrl = () => {
    enqueueSnackbar("Referral Id Copied to clipboard", { variant: "success" });
  };

  const handleUpdateStatus = (values) => {
    setMemberDetail?.((old) => {
      return { ...old, lead_status: values.lead_status };
    });
  };

  const handleUpdateAffiliate = (value) => {
    setMemberDetail?.((old) => {
      return { ...old, affiliate_url_name: value.affiliate_url_name };
    });
  };

  const onMemberDelete = () => {
    navigate(`/member`);
  };

  return (
    <>
      <div className="col-12 mt-3 user-profile-detail">
        <Card>
          <div className="row mb-4 mx-3 mt-3 justify-content-center align-items-center user-profile-data">
            <div className="col-12 col-md-7 d-flex align-items-center">
              <img
                className="rounded-circle"
                height="75px"
                width="75px"
                src={
                  memberDetail.profile_image === undefined ||
                  memberDetail.profile_image === ""
                    ? dummyImage
                    : s3baseUrl + memberDetail.profile_image
                }
              />
              <div className="ms-3">
                <h2>
                  {memberDetail.first_name + " " + memberDetail.last_name}
                </h2>
                <div className="text-muted">{memberDetail.email}</div>
              </div>
            </div>
            <div className="col-12 col-md-5 d-flex justify-content-end">
              <div className="d-flex me-1 text-muted">
                <div>Created At:&nbsp;</div>
                {dd_date_format(memberDetail.createdAt)}
              </div>
              <MemberMenuOptions
                memberDetail={memberDetail}
                setIsLoading={setIsLoading}
                handleUpdateStatus={handleUpdateStatus}
                handleUpdateAffiliate={handleUpdateAffiliate}
                onMemberDelete={onMemberDelete}
                list_type="member_profile"
              />
            </div>
          </div>
          <div className="row mx-3 mt-3 mb-5 user-profile-data">
            <div className="col-5 col-md-6 col-lg-3 mb-3">
              <h6>Last Login Activity:</h6>
            </div>
            <div className="col-7 col-md-6 col-lg-3 text-muted">
              {memberDetail?.last_login_activity
                ? moment(memberDetail?.last_login_activity).format(
                    "DD-MM-YYYY hh:mm A"
                  )
                : "N/A"}
            </div>
            <div className="col-5 col-md-6 col-lg-3 mb-3">
              <h6>Upcoming Assessment Date:</h6>
            </div>
            <div className="col-7 col-md-6 col-lg-3 text-muted">
              {memberDetail?.upcomming_attitude_assessment_date
                ? moment(
                    memberDetail?.upcomming_attitude_assessment_date
                  ).format("DD-MM-YYYY")
                : "N/A"}
            </div>
            <div className="col-5 col-md-6 col-lg-3 mb-3">
              <h6>Phone Number:</h6>
            </div>
            <div className="col-7 col-md-6 col-lg-3 text-muted">
              {memberDetail.contact_number}
            </div>
            <div className="col-5 col-md-6 col-lg-3 mb-3">
              <h6>Referred User:</h6>
            </div>
            <div className="col-7 col-md-6 col-lg-3 text-muted">
              <span
                className="me-2"
                style={{ cursor: "pointer" }}
                onClick={() => show_history_reffered(memberDetail)}
              >
                <Tooltip title="View Referred History">
                  <HistoryIcon />
                </Tooltip>
              </span>
              {affiliate_info(memberDetail.affliliate)}
            </div>
            <div className="col-5 col-md-6 col-lg-3 mb-3">
              <h6>Marketing Affliliate User:</h6>
            </div>
            <div className="col-7 col-md-6 col-lg-3 text-muted">
              <span
                className="me-2"
                style={{ cursor: "pointer" }}
                onClick={() => show_marketing_history_reffered(memberDetail)}
              >
                <Tooltip title="View Marketing Affliliate History">
                  <HistoryIcon />
                </Tooltip>
              </span>
              {affiliate_info(memberDetail.marketing_affliliate)}
            </div>
            <div className="col-5 col-md-6 col-lg-3 mb-3">
              <h6>Nurture:</h6>
            </div>
            <div className="col-7 col-md-6 col-lg-3 text-muted">
              <span
                className="me-2"
                style={{ cursor: "pointer" }}
                onClick={() => show_history(memberDetail)}
              >
                <Tooltip title="View Nurture History">
                  <HistoryIcon />
                </Tooltip>
              </span>
              {get_nurture_member(memberDetail)}
            </div>
            <div className="col-5 col-md-6 col-lg-3 mb-3">
              <h6>Delegate:</h6>
            </div>
            <div className="col-7 col-md-6 col-lg-3 text-muted">
              <span
                className="me-2"
                style={{ cursor: "pointer" }}
                onClick={() => show_history_delegate(memberDetail)}
              >
                <Tooltip title="View Delegate History">
                  <HistoryIcon />
                </Tooltip>
              </span>
              {get_delegate_member(memberDetail)}
            </div>
            <div className="col-5 col-md-6 col-lg-3 mb-3">
              <h6>Lead Status:</h6>
            </div>
            <div className="col-7 col-md-6 col-lg-3 text-muted">
              {memberDetail?.lead_status && (
                <span
                  className="me-1"
                  style={{ cursor: "pointer" }}
                  onClick={() => show_history_lead_status(memberDetail)}
                >
                  <Tooltip title="View Lead Status History">
                    <HistoryIcon />
                  </Tooltip>
                </span>
              )}
              {lead_status_change(memberDetail)}
            </div>

            <div className="col-5 col-md-6 col-lg-3 mb-3">
              <h6>Membership Expire:</h6>
            </div>
            <div className="col-7 col-md-6 col-lg-3 text-muted">
              {membership_expiry(memberDetail)}
            </div>
            <div className="col-5 col-md-6 col-lg-3 mb-3">
              <h6>Badge Level:</h6>
            </div>
            <div className="col-7 col-md-6 col-lg-3 text-muted capitalized">
              {community_level(memberDetail)}
              {/* {memberDetail?.community_level} */}
            </div>
            <div className="col-5 col-md-6 col-lg-3 mb-3">
              <h6>Wheel Of Life Enable:</h6>
            </div>
            <div className="col-7 col-md-6 col-lg-3 text-muted">
              {memberDetail.is_wheel_of_life_enable ? "Yes" : "No"}
            </div>
            <div className="col-5 col-md-6 col-lg-3 mb-3">
              <h6>Wheel Of Life Complete:</h6>
            </div>
            <div className="col-7 col-md-6 col-lg-3 text-muted">
              {is_wheel_of_life(memberDetail)}
            </div>
            <div className="col-5 col-md-6 col-lg-3 mb-3">
              <h6>Status:</h6>
            </div>
            <div className="col-7 col-md-6 col-lg-3 text-muted">
              <Label
                variant="ghost"
                color={memberDetail.status === false ? "error" : "success"}
              >
                {memberDetail.status === false ? "Inactive" : "Active"}
              </Label>
            </div>
            <div className="col-5 col-md-6 col-lg-3 mb-3">
              <h6>Goal:</h6>
            </div>
            <div className="col-7 col-md-6 col-lg-3 text-muted">
              <Label
                variant="ghost"
                color={
                  memberDetail.goal_statement_status === true
                    ? "success"
                    : "error"
                }
              >
                {memberDetail.goal_statement_status === true
                  ? "Unlocked"
                  : "Locked"}
              </Label>
            </div>
            <div className="col-5 col-md-6 col-lg-3 mb-3">
              <h6>Goal Statement:</h6>
            </div>
            <div className="col-7 col-md-6 col-lg-3 text-muted">
              {goal_statement_text(memberDetail)}
            </div>
            <div className="col-5 col-md-6 col-lg-3 mb-3">
              <h6>Created By Platform:</h6>
            </div>
            <div className="col-7 col-md-6 col-lg-3 text-muted">
              {replace_user_type(memberDetail)}
            </div>
            <div className="col-5 col-md-6 col-lg-3 mb-3">
              <h6>Total Coins:</h6>
            </div>
            <div className="col-7 col-md-6 col-lg-3 text-muted">
              {get_kmb_number(memberDetail.coins_count)}
            </div>
            <div className="col-5 col-md-6 col-lg-3 mb-3">
              <h6>Daily Intention Coins:</h6>
            </div>
            <div className="col-7 col-md-6 col-lg-3 text-muted">
              {get_kmb_number(memberDetail.dynamite_diary_coins_count)}
            </div>

            <div className="col-5 col-md-6 col-lg-3 mb-3">
              <h6>Gratitude Coins:</h6>
            </div>
            <div className="col-7 col-md-6 col-lg-3 text-muted">
              {get_kmb_number(memberDetail.dynamite_gratitude_coins_count)}
            </div>

            <div className="col-5 col-md-6 col-lg-3 mb-3">
              <h6>Assessment Coins:</h6>
            </div>
            <div className="col-7 col-md-6 col-lg-3 text-muted">
              {get_kmb_number(memberDetail.attitude_assessment_coins_count)}
            </div>
            <div className="col-5 col-md-6 col-lg-3 mb-3">
              <h6>Meditation Coins:</h6>
            </div>
            <div className="col-7 col-md-6 col-lg-3 text-muted">
              {get_kmb_number(memberDetail.meditation_coins_count)}
            </div>
            <div className="col-5 col-md-6 col-lg-3 mb-3">
              <h6>No. of Completed Challenges:</h6>
            </div>
            <div className="col-7 col-md-6 col-lg-3 text-muted">
              {get_kmb_number(completed_challenges)}
            </div>
            {memberDetail.affiliate_url_name && (
              <>
                <div className="col-5 col-md-6 col-lg-3 mb-3">
                  <h6>Copy Referral ID: </h6>
                </div>
                <div className="col-7 col-md-6 col-lg-3 text-muted ">
                  <CopyToClipboard
                    className="pointer me-2"
                    text={memberDetail.affiliate_url_name}
                    onCopy={() => handleCopiedUrl(true)}
                  >
                    <Tooltip title="Copy Referral ID">
                      <ContentCopyIcon
                        style={{
                          fontSize: "20px",
                        }}
                      />
                    </Tooltip>
                  </CopyToClipboard>
                  {memberDetail.affiliate_url_name}
                </div>
              </>
            )}
            {memberDetail?.register_url && (
              <div className="col-12 d-flex mb-3">
                <h6 className="profile_url">Registration Url:</h6>
                <div className="text-muted ">{memberDetail?.register_url}</div>
              </div>
            )}
            {memberDetail.goal_statement_unlock_obj && (
              <>
                <div className="col-12 d-flex">
                  <h6>Goal Statement Unlock History:</h6>
                  <div className="text-muted">
                    {handle_goal_unlock_string(
                      memberDetail.goal_statement_unlock_obj.other_info
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </Card>
      </div>

      <WhatsappPopUpModel
        open={changeStatusOpen}
        setOpen={setChangeStatusOpen}
        title={"Nurture History"}
        show_date_and_income={true}
        componentToPassDown={
          <History type="nurture" leadStatusData={leadStatusData} />
        }
      />
      <WhatsappPopUpModel
        open={changeLeadStatus}
        setOpen={setChangeLeadStatus}
        title={"Lead Status History"}
        show_date_and_income={true}
        componentToPassDown={
          <LeadStatusHistory leadStatusData={leadStatusData} />
        }
      />
      <WhatsappPopUpModel
        open={openMarketingAffliliate}
        setOpen={setOpenMarketingAffliliate}
        title={"Marketing Affliliate History"}
        show_date_and_income={true}
        componentToPassDown={
          <History
            type="marketing_affliliate"
            leadStatusData={leadStatusData}
          />
        }
      />
      <WhatsappPopUpModel
        open={referredPop}
        setOpen={setReferredPop}
        title={"Referred History"}
        show_date_and_income={true}
        componentToPassDown={
          <History type="affiliate" leadStatusData={leadStatusData} />
        }
      />
      <WhatsappPopUpModel
        open={changeDelegateOpen}
        setOpen={setChangeDelegateOpen}
        title={"Delegate History"}
        show_date_and_income={true}
        componentToPassDown={
          <History type="consultant" leadStatusData={leadStatusData} />
        }
      />
    </>
  );
}
