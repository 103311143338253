// material
import {
  Box,
  Grid,
  Container,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import { dashboardApi } from "src/DAL/Login/Login";
// components
import {
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppWeeklySales,
} from "../components/_dashboard/app";
import { useSnackbar } from "notistack";
import RecentMembersTable from "src/components/_dashboard/RecentMembers";
import Page from "src/components/Page";
import { usePGIMode } from "src/Hooks/PGIModeContext";
import GeneralCalendar from "src/components/GeneralComponents/GeneralCalendar";
import {
  difference_between_two_dates,
  get_view_dates,
} from "src/utils/constant";
import moment from "moment";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import PageLoader from "src/components/GeneralComponents/PageLoader";

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const { socket } = useContentSetting();
  const [isLoading, setIsLoading] = useState(true);
  const [isCalenderLoading, setIsCalenderLoading] = useState(true);
  const { metaTitle } = usePGIMode();
  const { enqueueSnackbar } = useSnackbar();
  const [defaultTimeValue, setDefaultTimeValue] = useState();
  const [currentDate, setCurrentDate] = useState(get_view_dates("month"));
  const [inputs, setInputs] = useState({
    member_count: "",
    program_count: "",
    lesson_count: "",
    program_recording_count: "",
    recent_lesson: [],
    recent_member: [],
    events: [],
  });

  const getDashboard = async () => {
    let apiData = {
      start_date: moment(currentDate.start_date).format("YYYY-MM-DD"),
      end_date: moment(currentDate.end_date).format("YYYY-MM-DD"),
    };
    const difference = difference_between_two_dates(
      apiData.start_date,
      apiData.end_date,
      "days"
    );

    if (difference < 10) {
      setIsCalenderLoading(true);
    }
    const result = await dashboardApi(apiData);
    if (result.code == 200) {
      setInputs(result);
      setDefaultTimeValue(result.time_zone);
      setIsLoading(false);
      setIsCalenderLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsCalenderLoading(false);
    }
  };

  useEffect(() => {
    getDashboard();
  }, [currentDate, socket]);

  if (isLoading === true) {
    return <PageLoader />;
  }

  return (
    <Page title={metaTitle}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome back</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySales
              total={inputs.total_amount}
              title="Total Amount"
              icon={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySales
              total={inputs.total_today_amount}
              title="Total Today Amount"
              icon={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySales
              total={inputs.total_dynamite_commission}
              title="Total Dynamite Commission"
              icon={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySales
              total={inputs.total_referral_commission}
              title="Total Referral Commission"
              icon={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers currency={false} members={inputs.member_count} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySales
              total={inputs.program_recording_count}
              currency={false}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppItemOrders programs={inputs.program_count} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppBugReports lesson={inputs.lesson_count} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySales
              total={inputs.total_user}
              currency={false}
              title="Total Users"
            />
          </Grid>{" "}
          <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySales
              total={inputs.total_in_processing_transaction_fee}
              currency={true}
              icon={true}
              title="DP Commission Processing"
            />
          </Grid>{" "}
          <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySales
              total={inputs.total_dynamite_payment_fee_for_succeded_transaction}
              currency={true}
              icon={true}
              title="DP Commission Succeed"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={9}></Grid>
          <Grid item xs={12} sm={6} md={6}>
            <RecentMembersTable
              recentMember={inputs.recent_member}
              type="recent_member"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <RecentMembersTable
              recentMember={inputs.recent_goal_statement_members}
              title="Recent Goal Statements"
              type="recent_goal_statement_members"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <GeneralCalendar
              eventList={inputs.events}
              setCurrentDate={setCurrentDate}
              showAddEvent={false}
              time_zone={defaultTimeValue}
              currentDate={currentDate}
              isLoading={isCalenderLoading}
              reloadList={getDashboard}
              calendar_title="Calendar"
              hideArrowsAndButtons={true}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
