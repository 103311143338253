import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, CircularProgress } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useEffect } from "react";
import {
  get_detail_template_configuration_api,
  template_configuration_api,
  update_template_configuration_api,
} from "src/DAL/WebsitePages/WebsitePages";
import { show_proper_words } from "src/utils/constant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const fileTypesOptions = ["input", "date", "editor", "file"];
const imageExtensionsOptions = [
  ".png",
  ".jpg",
  ".jpeg",
  ".webp",
  ".gif",
  ".JPG",
  ".JPEG",
  ".PNG",
  ".WEBP",
  ".GIF",
];
const ModuleArray = [
  { name: "Testimonial", value: "testimonial" },
  { name: "Food Menu", value: "food_menu" },
  { name: "Payment Plans", value: "payment_plans" },
  { name: "Website Programmes", value: "website_programmes" },
  { name: "Buisness Strategy", value: "buisness_strategy" },
  { name: "Success Schedule", value: "success_schedule" },
  { name: "Website Faq", value: "website_faq" },
  { name: "General Questions", value: "general_questions" },
  { name: "Banner Slider", value: "banner_slider" },
  { name: "Benefits", value: "benefits" },
  { name: "Vanues", value: "vanues" },
  { name: "Wheel Of Life", value: "wheel_of_life" },
];

const AddorUpdateConfig = () => {
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    template_name: "",
    template_type: "sale_page",
    template_status: true,
    page_component_name: "",
  });

  const [attributes, setAttributes] = useState([
    {
      required: false,
      image_extension: [
        ".png",
        ".jpg",
        ".jpeg",
        ".webp",
        ".gif",
        ".JPG",
        ".JPEG",
        ".PNG",
        ".WEBP",
        ".GIF",
      ],
      attribute_label: "",
      attribute_type: "input",
      attribute_db_name: "",
      attribute_db_type: "string",
      attribute_input_type: "text",
      width: "",
      height: "",
      is_access: true,
      isNew: true,
    },
  ]);

  const [moduleAttributes, setModuleAttributes] = useState([
    {
      selected_module: "",
      module_replica_name: "",
      module_label_text: "",
      add_button_text: "",
      add_page_heading: "",
      edit_page_heading: "",
      list_page_heading: "",
    },
  ]);

  const handleAddAttribute = (index) => {
    setAttributes((prev) => {
      const newAttribute = {
        required: false,
        image_extension: [
          ".png",
          ".jpg",
          ".jpeg",
          ".webp",
          ".gif",
          ".JPG",
          ".JPEG",
          ".PNG",
          ".WEBP",
          ".GIF",
        ],
        attribute_label: "",
        attribute_type: "input",
        attribute_db_name: "",
        attribute_db_type: "string",
        attribute_input_type: "text",
        width: "",
        height: "",
        is_access: true,
        isNew: true,
      };

      const updatedAttributes = [...prev];
      updatedAttributes.splice(index + 1, 0, newAttribute);

      return updatedAttributes;
    });
  };

  const handleAddModuleAttribute = (index) => {
    setModuleAttributes((prev) => {
      const newAttribute = {
        selected_module: "",
        module_replica_name: "",
        module_label_text: "",
        add_button_text: "",
        add_page_heading: "",
        edit_page_heading: "",
        list_page_heading: "",
      };

      const updatedAttributes = [...prev];
      updatedAttributes.splice(index + 1, 0, newAttribute);

      return updatedAttributes;
    });
  };

  const handleRemoveModuleAttribute = (index) => {
    const updatedAttributes = moduleAttributes.filter((_, i) => i !== index);
    setModuleAttributes(updatedAttributes);
  };

  const handleRemoveAttribute = (index) => {
    if (attributes.length === 1) return;
    const updatedAttributes = attributes.filter((_, i) => i !== index);
    setAttributes(updatedAttributes);
  };

  const handleFormFill = (value) => {
    setInputs(value);
    if (value.template_attributes_info) {
      let new_array = value.template_attributes_info.map((item) => {
        return {
          ...item,
          isNew: false,
        };
      });
      setAttributes(new_array);
    }
    if (value.module_info.length > 0) {
      let moduleData = value.module_info.map((item) => {
        return {
          selected_module: item.module_actual_name,
          module_replica_name: item.module_replica_name,
          module_label_text: item.module_label_text,
          add_button_text: item.module_replica_info.add_button_text,
          add_page_heading: item.module_replica_info.add_page_heading,
          edit_page_heading: item.module_replica_info.edit_page_heading,
          list_page_heading: item.module_replica_info.list_page_heading,
        };
      });
      setModuleAttributes(moduleData);
    }
  };

  const getTemplateDetail = async () => {
    setIsLoading(true);
    const result = await get_detail_template_configuration_api(params.id);
    if (result.code == 200) {
      handleFormFill(result.Template_configuration);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    let new_module_array = [];

    if (moduleAttributes.length > 0) {
      new_module_array = moduleAttributes.map((module) => {
        return {
          module_actual_name: module.selected_module,
          module_replica_name: module.module_replica_name,
          module_label_text: module.module_label_text,
          is_access: true,
          image_extension: [
            ".png",
            ".jpg",
            ".jpeg",
            ".webp",
            ".gif",
            ".JPG",
            ".JPEG",
            ".PNG",
            ".WEBP",
            ".GIF",
          ],
          image_width: "1000",
          image_height: "670",
          module_replica_info: {
            add_button_text: module.add_button_text,
            add_page_heading: module.add_page_heading,
            edit_page_heading: module.edit_page_heading,
            list_page_heading: module.list_page_heading,
          },
        };
      });
    }

    let new_attribute_array = attributes.map((item) => {
      let newItem = { ...item };
      delete newItem.isNew;
      return newItem;
    });

    const postData = {
      template_name: inputs?.template_name,
      template_status: inputs?.template_status,
      page_component_name: inputs?.page_component_name,
      template_type: inputs?.template_type,
      template_attributes_info: new_attribute_array,
      module_info: new_module_array,
    };

    const result = params.id
      ? await update_template_configuration_api(postData, params.id)
      : await template_configuration_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangeModule = (index, field, value) => {
    const updatedAttributes = [...moduleAttributes];
    updatedAttributes[index][field] = value;

    if (field === "selected_module") {
      updatedAttributes[index]["module_label_text"] = show_proper_words(value);
      updatedAttributes[index]["module_replica_name"] = value;
    } else if (field === "module_replica_name") {
      updatedAttributes[index][field] = value
        .replace(/\s+/g, "_")
        .toLowerCase();
    }
    setModuleAttributes(updatedAttributes);
  };

  const handleChange = (index, field, value, is_new) => {
    const updatedAttributes = [...attributes];
    if (field === "attribute_label") {
      let attribute_db_name = "";
      if (is_new) {
        attribute_db_name = value.replace(/[^a-zA-Z0-9]/g, "_").toLowerCase();
      } else {
        attribute_db_name = updatedAttributes[index]["attribute_db_name"];
      }
      updatedAttributes[index][field] = value;
      updatedAttributes[index]["attribute_db_name"] = attribute_db_name;
    } else {
      updatedAttributes[index][field] = value;
    }

    setAttributes(updatedAttributes);
  };

  const handleChangeInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    let page_name = "";
    if (name === "page_component_name" && !params.id) {
      page_name = value.replace(/[^a-zA-Z0-9]/g, "_").toLowerCase();
    } else {
      page_name = inputs.page_component_name;
    }

    setInputs((values) => ({
      ...values,
      [name]: value,
      page_component_name: page_name,
    }));
  };

  useEffect(() => {
    if (params && params.id) {
      getTemplateDetail();
    }
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-3"
              onClick={() => navigate(`/template-configuration`)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2 className="ms-1">{`${params.id ? "Edit" : "Add"} Template`}</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6  mt-4">
            <TextField
              id="outlined-basic"
              label="Template Name"
              variant="outlined"
              fullWidth
              required
              name="template_name"
              value={inputs.template_name}
              onChange={handleChangeInput}
            />
          </div>
          <div className="col-lg-6 col-md-6  mt-4">
            <TextField
              id="outlined-basic"
              label="Page Component Name"
              variant="outlined"
              fullWidth
              required
              disabled={params.id}
              name="page_component_name"
              value={inputs.page_component_name}
              onChange={handleChangeInput}
            />
          </div>
          <div className="col-lg-6 col-md-6  mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Template Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="template_type"
                value={inputs.template_type}
                label="Template Type"
                onChange={handleChangeInput}
                disabled={params.id}
              >
                <MenuItem value="sale_page">Sale Page</MenuItem>
                <MenuItem value="payment_page">Payment Page</MenuItem>
                <MenuItem value="thanks_page">Thanks Page</MenuItem>
                <MenuItem value="book_a_call_page">Book a Call Page</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="template_status"
                value={inputs.template_status}
                label="Status"
                onChange={handleChangeInput}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          {moduleAttributes.length > 0 && (
            <>
              <div className="col-md-12 my-3">
                <h3>Module Attributes Info</h3>
              </div>
              {moduleAttributes.map((attribute, index) => (
                <div className="row mb-3 ps-4" key={index}>
                  <div className="col-10 card  border_r_16 p-3">
                    <div className="row">
                      <div className="col-md-4">
                        <FormControl fullWidth required>
                          <InputLabel
                            id={`attributeTypeLabel${index}`}
                            style={{ marginBottom: "10px" }}
                          >
                            Select Module
                          </InputLabel>
                          <Select
                            value={attribute.selected_module}
                            onChange={(e) =>
                              handleChangeModule(
                                index,
                                "selected_module",
                                e.target.value
                              )
                            }
                            label="Select Module"
                          >
                            {ModuleArray.filter(
                              (option) =>
                                !moduleAttributes.some(
                                  (attr) =>
                                    attr.selected_module === option.value &&
                                    attr !== attribute
                                )
                            ).map((option, index) => (
                              <MenuItem key={index} value={option.value}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-md-4">
                        <TextField
                          id="outlined-basic"
                          label="Module Label"
                          variant="outlined"
                          fullWidth
                          name="module_label_text"
                          value={attribute.module_label_text}
                          onChange={(e) =>
                            handleChangeModule(
                              index,
                              "module_label_text",
                              e.target.value
                            )
                          }
                          required
                        />
                      </div>
                      <div className="col-md-4">
                        <TextField
                          id="outlined-basic"
                          label="Module Replica Name"
                          variant="outlined"
                          fullWidth
                          name="module_replica_name"
                          value={attribute.module_replica_name}
                          onChange={(e) =>
                            handleChangeModule(
                              index,
                              "module_replica_name",
                              e.target.value
                            )
                          }
                          required
                        />
                      </div>
                      <div className="col-md-3 mt-3">
                        <TextField
                          id="outlined-basic"
                          label="Add Button Text"
                          variant="outlined"
                          fullWidth
                          name="add_button_text"
                          value={attribute.add_button_text}
                          onChange={(e) =>
                            handleChangeModule(
                              index,
                              "add_button_text",
                              e.target.value
                            )
                          }
                          required
                        />
                      </div>
                      <div className="col-md-3 mt-3">
                        <TextField
                          id="outlined-basic"
                          label="Add Page Heading"
                          variant="outlined"
                          fullWidth
                          name="add_page_heading"
                          value={attribute.add_page_heading}
                          onChange={(e) =>
                            handleChangeModule(
                              index,
                              "add_page_heading",
                              e.target.value
                            )
                          }
                          required
                        />
                      </div>
                      <div className="col-md-3 mt-3">
                        <TextField
                          id="outlined-basic"
                          label="Edit Page Heading"
                          variant="outlined"
                          fullWidth
                          name="edit_page_heading"
                          value={attribute.edit_page_heading}
                          onChange={(e) =>
                            handleChangeModule(
                              index,
                              "edit_page_heading",
                              e.target.value
                            )
                          }
                          required
                        />
                      </div>
                      <div className="col-md-3 mt-3">
                        <TextField
                          id="outlined-basic"
                          label="List Page Heading"
                          variant="outlined"
                          fullWidth
                          name="list_page_heading"
                          value={attribute.list_page_heading}
                          onChange={(e) =>
                            handleChangeModule(
                              index,
                              "list_page_heading",
                              e.target.value
                            )
                          }
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-2 d-flex align-items-center">
                    <AddCircleOutlineIcon
                      className="question-survey cursor-pointer"
                      onClick={() => handleAddModuleAttribute(index)}
                    />
                    <RemoveCircleOutlineIcon
                      className="question-survey cursor-pointer"
                      onClick={() => handleRemoveModuleAttribute(index)}
                      style={{
                        fill: "red",
                      }}
                    />
                  </div>
                </div>
              ))}
            </>
          )}
          <div className="col-md-12 my-3">
            <h3>Template Attributes Info</h3>
          </div>
          {attributes.map((attribute, index) => (
            <div className="row mb-3 ps-4" key={index}>
              <div className="col-10 card  border_r_16 p-3">
                <div className="row">
                  <div className="col-md-12">
                    <TextField
                      id="outlined-basic"
                      label="Attribute Label"
                      variant="outlined"
                      fullWidth
                      name="attribute_label"
                      value={attribute.attribute_label}
                      onChange={(e) =>
                        handleChange(
                          index,
                          "attribute_label",
                          e.target.value,
                          attribute?.isNew
                        )
                      }
                      required
                    />
                  </div>
                  <div className="col-lg-6 col-md-6  mt-4">
                    <FormControl fullWidth>
                      <InputLabel
                        id={`attributeTypeLabel${index}`}
                        style={{ marginBottom: "10px" }}
                      >
                        Type
                      </InputLabel>
                      <Select
                        value={attribute.attribute_type}
                        onChange={(e) =>
                          handleChange(index, "attribute_type", e.target.value)
                        }
                        label="Type"
                      >
                        {fileTypesOptions.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option.charAt(0).toUpperCase() + option.slice(1)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  {attribute.attribute_type == "input" && (
                    <div className="col-lg-6 col-md-6  mt-4">
                      <FormControl fullWidth>
                        <InputLabel
                          id={`attributeTypeLabel${index}`}
                          style={{ marginBottom: "10px" }}
                        >
                          Input Type
                        </InputLabel>
                        <Select
                          value={attribute.attribute_input_type}
                          onChange={(e) =>
                            handleChange(
                              index,
                              "attribute_input_type",
                              e.target.value
                            )
                          }
                          label="Input Type"
                        >
                          <MenuItem value={"text"}>Text</MenuItem>
                          <MenuItem value={"button"}>Button</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  )}

                  {attribute.attribute_type === "file" && (
                    <>
                      <div className="col-lg-6 col-md-6  mt-4">
                        <TextField
                          id="outlined-basic"
                          label="Width"
                          variant="outlined"
                          fullWidth
                          name="width"
                          value={attribute.width}
                          onChange={(e) =>
                            handleChange(index, "width", e.target.value)
                          }
                          required
                          type="number"
                          min="0"
                        />
                      </div>
                      <div className="col-lg-6 col-md-6  mt-4">
                        <TextField
                          id="outlined-basic"
                          label={"Height"}
                          variant="outlined"
                          fullWidth
                          name="height"
                          value={attribute.height}
                          onChange={(e) =>
                            handleChange(index, "height", e.target.value)
                          }
                          required
                          type="number"
                          min="0"
                        />
                      </div>
                      <div className="col-lg-6 col-md-6  mt-4">
                        <FormControl fullWidth>
                          <InputLabel
                            id={`imageExtensionLabel${index}`}
                            style={{ marginBottom: "10px" }}
                          >
                            Image Extensions
                          </InputLabel>
                          <Select
                            multiple
                            labelId={`imageExtensionLabel${index}`}
                            id={`imageExtension${index}`}
                            label="Image Extensions"
                            value={attribute.image_extension}
                            required
                            onChange={(e) =>
                              handleChange(
                                index,
                                "image_extension",
                                e.target.value
                              )
                            }
                          >
                            {imageExtensionsOptions.map((option, index) => (
                              <MenuItem key={index} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </>
                  )}

                  <div className="col-lg-6 col-md-6  mt-4">
                    <FormControl fullWidth>
                      <InputLabel
                        id={`requiredLabel${index}`}
                        style={{ marginBottom: "10px" }}
                      >
                        is Required
                      </InputLabel>
                      <Select
                        value={attribute.required}
                        onChange={(e) =>
                          handleChange(index, "required", e.target.value)
                        }
                        label="is Required"
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="col-2 d-flex align-items-center">
                <AddCircleOutlineIcon
                  className="question-survey cursor-pointer"
                  onClick={() => handleAddAttribute(index)}
                />
                {attributes.length !== 1 && (
                  <RemoveCircleOutlineIcon
                    className="question-survey cursor-pointer"
                    onClick={() => handleRemoveAttribute(index)}
                    style={{
                      fill: "red",
                    }}
                  />
                )}
              </div>
            </div>
          ))}
          <div className="text-end mt-4" id={params.id ? "fixedbutton" : ""}>
            <button className="small-contained-button">
              {params.id ? "Update" : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddorUpdateConfig;
