import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import PageLoader from "src/components/GeneralComponents/PageLoader";
import { get_analytics_dashboard } from "src/DAL/FirebaseAnalytics/FirebaseAnalytics";
import RetentionGraph from "src/components/FirebaseAnalytics/RetentionGraph";
import AnalyticsDashboardStats from "src/components/FirebaseAnalytics/AnalyticsDashboardStats";
import { Icon } from "@iconify/react";
import { Tooltip } from "@mui/material";

const AnalyticsDashboard = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [userRetention, setUserRetention] = useState({});
  const [dashboardData, setDashboardData] = useState({});

  const getCategoriesList = async () => {
    setIsLoading(true);
    const result = await get_analytics_dashboard();
    if (result.code == 200) {
      setUserRetention(result.retention_graph);
      setDashboardData(result.dashboard);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCategoriesList();
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-3 mt-3">
          <div className="col-12">
            <h2>State</h2>
          </div>
          <div className="col-12">
            <div className="row firebase-dashboard-stats">
              <AnalyticsDashboardStats cardDetail={dashboardData} />
            </div>
          </div>
          <div className="col-12 mt-3">
            <div className="card p-3 height-100 retention-users-chart-header">
              <div className="d-flex justify-content-between">
                <h3 className="heading">User retention</h3>
                <Tooltip
                  title={
                    <>
                      Shows the percentage of users who continue to engage with
                      your app over a defined period. This helps to measure how
                      well your app retains users and the effectiveness of user
                      engagement strategies.
                    </>
                  }
                >
                  <Icon
                    className="information-icon"
                    icon={"eva:alert-circle-outline"}
                  />
                </Tooltip>
              </div>

              <RetentionGraph
                activeUsers={userRetention}
                metric="User retention"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnalyticsDashboard;
