import React from "react";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { useState } from "react";
import { useEffect } from "react";
import { s3baseUrl } from "src/config/config";
import arrowCircleDownFill from "@iconify/icons-eva/arrow-circle-down-fill";
import { Icon } from "@iconify/react";
import moment from "moment";
import { get_kmb_number } from "src/utils/formatNumber";
import { dd_date_format, replace_created_for } from "src/utils/constant";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import GeneralRequestForCsv from "../MembersList/GeneralRequestForCsv";
import PageLoader from "src/components/GeneralComponents/PageLoader";
import { useNavigate } from "react-router-dom";
import HistoryIcon from "@mui/icons-material/History";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import ShowBadgeLevelHistory from "./ShowBadgeLevelHistory";
import WhatsappPopUpModel from "src/components/GeneralComponents/WhatsappPopUpModel";
import CustomAvatarColorCode from "src/components/GeneralComponents/CustomAvatarColorCode";

let member_options = [
  {
    label: "First name",
    value: "first_name",
    is_checked: true,
    is_disabled: true,
  },

  {
    label: "Last name",
    value: "last_name",
    is_checked: true,
    is_disabled: true,
  },
  { label: "Email", value: "email", is_checked: true },
  {
    label: "Contact Number",
    value: "contact_number",
    is_checked: true,
  },
  { label: "Nurture", value: "nurture", is_checked: false },
  { label: "Delegate", value: "consultant", is_checked: false },
  {
    label: "Previous Badge Level",
    value: "previous_badge_level",
    is_checked: false,
  },
  {
    label: "current Badge Level",
    value: "current_badge_level",
    is_checked: false,
  },
  {
    label: "Created At",
    value: "createdAt",
    is_checked: false,
  },
  {
    label: "Membership Purchase Expiry",
    value: "membership_purchase_expiry",
    is_checked: false,
  },
];

export default function MembershipLevel({
  tabData,
  searchFunction,
  searchText,
  setSearchText,
  pageCount,
  totalPages,
  handleChangePages,
  custom_pagination,
  filterQuery,
  isLoadingBadgeLevel,
  badgeLevelType,
  handleChangeType,
}) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [membersData, setMembersData] = useState([]);
  const [showExportcsvFile, setShowExportcsvFile] = useState(false);
  const [openView, setOpenView] = useState("");
  const [openViewData, setOpenViewData] = useState(null);

  const showPopUPcsv = () => {
    setShowExportcsvFile(true);
  };

  const show_badge_level_history = (data) => {
    setOpenView(data?.previous_badge_level?.membership_level_badge_title);
    setOpenViewData(data);
  };

  const moveToMemberProfile = (value) => {
    navigate(`/member/member-profile/${value._id}`, {
      state: {
        ...value,
        move_from_page_title: "History",
        move_from_page_path: "/history",
      },
    });
  };

  const getMembershipLevelList = () => {
    setIsLoading(true);
    const members = tabData?.map((member, index) => {
      let affiliate_name = "Master Link";
      let affiliate_info = member.affliliate?.affiliate_user_info;
      if (affiliate_info) {
        affiliate_name =
          affiliate_info.first_name + " " + affiliate_info.last_name;
      }
      if (member.affliliate?.affiliate_url_name) {
        affiliate_name =
          affiliate_name + " (" + member.affliliate.affiliate_url_name + ")";
      }

      let delegate_name = "N/A";
      let delegate = member.consultant;
      if (delegate) {
        delegate_name = delegate.first_name + " " + delegate.last_name;
      }

      let goal_statement_status = member.goal_statement_completed_status;
      let goal_statement_text = "Incomplete";
      if (goal_statement_status && member.save_and_close_status) {
        goal_statement_text =
          "Responded" + " (" + dd_date_format(member.save_and_close_date) + ")";
      } else if (goal_statement_status && !member.save_and_close_status) {
        goal_statement_text =
          "Completed" +
          " (" +
          dd_date_format(member.goal_statement_completed_date) +
          ")";
      }

      let wheel_of_life_enable = "No";
      if (member.is_wheel_of_life_enable) {
        wheel_of_life_enable = "Yes";
      }

      let replace_user_type = "IOS";
      if (member.member_created_by_platform !== "ios") {
        replace_user_type = replace_created_for(
          member.member_created_by_platform
        );
      }

      return {
        ...member,
        name: member.first_name + " " + member.last_name,
        replace_user_type: replace_user_type,
        register_url: member.register_url ? member.register_url : "N/A",
        affiliate_name: affiliate_name,
        delegate_name: delegate_name,
        nurture: member.nurture
          ? member.nurture.first_name + " " + member.nurture.last_name
          : "N/A",
        completeNurture: member.nurture,
        goal_statement_text: goal_statement_text,
        created_at: dd_date_format(member.createdAt),
        last_login_activity: member.last_login_activity
          ? moment(member.last_login_activity).format("DD-MM-YYYY hh:mm A")
          : "N/A",
        user_coins: get_kmb_number(member.coins_count),
        diary_coins_count: get_kmb_number(member.dynamite_diary_coins_count),
        meditation_coins: get_kmb_number(member.meditation_coins_count),
        wheel_of_life_enable: wheel_of_life_enable,
        phone: member.contact_number ? member.contact_number : "N/A",
        assessment_coins_count: get_kmb_number(
          member.attitude_assessment_coins_count
        ),
        gratitude_coins: get_kmb_number(member.dynamite_gratitude_coins_count),
        membership_expire: member?.membership_purchase_expiry
          ? dd_date_format(member?.membership_purchase_expiry)
          : "N/A",
        table_avatar: {
          src: member.profile_image ? s3baseUrl + member.profile_image : "/",
          alt: member.first_name,
        },
        lead_status_title: member.lead_status?.title,
      };
    });
    setMembersData(members);
    setIsLoading(false);
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },

    {
      id: "table_avatar",
      label: "Image",
      renderData: (row) => {
        return (
          <p className="mb-0" style={{ cursor: "pointer" }}>
            <CustomAvatarColorCode
              alt={row?.first_name}
              src={row?.profile_image ? s3baseUrl + row?.profile_image : "/"}
              colorCode={
                row?.membership_level_badge_info
                  ?.membership_level_badge_color_code
              }
            />
          </p>
        );
      },
    },
    {
      id: "name",
      label: "Name",
      renderData: (row) => {
        return (
          <p
            className="mb-0"
            style={{ cursor: "pointer" }}
            onClick={() => moveToMemberProfile(row)}
          >
            {row?.name ? row?.name : "N/A"}
          </p>
        );
      },
    },
    {
      id: "email",
      label: "Email",
      renderData: (row) => {
        return (
          <p
            className="mb-0"
            style={{ cursor: "pointer" }}
            onClick={() => moveToMemberProfile(row)}
          >
            {row?.email ? row?.email : "N/A"}
          </p>
        );
      },
    },
    { id: "phone", label: "Phone Number", alignRight: false },
    {
      id: "affiliate_name",
      label: "Referred User",
      renderData: (row) => {
        return (
          <p className="mb-0">
            {row.affiliate_name ? row.affiliate_name : "N/A"}
          </p>
        );
      },
      alignRight: false,
    },
    {
      id: "nurture",
      label: "Nurture",
      renderData: (row) => {
        return <p className="mb-0">{row.nurture ? row.nurture : "N/A"}</p>;
      },
      alignRight: false,
    },
    {
      id: "delegate_name",
      label: "Delegate",
      renderData: (row) => {
        return (
          <p className="mb-0">
            {row.delegate_name ? row.delegate_name : "N/A"}
          </p>
        );
      },
      alignRight: false,
    },
    {
      label: "Current Badge Level",
      alignRight: false,
      className: "text-capitalize",
      renderData: (row) => {
        return (
          <>
            {row?.membership_level_badge_info ? (
              <p className="mb-0">
                {row?.previous_badge_level && (
                  <span
                    className="me-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => show_badge_level_history(row)}
                  >
                    <Tooltip title="View Badge Level History">
                      <HistoryIcon />
                    </Tooltip>
                  </span>
                )}
                {row?.membership_level_badge_info?.membership_level_badge_title}
              </p>
            ) : (
              "N/A"
            )}
          </>
        );
      },
    },

    {
      label: "Previous Badge Level",
      alignRight: false,
      className: "text-capitalize",
      renderData: (row) => {
        return (
          <>
            {row?.previous_badge_level ? (
              <p className="mb-0">
                <span
                  className="me-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => show_badge_level_history(row)}
                >
                  <Tooltip title="View Badge Level History">
                    <HistoryIcon />
                  </Tooltip>
                </span>
                {row?.previous_badge_level?.membership_level_badge_title}
              </p>
            ) : (
              "N/A"
            )}
          </>
        );
      },
    },

    {
      id: "created_at",
      label: "Created At",
      alignRight: false,
    },
    {
      id: "membership_expire",
      label: "Membership Expire",
      alignRight: false,
    },
    {
      id: "replace_user_type",
      label: "Created By Platform",
      alignRight: false,
      className: "text-capitalize",
    },
  ];

  useEffect(() => {
    getMembershipLevelList();
  }, [tabData]);

  if (isLoading || isLoadingBadgeLevel) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="container-fluid ps-1">
        <div className="d-flex justify-content-end">
          <FormControl className="badge_level_dropdown_in_history_filter">
            <InputLabel id="demo-simple-select-label">Members type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="type"
              value={badgeLevelType}
              label="Members type"
              onChange={handleChangeType}
              className="badge_level_dropdown_in_history_filter"
            >
              <MenuItem value="all">All Members</MenuItem>
              <MenuItem value="current">Members with Current Badge</MenuItem>
              <MenuItem value="previous">Member with Previous Badge</MenuItem>
            </Select>
          </FormControl>

          <div className="button-history mt-0 ms-3">
            <button className="small-contained-button" onClick={showPopUPcsv}>
              Request For Csv &nbsp;&nbsp; <Icon icon={arrowCircleDownFill} />
            </button>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={membersData}
          className="card-with-background mt-3"
          custom_pagination={custom_pagination}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />

        <GeneralPopUpModel
          open={showExportcsvFile}
          setOpen={setShowExportcsvFile}
          title="Export CSV file request"
          componentToPassDown={
            <GeneralRequestForCsv
              options_array={member_options}
              collection_name={"user_badge_level"}
              filter_data={filterQuery}
              setShowExportcsvFile={setShowExportcsvFile}
            />
          }
        />
      </div>

      <WhatsappPopUpModel
        open={openView}
        setOpen={setOpenView}
        title={`Badge Level History - ${
          openViewData?.first_name +
          " " +
          openViewData?.last_name +
          " ( " +
          openViewData?.email +
          " ) "
        } `}
        show_date_and_income={true}
        componentToPassDown={
          <ShowBadgeLevelHistory selectedRowData={openViewData} />
        }
      />
    </>
  );
}
