import * as React from "react";
import Box from "@mui/material/Box";
import AffiliateTransactions from "./AffiliateTransactions";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import { useState } from "react";

export default function CommissionTabs({ tabData }) {
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const TABS_OPTIONS = [
    {
      title: "Credit",
      component: <AffiliateTransactions type="credit" tabData={tabData} />,
    },
    {
      title: "Paid",
      component: <AffiliateTransactions type="paid" tabData={tabData} />,
    },
  ];

  return (
    <div className="ps-4 pe-4">
      <Box sx={{ width: "100%", typography: "body1", marginTop: "10px" }}>
        <div className="mt-3">
          <h2>Commission Detail</h2>
        </div>
        <MUICustomTabs
          data={TABS_OPTIONS}
          value={tabValue}
          handleChange={handleChange}
        />
      </Box>
    </div>
  );
}
