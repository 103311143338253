import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { deleteTaxApi, get_List_Tax_Api } from "src/DAL/Tax/TaxApi";
import { useNavigate } from "react-router-dom";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const TABLE_HEAD = [
  {
    id: "action",
    label: "Action",
    type: "action",
    MENU_OPTIONS: "MENU_OPTIONS",
  },
  { id: "number", label: "#", type: "number" },
  {
    id: "title",
    label: "Title",
  },
  { id: "country", label: "Country", alignRight: false },
  { id: "status", label: "Status", type: "row_status" },
  { id: "tax", label: "Tax Percent", alignRight: false },
];

export default function TaxList() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoadingSection, setIsLoadingSection] = useState(true);
  const [subscriptionsData, setSubscriptionsData] = useState([]);
  const [selectedObject, setSelectedObject] = useState();
  const [openDelete, setOpenDelete] = useState(false);

  const getMembersList = async () => {
    setIsLoadingSection(true);

    const result = await get_List_Tax_Api();
    if (result.code == 200) {
      let subscription_list = [];
      if (result.data.length > 0) {
        subscription_list = result.data.map((event, index) => {
          return {
            ...event,
            title: event.tax_type_title,
            country: event.country,
            tax: event.percentage + "%",
            object: event,
            status: event.status,
            MENU_OPTIONS: MENU_OPTIONS,
          };
        });
      }
      setSubscriptionsData(subscription_list);
      setIsLoadingSection(false);
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      setIsLoadingSection(false);
    }
  };
  const handleAgreeDelete = (value) => {
    setSelectedObject(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await deleteTaxApi(selectedObject._id);
    if (result.code === 200) {
      setSubscriptionsData((prev) => {
        return prev.filter((data) => data._id !== selectedObject._id);
      });
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleEdit = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/taxes/edit-taxes/${value?.object?._id}`, {
      state: value,
    });
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  useEffect(() => {
    getMembersList();
  }, []);

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-6 mb-3">
            <h2>Tax List</h2>
          </div>
          <div className="col-6 mb-3 text-end">
            <button
              className="small-contained-button"
              onClick={() => navigate("/taxes/add-taxes")}
            >
              Add tax
            </button>
          </div>
        </div>

        <div className="row mt-3">
          {isLoadingSection ? (
            <PageLoader />
          ) : (
            <div className="col-12">
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={subscriptionsData}
                className="card-with-background"
                pagePagination={true}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
