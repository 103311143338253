import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton, CircularProgress, Autocomplete } from "@mui/material";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { useEffect } from "react";

import { addAdminUSer, editAdminUser } from "src/DAL/AdminUserApi/AdminUserApi";
import {
  addVersionHistory,
  editVesionList,
} from "src/DAL/VesionHistoryApi/VersionHistory";
import moment from "moment";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ios_update_info = {
  version_no: "",
  description: "",
  force_update: true,
  prompt_show_time: "",
  submited_date: null,
  build_status: "pending",
  released_date: null,
};
const android_update_info = {
  version_no: "",
  description: "",
  force_update: true,
  prompt_show_time: "",
  submited_date: null,
  build_status: "pending",
  released_date: null,
};

export default function VersionHistoryAddOrUpdate() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState({});

  const [inputs, setInputs] = useState({
    platform_type: "both",
    ios_description: "",
    andoriod_description: "",
    android_update_info,
    ios_update_info,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let postData = {
      platform_type: inputs?.platform_type,
    };
    if (inputs?.platform_type === "ios" || inputs?.platform_type === "both") {
      postData.ios_update_info = {
        ...inputs?.ios_update_info,
        description: inputs?.ios_description,
        submited_date: inputs?.ios_update_info?.submited_date
          ? moment(inputs.ios_update_info.submited_date).format("YYYY-MM-DD")
          : "",
        released_date: inputs?.ios_update_info?.released_date
          ? moment(inputs.ios_update_info.released_date).format("YYYY-MM-DD")
          : "",
      };
    }
    if (
      inputs?.platform_type === "android" ||
      inputs?.platform_type === "both"
    ) {
      postData.android_update_info = {
        ...inputs?.android_update_info,
        description: inputs.andoriod_description,
        submited_date: inputs?.android_update_info?.submited_date
          ? moment(inputs.android_update_info.submited_date).format(
              "YYYY-MM-DD"
            )
          : "",
        released_date: inputs?.android_update_info?.released_date
          ? moment(inputs.android_update_info.released_date).format(
              "YYYY-MM-DD"
            )
          : "",
      };
    }
    const result = params.id
      ? await editVesionList(postData, params.id)
      : await addVersionHistory(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleChangeForDate = (event, name, forPlatform) => {
    const value = event;
    setInputs((values) => ({
      ...values,
      [forPlatform]: { ...values[forPlatform], [name]: value },
    }));
  };
  const handleChangeForIos = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({
      ...values,
      ios_update_info: { ...values.ios_update_info, [name]: value },
    }));
  };

  const handleChangeForAndroid = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({
      ...values,
      android_update_info: { ...values.android_update_info, [name]: value },
    }));
  };
  useEffect(() => {
    if (params?.id && state) {
      setInputs(state);
      setInputs({
        ...state,
        ["andoriod_description"]: state?.android_update_info?.description,
        ["ios_description"]: state?.ios_update_info?.description,
      });
    }
  }, []);

  console.log(state);
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-3"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2 className="ms-1">
            {params?.id ? "Edit Versions History" : "Add Versions History"}
          </h2>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Platform</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="platform_type"
                value={inputs.platform_type}
                label="Platform"
                onChange={handleChange}
              >
                <MenuItem value="both">Both</MenuItem>
                <MenuItem value="ios">IOS</MenuItem>
                <MenuItem value="android">Android</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        {(inputs?.platform_type === "ios" ||
          inputs?.platform_type === "both") && (
          <div
            className="row  mt-3"
            style={{
              backgroundColor: "#1d1c1d",
              borderRadius: "10px",
              marginLeft: "0px",
            }}
          >
            <h3 className="Sub-heading">IOS Update Info*</h3>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
              <TextField
                id="outlined-basic"
                label="Version No"
                variant="outlined"
                fullWidth
                required
                name="version_no"
                value={inputs?.ios_update_info?.version_no}
                onChange={handleChangeForIos}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">
                  Force Update
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="force_update"
                  value={inputs?.ios_update_info?.force_update}
                  label="Force Update"
                  onChange={handleChangeForIos}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </div>
            {!inputs?.ios_update_info?.force_update && (
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Prompt Show Time"
                  variant="outlined"
                  fullWidth
                  required
                  type="number"
                  name="prompt_show_time"
                  value={inputs?.ios_update_info?.prompt_show_time}
                  onChange={handleChangeForIos}
                />
              </div>
            )}
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Submited Date"
                  name="submited_date"
                  value={inputs?.ios_update_info?.submited_date}
                  format="YYYY-MM-DD"
                  inputFormat="dd-MM-yyyy"
                  onChange={(e) =>
                    handleChangeForDate(e, "submited_date", "ios_update_info")
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      className="inputs-fields"
                      required={true}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">
                  Build Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="build_status"
                  value={inputs?.ios_update_info?.build_status}
                  label="Build Status"
                  onChange={handleChangeForIos}
                >
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="approved">Approved</MenuItem>
                  <MenuItem value="rejected">Rejected</MenuItem>
                </Select>
              </FormControl>
            </div>{" "}
            {inputs?.ios_update_info?.build_status === "approved" && (
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Released Date"
                    name="released_date"
                    value={inputs?.ios_update_info?.released_date}
                    format="YYYY-MM-DD"
                    inputFormat="dd-MM-yyyy"
                    onChange={(e) =>
                      handleChangeForDate(e, "released_date", "ios_update_info")
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        className="inputs-fields"
                        required={true}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            )}
            <div className="col-12 mt-4 mb-4">
              <h4>Description</h4>
              <GeneralCkeditor
                setInputs={setInputs}
                inputs={inputs}
                name="ios_description"
                editorHeight={320}
              />
            </div>
          </div>
        )}
        {(inputs?.platform_type === "android" ||
          inputs?.platform_type === "both") && (
          <div
            className="row  mt-3"
            style={{
              backgroundColor: "#1d1c1d",
              borderRadius: "10px",
              marginLeft: "0px",
            }}
          >
            <h3 className="Sub-heading">Android Update Info*</h3>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
              <TextField
                id="outlined-basic"
                label="Version No"
                variant="outlined"
                fullWidth
                required
                name="version_no"
                value={inputs?.android_update_info?.version_no}
                onChange={handleChangeForAndroid}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">
                  Force Update
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="force_update"
                  value={inputs?.android_update_info?.force_update}
                  label="Force Update"
                  onChange={handleChangeForAndroid}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </div>
            {!inputs?.ios_update_info?.force_update && (
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Prompt Show Time"
                  variant="outlined"
                  fullWidth
                  required
                  type="number"
                  name="prompt_show_time"
                  value={inputs?.android_update_info?.prompt_show_time}
                  onChange={handleChangeForAndroid}
                />
              </div>
            )}
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Submited Date"
                  name="submited_date"
                  value={inputs?.android_update_info?.submited_date}
                  format="YYYY-MM-DD"
                  inputFormat="dd-MM-yyyy"
                  onChange={(e) =>
                    handleChangeForDate(
                      e,
                      "submited_date",
                      "android_update_info"
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      className="inputs-fields"
                      required={true}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">
                  Build Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="build_status"
                  value={inputs?.android_update_info?.build_status}
                  label="Build Status"
                  onChange={handleChangeForAndroid}
                >
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="approved">Approved</MenuItem>
                  <MenuItem value="rejected">Rejected</MenuItem>
                </Select>
              </FormControl>
            </div>{" "}
            {inputs?.android_update_info?.build_status === "approved" && (
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Released Date"
                    name="released_date"
                    value={inputs?.android_update_info?.released_date}
                    format="YYYY-MM-DD"
                    inputFormat="dd-MM-yyyy"
                    onChange={(e) =>
                      handleChangeForDate(
                        e,
                        "released_date",
                        "android_update_info"
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        className="inputs-fields"
                        required={true}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            )}
            <div className="col-12 mt-4 mb-4">
              <h4>Description</h4>
              <GeneralCkeditor
                setInputs={setInputs}
                inputs={inputs}
                name="andoriod_description"
                editorHeight={320}
              />
            </div>
          </div>
        )}

        <div className="text-end mt-4">
          <button className="small-contained-button">Submit</button>
        </div>
      </form>
    </div>
  );
}
