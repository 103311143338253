import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import { usePGIMode } from "src/Hooks/PGIModeContext";
import ChallengesList from "./ChallengesList";

const MissionTabsType = ({ level, formDataUpdated }) => {
  const navigate = useNavigate();
  const { badgeLevels } = usePGIMode();
  const [tabValue, setTabValue] = useState("");
  const handleNavigate = () => {
    navigate(`/mission/addOrUpdate`);
  };
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    sessionStorage.setItem("mission_list_type", newValue);
  };
  const TABS_OPTIONS = [
    {
      title: "Mission",
      component: (
        <ChallengesList
          level={level}
          type={"mission"}
          formDataUpdated={formDataUpdated}
        />
      ),
    },
    {
      title: "Quest",
      component: (
        <ChallengesList
          level={level}
          type={"quest"}
          formDataUpdated={formDataUpdated}
        />
      ),
    },
  ];
  useEffect(() => {
    let value = sessionStorage.getItem("mission_list_type");
    if (value) {
      setTabValue(Number(value));
    } else {
      setTabValue(0);
    }
  }, []);

  return (
    <>
      <div
        className="mt-3"
        style={{
          background: "#1d1c1d",
          borderRadius: "10px",
          // padding: "10px",
        }}
      >
        <MUICustomTabs
          data={TABS_OPTIONS}
          value={tabValue}
          handleChange={handleChange}
        />
      </div>
    </>
  );
};

export default MissionTabsType;
