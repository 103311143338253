import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useEffect } from "react";
import { detail_with_call_type } from "src/DAL/Programme/Programme";
import { update_manage_call_types_api } from "src/DAL/consultant/consultant";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const get_full_name = (item) => {
  return item.first_name + " " + item.last_name + " (" + item.email + ")";
};

export default function CallTypeConfig() {
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [calTypeList, setCalltypeList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [delegateList, setDelegateList] = useState([]);
  const [memberDetail, setMemberDetail] = useState({});

  const getCallTypesList = async () => {
    setIsLoading(true);
    const result = await detail_with_call_type(params.id);
    if (result.code == 200) {
      setMemberDetail(result.delegate);
      let selected_array = [];
      let selected_delegate = result.delegate?.delgate_for_call_type;

      //formatting data as we needed for MUI Autocomplete
      if (selected_delegate?.length > 0) {
        selected_delegate.map((item) => {
          let delegates = item.delegates.map((selected_) => {
            //find delegates data like name and other info
            let find_delegate_info = result.delegate_for_call_type.find(
              (all_delegates) => all_delegates._id == selected_._id
            );
            return {
              ...find_delegate_info,
              full_name: get_full_name(find_delegate_info),
            };
          });

          selected_array.push({
            _id: item.call_type,
            delegates: delegates,
          });
        });
      }

      setSelected(selected_array);
      setCalltypeList(result.call_type);
      setDelegateList(
        result.delegate_for_call_type.map((item) => {
          let full_name = get_full_name(item);
          return {
            ...item,
            full_name,
          };
        })
      );
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async () => {
    setIsLoadingForm(true);
    let delgate_for_call_type = selected.map((item) => {
      return {
        delegates: item.delegates.map((delegate_user) => {
          return {
            _id: delegate_user._id,
          };
        }),
        call_type: item._id,
      };
    });
    let programObject = {
      delgate_for_call_type: delgate_for_call_type,
    };
    const result = await update_manage_call_types_api(params.id, programObject);
    if (result.code == 200) {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSelectDelegate = (value, row) => {
    let new_array = {
      ...row,
      delegates: value,
    };
    let find_item = selected.find((item) => item._id == row._id);
    if (!find_item) {
      setSelected((old) => [...old, new_array]);
    } else {
      setSelected((old) =>
        old.map((item) => {
          if (item._id == row._id) {
            return {
              ...item,
              delegates: value,
            };
          }
          return item;
        })
      );
    }
  };

  const TABLE_HEAD = [
    { id: "title", label: " Title", alignRight: false },
    {
      id: "title",
      label: "Delegate",
      renderData: (row) => {
        let values = [];
        if (selected) {
          find = selected.find((item) => item._id == row._id);
          if (find) {
            values = find?.delegates;
          }
        }

        return (
          <div>
            <MUIAutocomplete
              inputLabel="Delegate"
              selectedOption={values}
              setSelectedOption={(value) => {
                handleSelectDelegate(value, row);
              }}
              optionsList={delegateList}
              name="full_name"
              size="small"
              multiple
            />
          </div>
        );
      },
    },
    {
      id: "status",
      label: "Status",
      type: "row_status",
    },
  ];

  let breadCrumbMenu = [
    {
      title: "Delegates",
      navigation: -1,
      active: false,
    },
    {
      title: get_full_name(memberDetail),
      active: true,
    },
  ];

  useEffect(() => {
    getCallTypesList();
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row mb-4">
        <div className="col-12 mb-3">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
        <div>
          <h2>Call Type Configuration</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={calTypeList}
            pagePagination={true}
          />
        </div>
      </div>
      <div className="ms-auto text-end" id="fixedbutton">
        <button
          className="small-contained-button mt-3 ml-auto"
          onClick={handleSubmit}
        >
          {isLoadingForm ? "Updating..." : "Update"}
        </button>
      </div>
    </div>
  );
}
