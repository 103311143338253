import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import PageLoader from "src/components/GeneralComponents/PageLoader";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { s3baseUrl } from "src/config/config";
import {
  _delete_membership_level_badge,
  _list_membership_level_badge,
  Badge_level_List,
} from "src/DAL/MembershipLevel/MembershipLevelBadges/MembershipLevelBadges";

const TABLE_HEAD = [
  { id: "action", label: "Action", type: "action" },
  { id: "number", label: "#", type: "number" },
  {
    id: "table_avatar",
    label: "Icon",
    alignRight: false,
    type: "thumbnail",
  },
  {
    id: "table_avatar_image",
    label: "Image",
    alignRight: false,
    type: "thumbnail",
  },
  { id: "title", label: "Title" },
  { id: "general_order", label: "Order" },
  { id: "status", label: "Status", type: "row_status" },
];

export default function BadgeLevelList() {
  const navigate = useNavigate();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [membershipLevelBadgeList, setMembershipLevelBadgeList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);

  const [page, setPage] = useState(
    localStorage.getItem("badge_level_list_page")
      ? parseInt(localStorage.getItem("badge_level_list_page"), 10)
      : 0
  );

  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("level_badge_list_rowsPerPage")
      ? parseInt(localStorage.getItem("level_badge_list_rowsPerPage"))
      : 50
  );
  const [searchText, setSearchText] = useState(
    localStorage.getItem("badge_level_list_searchText")
      ? localStorage.getItem("badge_level_list_searchText")
      : ""
  );

  const handleNavigate = () => {
    navigate(`/badge-level/add-badge-level`);
  };

  const handleEdit = (value) => {
    navigate(
      `/badge-level/${value?.membership_level_info?._id}/edit-badge-level/${value?._id}`,
      {
        state: value,
      }
    );
  };

  const handleBadgeConfiguration = (value) => {
    navigate(
      `/badge-level/${value?.membership_level_info?._id}/badge-level-configuration/${value._id}`,
      {
        state: value,
      }
    );
  };
  const handleAssessmentSetting = (value) => {
    navigate(`/badge-level/${value._id}/assessment-setting`, {
      state: value,
    });
  };
  const handleBookCallSetting = (value) => {
    navigate(`/badge-level/${value._id}/book-call-popup-settings`, {
      state: value,
    });
  };
  const handleUpgradePopupSetting = (value) => {
    navigate(`/badge-level/${value._id}/upgrade-popup-settings`, {
      state: value,
    });
  };

  const handleFeedSetting = (value) => {
    navigate(`/badge-level/${value._id}/feed-setting`, {
      state: value,
    });
  };

  const handleMissionControlSetting = (value) => {
    navigate(`/badge-level/${value._id}/mission-control-content`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await _delete_membership_level_badge(deleteDoc?._id);
    if (result.code === 200) {
      let newDataList = membershipLevelBadgeList.filter(
        (data) => data._id !== deleteDoc._id
      );
      const data = newDataList.map((membership_level, index) => {
        return {
          ...membership_level,
          order: index + 1 + rowsPerPage * page,
        };
      });
      setMembershipLevelBadgeList(data);
      setTotalCount((totalCount) => totalCount - 1);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getMembershipLevelBadgeListing = async () => {
    setIsLoading(true);

    const result = await Badge_level_List(page, rowsPerPage, searchText);
    if (result.code === 200) {
      const data = result.membership_levels_badges.map((membership_level) => {
        return {
          ...membership_level,
          table_avatar: {
            src: s3baseUrl + membership_level?.icon?.thumbnail_1,
            alt: membership_level.title,
          },
          table_avatar_image: {
            src: s3baseUrl + membership_level?.image?.thumbnail_1,
            alt: membership_level.title,
          },
        };
      });
      setMembershipLevelBadgeList(data);
      setIsLoading(false);
      setTotalCount(result?.total_membership_level_badges_count);
      setTotalPages(result?.total_pages < 0 ? 0 : result?.total_pages);
      localStorage.setItem("badge_level_list_page", page);
      localStorage.setItem("level_badge_list_rowsPerPage", rowsPerPage);
    } else {
      setIsLoading(false);
    }
  };

  const searchFunction = (e) => {
    e.preventDefault();
    setPage(0);
    setPageCount(1);
    getMembershipLevelBadgeListing();
    localStorage.setItem("badge_level_list_searchText", searchText);
    if (searchText.length < 1) {
      localStorage.setItem("badge_level_list_searchText", "");
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Badge Level Configuration",
      icon: "akar-icons:edit",
      handleClick: handleBadgeConfiguration,
    },
    {
      label: "Attitude Assessment Setting",
      icon: "akar-icons:edit",
      handleClick: handleAssessmentSetting,
    },
    {
      label: "Manage Book A Call Popup Settings",
      icon: "akar-icons:edit",
      handleClick: handleBookCallSetting,
    },
    {
      label: "Manage Upgrade Popup settings",
      icon: "akar-icons:edit",
      handleClick: handleUpgradePopupSetting,
    },
    {
      label: "Feed Setting",
      icon: "akar-icons:edit",
      handleClick: handleFeedSetting,
    },
    {
      label: "Mission Control Content Settings",
      icon: "akar-icons:edit",
      handleClick: handleMissionControlSetting,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
      color: "#da3030",
    },
  ];

  useEffect(() => {
    getMembershipLevelBadgeListing();
  }, [rowsPerPage, page]);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <CustomConfirmation
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="container-fluid">
        <div className="row mb-3 mt-2">
          <div className="col-lg-4 col-sm-12">
            <h2>Badge Level</h2>
          </div>
          <div className="col-lg-8 col-sm-12 text-end d-flex justify-content-end">
            <div>
              <button
                onClick={handleNavigate}
                className="small-contained-button"
              >
                Add Badge Level
              </button>
            </div>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={membershipLevelBadgeList}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
      </div>
    </>
  );
}
