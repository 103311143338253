import { IconButton } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  manage_reader_access_for_admin,
  stripe_reader_listing,
} from "src/DAL/AdminUserApi/AdminUserApi";
import PageLoader from "src/components/GeneralComponents/PageLoader";

export default function ManageStripeReader() {
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [stripeList, setStripeList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [adminDetail, setAdminDetail] = useState({});

  const getStripList = async () => {
    setIsLoading(true);
    const result = await stripe_reader_listing(params.id);
    if (result.code == 200) {
      setAdminDetail(result.admin_user);
      setSelected(result.admin_user.stripe_reader);
      setStripeList(result.stripeReader);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async () => {
    setIsLoadingForm(true);
    let stripe_reader = [];
    console.log(stripe_reader, "stripe_reader");
    selected.map((item) => {
      var program_object = {
        _id: item._id,
      };
      stripe_reader.push(program_object);
    });
    let post_data = {
      admin_id: params.id,
      stripe_reader: stripe_reader,
    };
    const result = await manage_reader_access_for_admin(post_data);
    if (result.code == 200) {
      enqueueSnackbar(result.message, {
        variant: "success",
      });
      setIsLoadingForm(false);
    } else {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const TABLE_HEAD = [
    { id: "reader_title", label: "Reader Title", alignRight: false },
    { id: "reader_id", label: "Reader Id", alignRight: false },
    { id: "location_name", label: "Location Name", alignRight: false },
    { id: "location_id", label: "Location Id", alignRight: false },
    {
      id: "status",
      label: "Status",
      type: "row_status",
    },
  ];

  useEffect(() => {
    getStripList();
  }, []);

  if (isLoading == true) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row mb-4">
        <div className="col-12 mb-3 reply-anchor">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <span className="sale-page-title">
            {adminDetail.name + " (" + adminDetail.email + ")"}
          </span>
        </div>
        <div className="col-6">
          <h2>Manage Stripe Reader Access</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={stripeList}
            selected={selected}
            setSelected={setSelected}
            checkbox_selection={true}
            pagePagination={true}
          />
        </div>
      </div>
      <div className="ms-auto text-end" id="fixedbutton">
        <button
          className="small-contained-button mt-3 ml-auto"
          onClick={handleSubmit}
        >
          {isLoadingForm ? "Updating..." : "Update"}
        </button>
      </div>
    </div>
  );
}
