import React, { useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { get_root_value } from "src/utils/domUtils";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import { useState } from "react";

const getDateRange = (period) => {
  const today = new Date();
  let start_date, end_date;

  switch (period) {
    case "today":
      start_date = new Date();
      end_date = new Date();
      break;

    case "yesterday":
      start_date = new Date();
      start_date.setDate(today.getDate() - 1);
      end_date = new Date(start_date);
      break;

    case "this_week":
      start_date = new Date();
      start_date.setDate(today.getDate() - today.getDay());
      end_date = new Date();
      break;

    case "last_week":
      start_date = new Date();
      start_date.setDate(today.getDate() - today.getDay() - 7);
      end_date = new Date(start_date);
      end_date.setDate(start_date.getDate() + 6);
      break;

    case "last_7_days":
      start_date = new Date();
      start_date.setDate(today.getDate() - 7);
      end_date = new Date();
      end_date.setDate(today.getDate() - 1);
      break;

    case "last_28_days":
      start_date = new Date();
      start_date.setDate(today.getDate() - 28);
      end_date = new Date();
      end_date.setDate(today.getDate() - 1);
      break;

    case "last_30_days":
      start_date = new Date();
      start_date.setDate(today.getDate() - 30);
      end_date = new Date();
      end_date.setDate(today.getDate() - 1);
      break;

    case "last_90_days":
      start_date = new Date();
      start_date.setDate(today.getDate() - 90);
      end_date = new Date();
      end_date.setDate(today.getDate() - 1);
      break;

    case "last_12_months":
      start_date = new Date();
      start_date.setFullYear(today.getFullYear() - 1);
      end_date = new Date();
      end_date.setDate(today.getDate() - 1);
      break;

    case "last_calendar_year":
      start_date = new Date(today.getFullYear() - 1, 0, 1);
      end_date = new Date(today.getFullYear() - 1, 11, 31);
      break;

    case "this_year":
      start_date = new Date(today.getFullYear(), 0, 1);
      end_date = new Date();
      break;

    default:
      return { start_date: null, end_date: null };
  }

  return { start_date, end_date };
};

const menuItems = [
  { label: "Today", value: "today", dates: getDateRange("today") },
  { label: "Yesterday", value: "yesterday", dates: getDateRange("yesterday") },
  { label: "This week", value: "this_week", dates: getDateRange("this_week") },
  { label: "Last week", value: "last_week", dates: getDateRange("last_week") },
  {
    label: "Last 7 days",
    value: "last_7_days",
    dates: getDateRange("last_7_days"),
  },
  {
    label: "Last 28 days",
    value: "last_28_days",
    dates: getDateRange("last_28_days"),
  },
  {
    label: "Last 30 days",
    value: "last_30_days",
    dates: getDateRange("last_30_days"),
  },
  {
    label: "Last 90 days",
    value: "last_90_days",
    dates: getDateRange("last_90_days"),
  },
  {
    label: "Last 12 Months",
    value: "last_12_months",
    dates: getDateRange("last_12_months"),
  },
  {
    label: "Last calendar year",
    value: "last_calendar_year",
    dates: getDateRange("last_calendar_year"),
  },
  {
    label: "This year (Jan – Today)",
    value: "this_year",
    dates: getDateRange("this_year"),
  },
  {
    label: "Custom",
    value: "custom",
    dates: { start_date: null, end_date: null },
  }, // Custom should allow user input
];

const weekDays = [
  { label: "Sunday", value: 0 },
  { label: "Monday", value: 1 },
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
  { label: "Saturday", value: 6 },
];

export default function AnalyticsFilter({
  filterData,
  setFilterData,
  searchFunction,
  handleClearFilter,
}) {
  const [selectedStartDay, setSelectedStartDay] = useState(0);
  const [selectedEndDay, setSelectedEndDay] = useState(6);

  const getWeekDays = (range_type, day_type) => {
    if (range_type === "last_week") {
      if (day_type === "start") return weekDays;
      return weekDays.filter((day) => day.value >= selectedStartDay);
    }
    const today = new Date().getDay();
    if (day_type === "start") {
      return weekDays.filter((day) => day.value <= today);
    }
    return weekDays.filter(
      (day) => day.value >= selectedStartDay && day.value <= today
    );
  };

  const handleChange = (event) => {
    const { value } = event.target;
    let find_type = menuItems.find((item) => item.value === value);
    if (find_type) {
      const { dates } = find_type;
      setFilterData((old) => ({ ...old, ...dates, range_type: value }));
    }
  };

  const handleSelectOther = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const handleSelectedStartDay = (event) => {
    const { value } = event.target;
    setSelectedStartDay(value);
    let today = new Date();
    let start_date = new Date();
    start_date.setDate(today.getDate() - today.getDay());

    if (filterData.range_type === "last_week") {
      start_date.setDate(start_date.getDate() - 7);
    }

    start_date.setDate(start_date.getDate() + value);
    setFilterData((values) => ({ ...values, start_date: start_date }));
  };

  const handleSelectedEndDay = (event) => {
    const { value } = event.target;
    setSelectedEndDay(value);
    let today = new Date();
    let end_date = new Date();
    end_date.setDate(today.getDate() - today.getDay());

    if (filterData.range_type === "last_week") {
      end_date.setDate(end_date.getDate() - 7);
    }

    end_date.setDate(end_date.getDate() + value);
    setFilterData((values) => ({ ...values, end_date: end_date }));
  };

  useEffect(() => {
    setSelectedStartDay(0);
    setSelectedEndDay(getWeekDays(filterData.range_type).length - 1);
  }, [filterData.range_type]);

  return (
    <div className="container-fluid new-memories">
      <div className="row">
        <div className="col-12 mt-3">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Range Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="range_type"
              value={filterData.range_type}
              label="Range Type"
              onChange={handleChange}
            >
              {menuItems.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {(filterData.range_type === "this_week" ||
          filterData.range_type === "last_week") && (
          <>
            <div className="col-12 col-md-6 mt-3">
              <FormControl fullWidth>
                <InputLabel>Start Day</InputLabel>
                <Select
                  value={selectedStartDay}
                  label="Start Day"
                  onChange={handleSelectedStartDay}
                >
                  {getWeekDays(filterData.range_type, "start").map((day) => (
                    <MenuItem key={day.value} value={day.value}>
                      {day.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="col-12 col-md-6 mt-3">
              <FormControl fullWidth>
                <InputLabel>End Day</InputLabel>
                <Select
                  value={selectedEndDay}
                  label="End Day"
                  onChange={handleSelectedEndDay}
                >
                  {getWeekDays(filterData.range_type).map((day) => (
                    <MenuItem key={day.value} value={day.value}>
                      {day.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </>
        )}
        <div className="col-12 col-md-6 mt-3">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="DD-MM-YYYY"
              mask="__/__/____"
              inputFormat="DD-MM-YYYY"
              label="Start Date"
              name="start_date"
              value={filterData.start_date}
              onChange={(e) => handleSelectOther("start_date", e?.$d)}
              renderInput={(params) => (
                <TextField {...params} required={true} />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="col-12 col-md-6 mt-3">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="DD-MM-YYYY"
              mask="__/__/____"
              inputFormat="DD-MM-YYYY"
              label="End Date"
              name="end_date"
              value={filterData.end_date}
              onChange={(e) => handleSelectOther("end_date", e?.$d)}
              renderInput={(params) => (
                <TextField {...params} required={true} />
              )}
            />
          </LocalizationProvider>
        </div>
      </div>
      <Box sx={{ py: 2 }}>
        <Button
          fullWidth
          size="large"
          type="submit"
          color="inherit"
          variant="outlined"
          onClick={searchFunction}
          startIcon={
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: 5 }}
            >
              <path
                d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                fill={get_root_value("--portal-theme-primary")}
              />
            </svg>
          }
        >
          Filter
        </Button>
      </Box>
      <Button
        fullWidth
        size="large"
        type="submit"
        color="inherit"
        variant="outlined"
        className="mb-3"
        onClick={handleClearFilter}
        startIcon={<Icon icon={roundClearAll} />}
      >
        Clear All
      </Button>
    </div>
  );
}
