import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { add_users_by_csv } from "src/DAL/member/member";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import { useParams } from "react-router-dom";
import { api_date_format } from "src/utils/constant";

export default function ImportCsvPopup({ inputs, setOpen, setInputs }) {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { mission_id } = useParams();

  const handleChangeStatus = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("csv", inputs.csv);
    formData.append("quest_id", mission_id);
    formData.append("start_date", api_date_format(inputs.start_date));

    const result = await add_users_by_csv(formData, "quest");
    if (result.code == 200) {
      setIsLoading(false);
      setOpen(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);

      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleChangeStatus();
  };

  const handleChange = (e) => {
    setInputs((values) => ({ ...values, start_date: e.$d }));
  };

  return (
    <div className="container-fluid">
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-lg-12 col-md-12  col-sm-12 mt-4">
          <LocalizationProvider dateAdapter={AdapterDayjs} size="small">
            <DatePicker
              label="Start Date"
              format="DD-MM-YYYY"
              inputFormat="DD-MM-YYYY"
              value={inputs.start_date}
              onChange={handleChange}
              renderInput={(params) => (
                <TextField
                  size="small"
                  {...params}
                  className="mt-3 inputs-fields"
                  required
                />
              )}
            />
          </LocalizationProvider>
        </div>
        <div class="col-12 mt-3">
          <button class="small-contained-button w-100">
            {isLoading ? (
              "please Wait..."
            ) : (
              <span>
                Import Csv &nbsp;&nbsp; <CloudUploadIcon />
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
