import { invokeApi } from "../../bl_libs/invokeApi";

export const firebase_analytics_overview = async (data) => {
  const requestObj = {
    path: `/app/firebase/analytics_overview`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const firebase_analytics_events = async (data) => {
  const requestObj = {
    path: `/app/firebase/analytics`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const firebase_event_details = async (data) => {
  const requestObj = {
    path: `/app/firebase/event_details`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const get_analytics_dashboard = async () => {
  const requestObj = {
    path: `/app/firebase/analytics_dashboard`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const firebase_demographic_analytics = async (data) => {
  const requestObj = {
    path: `/app/firebase/demographic_analytics`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const firebase_page_and_screen_views = async (data) => {
  const requestObj = {
    path: `/app/firebase/pages_screens`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
