import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { s3baseUrl } from "src/config/config";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import {
  _Get_Mission_Control_Settings,
  _update_mission_control_settings,
} from "src/DAL/WebsitePages/WebsitePages";
import { uploadImageOns3 } from "src/DAL/commonApi/commonApi";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import { useSnackbar } from "notistack";
import { usePGIMode } from "src/Hooks/PGIModeContext";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const Input = styled("input")({
  display: "none",
});

const ManageMissionControlSettings = () => {
  const navigate = useNavigate();
  const { page_slug } = useParams();
  const { badgeLevels } = usePGIMode();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const { state } = location;
  const [file, setProfileImage] = useState();
  const [oldImage, setOldImage] = useState();
  const [loading, setLoading] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    title: "",
    show_in_mission_control: false,
    banner_image: "",
    button_text: "",
    short_description: "",
    detail_description: "",
    consider_already_purchased: "free",
    badge_level_id: [],
  });
  const is_website_pages = window.location.pathname.includes("website-pages");
  let route_name = "/template-pages";
  if (is_website_pages) {
    route_name = "/website-pages";
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const fileChangedHandler = async (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    formData.append("width", "1000");
    formData.append("height", "670");
    const imageUpload = await uploadImageOns3(formData);
    if (imageUpload.code == 200) {
      setInputs({
        ...inputs,
        ["banner_image"]: imageUpload.image_path,
      });
    } else {
      enqueueSnackbar(imageUpload.message, { variant: "error" });
    }
  };

  const getMissionControlDetails = async () => {
    setIsLoading(true);
    const result = await _Get_Mission_Control_Settings(page_slug);
    if (result.code === 200) {
      const { sale_page } = result;
      setIsLoading(false);
      setOldImage(sale_page?.mission_control_settings?.banner_image);
      setInputs(sale_page?.mission_control_settings);
    } else {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.show_in_mission_control) {
      if (!inputs.banner_image) {
        enqueueSnackbar("Banner Image Is Required", { variant: "error" });
        return;
      }
      if (!inputs.detail_description) {
        enqueueSnackbar("Detail Description Is Required", { variant: "error" });
        return;
      }
    }

    setLoading(true);
    const FormData = {
      mission_control_settings: {
        show_in_mission_control: inputs.show_in_mission_control,
        title: inputs.title,
        banner_image: inputs.banner_image,
        button_text: inputs.button_text,
        short_description: inputs.short_description,
        detail_description: inputs.detail_description,
        consider_already_purchased: inputs?.consider_already_purchased,
        badge_level_id: inputs?.badge_level_id,
      },
    };
    const response = await _update_mission_control_settings(
      FormData,
      page_slug
    );
    if (response.code === 200) {
      enqueueSnackbar(response.message, { variant: "success" });
      navigate(`${route_name}`);
      setLoading(false);
    } else {
      setLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  let breadCrumbMenu = [
    {
      title: is_website_pages ? "Pages" : "Template Pages",
      navigation: route_name,
      active: false,
    },
    {
      title: `Mission Control Settings (${page_slug})`,
      active: true,
    },
  ];

  useEffect(() => {
    if (state) {
      setOldImage(state?.mission_control_settings?.banner_image);
      setInputs(state?.mission_control_settings);
    } else {
      getMissionControlDetails();
    }
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 mb-3">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
        <div className="col-12">
          <h2>Mission Control Setting</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Do You Want To show in Mission ?
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="show_in_mission_control"
                value={inputs?.show_in_mission_control || false}
                label="Do You Want To show in Mission ?"
                onChange={handleChange}
              >
                <MenuItem value={false}>No</MenuItem>
                <MenuItem value={true}>Yes</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs?.show_in_mission_control && (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  type="text"
                  id="outlined-basic"
                  label="Title"
                  variant="outlined"
                  fullWidth
                  name="title"
                  required={inputs?.show_in_mission_control ? true : false}
                  value={inputs.title}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Button Text"
                  variant="outlined"
                  fullWidth
                  name="button_text"
                  required={inputs?.show_in_mission_control ? true : false}
                  type="text"
                  value={inputs.button_text}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">
                    Consider Already Purchased ?
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="consider_already_purchased"
                    value={inputs?.consider_already_purchased}
                    label="Consider Already Purchased ?"
                    onChange={handleChange}
                  >
                    <MenuItem value={"free"}>Free</MenuItem>
                    <MenuItem value={"paid"}>Paid</MenuItem>
                    <MenuItem value={"both"}>Both</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">
                    Badge Level
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="badge_level_id"
                    value={inputs?.badge_level_id || []}
                    multiple
                    label="Badge Level"
                    onChange={(event) => {
                      const {
                        target: { value },
                      } = event;
                      handleChange({
                        target: {
                          name: "badge_level_id",
                          value:
                            typeof value === "string"
                              ? value.split(",")
                              : value,
                        },
                      });
                    }}
                    renderValue={(selected) =>
                      badgeLevels
                        .filter((level) => selected.includes(level?._id))
                        .map((level) => level?.title)
                        .join(", ")
                    }
                  >
                    {badgeLevels.map((level, index) => (
                      <MenuItem key={index} value={level?._id}>
                        {level?.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                <div className="row w-100 div-style ms-0 pt-0">
                  <div className="col-5">
                    <p className="">Banner Image* </p>
                    <FormHelperText className="pt-0">
                      Banner Image Size(1000 X 670) ("JPG", "JPEG",
                      "PNG","WEBP")
                    </FormHelperText>
                  </div>
                  <div className="col-3 pt-3">
                    {file ? (
                      <img src={file} width={50} />
                    ) : (
                      oldImage && <img src={s3baseUrl + oldImage} width={50} />
                    )}
                  </div>
                  <div className="col-4 text-end pt-2">
                    <label htmlFor="contained-button-file">
                      <Input
                        accept="image/*"
                        id="contained-button-file"
                        multiple
                        type="file"
                        name="banner_image"
                        onChange={fileChangedHandler}
                      />

                      <Button
                        className="small-contained-button"
                        startIcon={<FileUploadIcon />}
                        component="span"
                      >
                        Upload
                      </Button>
                    </label>
                  </div>
                </div>
                {inputs?.plan_image == "" ? (
                  ""
                ) : (
                  <p className="text-secondary">{inputs?.plan_image}</p>
                )}
              </div>
              <div className="col-12 mt-4">
                <TextField
                  type="text"
                  id="outlined-basic"
                  label="Short Description"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={6}
                  name="short_description"
                  required={inputs?.show_in_mission_control ? true : false}
                  value={inputs.short_description}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 mt-5">
                <h4>Detail Description:</h4>
                <GeneralCkeditor
                  setInputs={setInputs}
                  inputs={inputs}
                  name="detail_description"
                  editorHeight={320}
                />
              </div>
            </>
          )}
          <div className="text-end mt-4" id="fixedbutton">
            <Button type="submit" className="small-contained-button">
              {loading ? "Please Wait..." : "Submit"}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ManageMissionControlSettings;
