import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { useEffect } from "react";
import { uploadImageOns3 } from "src/DAL/commonApi/commonApi";
import { styled } from "@mui/material/styles";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { s3baseUrl } from "src/config/config";
import {
  _get_badge_level_setting,
  _update_badge_level_settings,
} from "src/DAL/AttitudeAccessmentQuestions/AttitudeAccessmentSetting";
import { useParams } from "react-router-dom";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const Input = styled("input")({
  display: "none",
});

export default function BadgeLevelMissionControlContent() {
  const { badge_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [badgeTitle, setBadgeTitle] = useState("");

  const [inputs, setInputs] = useState({
    video_url: "",
    button_text: "",
    button_url: "",
    description: "",
  });

  const getFeedSetting = async () => {
    setIsLoading(true);
    const result = await _get_badge_level_setting(
      badge_id,
      "mission_control_content"
    );
    if (result.code == 200) {
      let content_settings =
        result.membership_level_badge.mission_control_content;
      setBadgeTitle(result.membership_level_badge.title);
      if (content_settings) {
        setInputs(content_settings);
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);

    const formData = {
      settings: inputs,
      type: "mission_control_content",
    };

    const result = await _update_badge_level_settings(formData, badge_id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  let breadCrumbMenu = [
    {
      title: `Badge Level (${badgeTitle})`,
      navigation: "/badge-level",
      active: false,
    },
    {
      title: "Mission Control Content Setting",
      active: true,
    },
  ];

  useEffect(() => {
    getFeedSetting();
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin">
        <div className="col-12">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
      </div>
      <form className="row mt-4" onSubmit={handleSubmit}>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
          <TextField
            id="outlined-basic"
            label="Video URL"
            variant="outlined"
            type="url"
            fullWidth
            required
            name="video_url"
            value={inputs.video_url}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
          <TextField
            id="outlined-basic"
            label="Button Text"
            variant="outlined"
            fullWidth
            name="button_text"
            value={inputs.button_text}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
          <TextField
            id="outlined-basic"
            label="Button URL"
            variant="outlined"
            type="url"
            fullWidth
            name="button_url"
            value={inputs.button_url}
            onChange={handleChange}
          />
        </div>

        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <h4>Description</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="description"
            editorHeight={320}
          />
        </div>
        <div className="text-end mt-4" id="fixedbutton">
          <button className="small-contained-button">
            {isLoadingForm ? "Updating..." : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
