import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";

import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {
  ClickFunnelsSettingApi,
  GetFunnelsApi,
  GetSageSettingsApi,
  GetshopApi,
  SageSettingApi,
  shopSettingApi,
} from "src/DAL/SiteSetting/siteSetting";
import { useEffect } from "react";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function SageSettings() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [inputs, setInputs] = useState({
    client_id: "",
    client_secret: "",
    refresh_token: "",
    ledger_account_id: "",
    enable_sage_invoice: true,
  });
  const GetwhatappApiList = async () => {
    setIsLoading(true);
    const result = await GetSageSettingsApi();
    if (result.code == 200) {
      setInputs(result?.sage_settings);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);
    let data = inputs;
    let postData = {
      sage_settings: data,
    };
    const result = await SageSettingApi(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    GetwhatappApiList();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-6">
          <h2>Sage Settings</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Enable Sage Invoice
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={inputs?.enable_sage_invoice}
                name="enable_sage_invoice"
                label="Enable Sage Invoice"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>;
                <MenuItem value={false}>No</MenuItem>;
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Client ID"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              type="text"
              name="client_id"
              value={inputs?.client_id}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Client Secret"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              name="client_secret"
              value={inputs?.client_secret}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Refresh Token"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              name="refresh_token"
              value={inputs?.refresh_token}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Ledger Account ID"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              name="ledger_account_id"
              value={inputs?.ledger_account_id}
              onChange={handleChange}
            />
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">
              {isLoadingForm ? "Updating" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
