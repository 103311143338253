import { useEffect, useState } from "react";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import moment from "moment";
import { Icon } from "@iconify/react";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import FilteredChip from "src/components/FilteredChip";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import GeneralRequestForCsv from "../MembersList/GeneralRequestForCsv";
import arrowCircleDownFill from "@iconify/icons-eva/arrow-circle-down-fill";
import PageLoader from "src/components/GeneralComponents/PageLoader";
import { useNavigate } from "react-router-dom";

const subcribers_csv = [
  {
    label: "First Name",
    value: "first_name",
    is_checked: true,
    is_disabled: true,
  },
  {
    label: "Last Name",
    value: "last_name",
    is_checked: true,
    is_disabled: true,
  },
  { label: "Email", value: "email", is_checked: true, is_disabled: true },
  {
    label: "Contact Number",
    value: "contact_number",
    is_checked: true,
    is_disabled: true,
  },
  { label: "Plan_title", value: "plan_title", is_checked: false },
  { label: "Page_title", value: "page_title", is_checked: false },
  { label: "Referral_user", value: "referral_user", is_checked: false },
  { label: "aggrement_pdf_url", value: "aggrement_pdf_url", is_checked: false },
  {
    label: "Subscription_date",
    value: "subscription_date",
    is_checked: false,
  },
];

export default function MemberSubscribersHistory({
  tabData,
  searchFunction,
  searchText,
  setSearchText,
  pageCount,
  totalPages,
  handleChangePages,
  custom_pagination,
  handleOpenFilterDrawer,
  filterStateUpdated,
  filterData,
  EMPTY_FILTER,
  ALTER_FILTER,
  handleDeleteChip,
  handleClearFilter,
  filterQuery,
}) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionsData, setSubscriptionsData] = useState([]);
  const [showExportcsvFile, setShowExportcsvFile] = useState(false);

  const moveToMemberProfile = (value) => {
    navigate(`/member/member-profile/${value?.member}`, {
      state: {
        ...value,
        move_from_page_title: "History",
        move_from_page_path: "/history",
      },
    });
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "Member",
      label: "Member",
      renderData: (row) => {
        return (
          <p
            className="mb-0"
            style={{ cursor: "pointer" }}
            onClick={() => moveToMemberProfile(row)}
          >
            {row?.Member ? row?.Member : "N/A"}
          </p>
        );
      },
    },
    { id: "page_name", label: "Page Title" },
    { id: "plan_title", label: "Plan Title" },
    { id: "affiliate_name", label: "Referral" },
    {
      id: "preview_link_data",
      label: "Agreement PDF",
      type: "link",
    },
    {
      id: "created_at",
      label: "Subscription Date",
      renderData: (data) => {
        return (
          <span>{moment(data.createdAt).format("DD-MM-yyyy hh:mm A")}</span>
        );
      },
    },
    {
      id: "register_url",
      label: "Register Link",
    },
  ];

  const showPopUPcsv = () => {
    setShowExportcsvFile(true);
  };
  console.log(tabData, "tabDatatabDatatabData");

  const getMembersList = () => {
    setIsLoading(true);
    const members = tabData?.map((event, index) => {
      let event_plan = event.plan_info;
      let plan_title = "N/A";
      let created_at = "N/A";
      let affiliate_name = "N/A";
      let Member = "N/A";
      if (event.member_info) {
        Member =
          event.member_info.first_name +
          " " +
          event.member_info.last_name +
          " " +
          "(" +
          event.member_info.email +
          ")";
      }
      if (event_plan?.plan_title) {
        plan_title = event_plan?.plan_title;
      }
      let payment_access = event_plan?.payment_access;
      if (plan_title != "N/A") {
        if (event_plan?.is_plan_free) {
          plan_title = plan_title + " (Free)";
        } else if (payment_access == "recursion") {
          plan_title = plan_title + " (Recurring)";
        } else {
          plan_title = plan_title + " (OneTime)";
        }
      }
      let affiliate = event.affiliate_info?.affiliate_user_info;
      if (affiliate && affiliate.first_name) {
        affiliate_name = affiliate.first_name + " " + affiliate.last_name;
      }

      if (event.createdAt) {
        created_at = moment.utc(event.createdAt).format("DD-MM-YYYY");
      }

      return {
        ...event,
        Member: Member,
        page_name: event.page_info?.sale_page_title,
        created_at,
        plan_title: plan_title,
        affiliate_name: affiliate_name,
        preview_link_data: {
          to: s3baseUrl + event.aggrement_pdf_url,
          target: "_blank",
          show_text: event.aggrement_pdf_url ? "Preview" : "",
          className: "anchor-style",
        },
      };
    });
    setSubscriptionsData(members);
    setIsLoading(false);
  };

  useEffect(() => {
    getMembersList();
  }, [tabData]);

  if (isLoading == true) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="mt-3">
        <div className="button-history">
          <div className="d-flex justify-content-end mb-3 page-subscribers-filter">
            <button
              className="small-contained-button me-2"
              onClick={showPopUPcsv}
            >
              Request For Csv &nbsp;&nbsp;{" "}
              <Icon icon={arrowCircleDownFill} height={20} />
            </button>
            <button
              className="small-contained-button me-2"
              onClick={handleOpenFilterDrawer}
            >
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>
          </div>
        </div>
        <FilteredChip
          data={filterStateUpdated}
          tempState={filterData}
          EMPTY_FILTER={EMPTY_FILTER}
          // ALTER_FILTER={ALTER_FILTER}
          onDeleteChip={handleDeleteChip}
          onClear={handleClearFilter}
        />
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={subscriptionsData}
          className="card-with-background"
          custom_pagination={custom_pagination}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
        <GeneralPopUpModel
          open={showExportcsvFile}
          setOpen={setShowExportcsvFile}
          title={"Export CSV file request  "}
          componentToPassDown={
            <GeneralRequestForCsv
              options_array={subcribers_csv}
              collection_name={"subscribers_history"}
              filter_data={filterQuery}
              setShowExportcsvFile={setShowExportcsvFile}
            />
          }
        />
      </div>
    </>
  );
}
