import { useEffect, useState } from "react";
import { s3baseUrl } from "src/config/config";
import {
  get_nav_item_details_api,
  update_nav_items_access,
} from "src/DAL/Menu/Menu";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import DoneIcon from "@mui/icons-material/Done";
import { Icon } from "@iconify/react";
import RecordNotFound from "src/components/RecordNotFound";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const ManageNavItemsAccess = ({ type, title, navigate }) => {
  const { user_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [userData, setUserData] = useState({});
  const [navItems, setNavItems] = useState([]);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [selected, setSelected] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const handleSelect = (value, child_option) => {
    let new_value = { item_id: value._id };
    if (child_option) {
      new_value.child_options = [{ item_id: child_option._id }];
    }

    let find = selected.find((item) => item.item_id == value._id);
    if (find) {
      if (!child_option && value.child_options) {
        setSelected((old) => old.filter((item) => item.item_id !== value._id));
      } else {
        if (find.child_options) {
          let find_child = find.child_options.find(
            (find_c) => find_c.item_id == child_option._id
          );
          let child_options = find.child_options;
          if (find_child) {
            child_options = find.child_options.filter(
              (remove) => remove.item_id !== child_option._id
            );
          } else {
            child_options = [...child_options, { item_id: child_option._id }];
          }
          find.child_options = child_options;
          if (find.child_options.length > 0) {
            setSelected((old) =>
              old.map((item) => {
                if (item.item_id == find.item_id) {
                  return find;
                }
                return item;
              })
            );
          } else {
            setSelected((old) =>
              old.filter((item) => item.item_id !== value._id)
            );
          }
        } else {
          setSelected((old) =>
            old.filter((item) => item.item_id !== value._id)
          );
        }
      }
    } else {
      if (!child_option && value.child_options) {
        let child_options = value.child_options.map((c_option) => {
          return {
            item_id: c_option._id,
          };
        });
        new_value.child_options = child_options;
      }
      setSelected([...selected, new_value]);
    }
  };

  const handleSubmit = async () => {
    setIsLoadingButton(true);
    let postData = {
      nav_items: selected,
      type: type,
    };
    const result = await update_nav_items_access(user_id, postData);
    if (result.code == 200) {
      setIsLoadingButton(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoadingButton(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleToggle = (index) => {
    setNavItems((old) =>
      old.map((item, i) => {
        if (i == index) {
          item.is_expanded = !item.is_expanded;
        }
        return item;
      })
    );
  };

  const checkAllItemsExist = (data1, data2) => {
    let is_selected_all = true;
    data2.forEach((item) => {
      let find_p = data1.find((p) => p.item_id == item._id);
      if (!find_p) {
        is_selected_all = false;
      } else {
        if (find_p.child_options?.length > 0) {
          let find_c = item.child_options.every((co) =>
            find_p.child_options.some((c) => co._id === c.item_id)
          );
          if (!find_c) {
            is_selected_all = false;
          }
        }
      }
    });
    return is_selected_all;
  };
  const GetNavList = async () => {
    setIsLoading(true);
    let postData = {
      type: type,
    };
    const result = await get_nav_item_details_api(user_id, postData);
    if (result.code == 200) {
      setSelected(result.assigned_nav_items);
      setNavItems(result.default_setting_nav_items);
      setUserData(result.user_details);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const getNavItemsList = (query) => {
    let array = navItems;
    if (query) {
      const _nav_list = array
        .map((data) => {
          const is_main =
            data.title.toLowerCase().indexOf(query.toLowerCase()) !== -1;
          const match_child = data.child_options?.filter(
            (item) =>
              item.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
          );

          if (is_main) {
            return data;
          } else if (match_child?.length > 0) {
            return {
              ...data,
              child_options: match_child,
            };
          }
          return null;
        })
        .filter(Boolean);
      return _nav_list;
    }

    return array;
  };

  const handleCheckAll = (value) => {
    let updated_array = [];
    if (value) {
      updated_array = navItems.map((item) => {
        let data_object = { item_id: item._id };
        if (item.child_options?.length > 0) {
          let child_options = item.child_options.map((child) => {
            return {
              item_id: child._id,
            };
          });
          data_object.child_options = child_options;
        }
        return data_object;
      });
    }
    setSelected(updated_array);
  };

  useEffect(() => {
    GetNavList();
  }, []);

  useEffect(() => {
    setNavItems((old) =>
      old.map((item) => {
        return { ...item, is_expanded: true };
      })
    );
  }, [searchInput]);

  useEffect(() => {
    const is_checked = checkAllItemsExist(selected, navItems);
    setIsCheckedAll(is_checked);
  }, [selected, navItems]);

  let breadCrumbMenu = [
    {
      title: title,
      navigation: -1,
      active: false,
    },
    {
      title: "Manage Templates Access",
      title: userData
        ? userData?.first_name +
          " " +
          userData?.last_name +
          " (" +
          userData?.email +
          ")"
        : "Manage Templates Access",
      active: true,
    },
  ];

  if (isLoading === true) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex  mb-2">
        <div className="col-12 mb-3">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
        <div className="col-12">
          <h2>Manage Nav Items Access</h2>
        </div>
        <div className="col-12 col-md-9 mt-3">
          <div
            className="select-all-menus"
            onClick={() => handleCheckAll(!isCheckedAll)}
          >
            <div
              className={`select-menu-checkbox ps-3 mt-1 ${
                isCheckedAll ? "selected" : ""
              }`}
            >
              {isCheckedAll && (
                <span className="select-menu-tick">
                  <DoneIcon />
                </span>
              )}
            </div>
            <div className="ms-2">Select All</div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="menus-search-box">
            <Icon
              fontSize={20}
              className="menus-search-icon"
              icon="akar-icons:search"
            />
            <input
              className="menus-search-input"
              type="text"
              placeholder="Search"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
        </div>
      </div>

      {getNavItemsList(searchInput).length > 0 ? (
        getNavItemsList(searchInput).map((item, index) => {
          let is_checked = selected.find(
            (option) => option.item_id == item._id
          );
          return (
            <div className="card mt-2">
              <div className="main row ps-3 align-items-center">
                <div className="col-1">
                  <div
                    className={`select-menu-checkbox m-auto ${
                      is_checked ? "selected" : ""
                    }`}
                    onClick={() => {
                      handleSelect(item);
                    }}
                  >
                    {is_checked && (
                      <span className="select-menu-tick">
                        <DoneIcon />
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-7 col-md-3 mt-3 mb-3">
                  <div className="static-textfield">
                    {item?.title && (
                      <div className="static-textfield-label px-1">Title</div>
                    )}
                    <div className="field-value">
                      {item?.title ? (
                        item?.title
                      ) : (
                        <span className="disabled_label">Title</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-none d-lg-block col-lg-3 mt-3 mb-3 ps-0">
                  {(!item.child_options || item.child_options?.length < 1) && (
                    <div className="static-textfield">
                      <div className="static-textfield-label px-1">Is Link</div>
                      <div className="field-value">
                        {item?.is_link ? "Yes" : "No"}
                      </div>
                      <div className="dropdown-arrow">
                        <ArrowDropDownIcon />
                      </div>
                    </div>
                  )}
                </div>
                <div className="d-none d-lg-block col-lg-3 mt-3 mb-3 ps-0">
                  {(!item.child_options || item.child_options?.length < 1) && (
                    <div className="static-textfield">
                      <div className="static-textfield-label px-1">
                        Is Open New Tab
                      </div>
                      <div className="field-value">
                        {item?.is_open_new_tab ? "Yes" : "No"}
                      </div>
                      <div className="dropdown-arrow">
                        <ArrowDropDownIcon />
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-2 col-lg-1 mb-3 navitems">
                  {item?.icon && (
                    <div className="preview">
                      <img
                        src={s3baseUrl + item?.icon}
                        style={{
                          padding: "10px",
                          width: "120px",
                          height: "auto",
                          maxHeight: "110px",
                          cursor: "pointer",
                        }}
                        className="main"
                      />
                    </div>
                  )}
                </div>
                <div className="col-2 col-lg-1">
                  {item.child_options?.length > 0 && (
                    <div
                      onClick={() => {
                        handleToggle(index);
                      }}
                      className="cursor-pointer"
                    >
                      {item.is_expanded ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </div>
                  )}
                </div>
              </div>
              {item.child_options?.length > 0 &&
                item.is_expanded &&
                item.child_options.map((child_option, child_index) => {
                  let is_checked_c = null;
                  if (is_checked?.child_options) {
                    is_checked_c = is_checked?.child_options.find(
                      (c) => c.item_id == child_option._id
                    );
                  }
                  return (
                    <div className="main row ps-5 align-items-center">
                      <div className="col-1">
                        <div
                          onClick={() => {
                            handleSelect(item, child_option);
                          }}
                          className={`select-menu-checkbox m-auto ${
                            is_checked_c ? "selected" : ""
                          }`}
                        >
                          {is_checked_c && (
                            <span className="select-menu-tick">
                              <DoneIcon />
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-8 col-md-3 mt-3 mb-3 mt-3 mb-3">
                        <div className="static-textfield">
                          {child_option?.title && (
                            <div className="static-textfield-label px-1">
                              Title
                            </div>
                          )}
                          <div className="field-value">
                            {child_option?.title ? (
                              child_option?.title
                            ) : (
                              <span className="disabled_label">Title</span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="d-none d-lg-block col-lg-3 mt-3 mb-3 ps-0">
                        <div className="static-textfield">
                          <div className="static-textfield-label px-1">
                            Is Link
                          </div>
                          <div className="field-value">
                            {child_option?.is_link ? "Yes" : "No"}
                          </div>
                          <div className="dropdown-arrow">
                            <ArrowDropDownIcon />
                          </div>
                        </div>
                      </div>
                      <div className="d-none d-lg-block col-lg-3 mt-3 mb-3 ps-0">
                        <div className="static-textfield">
                          <div className="static-textfield-label px-1">
                            Is Open New Tab
                          </div>
                          <div className="field-value">
                            {child_option?.is_open_new_tab ? "Yes" : "No"}
                          </div>
                          <div className="dropdown-arrow">
                            <ArrowDropDownIcon />
                          </div>
                        </div>
                      </div>
                      <div className="col-2 col-lg-1 mb-3 ms-2 navitems">
                        {child_option?.icon && (
                          <div className="preview">
                            <img
                              src={s3baseUrl + child_option?.icon}
                              style={{
                                padding: "10px",
                                width: "120px",
                                height: "auto",
                                maxHeight: "110px",
                                cursor: "pointer",
                              }}
                              className="main"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          );
        })
      ) : (
        <RecordNotFound title="Data" />
      )}

      <div className="text-end mt-4" id="fixedbutton">
        <button onClick={handleSubmit} className="small-contained-button">
          {isLoadingButton ? "Submitting..." : "Submit"}
        </button>
      </div>
    </div>
  );
};

export default ManageNavItemsAccess;
