import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// material
import { useSnackbar } from "notistack";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import {
  _study_session_recording_listing_v2_api,
  DeleteStudySessionRecordingApi,
} from "src/DAL/studySession/studySessionApi";
import PageLoader from "src/components/GeneralComponents/PageLoader";
import { dd_date_format } from "src/utils/constant";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import FilteredChip from "src/components/FilteredChip";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { Icon } from "@iconify/react";
import StudySessionFilter from "./StudySessionFilter";

const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false, type: "action" },
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "title", label: "Title", alignRight: false },
  {
    id: "table_avatar",
    label: "Image",
    alignRight: false,
    type: "thumbnail",
  },
  { id: "study_session_title", label: "Study Session", alignRight: false },
  { id: "status", label: "Status", alignRight: false, type: "row_status" },
  { id: "created_at", label: "Created At", alignRight: false },
];

const EMPTY_FILTER = {
  study_sessions: [],
  sort_by: "newest",
};

export default function SessionsRecordingsList() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [recordingData, setRecordingData] = useState([]);
  const [deleteDoc, setDeleteDoc] = useState("");
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [selectedSession, setSelectedSession] = useState(EMPTY_FILTER);
  const [updateSessionsData, setUpdateSessionsData] = useState(EMPTY_FILTER);

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const getRecordingListing = async (data) => {
    setIsLoading(true);
    let postData = {
      ...data,
    };

    const result = await _study_session_recording_listing_v2_api(postData);
    if (result.code == 200) {
      const recordings = result.study_session_recording.map((recording) => {
        return {
          ...recording,
          study_session_title: recording.study_session?.title
            ? recording.study_session?.title
            : "N/A",
          table_avatar: {
            src: s3baseUrl + recording.session_recording_images.thumbnail_2,
            alt: recording.title,
          },
          created_at: dd_date_format(recording?.createdAt),
        };
      });

      setRecordingData(recordings);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const handleEdit = (value) => {
    navigate(
      `/session-recording/edit-study-session-recording/${value.study_session_recording_slug}`,
      {
        state: value,
      }
    );
  };

  const searchFunction = () => {
    handleCloseFilterDrawer();
    getRecordingListing(selectedSession);
    setUpdateSessionsData(selectedSession);
    localStorage.setItem("sessions_data", JSON.stringify(selectedSession));
  };

  const handleDeleteChip = (data) => {
    setSelectedSession(data);
    getRecordingListing(data);
    setUpdateSessionsData(data);
    localStorage.setItem("sessions_data", JSON.stringify(data));
  };

  const handleClearFilter = () => {
    setSelectedSession(EMPTY_FILTER);
    setUpdateSessionsData(EMPTY_FILTER);
    getRecordingListing(EMPTY_FILTER);
    handleCloseFilterDrawer();
    localStorage.removeItem("sessions_data");
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await DeleteStudySessionRecordingApi(
      deleteDoc.study_session_recording_slug
    );
    if (result.code === 200) {
      setRecordingData((prev) => {
        return prev.filter(
          (data) =>
            data.study_session_recording_slug !==
            deleteDoc.study_session_recording_slug
        );
      });
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("sessions_data");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
    }
    setSelectedSession(filter_data);
    setUpdateSessionsData(filter_data);
    getRecordingListing(filter_data);
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 mb-3">
            <h2>Study Sessions Recording</h2>
          </div>
          <div className="col-md-6 mb-3 text-end">
            <button
              className="small-contained-button mt-1"
              onClick={() =>
                navigate(`/session-recording/add-study-session-recording`)
              }
            >
              Add Study Sessions Recording
            </button>
            <button
              className="small-contained-button ms-2 mt-1 mb-4"
              onClick={handleOpenFilterDrawer}
            >
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>
          </div>
        </div>

        <div className="mt-3">
          <FilteredChip
            data={updateSessionsData}
            tempState={selectedSession}
            EMPTY_FILTER={EMPTY_FILTER}
            onDeleteChip={handleDeleteChip}
            onClear={handleClearFilter}
          />

          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            MENU_OPTIONS={MENU_OPTIONS}
            data={recordingData}
            className="card-with-background"
            pagePagination={true}
          />
        </div>
      </div>

      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <StudySessionFilter
            selectedSession={selectedSession}
            setSelectedSession={setSelectedSession}
            searchFunction={searchFunction}
            handleClearFilter={handleClearFilter}
          />
        }
      />
    </>
  );
}
