import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {
  BookCallPopUpContentSetting,
  UpdatePopUpContentSetting,
  UpdatePopUpContentSettingOnly,
  UpdateShopContentSetting,
} from "src/DAL/ClientSetting/ClientSetting";
import { useEffect } from "react";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import {
  _get_attitude_accessment_setting,
  _get_book_a_call_popup_settings,
  _get_mission_popup_settings,
  _get_store_house_setting,
} from "src/DAL/AttitudeAccessmentQuestions/AttitudeAccessmentSetting";
import { project_mode } from "src/config/config";
import { usePGIMode } from "src/Hooks/PGIModeContext";
import { isValidObject } from "src/utils/constant";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { _get_book_a_call_sale_pages } from "src/DAL/MissionApi/MissionApiNew";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const book_a_call_popup = {
  is_show_popup: false,
  description: "",
  book_a_call_page: null,
  book_call_button_text: "",
  show_after_times_portal_opens: 0,
  show_to_members: "all",
};

export default function BookCallUpgradePopupSettings() {
  const classes = useStyles();
  const { badge_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [badgeTitle, setBadgeTitle] = useState("");
  const [salePageList, setSalePageList] = useState([]);
  const [searchText, setSearchText] = useState("");

  const [inputs, setInputs] = React.useState({
    mission_book_call_popup_setting: book_a_call_popup,
    book_a_call_description: "",
    upgrade_popup_description: "",
  });

  const clientDetail = async () => {
    setIsLoading(true);
    const result = await _get_book_a_call_popup_settings(badge_id);
    if (result.code == 200) {
      setBadgeTitle(result?.membership_level_badge?.title);
      setInputs((prevValues) => ({
        ...prevValues,
        book_a_call_description:
          result?.membership_level_badge?.mission_book_call_popup_setting
            ?.description,
        mission_book_call_popup_setting: isValidObject(
          result?.membership_level_badge?.mission_book_call_popup_setting
        )
          ? result?.membership_level_badge?.mission_book_call_popup_setting
          : book_a_call_popup,
      }));
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getSalePageDetail = async () => {
    const result = await _get_book_a_call_sale_pages({
      search: searchText,
    });
    if (result.code === 200) {
      setSalePageList(result?.sale_pages);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);
    if (
      inputs?.book_a_call_description?.is_show_popup &&
      !inputs.book_a_call_description
    ) {
      enqueueSnackbar("book call popup description is required", {
        variant: "error",
      });
      return;
    }

    let postData = {
      settings: inputs?.mission_book_call_popup_setting,
      type: "book_a_call_popup_settings",
    };
    if (inputs?.mission_book_call_popup_setting?.is_show_popup) {
      postData.settings.description = inputs?.book_a_call_description;
    }
    const result = await UpdatePopUpContentSettingOnly(postData, badge_id);
    if (result.code === 200) {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };
  const handleChangeOthers = (event, name) => {
    setInputs((prevValues) => ({
      ...prevValues,
      mission_book_call_popup_setting: {
        ...prevValues.mission_book_call_popup_setting,
        [name]: event,
      },
    }));
  };

  const handleChange = (event, object) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((prevValues) => ({
      ...prevValues,
      mission_book_call_popup_setting: {
        ...prevValues.mission_book_call_popup_setting,
        [name]: value,
      },
    }));
  };

  useEffect(() => {
    clientDetail();
    getSalePageDetail();
  }, []);

  let breadCrumbMenu = [
    {
      title: `Badge Level (${badgeTitle})`,
      navigation: "/badge-level",
      active: false,
    },
    {
      title: "Book Call Popup Setting",
      active: true,
    },
  ];
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getSalePageDetail();
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [searchText]);
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin">
        <div className="col-12">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div class="row wheel-of-life-setting-section-heading">
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">
                  Is Show Popup
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="is_show_popup"
                  value={inputs?.mission_book_call_popup_setting?.is_show_popup}
                  label="Is Show Popup"
                  onChange={(e) =>
                    handleChange(e, "mission_book_call_popup_setting")
                  }
                  required
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </div>
            {inputs?.mission_book_call_popup_setting?.is_show_popup && (
              <>
                <div className="col-6 mt-4">
                  <TextField
                    className="text-muted"
                    id="outlined-basic"
                    label="App/Portal Open Count for Popup"
                    variant="outlined"
                    fullWidth
                    type="number"
                    inputProps={{
                      min: 0,
                    }}
                    name="show_after_times_portal_opens"
                    onChange={(e) =>
                      handleChange(e, "show_after_times_portal_opens")
                    }
                    value={
                      inputs?.mission_book_call_popup_setting
                        ?.show_after_times_portal_opens
                    }
                    required
                  />
                </div>
                <div className="col-6 mt-4">
                  <MUIAutocomplete
                    inputLabel="Book A Call Page"
                    selectedOption={
                      inputs?.mission_book_call_popup_setting?.book_a_call_page
                    }
                    setSelectedOption={(e) => {
                      handleChangeOthers(e, "book_a_call_page");
                    }}
                    optionsList={salePageList}
                    autoComplete="new-password"
                    name="sale_page_title"
                    setCustomSearch={setSearchText}
                    required
                  />
                </div>
                <div className="col-6 mt-4">
                  <TextField
                    className="text-muted"
                    id="outlined-basic"
                    label="Book Call Button Text"
                    variant="outlined"
                    fullWidth
                    name="book_call_button_text"
                    onChange={(e) =>
                      handleChange(e, "mission_book_call_popup_setting")
                    }
                    value={
                      inputs?.mission_book_call_popup_setting
                        ?.book_call_button_text
                    }
                    required
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                  <FormControl fullWidth required>
                    <InputLabel id="demo-simple-select-label">
                      Show To Members
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="show_to_members"
                      value={
                        inputs?.mission_book_call_popup_setting?.show_to_members
                      }
                      label="Show To Members"
                      onChange={(e) =>
                        handleChange(e, "mission_book_call_popup_setting")
                      }
                      required
                    >
                      <MenuItem value={"all"}>All Members</MenuItem>
                      <MenuItem value={"active"}>Membership Active</MenuItem>
                      <MenuItem value={"expired"}>Membership Expired</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col-12 mt-4">
                  <h4 className="mb-3">Description *</h4>
                  <GeneralCkeditor
                    setInputs={setInputs}
                    inputs={inputs}
                    name="book_a_call_description"
                    editorHeight={250}
                  />
                </div>
              </>
            )}
          </div>

          <div className="text-end mt-4" id="fixedbutton">
            <button className="small-contained-button">
              {isLoadingForm ? "Updating" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
