import React from "react";

const NotificationScheduleOpenView = ({ openViewData, onClick }) => {
  return (
    <>
      {openViewData && (
        <p
          className="mb-1 mt-1 view-more"
          variant="contained"
          onClick={onClick}
        >
          view
        </p>
      )}
    </>
  );
};

export default NotificationScheduleOpenView;
