import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { deleteVaultApi, VaultListing } from "src/DAL/Vault/vault";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false, type: "action" },
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "vault_title", label: "Name", alignRight: false },
  { id: "table_avatar", label: "Image", alignRight: false, type: "thumbnail" },
  { id: "program_title", label: "Programme", alignRight: false },
  { id: "status", label: "Status", alignRight: false, type: "row_status" },
];

export default function CategoriesList() {
  const navigate = useNavigate();
  const [categoriesData, setCategoriesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);

  const getVaultCategoryListing = async () => {
    const result = await VaultListing();
    if (result.code === 200) {
      const data = result.vault_category.map((category) => {
        return {
          ...category,
          table_avatar: {
            src: s3baseUrl + category.vault_image.thumbnail_2,
            alt: category.vault_title,
          },
          program_title: category.program?.title,
        };
      });
      setCategoriesData(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleEdit = (value) => {
    navigate(`/vault/edit-category/${value.vault_slug}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await deleteVaultApi(deleteDoc.vault_slug);
    if (result.code === 200) {
      setCategoriesData((prev) => {
        return prev.filter((data) => data.vault_slug !== deleteDoc.vault_slug);
      });
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  useEffect(() => {
    getVaultCategoryListing();
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container-fluid">
        <div className="row">
          <div className="col-6 mb-3 ">
            <h2>Vault Categories</h2>
          </div>
          <div className="col-6 mb-3 text-end">
            <button
              className="small-contained-button mt-1"
              onClick={() => navigate(`/vault/add-vault`)}
            >
              Add Vault Category
            </button>
          </div>
        </div>
        <div className="mt-3">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={categoriesData}
            MENU_OPTIONS={MENU_OPTIONS}
            className="card-with-background"
            pagePagination={true}
          />
        </div>
      </div>
    </>
  );
}
