import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { CircularProgress, TextareaAutosize, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import {
  GetNotificationSettingApi,
  addCalendarSettingApi,
  addNOtificationSetting,
  addStripeApi,
  siteSettingApi,
} from "src/DAL/SiteSetting/siteSetting";
import { useEffect } from "react";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function NotificationSetting() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  const [inputs, setInputs] = useState({
    feed_like_statment: "",
    new_message_statement: "",
    comment_like_statment: "",
    gratitude_statement: "",
    add_comment_statement: "",
    add_reply_statement: "",
    goal_statement_save_and_close: "",
    goal_statement_completed: "",
    add_support_ticket_created_statement: "",
    add_support_ticket_comment_added_statement: "",
    add_support_ticket_comment_added_status: "",
    add_support_ticket_comment_by_delegate_add_statement: "",
    add_support_ticket_internal_note_added_statement: "",
    move_support_ticket_status_statement: "",
    send_reminder_added_statement: "",
    close_support_ticket_statement: "",
    need_fixes_reminder_support_ticket_statement: "",
    daily_dynamite_reminder_time_statement: "",
    gratitude_reminder_time_statement: "",
    meditation_reminder_time_statement: "",
    daily_dynamite_intention_reminder_time_statement: "",
    appointment: "",
    event_notification_message: "",
    payment_reminder_before_expiry: "",
    payment_reminder_after_expiry: "",
    payment_succeded: "",
    payment_failed: "",
    subscription_cancelled: "",
    payment_refunded: "",
    goal_statement_incomplete: "",
    feed_mentioned_statement: "",
    feed_mentioned_statement_the_source: "",
    comment_mentioned_statement: "",
    comment_mentioned_statement_the_source: "",
    poll_answer_statement: "",
    survey_answer_statement: "",
  });
  const NotificationSettingList = async () => {
    setIsLoading(true);
    const result = await GetNotificationSettingApi();
    if (result.code == 200) {
      setInputs(result?.notification_settings);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);

    let postData = {
      notification_setting: {
        survey_answer_statement: inputs.survey_answer_statement,
        feed_like_statment: inputs.feed_like_statment,
        new_message_statement: inputs.new_message_statement,
        comment_like_statment: inputs.comment_like_statment,
        gratitude_statement: inputs.gratitude_statement,
        add_comment_statement: inputs.add_comment_statement,
        add_reply_statement: inputs.add_reply_statement,
        goal_statement_save_and_close: inputs.goal_statement_save_and_close,

        goal_statement_completed: inputs.goal_statement_completed,

        add_support_ticket_created_statement:
          inputs.add_support_ticket_created_statement,

        add_support_ticket_comment_added_statement:
          inputs.add_support_ticket_comment_added_statement,

        add_support_ticket_comment_by_delegate_add_statement:
          inputs.add_support_ticket_comment_by_delegate_add_statement,

        add_support_ticket_internal_note_added_statement:
          inputs.add_support_ticket_internal_note_added_statement,

        move_support_ticket_status_statement:
          inputs.move_support_ticket_status_statement,
        move_support_ticket_status: inputs.move_support_ticket_status,
        send_reminder_added_statement: inputs.send_reminder_added_statement,

        close_support_ticket_statement: inputs.close_support_ticket_statement,

        need_fixes_reminder_support_ticket_statement:
          inputs.need_fixes_reminder_support_ticket_statement,

        daily_dynamite_reminder_time_statement:
          inputs.daily_dynamite_reminder_time_statement,

        gratitude_reminder_time_statement:
          inputs.gratitude_reminder_time_statement,

        meditation_reminder_time_statement:
          inputs.meditation_reminder_time_statement,
        meditation_reminder_time: inputs.meditation_reminder_time,
        daily_dynamite_intention_reminder_time_statement:
          inputs.daily_dynamite_intention_reminder_time_statement,

        appointment: inputs.appointment,
        appointment_status: inputs.appointment_status,
        event_notification_message: inputs.event_notification_message,
        payment_reminder_before_expiry: inputs.payment_reminder_before_expiry,
        payment_reminder_after_expiry: inputs.payment_reminder_after_expiry,
        payment_succeded: inputs.payment_succeded,
        payment_failed: inputs.payment_failed,
        subscription_cancelled: inputs.subscription_cancelled,
        payment_refunded: inputs.payment_refunded,
        goal_statement_incomplete: inputs.goal_statement_incomplete,
        feed_mentioned_statement: inputs.feed_mentioned_statement,
        comment_mentioned_statement: inputs.comment_mentioned_statement,
        poll_answer_statement: inputs.poll_answer_statement,
        comment_mentioned_statement_the_source:
          inputs.comment_mentioned_statement_the_source,
        feed_mentioned_statement_the_source:
          inputs.feed_mentioned_statement_the_source,
      },
    };

    const result = await addNOtificationSetting(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    NotificationSettingList();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-6">
          <h2>Notification Statements</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row notification-statement">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <p className="tracking_code_heading">Feed Like Statement</p>

            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Feed Like Statment"
              name="feed_like_statment"
              minRows={1}
              value={inputs?.feed_like_statment}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
            {/* <TextField
              id="outlined-basic"
              variant="outlined"
              fullWidth
              aria-readonly
              label="Feed Like Statment"
              name="feed_like_statment"
              value={inputs.feed_like_statment}
              onChange={handleChange}
            /> */}
          </div>
          {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Feed Like Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="feed_Like_status"
                required
                value={inputs.feed_Like_status}
                label="Feed Like Status*"
                onChange={handleChange}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div> */}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <p className="tracking_code_heading">
              New Message Notification Statement
            </p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="New Message Notification Statement"
              name="new_message_statement"
              value={inputs?.new_message_statement}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="tracking_code_heading">Comment Like Statment</p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Comment Like Statment"
              name="comment_like_statment"
              value={inputs?.comment_like_statment}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="tracking_code_heading">
              Gratitude Notification Statement
            </p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Gratitude Notification Statement"
              value={inputs?.gratitude_statement}
              name="gratitude_statement"
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="tracking_code_heading">
              Add Comment Notification Statement
            </p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Add Comment Notification Statement"
              name="add_comment_statement"
              value={inputs?.add_comment_statement}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="tracking_code_heading">
              Add Reply Notification Statement
            </p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Add Reply Notification Statement"
              name="add_reply_statement"
              value={inputs?.add_reply_statement}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="tracking_code_heading">
              Goal Statement Save And Close Notification
            </p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Goal Statement Save And Close Notification"
              name="goal_statement_save_and_close"
              value={inputs?.goal_statement_save_and_close}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="tracking_code_heading">
              Goal Statement Completed Notification Statement
            </p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Goal Statement Completed Notification Statement"
              name="goal_statement_completed"
              value={inputs?.goal_statement_completed}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="tracking_code_heading">
              Add Support Ticket Created Notification Statement
            </p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Add Support Ticket Created Notification Statement"
              name="add_support_ticket_created_statement"
              value={inputs?.add_support_ticket_created_statement}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="tracking_code_heading">
              Support Ticket Comment Added Notification Statemen
            </p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Support Ticket Comment Added Notification Statemen"
              name="add_support_ticket_comment_added_statement"
              value={inputs?.add_support_ticket_comment_added_statement}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="tracking_code_heading">
              Support Ticket Comment Add By Delegate Satement
            </p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Support Ticket Comment Add By Delegate  Satement"
              name="add_support_ticket_comment_by_delegate_add_statement"
              value={
                inputs?.add_support_ticket_comment_by_delegate_add_statement
              }
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="tracking_code_heading">
              Support Ticket Internal Note Added Notification Statement
            </p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Support Ticket Internal Note Added Notification Statement"
              name="add_support_ticket_internal_note_added_statement"
              value={inputs?.add_support_ticket_internal_note_added_statement}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 ">
            <p className="tracking_code_heading">
              Move Support Ticket Status Notification Statement
            </p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Move Support Ticket Status Notification Statement"
              name="move_support_ticket_status_statement"
              value={inputs?.move_support_ticket_status_statement}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="tracking_code_heading">
              Send Reminder Notification Statement
            </p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Send Reminder Notification Statement"
              name="send_reminder_added_statement"
              value={inputs?.send_reminder_added_statement}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="tracking_code_heading">
              Close Support Ticket Notification Statement
            </p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Close Support Ticket Notification Statement"
              name="close_support_ticket_statement"
              value={inputs?.close_support_ticket_statement}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="tracking_code_heading">
              Need Fixes Reminder Support Ticket Notification Statement
            </p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Need Fixes Reminder Support Ticket Notification  Statement"
              name="need_fixes_reminder_support_ticket_statement"
              value={inputs?.need_fixes_reminder_support_ticket_statement}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="tracking_code_heading">
              Daily Dynamite Reminder Time Notification Statement
            </p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Daily Dynamite Reminder Time Notification Statement"
              name="daily_dynamite_reminder_time_statement"
              value={inputs?.daily_dynamite_reminder_time_statement}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 ">
            <p className="tracking_code_heading">
              Gratitude Reminder Time Notification Statement
            </p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Gratitude Reminder Time Notification Statement"
              name="gratitude_reminder_time_statement"
              value={inputs?.gratitude_reminder_time_statement}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="tracking_code_heading">
              Meditation Reminder Time Notification Statement
            </p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Meditation Reminder Time Notification Statement"
              name="meditation_reminder_time_statement"
              value={inputs?.meditation_reminder_time_statement}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 ">
            <p className="tracking_code_heading">
              Daily Dynamite Intention Reminder Time Notification Statement
            </p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Daily Dynamite Intention Reminder Time Notification Statement"
              name="daily_dynamite_intention_reminder_time_statement"
              value={inputs?.daily_dynamite_intention_reminder_time_statement}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="tracking_code_heading">
              Appointment Booking Notification Statment
            </p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Appointment Booking Notification Statment"
              name="appointment"
              value={inputs?.appointment}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="tracking_code_heading">
              Event Start Notification Statement
            </p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Event Start Notification Statement"
              name="event_notification_message"
              value={inputs?.event_notification_message}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="tracking_code_heading">
              Payment Reminder Before Expiry Notification Statement
            </p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Payment Reminder Before Expiry Notification Statement"
              name="payment_reminder_before_expiry"
              value={inputs?.payment_reminder_before_expiry}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="tracking_code_heading">
              Payment Reminder After Expiry Notification Statement
            </p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Payment Reminder After Expiry Notification Statement"
              name="payment_reminder_after_expiry"
              value={inputs?.payment_reminder_after_expiry}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="tracking_code_heading">
              Payment Succeded Notification Statement
            </p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Payment Succeded Notification Statement"
              name="payment_succeded"
              value={inputs?.payment_succeded}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="tracking_code_heading">
              Payment Failed Notification Statement
            </p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Payment Failed Notification Statement"
              name="payment_failed"
              value={inputs?.payment_failed}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="tracking_code_heading">
              Subscription Cancelled Notification Statement
            </p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Subscription Cancelled Notification Statement"
              name="subscription_cancelled"
              value={inputs?.subscription_cancelled}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="tracking_code_heading">
              Payment Refunded Notification Statement
            </p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Payment Refunded Notification Statement"
              name="payment_refunded"
              value={inputs?.payment_refunded}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="tracking_code_heading">
              Goal Statement Incomplete Notification Statement
            </p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Goal Statement Incomplete Notification Statement"
              name="goal_statement_incomplete"
              value={inputs?.goal_statement_incomplete}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="tracking_code_heading">
              Feed Mentioned Statement The Cosmos
            </p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Feed Mentioned Statement The Cosmos"
              name="feed_mentioned_statement"
              value={inputs?.feed_mentioned_statement}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="tracking_code_heading">
              Feed Mentioned Statement The Source
            </p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Feed Mentioned Statement The Source"
              name="feed_mentioned_statement_the_source"
              value={inputs?.feed_mentioned_statement_the_source}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="tracking_code_heading">
              Comment Mentioned Statement The Cosmos
            </p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Comment Mentioned Statement The Cosmos"
              name="comment_mentioned_statement"
              value={inputs?.comment_mentioned_statement}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="tracking_code_heading">
              Comment Mentioned Statement The Source
            </p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Comment Mentioned Statement The Source"
              name="comment_mentioned_statement_the_source"
              value={inputs?.comment_mentioned_statement_the_source}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="tracking_code_heading">
              Poll Answer Notification Statement
            </p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Poll Answer Notification Statement"
              name="poll_answer_statement"
              value={inputs?.poll_answer_statement}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="tracking_code_heading">
              Survey Answer Notification Statement
            </p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Survey Answer Notification Statement"
              name="survey_answer_statement"
              value={inputs?.survey_answer_statement}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="text-end mt-4" id="fixedbutton">
            <button className="small-contained-button">
              {isLoadingForm ? "Updating" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
