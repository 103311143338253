import React from "react";
import { useEffect, useState } from "react";
import { Link as RouterLink, Navigate, useNavigate } from "react-router-dom";
import { s3baseUrl } from "../../config/config";
import { useSnackbar } from "notistack";
import moment from "moment";
import { get_wheel_of_life_members_api } from "src/DAL/QuestionSurvey/QuestionSurvey";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import PageLoader from "src/components/GeneralComponents/PageLoader";

export default function WheelOfLifeMemberList() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [searchText, setSearchText] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeRoute = (route, data) => {
    delete data.MENU_OPTIONS;
    navigate(route, { state: data });
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getMemberListing();
  };

  const handleEdit = (value) => {
    let route = `/wheel-of-life-member-list/${value._id}`;
    handleChangeRoute(route, value);
  };

  const handleCopyReferralId = async (e) => {
    await navigator.clipboard.writeText(e.affliliate?.affiliate_url_name);
    enqueueSnackbar("Referral Id Copied to clipboard", { variant: "success" });
  };

  const getMemberListing = async () => {
    setIsLoading(true);
    let search_keyword =
      localStorage.getItem("wheel_of_life_search_text") == null
        ? searchText
        : localStorage.getItem("wheel_of_life_search_text");
    if (search_keyword !== null) {
      setSearchText(search_keyword);
    }
    const result = await get_wheel_of_life_members_api(
      page,
      rowsPerPage,
      search_keyword
    );
    if (result.code == 200) {
      const members = result.member.map((member) => {
        let full_name = "N/A";
        let refUser = "N/A";
        let completedDate = "N/A";
        let created_At = "N/A";

        if (member.first_name) {
          full_name =
            member.first_name +
            " " +
            member.last_name +
            " (" +
            member.email +
            ")";
        }

        if (member.wheel_of_life_completed_date) {
          completedDate = moment(member.wheel_of_life_completed_date).format(
            "DD-MM-YYYY"
          );
        }
        if (member.createdAt) {
          created_At = moment(member.createdAt).format("DD-MM-YYYY");
        }

        if (member.affliliate?.affiliate_user_info) {
          refUser =
            member.affliliate.affiliate_user_info.first_name +
            " " +
            member.affliliate.affiliate_user_info.last_name +
            " (" +
            member.affliliate.affiliate_user_info.email +
            ")";
        }
        return {
          ...member,
          full_name,
          refUser,
          completedDate,
          created_At,
          table_avatar: {
            src: member.profile_image ? s3baseUrl + member.profile_image : "/",
            alt: member.first_name,
          },
          MENU_OPTIONS: handleMenu(member),
        };
      });
      setData(members);
      setTotalCount(result.total_member_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  const handleMenu = (row) => {
    let MENU_OPTIONS = [
      {
        label: "View Detail",
        icon: "akar-icons:edit",
        handleClick: handleEdit,
      },
    ];
    if (row.affliliate) {
      if (
        row.affliliate.affiliate_user_info &&
        row.affliliate.affiliate_url_name
      ) {
        MENU_OPTIONS.push({
          label: "Copy Referral Id",
          icon: "eva:eye-fill",
          handleClick: handleCopyReferralId,
        });
      }
    }
    return MENU_OPTIONS;
  };

  const TABLE_HEAD = [
    {
      id: "action",
      label: "Action",
      type: "action",
      MENU_OPTIONS: "MENU_OPTIONS",
    },
    { id: "number", label: "#", type: "number" },
    { id: "table_avatar", label: "Image", type: "thumbnail" },
    {
      id: "full_name",
      label: "Name",
      className: "pointer",
      handleClick: handleEdit,
    },
    { id: "refUser", label: "Referred User" },
    {
      id: "completedDate",
      label: "Wheel Of Life Completed Date",
    },
    { id: "created_At", label: "Created At" },
    { id: "status", label: "Status", type: "row_status" },
  ];

  useEffect(() => {
    getMemberListing();
  }, [rowsPerPage, page]);

  useEffect(() => {
    localStorage.setItem("wheel_of_life_search_text", searchText);
    if (searchText.length < 1) {
      localStorage.setItem("wheel_of_life_search_text", "");
    } else {
      localStorage.setItem("wheel_of_life_search_text", searchText);
    }
  }, [searchText]);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row ">
          <div className="col-lg-12 col-sm-12 mb-5">
            <h2>Wheel Of Life Members</h2>
          </div>
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={data}
            className="card-with-background"
            custom_pagination={{
              total_count: totalCount,
              rows_per_page: rowsPerPage,
              page: page,
              handleChangePage: handleChangePage,
              onRowsPerPageChange: handleChangeRowsPerPage,
            }}
            custom_search={{
              searchText: searchText,
              setSearchText: setSearchText,
              handleSubmit: searchFunction,
            }}
            pageCount={pageCount}
            totalPages={totalPages}
            handleChangePages={handleChangePages}
            pagePagination={true}
          />
        </div>
      </div>
    </>
  );
}
