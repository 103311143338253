import * as React from "react";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import {
  _create_portal_updates,
  _detail_portal_updates,
  _update_portal_updates,
} from "src/DAL/Updates/Updates";
import PageLoader from "src/components/GeneralComponents/PageLoader";

export default function AddOrEditUpdate() {
  const { enqueueSnackbar } = useSnackbar();
  const { update_id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const [inputs, setInputs] = useState({
    title: "",
    date: new Date(),
    fixed_issues_description: "",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeDate = (name, value) => {
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleFormatData = (data) => {
    setInputs(data);
    setIsLoading(false);
  };

  const getUpdateDetail = async () => {
    const result = await _detail_portal_updates(update_id);
    if (result.code === 200) {
      handleFormatData(result?.portal_updates);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let postData = {
      title: inputs?.title,
      date: moment(inputs?.date).format("YYYY-MM-DD"),
      fixed_issues_description: inputs?.fixed_issues_description,
    };

    const result = update_id
      ? await _update_portal_updates(update_id, postData)
      : await _create_portal_updates(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(`/updates`);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (update_id) {
      if (state) {
        handleFormatData(state);
      } else {
        getUpdateDetail();
      }
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-0"
              onClick={() => navigate("/updates")}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2 className="ms-2">{`${update_id ? "Edit" : "Add"} Update`}</h2>
        </div>
      </div>

      <form className="row" onSubmit={handleSubmit}>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Title"
            required
            variant="outlined"
            fullWidth
            name="title"
            value={inputs?.title}
            onChange={handleChange}
          />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4 ">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date"
              inputFormat="dd/MM/yyyy"
              name="date"
              minDate={new Date()}
              value={inputs?.date}
              onChange={(e) => {
                handleChangeDate("date", e);
              }}
              format="YYYY-MM-DD"
              renderInput={(params) => (
                <TextField {...params} required={true} fullWidth />
              )}
            />
          </LocalizationProvider>
        </div>

        <div className="col-12 mt-4">
          <h4>Update Description</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="fixed_issues_description"
            editorHeight={320}
          />
        </div>

        <div className="text-end mt-4">
          <button className="small-contained-button">
            {update_id ? "Update" : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
}
