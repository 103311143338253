import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton } from "@mui/material";
import {
  add_programme_api,
  deleteAudioApi,
  update_programme_api,
  programme_detail_api,
} from "src/DAL/Programme/Programme";
import { s3baseUrl } from "../../config/config";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { useEffect } from "react";
import { urlPatternValidation } from "src/utils/constant";
import { usePGIMode } from "src/Hooks/PGIModeContext";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const Input = styled("input")({
  display: "none",
});

export default function AddOrUpdateProgramme() {
  const navigate = useNavigate();
  const { badgeLevels } = usePGIMode();
  const { programme_slug } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [audioFile, setAudio] = useState();
  const [oldAudio, setOldAudio] = useState("");
  const [formType, setFormType] = useState("ADD");

  const [images, setImages] = useState({
    main_image: "",
    program_lock_icon: "",
  });

  const [oldImages, setOldImages] = useState({
    main_image: "",
    program_lock_icon: "",
  });

  const [inputs, setInputs] = useState({
    title: "",
    status: true,
    is_program_show_on_list: true,
    main_image: {},
    program_access_type: "",
    video_url: "",
    delegate_first_tab_heading: "",
    short_description: "",
    detailed_description: "",
    no_of_limited_days: 0,
    no_of_start_days: 0,
    is_hidden: false,
    show_on_mission_control: false,
    is_program_module: false,
    is_show_on: "main_portal",
    order: 0,
    module_tab_name: "",
    program_lock_icon: {},
    program_lock_statement: "",
    badge_level_id: "",
    is_membership_allowed: true,
  });

  const getProgrammeDetail = async () => {
    setIsLoading(true);
    const result = await programme_detail_api(programme_slug);
    if (result.code === 200) {
      handleFormat(result.program);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fileChangedHandler = (e) => {
    const { name, files } = e.target;
    setImages({
      ...images,
      [name]: URL.createObjectURL(files[0]),
    });

    setInputs({
      ...inputs,
      [name]: files[0],
    });
  };

  const audioFileChange = (e) => {
    setAudio(e.target.files[0]);
  };

  const handldeDeleteAudio = async (value) => {
    let postData = {
      status: "audio",
    };
    setIsLoading(true);
    let result = await deleteAudioApi(postData, programme_slug);
    if (result.code == 200) {
      setIsLoading(false);
      setOldAudio();
      setAudio();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.video_url && urlPatternValidation(inputs.video_url) === false) {
      enqueueSnackbar("Enter Valid Video URL", { variant: "error" });
    } else {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("title", inputs.title);
      formData.append("badge_level_id", inputs?.badge_level_id);
      // formData.append("reminder_text_for_page", inputs.reminder_text_for_page);
      // formData.append("timer_top_description", inputs.timer_top_description);
      // formData.append("button_text", inputs.button_text);
      // formData.append("button_link", inputs.button_link);
      // formData.append("reminder_show_days", inputs.reminder_show_days);
      formData.append("short_description", inputs.short_description);
      formData.append("is_hidden", inputs.is_hidden);
      formData.append("is_show_on", inputs.is_show_on);
      formData.append("detailed_description", inputs.detailed_description);
      formData.append("status", inputs.status);
      formData.append("video_url", inputs.video_url);
      formData.append("program_access_type", inputs.program_access_type);
      formData.append("program_lock_statement", inputs.program_lock_statement);
      if (inputs.program_access_type == "limited") {
        formData.append("is_membership_allowed", inputs.is_membership_allowed);
      }
      // formData.append("is_program_module", inputs.is_program_module);
      // formData.append("module_tab_name", inputs.module_tab_name);

      formData.append(
        "is_program_show_on_list",
        inputs.is_program_show_on_list
      );
      formData.append(
        "show_on_mission_control",
        inputs.show_on_mission_control
      );
      // formData.append(
      //   "timer_bottom_description",
      //   inputs.timer_bottom_description
      // );

      formData.append(
        "delegate_first_tab_heading",
        inputs.delegate_first_tab_heading
      );
      formData.append(
        "no_of_limited_days",
        inputs.no_of_limited_days ? inputs.no_of_limited_days : 0
      );
      formData.append(
        "no_of_start_days",
        inputs.no_of_start_days ? inputs.no_of_start_days : 0
      );
      if (formType == "EDIT") {
        formData.append("order", inputs.order);
      }
      if (audioFile) {
        formData.append("audio_file", audioFile);
      }
      if (images.main_image) {
        formData.append("main_image", inputs.main_image);
      }
      if (images.program_lock_icon) {
        formData.append("program_lock_icon", inputs.program_lock_icon);
      }
      if (+inputs.no_of_start_days > +inputs.no_of_limited_days) {
        enqueueSnackbar(
          "No of Limit Start Days cannot be greater than No of Limit End days",
          { variant: "error" }
        );
        setIsLoading(false);
        return;
      }
      const result =
        formType == "ADD"
          ? await add_programme_api(formData)
          : await update_programme_api(formData, programme_slug);
      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        if (formType !== "ADD") {
          handleNavigate(result?.program?.program_slug);
        } else {
          handleNavigate();
        }
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleFormat = (data) => {
    setInputs((values) => ({
      ...data,
      badge_level_id: data?.membership_levels?.membership_level_badges?._id,
    }));

    setFormType("EDIT");
    setOldImages({
      ...oldImages,
      ["main_image"]: data.program_images.thumbnail_2,
      ["program_lock_icon"]: data.program_lock_icon,
    });
    setOldAudio(data.audio_file);
    setIsLoading(false);
  };

  const handleNavigate = (value) => {
    if (formType == "ADD") {
      navigate(`/programmes`);
    } else {
      navigate(
        `/programmes/programmes-detail/${value ? value : programme_slug}`
      );
    }
  };

  useEffect(() => {
    if (programme_slug) {
      if (state) {
        handleFormat(state);
      } else {
        getProgrammeDetail();
      }
    }
  }, []);

  if (isLoading === true) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => handleNavigate(programme_slug)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2>{`${formType == "ADD" ? "Add" : "Edit"} Programme`}</h2>
        </div>
      </div>
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Programme Name"
            variant="outlined"
            fullWidth
            name="title"
            value={inputs.title}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Video Url"
            variant="outlined"
            fullWidth
            name="video_url"
            value={inputs.video_url}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Delegate First Tab Heading"
            variant="outlined"
            fullWidth
            name="delegate_first_tab_heading"
            value={inputs.delegate_first_tab_heading}
            onChange={handleChange}
          />
        </div>
        {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Upgrade Button Text"
            variant="outlined"
            fullWidth
            name="button_text"
            value={inputs.button_text == undefined ? "" : inputs.button_text}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Upgrade Button Link"
            variant="outlined"
            fullWidth
            type="url"
            name="button_link"
            value={inputs.button_link == undefined ? "" : inputs.button_link}
            onChange={handleChange}
          />
        </div> */}
        {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Programme Module Tab Label"
            variant="outlined"
            fullWidth
            name="module_tab_name"
            value={
              inputs.module_tab_name == undefined ? "" : inputs.module_tab_name
            }
            onChange={handleChange}
          />
        </div> */}
        {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Reminder Days"
            variant="outlined"
            fullWidth
            type="number"
            name="reminder_show_days"
            value={inputs.reminder_show_days}
            onChange={handleChange}
          />
        </div> */}

        {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Is Allow Programme Module
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="is_program_module"
              value={inputs.is_program_module}
              label="Is Allow Programme Module"
              onChange={handleChange}
              required
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
        </div> */}
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Programme Access Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="program_access_type"
              value={inputs.program_access_type}
              label="Programme Access Type"
              onChange={handleChange}
              required
            >
              <MenuItem value="limited">Limited</MenuItem>
              <MenuItem value="unlimited">Unlimited</MenuItem>
            </Select>
          </FormControl>
        </div>
        {inputs.program_access_type == "limited" && (
          <>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">
                  Is Membership Allowed
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="is_membership_allowed"
                  value={inputs.is_membership_allowed}
                  label="Is Membership Allowed"
                  onChange={handleChange}
                  required
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="No Of Limit Start Days"
                variant="outlined"
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                placeholder="No Of Limit Start Days"
                fullWidth
                required
                name="no_of_start_days"
                value={inputs.no_of_start_days}
                onChange={handleChange}
              />
            </div>{" "}
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="No Of Limit End Days"
                variant="outlined"
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                placeholder="No Of Limit End Days"
                fullWidth
                required
                name="no_of_limited_days"
                value={inputs.no_of_limited_days}
                onChange={handleChange}
              />
            </div>
          </>
        )}
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Programme Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="status"
              value={inputs.status}
              label="Programme Status"
              onChange={handleChange}
              required
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">is Hidden </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="is_hidden"
              value={inputs.is_hidden}
              label="is Hidden"
              onChange={handleChange}
              required
            >
              <MenuItem value={false}>No</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Is Programme Show On List?
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="is_program_show_on_list"
              value={inputs.is_program_show_on_list}
              label="Is Programme Show On List? "
              onChange={handleChange}
              required
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Show On Mission Control
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="show_on_mission_control"
              value={inputs.show_on_mission_control}
              label="Show On Mission Control "
              onChange={handleChange}
              required
            >
              <MenuItem value={false}>No</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Is Programme Show On ?
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="is_show_on"
              value={inputs.is_show_on}
              label="Is Programme Show On ?"
              onChange={handleChange}
              required
            >
              <MenuItem value="main_portal">Main Portal</MenuItem>
              <MenuItem value="delegate_portal">Delegate Portal</MenuItem>
              <MenuItem value="both">Both</MenuItem>
            </Select>
          </FormControl>
        </div>
        {formType == "EDIT" && (
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Order"
              variant="outlined"
              fullWidth
              required
              name="order"
              value={inputs.order}
              onChange={handleChange}
            />
          </div>
        )}
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Program Lock Statement"
            variant="outlined"
            fullWidth
            name="program_lock_statement"
            value={inputs.program_lock_statement}
            onChange={handleChange}
          />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Badge Level</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="badge_level_id"
              value={inputs?.badge_level_id}
              label="Badge Level"
              onChange={handleChange}
            >
              {badgeLevels.map((level, index) => (
                <MenuItem key={index} value={level?._id}>
                  {level?.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Upload Audio</p>
              <FormHelperText className="pt-0">
                Audio mp3 (max 200mb)
              </FormHelperText>
            </div>
            <div className="col-2">
              {oldAudio && (
                <DeleteIcon
                  onClick={handldeDeleteAudio}
                  className="mt-3 icon-color"
                />
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="audio">
                <Input
                  accept="audio/mp3,audio/*;capture=microphone"
                  id="audio"
                  multiple
                  name="audio"
                  type="file"
                  onChange={audioFileChange}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          <p className="text-secondary">{audioFile && audioFile.name}</p>
          {oldAudio && (
            <audio className="w-100" src={s3baseUrl + oldAudio} controls />
          )}
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Upload Image *</p>
              <FormHelperText className="pt-0">
                Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-2">
              {(images.main_image || oldImages.main_image) && (
                <img
                  src={
                    images.main_image
                      ? images.main_image
                      : s3baseUrl + oldImages.main_image
                  }
                  height="50"
                />
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  name="main_image"
                  onChange={fileChangedHandler}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          {inputs?.image?.name == "" ? (
            ""
          ) : (
            <p className="text-secondary">{inputs?.image?.name}</p>
          )}
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Upload Lock Icon</p>
              <FormHelperText className="pt-0">
                Image Size(32 X 32) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-2">
              {(images.program_lock_icon || oldImages.program_lock_icon) && (
                <img
                  src={
                    images.program_lock_icon
                      ? images.program_lock_icon
                      : s3baseUrl + oldImages.program_lock_icon
                  }
                  height="50"
                />
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file2">
                <Input
                  accept="image/*"
                  id="contained-button-file2"
                  multiple
                  type="file"
                  name="program_lock_icon"
                  onChange={fileChangedHandler}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          {inputs?.image?.name == "" ? (
            ""
          ) : (
            <p className="text-secondary">{inputs?.image?.name}</p>
          )}
        </div>
        <div className="col-12 mt-4">
          <FormControl fullWidth>
            <TextField
              id="outlined-multiline-flexible"
              label="Short Description"
              multiline
              rows={6}
              required
              name="short_description"
              value={inputs.short_description}
              onChange={handleChange}
            />
            <FormHelperText>Maximum limit 500 characters</FormHelperText>
          </FormControl>
        </div>
        {/* <div className="col-12 mt-4">
          <h4>Reminder Box For Client Dashboard</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="reminder_text_for_page"
            editorHeight={320}
          />
        </div>
        <div className="col-12 mt-4">
          <h4>Timer Top Description</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="timer_top_description"
            editorHeight={320}
          />
        </div>
        <div className="col-12 mt-4">
          <h4>Timer Bottom Description</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="timer_bottom_description"
            editorHeight={320}
          />
        </div> */}
        <div className="col-12 mt-4">
          <h4>Detailed Description *</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="detailed_description"
            editorHeight={320}
          />
        </div>
        <div
          className="text-end mt-4"
          id={formType == "ADD" ? "" : "fixedbutton"}
        >
          <button className="small-contained-button">
            {formType == "ADD" ? "Submit" : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
