import { useState } from "react";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import Chart from "react-apexcharts";
import { download_csv_file } from "src/utils/convertHtml";
import { useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import { detail_funnel_report } from "src/DAL/FunnelPage/FunnelPage";
import { useNavigate, useParams } from "react-router-dom";
import FunnelStepEvent from "./FunnelStepEvent";
import { useSnackbar } from "notistack";
import WhatsappPopUpModel from "src/components/GeneralComponents/WhatsappPopUpModel";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const FunnelExploration = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const [openFilter, setOpenfilter] = useState(false);
  const [stepLocation, setStepLocation] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [userList, setUserList] = useState([]);

  const getSetpList = async () => {
    setIsLoading(true);
    const result = await detail_funnel_report(params.id);
    if (result.code === 200) {
      setUserList(result.report.table_data);
      setData(result.report.funnel_visualization_data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const TABLE_HEAD = [
    {
      id: "funnelStepName",
      label: "Step",
      tooltip: "Step",
      renderData: (row) => {
        return (
          <div
            className={`${row.pageLocation ? "pointer_cursor" : ""}`}
            style={{
              color: row.pageLocation ? "#e4c073" : "",
            }}
            onClick={() => {
              if (row.pageLocation) {
                setOpenfilter(true);
                setStepLocation(row.pageLocation);
              }
            }}
          >
            {row.funnelStepName}
          </div>
        );
      },
    },
    {
      id: "activeUsers",
      label: "Active User",
      tooltip: "The total number of active users",
    },
    {
      id: "funnelStepCompletionRate",
      label: "Completion Rate",
      tooltip:
        "Percentage of users from the previous step who continued to the current step.",
      renderData: (row) => {
        return <div>{row.funnelStepCompletionRate}%</div>;
      },
    },
    {
      id: "funnelStepAbandonments",
      label: "Abandonments",
      tooltip:
        "Number of users from this step who did not continue to the next step.",
    },
    {
      id: "abandonmentsrete",
      label: "Abandonments Rate",
      tooltip:
        "Percentage of users from this step who did not continue to the next step.",
      renderData: (row) => {
        return <div>{row.funnelStepAbandonmentRate}%</div>;
      },
    },
  ];

  let barChart = {
    options: {
      fill: {
        colors: ["#e4c073"],
      },
      plotOptions: {
        bar: {
          columnWidth: "70%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      chart: {
        id: "bar",
      },
      yaxis: {
        labels: {
          style: {
            colors: ["#fff"],
          },
        },
      },
      tooltip: {
        theme: "dark",
      },
      xaxis: {
        categories: data ? Object.keys(data) : [""],
        labels: {
          style: {
            colors: ["#fff", "#fff", "#fff", "#fff", "#fff"],
          },
        },
      },
    },
    series: [
      {
        name: "Active Users",
        data: data ? Object.values(data).map((step) => step.activeUsers) : [""],
      },
    ],
  };

  const headers = [
    { label: "Step", key: "funnelStepName" },
    { label: "Active User", key: "activeUsers" },
    { label: "Completion Rate", key: "funnelStepCompletionRate" },
    { label: "Abandonments", key: "funnelStepAbandonments" },
    { label: "Abandonments Rate", key: "funnelStepAbandonmentRate" },
  ];

  const handleExportClick = () => {
    download_csv_file(headers, userList);
  };

  useEffect(() => {
    getSetpList();
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row mb-3 mt-2">
        <div className="col-lg-4 d-flex">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <h2 className="ms-2"> Funnel Details</h2>
        </div>
        <div className="col-lg-8 text-end">
          {userList.length > 0 && (
            <button
              className="small-contained-button "
              onClick={handleExportClick}
            >
              Export CSV
            </button>
          )}
        </div>
      </div>
      <div className="mixed-chart card">
        <Chart
          options={barChart.options}
          series={barChart.series}
          type="bar"
          height={380}
        />
      </div>
      <div className="mt-3">
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={userList}
          className="card-with-background"
          is_hide={true}
          hide_footer_pagination={true}
        />
      </div>
      <WhatsappPopUpModel
        open={openFilter}
        setOpen={setOpenfilter}
        show_date_and_income={true}
        title={"Step Events"}
        componentToPassDown={<FunnelStepEvent data={stepLocation} />}
      />
    </div>
  );
};

export default FunnelExploration;
