import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useSnackbar } from "notistack";
import { useState, useEffect } from "react";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import {
  get_mission_badge_list,
  update_mission_badge_list,
} from "src/DAL/MissionApi/MissionApiNew";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const badge_object = {
  _id: null,
  no_of_badges: null,
};

export default function BadgesSetting() {
  const { mission_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [missionResult, setMissionResult] = useState({});
  const [badgeList, setBadgeList] = useState([]);
  const [badgesArray, setBadgesArray] = useState([badge_object]);

  const handleAddBadge = () => {
    setBadgesArray((old_array) => [...old_array, badge_object]);
  };

  const handleRemoveBadge = (index) => {
    const updatedItems = [...badgesArray];
    updatedItems.splice(index, 1);
    setBadgesArray(updatedItems);
  };

  const get_mission_badge = async () => {
    setIsLoading(true);
    const result = await get_mission_badge_list(mission_id);
    if (result.code === 200) {
      setMissionResult(result?.mission);
      if (result?.mission?.badge_configration.length > 0) {
        setBadgesArray(result?.mission?.badge_configration);
      }
      setBadgeList(result?.badge_list);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangeNotification = (event, index) => {
    const { name, value } = event.target;
    let temp_state = [...badgesArray];
    let temp_element = { ...temp_state[index] };
    temp_element[name] = value;
    temp_state[index] = temp_element;
    setBadgesArray(temp_state);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);
    let postData = {
      badge_configration: badgesArray,
    };
    const result = await update_mission_badge_list(postData, mission_id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });      
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  let breadCrumbMenu = [
    // {
    //   title: `Mission Level (${missionResult?.mission_lavel_info?.title})`,
    //   navigation: `/mission`,
    //   active: false,
    // },
    {
      title: `Mission (${missionResult?.title})`,
      // navigation: `/mission/challenge/${missionResult?.mission_lavel_info?._id}`,
      navigation: `/mission`,
      active: false,
    },
    {
      title: "Manage Rewards on Completion",
      active: true,
    },
  ];

  useEffect(() => {
    get_mission_badge();
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="container">
      <div className="row mb-4">
        <div className="col-12 mb-3">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
        <div className="col-6">
          <h2>Manage Rewards on Completion</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        {badgesArray.length > 0 &&
          badgesArray.map((badge, index) => {
            return (
              <div className="row mb-3 setting-card px-0 px-md-3">
                <div className="col-11">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <FormControl fullWidth required>
                        <InputLabel id="demo-simple-select-label">
                          Select Reward
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="_id"
                          label="Select Reward"
                          value={badge._id}
                          onChange={(e) => {
                            handleChangeNotification(e, index);
                          }}
                        >
                          {badgeList.map((item, index) => {
                            return (
                              <MenuItem value={item._id} key={index}>
                                {item.title}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-12 col-md-6">
                      <TextField
                        className="w-100"
                        id="outlined-basic"
                        label="No of Rewards"
                        variant="outlined"
                        name="no_of_badges"
                        type="number"
                        value={badge.no_of_badges}
                        required={true}
                        onChange={(e) => {
                          handleChangeNotification(e, index);
                        }}
                        inputProps={{ min: 1 }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-1 duplicate-icon-box">
                  <AddCircleOutlineIcon
                    className="duplicate-icon mb-2"
                    onClick={handleAddBadge}
                  />
                  {badgesArray.length !== 1 && (
                    <RemoveCircleOutlineIcon
                      className="duplicate-icon"
                      onClick={() => handleRemoveBadge(index)}
                    />
                  )}
                </div>
              </div>
            );
          })}
        <div className="text-end mt-4" id="fixedbutton">
          <button className="small-contained-button">
            {isLoadingForm ? "Updating..." : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
