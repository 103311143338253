import React from "react";
import EastIcon from "@mui/icons-material/East";
import { useNavigate } from "react-router-dom";

export default function MoveToNextPage(props) {
  const navigate = useNavigate();
  const { text, move_path } = props;

  const handleClick = () => navigate(move_path);

  return (
    <div className="move-another-page" onClick={handleClick}>
      {text}
      <EastIcon />
    </div>
  );
}
