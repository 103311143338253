import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import { s3baseUrl } from "src/config/config";
import { useEffect } from "react";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import {
  add_mentorship_category_api,
  mentorship_category_detail_api,
  update_mentorship_category_api,
} from "src/DAL/Mentorship/MentorshipApi";
import { list_pages_for_mentorship } from "src/DAL/WebsitePages/WebsitePages";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const Input = styled("input")({
  display: "none",
});

export default function AddOrUpdateMentorshipCategory() {
  const navigate = useNavigate();
  const params = useParams();
  const { state } = useLocation();
  const { socket } = useContentSetting();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setProfileImage] = useState();
  const [formType, setFormType] = useState("ADD");
  const [oldImage, setOldImage] = useState("");
  const [pagesList, setPagesList] = useState([]);
  const [plansList, setPlansList] = useState([]);

  const [images, setImages] = useState({
    image: "",
    link_image: "",
  });

  const [oldImages, setOldImages] = useState({
    image: "",
    link_image: "",
  });

  const [inputs, setInputs] = useState({
    title: "",
    order: 0,
    status: true,
    image: {},
    short_description: "",
    detailed_description: "",
    show_category_to: "all_users",
    is_purchase_link: false,
    upgrade_button_1_text: "",
    upgrade_button_2_text: "",
    upgrade_button_1_link: "",
    upgrade_button_2_link: "",
    banner_1_description: "",
    banner_2_description: "",
    sale_page: null,
    plan: "",
  });

  const fileChangedHandler = (e) => {
    const { name, files } = e.target;

    setImages({
      ...images,
      [name]: URL.createObjectURL(files[0]),
    });

    setInputs({
      ...inputs,
      [name]: files[0],
    });
  };

  const getCategoryData = async () => {
    setIsLoading(true);
    let result = await mentorship_category_detail_api(params.category_slug);
    if (result.code == 200) {
      handleFormat(result.mentorship_category);
      setIsLoading(false);
    } else {
      navigate(`/mentorship-category`);
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const get_list_pages = async () => {
    const result = await list_pages_for_mentorship();
    if (result.code === 200) {
      setPagesList(result.sale_pages);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleSelectPage = (value) => {
    setInputs((values) => ({ ...values, sale_page: value, plan: null }));
    setPlansList(value.payment_plans);
  };

  const handleChangePlan = (value) => {
    let add_obj = {
      plan: value,
      amount: value.plan_price,
    };
    setInputs((values) => ({ ...values, ...add_obj }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("title", inputs.title);
    formData.append("status", inputs.status);
    formData.append("short_description", inputs.short_description);
    formData.append("show_category_to", inputs.show_category_to);
    formData.append("upgrade_button_1_text", inputs.upgrade_button_1_text);
    formData.append("upgrade_button_2_text", inputs.upgrade_button_2_text);
    formData.append("upgrade_button_1_link", inputs.upgrade_button_1_link);
    formData.append("upgrade_button_2_link", inputs.upgrade_button_2_link);
    formData.append("banner_1_description", inputs.banner_1_description);
    formData.append("banner_2_description", inputs.banner_2_description);
    formData.append("is_purchase_link", inputs.is_purchase_link);
    formData.append("sale_page", inputs.sale_page?._id);
    formData.append("plan", inputs.plan?._id);

    if (formType == "EDIT") {
      // if Form Type is EDIT order is required && image is optional
      formData.append("order", inputs.order);
      if (images.image) {
        formData.append("image", inputs.image);
      }

      if (inputs.is_purchase_link == true) {
        // if is_purchase_link link is required && link_image is optional
        formData.append("link", inputs.link);
        if (images.link_image) {
          formData.append("link_image", inputs.link_image);
        }
      }
    } else {
      // if Form Type is ADD image is reqired
      formData.append("image", inputs.image);
      // if is_purchase_link link && link_image is required
      if (inputs.is_purchase_link == true) {
        formData.append("link_image", inputs.link_image);
        formData.append("link", inputs.link);
      }
    }

    formData.append("detailed_description", inputs.detailed_description);

    const result =
      formType === "ADD"
        ? await add_mentorship_category_api(formData)
        : await update_mentorship_category_api(formData, params.category_slug);
    if (result.code === 200) {
      if (formType === "EDIT") {
        let data = {
          is_purchase_link: result.mentorship_category.is_purchase_link,
          link: result.mentorship_category.link,
          link_image: result.mentorship_category.link_image,
          category_slug: params.category_slug,
        };
        socket.emit("purchase_link_enabled_event", data);
      }
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(`/mentorship-category`);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleFormat = (state) => {
    setFormType("EDIT");
    setInputs({ ...state, is_purchase_link: Boolean(state.is_purchase_link) });
    setPlansList((prevPlansList) => [...prevPlansList, state.plan]);
    setOldImage(state?.image?.thumbnail_2);
    setOldImages({
      image: state?.image?.thumbnail_2,
      link_image: state?.link_image,
    });
  };

  useEffect(() => {
    if (params && params.category_slug) {
      if (state) {
        handleFormat(state);
      } else {
        getCategoryData();
      }
    }
  }, []);

  useEffect(() => {
    get_list_pages();
  }, []);

  useEffect(() => {
    if (params.category_slug) {
      if (pagesList.length > 0) {
        let find_id = pagesList.find(
          (page_id) => page_id._id == inputs.sale_page._id
        );

        if (find_id && find_id.payment_plans.length > 0) {
          setPlansList(find_id?.payment_plans);
        }
      }
    }
  }, [pagesList]);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(`/mentorship-category`)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2 className="ms-1">{`${
            formType === "ADD" ? "Add" : "Edit"
          } Bonus Content Category`}</h2>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              fullWidth
              required
              name="title"
              value={inputs.title}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Show Category To *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="show_category_to"
                required
                value={inputs.show_category_to}
                label="Show Category To *"
                onChange={handleChange}
              >
                <MenuItem value="all_users">All Users</MenuItem>
                <MenuItem value="users_with_access">Users With Access</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <MUIAutocomplete
              inputLabel="Sale Page"
              selectedOption={inputs.sale_page}
              setSelectedOption={handleSelectPage}
              optionsList={pagesList}
              name="sale_page_title"
              required
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Payment Plan
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="plan"
                value={!!inputs.plan ? inputs.plan?._id : ""}
                label="Payment Plan"
                required
              >
                {plansList.length > 0 &&
                  plansList?.map((plan) => {
                    return (
                      <MenuItem
                        value={plan?._id}
                        onClick={() => handleChangePlan(plan)}
                      >
                        {plan?.plan_title}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Upgrade Button Text 1"
              variant="outlined"
              fullWidth
              name="upgrade_button_1_text"
              value={inputs.upgrade_button_1_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Upgrade Button Link 1"
              variant="outlined"
              fullWidth
              type="url"
              name="upgrade_button_1_link"
              value={inputs.upgrade_button_1_link}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Upgrade Button Text 2"
              variant="outlined"
              fullWidth
              name="upgrade_button_2_text"
              value={inputs.upgrade_button_2_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Upgrade Button Link 2"
              variant="outlined"
              fullWidth
              type="url"
              name="upgrade_button_2_link"
              value={inputs.upgrade_button_2_link}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                required
                value={inputs.status}
                label="Status *"
                onChange={handleChange}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          {formType == "EDIT" && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Order"
                variant="outlined"
                fullWidth
                required
                name="order"
                value={inputs.order}
                onChange={handleChange}
              />
            </div>
          )}

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Is Purchase Link Enable
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_purchase_link"
                value={inputs.is_purchase_link}
                label="Is Purchase Link Enable"
                required
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs.is_purchase_link == true && (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Purchase Link"
                  variant="outlined"
                  fullWidth
                  required
                  name="link"
                  value={inputs.link}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                <div className="row w-100 div-style ms-0 pt-0">
                  <div className="col-5">
                    <p className="">Upload Link Image *</p>
                    <FormHelperText className="pt-0">
                      Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                    </FormHelperText>
                  </div>
                  <div className="col-2">
                    {(images.link_image || oldImages.link_image) && (
                      <img
                        src={
                          images.link_image
                            ? images.link_image
                            : s3baseUrl + oldImages.link_image
                        }
                        height="50"
                      />
                    )}
                  </div>
                  <div className="col-5 text-end pt-2">
                    <label htmlFor="link_image">
                      <Input
                        accept="image/*"
                        id="link_image"
                        multiple
                        type="file"
                        name="link_image"
                        onChange={fileChangedHandler}
                      />

                      <Button
                        className="small-contained-button"
                        startIcon={<FileUploadIcon />}
                        component="span"
                      >
                        Upload
                      </Button>
                    </label>
                  </div>
                </div>
                {inputs?.link_image?.name == "" ? (
                  ""
                ) : (
                  <p className="text-secondary">{inputs?.link_image?.name}</p>
                )}
              </div>
            </>
          )}
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Upload Image *</p>
                <FormHelperText className="pt-0">
                  Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {(images.image || oldImages.image) && (
                  <img
                    src={
                      images.image ? images.image : s3baseUrl + oldImages.image
                    }
                    height="50"
                  />
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="image"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.image?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.image?.name}</p>
            )}
          </div>
          <div className="col-12 mt-3">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Short Description"
                multiline
                rows={6}
                name="short_description"
                value={inputs.short_description}
                onChange={handleChange}
                required
              />
              <FormHelperText>Maximum limit 500 characters</FormHelperText>
            </FormControl>
          </div>
          <div className="col-12 mt-4">
            <h4>Detail Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="detailed_description"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-4">
            <h4>Banner Description 1</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="banner_1_description"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-4">
            <h4>Banner Description 2</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="banner_2_description"
              editorHeight={320}
            />
          </div>
          <div
            className="text-end mt-4"
            id={formType == "ADD" ? "" : "fixedbutton"}
          >
            <button className="small-contained-button">
              {formType == "ADD" ? "Submit" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
