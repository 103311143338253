import React from "react";
import { Icon } from "@iconify/react";
import { Tooltip } from "@mui/material";

export default function ActiveUsersChartHeader({ data, toggleNumber }) {
  const { counts } = data;

  const LABELS = [
    {
      label: "1 Day",
      color: "rgb(114, 7, 150)",
      count: counts["1_day_active_users"],
      value: "1day",
    },
    {
      label: "7 Days",
      color: "rgb(71, 71, 235)",
      count: counts["7_day_active_users"],
      value: "7days",
    },
    {
      label: "30 Days",
      color: "rgb(26, 115, 232)",
      count: counts["30_day_active_users"],
      value: "30days",
    },
  ];

  return (
    <div className="d-flex active-users-chart-header justify-content-between">
      <h3>User activity over time</h3>

      <Tooltip
        title={
          <>
            Shows the number of users who used your app in the past
            <br />
            month (30 days), week (7 days), and day (1 day)
          </>
        }
      >
        <Icon className="information-icon" icon={"eva:alert-circle-outline"} />
      </Tooltip>

      {/* <div className="d-flex">
        {LABELS.map((item, index) => (
          <div
            key={index}
            className="d-flex align-content-center me-3"
            aria-label="Click here to hide the performance in graph"
            style={{
              alignItems: "center",
              cursor: "pointer",
              opacity: 1,
            }}
            onClick={() => toggleNumber(item.value)}
          >
            <div
              className="user-activity-color-box me-1"
              style={{ backgroundColor: item.color }}
            ></div>
            <div className="user-activity-color-label">{`${item.label} (${item.count})`}</div>
          </div>
        ))}
      </div> */}
    </div>
  );
}
