import { useEffect, useState } from "react";
import { CircularProgress, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import moment from "moment";
import { dd_date_format, show_proper_words } from "../../../utils/constant";
import Label from "src/components/Label";
import CopyToClipboard from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const TABLE_HEAD = [
  { id: "number", label: "#", type: "number" },
  { id: "product", label: "Product" },
  {
    id: "subscription_created_by",
    label: "Created By",
    className: "text-capitalize",
    renderData: (row) => {
      return (
        <p className="mb-0">
          {show_proper_words(`${row.subscription_created_by}`)}{" "}
        </p>
      );
    },
  },
  { id: "stripe_mode", label: "Subscription Mode" },
  { id: "invoice_date", label: "Next Invoice Date" },
  {
    id: "created_at",
    label: "Subscription Date",
  },
  {
    id: "member_name",
    label: "Subscription ID",
    renderData: (row) => {
      return (
        <>
          {row?.stripe_subscription_id ? (
            <span>
              <CopyToClipboard
                className="pointer me-2"
                text={row?.stripe_subscription_id}
                onCopy={() => handleCopiedUrl(true)}
              >
                <Tooltip title="Copy Subscription ID">
                  <ContentCopyIcon
                    style={{
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </CopyToClipboard>
              {row?.stripe_subscription_id}
            </span>
          ) : (
            "N/A"
          )}
        </>
      );
    },
  },
  {
    id: "statusDate",
    label: "Status",
    renderData: (row) => {
      return (
        <Label
          variant="ghost"
          color={row.statusDate === false ? "error" : "success"}
        >
          {row.statusDate === false ? "Expired" : "Active"}
        </Label>
      );
    },
  },
];

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function MemberSubscriptions({ tabData }) {
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionsData, setSubscriptionsData] = useState([]);
  const classes = useStyles();

  const getMembersList = () => {
    setIsLoading(true);
    const members = tabData?.map((event, index) => {
      let created_at = "N/A";
      if (event.createdAt) {
        created_at = moment.utc(event.createdAt).format("DD-MM-YYYY");
      }

      let product = "N/A";
      if (event.sale_page) {
        product = `Sale Page (${event.sale_page.sale_page_title} | ${event?.plan?.plan_title})`;
      } else if (event.subscription_created_by === "clickfunnels") {
        product = `Click Funnels (${event.plan.plan_title})`;
      } else if (event.subscription_type === "quest") {
        if (event.mission_info) {
          product = `Quest (${event.mission_info.title})`;
        }
      } else {
        if (event.payment_request_id) {
          product = `Payment Request (${show_proper_words(
            event.payment_request_id.request_title +
              " | " +
              event.payment_request_id.request_type
          )})`;
        }
      }
      let invoice_date = "N/A";
      if (event.next_invoice_date) {
        invoice_date = dd_date_format(event.next_invoice_date);
      }

      return {
        ...event,
        product,
        created_at,
        invoice_date,
        statusDate: event.subscription_status,
      };
    });
    setSubscriptionsData(members);
    setIsLoading(false);
  };

  useEffect(() => {
    getMembersList();
  }, [tabData]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="mt-3">
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={subscriptionsData}
          className="card-with-background"
        />
      </div>
    </>
  );
}
