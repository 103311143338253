import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton } from "@mui/material";
import {
  locked_program_info_api,
  programmeDetail,
  programme_detail_api,
} from "src/DAL/Programme/Programme";
import { s3baseUrl } from "src/config/config";
import { useEffect } from "react";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const Input = styled("input")({
  display: "none",
});

export default function LockedProgramInfo() {
  const navigate = useNavigate();

  const { state } = useLocation();
  const { programme_slug } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [programName, setProgramName] = useState("");
  const [images, setImages] = useState({
    image: "",
  });

  const [oldImages, setOldImages] = useState({
    image: "",
  });

  const [inputs, setInputs] = useState({
    video_url: "",
    short_description: "",
    detailed_description: "",
  });

  const fileChangedHandler = (e) => {
    const { name, files } = e.target;
    setImages({
      ...images,
      [name]: URL.createObjectURL(files[0]),
    });

    setInputs({
      ...inputs,
      [name]: files[0],
    });
  };

  const handleNavigate = () => {
    navigate(`/programmes/programmes-detail/${programme_slug}`);
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("video_url", inputs.video_url);
    formData.append("short_description", inputs.short_description);
    formData.append("detailed_description", inputs.detailed_description);

    if (images.image) {
      formData.append("locked_programme_image", inputs.image);
    }

    const result = await locked_program_info_api(formData, programme_slug);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      handleNavigate();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleFormat = (data) => {
    setInputs(data);
    setOldImages({
      ...oldImages,
      ["image"]: data.image,
    });
    setIsLoading(false);
  };

  const handleDetail = async () => {
    setIsLoading(true);
    let result = await programmeDetail(programme_slug);
    if (result.code === 200) {
      handleFormat(result.program.locked_program_info);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getProgrammeDetail = async () => {
    setIsLoading(true);
    const result = await programme_detail_api(programme_slug);
    if (result.code === 200) {
      setProgramName(result?.program?.title);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (state) {
      handleFormat(state.locked_program_info);
    } else {
      handleDetail();
    }
  }, []);
  let breadCrumbMenu = [
    {
      title: "Programmes",
      navigation: `/programmes`,
      active: false,
    },

    {
      title: state ? state?.title : programName ? programName : "",
      navigation: `/programmes/programmes-detail/${programme_slug}`,
      active: false,
    },
    {
      title: "Locked Programme",
      navigation: ``,
      active: true,
    },
  ];
  useEffect(() => {
    if (programme_slug) {
      if (state) {
      } else {
        getProgrammeDetail();
      }
    }
  }, []);
  useEffect(() => {
    getProgrammeDetail();
  }, []);

  if (isLoading === true) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 mb-4">
          <span className="">
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2>Locked Programme</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Locked Main Video URL"
            variant="outlined"
            fullWidth
            name="video_url"
            value={inputs.video_url}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Upload Image </p>
              <FormHelperText className="pt-0">
                Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-2">
              {(images.image || oldImages.image) && (
                <img
                  src={
                    images.image ? images.image : s3baseUrl + oldImages.image
                  }
                  height="50"
                />
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  name="image"
                  onChange={fileChangedHandler}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          {inputs?.image?.name == "" ? (
            ""
          ) : (
            <p className="text-secondary">{inputs?.image?.name}</p>
          )}
        </div>

        <div className="col-12 mt-5">
          <FormControl fullWidth>
            <TextField
              id="outlined-multiline-flexible"
              label="Short Description "
              multiline
              rows={6}
              name="short_description"
              value={inputs.short_description}
              onChange={handleChange}
            />
            <FormHelperText>Maximum limit 500 characters</FormHelperText>
          </FormControl>
        </div>

        <div className="col-12 mt-4">
          <h4>Detail Description</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="detailed_description"
            editorHeight={320}
          />
        </div>

        <div className="text-end mt-4" id="fixedbutton">
          <button onClick={handleSubmit} className="small-contained-button">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
