import React, { useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { s3baseUrl } from "src/config/config";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { useSnackbar } from "notistack";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";
import { useState } from "react";
import ReactVideoPlayer from "src/components/ReactVideoPlayer/ReactVideoPlayer";
import {
  delete_lesson_api,
  lesson_detail_api,
} from "src/DAL/Programme/Lessons/Lessons";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import PageLoader from "src/components/GeneralComponents/PageLoader";

function LessonsDetail() {
  const params = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [lessonDetailInfo, setLessonsDetailInfo] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [lessonName, setlessonName] = useState("");
  console.log(state, "statestate");
  let programName = lessonDetailInfo?.program[0]?._id?.title;
  let program_slug_navgation = lessonDetailInfo?.program[0]?._id?.program_slug;
  console.log(programName, "programName");
  const handleOpenDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete = () => setOpenDelete(false);

  const handleNavigateEdit = (value) => {
    navigate(
      `/lessons/edit-lesson/${lessonDetailInfo.program[0]._id.program_slug}/${params.lesson_slug}`,
      {
        state: lessonDetailInfo,
      }
    );
  };
  const handleNavigateConfiguration = (value) => {
    navigate(
      `/lessons/tab-configuration/${lessonDetailInfo.program[0]._id.program_slug}/${params.lesson_slug}`,
      {
        state: lessonDetailInfo,
      }
    );
  };

  const handleQuestionsConfiguration = () => {
    navigate(`/lessons/questions-configuration/${params.lesson_slug}`, {
      state: lessonDetailInfo,
    });
  };

  const handleNavigateDocs = () => {
    navigate(`/lessons/lesson-document/${params.lesson_slug}`, {
      state: lessonDetailInfo,
    });
  };

  const handleNavigateLessonRecordings = () => {
    navigate(`/lessons/lesson-recordings/${params.lesson_slug}`, {
      state: lessonDetailInfo,
    });
  };

  const handleNavigateQuestionsAnswers = () => {
    navigate(
      `/lessons/lessons-questions-answers?created_for_id=${lessonDetailInfo._id}`,
      {
        state: lessonDetailInfo,
      }
    );
  };

  const handleLessonsQuestions = () => {
    navigate(
      `/lessons/lessons-questions?created_for_id=${lessonDetailInfo._id}`,
      {
        state: lessonDetailInfo,
      }
    );
  };

  const handleDelete = async () => {
    handleCloseDelete();
    setIsLoading(true);
    let result = await delete_lesson_api(params.lesson_slug);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      handleNavigateBack();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleDetail = async () => {
    setIsLoading(true);
    let result = await lesson_detail_api(params.lesson_slug);
    if (result.code === 200) {
      setlessonName(result.lesson.title);
      setLessonsDetailInfo(result.lesson);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleNavigateBack = () => {
    let program_slug = lessonDetailInfo.program[0]._id.program_slug;
    navigate(`/lessons/${program_slug}`);
  };
  let program_slug = lessonDetailInfo?.program[0]?._id?.program_slug;

  let breadCrumbMenu = [
    {
      title: "Programmes",
      navigation: `/programmes`,
      active: false,
    },
    {
      title: programName,
      navigation: `/programmes/programmes-detail/${program_slug_navgation}`,
      active: false,
    },
    {
      title: "Lessons",
      navigation: `/lessons/${program_slug}`,
      active: false,
    },
    {
      title: lessonDetailInfo?.title,
      navigation: ``,
      active: true,
    },
  ];
  if (lessonDetailInfo?.title) {
    localStorage.setItem("lesson_title", lessonDetailInfo?.title);
    localStorage.setItem("lesson_slug", params.lesson_slug);
  }

  useEffect(() => {
    if (state) {
      setLessonsDetailInfo(state);
      setIsLoading(false);
    } else {
      handleDetail();
    }
  }, []);

  if (isLoading === true) {
    return <PageLoader />;
  }

  let child_menu_options = [
    {
      label: "Questions Setting",
      icon: "akar-icons:edit",
      handleClick: handleQuestionsConfiguration,
    },
    {
      label: "Manage Questions",
      icon: "akar-icons:edit",
      handleClick: handleLessonsQuestions,
    },
    {
      label: "Questions Answers",
      icon: "akar-icons:edit",
      handleClick: handleNavigateQuestionsAnswers,
    },
  ];

  let MENU_OPTIONS = [
    {
      label: "Manage Doc",
      icon: "akar-icons:edit",
      handleClick: handleNavigateDocs,
    },
    {
      label: "Manage Recording",
      icon: "akar-icons:edit",
      handleClick: handleNavigateLessonRecordings,
    },
    {
      label: "Tabs Configuration",
      icon: "akar-icons:edit",
      handleClick: handleNavigateConfiguration,
    },
    {
      label: "Questions Configuration",
      icon: "akar-icons:edit",
      child_options: child_menu_options,
    },
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleNavigateEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleOpenDelete,
    },
  ];

  return (
    <div className="container-fluid">
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this lesson?"}
        handleAgree={handleDelete}
      />
      <div className="col-lg-12 col-sm-12 text-end"></div>
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex mb-3">
          {/* <IconButton
            className="back-screen-button"
            onClick={handleNavigateBack}
          >
            <ArrowBackIcon />
          </IconButton> */}
          <span className="mt-2">
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
      </div>
      <div className="row section-space">
        <div className="col-12 d-flex justify-content-between">
          <h2>{lessonDetailInfo?.title}</h2>
          <CustomPopoverSection menu={MENU_OPTIONS} data={lessonDetailInfo} />
        </div>
      </div>

      <div className="row media-margin">
        <div className="col-12">
          {lessonDetailInfo?.landing_lesson_video_url == "" ? (
            <img
              width={"100%"}
              src={
                lessonDetailInfo?.lesson_images
                  ? s3baseUrl + lessonDetailInfo.lesson_images.thumbnail_1
                  : ""
              }
            />
          ) : (
            <ReactVideoPlayer
              url={lessonDetailInfo?.landing_lesson_video_url}
            />
          )}
        </div>
        <div className="col-12 section-space">
          {lessonDetailInfo?.audio_file == "" ? (
            ""
          ) : (
            <audio
              className="w-100"
              src={
                lessonDetailInfo?.audio_file
                  ? s3baseUrl + lessonDetailInfo?.audio_file
                  : ""
              }
              controls
            />
          )}
        </div>
        <div className="col-12 section-space">
          <p>
            <div
              dangerouslySetInnerHTML={{
                __html: lessonDetailInfo?.detailed_description,
              }}
            ></div>
          </p>
        </div>
      </div>
    </div>
  );
}

export default LessonsDetail;
