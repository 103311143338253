import { useEffect, useState } from "react";
import {
  Card,
  Chip,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import jsPDF from "jspdf";
import Label from "src/components/Label";
import ReactDOMServer from "react-dom/server";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import arrowCircleDownFill from "@iconify/icons-eva/arrow-circle-down-fill";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { orderDetailList } from "src/DAL/Transaction/transactionApi";
import { useNavigate, useParams } from "react-router-dom";
import { s3baseUrl } from "src/config/config";
import { Icon } from "@iconify/react";
import { convertCurrencyToSign, replace_created_for } from "src/utils/constant";
import moment from "moment";
import { download_csv_file } from "src/utils/convertHtml";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import { orderShopDetailList } from "src/DAL/ShopProductCategoryList/ProductCategory";
import PageLoader from "src/components/GeneralComponents/PageLoader";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "title", label: "Title", alignRight: false },

  {
    id: "table_avatar",
    label: "Product Image",
    alignRight: false,
    type: "thumbnail",
  },
  { id: "quantity", label: "Quantity", alignRight: false },
  { id: "price", label: "Price", alignRight: false },
];
const TABLE_HEAD_tickets = [
  { id: "number", label: "#", type: "number" },
  { id: "name", label: "Name" },

  { id: "email", label: "Email" },
  { id: "contact_number", label: "Phone" },
  { id: "venue_title", label: "Venue" },
];
const headers = [
  { label: "Title", key: "title" },
  { label: "Quantity", key: "quantity" },
  { label: "Price", key: "price" },
];

export default function ShopOrderDetail({ leadStatusData }) {
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = useState(false);
  const [ticketsList, setTicketsList] = useState([]);
  const [ProductList, setProductList] = useState([]);
  const [ProductListDetail, setProductListDetail] = useState([]);
  const [orderDeatail, setOrderDeatail] = useState();
  const [isDonwloadingPdf, setIsDonwloadingPdf] = useState(false);

  const getBookingStatusList = async () => {
    setIsLoading(true);
    const result = await orderShopDetailList(leadStatusData._id);
    if (result.code == 200) {
      setOrderDeatail(result.order_detail);
      let product_data = [];
      setProductListDetail(result.order_detail.product_order_id);
      product_data = result.order_detail.order_items.map((item) => {
        return {
          ...item,
          title: item.product_name,
          price: item.price,
          quantity: item.quantity,
          table_avatar: {
            src: s3baseUrl + item.product_image[0].image,
            alt: item.product_name,
          },
        };
      });
      setProductList(product_data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleExportClick = () => {
    download_csv_file(headers, ProductList);
  };

  const order_status = (row) => {
    let chipColor;
    let chipTextcolor;
    switch (row.order_status) {
      case "pending":
        chipColor = "#ff9800";
        chipTextcolor = "#fff";
        break;
      case "processing":
        chipColor = "#c19a6b";
        chipTextcolor = "#fff";
        break;
      case "dispatched":
        chipColor = "#0288d1";
        chipTextcolor = "#fff";
        break;
      case "completed":
        chipColor = "#4caf50";
        chipTextcolor = "#fff";
        break;
      case "delivered":
        chipColor = "#4caf50";
        chipTextcolor = "#fff";

        break;
      case "cancelled":
        chipColor = "rgb(211, 47, 47)";
        chipTextcolor = "#fff";

        break;
      default:
        chipColor = "#ff9800";
        chipTextcolor = "#fff";

        break;
    }
    return (
      <Chip
        label={row.order_status}
        style={{
          backgroundColor: chipColor,
          color: "white",
          textTransform: "capitalize",
        }}
      ></Chip>
    );
  };

  const referralName = (refferal_name, affiliate_url_name) => {
    return (
      <div>
        {`${refferal_name} `}
        {affiliate_url_name && (
          <span className="affiliate_url_name">({affiliate_url_name})</span>
        )}
      </div>
    );
  };

  function convertHtmlToString(html) {
    return ReactDOMServer.renderToString(html);
  }
  const handleGeneratePdf = () => {
    setIsDonwloadingPdf(true);
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });

    const html = (
      <div>
        <div className="container-fluid">
          <div className="row ">
            <div className="col-lg-4 col-sm-12">
              <h2>Products</h2>
            </div>
            <div className="col-lg-8 col-sm-12 text-end d-flex justify-content-end">
              <div>
                <button
                  className="small-contained-button me-2 mt-1 mb-4"
                  onClick={handleExportClick}
                >
                  Export Csv &nbsp;&nbsp; <Icon icon={arrowCircleDownFill} />
                </button>
              </div>
            </div>
          </div>
          {/* table */}
          <div
            className="row mt-3 p-3 card-with-background"
            style={{ borderRadius: "16px" }}
          >
            <table class="table table-nowrap ">
              <thead>
                <tr>
                  <th scope="col" className="size-table">
                    #
                  </th>
                  <th scope="col" className="size-table fixed-width">
                    Product Title
                  </th>

                  <th scope="col" className="size-table fixed-width">
                    Quantity
                  </th>
                  <th scope="col" className="size-table fixed-width">
                    Unit Price
                  </th>

                  <th scope="col" className="size-table fixed-width">
                    Total Price
                  </th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <th className="size-table" scope="row"></th>
                    <td className="size-table"></td>
                    <td className="size-table"></td>
                    <td className="size-table"></td>
                    <td className="size-table">
                      <CircularProgress color="primary" />
                    </td>
                    <td className="size-table"></td>
                    <td className="size-table"></td>
                    <td className="size-table"></td>
                  </tr>
                ) : (
                  <>
                    {ProductList.length > 0 &&
                      ProductList?.map((items, index) => {
                        return (
                          <tr>
                            <th className="size-table" scope="row">
                              {index + 1}
                            </th>
                            <td className="size-table">{items.title}</td>

                            <td className="size-table">
                              {`${items.quantity}`}
                            </td>
                            <td className="size-table">
                              {convertCurrencyToSign(items.product_id.currency)}
                              {items.price.toFixed(2)}
                            </td>
                            <td className="size-table">
                              {convertCurrencyToSign(items.product_id.currency)}
                              {(items.quantity * items.price).toFixed(2)}
                            </td>
                          </tr>
                        );
                      })}
                  </>
                )}
              </tbody>
            </table>
            {/* bottom prices box */}
            <div class="row border-div mb-4">
              <div className="col-7"></div>
              <div className="col-5 text-end px-4 mb-2">
                <div className="justify-content-between d-flex">
                  <div>
                    <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                      Sub Total:{" "}
                    </Typography>
                  </div>
                  <div>
                    <Typography sx={{ fontSize: "14px" }}>
                      {convertCurrencyToSign(ProductListDetail.currency)}
                      {ProductListDetail.sub_total.toFixed(2)}
                    </Typography>
                  </div>
                </div>
                {ProductListDetail?.tax_rate_id && (
                  <div className="mt-2 justify-content-between d-flex">
                    <div>
                      <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                        {ProductListDetail?.tax_rate_id
                          ? ProductListDetail?.tax_rate_id?.tax_type_title
                          : ""}
                        Tax
                      </Typography>
                    </div>
                    <div>
                      <Typography sx={{ fontSize: "14px" }}>
                        {convertCurrencyToSign(ProductListDetail.currency)}
                        {ProductListDetail.tax_charges.toFixed(2)}
                      </Typography>
                    </div>
                  </div>
                )}
                <div className="mt-2 justify-content-between d-flex">
                  <div>
                    <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                      Shipping Charges:{" "}
                    </Typography>
                  </div>
                  <div>
                    <Typography sx={{ fontSize: "14px" }}>
                      {convertCurrencyToSign(ProductListDetail.currency)}
                      {ProductListDetail.shipping_charge
                        ? ProductListDetail.shipping_charge.toFixed(2)
                        : "0"}
                    </Typography>
                  </div>
                </div>
                <hr></hr>
                <div className="mt-2 justify-content-between d-flex">
                  <div>
                    <Typography
                      sx={{
                        mr: 12,
                        fontWeight: "bold",
                        fontSize: "14px",
                      }}
                    >
                      Grand Total:
                    </Typography>
                  </div>
                  <div>
                    <Typography sx={{ fontSize: "14px" }}>
                      {convertCurrencyToSign(ProductListDetail.currency)}
                      {ProductListDetail.total.toFixed(2)}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    const html2 = convertHtmlToString(html);
    doc.html(html2, {
      margin: [15, 25, 20, 20],
      autoPaging: "text",
      callback: (doc) => {
        doc.save();
        setIsDonwloadingPdf(false);
      },
    });
  };

  useEffect(() => {
    getBookingStatusList();
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-3"></div>
        <Card className="payment-card mb-3">
          <div className="row p-3">
            <div className="col-12">
              <div className="row">
                <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                  Member:
                </div>
                {orderDeatail?.user_info ? (
                  <div className="col-7 col-md-6 col-lg-9 text-muted mb-3">
                    {orderDeatail.user_info.first_name +
                      " " +
                      orderDeatail.user_info.last_name +
                      "(" +
                      orderDeatail.user_info.email +
                      ")"}
                  </div>
                ) : (
                  <div className="col-7 col-md-6 col-lg-9 text-muted mb-3">
                    {orderDeatail.user_name}
                  </div>
                )}
                <div className="col-5 col-md-6 col-lg-3 mt-5 mt-lg-0 request-type">
                  <p className="finalPrice">Total Amount:</p>
                </div>
                <div className="col-7 col-md-6 col-lg-3  mt-5 mt-lg-0 text-muted">
                  {convertCurrencyToSign(orderDeatail.currency)}
                  {orderDeatail.total.toFixed(2)}
                </div>
                <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                  Order Date:
                </div>
                <div className="col-7 col-md-6 col-lg-3 text-muted mb-3">
                  <Icon icon="uiw:date" className="finalPrice" />
                  <span className="ms-1 mt-1">
                    {moment
                      .utc(orderDeatail.createdAt)
                      .format("DD-MM-YYYY hh:mm A")}
                  </span>
                </div>
                <div className="col-5 col-md-6 col-lg-3 mt-5 mb-3 mt-lg-0 request-type">
                  Payment Made By:
                </div>
                <div className="col-7 col-md-6 col-lg-3  mt-5 mb-3 mt-lg-0 text-muted">
                  {orderDeatail.payment_method
                    ? orderDeatail.payment_method
                    : "N/A"}
                </div>
                <div className="col-5 col-md-6 col-lg-3 mt-5  mb-3 mt-lg-0 request-type">
                  Order Placed By:
                </div>
                <div className="col-7 col-md-6 col-lg-3 mb-3  mt-5 mt-lg-0 text-muted text-capitalize">
                  {replace_created_for(orderDeatail.order_placed_by)}
                </div>
                <div className="col-5 col-md-6 col-lg-3 mb-3 mt-5 mt-lg-0 request-type">
                  Transaction Status:
                </div>
                <div className="col-7 col-md-6 col-lg-3 mb-3  mt-5 mt-lg-0 text-muted text-capitalize">
                  <Label
                    variant="ghost"
                    color={
                      orderDeatail.payment_status === "paid"
                        ? "success"
                        : "error"
                    }
                  >
                    {replace_created_for(orderDeatail.payment_status)}
                  </Label>
                </div>
                {orderDeatail?.transaction_note && (
                  <>
                    <div className="col-5 col-md-6 col-lg-3 mb-3 mt-5 mt-lg-0 request-type">
                      Transaction Note:
                    </div>
                    <div className="col-7 col-md-6 col-lg-3 mb-3  mt-5 mt-lg-0 text-muted text-capitalize">
                      {orderDeatail?.transaction_note}
                    </div>
                  </>
                )}
                <div className="col-5 col-md-6 col-lg-3 mt-5 mt-lg-0 request-type">
                  {" "}
                  Order Type:
                </div>
                <div className="col-7 col-md-6 col-lg-3  mt-5 mt-lg-0 ">
                  {order_status(orderDeatail)}
                </div>
                {orderDeatail.shipping_address.first_name ? (
                  <>
                    <div className="mt-3 mb-3">
                      <h3 className="text-center finalPrice">
                        Shipping Information
                      </h3>
                    </div>
                    <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                      Name
                    </div>
                    <div className="col-7 col-md-6 col-lg-9 text-muted mb-3">
                      {orderDeatail.shipping_address.first_name +
                        " " +
                        orderDeatail.shipping_address.last_name +
                        "(" +
                        orderDeatail.shipping_address.email +
                        ")"}
                    </div>
                    <div className="col-5 col-md-6 col-lg-3 mt-5 mt-lg-0 request-type">
                      <p className="">Contact No:</p>
                    </div>
                    <div className="col-7 col-md-6 col-lg-3  mt-5 mt-lg-0 text-muted">
                      {orderDeatail.shipping_address.contact_number}
                    </div>
                    <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                      Country:
                    </div>
                    <div className="col-7 col-md-6 col-lg-3 text-muted mb-3">
                      {orderDeatail.shipping_address.country}
                    </div>
                    <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                      State:
                    </div>
                    <div className="col-7 col-md-6 col-lg-3 text-muted mb-3">
                      {orderDeatail.shipping_address.state}
                    </div>

                    <div className="col-5 col-md-6 col-lg-3 mt-5 mt-lg-0 request-type">
                      <p className="">City:</p>
                    </div>
                    <div className="col-7 col-md-6 col-lg-3  mt-5 mt-lg-0 text-muted">
                      {orderDeatail.shipping_address.city}
                    </div>

                    <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                      Zip Code:
                    </div>
                    <div className="col-7 col-md-6 col-lg-3 text-muted mb-3">
                      {orderDeatail.shipping_address.zip_code}
                    </div>

                    <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                      Street:
                    </div>
                    <div className="col-7 col-md-6 col-lg-3 text-muted mb-3">
                      {orderDeatail.shipping_address.street}
                    </div>
                  </>
                ) : (
                  <>
                    {orderDeatail.shipping_address &&
                      Object.keys(orderDeatail.shipping_address).length > 0 && (
                        <>
                          <div className="mt-3 mb-3">
                            <h3 className="text-center finalPrice">
                              Shipping Info
                            </h3>
                          </div>
                          <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                            Country:
                          </div>
                          <div className="col-7 col-md-6 col-lg-3 text-muted mb-3">
                            {orderDeatail.shipping_address.country}
                          </div>
                          <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                            State:
                          </div>
                          <div className="col-7 col-md-6 col-lg-3 text-muted mb-3">
                            {orderDeatail.shipping_address.state}
                          </div>
                          <div className="col-5 col-md-6 col-lg-3 mt-5 mt-lg-0 request-type">
                            <p className="">City:</p>
                          </div>
                          <div className="col-7 col-md-6 col-lg-3  mt-5 mt-lg-0 text-muted">
                            {orderDeatail.shipping_address.city}
                          </div>
                          <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                            Zip Code:
                          </div>
                          <div className="col-7 col-md-6 col-lg-3 text-muted mb-3">
                            {orderDeatail.shipping_address.postal_code
                              ? orderDeatail.shipping_address.postal_code
                              : orderDeatail.shipping_address.zip_code
                              ? orderDeatail.shipping_address.zip_code
                              : "N/A"}
                          </div>{" "}
                          {orderDeatail.shipping_address.line1 && (
                            <>
                              <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                                Street:
                              </div>
                              <div className="col-7 col-md-6 col-lg-3 text-muted mb-3">
                                {orderDeatail.shipping_address.line1}
                              </div>
                            </>
                          )}{" "}
                          {orderDeatail.shipping_address.street && (
                            <>
                              <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                                Street:
                              </div>
                              <div className="col-7 col-md-6 col-lg-3 text-muted mb-3">
                                {orderDeatail.shipping_address.street}
                              </div>
                            </>
                          )}
                        </>
                      )}
                  </>
                )}
                {orderDeatail.billing_address.address &&
                Object.keys(orderDeatail.billing_address.address).length > 0 ? (
                  <>
                    <div className="mt-3 mb-3">
                      <h3 className="text-center finalPrice">
                        Billing Address
                      </h3>
                    </div>
                    <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                      Country:
                    </div>
                    <div className="col-7 col-md-6 col-lg-3 text-muted mb-3">
                      {orderDeatail.billing_address.address.country}
                    </div>
                    <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                      State:
                    </div>
                    <div className="col-7 col-md-6 col-lg-3 text-muted mb-3">
                      {orderDeatail.billing_address.address.state}
                    </div>
                    <div className="col-5 col-md-6 col-lg-3 mt-5 mt-lg-0 request-type">
                      <p className="">City:</p>
                    </div>
                    <div className="col-7 col-md-6 col-lg-3  mt-5 mt-lg-0 text-muted">
                      {orderDeatail.billing_address.address.city}
                    </div>
                    <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                      Zip Code:
                    </div>
                    <div className="col-7 col-md-6 col-lg-3 text-muted mb-3">
                      {orderDeatail.billing_address.address.postal_code
                        ? orderDeatail.billing_address.address.postal_code
                        : "N/A"}
                    </div>{" "}
                    {orderDeatail.billing_address.address.line1 && (
                      <>
                        <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                          Street:
                        </div>
                        <div className="col-7 col-md-6 col-lg-3 text-muted mb-3">
                          {orderDeatail.billing_address.address.line1}
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {orderDeatail.billing_address &&
                      Object.keys(orderDeatail.billing_address).length > 0 && (
                        <>
                          <div className="mt-3 mb-3">
                            <h3 className="text-center finalPrice">
                              Billing Address
                            </h3>
                          </div>
                          <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                            Country:
                          </div>
                          <div className="col-7 col-md-6 col-lg-3 text-muted mb-3">
                            {orderDeatail.billing_address.country}
                          </div>
                          <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                            State:
                          </div>
                          <div className="col-7 col-md-6 col-lg-3 text-muted mb-3">
                            {orderDeatail.billing_address.state ?? "N/A"}
                          </div>
                          <div className="col-5 col-md-6 col-lg-3 mt-5 mt-lg-0 request-type">
                            <p className="">City:</p>
                          </div>
                          <div className="col-7 col-md-6 col-lg-3  mt-5 mt-lg-0 text-muted">
                            {orderDeatail.billing_address.city}
                          </div>
                          <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                            Zip Code:
                          </div>
                          <div className="col-7 col-md-6 col-lg-3 text-muted mb-3">
                            {orderDeatail.billing_address.zip_code
                              ? orderDeatail.billing_address.zip_code
                              : "N/A"}
                          </div>{" "}
                          {orderDeatail.billing_address.street && (
                            <>
                              <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                                Street:
                              </div>
                              <div className="col-7 col-md-6 col-lg-4 text-muted mb-3">
                                {orderDeatail.billing_address.street}
                              </div>
                            </>
                          )}
                        </>
                      )}
                  </>
                )}
              </div>
            </div>
          </div>
        </Card>
        {ProductList.length > 0 && (
          <div>
            <div className="container-fluid">
              <div className="row ">
                <div className="col-lg-4 col-sm-12">
                  <h3 style={{ color: "white" }}>Products Information</h3>
                </div>
              </div>
              <div
                className="row mt-3 p-3 card-with-background"
                style={{ borderRadius: "16px" }}
              >
                <table class="table table-nowrap ">
                  <thead>
                    <tr>
                      <th scope="col" className="size-table">
                        #
                      </th>
                      <th scope="col" className="size-table fixed-width">
                        Product Title
                      </th>

                      <th scope="col" className="size-table fixed-width">
                        Quantity
                      </th>
                      <th scope="col" className="size-table fixed-width">
                        Unit Price
                      </th>

                      <th scope="col" className="size-table fixed-width">
                        Total Price
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <th className="size-table" scope="row"></th>
                        <td className="size-table"></td>
                        <td className="size-table"></td>
                        <td className="size-table"></td>
                        <td className="size-table">
                          <CircularProgress color="primary" />
                        </td>
                        <td className="size-table"></td>
                        <td className="size-table"></td>
                        <td className="size-table"></td>
                      </tr>
                    ) : (
                      <>
                        {ProductList.length > 0 &&
                          ProductList?.map((items, index) => {
                            return (
                              <tr>
                                <th className="size-table" scope="row">
                                  {index + 1}
                                </th>
                                <td className="size-table">{items.title}</td>

                                <td className="size-table">
                                  {`${items.quantity}`}
                                </td>
                                <td className="size-table">
                                  {convertCurrencyToSign(orderDeatail.currency)}
                                  {items.price.toFixed(2)}
                                </td>
                                <td className="size-table">
                                  {convertCurrencyToSign(orderDeatail.currency)}
                                  {(items.quantity * items.price).toFixed(2)}
                                </td>
                              </tr>
                            );
                          })}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* bottom prices box */}
            <div className="container-fluid">
              <div class="row  mt-1 mb-4">
                <div className="col-6"></div>
                <div
                  className="col-6 border-div p-3 card text-end px-4 mb-2"
                  style={{ borderRadius: "16px" }}
                >
                  <div className="justify-content-between d-flex">
                    <div>
                      <Typography sx={{ fontSize: "16px", color: "white" }}>
                        Sub Total:
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        sx={{ fontSize: "16px" }}
                        className="text-muted"
                      >
                        {convertCurrencyToSign(orderDeatail.currency)}
                        {orderDeatail.sub_total?.toFixed(2)}
                      </Typography>
                    </div>
                  </div>
                  {orderDeatail.discount > 0 && (
                    <div className="justify-content-between d-flex">
                      <div>
                        <Typography sx={{ fontSize: "16px", color: "white" }}>
                          Discount Amount:
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          sx={{ fontSize: "16px" }}
                          className="text-muted"
                        >
                          {convertCurrencyToSign(
                            orderDeatail
                              ? orderDeatail.currency
                              : orderDeatail.currency
                          )}
                          {orderDeatail.discount.toFixed(2)}
                        </Typography>
                      </div>
                    </div>
                  )}
                  <div className="justify-content-between d-flex">
                    <div>
                      <Typography sx={{ fontSize: "16px", color: "white" }}>
                        Shipping Fee:
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        sx={{ fontSize: "16px" }}
                        className="text-muted"
                      >
                        £{orderDeatail.shipping_charges.toFixed(2)}
                      </Typography>
                    </div>
                  </div>
                  <div className="justify-content-between d-flex">
                    <div>
                      <Typography sx={{ fontSize: "16px", color: "white" }}>
                        Tax
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        sx={{ fontSize: "16px" }}
                        className="text-muted"
                      >
                        {convertCurrencyToSign(orderDeatail.currency)}
                        {orderDeatail.tax_charges.toFixed(2)}
                      </Typography>
                    </div>
                  </div>
                  <div className="justify-content-between d-flex">
                    <div>
                      <Typography
                        sx={{
                          mr: 12,
                          fontSize: "16px",
                          color: "white",
                        }}
                      >
                        Grand Total:
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        sx={{ fontSize: "16px" }}
                        className="text-muted"
                      >
                        {convertCurrencyToSign(orderDeatail.currency)}
                        {(orderDeatail.total - +orderDeatail.discount)?.toFixed(
                          2
                        )}
                      </Typography>
                    </div>
                  </div>
                  <hr></hr>
                  <div className="justify-content-between d-flex">
                    <div>
                      <Typography
                        sx={{ fontSize: "16px" }}
                        className="finalPrice"
                      >
                        Charged Amount:
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        sx={{ fontSize: "16px" }}
                        className="finalPrice"
                      >
                        {convertCurrencyToSign(
                          orderDeatail.other_info?.converted_currency
                        )}
                        {(orderDeatail.other_info?.converted_total).toFixed(2)}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
