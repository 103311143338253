import * as React from "react";

import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import {
  add_quarter_api,
  quarter_detail_api,
  update_quarter_api,
} from "src/DAL/QuartersList/QuartersList";
import { useEffect } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { dd_date_format } from "src/utils/constant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function AddOrUpdateQuarter() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { quarter_slug } = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    title: "",
    status: true,
    order: 0,
    short_description: "",
    start_date: new Date(),
    detailed_description: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    let postData = {
      title: inputs.title,
      status: inputs.status,
      short_description: inputs.short_description,
      detailed_description: inputs.detailed_description,
      start_date: dd_date_format(inputs.start_date),
    };
    if (quarter_slug) {
      postData.order = +inputs.order;
    }
    setIsLoading(true);
    const result = quarter_slug
      ? await update_quarter_api(postData, quarter_slug)
      : await add_quarter_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeDate = (value) => {
    setInputs((values) => ({ ...values, start_date: value.$d }));
  };

  const get_quarter_detail = async () => {
    setIsLoading(true);
    let result = await quarter_detail_api(quarter_slug);
    if (result.code == 200) {
      handleFormat(result.quarter);
    } else {
      navigate(`/quarters`);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleFormat = (data) => {
    setInputs({ ...data, start_date: new Date(data.start_date) });
    setIsLoading(false);
    window.history.replaceState({}, document.title);
  };

  useEffect(() => {
    if (quarter_slug) {
      if (!state) {
        get_quarter_detail();
      } else {
        handleFormat(state);
      }
    }
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  console.log(inputs, "inputsinputsinputsinputs");

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2>{quarter_slug ? "Edit" : "Add"} Quarter</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              fullWidth
              required
              name="title"
              value={inputs.title}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-4">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Start Date"
                name="start_date"
                inputFormat="DD-MM-YYYY"
                value={inputs.start_date}
                format="DD-MM-YYYY"
                onChange={handleChangeDate}
                renderInput={(params) => (
                  <TextField {...params} required={true} />
                )}
              />
            </LocalizationProvider>
          </div>
          {quarter_slug && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Order"
                variant="outlined"
                fullWidth
                required
                name="order"
                value={inputs.order}
                onChange={handleChange}
              />
            </div>
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                value={inputs.status}
                label="Status"
                required
                onChange={handleChange}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-12 mt-4">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Short Description"
                multiline
                rows={6}
                name="short_description"
                value={inputs.short_description}
                onChange={handleChange}
              />
            </FormControl>
          </div>
          <div className="col-12 mt-4">
            <h4>Detail Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="detailed_description"
              editorHeight={320}
            />
          </div>
          <div
            className="text-end mt-4"
            id={!quarter_slug ? "" : "fixedbutton"}
          >
            <button className="small-contained-button">
              {!quarter_slug ? (
                <>{isLoading ? "Submitting..." : "Submit"}</>
              ) : (
                <>{isLoading ? "Updating..." : "Update"}</>
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
