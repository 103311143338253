import React from "react";
import MoveToNextPage from "./MoveToNextPage";

export default function ThirtyMinutesReport({ data }) {
  const { active_users, real_time_users_report } = data;
  return (
    <div className="thirty-minutes-report">
      <h2>{active_users}</h2>
      <div className="d-flex justify-content-between ">
        <h3>Top countries</h3>
        <h3>Active users</h3>
      </div>
      <hr />
      {real_time_users_report?.map((user) => {
        const { activeUsers, country } = user;
        return (
          <>
            <div className="d-flex justify-content-between ">
              <div>{country}</div>
              <div>{activeUsers}</div>
            </div>
            <hr />
          </>
        );
      })}
      <MoveToNextPage
        text="View all countries"
        move_path="/firebase-countries"
      />
    </div>
  );
}
