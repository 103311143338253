import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import { useEffect } from "react";
import { detailContentPageApi } from "src/DAL/WebsitePages/WebsitePages";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { free_plan_popup_setting_api } from "src/DAL/SaleSections/saleSection";
import PageLoader from "src/components/GeneralComponents/PageLoader";

export default function UpdateAppointmentContent() {
  const navigate = useNavigate();
  const { page_slug } = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [pageData, setPageData] = useState(null);

  const [inputs, setInputs] = useState({
    already_have_an_account_text: "Already have an account?",
    login_button_text: "Login",
    login_source_button_text: "The Source Login",
    form_top_text:
      "Step 1️⃣ - Register or Login To Book Your Free 60 Mins Business Call ",
    form_checkbox_text:
      "Yes Please, I would like to receive communications by email",

    calender_heading:
      "⏰ Please select your timezone for slots to show in your timezone.⏰",
    switch_account_text: "Want To Switch Account...?",
    button_one_text: "Submit",
    question_page_appointment_heading: "Appointment Details",
    slot_duration_top_text: "Slot duration",
    question_section_heading: "Some Things To Note Before Our Call",
    button_two_text: "SCHEDULE EVENT",
    redirect_button_text: "CONTINUE TO THE SOURCE",
    redirect_button_link: "https://thesource.dynamitelifestyle.com/bookings",

    video_section_description: `<img src="https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/upload/8f1528fa-c281-4271-a4ab-c91c239cc346.png" alt="Image" style="height:83px;width:71px;">
<p></p>`,
    video_left_side_text: "",
    question_page_top_text: "",
    question_top_text: "",
    thank_you_content: "",
  });

  const getPageDetail = async () => {
    const result = await detailContentPageApi(page_slug);
    if (result.code == 200) {
      handleFormatData(result.sale_page);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setIsLoadingForm(true);
    // let postData = {
    //   free_plan_popup_setting: inputs,
    // };
    // const result = await free_plan_popup_setting_api(postData, page_slug);
    // if (result.code === 200) {
    //   enqueueSnackbar(result.message, { variant: "success" });
    //   setIsLoadingForm(false);
    // } else {
    //   enqueueSnackbar(result.message, { variant: "error" });
    //   setIsLoadingForm(false);
    // }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleFormatData = (data) => {
    setPageData(data);
    // if (data.free_plan_popup_setting) {
    //   setInputs(data.free_plan_popup_setting);
    // }
    setIsLoading(false);
  };

  useEffect(() => {
    //
    setIsLoading(false);
    //
    if (state) {
      handleFormatData(state);
    } else {
      //   getPageDetail();
    }
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 display-flex mb-4">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <span className="sale-page-title">{pageData?.sale_page_title}</span>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <h2>Update Appointment Content</h2>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Already have an account text"
              variant="outlined"
              fullWidth
              name="already_have_an_account_text"
              value={inputs.already_have_an_account_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Login Button Text"
              variant="outlined"
              fullWidth
              name="login_button_text"
              value={inputs.login_button_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Source Login Button Text"
              variant="outlined"
              fullWidth
              name="login_source_button_text"
              value={inputs.login_source_button_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Form Top Text"
              variant="outlined"
              fullWidth
              name="form_top_text"
              value={inputs.form_top_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Form Checkbox Text"
              variant="outlined"
              fullWidth
              name="form_checkbox_text"
              value={inputs.form_checkbox_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Calender Heading"
              variant="outlined"
              fullWidth
              name="calender_heading"
              value={inputs.calender_heading}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Switch Account Text"
              variant="outlined"
              fullWidth
              name="switch_account_text"
              value={inputs.switch_account_text}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Button One Text"
              variant="outlined"
              fullWidth
              name="button_one_text"
              value={inputs.button_one_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Question Page Appointment Heading"
              variant="outlined"
              fullWidth
              name="question_page_appointment_heading"
              value={inputs.question_page_appointment_heading}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Slot Duration Top Text"
              variant="outlined"
              fullWidth
              name="slot_duration_top_text"
              value={inputs.slot_duration_top_text}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Question Section Heading"
              variant="outlined"
              fullWidth
              name="question_section_heading"
              value={inputs.question_section_heading}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Button Two Text"
              variant="outlined"
              fullWidth
              name="button_two_text"
              value={inputs.button_two_text}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Redirect Button Text"
              variant="outlined"
              fullWidth
              name="redirect_button_text"
              value={inputs.redirect_button_text}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Redirect Button Link"
              variant="outlined"
              fullWidth
              name="redirect_button_link"
              value={inputs.redirect_button_link}
              onChange={handleChange}
            />
          </div>

          <div className="col-12 mt-5">
            <h4>Video Section Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="video_section_description"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-5">
            <h4>Video Left Side Text</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="video_left_side_text"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-5">
            <h4>Question Page Top Text</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="question_page_top_text"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-5">
            <h4>Question Top Text</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="question_top_text"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-5">
            <h4>Thankyou Content</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="thank_you_content"
              editorHeight={320}
            />
          </div>
          <div className="text-end mt-4" id="fixedbutton">
            <button className="small-contained-button" disabled={isLoadingForm}>
              {isLoadingForm ? "Updating..." : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
