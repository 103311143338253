import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Chip, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import moment from "moment";
import { deleteCoupenApi, getCoupenListing } from "src/DAL/CoupenApi/CoupenApi";
import { convertCurrencyToSign } from "src/utils/constant";
import CopyToClipboard from "react-copy-to-clipboard";
import PageLoader from "src/components/GeneralComponents/PageLoader";

export default function CoupenList() {
  const navigate = useNavigate();
  const [categoryList, setCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);

  const getCoupenList = async () => {
    setIsLoading(true);
    const result = await getCoupenListing();
    if (result.code == 200) {
      console.log(result, "result");
      setCategoryList(result?.coupons?.data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleEdit = (value) => {
    navigate(`/coupon/edit-coupon/${value?.id}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await deleteCoupenApi(deleteDoc?.id);
    if (result.code === 200) {
      getCoupenList();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleManageStripeReader = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/admin-user/manage-stripe-reader/${value._id}`, {
      state: value,
    });
  };

  const ManageNavItemsAccess = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/admin-user/manage-navitems-access/${value._id}`, {
      state: value,
    });
  };

  const handleNavigate = () => {
    navigate(`/coupon/add-coupon`);
  };

  function convertTimestampToDate(timestamp) {
    return moment.unix(timestamp).format("DD-MM-YYYY");
  }

  const handleCopiedUrl = () => {
    enqueueSnackbar("Coupon Code copied to clipboard", { variant: "success" });
  };

  useEffect(() => {
    getCoupenList();
  }, []);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    { id: "name", label: "Name" },
    {
      id: "discount_type",
      label: "Discount Type",
      renderData: (value) => {
        return value.percent_off ? "Percentage" : "Fixed Amount";
      },
    },
    { id: "duration", label: "Duration", className: "text-capitalize" },
    { id: "max_redemptions", label: "Max Redemptions" },
    {
      id: "amount",
      label: "Amount/Percentage",
      renderData: (value) => {
        return value.percent_off
          ? value.percent_off + "%"
          : convertCurrencyToSign(value.currency) + value.amount_off / 100;
      },
    },
    {
      id: "coupon_mode",
      label: "Coupon Mode",
      renderData: (value) => {
        return value.coupon_mode == "sandBox" ? "Sandbox" : "Live";
      },
    },
    {
      id: "code",
      label: "Coupon Code",
      renderData: (value) => {
        return (
          <CopyToClipboard text={value.id} onCopy={() => handleCopiedUrl(true)}>
            <Tooltip title={value.id} placeholder="top">
              <Chip label="Copy Code" color="primary" variant="outlined" />
            </Tooltip>
          </CopyToClipboard>
        );
      },
    },
    {
      id: "redeem_by",
      label: "Redeem By",
      renderData: (value) => {
        return value.redeem_by
          ? convertTimestampToDate(value.redeem_by)
          : "N/A";
      },
    },
  ];

  if (isLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <CustomConfirmation
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-lg-8 col-sm-12">
            <h2>Coupon</h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Coupon
            </button>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={categoryList}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          pagePagination={true}
        />
      </div>
    </>
  );
}
