import { useEffect, useState } from "react";
import { Box, InputAdornment, OutlinedInput } from "@mui/material";
import { useSnackbar } from "notistack";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import FilteredChip from "src/components/FilteredChip";
import moment from "moment";
import { dd_date_format } from "src/utils/constant";
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
import { styled } from "@mui/material/styles";
import PageLoader from "src/components/GeneralComponents/PageLoader";
import AnalyticsFilter from "src/components/FirebaseAnalytics/AnalyticsFilter";
import { firebase_demographic_analytics } from "src/DAL/FirebaseAnalytics/FirebaseAnalytics";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import {
  DEFAULT_DATES,
  formatFirebaseDuration,
} from "src/utils/firebase_utils";
import { get_kmb_number } from "src/utils/formatNumber";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  height: 44,
  color: "#fff",
  border: "1px solid #e4c073",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    boxShadow: theme.customShadows.z8,
    border: "1px solid #e4c073",
    width: 280,
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const EMPTY_FILTER = {
  ...DEFAULT_DATES,
};

const FirebaseCountries = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [dataList, setDataList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [openFilter, setOpenfilter] = useState(false);
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [updateFilterData, setUpdateFilterData] = useState(EMPTY_FILTER);

  const getCategoriesList = async (filterData) => {
    setIsLoading(true);
    let postData = { ...filterData };
    if (postData.start_date) {
      postData.start_date = moment(postData.start_date).format("YYYY-MM-DD");
    }
    if (postData.end_date) {
      postData.end_date = moment(postData.end_date).format("YYYY-MM-DD");
    }
    const result = await firebase_demographic_analytics(postData);
    if (result.code == 200) {
      let chipData = { ...filterData };
      if (chipData.start_date) {
        let date = `Start Date: ${dd_date_format(
          chipData.start_date
        )} End Date:  ${dd_date_format(chipData.end_date)}`;
        chipData.date = {
          chip_label: date,
          chip_value: date,
        };
        delete chipData.start_date;
        delete chipData.end_date;
      }

      let newData = result?.demographic_report?.map((report) => {
        return {
          ...report,
          totalUsers: get_kmb_number(report?.totalUsers),
          activeUsers: get_kmb_number(report?.activeUsers),
          newUsers: get_kmb_number(report?.newUsers),
          engagedSessions: get_kmb_number(report?.engagedSessions),
        };
      });

      setDataList(newData);
      setUpdateFilterData(chipData);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleClearFilter = () => {
    getCategoriesList(EMPTY_FILTER);
    setUpdateFilterData(EMPTY_FILTER);
    setFilterData(EMPTY_FILTER);
    localStorage.removeItem("analytics_filter_country");
    setOpenfilter(false);
  };

  const searchFunction = () => {
    getCategoriesList(filterData);
    setOpenfilter(false);
    localStorage.setItem(
      "analytics_filter_country",
      JSON.stringify(filterData)
    );
  };

  const handleDeleteChip = (data, item) => {
    const { key } = item;
    if (key === "date" || key === "range_type") {
      data = { ...data, ...DEFAULT_DATES };
    }
    getCategoriesList(data);
    localStorage.setItem("analytics_filter_country", JSON.stringify(data));
    setFilterData(data);
  };

  const handleOpenDawer = () => {
    setOpenfilter(true);
  };

  const handleSearchText = (event) => {
    setSearchText(event.target.value);
    localStorage.setItem("search_text_country", event.target.value);
  };

  const handleData = (data = [], search = "") => {
    if (!search.trim()) return data;
    return data.filter((item) =>
      item?.country?.toLowerCase().includes(search.toLowerCase())
    );
  };

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("analytics_filter_country");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
    }
    setFilterData(filter_data);
    setUpdateFilterData(filter_data);
    getCategoriesList(filter_data);
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "country", label: "Country" },
    { id: "totalUsers", label: "Total User" },
    { id: "activeUsers", label: "Active User" },
    { id: "newUsers", label: "New User" },
    { id: "engagedSessions", label: "Engaged Sessions" },
    {
      id: "engagementRate",
      label: "Engagement Rate",
      renderData: (row) => {
        const formattedRate = Number(row.engagementRate).toFixed(2);
        return <>{formattedRate}%</>;
      },
    },
    {
      id: "averageSessionDuration",
      label: "Average Engagement Time",
      renderData: (row) => formatFirebaseDuration(row?.averageSessionDuration),
    },
  ];

  return (
    <div className="container-fluid">
      <div className="row mb-3">
        <div className="col-lg-6">
          <h2>Demographic Report</h2>
        </div>
        <div className="col-lg-6 text-end">
          <SearchStyle
            className="ms-auto"
            value={searchText}
            onChange={handleSearchText}
            placeholder="Search by country name"
            startAdornment={
              <InputAdornment position="start">
                <Box
                  component={Icon}
                  icon={searchFill}
                  sx={{ color: "text.disabled" }}
                />
              </InputAdornment>
            }
          />
          <button
            onClick={handleOpenDawer}
            className="small-contained-button ms-2"
          >
            Filter &nbsp;&nbsp; <Icon icon={roundFilterList} />
          </button>
        </div>
      </div>
      <FilteredChip
        data={updateFilterData}
        tempState={filterData}
        EMPTY_FILTER={EMPTY_FILTER}
        onDeleteChip={handleDeleteChip}
        onClear={handleClearFilter}
      />
      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        data={handleData(dataList, searchText)}
        className="card-with-background"
        is_hide={true}
      />

      <CustomDrawer
        isOpenDrawer={openFilter}
        onOpenDrawer={() => setOpenfilter(true)}
        onCloseDrawer={() => setOpenfilter(false)}
        pageTitle="Filter"
        componentToPassDown={
          <AnalyticsFilter
            filterData={filterData}
            setFilterData={setFilterData}
            searchFunction={searchFunction}
            handleClearFilter={handleClearFilter}
          />
        }
      />
    </div>
  );
};

export default FirebaseCountries;
