import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useNavigate, useParams } from "react-router-dom";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useEffect } from "react";
import { s3baseUrl } from "src/config/config";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  bonus_content_category_list,
  update_bonus_content_category_api,
} from "src/DAL/member/member";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const format_date = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

export default function ManageBonusContentAccessForUser() {
  const { member_id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [bonusContentList, setBonusContentList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [rowPerPage, setrowPerPage] = useState("");
  const [memberDetail, setMemberDetail] = useState({});

  const get_bonus_content_list = async () => {
    const result = await bonus_content_category_list(member_id);
    if (result.code == 200) {
      setMemberDetail(result.member);
      if (result.member.bonus_content_categories?.length > 0) {
        setSelected(result.member.bonus_content_categories);
      }

      var bonusContentArray = [];
      setrowPerPage(result.bonus_content_categories.length);
      result.bonus_content_categories.map((item) => {
        item.table_avatar = {
          src: s3baseUrl + item.image.thumbnail_2,
          alt: item.title,
        };
        item.access_type = "free";
        item.start_date = new Date();
        item.expiry_date = new Date();
        bonusContentArray.push(item);
      });
      setBonusContentList(bonusContentArray);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    setIsLoadingForm(true);
    let categories_array = [];
    selected.map((item) => {
      var category_object = {
        _id: item._id,
        title: item.title,
        access_type: item.access_type,
        expiry_date: format_date(item.expiry_date),
        start_date: format_date(item.start_date),
      };
      categories_array.push(category_object);
    });

    let data = {
      bonus_content_categories: categories_array,
    };

    const result = await update_bonus_content_category_api(member_id, data);
    if (result.code == 200) {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handle_update_values = (name, value, id) => {
    setBonusContentList((data) =>
      data.map((obj) => {
        if (obj._id === id) {
          return { ...obj, [name]: value };
        }
        return obj;
      })
    );
    setSelected((data) =>
      data.map((obj) => {
        if (obj._id === id) {
          return { ...obj, [name]: value };
        }
        return obj;
      })
    );
  };

  const handleChangeType = (event, name, id) => {
    const value = event.target.value;
    handle_update_values(name, value, id);
  };

  const handle_set_values = (date, row, name, array, setter) => {
    let find_index = array.findIndex((prog) => prog._id == row._id);
    if (find_index !== -1) {
      let temp_state = [...array];
      let temp_element = { ...temp_state[find_index] };
      temp_element[name] = date;
      temp_state[find_index] = temp_element;
      setter(temp_state);
    }
  };

  const handle_update_date = (date, row, name) => {
    handle_set_values(date, row, name, bonusContentList, setBonusContentList);
    handle_set_values(date, row, name, selected, setSelected);
  };

  const handleChangeExpiryDate = (date, row) => {
    handle_update_date(date, row, "expiry_date");
  };

  const handleChangeStartDate = (date, row) => {
    handle_update_date(date, row, "start_date");
  };

  const TABLE_HEAD = [
    {
      id: "table_avatar",
      label: "Image",
      type: "thumbnail",
      className: "p-0",
    },
    { id: "title", label: "Title", alignRight: false },
    {
      id: "status",
      label: "Status",
      type: "row_status",
    },
    {
      id: "access_type",
      label: "Access Type",
      renderData: (row) => {
        let is_selected = selected?.find((object) => row._id === object._id);
        let access_type = row.access_type;
        if (is_selected) {
          access_type = is_selected.access_type;
        }

        return (
          <div>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label"></InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="access_type"
                className="bonus_type_input"
                value={access_type}
                label=""
                onChange={(e) => {
                  handleChangeType(e, "access_type", row._id);
                }}
              >
                <MenuItem value="free">Free</MenuItem>
                <MenuItem value="paid">Paid</MenuItem>
              </Select>
            </FormControl>
          </div>
        );
      },
    },
    {
      id: "no_of_start_days",
      label: "Start Date",
      renderData: (row) => {
        let is_selected = selected?.find((object) => row._id === object._id);
        let start_date = row.start_date;
        if (is_selected) {
          if (is_selected.start_date) {
            start_date = is_selected.start_date;
          }
        }

        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              inputFormat="dd-MM-yyyy"
              onChange={(e) => {
                handleChangeStartDate(e, row);
              }}
              value={start_date}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        );
      },
    },
    {
      id: "no_of_limited_days",
      label: "End Date",
      renderData: (row) => {
        let is_selected = selected?.find((object) => row._id === object._id);

        let expiry_date = row.expiry_date;
        let start_date = row.start_date;

        if (is_selected) {
          if (is_selected.expiry_date) {
            expiry_date = is_selected.expiry_date;
          }
          if (is_selected.start_date) {
            start_date = is_selected.start_date;
          }
        }

        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              inputFormat="dd-MM-yyyy"
              onChange={(e) => {
                handleChangeExpiryDate(e, row);
              }}
              value={expiry_date}
              minDate={new Date(start_date)}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        );
      },
    },
  ];

  useEffect(() => {
    get_bonus_content_list();
  }, []);

  if (isLoading == true) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row mb-4">
        <div className="col-12 mb-3 reply-anchor">
          <IconButton
            className="back-screen-button "
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <span className="sale-page-title">
            {memberDetail.first_name +
              " " +
              memberDetail.last_name +
              " (" +
              memberDetail.email +
              ")"}
          </span>
        </div>
        <div className="col-6">
          <h2>Manage Bonus Content Access</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={bonusContentList}
            selected={selected}
            setSelected={setSelected}
            checkbox_selection={true}
            pagePagination={false}
            rows_per_page_count={rowPerPage}
            hide_footer_pagination={true}
          />
        </div>
      </div>
      <div className="ms-auto text-end" id="fixedbutton">
        <button
          className="small-contained-button mt-3 ml-auto"
          onClick={handleSubmit}
        >
          {isLoadingForm ? "Updating..." : "Update"}
        </button>
      </div>
    </div>
  );
}
