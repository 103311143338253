import * as React from "react";
import { _get_membership_level_coins_configration } from "src/DAL/challenges/challenges";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import { useState } from "react";
import { _get_membership_levels_program_detail } from "src/DAL/Programme/Programme";
import MembershipLevel from "./MembershipLevel";
import PageLoader from "src/components/GeneralComponents/PageLoader";
import { s3baseUrl } from "src/config/config";

export default function LevelsHistory({
  tabData,
  searchFunction,
  searchText,
  setSearchText,
  pageCount,
  totalPages,
  handleChangePages,
  custom_pagination,
  filterQuery,
  membershipLevelList,
  getRoadMapList,
  isLoadingBadgeLevel,
  setBadgeLevelID,
  badgeLevelType,
  setBadgeLevelType,
  onChangeBadgeLevel,
}) {
  let EMPTY_DATA = {
    vertical_tab_value: 0,
  };
  let find_filter = localStorage.getItem(`updates_vertical_tab_value`);
  if (find_filter) {
    EMPTY_DATA = JSON.parse(find_filter);
  }

  const [verticalTabValue, setVerticalTabValue] = useState(
    EMPTY_DATA?.vertical_tab_value
  );

  const handleChangeType = (event) => {
    const value = event.target.value;
    onChangeBadgeLevel(value);
    getRoadMapList(null, null, null, value);
  };

  const handleChange = (event, newValue) => {
    onChangeBadgeLevel("current");
    let badge_level = membershipLevelList[parseInt(newValue)];
    setVerticalTabValue(newValue);
    setBadgeLevelID(badge_level?._id ?? "");
    getRoadMapList(null, null, badge_level?._id ?? "", "sub_tab_change");

    localStorage.setItem(
      "updates_vertical_tab_value",
      JSON.stringify({
        vertical_tab_value: newValue,
      })
    );
  };

  const TABS_OPTIONS = [];
  membershipLevelList &&
    membershipLevelList.map((level) => {
      TABS_OPTIONS.push({
        title: (
          <div className="d-flex align-self-start">
            <img
              className="avatar-adjust ms-2 me-4 mt-0"
              style={{
                width: 20,
                height: 20,
              }}
              alt={level?.title}
              src={s3baseUrl + level?.icon?.thumbnail_1}
            />
            {level?.title} ({level?.member_count})
          </div>
        ),
        component: (
          <MembershipLevel
            level={level}
            tabData={tabData}
            searchFunction={searchFunction}
            searchText={searchText}
            setSearchText={setSearchText}
            pageCount={pageCount}
            totalPages={totalPages}
            handleChangePages={handleChangePages}
            custom_pagination={custom_pagination}
            filterQuery={filterQuery}
            isLoadingBadgeLevel={isLoadingBadgeLevel}
            badgeLevelType={badgeLevelType}
            handleChangeType={handleChangeType}
          />
        ),
      });
    });

  if (isLoadingBadgeLevel && membershipLevelList.length == 0) {
    return <PageLoader />;
  }

  return (
    <>
      <MUICustomTabs
        data={TABS_OPTIONS}
        value={verticalTabValue}
        handleChange={handleChange}
        orientation="vertical"
        verticalTabsTitle="Badge Levels"
      />
    </>
  );
}
