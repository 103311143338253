import React, { useEffect, useState } from "react";
import Message from "src/components/Message";
// import { GoalStatementList } from "../../components/GoalStatement";
// import GoalGallerySlider from "../../components/GoalStatement/GoalGallerySlider";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "src/config/config";
import { useParams } from "react-router-dom";
import { delegateQuestionsListing } from "src/DAL/consultant/consultant";
import MessageAssessmentQuestions from "src/components/MessageAssessmentQuestions";
import PageLoader from "src/components/GeneralComponents/PageLoader";
// import PageDescription from "src/components/GeneralComponents/PageDescription";

function WheelOfLifeQuestionsList({ goalMembers, finalData }) {
  const params = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [goalStatementQuestions, setGoalStatementQuestions] = useState([]);
  const [goalStatementAnswers, setGoalStatementAnswers] = useState([]);
  const [goalStatementGallery, setGoalStatementGallery] = useState([]);
  const [goalStatementCompletedStatus, setGoalStatementCompletedStatus] =
    useState(false);
  const [goalStatementCompletedDate, setGoalStatementCompletedDate] = useState(
    new Date()
  );
  const [replies, setreplies] = useState([]);

  const getFilteredQuestion = (question_id) => {
    const values = goalStatementQuestions.filter(
      (value) => value._id == question_id
    );
    return values[0];
  };

  const getGoalStatementList = async () => {
    const result = await delegateQuestionsListing(params.id);
    if (result.code === 200) {
      const get_answer = (question_id) => {
        return result?.answers.filter((answer) => answer._id == question_id)[0]
          ?.answer_statement;
      };

      const data = result.delegate_ninety_day_question.map(
        (question, index) => {
          return {
            ...question,
            answer: get_answer(question._id),
          };
        }
      );

      setGoalStatementQuestions(data);
      setGoalStatementAnswers(result.answers.replies);

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getGoalStatementList();
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }
  return (
    <div className="">
      {/* <PageDescription
        parameter="goal_statement_description"
        else_title="Goal Statement"
      /> */}

      {goalStatementQuestions.length > 0 && (
        <div className="row ">
          <div className="col-12">
            <MessageAssessmentQuestions
              replies={replies}
              data={goalMembers}
              userData={finalData}
              answers={goalStatementAnswers}
              goalStatementCompletedStatus={goalStatementCompletedStatus}
              goalStatementCompletedDate={goalStatementCompletedDate}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default WheelOfLifeQuestionsList;
