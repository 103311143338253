import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { _group_filter_listing, DeleteGroupApi } from "src/DAL/group/group";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { show_proper_words } from "src/utils/constant";
import MemberProgramPopover from "../MembersList/memberProgramPopOver";
import PopOverViewMore from "src/components/PopOverViewMore";
import PopOverViewMoreSalePage from "src/components/PopOverViewMoreSalePage";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { Icon } from "@iconify/react";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import GroupFilter from "./GroupFilter";
import FilteredChip from "src/components/FilteredChip";
import PopOverViewMoreMissions from "src/components/PopOverViewMoreMissions";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const EMPTY_FILTER = {
  badge_levels: [],
  programsName: [],
  eventsName: [],
  salePageName: [],
  missionsName: [],
  delegate_ids: [],
  group_by: "",
  search_text: "",
  created_by: "all",
};

export default function CalendarGroupList() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElEvnet, setAnchorElEvnet] = useState(null);
  const [anchorElBadgeLevel, setAnchorElBadgeLevel] = useState(null);
  const [anchorElSalePage, setAnchorElSalePage] = useState(null);
  const [program, setprogram] = useState([]);
  const [event, setEvent] = useState([]);
  const [salePage, setSalePage] = useState([]);
  const [missions, setMissions] = useState([]);
  const [badgeLevels, setBadgeLevels] = useState([]);
  const [paymentPlan, setPaymentPlan] = useState([]);
  const [anchorElPaymentPlan, setAnchorElPaymentPlan] = useState(null);
  const opens = Boolean(anchorEl);
  const id = opens ? "simple-popover" : undefined;
  const opensEvent = Boolean(anchorElEvnet);
  const opensMission = Boolean(anchorElEvnet);
  const opensBadgeLevels = Boolean(anchorElBadgeLevel);
  const idevent = opensEvent ? "simple-popover" : undefined;
  const opensSalePage = Boolean(anchorElSalePage);
  const idSalePage = opensSalePage ? "simple-popover" : undefined;
  const idMission = opensMission ? "simple-popover" : undefined;
  const idBadgeLevels = opensBadgeLevels ? "simple-popover" : undefined;
  const opensPaymentPlan = Boolean(anchorElPaymentPlan);
  const idPaymentPlan = opensPaymentPlan ? "simple-popover" : undefined;
  const [searchText, setSearchText] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [filterStateUpdated, setFilterStateUpdated] = useState(EMPTY_FILTER);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [page, setPage] = useState(
    localStorage.getItem(`group_filter_data_page`)
      ? parseInt(localStorage.getItem(`group_filter_data_page`))
      : 0
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem(`group_filter_data_rowsPerPage`)
      ? parseInt(localStorage.getItem(`group_filter_data_rowsPerPage`))
      : 50
  );

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const handleClearFilter = () => {
    setFilterData(EMPTY_FILTER);
    localStorage.removeItem("group_filter_data");
    getConsultantListing(EMPTY_FILTER);
    setFilterDrawerState(false);
    setSearchText("");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    filterData.search_text = searchText;
    getConsultantListing(filterData);
    localStorage.setItem("group_filter_data", JSON.stringify(filterData));
    handleCloseFilterDrawer();
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);
    data.search_text = searchText;

    if (data.group_by == "") {
      data.programsName = [];
      data.eventsName = [];
      data.salePageName = [];
      data.missionsName = [];
    }

    getConsultantListing(data);
    localStorage.setItem("group_filter_data", JSON.stringify(data));
    setFilterData(data);
    handleCloseFilterDrawer();
  };

  const getConsultantListing = async (filterData) => {
    setIsLoading(true);
    let data = filterData ? filterData : EMPTY_FILTER;
    let chipData = { ...filterData };
    let badge_levels = [];
    let group_by_ids = [];
    let group_by = data?.group_by ?? "";
    let search_text = data?.search_text ?? "";

    if (data?.badge_levels?.length > 0) {
      badge_levels = data?.badge_levels.map((level) => {
        return level._id;
      });
    }

    if (group_by) {
      let search_ids = [];
      if (group_by == "program") {
        search_ids = data?.programsName ?? [];
        chipData.eventsName = [];
        chipData.salePageName = [];
        chipData.missionsName = [];
      }
      if (group_by == "event") {
        search_ids = data?.eventsName ?? [];
        chipData.programsName = [];
        chipData.salePageName = [];
        chipData.missionsName = [];
      }
      if (group_by == "sale_page") {
        search_ids = data?.salePageName ?? [];
        chipData.programsName = [];
        chipData.eventsName = [];
        chipData.missionsName = [];
      }
      if (group_by == "mission") {
        search_ids = data?.missionsName ?? [];
        chipData.programsName = [];
        chipData.eventsName = [];
        chipData.salePageName = [];
      }
      group_by_ids = search_ids.map((item) => {
        return item?._id;
      });
    }

    let postData = {
      group_by: group_by,
      group_by_ids: group_by_ids,
      badge_levels: badge_levels,
      created_by:
        data.created_by == "admin"
          ? "admin_user"
          : data.created_by == "delegate"
          ? "consultant_user"
          : "all",
      search: search_text,
    };
    if (!group_by) {
      delete postData.group_by;
      delete postData.group_by_ids;
    }
    if (data.created_by == "delegate" && data.delegate_ids?.length > 0) {
      postData.delegate_ids = data.delegate_ids.map((delegate) => delegate._id);
    }

    const result = await _group_filter_listing(page, rowsPerPage, postData);
    if (result.code === 200) {
      localStorage.setItem("group_filter_data_page", parseInt(page));
      localStorage.setItem(
        "group_filter_data_rowsPerPage",
        parseInt(rowsPerPage)
      );
      localStorage.setItem(
        "group_filter_data",
        JSON.stringify({ ...chipData })
      );

      setFilterData({ ...chipData });

      if (chipData?.group_by) {
        if (chipData?.group_by == "program") {
          delete chipData.eventsName;
          delete chipData.salePageName;
          delete chipData.missionsName;
        }
        if (chipData?.group_by == "event") {
          delete chipData.programsName;
          delete chipData.salePageName;
          delete chipData.missionsName;
        }
        if (chipData?.group_by == "sale_page") {
          delete chipData.programsName;
          delete chipData.eventsName;
          delete chipData.missionsName;
        }
        if (chipData?.group_by == "missions") {
          delete chipData.programsName;
          delete chipData.salePageName;
          delete chipData.eventsName;
        }
      }

      delete chipData.search_text;
      setFilterStateUpdated(chipData);

      const data = result?.groups.map((group) => {
        let group_by_type = "Event";
        if (group.group_by === "program") {
          group_by_type = "Programme";
        } else if (group.group_by === "event") {
          group_by_type = "Event";
        } else if (group.group_by === "sale_page") {
          group_by_type = "Sale Page";
        } else if (group.group_by === "missions") {
          group_by_type = "Missions";
        }

        let member_list = "N/A";
        if (group.member) {
          member_list = group.member.length;
        }
        return { ...group, member_list, group_by_type: group_by_type };
      });
      setTotalCount(result?.total_groups_count);
      setTotalPages(result.total_pages);
      setUserList(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleClickPopUP = (event, row) => {
    setprogram(row.program);
    setAnchorEl(event.currentTarget);
  };

  const handleClickPopUPEvent = (event, row) => {
    setEvent(row.event);
    setAnchorElEvnet(event.currentTarget);
  };

  const handleClickPopUpMission = (event, row) => {
    setMissions(row?.missions);
    setAnchorElEvnet(event.currentTarget);
  };
  const handleClickPopUpBadgeLevels = (event, row) => {
    setBadgeLevels(row?.badge_levels ?? []);
    setAnchorElBadgeLevel(event.currentTarget);
  };

  const handleClickPopUpSalePage = (event, row) => {
    setSalePage(row.sale_pages);
    setAnchorElSalePage(event.currentTarget);
  };

  const handleClickPopUpPaymentPlan = (event, row) => {
    setPaymentPlan(row.plans);
    setAnchorElPaymentPlan(event.currentTarget);
  };

  const handleClosePopUpPaymentPlan = () => {
    setAnchorElPaymentPlan(null);
  };

  const handleClosePopUpSalePage = () => {
    setAnchorElSalePage(null);
  };

  const handleClosePopUpEvent = () => {
    setAnchorElEvnet(null);
  };
  const handleClosePopUpMission = () => {
    setAnchorElEvnet(null);
  };
  const handleClosePopUpBadgeLevels = () => {
    setAnchorElBadgeLevel(null);
  };

  const handleClosePopUp = () => {
    setAnchorEl(null);
  };

  const handleEdit = (value) => {
    navigate(`/calendar-groups/edit-group/${value.group_slug}`, {
      state: value,
    });
  };

  const handleViewDetail = (value) => {
    navigate(`/calendar-groups/group-detail/${value.group_slug}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await DeleteGroupApi(deleteDoc.group_slug);
    if (result.code === 200) {
      getConsultantListing();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleNavigate = () => {
    navigate(`/calendar-groups/add-group`);
  };

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem(`group_filter_data`);

    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      if (filter_data.search_text) {
        setSearchText(filter_data.search_text);
      }
    }
    setFilterData(filter_data);
    setFilterStateUpdated(filter_data);
    getConsultantListing(filter_data);
  }, [rowsPerPage, page]);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "View Detail",
      icon: "fluent:video-recording-20-regular",
      handleClick: handleViewDetail,
    },
  ];

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    {
      id: "title",
      label: "Name",
      handleClick: handleViewDetail,
      className: "pointer_cursor",
    },
    {
      id: "program",
      label: "Programme / Event / Sale Page / Missions",
      renderData: (row) => {
        const firstFivePrograms = row.program?.slice(0, 4);
        const firstFiveEvents = row.event?.slice(0, 4);
        const firstFiveSalePages = row.sale_pages?.slice(0, 4);
        const firstFiveMissions = row.missions?.slice(0, 4);

        if (firstFivePrograms && firstFivePrograms.length > 0) {
          return (
            <>
              {firstFivePrograms.map(
                (program, i) =>
                  program._id && (
                    <React.Fragment key={i}>
                      <div>
                        <p>{program._id.title + ","}</p>
                      </div>
                      {i === 3 && (
                        <p
                          className="mb-1 mt-1 view-more"
                          aria-describedby={id}
                          variant="contained"
                          onClick={(e) => handleClickPopUP(e, row)}
                        >
                          view more
                        </p>
                      )}
                    </React.Fragment>
                  )
              )}
            </>
          );
        } else if (firstFiveEvents && firstFiveEvents.length > 0) {
          return (
            <>
              {firstFiveEvents.map(
                (event, i) =>
                  event._id && (
                    <React.Fragment key={i}>
                      <div>
                        <p>{event._id.title + ","}</p>
                      </div>
                      {i === 3 && (
                        <p
                          className="mb-1 mt-1 view-more"
                          aria-describedby={idevent}
                          variant="contained"
                          onClick={(e) => handleClickPopUPEvent(e, row)}
                        >
                          view more
                        </p>
                      )}
                    </React.Fragment>
                  )
              )}
            </>
          );
        } else if (firstFiveSalePages && firstFiveSalePages.length > 0) {
          return (
            <>
              {firstFiveSalePages.map(
                (sale_page, i) =>
                  sale_page._id && (
                    <React.Fragment key={i}>
                      <div>
                        <p>{sale_page._id.sale_page_title + ","}</p>
                      </div>
                      {i === 3 && (
                        <p
                          className="mb-1 mt-1 view-more"
                          aria-describedby={idSalePage}
                          variant="contained"
                          onClick={(e) => handleClickPopUpSalePage(e, row)}
                        >
                          view more
                        </p>
                      )}
                    </React.Fragment>
                  )
              )}
            </>
          );
        } else if (firstFiveMissions && firstFiveMissions.length > 0) {
          return (
            <>
              {firstFiveMissions.map(
                (mission, i) =>
                  mission?._id && (
                    <React.Fragment key={i}>
                      <div>
                        <p>{mission?.title + ","}</p>
                      </div>
                      {i === 3 && (
                        <p
                          className="mb-1 mt-1 view-more"
                          aria-describedby={idMission}
                          variant="contained"
                          onClick={(e) => handleClickPopUpMission(e, row)}
                        >
                          view more
                        </p>
                      )}
                    </React.Fragment>
                  )
              )}
            </>
          );
        }
      },
    },
    {
      id: "payment_plan",
      label: "Payment Plan",
      renderData: (row) => {
        const firstFivePaymentPlan = row?.plans?.slice(0, 4);
        return (
          <>
            {firstFivePaymentPlan &&
              firstFivePaymentPlan.length > 0 &&
              firstFivePaymentPlan.map((payment_plan, i) => (
                <React.Fragment key={i}>
                  <div>
                    <p>{payment_plan?._id?.plan_title + ","}</p>
                  </div>
                  {i === 3 && (
                    <p
                      className="mb-1 mt-1 view-more"
                      aria-describedby={idPaymentPlan}
                      variant="contained"
                      onClick={(e) => handleClickPopUpPaymentPlan(e, row)}
                    >
                      view more
                    </p>
                  )}
                </React.Fragment>
              ))}
          </>
        );
      },
    },
    {
      label: "Badge Level",
      renderData: (row) => {
        const firstFourBadgeLevel = row?.badge_levels?.slice(0, 4);

        return (
          <>
            {firstFourBadgeLevel &&
              firstFourBadgeLevel.length > 0 &&
              firstFourBadgeLevel.map((level, i) => (
                <React.Fragment key={i}>
                  <div>
                    <p>{level?.title + ","}</p>
                  </div>
                  {i === 3 && (
                    <p
                      className="mb-1 mt-1 view-more"
                      aria-describedby={idBadgeLevels}
                      variant="contained"
                      onClick={(e) => handleClickPopUpBadgeLevels(e, row)}
                    >
                      view more
                    </p>
                  )}
                </React.Fragment>
              ))}
          </>
        );
      },
    },

    {
      id: "group_type",
      label: "Type",
      renderData: (row) => {
        return <p className="mb-0">{show_proper_words(row.group_type)}</p>;
      },
    },
    {
      id: "group_by_type",
      label: "Group By",
    },
    {
      id: "action_by_info",
      label: "Created By",
    },
    { id: "member_list", label: "Members" },
    { id: "status", label: "Status", type: "row_status" },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4 col-sm-12">
            <h2>Groups</h2>
          </div>

          <div className="col-lg-8 col-sm-12 text-end d-flex justify-content-end">
            <div>
              <button
                className="small-contained-button me-2 mt-1 mb-4"
                onClick={handleOpenFilterDrawer}
              >
                Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
              </button>
              <button
                onClick={handleNavigate}
                className="small-contained-button"
              >
                Add Group
              </button>
            </div>
          </div>
          <FilteredChip
            data={filterStateUpdated}
            tempState={filterData}
            EMPTY_FILTER={EMPTY_FILTER}
            onDeleteChip={handleDeleteChip}
            onClear={handleClearFilter}
          />
        </div>

        <div className="row">
          <div className="col-12 mt-3">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              MENU_OPTIONS={MENU_OPTIONS}
              data={userList}
              className="card-with-background"
              pagePagination={true}
              localSearchName="calender_group_searh"
              custom_search={{
                searchText: searchText,
                setSearchText: setSearchText,
                handleSubmit: searchFunction,
              }}
              custom_pagination={{
                total_count: totalCount,
                rows_per_page: rowsPerPage,
                page: page,
                handleChangePage: handleChangePage,
                onRowsPerPageChange: handleChangeRowsPerPage,
              }}
            />
          </div>
        </div>
      </div>

      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <>
            <GroupFilter
              filterData={filterData}
              setFilterData={setFilterData}
              searchSubmitFilter={searchFunction}
              handleClearFilter={handleClearFilter}
            />
          </>
        }
      />

      <MemberProgramPopover
        handleClick={handleClickPopUP}
        handleClose={handleClosePopUp}
        anchorEl={anchorEl}
        id={id}
        navigatePage={true}
        open={opens}
        program={program}
      />
      <PopOverViewMore
        handleClick={handleClickPopUPEvent}
        handleClose={handleClosePopUpEvent}
        anchorEl={anchorElEvnet}
        id={idevent}
        open={opensEvent}
        program={event}
        title="Events"
      />
      <PopOverViewMoreMissions
        handleClick={handleClickPopUpMission}
        handleClose={handleClosePopUpMission}
        anchorEl={anchorElEvnet}
        id={idMission}
        open={opensMission}
        program={missions}
        title="Missions"
      />

      <PopOverViewMoreMissions
        handleClick={handleClickPopUpBadgeLevels}
        handleClose={handleClosePopUpBadgeLevels}
        anchorEl={anchorElBadgeLevel}
        id={idBadgeLevels}
        open={opensBadgeLevels}
        program={badgeLevels}
        title="Badge Levels"
      />

      <PopOverViewMoreSalePage
        handleClick={handleClickPopUpSalePage}
        handleClose={handleClosePopUpSalePage}
        anchorEl={anchorElSalePage}
        id={idSalePage}
        open={opensSalePage}
        program={salePage}
        title="Sale Pages"
      />
      <PopOverViewMoreSalePage
        handleClick={handleClickPopUpPaymentPlan}
        handleClose={handleClosePopUpPaymentPlan}
        anchorEl={anchorElPaymentPlan}
        id={idPaymentPlan}
        open={opensPaymentPlan}
        program={paymentPlan}
        title="Payment Plans"
      />
    </>
  );
}
