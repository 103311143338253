import { useEffect, useState } from "react";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { deleteProgramme, programmeListing } from "src/DAL/Programme/Programme";
import { delegate_Url, s3baseUrl } from "src/config/config";
import CircleIcon from "@mui/icons-material/Circle";
import { htmlDecode } from "src/utils/convertHtml";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import ListIcon from "@mui/icons-material/List";
import GridViewIcon from "@mui/icons-material/GridView";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { usePGIMode } from "src/Hooks/PGIModeContext";
import { show_proper_words } from "src/utils/constant";
import PopOverViewMoreNav from "src/components/PopOverViewMoreNav";
import PageLoader from "src/components/GeneralComponents/PageLoader";

// ----------------------------------------------------------------------

const Progrmme_status = [
  {
    name: "Active",
    value: true,
    class: "active_bg_program",
  },
  {
    name: "InActive",
    value: false,
    class: "inactive_bg_program",
  },
];

const handle_nav_items = (data) => {
  let nav_items = [];
  data.map((nav) => {
    if (nav.child_options) {
      nav.child_options.map((child) => {
        nav_items.push(child.title);
      });
    } else {
      nav_items.push(nav.title);
    }
  });
  return nav_items;
};

export default function ProgrammesList() {
  const { enqueueSnackbar } = useSnackbar();
  const { viewMode, setViewMode } = usePGIMode();
  const navigate = useNavigate();
  const [program, setProgram] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [ProgramData, setProgramData] = useState({});
  const [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [anchorElEvnet, setAnchorElEvnet] = useState(null);
  const [event, setEvent] = useState([]);
  const opensEvent = Boolean(anchorElEvnet);
  const idevent = opensEvent ? "simple-popover" : undefined;

  const handleClickPopUPEvent = (event, row) => {
    setEvent(row.nav_items);
    setAnchorElEvnet(event.currentTarget);
  };
  const handleClosePopUpEvent = () => {
    setAnchorElEvnet(null);
  };
  const toggleView = () => {
    setViewMode((prevMode) => (prevMode === "grid" ? "table" : "grid"));
  };

  const programsList = async () => {
    const result = await programmeListing();
    if (result.code == 200) {
      setIsLoading(false);
      const ListData = result.program.map((items) => {
        return {
          ...items,
          nav_items: handle_nav_items(items.nav_items),
          table_avatar: {
            src: s3baseUrl + items.program_images.thumbnail_1,
            alt: items.title,
          },
        };
      });
      setProgram(ListData);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleNavigateDetail = (value) => {
    navigate(`/programmes/programmes-detail/${value.program_slug}`, {
      state: value,
    });
  };
  const handleChangeSearch = (e) => {
    setQuery(e.target.value);
  };
  const get_short_string = (str, limit = 30) => {
    return str && str.length < limit ? str : str?.slice(0, limit) + ".... ";
  };

  const getFilteredData = (query) => {
    let dataToFilter = [...program];
    if (query) {
      let resultFilter = dataToFilter.filter((post) =>
        post.title.toLowerCase().includes(query.toLowerCase())
      );
      return resultFilter;
    } else {
      return dataToFilter;
    }
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", alignRight: false, type: "action" },
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "table_avatar",
      label: "Image",
      type: "thumbnail",
    },
    {
      id: "title",
      label: "Title",
      alignRight: false,
      renderData: (row) => {
        let find_status = Progrmme_status.find(
          (status) => status.value == row.status
        );
        const iconStyle = {
          color: "blue !important",
        };

        return (
          <div className="d-flex pointer">
            <Tooltip title={find_status?.name} className="pointer">
              <CircleIcon
                style={iconStyle}
                className={`transation-status-icon ${find_status?.class}`}
              />
            </Tooltip>
            <p
              className="mb-0 "
              style={{ cursor: "pointer" }}
              onClick={() => handleNavigateDetail(row)}
            >
              {" "}
              {row.title}
            </p>
          </div>
        );
      },
    },

    {
      id: "is_show_on",
      label: "Programme For",
      alignRight: false,
      renderData: (row) => {
        return (
          <>
            <span style={{ textTransform: "capitalize" }}>
              {show_proper_words(
                row.is_show_on == "main_portal"
                  ? "Client Portal"
                  : row.is_show_on
              )}
            </span>
          </>
        );
      },
    },
    {
      id: "is_membership_allowed",
      label: "Is Membership Allowed",
      alignRight: false,
      renderData: (row) => {
        return (
          <>{row.is_membership_allowed ? <span>Yes</span> : <span>No</span>}</>
        );
      },
    },
    {
      id: "membership_levels",
      label: "Membership Level",
      alignRight: false,
      renderData: (row) => {
        let membership_level = row?.membership_levels?.title ?? "N/A";

        return (
          <>
            <span>{membership_level}</span>
          </>
        );
      },
    },
    {
      id: "badge_levels",
      label: "Badge Level",
      alignRight: false,
      renderData: (row) => {
        let membership_level_badges =
          row?.membership_levels?.membership_level_badges?.title ?? "N/A";
        return (
          <>
            <span>{membership_level_badges}</span>
          </>
        );
      },
    },
    {
      id: "program_access_type",
      label: "Program Access",
      alignRight: false,
      renderData: (row) => {
        return (
          <>
            <span style={{ textTransform: "capitalize" }}>
              {" "}
              {row.program_access_type}{" "}
            </span>
          </>
        );
      },
    },
    {
      id: "no_of_start_days",
      label: "No Of Start Days",
      alignRight: false,
      renderData: (row) => {
        return (
          <>
            <span style={{ textTransform: "capitalize" }}>
              {" "}
              {row.program_access_type == "limited"
                ? row.no_of_start_days
                : "--"}
            </span>
          </>
        );
      },
    },
    {
      id: "no_of_limited_days",
      label: "No Of Limited Days",
      alignRight: false,
      renderData: (row) => {
        return (
          <>
            <span style={{ textTransform: "capitalize" }}>
              {" "}
              {row.program_access_type == "limited"
                ? row.no_of_limited_days
                : "--"}
            </span>
          </>
        );
      },
    },
    {
      id: "title",
      label: "Nav Items",
      renderData: (row) => {
        const firstFiveNav = row.nav_items?.slice(0, 5);
        return (
          <div>
            {firstFiveNav.length > 0
              ? firstFiveNav.map((navitem, i) => {
                  if (navitem !== "[]") {
                    return (
                      <>
                        <div key={i}>{show_proper_words(navitem + ",")}</div>
                        {row.nav_items.length > 5 && i == 4 && (
                          <p
                            className="mb-1 mt-1 view-more"
                            aria-describedby={idevent}
                            variant="contained"
                            onClick={(e) => handleClickPopUPEvent(e, row)}
                          >
                            view more
                          </p>
                        )}
                      </>
                    );
                  } else {
                    return "N/A";
                  }
                })
              : "N/A"}
          </div>
        );
      },
    },

    {
      id: "title",
      label: "Total Lessons",
      alignRight: false,
      renderData: (row) => {
        return (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => handleManageLessons(row)}
          >
            {" "}
            {row.no_of_lesson} Lessons
          </span>
        );
      },
    },
    {
      id: "title",
      label: "Lessons Duration",
      alignRight: false,
      renderData: (row) => {
        return <span> {row.total_lesson_duration} </span>;
      },
    },
    {
      id: "title",
      label: "Views",
      alignRight: false,
      renderData: (row) => {
        return (
          <>
            {row.view_count ? (
              <span> {row.view_count} Views</span>
            ) : (
              <span> 0 Views</span>
            )}
          </>
        );
      },
    },
    { id: "order", label: "Order", alignRight: false },
  ];
  // --------------------
  const handleOpenDelete = (row) => {
    setOpenDelete(true);
    setProgramData(row);
  };
  const handleCloseDelete = () => setOpenDelete(false);

  const handleCopyReferralId = async (row) => {
    await navigator.clipboard.writeText(
      delegate_Url + "delegate-training/" + row.program_slug
    );
    enqueueSnackbar("Delegate Training Url Copied", { variant: "success" });
  };

  const handleNavigateEdit = (row) => {
    navigate(`/programmes/edit-programme/${row.program_slug}`, {
      state: row,
    });
  };
  const handleNavigateReminder = (row) => {
    navigate(`/programmes/reminder-config/${row.program_slug}`, {
      state: row,
    });
  };

  const handleNavigateGroup = (row) => {
    navigate(`/programmes/automated-groups/${row.program_slug}`, {
      state: row,
    });
  };
  const handleNavigateAdd = (row) => {
    navigate(`/programmes/bulk-access/add/${row._id}`, {
      state: row,
    });
    localStorage.setItem("program_slug", row.program_slug);
  };
  const handleNavigateRemove = (row) => {
    navigate(`/programmes/bulk-access/remove/${row._id}`, {
      state: row,
    });
    localStorage.setItem("program_slug", row.program_slug);
  };

  const handleNavigateDocs = (row) => {
    navigate(`/programmes/programm-document/${row.program_slug}`, {
      state: row,
    });
  };
  const handleNavigateConfiguration = (row) => {
    navigate(`/programmes/programm-configuration/${row.program_slug}`, {
      state: row,
    });
  };

  const handleNavigateSection = (row) => {
    navigate(`/programmes/sections/${row.program_slug}`, {
      state: row,
    });
  };

  const handleNavigateReviews = (row) => {
    navigate(`/programmes/reviews/${row.program_slug}`, {
      state: row,
    });
  };

  const handleLockedProgramInfo = (row) => {
    navigate(`/programmes/locked-programme/${row.program_slug}`, {
      state: row,
    });
  };

  const handleManageLessons = (row) => {
    navigate(`/lessons/${row.program_slug}`, {
      state: row,
    });
  };
  const handleManageProgramModule = (row) => {
    navigate(`/programmes/modules/${row.program_slug}`, {
      state: row,
    });
  };
  const handleManageMember = (row) => {
    navigate(`/programmes/members/${row._id}`, {
      state: row,
    });
  };
  const ManageNavItemsAccess = (row) => {
    navigate(`/programmes/navitems/${row._id}`, {
      state: row,
    });
  };

  const ManageMembershipLevel = (row) => {
    navigate(`/programmes/manage-membership-levels/${row?.program_slug}`, {
      state: row,
    });
  };

  const handleNavigateQuestions = (row) => {
    navigate(`/programmes/delegate-questions?created_for_id=${row._id}`, {
      state: row,
    });
  };
  const handleNavigateAnswers = (row) => {
    navigate(
      `/programmes/delegate-questions/answers?created_for_id=${row._id}`,
      {
        state: row,
      }
    );
  };

  const handleDelete = async () => {
    let result = await deleteProgramme(ProgramData.program_slug);
    if (result.code === 200) {
      handleCloseDelete();
      programsList();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const delegate_questions_child_options = [
    {
      label: "Questions List",
      icon: "akar-icons:edit",
      handleClick: handleNavigateQuestions,
    },
    {
      label: "Question Answers",
      icon: "akar-icons:edit",
      handleClick: handleNavigateAnswers,
    },
  ];
  let MENU_OPTIONS = [
    {
      label: "Tab Configuration",
      icon: "akar-icons:edit",
      handleClick: handleNavigateConfiguration,
    },
    {
      label: "Manage Doc",
      icon: "akar-icons:edit",
      handleClick: handleNavigateDocs,
    },
    {
      label: "Manage Sections",
      icon: "akar-icons:edit",
      handleClick: handleNavigateSection,
    },
    {
      label: "Manage Lessons",
      icon: "akar-icons:edit",
      handleClick: handleManageLessons,
    },
    {
      label: "Manage Program Modules",
      icon: "akar-icons:edit",
      handleClick: handleManageProgramModule,
    },

    {
      label: "Manage Member",
      icon: "akar-icons:edit",
      handleClick: handleManageMember,
    },
    {
      label: "Manage Nav Items Access",
      icon: "fluent:video-recording-20-regular",
      handleClick: ManageNavItemsAccess,
    },
    // {
    //   label: "Manage Membership Level",
    //   icon: "cil:badge",
    //   handleClick: ManageMembershipLevel,
    // },
    {
      label: "Programme Reviews",
      icon: "akar-icons:edit",
      handleClick: handleNavigateReviews,
    },
    {
      label: "Automated Group",
      icon: "akar-icons:edit",
      handleClick: handleNavigateGroup,
    },
    {
      label: "Add Member Programme Access",
      icon: "akar-icons:edit",
      handleClick: handleNavigateAdd,
    },
    {
      label: "Remove Member Programme Access",
      icon: "akar-icons:edit",
      handleClick: handleNavigateRemove,
    },
    {
      label: "Locked Programme Info",
      icon: "akar-icons:edit",
      handleClick: handleLockedProgramInfo,
    },
    {
      label: "Delegate Questions",
      icon: "akar-icons:edit",
      child_options: delegate_questions_child_options,
    },
    {
      label: "Program Reminder Configuration",
      icon: "akar-icons:edit",
      handleClick: handleNavigateReminder,
    },
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleNavigateEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleOpenDelete,
    },
    {
      label: "Copy Delegate Training URL",
      icon: "akar-icons:edit",
      handleClick: handleCopyReferralId,
    },
  ];

  // -------------------------
  useEffect(() => {
    programsList();
  }, []);

  if (isLoading === true) {
    return <PageLoader />;
  }
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-4 col-md-8 ">
          <h2>Programme </h2>
        </div>
        <div
          className="col-lg-8 col-md-4 text-end"
          style={{ display: "flex", justifyContent: "end" }}
        >
          <div>
            <FormControl variant="outlined" style={{ marginTop: "2px" }}>
              <InputLabel htmlFor="outlined-adornment-password">
                Search
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type="text"
                variant="small"
                onChange={handleChangeSearch}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label="Search"
              />
            </FormControl>
          </div>
          <button
            onClick={() => navigate(`/programmes/add-programme`)}
            className="ms-3 mt-1 small-contained-button"
          >
            Add Programme
          </button>

          <button
            onClick={toggleView}
            className="ms-1 mt-1 small-contained-button"
          >
            {viewMode == "grid" ? (
              <>
                <GridViewIcon />
              </>
            ) : (
              <>
                <ListIcon />
                {/* List View */}
              </>
            )}
          </button>
        </div>

        {viewMode == "grid" ? (
          <>
            {getFilteredData(query)?.map((value, index) => {
              return (
                <div className="col-lg-4 col-md-4 col-sm-12 mb-4" key={index}>
                  <div className="card mt-4 pods-cards-shadow cursor h-100">
                    <img
                      src={s3baseUrl + value.program_images.thumbnail_1}
                      className="card-img-top pods-image"
                      alt="Programme"
                      onClick={() => handleNavigateDetail(value)}
                    />
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12">
                          <h3
                            className="h2-heading"
                            onClick={() => handleNavigateDetail(value)}
                          >
                            {htmlDecode(value.title)}
                          </h3>
                        </div>
                      </div>
                      <p
                        className="programme-card-desc mb-3"
                        onClick={() => handleNavigateDetail(value)}
                      >
                        {get_short_string(
                          htmlDecode(value.short_description),
                          140
                        )}
                        {value.short_description.length > 140 ? (
                          <span className="see-more-button">see more</span>
                        ) : (
                          ""
                        )}
                      </p>
                      <div className="row recording-card-date">
                        <div className="col-5 card-button recording-card-date-position">
                          <p className="pods-active-members">
                            <span> {value.no_of_lesson} Lessons</span>
                          </p>
                        </div>
                        <div className="col-7 text-end ms-auto">
                          {value.total_lesson_duration}
                        </div>
                      </div>
                      <div className="row recording-card-date ">
                        <div className="col-4 card-button recording-card-date-position ">
                          <p className="pods-active-members ">
                            {value.view_count ? (
                              <span> {value.view_count} Views</span>
                            ) : (
                              <span> 0 Views</span>
                            )}
                          </p>
                        </div>
                        <div className="col-8 text-end card-button recording-card-date-position ">
                          <p className="pods-active-members ">
                            {value.watch_count ? (
                              <span> {value.watch_count} Watch</span>
                            ) : (
                              <span> 0 Watch</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <div className="mt-4">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              MENU_OPTIONS={MENU_OPTIONS}
              data={getFilteredData(query)}
              className="card-with-background"
              pagePagination={true}
              is_hide={true}
            />
          </div>
        )}
        <CustomConfirmation
          open={openDelete}
          setOpen={setOpenDelete}
          title={"Are you sure you want to delete this programme?"}
          handleAgree={handleDelete}
        />
        <PopOverViewMoreNav
          handleClick={handleClickPopUPEvent}
          handleClose={handleClosePopUpEvent}
          anchorEl={anchorElEvnet}
          id={idevent}
          open={opensEvent}
          program={event}
          title="Nav Itmes"
        />
      </div>
    </div>
  );
}
