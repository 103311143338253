import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Checkbox,
  Chip,
  FormControlLabel,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { client_Url } from "src/config/config";
import { manage_commission_access_for_consultant_api } from "src/DAL/WebsitePages/WebsitePages";
import CopyToClipboard from "react-copy-to-clipboard";
import { consultant_detail_with_page_api } from "src/DAL/consultant/consultant";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import PageLoader from "src/components/GeneralComponents/PageLoader";

export default function ManageCommissionAccess() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const [selected, setSelected] = useState([]);
  const [selectedTemp, setSelectedTemp] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isAllFiltered, setIsAllFiltered] = useState(false);
  const [templateData, setTemplateData] = useState([]);
  const [delegateData, setDelegateData] = useState({});

  const get_consultant_detail = async () => {
    const result = await consultant_detail_with_page_api(params.id);
    if (result.code == 200) {
      setDelegateData(result.consultant);
      let selecte_array = [];
      result.consultant.sales_commission.map((page) => {
        selecte_array.push({
          _id: page.sale_page,
          commission_percentage: page.commission_percentage,
        });
      });
      setSelected(selecte_array);

      let mc_filter = result.consultant.page_acces_for_mission_control;
      setSelectedTemp(mc_filter);

      if (mc_filter.length == result.sale_page.length) {
        setIsAllFiltered(true);
      }

      let result_array = [];
      result.sale_page.map((template) => {
        let is_selected = false;
        let is_filter = mc_filter.find((item) => item == template._id);
        if (is_filter) {
          is_selected = true;
        }

        result_array.push({
          ...template,
          preview_link_data: {
            to: client_Url + template.sale_page_title_slug,
            target: "_blank",
            show_text: "Preview",
            className: "anchor-style",
          },
          commission_percentage: 0,
          is_mc_filter: is_selected,
        });
      });
      setTemplateData(result_array);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleCopiedUrl = () => {
    enqueueSnackbar("Preview Url copied to clipboard", { variant: "success" });
  };

  const handleChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    setTemplateData((templateData) =>
      templateData.map((obj) => {
        if (obj._id === name) {
          return { ...obj, commission_percentage: value };
        }
        return obj;
      })
    );
    setSelected((templateData) =>
      templateData.map((obj) => {
        if (obj._id === name) {
          return { ...obj, commission_percentage: value };
        }
        return obj;
      })
    );
  };

  const handle_click_check_box = async (e, row, index) => {
    let is_checked = e.target.checked;
    if (is_checked) {
      setSelectedTemp((old) => [...old, row._id]);
    } else {
      setSelectedTemp((old) => old.filter((item) => item !== row._id));
    }

    setTemplateData((old) =>
      old.map((obj) => {
        if (obj._id === row._id) {
          return { ...obj, is_mc_filter: is_checked };
        }
        return obj;
      })
    );
  };

  const handleChangeAll = async (e, row, index) => {
    let is_checked = e.target.checked;
    setIsAllFiltered(is_checked);
    if (is_checked) {
      let template_array = templateData.map((item) => item._id);
      setSelectedTemp(template_array);
    } else {
      setSelectedTemp([]);
    }

    setTemplateData((old) =>
      old.map((obj) => {
        return { ...obj, is_mc_filter: is_checked };
      })
    );
  };

  const TABLE_HEAD = [
    { id: "sale_page_title", label: "Page Title", alignRight: false },
    {
      id: "is_mc_filter",
      label: (
        <FormControlLabel
          control={<Checkbox checked={isAllFiltered} />}
          label=" &nbsp; MC Filter Access"
          onChange={handleChangeAll}
        />
      ),
      type: "checkbox",
      handleClick: (e, row, index) => {
        handle_click_check_box(e, row, index);
      },
    },
    {
      label: "Default Commission in %",
      alignRight: false,
      renderData: (row) => {
        let is_selected = selected.filter((object) => row._id === object._id);

        let co_commission_percentage =
          is_selected.length > 0
            ? is_selected[0].commission_percentage
            : row.commission_percentage;

        return (
          <TextField
            id="outlined-basic"
            variant="outlined"
            fullWidth
            size="small"
            type="number"
            required
            name={row._id}
            className="default_consultant_commission_input"
            value={co_commission_percentage}
            InputProps={{
              endAdornment: <span>%</span>,
              inputProps: { min: 1, max: 100 },
            }}
            sx={{
              "& fieldset": { border: "none" },
            }}
            onChange={handleChange}
          />
        );
      },
    },
    {
      id: "preview_link_data",
      label: "URL",
      type: "link",
      alignRight: false,
    },
  ];

  TABLE_HEAD.splice(2, 0, {
    label: "Copy URL",
    alignRight: false,
    renderData: (row) => {
      return (
        <CopyToClipboard
          text={client_Url + row.sale_page_title_slug}
          onCopy={() => handleCopiedUrl(true)}
        >
          <Tooltip title="Click to copy Preview URL">
            <Chip label="Copy URL" color="primary" variant="outlined" />
          </Tooltip>
        </CopyToClipboard>
      );
    },
  });

  const handleSubmit = async () => {
    setIsLoading(true);
    let selectedTemplates = [];
    selected.map((template) => {
      selectedTemplates.push({
        sale_page: template._id,
        commission_percentage: template.commission_percentage
          ? template.commission_percentage
          : 0,
      });
    });

    const postData = {
      consultant_id: params.id,
      sales_commission: selectedTemplates,
      page_acces_for_mission_control: selectedTemp,
    };

    const result = await manage_commission_access_for_consultant_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(`/consultant`);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  let breadCrumbMenu = [
    {
      title: "Delegates",
      navigation: -1,
      active: false,
    },
    {
      title: delegateData
        ? delegateData?.first_name +
          " " +
          delegateData?.last_name +
          " (" +
          delegateData?.email +
          ")"
        : "Manage Commission Access",
      active: true,
    },
  ];

  useEffect(() => {
    get_consultant_detail();
  }, []);

  if (isLoading == true) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex  mb-2">
        <div className="col-12 mb-3">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
        <div className="col-12">
          <h2>Manage Commission Access</h2>
        </div>
      </div>
      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        data={templateData}
        selected={selected}
        setSelected={setSelected}
        checkbox_selection={true}
        pagePagination={true}
      />
      <div className="ms-auto text-end" id="fixedbutton">
        <button
          className="small-contained-button mt-3 ml-auto"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
}
