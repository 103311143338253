import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import "react-tagsinput/react-tagsinput.css";
import TagsInput from "react-tagsinput";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { _update_notify_schedule } from "src/DAL/member/member";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function EmailNotificationSetting({
  onCloseDrawer,
  data,
  _id,
  setRow,
}) {
  const classes = useStyles();
  const member_id = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState([]);

  const [inputs, setInputs] = useState({
    title: "",
    status: "",
    email_subject: "",
    email_message: "",
    cc_emails: [],
    main_email: "",
  });

  const updateListObject = (newItem) => {
    setRow(newItem);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let cc_email_array = [];
    let cc_words_email_array = [];
    selected?.map((item, index) => {
      var pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const check = pattern.test(item);
      if (check == true) {
        const email_object = { email: item };
        cc_email_array.push(email_object);
      } else {
        cc_words_email_array.push(item);
      }
    });
    if (inputs.email_message.length <= 0) {
      enqueueSnackbar(` Email Messages Is required`, {
        variant: "error",
      });
      setIsLoading(false);
    } else {
      if (cc_email_array.length == selected.length) {
        let postData = {
          member_id: member_id.id,
          email_notification_info: {
            title: inputs.title,
            email_subject: inputs.email_subject,
            email_message: inputs.email_message,
            status: inputs.status,
            cc_emails: cc_email_array,
            main_email: inputs.main_email,
          },
        };
        console.log("postData  ___postData", postData);
        const result = await _update_notify_schedule(postData, _id);
        if (result.code === 200) {
          enqueueSnackbar(result.message, { variant: "success" });
          updateListObject(result?.event);
          onCloseDrawer();
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
        const wrappedWords = cc_words_email_array?.map((word) => `"${word}"`);
        const paragraph = wrappedWords.join(" ");
        enqueueSnackbar(`${paragraph} is must be a valid Email in CC Emails`, {
          variant: "error",
        });
      }
    }
  };

  useEffect(() => {
    setInputs({
      ...inputs,
      ...data.email_notification_info,
    });
    let Array_selected = [];
    data?.email_notification_info?.cc_emails?.map((item, index) => {
      Array_selected?.push(item.email);
    });
    setSelected(Array_selected);
  }, [data]);

  return (
    <div className="container-fluid new-memories">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div class="text-end mb-4">
            <button class="small-contained-button event-submit-button">
              {isLoading ? "Updating" : "Update"}
            </button>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mt-3">
            <TextField
              id="outlined-basic"
              label="Email Subject"
              variant="outlined"
              fullWidth
              required
              name="email_subject"
              value={inputs?.email_subject}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mt-3">
            <TextField
              id="outlined-basic"
              label="Email Body Heading"
              variant="outlined"
              fullWidth
              required
              name="title"
              value={inputs?.title}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12 mt-3">
            <TextField
              id="outlined-basic"
              label="Main Email"
              variant="outlined"
              type="email"
              fullWidth
              name="main_email"
              value={inputs?.main_email}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
            <TagsInput
              value={selected}
              onChange={setSelected}
              inputProps={{
                placeholder: "CC Emails",
              }}
              name="cc_emails"
              type="email"
              label="cc_emails"
            />
          </div>

          <div className="col-12 mt-3">
            <h4 style={{ color: "white" }}>Email Body Message *</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="email_message"
              editorHeight={320}
            />
          </div>
        </div>
      </form>
    </div>
  );
}
