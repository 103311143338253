import React, { useEffect, useState } from "react";
import {
  IconButton,
  OutlinedInput,
  InputLabel,
  FormControl,
  InputAdornment,
} from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import RecordNotFound from "src/components/RecordNotFound";
import DocumentCard from "src/components/ProgrammesList/ProgrammeDocuments/DocumentCard";
import {
  delete_lesson_document_api,
  lesson_document_list_api,
} from "src/DAL/Programme/Lessons/LessonDocument/LessonDocument";
import { lesson_detail_api } from "src/DAL/Programme/Lessons/Lessons";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import PageLoader from "src/components/GeneralComponents/PageLoader";

export const LessonDocuments = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [documentList, setDocumentList] = useState([]);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [deleteDoc, setDeleteDoc] = React.useState("");
  const [query, setQuery] = useState("");
  const [lessonName, setlessonName] = useState("");
  const [lessonDetailInfo, setLessonsDetailInfo] = useState();
  const { state } = useLocation();
  console.log(state, "statestatestate");
  let programName = lessonDetailInfo?.program[0]?._id?.title;
  let program_slug_navgation = lessonDetailInfo?.program[0]?._id?.program_slug;
  const getFilteredData = (query) => {
    let dataToFilter = [...documentList];
    if (query) {
      let resultFilter = dataToFilter.filter((post) =>
        post.title.toLowerCase().includes(query.toLowerCase())
      );
      return resultFilter;
    } else {
      return dataToFilter;
    }
  };

  const handleChangeSearch = (e) => {
    setQuery(e.target.value);
  };

  const handleEdit = (value) => {
    navigate(
      `/lessons/lesson-document/${params.lesson_slug}/edit-document/${value.document_slug}`,
      {
        state: value,
      }
    );
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_lesson_document_api(deleteDoc.document_slug);
    if (result.code === 200) {
      setDocumentList((prev) => {
        return prev.filter((data) => data._id !== deleteDoc._id);
      });
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleDetail = async () => {
    setIsLoading(true);
    let result = await lesson_detail_api(params.lesson_slug);
    if (result.code === 200) {
      setlessonName(result.lesson.title);
      setLessonsDetailInfo(result.lesson);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const getDocument = async () => {
    let result = await lesson_document_list_api(params.lesson_slug);
    if (result.code == 200) {
      setIsLoading(false);
      setDocumentList(result.lesson_document);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleNavigate = () => {
    navigate(`/lessons/lesson-document/${params.lesson_slug}/add-document`);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  let breadCrumbMenu = [
    {
      title: "Programmes",
      navigation: `/programmes`,
      active: false,
    },
    {
      title: programName,
      navigation: `/programmes/programmes-detail/${program_slug_navgation}`,
      active: false,
    },
    {
      title: "Lessons",
      navigation: `/lessons/${program_slug_navgation}`,
      active: false,
    },
    {
      title: lessonDetailInfo?.title,
      navigation: `/lessons/lesson-detail/${params.lesson_slug}`,
      active: false,
    },
    {
      title: "Lesson Documents",
      navigation: ``,
      active: true,
    },
  ];

  useEffect(() => {
    getDocument();
  }, []);
  useEffect(() => {
    if (state) {
      setLessonsDetailInfo(state);
    } else {
      handleDetail();
    }
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this document?"}
        handleAgree={handleDelete}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 d-flex mb-3">
            <span className="mt-2">
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
          </div>

          <div className="col-lg-4 col-md-8 ">
            <h2>Lesson Documents</h2>
          </div>

          <div
            className="col-lg-8 col-md-4 text-end"
            style={{ display: "flex", justifyContent: "end" }}
          >
            <div>
              <FormControl variant="outlined" style={{ marginTop: "2px" }}>
                <InputLabel htmlFor="outlined-adornment-password">
                  Search
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type="text"
                  variant="small"
                  onChange={handleChangeSearch}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Search"
                />
              </FormControl>
            </div>
            <button
              onClick={handleNavigate}
              className="ms-3 mt-1 small-contained-button"
            >
              Add Document
            </button>
          </div>

          <div className="col-lg-12 col-sm-12 text-end"></div>
          {getFilteredData(query).length < 1 ? (
            <RecordNotFound title="Document" />
          ) : (
            getFilteredData(query)?.map((value, i) => {
              return <DocumentCard value={value} MENU_OPTIONS={MENU_OPTIONS} />;
            })
          )}
        </div>
      </div>
    </>
  );
};
