import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import { programmeActiveListing } from "src/DAL/Programme/Programme";
import {
  addCommunityRewardApi,
  editCommunityListing,
  reward_deatil_api,
} from "src/DAL/Community/community";
import { useEffect } from "react";
import { s3baseUrl } from "src/config/config";
import { COMMUNITY_LEVELS } from "src/utils/constant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function AddOrUpdateReward() {
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [programList, setProgramList] = useState([]);
  const [formType, setFormType] = useState("ADD");
  const [inputs, setInputs] = useState({
    title: "",
    status: true,
    main_image: {},
    icon_without_border: {},
    created_for: "general",
    reward_type: "dynamite",
    priority: 0,
    program: "",
  });

  const [oldImages, setOldImages] = React.useState({
    main_image: "",
    icon_without_border: "",
  });

  const [images, setImages] = React.useState({
    main_image: "",
    icon_without_border: "",
  });

  const getProgram = async () => {
    const result = await programmeActiveListing();
    if (result.code === 200) {
      setProgramList(result.program);
    }
  };

  const getRewardDeatil = async () => {
    setIsLoading(true);
    const result = await reward_deatil_api(params.reward_id);
    if (result.code === 200) {
      formatData(result.reward);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fileChangedHandler = (e) => {
    const { name, files } = e.target;
    setInputs({
      ...inputs,
      [name]: files[0],
    });
    setImages({
      ...images,
      [name]: URL.createObjectURL(files[0]),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.priority < 0) {
      enqueueSnackbar("Priority must be greater than 0", { variant: "error" });
    } else {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("status", inputs.status);
      formData.append("title", inputs.title);
      formData.append("reward_type", inputs.reward_type);
      formData.append("created_for", inputs.created_for);
      formData.append("priority", inputs.priority);

      if (formType === "EDIT") {
        formData.append("order", inputs.order);
        if (images.main_image) {
          formData.append("main_image", inputs.main_image);
        }
        if (images.icon_without_border) {
          formData.append("icon_without_border", inputs.icon_without_border);
        }
      } else {
        formData.append("main_image", inputs.main_image);
        formData.append("icon_without_border", inputs.icon_without_border);
      }

      if (inputs.created_for === "program") {
        formData.append("program_id", inputs.program);
      }

      const result =
        formType == "ADD"
          ? await addCommunityRewardApi(formData)
          : await editCommunityListing(formData, params.reward_id);
      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        navigate(-1);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const formatData = (data) => {
    setFormType("EDIT");
    setInputs(data);
    setOldImages({
      main_image: data.icon_with_border.thumbnail_1,
      icon_without_border: data.icon_without_border.thumbnail_1,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    getProgram();
    if (params.reward_id) {
      if (state) {
        formatData(state);
      } else {
        getRewardDeatil();
      }
    }
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex ">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <h2>{`${formType === "ADD" ? "Add" : "Edit"} Community`}</h2>
        </div>
      </div>

      <form action="" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              fullWidth
              required
              name="title"
              value={inputs.title}
              onChange={handleChange}
            />
          </div>
          {formType === "EDIT" && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Order"
                variant="outlined"
                fullWidth
                required
                name="order"
                value={inputs.order}
                onChange={handleChange}
              />
            </div>
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Level</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="reward_type"
                value={inputs.reward_type}
                label="Level"
                onChange={handleChange}
              >
                {COMMUNITY_LEVELS.map((level) => {
                  return <MenuItem value={level.name}>{level.title}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Reward Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="created_for"
                value={inputs.created_for}
                label="Reward Type"
                onChange={handleChange}
              >
                <MenuItem value="general">General</MenuItem>
                <MenuItem value="program">Program</MenuItem>
                <MenuItem value="dynamite_membership">
                  Dynamite Membership
                </MenuItem>
                <MenuItem value="basic_membership">Basic Membership</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                value={inputs.status}
                label="Status *"
                onChange={handleChange}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs.created_for == "program" && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">Program </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="program"
                  value={inputs.program}
                  label="Program *"
                  onChange={handleChange}
                >
                  {programList.map((program) => {
                    return (
                      <MenuItem value={program._id}>{program.title}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Priority"
              variant="outlined"
              fullWidth
              required
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              name="priority"
              value={inputs.priority}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Upload Icon *</p>
                <FormHelperText className="pt-0">
                  Image Size(50 X 50) ("PNG", "SVG")
                </FormHelperText>
              </div>
              <div className="col-2">
                {images.main_image ? (
                  <img src={images.main_image} height="50" />
                ) : oldImages.main_image ? (
                  <img src={s3baseUrl + oldImages.main_image} height="50" />
                ) : (
                  ""
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="main_image"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.main_image?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.main_image?.name}</p>
            )}
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Upload Icon Without Border*</p>
                <FormHelperText className="pt-0">("PNG", "SVG")</FormHelperText>
              </div>
              <div className="col-2">
                {images.icon_without_border ? (
                  <img src={images.icon_without_border} height="50" />
                ) : oldImages.icon_without_border ? (
                  <img
                    src={s3baseUrl + oldImages.icon_without_border}
                    height="50"
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file-border">
                  <Input
                    accept="image/*"
                    id="contained-button-file-border"
                    multiple
                    type="file"
                    name="icon_without_border"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.icon_without_border?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">
                {inputs.icon_without_border?.name}
              </p>
            )}
          </div>
          <div
            className="text-end mt-4"
            id={formType == "ADD" ? "" : "fixedbutton"}
          >
            <button className="small-contained-button">
              {formType == "ADD" ? "Submit" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
