import * as React from "react";
import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  IconButton,
  CircularProgress,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import {
  add_agreeement_configuration,
  add_reminder_configuration,
} from "src/DAL/WebsitePages/WebsitePages";
import { useEffect } from "react";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { detailPaymentPlanApi } from "src/DAL/WebsitePages/paymentPlan";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import GeneralIterativeCkeditor from "src/components/GeneralComponents/GeneralIterativeCkeditor";
import { clientSettingApi } from "src/DAL/ClientSetting/ClientSetting";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ReminderConfiguration() {
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [inputs, setInputs] = useState({
    reminder_after_days_1: "",
    reminder_message_1: "",
    reminder_after_days_2: "",
    reminder_message_2: "",
    reminder_after_days_3: "",
    reminder_message_3: "",
  });
  const [inputList, setInputList] = useState([
    {
      reminder_days: "",
      reminder_type: "before_expiry",
      reminder_message: "",
    },
  ]);
  const handleAddClick = (index) => {
    setInputList([
      ...inputList.slice(0, index),
      {
        reminder_days: "",
        reminder_type: "before_expiry",
        reminder_message: "",
      },
      ...inputList.slice(index),
    ]);
  };
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log(name, value, "value from the downside");

    // setInputList((values) => ({ ...values, [name]: value }));
    // if (inputList.length < 1) {
    //   setInputList([
    //     {
    //       reminder_days: 0,
    //       reminder_type: "before_expiry",
    //       reminder_message: "",
    //     },
    //   ]);
    // }
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };
  const getPlanDetail = async () => {
    const result = await detailPaymentPlanApi(params.plan_id);
    if (result.code == 200) {
      let configration = result.payment_plan.agrement_configration;
      if (configration) {
        handleFormatdata(configration);
      } else {
        setIsLoading(false);
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleFormatdata = (data) => {
    setInputs({
      ...data,
    });
    setIsLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoadingForm(true);
    let postData = {
      payment_notify_schedule: inputList,
    };

    const result = await add_reminder_configuration(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const clientDetail = async () => {
    setIsLoading(true);
    const result = await clientSettingApi();
    if (result.code == 200) {
      console.log(result, "result for the reminder");
      if (result.content_setting.payment_notify_schedule) {
        setInputList(result.content_setting.payment_notify_schedule);
      }
      // let generalSetting = result?.content_setting?.client_content_setting;
      // setInputs(generalSetting);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  // const handleChange = (event) => {
  //   const name = event.target.name;
  //   const value = event.target.value;
  //   setInputs((values) => ({ ...values, [name]: value }));
  // };

  useEffect(() => {
    clientDetail();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      {/* <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div> */}
      <div className="row mb-3">
        <div className="col-6">
          <h2>Reminder Setting</h2>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        {inputList.map((questions, index) => {
          return (
            <>
              <div className="row mb-4 setting-card">
                <div className="col-10">
                  <div className="row">
                    <div className="col-6">
                      <TextField
                        id="outlined-basic"
                        label="Reminder After Days 1"
                        variant="outlined"
                        fullWidth
                        type="number"
                        name="reminder_days"
                        value={questions.reminder_days}
                        onChange={(e) => handleInputChange(e, index)}
                      />
                    </div>
                    <div className="col-6">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Reminder Type *
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="reminder_type"
                          value={questions.reminder_type}
                          label="Reminder Type"
                          onChange={(e) => handleInputChange(e, index)}
                        >
                          <MenuItem value="before_expiry">
                            Before Invoice Expiry
                          </MenuItem>
                          <MenuItem value="after_expiry">
                            After Invoice Expiry
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    <div className="col-12 mt-4">
                      <h4>Reminder Message</h4>
                      <GeneralIterativeCkeditor
                        setInputs={setInputList}
                        inputs={inputList}
                        name="reminder_message"
                        index={index}
                        editorHeight={320}
                      />
                      {/* <TextareaAutosize
                        minRows={10}
                        maxRows={10}
                        aria-label="empty textarea"
                        value={questions.reminder_message}
                        name="reminder_message"
                        onChange={(e) => handleInputChange(e, index)}
                        className="textarea-autosize"
                        placeholder="Reminder Message"
                        style={{
                          width: "100%",
                          height: "100px",
                          backgroundColor: "transparent",
                          color: "white",
                          borderRadius: "10px",
                          paddingTop: "8px",
                          paddingLeft: "5px",
                          outline: "none",
                        }}
                      /> */}
                    </div>
                  </div>
                </div>
                <div className="col-2">
                  <AddCircleOutlineIcon
                    className="question-survey"
                    onClick={() => handleAddClick(index + 1)}
                  />
                  {inputList.length !== 1 && (
                    <RemoveCircleOutlineIcon
                      className="question-survey-cross"
                      onClick={() => handleRemoveClick(index)}
                    />
                  )}
                </div>
              </div>
            </>
          );
        })}
        <div className="text-end mt-4" id="fixedbutton">
          <button className="small-contained-button">
            {isLoadingForm ? "Updating..." : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
