import React from "react";
import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import {
  DeletePaymentTemplateApi,
  paymentTemplateListApiPagination,
} from "src/DAL/PaymentTemplate/paymentTemplateApi";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import PaymentTemplateFilter from "./PaymentTemlateFilter";
import {
  convertCurrencyToSign,
  dd_date_format,
  show_proper_words,
} from "src/utils/constant";
import FilteredChip from "src/components/FilteredChip";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import Label from "src/components/Label";

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const get_is_basic_membership = (row) => {
  return (
    <>
      <Label
        variant="ghost"
        color={
          !row.is_basic_membership || !row.basic_membership_days
            ? "error"
            : "success"
        }
      >
        {row.basic_membership_days && row.is_basic_membership
          ? `Membership Days ${row.basic_membership_days}`
          : "No"}
      </Label>
    </>
  );
};

export default function PaymentTemplateList() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [pageCount, setPageCount] = useState(1);

  const EMPTY_FILTER = {
    type: "all",
    id: null,
  };
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [updateFilterData, setUpdateFilterData] = useState(EMPTY_FILTER);

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getMemberListing(filterData);
    localStorage.setItem("payment_template_data", JSON.stringify(filterData));
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);
    if (data.type !== "consultant") {
      data.id = null;
    }
    setFilterData(data);
    getMemberListing(data);
    localStorage.setItem("payment_template_data", JSON.stringify(data));
  };

  const handleClearFilter = () => {
    setFilterData(EMPTY_FILTER);
    getMemberListing(EMPTY_FILTER);
    localStorage.removeItem("payment_template_data");
  };

  const handleSelectOther = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const getMemberListing = async (filter_data) => {
    setIsLoading(true);
    let postData = {
      ...filter_data,
    };

    if (postData.id) {
      postData.sale_page = filter_data.id._id;
    } else {
      postData.sale_page = "";
    }

    let search_keyword =
      localStorage.getItem("payment_search_text") == null
        ? searchText
        : localStorage.getItem("payment_search_text");
    if (search_keyword !== null) {
      setSearchText(search_keyword);
    }

    handleCloseFilterDrawer();
    const result = await paymentTemplateListApiPagination(
      page,
      rowsPerPage,
      postData,
      search_keyword
    );
    if (result.code == 200) {
      let chipData = { ...filter_data };
      if (chipData.type !== "consultant") {
        delete chipData.id;
      }
      setUpdateFilterData(chipData);
      const members = result?.payment_template?.map((template, index) => {
        let program_name = "N/A";
        let product_name = "N/A";
        let lead_status_title = "N/A";
        let consider_purchasing_user = "N/A";
        let initial_amount = 0;
        let installment_amount = 0;
        let month = 0;

        if (template?.lead_status) {
          lead_status_title = template?.lead_status?.title;
        }

        if (template?.consider_purchasing_user) {
          consider_purchasing_user = template?.consider_purchasing_user;
        }

        if (template.program) {
          program_name = template.program.title;
        }

        if (template.product) {
          product_name = template.product.name;
        }
        if (template.template_type == "recurring") {
          initial_amount = template.initial_amount;
          installment_amount = template.installment_amount;
          month = template.month;
        }

        let actionBy =
          template.action_by == "admin_user"
            ? "Admin" + " (" + template.action_info.name + ")"
            : template.action_by == "consultant_user"
            ? "Delegate" + " (" + template.action_info.name + ")"
            : "N/A";

        return {
          ...template,
          name: template.title,
          lead_status_title: lead_status_title,
          consider_purchasing_user_type: consider_purchasing_user,
          program_name,
          month,
          product_name,
          template_type: template.template_type,
          total_amount_name:
            convertCurrencyToSign(template.currency) + template.total_amount,
          initial_amount_name:
            convertCurrencyToSign(template.currency) + initial_amount,
          installment_amount_name:
            convertCurrencyToSign(template.currency) + installment_amount,
          actionBy,
          description: template.short_description,
          created_at: dd_date_format(template.createdAt),
          object: template,
        };
      });
      setTotalPages(result.total_pages);
      setData(members);
      setTotalCount(result.total_payment_template_count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };

  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const handleEdit = (value) => {
    navigate(`/payment-template/edit-template/${value._id}`, {
      state: value,
    });
  };

  const handleQuestAccess = (value) => {
    navigate(`/payment-template/quest-access/${value._id}`, {
      state: value,
    });
  };

  const handleMissionAccess = (value) => {
    navigate(`/payment-template/mission-access/${value._id}`, {
      state: value,
    });
  };

  const handlecommissionConfiguration = (value) => {
    navigate(`/payment-template/commission-configuration/${value._id}`, {
      state: value,
    });
  };

  const handleNavigate = () => {
    navigate(`/payment-template/add-template`);
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await DeletePaymentTemplateApi(deleteDoc._id);
    if (result.code === 200) {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
      getMemberListing(filterData);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAgreementConfig = (value) => {
    navigate(`/payment-template/payment-agreement-config/${value._id}`, {
      state: value,
    });
  };
  const handlemanageProgramAccess = (value) => {
    navigate(`/payment-template/payment-program-access/${value._id}`, {
      state: value,
    });
  };
  const handlemanageEventAccess = (value) => {
    navigate(`/payment-template/payment-event-access/${value._id}`, {
      state: value,
    });
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Commission configuration",
      icon: "akar-icons:edit",
      handleClick: handlecommissionConfiguration,
    },
    {
      label: "Agreement Configuration",
      icon: "akar-icons:edit",
      handleClick: handleAgreementConfig,
    },
    {
      label: "Manage Programme Access",
      icon: "akar-icons:edit",
      handleClick: handlemanageProgramAccess,
    },
    {
      label: "Manage Event Access",
      icon: "akar-icons:edit",
      handleClick: handlemanageEventAccess,
    },
    {
      label: "Manage Mission Access",
      icon: "ant-design:key",
      handleClick: handleMissionAccess,
    },
    {
      label: "Manage Quest Access",
      icon: "ant-design:key",
      handleClick: handleQuestAccess,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("payment_template_data");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
    }
    setFilterData(filter_data);
    setUpdateFilterData(filter_data);
    getMemberListing(filter_data);
  }, [rowsPerPage, page]);

  useEffect(() => {
    localStorage.setItem("payment_search_text", searchText);
    if (searchText.length < 1) {
      localStorage.setItem("payment_search_text", "");
    } else {
      localStorage.setItem("payment_search_text", searchText);
    }
  }, [searchText]);

  const TABLE_HEAD = [
    {
      id: "action",
      label: "Action",
      type: "action",
      MENU_OPTIONS: "MENU_OPTIONS",
    },
    { id: "number", label: "#", type: "number" },
    { id: "name", label: "Title" },
    { id: "program_name", label: "Programme" },
    { id: "product_name", label: "Product" },
    {
      id: "template_type",
      label: "Template Type",
      className: "text-capitalize",
    },
    {
      id: "consider_purchasing_user_type",
      label: "Consider Purchasing User",
      className: "text-capitalize",
    },
    { id: "lead_status_title", label: "Lead Status Title" },
    { id: "total_amount_name", label: "Total Amount" },
    { id: "initial_amount_name", label: "Initial Amount" },
    { id: "installment_amount_name", label: "Installment Amount" },
    {
      id: "no_of_installment",
      label: "No of Installments",
    },
    { id: "actionBy", label: "Action By" },
    {
      id: "is_basic_membership",
      label: "Is Basic Membership",
      renderData: (row) => get_is_basic_membership(row),
    },
    { id: "created_at", label: "Created At" },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8 col-sm-12">
            <h2>Payment Template</h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            <button
              className="small-contained-button me-2 mt-1 mb-4"
              onClick={handleOpenFilterDrawer}
            >
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>
            <button onClick={handleNavigate} className="small-contained-button">
              Add Template
            </button>
          </div>
        </div>
        <FilteredChip
          data={updateFilterData}
          tempState={filterData}
          EMPTY_FILTER={EMPTY_FILTER}
          onDeleteChip={handleDeleteChip}
          onClear={handleClearFilter}
        />
        <div className="row">
          <div className="col-12">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={data}
              MENU_OPTIONS={MENU_OPTIONS}
              className="card-with-background"
              custom_pagination={{
                total_count: totalCount,
                rows_per_page: rowsPerPage,
                page: page,
                handleChangePage: handleChangePage,
                onRowsPerPageChange: handleChangeRowsPerPage,
              }}
              custom_search={{
                searchText: searchText,
                setSearchText: setSearchText,
                handleSubmit: searchFunction,
              }}
              pageCount={pageCount}
              totalPages={totalPages}
              handleChangePages={handleChangePages}
              pagePagination={true}
            />
          </div>
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <PaymentTemplateFilter
            filterData={filterData}
            handleSelectOther={handleSelectOther}
            searchFunction={searchFunction}
            handleClearFilter={handleClearFilter}
          />
        }
      />
    </>
  );
}
