import * as React from "react";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { Autocomplete } from "@mui/material";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

import {
  _member_badge_levels,
  _update_member_badge_levels,
} from "src/DAL/member/member";
import PageLoader from "src/components/GeneralComponents/PageLoader";

export default function ManageMembershipLevelsForMembers() {
  const navigate = useNavigate();
  const { member_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [membershipLevelList, setMembershipLevelList] = useState([]);
  const [selectedMembershipLevel, setSelectedMembershipLevel] = useState(null);
  const [inputData, setInputData] = useState({
    name: "",
    badges: {},
  });

  const handleChange = (value, name) => {
    const list = { ...inputData };
    list[name] = value;
    if (name == "name") {
      list["badges"] = null;
    }
    setInputData(list);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let badgeLevel = {};
    const selectedEvent = membershipLevelList.find(
      (event) => event?.title === inputData?.name
    );

    if (inputData?.badges?._id) {
      badgeLevel = {
        _id: inputData?.badges?._id || "",
        title: inputData?.badges?.title || "",
        alias_title: inputData?.badges?.alias_title || "",
      };
    }

    const postData = {
      membership_level_info: {
        _id: selectedEvent?._id || "",
        title: inputData?.name || "",
      },
      badge_level: badgeLevel,
    };

    setIsLoading(true);
    const result = await _update_member_badge_levels(postData, member_id);

    if (result.code === 200) {
      navigate(-1);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const getMemberDetail = async () => {
    setIsLoading(true);
    const result = await _member_badge_levels(member_id);
    if (result.code === 200) {
      setMembershipLevelList(result?.membership_levels);
      setSelectedMembershipLevel(result?.member || null);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMemberDetail();
  }, []);

  useEffect(() => {
    if (selectedMembershipLevel) {
      const updatedInputList = {
        name: selectedMembershipLevel?.membership_level?.title,
        badges: selectedMembershipLevel?.badge_level || {},
      };

      setInputData(updatedInputList);
    }
  }, [selectedMembershipLevel]);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <ActiveLastBreadcrumb
            breadCrumbMenu={[
              {
                title: `Members (${selectedMembershipLevel?.first_name} ${selectedMembershipLevel?.last_name})`,
                navigation: `/member`,
                active: false,
              },
              { title: "Manage Membership Level", active: true },
            ]}
          />
        </div>
      </div>

      <form className="row mt-3" onSubmit={handleSubmit}>
        <div className="px-4">
          <div className="row pb-3 mb-3 manage-event-pixel-bg">
            <div className="col-12 col-md-6 mt-3">
              <Autocomplete
                fullWidth
                options={membershipLevelList}
                getOptionLabel={(option) => option.title || ""}
                value={
                  membershipLevelList.find(
                    (event) => event.title === inputData.name
                  ) || null
                }
                onChange={(event, newValue) =>
                  handleChange(newValue ? newValue.title : "", "name")
                }
                isOptionEqualToValue={(option, value) =>
                  option.title === value.title
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Membership Level List"
                    required
                  />
                )}
                noOptionsText="No Option Available"
              />
            </div>

            {inputData.name && (
              <div className="col-12 col-md-6 mt-3">
                <Autocomplete
                  options={
                    membershipLevelList.find(
                      (event) => event.title === inputData.name
                    )?.membership_level_badges || []
                  }
                  getOptionLabel={(badge) => badge.title || ""}
                  value={inputData.badges || null}
                  onChange={(event, newValue) =>
                    handleChange(newValue || {}, "badges")
                  }
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Badge"
                      placeholder="Badge"
                    />
                  )}
                  noOptionsText="No Badge Available"
                />
              </div>
            )}
          </div>
        </div>

        <div className="text-end" id="fixedbutton">
          <button className="small-contained-button">Submit</button>
        </div>
      </form>
    </div>
  );
}
