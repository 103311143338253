import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { paymentRequestMemberProgramsTemplateProductListApi } from "src/DAL/Programme/Programme";
import {
  add_payment_request_api,
  payment_request_detail_api,
  update_payment_request_api,
} from "src/DAL/PaymentRequest/paymentRequestApi";
import { project_mode } from "src/config/config";
import { paymentTemplateDetailApi } from "src/DAL/PaymentTemplate/paymentTemplateApi";
import { events_programs_product_lead_status_api } from "src/DAL/DynamiteEvents/DynamiteEvents";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const EMPTY_DATA = {
  request_title: "",
  currency: "gbp",
  total_amount: "",
  vat_number: "",
  payment_mode: project_mode == "dev" ? "sandBox" : "live",
  transaction_note: "",
  payment_template: null,
  template_type: "onetime",
  product: null,
  status: true,
  programme: null,
  member: "",
  initial_amount: "",
  is_basic_membership: false,
  no_of_installment: 0,
  basic_membership_days: 0,
  installment_amount: "",
  request_iteration_type: "month",
  first_name: "",
  last_name: "",
  email: "",
  number_of_days: "0",
  show_on_consultant: "no",
  lead_status: null,
  consider_purchasing_user: "free",
};

const get_full_name = (user) => {
  return user.first_name + " " + user.last_name + " (" + user.email + ")";
};

export default function AddOrUpdatePaymentRequest() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { request_slug } = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [membersList, setMembersList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [products, setProducts] = useState([]);
  const [programmes, setProgrammes] = useState([]);
  const [installmentAmount, setInstallmentAmount] = useState(0);
  const [member, setMember] = useState(null);
  const [serchText, setSerchText] = useState("");
  const [inputs, setInputs] = useState(EMPTY_DATA);
  const [leadStatusList, setLeadStatusList] = useState([]);
  const [open, setOpen] = useState(false);

  const get_data_list = async () => {
    const result = await events_programs_product_lead_status_api();
    if (result.code === 200) {
      setLeadStatusList(result.lead_status);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const get_list_of_data = async () => {
    const result = await paymentRequestMemberProgramsTemplateProductListApi("");
    if (result.code == 200) {
      setProgrammes(result.program);
      setTemplates(result.payment_template);
      setProducts(result.product);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const get_request_detail = async () => {
    const result = await payment_request_detail_api(request_slug);
    if (result.code == 200) {
      handleFormatData(result.payment_request);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getMemberList = async () => {
    const result = await paymentRequestMemberProgramsTemplateProductListApi(
      serchText
    );
    if (result.code === 200) {
      let members = [];
      if (result.members.length > 0) {
        members = result.members.map((user) => {
          user.full_name = get_full_name(user);
          return user;
        });
      }
      setMembersList(members);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    if (!request_slug && !inputs.status && !open) {
      setOpen(true);
      return;
    }
    setIsLoading(true);
    let postData = {
      request_title: inputs.request_title,
      request_type: inputs.template_type,
      total_amount: inputs.total_amount,
      currency: inputs.currency,
      payment_mode: inputs.payment_mode,
      vat_number: inputs.vat_number,
      transaction_note: inputs.transaction_note,
      product: inputs.product,
      status: inputs.status,
      show_on_consultant: inputs.show_on_consultant,
      member_id: member._id,
      consider_purchasing_user: inputs?.consider_purchasing_user,
    };

    if (inputs?.lead_status) {
      postData.lead_status = inputs?.lead_status._id;
    }

    if (inputs.product) {
      postData.product = inputs.product._id;
    }
    if (inputs.programme) {
      postData.program_slug = inputs.programme.program_slug;
    }
    if (inputs.payment_template) {
      postData.payment_template = inputs.payment_template._id;
    }

    if (inputs.template_type === "recurring") {
      postData.initial_amount = inputs.initial_amount;
      postData.month = inputs.no_of_installment;
      postData.request_iteration_type = inputs.request_iteration_type;

      if (inputs.request_iteration_type == "custom") {
        postData.number_of_days = inputs.number_of_days;
      }
    } else {
      postData.is_basic_membership = inputs.is_basic_membership;
      if (inputs.is_basic_membership) {
        postData.basic_membership_days = inputs.basic_membership_days;
      }
    }

    const result = request_slug
      ? await update_payment_request_api(postData, request_slug)
      : await add_payment_request_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(`/payment-request`);
      setOpen(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setOpen(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeOthers = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeTemplate = async (value) => {
    if (value) {
      setIsLoading(true);
      const result = await paymentTemplateDetailApi(value._id);
      if (result.code == 200) {
        let template_detail = result.payment_template;
        setInputs((old) => ({
          ...old,
          ...template_detail,
          request_title: template_detail.title,
          request_iteration_type: template_detail.interval_type,
          programme: template_detail.program,
          payment_template: value,
        }));
        setIsLoading(false);
      }
      setIsLoading(false);
    } else {
      setInputs((old) => ({ ...old, payment_template: value }));
    }
  };

  const handleFormatData = (data) => {
    console.log("data   ___data  ___handleFormatData", data);

    if (data.is_basic_membership == undefined) {
      data.is_basic_membership = false;
    }

    let leadStatus = data?.lead_status ? data?.lead_status : null;

    setInputs((values) => ({
      ...values,
      ...data,
      template_type: data.request_type,
      no_of_installment: data.month,
      programme: data.program,
      lead_status: leadStatus,
      show_on_consultant: data?.show_on_consultant
        ? data?.show_on_consultant
        : "no",

      ["consider_purchasing_user"]: data?.consider_purchasing_user
        ? data?.consider_purchasing_user
        : "free",
    }));

    let { member } = data;
    member.full_name = get_full_name(data.member);
    setMember(member);
    setIsLoading(false);
    window.history.replaceState({}, document.title);
  };

  useEffect(() => {
    get_data_list();
    get_list_of_data();
    if (request_slug) {
      setIsLoading(true);
      if (state) {
        handleFormatData(state);
      } else {
        get_request_detail();
      }
    }
  }, []);

  useEffect(() => {
    getMemberList();
  }, [serchText]);

  useEffect(() => {
    console.log("inputs   ___inputs", inputs?.lead_status);
  }, [inputs]);

  useEffect(() => {
    let totalValue = inputs.total_amount - inputs.initial_amount;
    let installment = (totalValue / inputs.no_of_installment).toFixed(1);
    setInstallmentAmount(installment);
  }, [inputs.total_amount, inputs.no_of_installment, inputs.initial_amount]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <form className="container-fluid" onSubmit={handleSubmit}>
      <div className="row mobile-margin display-flex mt-2">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(`/payment-request`)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2 className="ms-2">{`${
            request_slug ? "Edit" : "Add"
          } Payment Request`}</h2>
        </div>
      </div>
      <div className="mt-4">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4 mt-md-0">
            <MUIAutocomplete
              inputLabel="Payment Template Type"
              selectedOption={inputs.payment_template}
              setSelectedOption={(value) => {
                handleChangeTemplate(value);
              }}
              optionsList={templates}
              name="title"
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4 mt-md-0">
            <MUIAutocomplete
              inputLabel="Members"
              selectedOption={member}
              setSelectedOption={setMember}
              optionsList={membersList}
              setCustomSearch={setSerchText}
              name="full_name"
              required
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Request Title"
              variant="outlined"
              fullWidth
              required
              name="request_title"
              value={inputs.request_title}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                value={inputs.status}
                label="Status"
                onChange={handleChange}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Delegates Access
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="show_on_consultant"
                value={inputs.show_on_consultant}
                label="Delegates Access"
                onChange={handleChange}
              >
                <MenuItem value="no">No Access</MenuItem>
                <MenuItem value="list">Listing Access</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label" required>
                Currency
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="currency"
                value={inputs.currency}
                label="currency"
                onChange={handleChange}
                required
              >
                <MenuItem value="usd">Dollar</MenuItem>
                <MenuItem value="gbp">UK Pounds</MenuItem>
                <MenuItem value="eur">Euro</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <MUIAutocomplete
              inputLabel="Product"
              selectedOption={inputs.product}
              setSelectedOption={(value) => {
                handleChangeOthers("product", value);
              }}
              optionsList={products}
              name="name"
              required
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <MUIAutocomplete
              inputLabel="Programme"
              selectedOption={inputs.programme}
              setSelectedOption={(value) => {
                handleChangeOthers("programme", value);
              }}
              optionsList={programmes}
              name="title"
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Payment request Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="template_type"
                value={inputs.template_type}
                label="Payment Request Type"
                onChange={handleChange}
                required
              >
                <MenuItem value="onetime">One Time</MenuItem>
                <MenuItem value="recurring">Recurring</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Total Amount"
              variant="outlined"
              fullWidth
              type="number"
              required
              name="total_amount"
              value={inputs.total_amount}
              onChange={handleChange}
            />
          </div>
          {inputs.template_type === "recurring" ? (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Initial Amount"
                  variant="outlined"
                  fullWidth
                  type="number"
                  required
                  name="initial_amount"
                  value={inputs.initial_amount}
                  onChange={handleChange}
                />
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="No. Of Installments"
                  variant="outlined"
                  fullWidth
                  type="number"
                  required
                  name="no_of_installment"
                  value={inputs.no_of_installment}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Installment Amount"
                  variant="outlined"
                  fullWidth
                  type="number"
                  required
                  aria-readonly
                  name="installment_amount"
                  value={installmentAmount}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label1">
                    Plan Payment Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label1"
                    id="demo-simple-select"
                    name="request_iteration_type"
                    value={
                      inputs.request_iteration_type
                        ? inputs.request_iteration_type
                        : "week"
                    }
                    label="Plan Payment Type"
                    onChange={handleChange}
                    required
                  >
                    <MenuItem value="month">Monthly</MenuItem>
                    <MenuItem value="week">Weekly</MenuItem>
                    <MenuItem value="year">Yearly</MenuItem>
                    <MenuItem value="custom">Custom</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {inputs.request_iteration_type == "custom" && (
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="No of days"
                    variant="outlined"
                    fullWidth
                    required
                    type="number"
                    name="number_of_days"
                    value={inputs.number_of_days}
                    onChange={handleChange}
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Is Basic Membership*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="is_basic_membership"
                    value={inputs.is_basic_membership}
                    label="Is Basic Membership*"
                    onChange={handleChange}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {inputs.is_basic_membership && (
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Basic Membership Days"
                    variant="outlined"
                    fullWidth
                    type="number"
                    required
                    name="basic_membership_days"
                    value={inputs.basic_membership_days}
                    onChange={handleChange}
                  />
                </div>
              )}
            </>
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Vat Number"
              variant="outlined"
              fullWidth
              name="vat_number"
              value={inputs.vat_number}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <MUIAutocomplete
              inputLabel="Lead Status"
              selectedOption={inputs.lead_status}
              setSelectedOption={(v) => {
                handleChangeOthers("lead_status", v);
              }}
              optionsList={leadStatusList}
              autoComplete="new-password"
              name="title"
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Consider Purchasing User As
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="consider_purchasing_user"
                value={inputs.consider_purchasing_user}
                label="Consider Purchasing User As"
                onChange={handleChange}
              >
                <MenuItem value={"free"}>Free Source Membership</MenuItem>
                <MenuItem value={"paid"}>Paid Source Membership</MenuItem>
              </Select>
            </FormControl>
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Payment Mode
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="payment_mode"
                value={inputs.payment_mode}
                label="Payment Mode"
                onChange={handleChange}
              >
                <MenuItem value="sandBox">SandBox</MenuItem>
                <MenuItem value="live">Live</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-12 mt-4">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Transaction Note"
                multiline
                rows={6}
                name="transaction_note"
                value={inputs.transaction_note}
                onChange={handleChange}
              />
            </FormControl>
          </div>
          <div className="text-end mt-4" id={request_slug ? "fixedbutton" : ""}>
            <button className="small-contained-button" disabled={isLoading}>
              {request_slug ? "Update" : "Submit"}
            </button>
          </div>
          <CustomConfirmation
            open={open}
            setOpen={setOpen}
            handleAgree={handleSubmit}
            description={
              "The payment request status is inactive; in this case, the member will not receive a notification. Are you sure you want to create the payment request this way?"
            }
          />
        </div>
      </div>
    </form>
  );
}
