import { useState } from "react";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import {
  _delete_membership_level_badge,
  _list_membership_level_badge,
} from "src/DAL/MembershipLevel/MembershipLevelBadges/MembershipLevelBadges";
import { dd_date_format, show_proper_words } from "src/utils/constant";
import { Tooltip } from "@mui/material";

const TABLE_HEAD = [
  { id: "number", label: "#", type: "number" },
  {
    id: "membership_level_badge_title",
    label: "Membership Level Badge Title",
    renderData: (row) => {
      return (
        <div className="d-flex" style={{ alignItems: "center" }}>
          <span>{row.membership_level_badge_title}</span>
          <Tooltip title={row?.membership_level_badge_title} arrow>
            <img
              className="avatar-adjust ms-2 me-4 mt-0"
              style={{
                width: 20,
                height: 20,
              }}
              alt={row?.membership_level_badge_title}
              src={s3baseUrl + row?.membership_level_badge_icon?.thumbnail_1}
            />
          </Tooltip>
        </div>
      );
    },
  },
  {
    id: "date",
    label: "Date",
    renderData: (row) => {
      return <p className="mb-0">{dd_date_format(row?.date)}</p>;
    },
  },
  {
    id: "action_by",
    label: "Action By",
    renderData: (row) => {
      return (
        <p className="mb-0">
          {show_proper_words(row?.action_info?.action_by)} ({" "}
          {row?.action_info?.action_by_name} ){" "}
        </p>
      );
    },
  },
  {
    id: "action_from",
    label: "Action From",
    renderData: (row) => {
      return (
        <p className="mb-0">
          {show_proper_words(row?.action_info?.action_from)} ({" "}
          {row?.action_info?.action_title} ){" "}
        </p>
      );
    },
  },
];

export default function ShowBadgeLevelHistory({ selectedRowData }) {
  const [membershipLevelBadgeList, setMembershipLevelBadgeList] = useState(
    selectedRowData?.badge_level_history ?? []
  );

  return (
    <div style={{ zIndex: "0" }}>
      <div className="container-fluid" style={{ zIndex: "0" }}>
        <div class="row ">
          <div class="col-12">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={membershipLevelBadgeList}
              className="card-with-background"
              pagePagination={false}
              is_hide={true}
              hide_footer_pagination={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
