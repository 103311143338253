import { invokeApi } from "../../bl_libs/invokeApi";

export const update_firebase_setting_api = async (data) => {
  const requestObj = {
    path: `/api/default_setting/update/firebase_settings`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const get_firebase_setting_api = async () => {
  const requestObj = {
    path: `/api/default_setting/get/firebase_settings`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
