import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {
  UpdatePopUpContentSetting,
  UpdatePopUpContentSettingOnly,
  UpdateShopContentSetting,
} from "src/DAL/ClientSetting/ClientSetting";
import { useEffect } from "react";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import {
  _get_attitude_accessment_setting,
  _get_mission_popup_settings,
  _get_store_house_setting,
  _get_upgrade_popup_settings,
} from "src/DAL/AttitudeAccessmentQuestions/AttitudeAccessmentSetting";
import { project_mode } from "src/config/config";
import { usePGIMode } from "src/Hooks/PGIModeContext";
import { isValidObject } from "src/utils/constant";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { _get_book_a_call_sale_pages } from "src/DAL/MissionApi/MissionApiNew";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Upgrade_popup = {
  is_show_popup: false,
  description: "",

  upgrade_button_text: "",
  upgrade_button_url: "",
  upgrade_for: "membership",
  show_after_times_portal_opens: 0,
  show_to_members: "all",
};
export default function UpgradePopupSettingsMain() {
  const classes = useStyles();
  const { badgeLevels } = usePGIMode();
  const { enqueueSnackbar } = useSnackbar();
  const { badge_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [missions, setMission] = useState([]);
  const [salePageList, setSalePageList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [badgeTitle, setBadgeTitle] = useState("");
  const [inputs, setInputs] = React.useState({
    mission_upgrade_popup_setting: Upgrade_popup,
    upgrade_popup_description: "",
  });

  const clientDetail = async () => {
    setIsLoading(true);
    const result = await _get_upgrade_popup_settings(badge_id);
    if (result.code == 200) {
      setMission(result.missions);
      setBadgeTitle(result?.membership_level_badge?.title);
      setInputs((prevValues) => ({
        ...prevValues,

        upgrade_popup_description:
          result?.membership_level_badge?.mission_upgrade_popup_setting
            ?.description,

        mission_upgrade_popup_setting: isValidObject(
          result?.membership_level_badge?.mission_upgrade_popup_setting
        )
          ? result?.membership_level_badge?.mission_upgrade_popup_setting
          : Upgrade_popup,
      }));
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getSalePageDetail = async () => {
    const result = await _get_book_a_call_sale_pages({
      search: searchText,
    });
    if (result.code === 200) {
      setSalePageList(result?.sale_pages);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);
    if (
      inputs?.mission_upgrade_popup_setting?.is_show_popup &&
      !inputs.upgrade_popup_description
    ) {
      enqueueSnackbar("upgrade popup description is required", {
        variant: "error",
      });
      return;
    }

    let postData = {
      settings: inputs?.mission_upgrade_popup_setting,
      type: "upgrade_popup_settings",
    };
    if (inputs?.mission_upgrade_popup_setting?.is_show_popup) {
      postData.settings.description = inputs?.upgrade_popup_description;
    }

    const result = await UpdatePopUpContentSettingOnly(postData, badge_id);
    if (result.code === 200) {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event, object) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((prevValues) => ({
      ...prevValues,
      mission_upgrade_popup_setting: {
        ...prevValues.mission_upgrade_popup_setting,
        [name]: value,
      },
    }));
  };
  const handleChangeMission = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  let breadCrumbMenu = [
    {
      title: `Badge Level (${badgeTitle})`,
      navigation: "/badge-level",
      active: false,
    },
    {
      title: "Upgrade Popup settings",
      active: true,
    },
  ];
  useEffect(() => {
    clientDetail();
    getSalePageDetail();
  }, []);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getSalePageDetail();
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [searchText]);
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin">
        <div className="col-12">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Is Show Popup
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_show_popup"
                value={inputs?.mission_upgrade_popup_setting?.is_show_popup}
                label="Is Show Popup"
                onChange={(e) =>
                  handleChange(e, "mission_upgrade_popup_setting")
                }
                required
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>

          {inputs?.mission_upgrade_popup_setting?.is_show_popup && (
            <>
              <div className="col-6 mt-4">
                <TextField
                  className="text-muted"
                  id="outlined-basic"
                  label="App/Portal Open Count for Popup"
                  variant="outlined"
                  fullWidth
                  type="number"
                  inputProps={{
                    min: 0,
                  }}
                  name="show_after_times_portal_opens"
                  onChange={(e) =>
                    handleChange(e, "mission_upgrade_popup_setting")
                  }
                  value={
                    inputs?.mission_upgrade_popup_setting
                      ?.show_after_times_portal_opens
                  }
                  required
                />
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">
                    Show To Members
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="show_to_members"
                    value={
                      inputs?.mission_upgrade_popup_setting?.show_to_members
                    }
                    label="Show To Members"
                    onChange={(e) =>
                      handleChange(e, "mission_upgrade_popup_setting")
                    }
                    required
                  >
                    <MenuItem value={"all"}>All Members</MenuItem>
                    <MenuItem value={"active"}>Membership Active</MenuItem>
                    <MenuItem value={"expired"}>Membership Expired</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-6 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">
                    Upgrade For
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="upgrade_for"
                    value={inputs?.mission_upgrade_popup_setting?.upgrade_for}
                    label="Upgrade For"
                    onChange={(e) =>
                      handleChange(e, "mission_upgrade_popup_setting")
                    }
                  >
                    <MenuItem value="membership">Membership</MenuItem>
                    <MenuItem value="sale_page">Sale Page</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-6 mt-3">
                <TextField
                  className="text-muted"
                  id="outlined-basic"
                  label="Upgrade Button Text"
                  variant="outlined"
                  fullWidth
                  name="upgrade_button_text"
                  onChange={(e) =>
                    handleChange(e, "mission_upgrade_popup_setting")
                  }
                  value={
                    inputs?.mission_upgrade_popup_setting?.upgrade_button_text
                  }
                  required
                />
              </div>
              <div className="col-6 mt-3">
                <TextField
                  className="text-muted"
                  id="outlined-basic"
                  label="Upgrade Button Url"
                  variant="outlined"
                  fullWidth
                  type="url"
                  name="upgrade_button_url"
                  onChange={(e) =>
                    handleChange(e, "mission_upgrade_popup_setting")
                  }
                  value={
                    inputs?.mission_upgrade_popup_setting?.upgrade_button_url
                  }
                  required
                />
              </div>
              <div className="col-12 mt-4">
                <h4 className="mb-3">Description *</h4>
                <GeneralCkeditor
                  setInputs={setInputs}
                  inputs={inputs}
                  name="upgrade_popup_description"
                  editorHeight={250}
                />
              </div>
            </>
          )}

          <div className="text-end mt-4" id="fixedbutton">
            <button className="small-contained-button">
              {isLoadingForm ? "Updating..." : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
