import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import {
  _delete_membership_level,
  _list_membership_level,
} from "src/DAL/MembershipLevel/MembershipLevel";
import WhatsappPopUpModel from "src/components/GeneralComponents/WhatsappPopUpModel";
import ViewMembershipLevelBadgesList from "./ViewMembershipLevelBadgesList";
import { usePGIMode } from "src/Hooks/PGIModeContext";
import PageLoader from "src/components/GeneralComponents/PageLoader";

export default function MembershipLevelList() {
  const navigate = useNavigate();
  const { userInfo } = usePGIMode();
  const [membershipLevelList, setMembershipLevelList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isBadgesShow, setIsBadgesShow] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [page, setPage] = useState(
    localStorage.getItem("membership_level_page")
      ? parseInt(localStorage.getItem("membership_level_page"), 10)
      : 0
  );

  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("membership_level_rowsPerPage")
      ? parseInt(localStorage.getItem("membership_level_rowsPerPage"))
      : 50
  );

  const [searchText, setSearchText] = useState(
    localStorage.getItem("membership_level_search_text")
      ? localStorage.getItem("membership_level_search_text")
      : ""
  );

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    {
      id: "table_avatar",
      label: "Icon",
      alignRight: false,
      type: "thumbnail",
    },
    { id: "title", label: "Title" },

    { id: "order", label: "Order" },

    { id: "type", label: "Type", className: "capitalaize" },
    {
      id: "badges",
      label: "Badges",
      renderData: (row) => {
        return (
          <div>
            {row?.type == "paid" && (
              <p
                className="mb-1 mt-1 view-more"
                aria-describedby={row._id}
                variant="contained"
                onClick={(e) => handleBadges(row)}
              >
                View
              </p>
            )}
          </div>
        );
      },
    },
    { id: "status", label: "Status", type: "row_status" },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleNavigate = () => {
    navigate(`/membership-level/add-membership-level`);
  };

  const handleEdit = (value) => {
    navigate(`/membership-level/edit-membership-level/${value._id}`, {
      state: value,
    });
  };

  const handleMoveToBadges = (value) => {
    navigate(`/membership-level/${value._id}/membership-level-badges`, {
      state: value,
    });
  };

  const handleBadges = (value) => {
    setIsBadgesShow(true);
    setSelectedRowData(value);
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await _delete_membership_level(deleteDoc?._id);
    if (result.code === 200) {
      let newDataList = membershipLevelList.filter(
        (data) => data._id !== deleteDoc._id
      );
      const data = newDataList.map((membership_level, index) => {
        return {
          ...membership_level,
          order: index + 1 + rowsPerPage * page,
        };
      });
      setMembershipLevelList(data);
      setTotalCount((totalCount) => totalCount - 1);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getMembershipLevelListing = async () => {
    setIsLoading(true);

    let postData = {
      search: searchText,
    };

    const result = await _list_membership_level(page, rowsPerPage, postData);
    if (result.code === 200) {
      const data = result.membership_levels.map((membership_level) => {
        return {
          ...membership_level,
          table_avatar: {
            src: s3baseUrl + membership_level.icon?.thumbnail_1,
            alt: membership_level.title,
          },
        };
      });
      setMembershipLevelList(data);
      setIsLoading(false);
      setTotalCount(result?.total_membership_levels_count);
      setTotalPages(result?.total_pages < 0 ? 0 : result?.total_pages);

      localStorage.setItem("membership_level_page", page);
      localStorage.setItem("membership_level_rowsPerPage", rowsPerPage);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const searchFunction = (e) => {
    e.preventDefault();
    setPage(0);
    setPageCount(1);
    getMembershipLevelListing();
    localStorage.setItem("membership_level_search_text", searchText);
    if (searchText.length < 1) {
      localStorage.setItem("membership_level_search_text", "");
    }
  };

  const handleMenu = (row) => {
    const MENU_OPTIONS = [];

    // if (row?.type == "paid") {
    //   MENU_OPTIONS.push({
    //     label: "Manage Badge Levels",
    //     icon: "cil:badge",
    //     handleClick: handleMoveToBadges,
    //   });
    // }

    MENU_OPTIONS.push({
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    });

    if (userInfo.role == "super_admin") {
      MENU_OPTIONS.push({
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: handleAgreeDelete,
        color: "#da3030",
      });
    }
    return MENU_OPTIONS;
  };

  useEffect(() => {
    getMembershipLevelListing();
  }, [rowsPerPage, page]);

  if (isLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <CustomConfirmation
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container-fluid">
        <div className="row mb-3 mt-2">
          <div className="col-lg-4 col-sm-12">
            <h2>Membership Level</h2>
          </div>
          <div className="col-lg-8 col-sm-12 text-end d-flex justify-content-end">
            <div>
              {userInfo.role == "super_admin" && (
                <button
                  onClick={handleNavigate}
                  className="small-contained-button"
                >
                  Add Membership Level
                </button>
              )}
            </div>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={membershipLevelList}
          // MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
          handle_menus={handleMenu}
        />

        <WhatsappPopUpModel
          open={isBadgesShow}
          setOpen={setIsBadgesShow}
          title={"Badge Levels"}
          show_date_and_income={true}
          componentToPassDown={
            <>
              <ViewMembershipLevelBadgesList
                selectedRowData={selectedRowData}
              />
            </>
          }
        />
      </div>
    </>
  );
}
