import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton, CircularProgress, Autocomplete } from "@mui/material";
import { useEffect } from "react";
import {
  CommissionConfigurationPaymentRequestApi,
  get_list_list_without_team,
} from "src/DAL/PaymentRequest/paymentRequestApi";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const recurring_fields = {
  public_user_commission_amount_for_first_transaction: 0,
  public_user_commission_amount_for_next_transaction: 0,
  associate_user_commission_amount_for_first_transaction: 0,
  associate_user_commission_amount_for_next_transaction: 0,
  delegate_user_commission_amount_for_first_transaction: 0,
  delegate_user_commission_amount_for_next_transaction: 0,
  dynamite_digital_user_commission_amount_for_first_transaction: 0,
  dynamite_digital_user_commission_amount_for_next_transaction: 0,
  main_refferal_commission_amount_for_first_transaction: 0,
  main_refferal_commission_amount_for_next_transaction: 0,
  inner_circle_commission_amount_for_first_transaction: 0,
  inner_circle_commission_amount_for_next_transaction: 0,
  marketing_team_commission_amount_for_first_transaction: 0,
  marketing_team_commission_amount_for_next_transaction: 0,
  referral_user: "",
};

const onetime_fields = {
  main_refferal_commission: 0,
  public_user_commission_amount: 0,
  associate_user_commission_amount: 0,
  delegate_user_commission_amount: 0,
  dynamite_digital_user_commission_amount: 0,
  referral_user: "",
  inner_circle_commission_amount: 0,
  marketing_team_commission_amount: 0,
};
export default function PaymentRequestCommissionConfiguration() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { state } = useLocation();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [serchText, setSerchText] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [requestType, setrequestType] = useState("onetime");
  const [inputs, setInputs] = useState(recurring_fields);
  const [requestObject, setrRequestObject] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let data = { commission_info: inputs };
    if (requestType == "onetime") {
      if (selectedUser) {
        data.commission_info.referral_user = selectedUser._id;
      } else {
        delete data.commission_info.main_refferal_commission;
        delete data.commission_info.referral_user;
      }
    } else if (requestType === "recurring") {
      if (selectedUser) {
        data.commission_info.referral_user = selectedUser._id;
      } else {
        delete data.commission_info
          .main_refferal_commission_amount_for_first_transaction;
        delete data.commission_info
          .main_refferal_commission_amount_for_next_transaction;
        delete data.commission_info.referral_user;
      }
    }

    let postData = data;
    const result = await CommissionConfigurationPaymentRequestApi(
      postData,
      state.payment_request_slug
    );

    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getUserList = async () => {
    const result = await get_list_list_without_team(serchText);
    if (result.code === 200) {
      setUserList(result.consultant_list);
      if (state.commission_info.referral_user) {
        const findeduser = result.consultant_list.find(
          (item) => item._id === state.commission_info.referral_user
        );
        setSelectedUser(findeduser);
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleSearch = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSerchText(value);
  };
  const handleChangeCommission = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleFormat = (data) => {
    setrequestType(data.request_type);
    setrRequestObject(data);

    if (data.commission_info) {
      setInputs(data.commission_info);
    } else if (data.request_type == "onetime") {
      setInputs(onetime_fields);
    } else {
      setInputs(recurring_fields);
    }
  };

  useEffect(() => {
    getUserList();
  }, [serchText.length > 3]);

  React.useEffect(() => {
    getUserList();
  }, [serchText.length == 0]);

  useEffect(() => {
    if (state) {
      handleFormat(state);
    } else {
    }
  }, []);

  let breadCrumbMenu = [
    {
      title: "Payment Request",
      navigation: `/payment-request`,
      active: false,
    },
    {
      title: state?.member_name,
      active: true,
    },
  ];

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
          <div className="col-12 mt-3">
            <h2>Commission Configuraion</h2>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="row">
            {requestType === "recurring" ? (
              <>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Inner Circle User Commission Amount for first recursion"
                    variant="outlined"
                    fullWidth
                    type="number"
                    name="inner_circle_commission_amount_for_first_transaction"
                    value={
                      inputs.inner_circle_commission_amount_for_first_transaction
                    }
                    onChange={handleChangeCommission}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Inner Circle User Commission Amount for next recursion"
                    variant="outlined"
                    fullWidth
                    type="number"
                    name="inner_circle_commission_amount_for_next_transaction"
                    value={
                      inputs.inner_circle_commission_amount_for_next_transaction
                    }
                    onChange={handleChangeCommission}
                  />
                </div>{" "}
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Consultant User Commission Amount for first recursion"
                    variant="outlined"
                    fullWidth
                    type="number"
                    name="associate_user_commission_amount_for_first_transaction"
                    value={
                      inputs.associate_user_commission_amount_for_first_transaction
                    }
                    onChange={handleChangeCommission}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Consultant User Commission Amount for next recursion"
                    variant="outlined"
                    fullWidth
                    type="number"
                    name="associate_user_commission_amount_for_next_transaction"
                    value={
                      inputs.associate_user_commission_amount_for_next_transaction
                    }
                    onChange={handleChangeCommission}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Delegate User Commission Amount for first recursion"
                    variant="outlined"
                    fullWidth
                    type="number"
                    name="delegate_user_commission_amount_for_first_transaction"
                    value={
                      inputs.delegate_user_commission_amount_for_first_transaction
                    }
                    onChange={handleChangeCommission}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Delegate User Commission Amount for next recursion"
                    variant="outlined"
                    fullWidth
                    type="number"
                    name="delegate_user_commission_amount_for_next_transaction"
                    value={
                      inputs.delegate_user_commission_amount_for_next_transaction
                    }
                    onChange={handleChangeCommission}
                  />
                </div>{" "}
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Marketing Team Commission Amount for first recursion"
                    variant="outlined"
                    fullWidth
                    type="number"
                    name="marketing_team_commission_amount_for_first_transaction"
                    value={
                      inputs.marketing_team_commission_amount_for_first_transaction
                    }
                    onChange={handleChangeCommission}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Marketing Team  Commission Amount for next recursion"
                    variant="outlined"
                    fullWidth
                    type="number"
                    name="marketing_team_commission_amount_for_next_transaction"
                    value={
                      inputs.marketing_team_commission_amount_for_next_transaction
                    }
                    onChange={handleChangeCommission}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Team Diego User Commission Amount for first recursion"
                    variant="outlined"
                    fullWidth
                    type="number"
                    name="dynamite_digital_user_commission_amount_for_first_transaction"
                    value={
                      inputs.dynamite_digital_user_commission_amount_for_first_transaction
                    }
                    onChange={handleChangeCommission}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Team Diego User Commission Amount for next recursion"
                    variant="outlined"
                    fullWidth
                    type="number"
                    name="dynamite_digital_user_commission_amount_for_next_transaction"
                    value={
                      inputs.dynamite_digital_user_commission_amount_for_next_transaction
                    }
                    onChange={handleChangeCommission}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Public User Commission Amount for first recursion"
                    variant="outlined"
                    fullWidth
                    type="number"
                    name="public_user_commission_amount_for_first_transaction"
                    value={
                      inputs.public_user_commission_amount_for_first_transaction
                    }
                    onChange={handleChangeCommission}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Public User Commission Amount for next recursion"
                    variant="outlined"
                    fullWidth
                    type="number"
                    name="public_user_commission_amount_for_next_transaction"
                    value={
                      inputs.public_user_commission_amount_for_next_transaction
                    }
                    onChange={handleChangeCommission}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <Autocomplete
                    loading
                    id="tags-outlined"
                    options={userList}
                    getOptionLabel={(option) =>
                      option.first_name +
                      " " +
                      option.last_name +
                      " (" +
                      option.email +
                      ")"
                    }
                    filterSelectedOptions
                    value={selectedUser}
                    onChange={(event, newValue) => {
                      setSelectedUser(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`Referral User`}
                        placeholder={`Referral User`}
                        onChange={handleSearch}
                      />
                    )}
                  />
                </div>
                {selectedUser !== null ? (
                  <>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                      <TextField
                        id="outlined-basic"
                        label="Referral Commission Amount for first recursion"
                        variant="outlined"
                        fullWidth
                        type="number"
                        name="main_refferal_commission_amount_for_first_transaction"
                        value={
                          inputs.main_refferal_commission_amount_for_first_transaction
                        }
                        onChange={handleChangeCommission}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                      <TextField
                        id="outlined-basic"
                        label="Referral  Commission Amount for next recursion"
                        variant="outlined"
                        fullWidth
                        type="number"
                        name="main_refferal_commission_amount_for_next_transaction"
                        value={
                          inputs.main_refferal_commission_amount_for_next_transaction
                        }
                        onChange={handleChangeCommission}
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Inner Circle User Commission Amount"
                    variant="outlined"
                    fullWidth
                    type="number"
                    name="inner_circle_commission_amount"
                    value={inputs.inner_circle_commission_amount}
                    onChange={handleChangeCommission}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Consultant User Commission Amount"
                    variant="outlined"
                    fullWidth
                    type="number"
                    name="associate_user_commission_amount"
                    value={inputs.associate_user_commission_amount}
                    onChange={handleChangeCommission}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Delegate User Commission Amount"
                    variant="outlined"
                    fullWidth
                    type="number"
                    name="delegate_user_commission_amount"
                    value={inputs.delegate_user_commission_amount}
                    onChange={handleChangeCommission}
                  />
                </div>{" "}
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Marketing Team  Commission Amount"
                    variant="outlined"
                    fullWidth
                    type="number"
                    name="marketing_team_commission_amount"
                    value={inputs.marketing_team_commission_amount}
                    onChange={handleChangeCommission}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Team Diego User Commission Amount"
                    variant="outlined"
                    fullWidth
                    type="number"
                    name="dynamite_digital_user_commission_amount"
                    value={inputs.dynamite_digital_user_commission_amount}
                    onChange={handleChangeCommission}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Public User Commission Amount"
                    variant="outlined"
                    fullWidth
                    type="number"
                    name="public_user_commission_amount"
                    value={inputs.public_user_commission_amount}
                    onChange={handleChangeCommission}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <Autocomplete
                    loading
                    id="tags-outlined"
                    options={userList}
                    getOptionLabel={(option) =>
                      option.first_name +
                      " " +
                      option.last_name +
                      " (" +
                      option.email +
                      ")"
                    }
                    filterSelectedOptions
                    value={selectedUser}
                    onChange={(event, newValue) => {
                      setSelectedUser(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`Referral User`}
                        placeholder={`Referral User`}
                        onChange={handleSearch}
                      />
                    )}
                  />
                </div>
                {selectedUser !== null ? (
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Referral Commission"
                      variant="outlined"
                      fullWidth
                      type="number"
                      name="main_refferal_commission"
                      value={inputs.main_refferal_commission}
                      onChange={handleChangeCommission}
                    />
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
          <div className="text-end mt-4" id="fixedbutton">
            <button className="small-contained-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
}
