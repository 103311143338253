import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { s3baseUrl } from "src/config/config";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  allMeditationsListing,
  DeleteMeditationCategoryApi,
} from "src/DAL/meditations/meditationsApi";
import PageLoader from "src/components/GeneralComponents/PageLoader";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false, type: "action" },
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "title", label: "Title", alignRight: false },
  { id: "table_avatar", label: "Image", alignRight: false, type: "thumbnail" },
  { id: "order", label: "Order", alignRight: false },
  {
    id: "category_status",
    label: "Status",
    type: "row_status",
  },
];

export default function MeditationCategories() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [categoriesData, setCategoriesData] = useState([]);

  const getCategoriesListing = async () => {
    const result = await allMeditationsListing();
    if (result.code == 200) {
      const categories = result.meditation_category.map((category) => {
        return {
          ...category,
          table_avatar: {
            src: s3baseUrl + category.image.thumbnail_2,
            alt: category.title,
          },
          category_status: category.status,
        };
      });
      setCategoriesData(categories);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await DeleteMeditationCategoryApi(
      deleteDoc.meditation_category_slug
    );
    if (result.code === 200) {
      setCategoriesData((categoriesData) => {
        return categoriesData.filter((data) => data._id !== deleteDoc._id);
      });
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleEdit = (value) => {
    navigate(
      `/meditations-category/edit-category/${value.meditation_category_slug}`,
      {
        state: value,
      }
    );
  };

  const handleNavigate = () => {
    navigate(`/meditations-category/add-category`);
  };

  useEffect(() => {
    getCategoriesListing();
  }, []);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  if (isLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this category?"}
        handleAgree={handleDelete}
      />

      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-lg-6 col-sm-12">
            <h2>Meditation Category</h2>
          </div>
          <div className="col-lg-6 col-sm-12 text-end ">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Meditation Category
            </button>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={categoriesData}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          pagePagination={true}
        />
      </div>
    </>
  );
}
