import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useEffect } from "react";
import {
  options_plan_api,
  update_options_for_plan_api,
} from "src/DAL/WebsitePages/paymentPlan";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { usePGIMode } from "src/Hooks/PGIModeContext";
import GeneralIterativeCkeditor from "src/components/GeneralComponents/GeneralIterativeCkeditor";
import { convertCurrencyToSign } from "src/utils/constant";
import PageLoader from "src/components/GeneralComponents/PageLoader";

export default function ManagePlanOptions() {
  const params = useParams();
  const { badgeLevels } = usePGIMode();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [planDetail, setPlanDetail] = useState({});
  const [inputList, setInputList] = useState([
    {
      option_title: "",
      badge_levels: [],
      coins_to_deduct: 0,
      use_with_coins: false,
      discount_percentage: 0,
      description: "",
      discount_type: "percentage",
      discount_fix_amount: "",
      membership_status: "all",
    },
  ]);

  const handleAddClick = (index) => {
    setInputList([
      ...inputList.slice(0, index),
      {
        option_title: "",
        badge_levels: [],
        coins_to_deduct: 0,
        use_with_coins: false,
        discount_percentage: 0,
        description: "",
        discount_type: "percentage",
        discount_fix_amount: "",
        membership_status: "all",
      },
      ...inputList.slice(index),
    ]);
  };
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    if (name == "content_type") {
      const list = [...inputList];
      list[index]["content"] = "";
      setInputList(list);
    }
  };
  const handleChangeMembers = (name, member, index) => {
    console.log(member, name, index, "member");
    const list = [...inputList];
    list[index][name] = member;
    setInputList(list);
  };

  const getProgramsList = async () => {
    const result = await options_plan_api(params.plan_id);
    if (result.code == 200) {
      setPlanDetail(result.payment_plan);
      if (
        result.payment_plan &&
        result?.payment_plan?.plan_options?.length > 0
      ) {
        setInputList(result.payment_plan.plan_options);
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  function validateOptions(options) {
    const find_empty_description = options.find(
      (option) => !option.description
    );
    if (find_empty_description && find_empty_description.option_title) {
      enqueueSnackbar(
        `${find_empty_description.option_title}'s description is required`,
        {
          variant: "error",
        }
      );
      setIsLoadingForm(false);
      return true;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);
    const is_validate = validateOptions(inputList);
    if (is_validate) {
      return;
    }
    let postData = {
      plan_options: inputList,
    };

    const result = await update_options_for_plan_api(params.plan_id, postData);
    if (result.code == 200) {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getProgramsList();
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  const websitePages = window.location.pathname.includes("/website-pages");

  let breadCrumbMenu = [
    {
      title: "Payment Plans",
      navigation: `${
        !params.page_id
          ? "/payment-plans"
          : websitePages
          ? `/website-pages/${params?.page_id}/payment-plans`
          : `/template-pages/${params?.page_id}/payment-plans`
      }`,
      active: false,
    },
    {
      title: planDetail?.plan_title,
      active: true,
    },
  ];

  if (params?.page_id) {
    breadCrumbMenu.unshift({
      title: websitePages ? "Pages" : "Template Pages",
      navigation: `${websitePages ? `/website-pages` : `/template-pages`}`,
      active: false,
    });
  }

  console.log(planDetail, "inputListinputList");

  return (
    <div className="container-fluid">
      <div className="row mb-4">
        <div className="col-12 mb-3">
          <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
        </div>
        <div className="col-6">
          <h2>Manage Plan Options</h2>
        </div>
      </div>

      <form className="ms-auto" onSubmit={handleSubmit}>
        <div className="Container mt-3">
          {inputList.map((item, index) => {
            return (
              <div className="setting-card mb-4 ">
                <div className="row  ">
                  <div className="col-11">
                    <div className="row">
                      <div className="col-6">
                        <TextField
                          id="outlined-basic"
                          label="Option Title"
                          variant="outlined"
                          fullWidth
                          required
                          type="text"
                          name="option_title"
                          value={item.option_title}
                          onChange={(e) => handleInputChange(e, index)}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12   ">
                        <MUIAutocomplete
                          inputLabel="Select Badge Levels"
                          limitTags={3}
                          selectedOption={item.badge_levels ?? []}
                          setSelectedOption={(member) =>
                            handleChangeMembers("badge_levels", member, index)
                          }
                          optionsList={badgeLevels}
                          multiple
                          required={true}
                          name="title"
                        />
                      </div>
                      <div className="col-6 mt-3">
                        <FormControl fullWidth required>
                          <InputLabel id="demo-simple-select-label">
                            Use With Coins
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="use_with_coins"
                            value={item.use_with_coins}
                            label="Use With Coins"
                            onChange={(e) => handleInputChange(e, index)}
                          >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </div>{" "}
                      {item.use_with_coins && (
                        <div className="col-6 mt-3">
                          <TextField
                            id="outlined-basic"
                            label="Coins"
                            variant="outlined"
                            fullWidth
                            required
                            type="number"
                            inputProps={{
                              min: 0,
                            }}
                            name="coins_to_deduct"
                            value={item.coins_to_deduct}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                        </div>
                      )}
                      <div className="col-6 mt-3">
                        <FormControl fullWidth required>
                          <InputLabel id="demo-simple-select-label">
                            Show plan options to
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="membership_status"
                            value={item.membership_status}
                            label="Show plan options to"
                            onChange={(e) => handleInputChange(e, index)}
                          >
                            <MenuItem value={"all"}>All Members</MenuItem>
                            <MenuItem value={"active"}>
                              Members With Active Membership
                            </MenuItem>
                            <MenuItem value={"expired"}>
                              Members With Expired Membership
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-6 mt-3">
                        <FormControl fullWidth required>
                          <InputLabel id="demo-simple-select-label">
                            Discount Type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="discount_type"
                            value={item.discount_type}
                            label="Discount Type"
                            onChange={(e) => handleInputChange(e, index)}
                          >
                            <MenuItem value={"percentage"}>Percentage</MenuItem>
                            <MenuItem value={"fixed"}>Fixed</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      {item.discount_type == "percentage" ? (
                        <div className="col-6 mt-3">
                          <TextField
                            id="outlined-basic"
                            label="Discount Percentage"
                            variant="outlined"
                            fullWidth
                            required
                            type="number"
                            inputProps={{
                              min: 0,
                              max: 100,
                            }}
                            name="discount_percentage"
                            value={item.discount_percentage}
                            onChange={(e) => handleInputChange(e, index)}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      ) : (
                        <div className="col-6 mt-3">
                          <TextField
                            id="outlined-basic"
                            label={`Discount Amount (not greater then ${convertCurrencyToSign(
                              planDetail.plan_currency
                            )}${planDetail?.plan_price}) `}
                            variant="outlined"
                            fullWidth
                            required
                            type="number"
                            inputProps={{
                              min: 1,
                              max: planDetail?.plan_price,
                            }}
                            name="discount_fix_amount"
                            value={item.discount_fix_amount}
                            onChange={(e) => handleInputChange(e, index)}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {convertCurrencyToSign(
                                    planDetail.plan_currency
                                  )}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      )}
                      <div className="col-12 mt-4">
                        <h4>Description *</h4>
                        <GeneralIterativeCkeditor
                          setInputs={setInputList}
                          inputs={inputList}
                          required
                          name="description"
                          index={index}
                          editorHeight={300}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-1">
                    <AddCircleOutlineIcon
                      className="question-survey-update"
                      onClick={() => handleAddClick(index + 1)}
                    />
                    {inputList.length !== 1 && (
                      <RemoveCircleOutlineIcon
                        className="question-survey-cross-update"
                        onClick={() => handleRemoveClick(index)}
                      />
                    )}
                  </div>
                </div>
              </div>
            );
          })}
          <div className="text-end">
            <button className="small-contained-button mt-3 ml-auto">
              {isLoadingForm ? "Updating..." : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
