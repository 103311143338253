import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  road_Delete_Api,
  _bonus_listApi,
} from "src/DAL/BonusRoadMAp/BonusRoadMApApi";
import { s3baseUrl } from "src/config/config";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import SalesTeam from "./SalesTeam";

const SalesTeamMain = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteValue, setDeleteValue] = useState("");
  const [levelsList, setLevelsList] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  //Changing tab values
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    sessionStorage.setItem("tabsale", newValue);
  };

  const TABS_OPTIONS = [
    {
      title: "Pending",
      tab_slug: "expire",

      component: (
        <SalesTeam user_type="member" heading="" is_approved={false} />
      ),
    },
    {
      title: "Approved",
      tab_slug: "pending",

      component: <SalesTeam user_type="member" heading="" is_approved={true} />,
    },
  ];
  useEffect(() => {
    let tabValue = sessionStorage.getItem("tabsale");
    if (tabValue == "1") {
      setTabValue(1);
    } else {
      setTabValue(0);
    }
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col-lg-8 col-sm-12">
            <h2>Sub Team</h2>
          </div>
        </div>

        <MUICustomTabs
          data={TABS_OPTIONS}
          value={tabValue}
          handleChange={handleChange}
        />
      </div>
    </>
  );
};

export default SalesTeamMain;
