import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton } from "@mui/material";
import { useEffect } from "react";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import {
  mission_detail_api,
  update_questiion_config_api,
} from "src/DAL/MissionApi/MissionApiNew";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import PageLoader from "src/components/GeneralComponents/PageLoader";

export default function QuestionSettingForMissions() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [missionDetails, setMissionDetails] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const [inputs, setInputs] = useState({
    show_question: false,
    button_text: "",
    submit_button_text: "",
    questions_top_description: "",
    thanks_description: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.show_question && !inputs.thanks_description) {
      enqueueSnackbar("Thank You Page Description is required", {
        variant: "error",
      });
      return;
    }
    setIsLoadingForm(true);
    let question_configration = {
      question_configration: inputs,
    };

    const result = await update_questiion_config_api(
      question_configration,
      params.mission_id
    );
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleDetail = async () => {
    let result = await mission_detail_api(params.mission_id);
    if (result.code === 200) {
      if (result?.mission?.question_configration) {
        setInputs(result.mission.question_configration);
      }
      setMissionDetails(result?.mission?.title);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  let breadCrumbMenu = [
    {
      title: `Mission (${missionDetails})`,
      navigation: `/mission`,
      active: false,
    },
    {
      title: "Question Settings",
      navigation: ``,
      active: true,
    },
  ];

  useEffect(() => {
    if (
      state &&
      state?.question_configration &&
      Object.keys(state?.question_configration).length > 0
    ) {
      setMissionDetails(state?.title);
      setInputs(state.question_configration);
      setIsLoading(false);
    } else {
      handleDetail();
    }
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12 mb-4 ms-2">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
        {/* <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-3"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2 className="ms-2">Questions Configuration</h2>
        </div> */}
      </div>
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Show Questions
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="show_question"
              value={inputs.show_question}
              label="Show Questions"
              onChange={handleChange}
              required
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
        </div>
        {inputs.show_question == true && (
          <>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Button Text"
                variant="outlined"
                fullWidth
                name="button_text"
                value={inputs.button_text}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Submit Button Text"
                variant="outlined"
                fullWidth
                name="submit_button_text"
                value={inputs.submit_button_text}
                onChange={handleChange}
              />
            </div>
          </>
        )}
        <div className="col-12 mt-4">
          <h4>Questions Top Description</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="questions_top_description"
            editorHeight={320}
          />
        </div>
        <div className="col-12 mt-4">
          <h4>Thanks Description *</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="thanks_description"
            editorHeight={320}
          />
        </div>
        <div className="text-end mt-4" id="fixedbutton">
          <button className="small-contained-button">
            {isLoadingForm ? "Updating..." : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
