import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton, Stack } from "@mui/material";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { useEffect } from "react";
import { urlPatternValidation } from "src/utils/constant";
import { LocalizationProvider, TimePicker } from "@mui/lab";
import { s3baseUrl } from "src/config/config";
import {
  add_lesson_api,
  delete_lesson_data_api,
  lesson_detail_api,
  update_lesson_api,
} from "src/DAL/Programme/Lessons/Lessons";
import moment from "moment";
import { program_module_listing_api } from "src/DAL/Programme/Lessons/ProgramModule";
import { useTheme } from "@mui/material/styles";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const Input = styled("input")({
  display: "none",
});

export default function AddOrUpdateLesson() {
  const navigate = useNavigate();
  const { programme_slug, lesson_slug } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [audioFile, setAudio] = useState();
  const [oldAudio, setOldAudio] = useState("");
  const [formType, setFormType] = useState("ADD");
  const [programModule, setProgramModule] = useState([]);
  const [lessonDetail, setLessonDetails] = useState({});

  const [images, setImages] = useState({
    main_image: "",
    lock_icon: "",
  });

  const [oldImages, setOldImages] = useState({
    main_image: "",
    lock_icon: "",
  });

  const [inputs, setInputs] = useState({
    title: "",
    lesson_duration: new Date("00"),
    status: true,
    locked: true,
    is_chat_enable: false,
    main_image: {},
    landing_lesson_video_url: "",
    short_description: "",
    detailed_description: "",
    lock_statement: "",
    program_module: null,
    order: 0,
    access_after_days: 0,
    end_access_after_days: 0,
  });
  const handleDetail = async () => {
    setIsLoading(true);
    let result = await lesson_detail_api(lesson_slug);
    if (result.code === 200) {
      handleFormat(result.lesson);
      setLessonDetails(result.lesson);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const getModulesList = async () => {
    let result = await program_module_listing_api(programme_slug);
    if (result.code == 200) {
      if (state?.program_module) {
        const foundObject = result?.active_program_module.find(
          (obj) => obj?._id === state?.program_module?._id
        );

        if (!foundObject) {
          result?.active_program_module.push(state?.program_module);
        }
      }
      setProgramModule(result?.active_program_module);
      setIsLoading(false);
    }
  };

  const fileChangedHandler = (e) => {
    const { name, files } = e.target;
    setImages({
      ...images,
      [name]: URL.createObjectURL(files[0]),
    });

    setInputs({
      ...inputs,
      [name]: files[0],
    });
  };

  const audioFileChange = (e) => {
    setAudio(e.target.files[0]);
  };

  const handldeDeleteAudio = async () => {
    setIsLoading(true);
    let result = await delete_lesson_data_api(lesson_slug);
    if (result.code == 200) {
      setOldAudio();
      setAudio();
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      inputs.landing_lesson_video_url &&
      urlPatternValidation(inputs.landing_lesson_video_url) === false
    ) {
      enqueueSnackbar("Enter Valid Video URL", { variant: "error" });
    } else {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("title", inputs.title);
      formData.append("short_description", inputs.short_description);
      formData.append("detailed_description", inputs.detailed_description);
      formData.append("status", inputs.status);
      formData.append("is_chat_enable", inputs.is_chat_enable);
      formData.append(
        "landing_lesson_video_url",
        inputs.landing_lesson_video_url
      );
      formData.append("access_after_days", inputs.access_after_days);
      formData.append("end_access_after_days", inputs.end_access_after_days);

      formData.append("locked", inputs.locked);

      formData.append(
        "lock_statement",
        inputs.lock_statement ? inputs.lock_statement : ""
      );
      formData.append(
        "lesson_duration",
        moment(inputs.lesson_duration).format("HH:mm:ss")
      );
      if (inputs.program_module) {
        formData.append("program_module", inputs.program_module._id);
      }
      if (formType == "EDIT") {
        formData.append("order", inputs.order);
      } else {
        formData.append("program_slug", programme_slug);
      }

      if (audioFile) {
        formData.append("audio_file", audioFile);
      }
      if (images.main_image) {
        formData.append("main_image", inputs.main_image);
      }
      if (images.lock_icon) {
        formData.append("lock_icon", inputs.lock_icon);
      }
      if (+inputs.access_after_days > +inputs.end_access_after_days) {
        enqueueSnackbar(
          "No of Acces Days Intervel Start cannot be greater than No of Access Days Intervel End",
          { variant: "error" }
        );
        setIsLoading(false);
        return;
      }
      const result =
        formType == "ADD"
          ? await add_lesson_api(formData)
          : await update_lesson_api(formData, lesson_slug);
      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        handleNavigate();
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeProgram = (value) => {
    setInputs((values) => ({ ...values, program_module: value }));
  };

  const handleFormat = (data) => {
    let lesson_duration = "Wed Apr 27 2022 " + " " + data.lesson_duration;
    setInputs({ ...data, lesson_duration });
    setFormType("EDIT");
    setOldImages({
      ...oldImages,
      ["main_image"]: data.lesson_images.thumbnail_2,
      ["lock_icon"]: data.lock_icon,
    });
    setInputs((inputs) => ({
      ...inputs,
      ["program_module"]: data?.program_module,
    }));
    setOldAudio(data.audio_file);
    setIsLoading(false);
  };

  const handleNavigate = () => {
    if (formType == "ADD") {
      navigate(`/lessons/${programme_slug}`);
    } else {
      navigate(`/lessons/lesson-detail/${lesson_slug}`);
    }
  };

  useEffect(() => {
    getModulesList();
    if (lesson_slug) {
      if (state) {
        handleFormat(state);
      } else {
        handleDetail();
      }
    }
  }, []);

  if (isLoading === true) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={handleNavigate}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2>{`${formType == "ADD" ? "Add" : "Edit"} Lesson`}</h2>
        </div>
      </div>
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Title"
            variant="outlined"
            fullWidth
            name="title"
            value={inputs.title}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
              <TimePicker
                ampm={false}
                openTo="hours"
                views={["hours", "minutes", "seconds"]}
                inputFormat="HH:mm:ss"
                mask="__:__:__"
                label="Duration"
                value={inputs.lesson_duration}
                onChange={(newValue) => {
                  setInputs((prevState) => ({
                    ...prevState,
                    ["lesson_duration"]: newValue,
                  }));
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>
          </LocalizationProvider>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Video Url"
            variant="outlined"
            fullWidth
            name="landing_lesson_video_url"
            value={inputs.landing_lesson_video_url}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <MUIAutocomplete
            inputLabel="Programme Module"
            selectedOption={inputs.program_module}
            setSelectedOption={handleChangeProgram}
            optionsList={programModule}
            name="title"
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="status"
              value={inputs.status}
              label="Status"
              onChange={handleChange}
              required
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Is Chat Enable
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="is_chat_enable"
              value={inputs.is_chat_enable}
              label="Is Chat Enable "
              onChange={handleChange}
              required
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
        </div>
        {formType == "EDIT" && (
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Order"
              variant="outlined"
              fullWidth
              required
              name="order"
              value={inputs.order}
              onChange={handleChange}
            />
          </div>
        )}
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">Is Locked </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="locked"
              value={inputs.locked}
              label="Is Locked"
              onChange={handleChange}
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Lock Statement Description"
            variant="outlined"
            fullWidth
            name="lock_statement"
            value={inputs.lock_statement}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Access Days Interval Start"
            variant="outlined"
            fullWidth
            type="number"
            name="access_after_days"
            value={inputs.access_after_days}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Access Days Interval End"
            variant="outlined"
            fullWidth
            type="number"
            name="end_access_after_days"
            value={inputs.end_access_after_days}
            onChange={handleChange}
            required
          />
        </div>

        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Upload Audio</p>
              <FormHelperText className="pt-0">
                Audio mp3 (max 200mb)
              </FormHelperText>
            </div>
            <div className="col-2">
              {oldAudio && (
                <DeleteIcon
                  onClick={handldeDeleteAudio}
                  className="mt-3 icon-color"
                />
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="audio">
                <Input
                  accept="audio/mp3,audio/*;capture=microphone"
                  id="audio"
                  multiple
                  name="audio"
                  type="file"
                  onChange={audioFileChange}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          <p className="text-secondary">{audioFile && audioFile.name}</p>
          {oldAudio && (
            <audio className="w-100" src={s3baseUrl + oldAudio} controls />
          )}
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Upload Image *</p>
              <FormHelperText className="pt-0">
                Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-2">
              {(images.main_image || oldImages.main_image) && (
                <img
                  src={
                    images.main_image
                      ? images.main_image
                      : s3baseUrl + oldImages.main_image
                  }
                  height="50"
                />
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  name="main_image"
                  onChange={fileChangedHandler}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          {inputs?.image?.name == "" ? (
            ""
          ) : (
            <p className="text-secondary">{inputs?.image?.name}</p>
          )}
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Upload Lock Icon </p>
              <FormHelperText className="pt-0">
                Icon Size(32 X 32) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-2">
              {(images.lock_icon || oldImages.lock_icon) && (
                <img
                  src={
                    images.lock_icon
                      ? images.lock_icon
                      : s3baseUrl + oldImages.lock_icon
                  }
                  height="50"
                />
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file1">
                <Input
                  accept="image/*"
                  id="contained-button-file1"
                  multiple
                  type="file"
                  name="lock_icon"
                  onChange={fileChangedHandler}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          {inputs?.lock_icon?.name == "" ? (
            ""
          ) : (
            <p className="text-secondary">{inputs?.lock_icon?.name}</p>
          )}
        </div>
        <div className="col-12 mt-4">
          <FormControl fullWidth>
            <TextField
              id="outlined-multiline-flexible"
              label="Short Description"
              multiline
              rows={6}
              required
              name="short_description"
              value={inputs.short_description}
              onChange={handleChange}
            />
            <FormHelperText>Maximum limit 500 characters</FormHelperText>
          </FormControl>
        </div>
        <div className="col-12 mt-4">
          <h4>Detailed Description *</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="detailed_description"
            editorHeight={320}
          />
        </div>
        <div
          className="text-end mt-4"
          id={formType == "ADD" ? "" : "fixedbutton"}
        >
          <button className="small-contained-button">
            {formType == "ADD" ? "Submit" : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
