import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { show_proper_words } from "src/utils/constant";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const NotificationContent = ({ type, data }) => {
  const [content, setContent] = useState("");

  const convertMarkdownToHtml = (markdown) => {
    if (!markdown) return "";
    markdown = markdown.replace(/^# (.*)$/gm, "<h1>$1</h1>");
    markdown = markdown.replace(/^## (.*)$/gm, "<h2>$1</h2>");
    markdown = markdown.replace(/^### (.*)$/gm, "<h3>$1</h3>");
    markdown = markdown.replace(/^#### (.*)$/gm, "<h4>$1</h4>");
    markdown = markdown.replace(/^##### (.*)$/gm, "<h5>$1</h5>");
    markdown = markdown.replace(/^###### (.*)$/gm, "<h6>$1</h6>");
    markdown = markdown.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
    markdown = markdown.replace(/\_\_(.*?)\_\_/g, "<strong>$1</strong>");
    markdown = markdown.replace(/\*(.*?)\*/g, "<em>$1</em>");
    markdown = markdown.replace(/\_(.*?)\_/g, "<em>$1</em>");
    markdown = markdown.replace(/^\* (.*)$/gm, "<ul><li>$1</li></ul>");
    markdown = markdown.replace(/<\/ul>\n<ul>/g, "");
    markdown = markdown.replace(/^\d+\. (.*)$/gm, "<ol><li>$1</li></ol>");
    markdown = markdown.replace(/<\/ol>\n<ol>/g, "");
    markdown = markdown.replace(
      /\[([^\]]+)\]\(([^)]+)\)/g,
      '<a href="$2">$1</a>'
    );
    return markdown;
  };

  const handleFormatContent = (data) => {
    let returnData = "";

    if (type === "Email") {
      returnData = data?.email_notification_info?.email_message;
    } else if (type === "Notification") {
      returnData = data?.push_notification_info?.description;
    } else if (type === "WhatsApp") {
      if (data.whatsapp_notification_info?.type == "template") {
        returnData = show_proper_words(
          data?.whatsapp_notification_info?.template
        );
      } else {
        returnData = data?.whatsapp_notification_info?.whatsappMessage;
      }
    } else if (type === "Message") {
      returnData = data?.message_notification_info?.message;
    } else if (type === "SMS") {
      returnData = data?.sms_notification_info?.message;
    }

    if (returnData && type != "Email") {
      if (
        returnData.includes("#") ||
        returnData.includes("*") ||
        returnData.includes("_")
      ) {
        returnData = convertMarkdownToHtml(returnData);
      }
    }

    return returnData;
  };

  useEffect(() => {
    setContent(handleFormatContent(data));
  }, [data]);

  return (
    <>
      {content && (
        <div className="col-12 mb-2">
          <Accordion className="question-background text-white">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${type}-content`}
              id={`panel${type}-header`}
              className="svg-color"
            >
              {type} Content
            </AccordionSummary>
            <AccordionDetails>
              <div
                className="text-white"
                dangerouslySetInnerHTML={{
                  __html: content,
                }}
              ></div>
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </>
  );
};

export default NotificationContent;
