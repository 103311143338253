import { Icon } from "@iconify/react";
import CopyToClipboard from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useSnackbar } from "notistack";
import { Tooltip } from "@mui/material";
import Label from "src/components/Label";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { useEffect } from "react";
import { useState } from "react";
import { subscription_stripe_list } from "src/DAL/WebsitePages/WebsitePages";
import moment from "moment";
import { convertCurrencyToSign } from "src/utils/constant";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const SubscriptionDetail = (value) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoadingSection, setIsLoadingSection] = useState(false);
  const [subscriptionsData, setSubscriptionsData] = useState({});
  const [invoices, setInvoices] = useState([]);
  const [invoicesUpcommig, setInvoicesUpcommig] = useState([]);

  const handleCopiedUrl = () => {
    enqueueSnackbar("Subscription Id Copied to clipboard", {
      variant: "success",
    });
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "amount", label: "Amount" },
    { id: "Invoice_number", label: "Invoice Number" },
    {
      id: "status",
      label: "Status",
      renderData: (row) => {
        return (
          <Label
            variant="ghost"
            color={row.status !== true ? "error" : "success"}
          >
            {row.status !== true ? "Upaid" : "Paid"}
          </Label>
        );
      },
    },
    {
      id: "status",
      label: "Download PDF",
      renderData: (row) => {
        return (
          <div style={{ marginLeft: "40px" }}>
            <a
              href={row.invoice_pdf}
              target="_blank"
              rel="noreferrer"
              className="text-decoration-none"
            >
              <Icon
                icon="uil:file-download-alt"
                className="finalPrice font-size"
              />
            </a>
          </div>
        );
      },
    },
    { id: "created_at", label: "Created At" },
  ];
  const TABLE_HEAD_2 = [
    { id: "number", label: "#", type: "number" },
    { id: "product", label: "Product" },
    { id: "amount", label: "Amount" },
    { id: "Invoice_date", label: "Invoice Date" },
  ];

  const getMembersList = async () => {
    setIsLoadingSection(true);
    let postData = {
      retrive_array: ["stripe_subscription_detail"],
      stripe_subscription_id: value.value.stripe_subscription_id,
    };

    const result = await subscription_stripe_list("", "", "", postData, "");
    if (result.code == 200) {
      setSubscriptionsData(result.stripe_subscription);
      if (
        result.stripe_subscription.invoices &&
        result.stripe_subscription.invoices.length > 0
      ) {
        let invoice_array = result.stripe_subscription.invoices.map(
          (item, index) => {
            return {
              amount:
                convertCurrencyToSign(item.currency) +
                (item.amount_paid / 100).toFixed(2),
              Invoice_number: item.number,
              status: item.paid,
              invoice_pdf: item.invoice_pdf,
              created_at: moment(item.created * 1000).format("DD-MM-YYYY"),
            };
          }
        );
        setInvoices(invoice_array);
      }
      if (
        result.stripe_subscription.upcoming_invoice &&
        Object.keys(result.stripe_subscription.upcoming_invoice).length > 0
      ) {
        invoicesUpcommig.push({
          product: result.stripe_subscription.product,
          Invoice_date: result.stripe_subscription.upcoming_invoice
            .next_payment_attempt
            ? moment(
                result.stripe_subscription.upcoming_invoice
                  .next_payment_attempt * 1000
              ).format("DD-MM-YYYY")
            : "No further invoice",
          amount:
            convertCurrencyToSign(
              result.stripe_subscription.upcoming_invoice.currency
            ) +
            (
              result.stripe_subscription.upcoming_invoice.amount_due / 100
            ).toFixed(2),
        });
      }
      setIsLoadingSection(false);
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      setIsLoadingSection(false);
    }
  };
  useEffect(() => {
    getMembersList();
  }, []);
  if (isLoadingSection) {
    return <PageLoader />;
  }
  return (
    <>
      <div className="container-fluid px-4 mb-3">
        <div className="row p-3 mt-2 solid-border">
          <div className="d-flex justify-content-end ">
            <div class="d-flex me-1 text-muted">
              <div>
                <Icon icon="uiw:date" className="finalPrice" />
                <span className="ms-2">Created At:&nbsp;</span>
              </div>
              <span className="">
                {moment(subscriptionsData.created * 1000).format("DD-MM-YYYY")}
              </span>
            </div>
          </div>
          <div className="col-2 mt-3   mb-3">Product :</div>
          <div className="col-10  mt-3   text-muted">
            {subscriptionsData.product}
          </div>{" "}
          <div className="col-2  mb-3">Subscription id :</div>
          <div className="col-4 text-muted">
            <CopyToClipboard
              className="pointer me-2"
              text={subscriptionsData.id}
              onCopy={() => handleCopiedUrl(true)}
            >
              <Tooltip title="Copy Subscription  ID">
                <ContentCopyIcon
                  style={{
                    fontSize: "20px",
                  }}
                />
              </Tooltip>
            </CopyToClipboard>
            {subscriptionsData.id}
          </div>{" "}
          <div className="col-2  mb-3">Next Invoice Date:</div>
          <div className="col-4 text-muted">
            {/* <Icon icon="uiw:date" className="finalPrice" /> */}
            <span className="">
              {" "}
              {subscriptionsData.current_period_end
                ? moment(subscriptionsData.current_period_end * 1000).format(
                    "DD-MM-YYYY"
                  )
                : "NO Further Invoice"}
            </span>
          </div>{" "}
          <div className="col-2  mb-3">Mode :</div>
          <div className="col-4 text-muted">
            {subscriptionsData.livemode == false ? "sandBox" : "live"}
          </div>{" "}
          <div className="col-2  mb-3"> Status :</div>
          <div className="col-4 text-muted">
            <Label
              variant="ghost"
              color={
                subscriptionsData.status !== "active" ? "error" : "success"
              }
            >
              {subscriptionsData.status !== "active" ? "Cancelled" : "Active"}
            </Label>
          </div>
        </div>
        {invoices.length > 0 && (
          <div className="row">
            <h3 className="ps-3 mt-3 invoice-heading">Paid Invoices</h3>
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={invoices}
              className="card-with-background"
              is_hide={true}
            />
          </div>
        )}
        {invoicesUpcommig.length > 0 && (
          <>
            <div className="row">
              <h3 className="ps-3 mt-4 invoice-heading">Upcoming Invoice</h3>
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD_2}
                data={invoicesUpcommig}
                className="card-with-background"
                is_hide={true}
              />
            </div>
            <div className="row  mt-1 mb-4">
              <div className="col-6" />
              <div
                className="col-6 border-div p-3 card text-end px-4 mb-2"
                style={{ borderRadius: 16 }}
              >
                <div className="justify-content-between d-flex">
                  <div>
                    <p className="MuiTypography-root MuiTypography-body1 css-8d3p9c">
                      Sub Total:{" "}
                    </p>
                  </div>
                  <div>
                    <p className="MuiTypography-root MuiTypography-body1 text-muted css-8d3p9c">
                      {subscriptionsData.upcoming_invoice &&
                        convertCurrencyToSign(
                          subscriptionsData.upcoming_invoice.currency
                        ) +
                          (
                            subscriptionsData.upcoming_invoice.subtotal / 100
                          ).toFixed(2)}
                    </p>
                  </div>
                </div>{" "}
                <div className="justify-content-between d-flex">
                  <div>
                    <p className="MuiTypography-root MuiTypography-body1 css-8d3p9c">
                      Total:
                    </p>
                  </div>
                  <div>
                    <p className="MuiTypography-root MuiTypography-body1 text-muted css-8d3p9c">
                      {subscriptionsData.upcoming_invoice &&
                        convertCurrencyToSign(
                          subscriptionsData.upcoming_invoice.currency
                        ) +
                          (
                            subscriptionsData.upcoming_invoice.total / 100
                          ).toFixed(2)}
                    </p>
                  </div>
                </div>
                <hr />
                <div className="mt-2 justify-content-between d-flex">
                  <div>
                    <p className="MuiTypography-root MuiTypography-body1 finalPrice css-1ibif7e">
                      Amount Due:
                    </p>
                  </div>
                  <div>
                    <p className="MuiTypography-root MuiTypography-body1 css-kn7ya5">
                      {subscriptionsData.upcoming_invoice &&
                        convertCurrencyToSign(
                          subscriptionsData.upcoming_invoice.currency
                        ) +
                          (
                            subscriptionsData.upcoming_invoice.amount_due / 100
                          ).toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SubscriptionDetail;
