import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useTheme } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  CircularProgress,
  TextareaAutosize,
  FormControlLabel,
} from "@mui/material";
import {
  getTransactionDetailApiNew,
  updateTransactionCommissionApi,
} from "src/DAL/Transaction/transactionApi";
import {
  consultantSearch,
  consultantSearchNewTransactionRefferal,
} from "src/DAL/booking/bookingApi";
import { useEffect } from "react";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const notification_object = {
  affiliate_id: null,
  commission: 0,
  note: "",
};
export default function ManageCommission() {
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [notificationArray, setNotificationArray] = useState([
    notification_object,
  ]);
  const [serchTextAffiliate, setSerchTextAffiliate] = useState("");
  const [transactionName, settransactionName] = useState();
  const [serchother, setSerchother] = useState("");
  const [serchTextAffiliateTransaction, setSerchTextAffiliateTransaction] =
    useState("");
  const [affiliate, setAffiliate] = useState(null);
  const [transaction_referral, settransaction_referral] = useState(null);
  const [Users, setUsers] = useState([]);
  const [UsersUsersTransaction, setUsersTransaction] = useState([]);
  const [UsersUsersOther, setUsersOther] = useState([]);
  const [inputs, setInputs] = useState({
    referral_commission: 0,
    transaction_referral_commission: 0,
    dynamite_commission: 0,
  });

  // /imp
  const handleSearchAffiliate = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSerchTextAffiliate(value);
  };
  const handleSearchother = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSerchother(value);
  };
  const handleSearchAffiliatetransaction = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSerchTextAffiliateTransaction(value);
  };
  const getSearchAndMembers = async () => {
    const result = await consultantSearchNewTransactionRefferal(
      serchTextAffiliate,
      "all"
    );
    if (result.code === 200) {
      setUsers(result.users);
    } else {
    }
  };
  const getSearchAndMembersother = async () => {
    const result = await consultantSearch(serchother);
    if (result.code === 200) {
      setUsersOther(result.users);
    } else {
    }
  };
  const getSearchAndMembersothers = async () => {
    const result = await consultantSearch();
    if (result.code === 200) {
      setUsersOther(result.users);
    } else {
    }
  };
  const getSearchAndMemberstransactionss = async () => {
    const result = await consultantSearchNewTransactionRefferal(
      serchTextAffiliateTransaction,
      "transection_referral"
    );
    if (result.code === 200) {
      setUsersTransaction(result.users);
    } else {
    }
  };

  const handleAddNotification = () => {
    setNotificationArray((old_array) => [...old_array, notification_object]);
    getSearchAndMembersothers();
  };

  const handleRemoveNotification = (index) => {
    getSearchAndMembersothers();
    const updatedItems = [...notificationArray];
    updatedItems.splice(index, 1);
    setNotificationArray(updatedItems);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let postData;

    let newArray = [];
    if (notificationArray.length > 0) {
      notificationArray.map((item, index) => {
        const obj = {
          user_id: item.affiliate_id.affiliate_user_info?.affiliate_user,
          affiliate_id: item.affiliate_id?._id,
          commission: item.commission,
          note: item.note,
        };
        newArray.push(obj);
      });
    }

    postData = {
      affliliate: affiliate ? affiliate._id : "",
      transaction_referral: transaction_referral
        ? transaction_referral._id
        : "",
      referral_commission: inputs.referral_commission
        ? inputs.referral_commission
        : 0,

      transaction_referral_commission: inputs.transaction_referral_commission
        ? inputs.transaction_referral_commission
        : 0,

      dynamite_commission: inputs.dynamite_commission
        ? inputs.dynamite_commission
        : 0,

      other_commission: newArray,
    };

    const result = await updateTransactionCommissionApi(
      params.transaction_id,
      postData
    );

    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const getTransactionDetail = async () => {
    setIsLoading(true);
    const result = await getTransactionDetailApiNew(params.transaction_id);
    if (result.code == 200) {
      settransactionName(result.transaction?.member);
      settransaction_referral(result.transaction?.transaction_referral);
      setAffiliate(result.transaction?.affliliate);
      setInputs(result.transaction);
      setNotificationArray(result.transaction.other_commission);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value < 0 ? 0 : value }));
  };
  const handleChangeNotification = (event, index, name) => {
    if (name == "affiliate_id") {
      let temp_state = [...notificationArray];
      let temp_element = { ...temp_state[index] };
      temp_element[name] = event;
      temp_state[index] = temp_element;
      setNotificationArray(temp_state);
    } else {
      const { value } = event.target;

      let temp_state = [...notificationArray];
      let temp_element = { ...temp_state[index] };
      temp_element[name] = value < 0 ? 0 : value;
      temp_state[index] = temp_element;
      setNotificationArray(temp_state);
    }
  };
  useEffect(() => {
    if (params.transaction_id) {
      getTransactionDetail();
    }
  }, []);
  useEffect(() => {
    if (!affiliate) {
      setInputs((prevState) => ({
        ...prevState,
        ["referral_commission"]: 0,
      }));
    }

    if (!transaction_referral) {
      setInputs((prevState) => ({
        ...prevState,
        ["transaction_referral_commission"]: 0,
      }));
    }
  }, [affiliate, transaction_referral]);

  useEffect(() => {
    getSearchAndMembers();
    getSearchAndMemberstransactionss();
    getSearchAndMembersother();
  }, []);

  useEffect(() => {
    getSearchAndMembers();
  }, [serchTextAffiliate]);

  useEffect(() => {
    getSearchAndMembersother();
  }, [serchother]);

  useEffect(() => {
    getSearchAndMemberstransactionss();
  }, [serchTextAffiliateTransaction]);

  let breadCrumbMenu = [
    {
      title: "Transaction",
      navigation: -1,
      active: false,
    },
    {
      title: transactionName
        ? transactionName.first_name +
          " " +
          transactionName.last_name +
          " (" +
          transactionName.email +
          ")"
        : "",
      active: true,
    },
  ];

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col-6 mt-3">
          <h2>Manage Commission</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <Autocomplete
              id="tags-outlined"
              options={Users}
              getOptionLabel={(option) => {
                const firstName = option?.affiliate_user_info?.first_name || "";
                const lastName = option?.affiliate_user_info?.last_name || "";
                const email = option?.affiliate_user_info?.email || "";
                const createdFor =
                  option?.affiliate_user_info?.affiliate_user_created_for || "";

                const userType =
                  createdFor === "memberuser" ? "Public user" : "Delegate user";

                return `${firstName} ${lastName} (${email}) | ${userType}`;
              }}
              filterSelectedOptions
              value={affiliate}
              onChange={(event, newValue) => {
                setAffiliate(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Referral User"
                  placeholder="Referral User"
                  onChange={handleSearchAffiliate}
                />
              )}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Referral User Commission"
              variant="outlined"
              required={affiliate ? true : false}
              fullWidth
              type="number"
              disabled={affiliate ? false : true}
              name="referral_commission"
              value={inputs.referral_commission}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <Autocomplete
              id="tags-outlined"
              options={UsersUsersTransaction}
              getOptionLabel={(option) => {
                const firstName = option?.affiliate_user_info?.first_name || "";
                const lastName = option?.affiliate_user_info?.last_name || "";
                const email = option?.affiliate_user_info?.email || "";
                const createdFor =
                  option?.affiliate_user_info?.affiliate_user_created_for || "";

                const userType =
                  createdFor === "memberuser" ? "Public user" : "Delegate user";

                return `${firstName} ${lastName} (${email}) | ${userType}`;
              }}
              filterSelectedOptions
              value={transaction_referral}
              onChange={(event, newValue) => {
                settransaction_referral(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Transaction Referral User"
                  placeholder="Transaction Referral User"
                  onChange={handleSearchAffiliatetransaction}
                />
              )}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Transaction Referral Commission"
              variant="outlined"
              required={transaction_referral ? true : false}
              fullWidth
              type="number"
              disabled={transaction_referral ? false : true}
              name="transaction_referral_commission"
              value={inputs.transaction_referral_commission}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Team Diego Commission"
              variant="outlined"
              fullWidth
              type="number"
              name="dynamite_commission"
              value={inputs.dynamite_commission}
              onChange={handleChange}
            />
          </div>
          {notificationArray.length > 0 ? (
            <div className="mt-4">
              <h3>Other Commission</h3>
            </div>
          ) : (
            ""
          )}
          {notificationArray.length > 0 &&
            notificationArray.map((notification, index) => {
              return (
                <div className="card pt-5 pb-5 pe-3 ps-3 mb-3">
                  <div className="col-12 position-relative">
                    <span
                      style={{ top: "-38px", right: "-8px" }}
                      className="icon-container-card icon-postion"
                      onClick={() => handleRemoveNotification(index)}
                    >
                      x
                    </span>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <Autocomplete
                        id="tags-outlined"
                        options={UsersUsersOther}
                        getOptionLabel={(option) =>
                          option?.affiliate_user_info?.first_name +
                          " " +
                          option?.affiliate_user_info?.last_name +
                          " (" +
                          option?.affiliate_user_info?.email +
                          ")"
                        }
                        filterSelectedOptions
                        value={notification.affiliate_id}
                        onChange={(event, newValue) => {
                          handleChangeNotification(
                            newValue,
                            index,
                            "affiliate_id"
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Delegate"
                            required
                            placeholder="Delegate"
                            onChange={handleSearchother}
                          />
                        )}
                      />
                    </div>
                    <div className="col-6">
                      <TextField
                        id="outlined-basic"
                        label="Commission"
                        variant="outlined"
                        fullWidth
                        required={notification.affiliate_id ? true : false}
                        type="number"
                        name="commission"
                        disabled={notification.affiliate_id ? false : true}
                        value={notification.commission}
                        onChange={(e) => {
                          handleChangeNotification(e, index, "commission");
                        }}
                      />
                    </div>
                    <div className="col-12">
                      <p className="tracking_code_heading">Note:</p>
                      <TextareaAutosize
                        aria-label="empty textarea"
                        value={notification?.note}
                        name="note"
                        onChange={(e) => {
                          handleChangeNotification(e, index, "note");
                        }}
                        className="textarea-autosize"
                        placeholder="Note"
                        style={{
                          width: "100%",
                          height: "100px",
                          backgroundColor: "transparent",
                          color: "white",
                          borderRadius: "10px",
                          paddingTop: "8px",
                          paddingLeft: "5px",
                          outline: "none",
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          <div className="col-12 mt-3">
            <FormControlLabel
              control={<AddCircleOutlineIcon className="diary-icon-add mx-2" />}
              label="Add Other Commission"
              onClick={handleAddNotification}
            />
          </div>
          <div className="text-end mt-4" id="fixedbutton">
            <button className="small-contained-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
}
