import React from "react";
import { convertCurrencyToSign } from "src/utils/constant";

export default function DiscountInfo({ item }) {
  const { currency, discount_info } = item;
  let Currency = convertCurrencyToSign(currency);

  return (
    <>
      {discount_info ? (
        <>
          <div>
            <span className="main-menu-heading fw-normal">
              Original Amount:{" "}
            </span>
            <span>{Currency + discount_info.original_amount}</span>
          </div>
          {discount_info.discount_amount > 0 && (
            <div>
              <span className="main-menu-heading fw-normal">Discount: </span>
              <span>{Currency + discount_info.discount_amount.toFixed(2)}</span>
            </div>
          )}
          {discount_info.id && (
            <div>
              <span className="main-menu-heading fw-normal">
                Discount Code:{" "}
              </span>
              <span>{discount_info.id}</span>
            </div>
          )}
          {discount_info.coins_deduction > 0 && (
            <div>
              <span className="main-menu-heading fw-normal">
                Coins Deduction:{" "}
              </span>
              <span>{discount_info.coins_deduction}</span>
            </div>
          )}
        </>
      ) : (
        "N/A"
      )}
    </>
  );
}
