import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import { useEffect } from "react";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { s3baseUrl } from "src/config/config";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import {
  active_list_all_mediatation_category_api,
  add_mentorship_video_api,
  mentorship_video_detail_api,
  mentorship_video_update_api,
} from "src/DAL/Mentorship/MentorshipApi";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DatePicker } from "@mui/lab";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const Input = styled("input")({
  display: "none",
});

export default function AddOrUpdateMentorshipVideos() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [thumbnail_file, setThumbnailFile] = useState();
  const [categoryName, setCategoryName] = useState(null);
  const [oldImage, setOldImage] = useState("");
  const [formType, setFormType] = useState("ADD");
  const [categoriesData, setCategoriesData] = useState([]);

  const [inputs, setInputs] = useState({
    title: "",
    status: true,
    short_description: "",
    detailed_description: "",
    is_locked: false,
    is_default: false,
    // start_days: "",
    // end_days: "",
    upgrade_button_text: "",
    video_url: "",
    image: {},
    order: 0,
    start_date: "",
    end_date: "",
    locked_statement: "",
  });

  const fileChangedHandler = (e) => {
    setThumbnailFile(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };

  const getCategoryData = async () => {
    setIsLoading(true);
    let result = await mentorship_video_detail_api(params.video_slug);
    if (result.code == 200) {
      setFormType("EDIT");
      setInputs(result.mentorship_video);
      setOldImage(result.mentorship_video?.image?.thumbnail_2);
      setCategoryName(result.mentorship_video?.mentorship_category);
      setIsLoading(false);
    } else {
      navigate(`/mentorship-category`);
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getCategoriesListing = async () => {
    const result = await active_list_all_mediatation_category_api();
    if (result.code == 200) {
      setCategoriesData(result.meditation_category);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    const formData = new FormData();
    formData.append("title", inputs.title);
    formData.append("video_url", inputs.video_url);
    // formData.append("start_days", inputs.start_days);
    // formData.append("end_days", inputs.end_days);
    if (inputs.is_default == true) {
      formData.append("is_locked", false);
    } else {
      formData.append("is_locked", inputs.is_locked);
    }
    formData.append("is_default", inputs.is_default);
    formData.append("upgrade_button_text", inputs.upgrade_button_text);
    if (inputs.is_locked) {
      formData.append("locked_statement", inputs.locked_statement);
    }
    formData.append("mentorship_category", categoryName._id);
    formData.append("detailed_description", inputs.detailed_description);
    formData.append("short_description", inputs.short_description);
    formData.append("status", inputs.status);
    if (formType === "EDIT") {
      formData.append("order", inputs.order);
      if (thumbnail_file) {
        formData.append("image", inputs.image);
      }
    } else {
      formData.append("image", inputs.image);
    }
    const result =
      formType === "ADD"
        ? await add_mentorship_video_api(formData)
        : await mentorship_video_update_api(formData, params.video_slug);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(`/mentorship-video`);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangeDateStart = (event) => {
    setInputs({
      ...inputs,
      ["start_date"]: event,
    });
  };

  const handleChangeDateEnd = (event) => {
    setInputs({
      ...inputs,
      ["end_date"]: event,
    });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    if (name == "is_locked" && value == true) {
      setInputs((values) => ({ ...values, is_default: false }));
    }
    if (name == "is_default" && value == true) {
      setInputs((values) => ({ ...values, is_locked: false }));
    }
  };

  const handleChangeCategorie = (value) => {
    setCategoryName(value);
  };

  useEffect(() => {
    getCategoriesListing();
  }, []);

  useEffect(() => {
    if (params && params.video_slug) {
      if (state) {
        setFormType("EDIT");
        setInputs(state);
        setOldImage(state?.image?.thumbnail_2);
        setCategoryName(state?.mentorship_category);
      } else {
        getCategoryData();
      }
    }
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(`/mentorship-video`)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2 className="ms-1">{`${
            formType === "ADD" ? "Add" : "Edit"
          } Bonus Content Video`}</h2>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              fullWidth
              required
              name="title"
              value={inputs.title}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Upgrade Button Text"
              variant="outlined"
              fullWidth
              required
              name="upgrade_button_text"
              value={inputs.upgrade_button_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Video Url"
              variant="outlined"
              fullWidth
              required
              name="video_url"
              value={inputs.video_url}
              onChange={handleChange}
            />
          </div>
          {formType === "EDIT" && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Order"
                variant="outlined"
                fullWidth
                required
                name="order"
                value={inputs.order}
                onChange={handleChange}
              />
            </div>
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <MUIAutocomplete
              inputLabel="Category"
              selectedOption={categoryName}
              setSelectedOption={handleChangeCategorie}
              optionsList={categoriesData}
              required
              name="title"
            />
          </div>
          {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Start Days"
              variant="outlined"
              fullWidth
              required
              name="start_days"
              value={inputs.start_days}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="End Days"
              variant="outlined"
              fullWidth
              required
              name="end_days"
              value={inputs.end_days}
              onChange={handleChange}
            />
          </div> */}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Is Locked *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_locked"
                value={inputs.is_locked}
                label="Is Locked *"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs.is_locked && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Locked Statement"
                variant="outlined"
                fullWidth
                required={inputs.is_locked}
                name="locked_statement"
                value={inputs.locked_statement}
                onChange={handleChange}
              />
            </div>
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Is Default *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_default"
                required
                value={inputs.is_default}
                label="Is Default *"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                value={inputs.status}
                label="Status *"
                onChange={handleChange}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Upload Image *</p>
                <FormHelperText className="pt-0">
                  Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {thumbnail_file ? (
                  <img src={thumbnail_file} height="50" />
                ) : (
                  oldImage && <img src={s3baseUrl + oldImage} height="50" />
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="image"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.image?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.image?.name}</p>
            )}
          </div>
          <div className="col-12 mt-3">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Short Description"
                multiline
                required
                rows={6}
                name="short_description"
                value={inputs.short_description}
                onChange={handleChange}
              />
            </FormControl>
          </div>
          <div className="col-12 mt-5">
            <h4>Detail Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="detailed_description"
              editorHeight={320}
            />
          </div>

          <div
            className="text-end mt-4"
            id={formType == "ADD" ? "" : "fixedbutton"}
          >
            <button className="small-contained-button">
              {formType == "ADD" ? "Submit" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
