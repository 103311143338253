import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import {
  get_goal_statement_unlock_settings_api,
  update_goal_statement_unlock_settings_api,
} from "src/DAL/goalstatement/goalStatement";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const EMPTY_OBJECT = {
  program_info: null,
  days_to_unlock: 0,
};

export default function UnlockGoalStatementSetting() {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [programsList, setProgramsList] = useState([]);
  const [inputList, setInputList] = useState([{ ...EMPTY_OBJECT }]);

  const handleAddClick = (index) => {
    setInputList([
      ...inputList.slice(0, index),
      { ...EMPTY_OBJECT },
      ...inputList.slice(index),
    ]);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const getFilteredPrograms = () => {
    let selectedProgramIds = inputList.map(
      (inputs) => inputs.program_info?._id
    );
    return programsList.filter(
      (program) => !selectedProgramIds.includes(program._id)
    );
  };

  const get_content_setting = async () => {
    const result = await get_goal_statement_unlock_settings_api();
    if (result.code == 200) {
      setProgramsList(result.programs);
      if (result.goal_statement_unlock_setting?.length > 0) {
        setInputList(result.goal_statement_unlock_setting);
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    for (let i = 0; i < inputList.length; i++) {
      if (!inputList[i].program_info) {
        enqueueSnackbar(`Please select a program for entry #${i + 1}`, {
          variant: "error",
        });
        return;
      }
    }

    setIsLoadingForm(true);
    let postData = {
      goal_statement_unlock_setting: inputList,
    };
    const result = await update_goal_statement_unlock_settings_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (value, name, index) => {
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  useEffect(() => {
    get_content_setting();
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <form onSubmit={handleSubmit} className="row">
        <div className="col-12">
          <h2>Unlock Goal Statement Setting</h2>
        </div>
        {inputList.map((inputs, index) => {
          return (
            <>
              <div className="col-12 col-lg-6 mt-4">
                <MUIAutocomplete
                  inputLabel="Choose Program"
                  selectedOption={inputs.program_info}
                  setSelectedOption={(value) =>
                    handleChange(value, "program_info", index)
                  }
                  optionsList={getFilteredPrograms()}
                  autoComplete="new-password"
                  name="title"
                />
              </div>
              <div className="col-10 col-lg-5 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Days To Unlock"
                  variant="outlined"
                  fullWidth
                  type="number"
                  name="days_to_unlock"
                  value={inputs.days_to_unlock}
                  onChange={(e) =>
                    handleChange(e.target.value, "days_to_unlock", index)
                  }
                />
              </div>
              <div className="col-2 col-lg-1 mt-4">
                <AddCircleOutlineIcon
                  className="question-survey-update"
                  onClick={() => handleAddClick(index + 1)}
                />
                {inputList.length !== 1 && (
                  <RemoveCircleOutlineIcon
                    className="question-survey-cross-update"
                    onClick={() => handleRemoveClick(index)}
                  />
                )}
              </div>
            </>
          );
        })}
        <div className="text-end mt-4" id="fixedbutton">
          <button className="small-contained-button" disabled={isLoadingForm}>
            {isLoadingForm ? "Updating..." : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
