import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton, Autocomplete } from "@mui/material";
import { useEffect } from "react";
import { s3baseUrl } from "src/config/config";
import {
  addAdminUSer,
  AdminNavItems,
  editAdminUser,
} from "src/DAL/AdminUserApi/AdminUserApi";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const Input = styled("input")({
  display: "none",
});
const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, delegatesList, theme) {
  return {
    fontWeight:
      delegatesList.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export default function AddOrUpdateAdminUser() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const params = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState({});
  const [oldImage, setOldImage] = useState();
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    password: "",
    role: "admin",
    image: {},
    status: true,
  });

  const fileChangedHandler = (e) => {
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
    setImage(URL.createObjectURL(e.target.files[0]));
  };
  const getNavItemList = async () => {
    const result = await AdminNavItems();
    if (result.code === 200) {
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("name", inputs.name);
    formData.append("email", inputs.email);
    formData.append("status", inputs.status);
    if (!params?.id) {
      formData.append("password", inputs.password);
    }
    formData.append("role", inputs.role);

    if (image.length > 0) {
      formData.append("image", inputs.image);
    }
    console.log(...formData);
    const result = params.id
      ? await editAdminUser(formData, params.id)
      : await addAdminUSer(formData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    if (params?.id && state) {
      setInputs(state);
      setOldImage(state?.image?.thumbnail_1);
    }
  }, []);
  useEffect(() => {
    getNavItemList();
  }, []);
  console.log(state);
  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2 className="ms-1">
            {params?.id ? "Edit Admin User" : "Add Admin User"}
          </h2>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Name"
              variant="outlined"
              fullWidth
              required
              name="name"
              value={inputs.name}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              fullWidth
              required
              type="email"
              name="email"
              value={inputs.email}
              onChange={handleChange}
            />
          </div>{" "}
          {!params?.id && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Password"
                variant="outlined"
                fullWidth
                required
                type="password"
                name="password"
                value={inputs.password}
                onChange={handleChange}
              />
            </div>
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Role</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="role"
                value={inputs.role}
                label="Role"
                onChange={handleChange}
              >
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="developer">Developer</MenuItem>
                <MenuItem value="manager">Manager</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                value={inputs.status}
                label="Status"
                onChange={handleChange}
              >
                <MenuItem value="true">Active</MenuItem>
                <MenuItem value="false">Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">
                  {params?.id ? `Upload Image` : `Upload Image `}
                </p>
                <FormHelperText className="pt-0">
                  Image Size(350 X 100) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {image.length > 0 ? (
                  <img src={image} height="50" />
                ) : (
                  oldImage && <img src={s3baseUrl + oldImage} height="50" />
                )}
              </div>

              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file-logo">
                  <Input
                    accept="image/*"
                    id="contained-button-file-logo"
                    multiple
                    type="file"
                    name="logo"
                    hidden
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.image?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs?.image?.name}</p>
            )}
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
}
