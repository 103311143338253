import React, { useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, Button, FormControl, TextField } from "@mui/material";
import {
  Detailbooking,
  booking_slots_by_delegate_and_date,
  consultantSearchListApi,
  consultantUpdateForBooking,
  update_booking,
} from "src/DAL/booking/bookingApi";
import { useEffect } from "react";
import { get_root_value } from "src/utils/domUtils";
import { useSnackbar } from "notistack";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const UpdateBooking = ({
  selectedDelegate,
  setSelectedDelegate,
  bookingId,
  onCloseDrawer,
  DelegateData,
  reload,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [serchText, setSerchText] = useState("");
  const [timeSlotList, setTimeSlotList] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState({
    startDate: DelegateData.date,
  });

  const [personName, setPersonName] = useState([]);
  const [member, setMember] = useState([]);
  console.log(DelegateData, "DelegateData");
  const handleSearch = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSerchText(value);
  };
  const [startDateMin, setStartDateMin] = useState(new Date());

  const handleChangeStartDate = (event) => {
    setState((prevState) => {
      return {
        ...prevState,
        startDate: event.$d,
      };
    });
  };

  console.log(selectedTime, "selectedTime");
  const getTimeListing = async () => {
    const dateString = moment(state.startDate).format("YYYY/MM/DD");
    let data = {
      date: dateString,
      consultant_id: selectedDelegate._id,
    };
    const result = await booking_slots_by_delegate_and_date(data);
    if (result.code === 200) {
      setTimeSlotList(result.slots);
      if (timeSlotList.length > 0) {
        setSelectedTime(null);
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getDetailBooking = async () => {
    const result = await Detailbooking(DelegateData._id);
    if (result.code === 200) {
      setSelectedTime(result?.booking?.slot_id);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getSearchGroupsAndMembers = async () => {
    const result = await consultantSearchListApi(serchText);
    if (result.code === 200) {
      setPersonName(result.consultant_list);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  const getConsultantsOnsearch = async () => {
    if (serchText.length > 2) {
      const result = await consultantSearchListApi(serchText);
      if (result.code === 200) {
        setPersonName(result.consultant_list);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }
  };
  const handleSubmit = async () => {
    setIsLoadingButton(true);
    // e.preventDefault();
    if (selectedDelegate == null) {
      setIsLoadingButton(false);
      return enqueueSnackbar("Delegate's name can not be empty !", {
        variant: "error",
      });
    }
    if (selectedTime == null) {
      setIsLoadingButton(false);
      return enqueueSnackbar("Time Slot can not be empty !", {
        variant: "error",
      });
    }
    var startdateString = moment(state.startDate).format("YYYY/MM/DD");
    const data = {
      consultant_id: selectedDelegate._id,
      date: startdateString,
      slot_id: selectedTime.slot_id,
      time: selectedTime.start_time,
    };
    const result = await update_booking(bookingId, data);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      onCloseDrawer();
      reload();
      setIsLoadingButton(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingButton(false);
    }
  };
  useEffect(() => {
    getSearchGroupsAndMembers();
  }, []);
  useEffect(() => {
    getConsultantsOnsearch();
  }, [serchText]);
  useEffect(() => {
    getDetailBooking();
  }, []);
  useEffect(() => {
    if (selectedDelegate?._id) {
      getTimeListing();
    }
  }, [state.startDate, selectedDelegate]);

  if (isLoading) {
    return <PageLoader />;
  }
  // console.log(personName, "personName");
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="mt-4">
          <Autocomplete
            // multiple
            id="tags-outlined"
            options={personName}
            getOptionLabel={(option) =>
              option.first_name +
              " " +
              option.last_name +
              " (" +
              option.email +
              ")"
            }
            filterSelectedOptions
            value={selectedDelegate}
            onChange={(event, newValue) => {
              setSelectedDelegate(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Consultant"
                placeholder="Consultant"
                onChange={handleSearch}
              />
            )}
          />
        </div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            format="DD-MM-YYYY"
            mask="__/__/____"
            inputFormat="DD-MM-YYYY"
            minDate={startDateMin}
            label="Date"
            name="startDate"
            value={state.startDate}
            onChange={(e) => handleChangeStartDate(e, "startDate")}
            renderInput={(params) => (
              <TextField {...params} className="mt-3" required={true} />
            )}
          />
        </LocalizationProvider>
        <FormControl fullWidth className="mt-3">
          <Autocomplete
            id="time-slots"
            options={timeSlotList}
            getOptionLabel={(time) => time.start_time + " - " + time.end_time}
            getOptionSelected={(option, value) =>
              option.slot_id === value.slot_id
            }
            value={selectedTime}
            onChange={(event, newValue) => {
              setSelectedTime(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Time Slots*" variant="outlined" />
            )}
            required
          />
        </FormControl>

        <Box sx={{ py: 2 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            onClick={() => {
              handleSubmit();
            }}
            // startIcon={
            //   <svg
            //     width="14"
            //     height="14"
            //     viewBox="0 0 14 14"
            //     fill="none"
            //     xmlns="http://www.w3.org/2000/svg"
            //     style={{ marginRight: 5 }}
            //   >
            //     <path
            //       d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
            //       fill={get_root_value("--portal-theme-primary")}
            //     />
            //   </svg>
            // }
          >
            {isLoadingButton ? "Updating..." : "Update"}
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default UpdateBooking;
