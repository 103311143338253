import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import {
  get_firebase_setting_api,
  update_firebase_setting_api,
} from "src/DAL/SiteSetting/FirebaseConfig";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function FirebaseConfig() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  const [inputs, setInputs] = useState({
    apiKey: "",
    authDomain: "",
    databaseURL: "",
    projectId: "",
    storageBucket: "",
    messagingSenderId: "",
    appId: "",
    measurementId: "",
  });

  const get_firebase_setting = async () => {
    const result = await get_firebase_setting_api();
    if (result.code == 200) {
      setInputs(result.firebase_settings);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);

    let postData = { firebase_settings: inputs };

    const result = await update_firebase_setting_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    get_firebase_setting();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-6">
          <h2>Firebase Setting</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Api Key"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              name="apiKey"
              value={inputs.apiKey}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Auth Domain"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              name="authDomain"
              value={inputs.authDomain}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Database URL"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              name="databaseURL"
              value={inputs.databaseURL}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Project Id"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              name="projectId"
              value={inputs.projectId}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Storage Bucket"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              name="storageBucket"
              value={inputs.storageBucket}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Messaging Sender Id"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              name="messagingSenderId"
              value={inputs.messagingSenderId}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="App Id"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              name="appId"
              value={inputs.appId}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Measurement Id"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              name="measurementId"
              value={inputs.measurementId}
              onChange={handleChange}
            />
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">
              {isLoadingForm ? "Updating" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
