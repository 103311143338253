import * as React from "react";
import { useState } from "react";
import {
  _get_detail_tab_configration,
  _update_tab_configration_v1,
} from "src/DAL/Programme/Programme";
import GeneralTabConfiguration from "src/components/GeneralComponents/GeneralTabConfiguration";

export default function LessonTabConfiguration() {
  const [isLoading, setIsLoading] = useState(true);
  const [moduleInfo, setModuleInfo] = useState(null);

  let breadCrumbMenu = [
    {
      title: "Programmes",
      navigation: `/programmes`,
    },
    {
      title: moduleInfo?.program[0]?._id?.title,
      navigation: `/programmes/programmes-detail/${moduleInfo?.program[0]?._id?.program_slug}`,
    },
    {
      title: "Lessons",
      navigation: `/lessons/${moduleInfo?.program[0]?._id?.program_slug}`,
      active: false,
    },
    {
      title: moduleInfo?.title,
      navigation: `/lessons/lesson-detail/${moduleInfo?.lesson_slug}`,
      active: false,
    },
    {
      title: `Lesson Tabs Configuration`,
      navigation: ``,
      active: true,
    },
  ];

  return (
    <GeneralTabConfiguration
      type="lesson"
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      setModuleInfo={setModuleInfo}
      breadCrumbMenu={breadCrumbMenu}
      tabsConfigurationTitle={`Lesson Tabs Configuration`}
    />
  );
}
