import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useEffect } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { s3baseUrl } from "src/config/config";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { api_date_format } from "src/utils/constant";
import {
  get_quest_for_member_api,
  update_quest_for_member_api,
} from "src/DAL/member/member";
import PageLoader from "src/components/GeneralComponents/PageLoader";

export default function ManageQuestAccess() {
  const { member_id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [questsList, setQuestsList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [memberDetail, setMemberDetail] = useState({});
  const [rowPerPage, setrowPerPage] = useState(50);

  const getting_start_date = (object, member) => {
    let selected = member.quests.find(
      (old) => old.mission_info._id == object._id
    );
    if (selected) {
      return selected.mission_start_date;
    }
    return object.start_date;
  };

  const getting_expiry_date = (item, member) => {
    let selected = member.quests.find(
      (old) => old.mission_info._id == item._id
    );
    if (selected) return selected.mission_end_date;
    let no_of_days = item.mission_duration + item.replay_days;
    let expiry_date = new Date(item.start_date);
    expiry_date.setDate(expiry_date.getDate() + no_of_days);
    return expiry_date;
  };

  const get_mission_quests = async () => {
    const result = await get_quest_for_member_api(member_id);
    if (result.code == 200) {
      setMemberDetail(result.member);
      if (result.member.quests?.length > 0) {
        let selected_quests = result.member.quests.map((quest) => {
          return { ...quest, _id: quest.mission_info._id || quest._id };
        });
        setSelected(selected_quests);
      }
      setrowPerPage(result.all_quests.length);
      var questsArray = [];
      result.all_quests.map((item) => {
        let no_of_days = item.mission_duration + item.replay_days;
        item.start_date = getting_start_date(item, result.member);
        item.table_avatar = {
          src: s3baseUrl + item.image.thumbnail_2,
          alt: item.title,
        };
        item.is_show_celendar = true;
        item.type = "date";
        item.access_type = result?.member?.is_plan_free ? "free" : "paid";
        item.expiry_date = getting_expiry_date(item, result.member);
        item.no_of_days = no_of_days;
        questsArray.push(item);
      });
      setQuestsList(questsArray);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async () => {
    setIsLoadingForm(true);
    let quests_array = [];
    selected.map((item) => {
      var quest_object = {
        _id: item._id,
        start_date: api_date_format(item.start_date),
      };
      if (item.type == "day") {
        quest_object.no_of_days = parseInt(item.no_of_days, 10);
      } else {
        quest_object.expiry_date = api_date_format(item.expiry_date);
      }
      quests_array.push(quest_object);
    });
    let questObject = { quests: quests_array };

    const result = await update_quest_for_member_api(member_id, questObject);
    if (result.code == 200) {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleChange = (event, name) => {
    const value = event.target.value;
    const mission_id = event.target.name;
    setQuestsList((data) =>
      data.map((obj) => {
        if (obj._id === mission_id) {
          return { ...obj, [name]: value };
        }
        return obj;
      })
    );
    setSelected((data) =>
      data.map((obj) => {
        if (obj._id === mission_id) {
          return { ...obj, [name]: value };
        }
        return obj;
      })
    );
  };
  const handleChangeType = (event, name, id) => {
    const value = event.target.value;
    setQuestsList((data) =>
      data.map((obj) => {
        if (obj._id === id) {
          return { ...obj, [name]: value };
        }
        return obj;
      })
    );
    setSelected((data) =>
      data.map((obj) => {
        if (obj._id === id) {
          return { ...obj, [name]: value };
        }
        return obj;
      })
    );
  };
  const find_and_update_start_date = (row, date, list, setList) => {
    let find_index = list.findIndex((prog) => prog._id == row._id);
    let temp_state = [...list];
    let temp_element = { ...temp_state[find_index] };
    temp_element.start_date = date;
    temp_state[find_index] = temp_element;
    setList(temp_state);
  };

  const handleChangeStartDate = (date, index, row) => {
    find_and_update_start_date(row, date, questsList, setQuestsList);
    let exist_in_selected = selected.find((s) => s._id === row._id);
    if (exist_in_selected) {
      find_and_update_start_date(row, date, selected, setSelected);
    }
  };

  const handleChangeExpiryDate = (date, row) => {
    let find_index = questsList.findIndex((prog) => prog._id == row._id);
    let temp_state = [...questsList];
    let temp_element = { ...temp_state[find_index] };
    temp_element.expiry_date = date;
    temp_element.type = "date";
    temp_state[find_index] = temp_element;
    setQuestsList(temp_state);

    let find_index_selected = selected.findIndex((prog) => prog._id == row._id);
    let temp_state_selected = [...selected];
    let temp_element_selected = { ...temp_state_selected[find_index_selected] };
    temp_element_selected.expiry_date = date;
    temp_state_selected[find_index_selected] = temp_element;
    setSelected(temp_state_selected);
  };

  const TABLE_HEAD = [
    {
      id: "table_avatar",
      label: "Image",
      type: "thumbnail",
      className: "p-0",
    },
    { id: "title", label: "Quest Title" },
    { id: "mission_duration", label: "Quest Duration" },
    // {
    //   id: "access_type",
    //   label: "Access Type",
    //   renderData: (row) => {
    //     let is_selected = selected?.find((object) => row._id === object._id);
    //     let access_type = is_selected?.access_type || row.access_type;

    //     return (
    //       <div className="col-12">
    //         <FormControl fullWidth size="small">
    //           <InputLabel id="demo-simple-select-label"></InputLabel>
    //           <Select
    //             labelId="demo-simple-select-label"
    //             id="demo-simple-select"
    //             name="access_type"
    //             value={access_type}
    //             className="access_type_field"
    //             label=""
    //             onChange={(e) => {
    //               handleChangeType(e, "access_type", row._id);
    //             }}
    //           >
    //             <MenuItem value="free">Free</MenuItem>
    //             <MenuItem value="paid">Paid</MenuItem>
    //           </Select>
    //         </FormControl>
    //       </div>
    //     );
    //   },
    // },
    {
      id: "start_date",
      label: "Start Date",
      type: "row_calendar",
      handleChangeDate: handleChangeStartDate,
      className: "event_calendar_box",
    },
    // {
    //   id: "no_of_start_days",
    //   label: "End Access Interval Type",
    //   renderData: (row) => {
    //     let is_selected = selected?.find((object) => row._id === object._id);
    //     let type = is_selected?.type || row.type;

    //     return (
    //       <div className="col-12">
    //         <FormControl fullWidth size="small">
    //           <InputLabel id="demo-simple-select-label"></InputLabel>
    //           <Select
    //             labelId="demo-simple-select-label"
    //             id="demo-simple-select"
    //             name="type"
    //             value={type}
    //             label=""
    //             onChange={(e) => {
    //               handleChangeType(e, "type", row._id);
    //             }}
    //           >
    //             <MenuItem value="day">Days</MenuItem>
    //             <MenuItem value="date">Date</MenuItem>
    //           </Select>
    //         </FormControl>
    //       </div>
    //     );
    //   },
    // },
    {
      id: "no_of_days",
      label: "Expiry Date",
      renderData: (row) => {
        let is_selected = selected?.find((object) => row._id === object._id);
        let no_of_days = is_selected?.no_of_days || row.no_of_days;
        let expiry_date = is_selected?.expiry_date || row.expiry_date;
        let start_date = is_selected?.start_date || row.start_date;
        let type = is_selected?.type || row.type;

        return (
          <div className="quest-access-expiry-box">
            {type == "day" ? (
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                size="small"
                type="number"
                required
                name={row._id}
                className="default_consultant_commission_input"
                value={no_of_days}
                sx={{
                  "& fieldset": { border: "none" },
                }}
                inputProps={{ min: 0 }}
                onChange={(e) => {
                  handleChange(e, "no_of_days");
                }}
              />
            ) : (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  inputFormat="dd-MM-yyyy"
                  onChange={(e) => {
                    handleChangeExpiryDate(e, row);
                  }}
                  value={expiry_date}
                  minDate={new Date(start_date)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </LocalizationProvider>
            )}
          </div>
        );
      },
    },
    {
      id: "status",
      label: "Status",
      type: "row_status",
    },
  ];

  useEffect(() => {
    get_mission_quests();
  }, []);

  if (isLoading == true) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row mb-4">
        <div className="col-12 mb-3 reply-anchor">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <span className="sale-page-title">
            {memberDetail.first_name +
              " " +
              memberDetail.last_name +
              " (" +
              memberDetail.email +
              ")"}
          </span>
        </div>
        <div className="col-6">
          <h2>Manage Quest Access</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={questsList}
            selected={selected}
            setSelected={setSelected}
            checkbox_selection={true}
            pagePagination={false}
            rows_per_page_count={rowPerPage}
            hide_footer_pagination={true}
          />
        </div>
      </div>
      <div className="ms-auto text-end" id="fixedbutton">
        <button
          className="small-contained-button mt-3 ml-auto"
          onClick={handleSubmit}
        >
          {isLoadingForm ? "Updating..." : "Update"}
        </button>
      </div>
    </div>
  );
}
