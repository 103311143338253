import { TableCell } from "@mui/material";
import React from "react";
import { useState } from "react";
import { get_short_string } from "src/utils/constant";

export default function TableDescription(props) {
  const { head, row, index } = props;
  const [showMore, setShowMore] = useState(false);
  return (
    <TableCell
      align="left"
      className={head.className}
      key={`key-${head.label}-${index}`}
    >
      {head.renderData ? (
        head.renderData(row, index)
      ) : (
        <span
          className={row.className}
          onClick={() => {
            head.handleClick ? head.handleClick(row, index) : undefined;
          }}
        >
          {head.letterCounts && !showMore
            ? get_short_string(row[head.id], head.letterCounts)
            : row[head.id]}
          {head.letterCounts &&
            row[head.id]?.length > get_short_string(row[head.id])?.length && (
              <span
                className="see-less-more"
                onClick={() => setShowMore((old) => !old)}
              >
                {`See ${showMore ? "Less" : "More"}`}
              </span>
            )}
        </span>
      )}
    </TableCell>
  );
}
