import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton, FormHelperText, Button } from "@mui/material";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  UPdate_mission_list,
  _get_membership_levels_mission_detail,
  addMissionLevel,
  level_list_api_mission,
  mission_days_and_badges,
} from "src/DAL/MissionApi/MissionApiNew";
import { s3baseUrl } from "src/config/config";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import moment from "moment";
import { usePGIMode } from "src/Hooks/PGIModeContext";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import PageLoader from "src/components/GeneralComponents/PageLoader";
import NotifyUser from "./NotifyUser";
import { membership_level_pyramid_delete } from "src/DAL/BonusRoadMAp/BonusRoadMApApi";

const Input = styled("input")({
  display: "none",
});

const badge_object = {
  badge_level_id: "",
  membership_status: "all",
};

export default function AddOrUpdateChallenges() {
  const navigate = useNavigate();
  const params = useParams();
  const { state } = useLocation();
  const [formType, setFormType] = useState("ADD");
  const { badgeLevels } = usePGIMode();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setProfileImage] = useState();
  const [oldImage, setOldImage] = useState("");
  const [level, setLevels] = useState([]);
  const [membershipLevelList, setMembershipLevelList] = useState([]);
  const [days, setDays] = useState([]);
  const [badgeLevelList, setBadgeLevelList] = useState([]);
  const [paymentPlans, setPaymentPlan] = useState([]);
  const [paymentPlansFree, setPaymentPlanFree] = useState([]);
  const [notifyLevel, setNotifyLevel] = useState([badge_object]);
  const [inputs, setInputs] = useState({
    title: "",
    status: true,
    short_description: "",
    type: "mission",
    quest_type: "",
    detailed_description: "",
    rewarded_coins: 0,
    coins_to_unlock: 0,
    start_date: moment(),
    video_url: "",
    schedule_unlock_type: "coins_based",
    complete_current_schedule: true,
    order: 0,
    mission_duration: 1,
    level_info: [],
    start_time: new Date(),
    mission_access_type: "both",
    membership_level_id: "",
    badge_level_id: "",
    promo_video: "",
    replay_days: 0,
    is_paid: true,
    show_day_on_dashboard: "",
    is_chat_enabled: true,
    payment_plan: null,
    is_show_on_mission_control: true,
    notify_users: false,
    redirect_link: "",
    redirect_user_to: false,
  });
  const handleChangeCommunity = (value) => {
    setInputs((values) => ({ ...values, ["level_info"]: value }));
  };

  const handleChangeDateRange = (event, type) => {
    setInputs((values) => ({
      ...values,
      [type]: event,
    }));
  };

  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!file && formType == "ADD") {
      setIsLoading(false);
      enqueueSnackbar("image is required", { variant: "error" });
      return;
    }
    // if (inputs.level_info.length == 0) {
    //   setIsLoading(false);
    //   enqueueSnackbar("road map level is required", { variant: "error" });
    //   return;
    // }

    // let level_info = [];
    // if (inputs.level_info.length > 0) {
    //   level_info = inputs.level_info.map((item) => item._id);
    // }

    let find_badge_level = null;
    let find_membership_Level = membershipLevelList.find(
      (level) => level._id == inputs?.membership_level_id
    );

    if (find_membership_Level) {
      find_badge_level = find_membership_Level?.membership_level_badges.find(
        (badge) => badge._id == inputs?.badge_level_id
      );

      if (!find_badge_level) {
        setIsLoading(false);
        enqueueSnackbar("Badge Level is required", { variant: "error" });
        return;
      }
    } else {
      setIsLoading(false);
      enqueueSnackbar("Membership Level is required", { variant: "error" });
      return;
    }

    let membership_level_info = {
      _id: find_membership_Level?._id,
      title: find_membership_Level?.title,

      badge_level_info: {
        _id: find_badge_level?._id,
        title: find_badge_level?.title,
      },
    };

    const formData = new FormData();
    formData.append("title", inputs.title);

    formData.append("short_description", inputs.short_description);
    formData.append("detailed_description", inputs.detailed_description);
    formData.append("rewarded_coins", inputs.rewarded_coins);
    formData.append("coins_to_unlock", inputs.coins_to_unlock);
    formData.append("video_url", inputs.video_url);
    formData.append("promo_video", inputs.promo_video);
    formData.append("mission_duration", inputs.mission_duration);
    formData.append("mission_access_type", inputs.mission_access_type);
    formData.append("schedule_unlock_type", inputs.schedule_unlock_type);
    formData.append("replay_days", parseInt(inputs.replay_days ?? 0));
    formData.append("is_paid", inputs.is_paid ?? false);
    formData.append("type", inputs.type);

    if (inputs?.type === "quest") {
      formData.append("payment_plan", JSON.stringify(inputs.payment_plan));
      formData.append("redirect_link", inputs.redirect_link);
      formData.append("redirect_user_to", inputs.redirect_user_to ?? fasle);
      formData.append(
        "is_show_on_mission_control",
        inputs.is_show_on_mission_control
      );
      formData.append("start_time", moment(inputs.start_time).format("HH:mm"));
    }

    if (inputs?.type === "quest" && formType !== "ADD") {
      if (inputs.show_day_on_dashboard !== "none") {
        formData.append("show_day_on_dashboard", inputs.show_day_on_dashboard);
      }
    }

    if (inputs.start_date) {
      formData.append(
        "start_date",
        moment(inputs.start_date).format("YYYY-MM-DD")
      );
    } else {
      formData.append("start_date", moment().format("YYYY-MM-DD"));
    }

    formData.append("is_chat_enabled", inputs.is_chat_enabled ?? false);

    // formData.append(
    //   "complete_current_schedule",
    //   inputs.complete_current_schedule
    // );

    formData.append(
      "membership_level_info",
      JSON.stringify(membership_level_info)
    );

    // formData.append("road_map_levels", JSON.stringify(level_info));

    formData.append("status", inputs.status);
    // formData.append("mission_lavel_id", params.mission_id);
    if (file) {
      formData.append("image", inputs.image);
    }
    if (formType === "EDIT") {
      formData.append("order", inputs.order);
    }
    console.log(...formData, "formData");
    const result =
      formType === "ADD"
        ? await addMissionLevel(formData)
        : await UPdate_mission_list(formData, params.id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangeOthers = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    if (name == "is_paid") {
      setInputs((values) => ({ ...values, ["payment_plan"]: null }));
    }
  };

  const handleMembershipLevelChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, badge_level_id: "", [name]: value }));
  };

  const getRoadMapList = async () => {
    setIsLoading(true);
    const result = await level_list_api_mission();
    if (result.code == 200) {
      setLevels(result.active_road_map_levels);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getMissionDetail = async () => {
    setIsLoading(true);
    const postData = {
      mission_id: params.id ?? "",
    };
    const result = await mission_days_and_badges(postData);
    if (result.code === 200) {
      setMembershipLevelList(result?.membership_levels);
      setDays(result?.mission_schedules);

      setPaymentPlan(result?.payment_plans);

      // setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    getRoadMapList();
    if (params && params.id) {
      if (state) {
        let startTime = state?.start_time
          ? "Wed Apr 27 2022 " + " " + state?.start_time
          : new Date();

        setFormType("EDIT");
        console.log("Inputs Data", state);
        setInputs({
          ...state,
          ["level_info"]: state.road_map_levels ?? [],
          ["payment_plan"]: state.payment_plan ?? null,
          ["is_show_on_mission_control"]:
            state.is_show_on_mission_control ?? true,
          ["show_day_on_dashboard"]: !state.show_day_on_dashboard
            ? "none"
            : state.show_day_on_dashboard,

          membership_level_id: state?.membership_level_info?._id,
          badge_level_id: state?.membership_level_info?.badge_level_info?._id,
          start_time: startTime,
        });

        setOldImage(state?.image?.thumbnail_1);
      }
    }
  }, []);

  useEffect(() => {
    getMissionDetail();
  }, []);

  useEffect(() => {
    let find = membershipLevelList.find(
      (level) => level._id == inputs?.membership_level_id
    );

    if (find) {
      setBadgeLevelList(find?.membership_level_badges ?? []);
    } else {
      setBadgeLevelList([]);
    }
  }, [inputs?.membership_level_id]);
  function getPlans(plans, inputs) {
    return plans.filter((plan) =>
      inputs.is_paid ? !plan.is_plan_free : plan.is_plan_free
    );
  }

  useEffect(() => {
    if (membershipLevelList && membershipLevelList.length > 0) {
      let find = membershipLevelList.find(
        (level) => level._id == inputs?.membership_level_id
      );

      if (find) {
        setBadgeLevelList(find?.membership_level_badges ?? []);
      } else {
        setBadgeLevelList([]);
      }
    }
  }, [inputs?.badge_level_id, membershipLevelList]);

  useEffect(() => {
    console.log("notifyLevel __notifyLevel", notifyLevel);
  }, [notifyLevel]);

  if (isLoading) {
    return <PageLoader />;
  }
  console.log(inputs?.is_show_on_mission_control, "inputsinputs");

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2 className="ms-2">{`${formType === "ADD" ? "Add" : "Edit"} ${
            inputs?.type === "quest" ? "Quest" : "Mission"
          }`}</h2>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Select Mission Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="type"
                value={inputs?.type}
                label="Select Mission Type"
                onChange={handleChange}
              >
                <MenuItem value={"mission"}>Mission</MenuItem>
                <MenuItem value={"quest"}>Quest</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              fullWidth
              name="title"
              value={inputs.title}
              onChange={handleChange}
              required
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Video URL"
              variant="outlined"
              fullWidth
              type="URL"
              name="video_url"
              value={inputs.video_url}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Promo Video URL"
              variant="outlined"
              fullWidth
              type="URL"
              name="promo_video"
              value={inputs.promo_video}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label={`${
                inputs?.type === "mission" ? "Mission" : "Quest"
              } Duration (Days)`}
              variant="outlined"
              fullWidth
              type="number"
              inputProps={{
                min: 1, // Corrected placement
              }}
              name="mission_duration"
              value={inputs.mission_duration}
              onChange={handleChange}
              required
            />
          </div>
          {inputs?.type === "mission" && (
            <>
              {" "}
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Coins To Start Mission"
                  variant="outlined"
                  fullWidth
                  type="number"
                  name="coins_to_unlock"
                  value={inputs.coins_to_unlock}
                  onChange={handleChange}
                  required
                />
              </div>
            </>
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Rewarded Coins"
              variant="outlined"
              fullWidth
              type="number"
              name="rewarded_coins"
              value={inputs.rewarded_coins}
              onChange={handleChange}
              required
            />
          </div>
          {formType === "EDIT" && (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Order"
                  variant="outlined"
                  fullWidth
                  name="order"
                  value={inputs.order}
                  onChange={handleChange}
                  required
                />
              </div>
              {inputs?.type == "quest" && (
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Show Day On Dashboard
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="show_day_on_dashboard"
                      value={inputs?.show_day_on_dashboard}
                      label="Show Day On Dashboard"
                      onChange={handleChange}
                    >
                      <MenuItem value={"none"}>None</MenuItem>
                      {days.map((level, index) => (
                        <MenuItem key={index} value={level?._id}>
                          {level?.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}
            </>
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Select Membership Level
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="membership_level_id"
                value={inputs?.membership_level_id}
                label="Select Membership Level "
                onChange={handleMembershipLevelChange}
              >
                {membershipLevelList.map((membership) => {
                  return (
                    <MenuItem key={membership?._id} value={membership?._id}>
                      {membership?.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          {inputs?.membership_level_id && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">
                  Select Badge Level
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="badge_level_id"
                  value={inputs?.badge_level_id}
                  label="Select Badge Level "
                  onChange={handleMembershipLevelChange}
                >
                  {badgeLevelList.map((badgeLevel) => {
                    return (
                      <MenuItem key={badgeLevel?._id} value={badgeLevel?._id}>
                        {badgeLevel?.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Schedule Unlock Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="schedule_unlock_type"
                value={inputs.schedule_unlock_type}
                label="Schedule Unlock Type"
                onChange={handleChange}
              >
                <MenuItem value="at_once">At Once</MenuItem>
                <MenuItem value="coins_based">Coins Based</MenuItem>
                <MenuItem value="drip_sequence">Drip Sequence</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                value={inputs.status}
                label="Status"
                onChange={handleChange}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs?.type === "mission" ? (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">
                    Mission Unlock User Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="mission_access_type"
                    value={inputs.mission_access_type}
                    onChange={handleChange}
                    label="Mission Unlock User Type"
                    required
                  >
                    <MenuItem value="both">Both</MenuItem>
                    <MenuItem value="free">Free</MenuItem>
                    <MenuItem value="paid">Paid</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </>
          ) : (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Start Date"
                    name="start_date"
                    value={inputs.start_date || moment()}
                    format="YYYY-MM-DD"
                    inputFormat="dd-MM-yyyy"
                    onChange={(e) => handleChangeDateRange(e, "start_date")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="inputs-fields"
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    ampm={true}
                    openTo="hours"
                    views={["hours", "minutes"]}
                    inputFormat="hh:mm a"
                    mask="__:__ _M"
                    label="Start Time"
                    value={inputs?.start_time}
                    onChange={(newValue) => {
                      setInputs((prevState) => ({
                        ...prevState,
                        ["start_time"]: newValue,
                      }));
                    }}
                    renderInput={(params) => <TextField {...params} required />}
                  />
                </LocalizationProvider>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Replay Days"
                  variant="outlined"
                  fullWidth
                  type="number"
                  name="replay_days"
                  value={inputs.replay_days}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Quest Type*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="is_paid"
                    value={inputs.is_paid}
                    label="Quest Type*"
                    onChange={handleChange}
                    required
                  >
                    <MenuItem value={true}>Paid</MenuItem>
                    <MenuItem value={false}>Free</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Quest Show On MissionControl
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="is_show_on_mission_control"
                    value={inputs.is_show_on_mission_control ?? true}
                    label="Quest Show On MissionControl"
                    onChange={handleChange}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {inputs.is_paid && (
                <>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <FormControl fullWidth required>
                      <InputLabel id="demo-simple-select-label">
                        Enable Redirect User
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="redirect_user_to"
                        value={inputs.redirect_user_to}
                        label=" Enable Redirect User"
                        onChange={handleChange}
                        required
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  {inputs.redirect_user_to && (
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                      <TextField
                        id="outlined-basic"
                        label="Redirect Link"
                        variant="outlined"
                        fullWidth
                        type="URL"
                        required
                        name="redirect_link"
                        value={inputs.redirect_link}
                        onChange={handleChange}
                      />
                    </div>
                  )}

                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <MUIAutocomplete
                      inputLabel="Select Plan"
                      selectedOption={inputs.payment_plan ?? null}
                      setSelectedOption={(value) => {
                        handleChangeOthers("payment_plan", value);
                      }}
                      optionsList={getPlans(paymentPlans, inputs)}
                      name="plan_title"
                      autoComplete="new-password"
                      required={inputs.redirect_user_to ? false : true}
                    />
                  </div>
                </>
              )}
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Is Chat Enabled*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="is_chat_enabled"
                    value={inputs.is_chat_enabled}
                    label="Is Chat Enabled"
                    onChange={handleChange}
                    required
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </>
          )}
          {inputs.schedule_unlock_type === "drip_sequence" && (
            <>
              {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Required Completing Current Schedule to Start Next
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="complete_current_schedule"
                    value={inputs.complete_current_schedule}
                    label="Required Completing Current Schedule to Start Next"
                    onChange={handleChange}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </FormControl>
              </div> */}
            </>
          )}
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Upload Image *</p>
                <FormHelperText className="pt-0">
                  Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {file ? (
                  <img src={file} height="50" />
                ) : oldImage ? (
                  <img src={s3baseUrl + oldImage} height="50" />
                ) : (
                  ""
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="image"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.image?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.image?.name}</p>
            )}
          </div>
          <div className="col-12 mt-3">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Short Description "
                multiline
                required
                rows={4}
                name="short_description"
                value={inputs.short_description}
                onChange={handleChange}
              />
            </FormControl>
          </div>
          <div className="col-12 mt-4">
            <h4>Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="detailed_description"
              editorHeight={320}
            />
          </div>
          <div
            className="text-end mt-4"
            id={formType == "ADD" ? "" : "fixedbutton"}
          >
            <button className="small-contained-button">
              {formType == "ADD" ? "Submit" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
