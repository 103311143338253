import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { answers_detail_by_user_and_question_api } from "src/DAL/GeneralQuestions/GeneralQuestions";
import AnswersList from "src/components/GeneralComponents/GeneralQuestions/AnswersList";
import jsPDF from "jspdf";
import ReactDOMServer from "react-dom/server";
import { appLogoIcon } from "src/assets";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import PageLoader from "src/components/GeneralComponents/PageLoader";
import { show_proper_words } from "src/utils/constant";

export default function QuestionsAnswers() {
  const { search, pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isDonwloadingPdf, setIsDonwloadingPdf] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [userInfo, setUserInfo] = useState({});
  const [moduleInfo, setModuleInfo] = useState({});
  const [questions, setQuestions] = useState([]);
  const main_route = `${pathname.split("/")[1]}`;
  const created_for_id = new URLSearchParams(search).get("created_for_id");
  const member_id = new URLSearchParams(search).get("member_id");
  const created_for = new URLSearchParams(search).get("created_for");
  const check_user = new URLSearchParams(search).get("check_user");

  const members_list = window.location.pathname.includes(
    "/member/questions-answers"
  );
  const members_answers_list = window.location.pathname.includes(
    "/members-answers-list"
  );
  const delegate_answers_list = window.location.pathname.includes(
    "/delegate-answers-list"
  );
  const bookings_answers_list = window.location.pathname.includes("/bookings");
  const quarters_list = window.location.pathname.includes("/quarters");
  const ninety_day_answers =
    window.location.pathname.includes("/90-day-answers");
  const consultant_answers_list = window.location.pathname.includes(
    "/consultant/questions-answers"
  );

  const get_questions_list = async () => {
    let postData = {
      created_for_id: created_for_id,
      member_id: member_id,
      created_for: created_for,
      check_user: check_user ? check_user : false,
    };
    const result = await answers_detail_by_user_and_question_api(postData);
    if (result.code === 200) {
      let data = result.questionnaire.map((question) => {
        return {
          ...question,
          isExpanded: true,
        };
      });
      setUserInfo(result.member);
      setModuleInfo(result.details);
      setQuestions(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  function convertHtmlToString(html) {
    return ReactDOMServer.renderToString(html);
  }

  const handleGeneratePdf = () => {
    setIsDonwloadingPdf(true);
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });

    const html = (
      <div className="text-dark generating-pdf">
        <div className="d-flex justify-content-center">
          <img src={appLogoIcon} alt="" srcset="" className="logo-dd" />
        </div>
        <div className="text-center font-weight-bold mb-4">
          {userInfo.first_name +
            " " +
            userInfo.last_name +
            " (" +
            userInfo.email +
            ")"}
        </div>
        {questions.map((question, index) => {
          let find_answer = question.answer;
          return (
            <>
              <h3
                className={`question-statement-for-pdf font-weight-bold ${
                  index == 0 ? "mt-0" : "mt-2"
                }`}
              >
                Question Statement:
              </h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: question.question_statement,
                }}
                className="question-statement-for-pdf-text"
              />
              <h3 className="question-statement-for-pdf font-weight-bold">
                Answer:
              </h3>
              {question.question_type == "checkbox" ? (
                <div class="control checkbox-buttons mt-1">
                  {question?.options.map((option) => {
                    let checkbox_answer = find_answer?.answer_statement?.find(
                      (old_answer) => old_answer == option
                    );
                    return (
                      <div className="d-flex">
                        <input
                          type="checkbox"
                          id="vehicle1"
                          checked={checkbox_answer ? true : false}
                        />
                        <span>{option}</span>
                        <br />
                      </div>
                    );
                  })}
                </div>
              ) : question.question_type == "scaling" ? (
                <div className="scaling-questions d-flex mt-3">
                  {[...Array(question.scaling_max)].map((_, index) => {
                    return (
                      <div
                        className={`scaling-count ${
                          index < find_answer?.answer_statement
                            ? "scaling-count-selected"
                            : ""
                        }`}
                      >
                        <p>{index + 1}</p>
                      </div>
                    );
                  })}
                </div>
              ) : question.question_type == "mcq" ? (
                <div class="control radio-buttons mt-1">
                  {question?.options.map((option) => {
                    return (
                      <div className="d-flex">
                        <input
                          type="radio"
                          checked={find_answer?.answer_statement == option}
                        />
                        <span>{option}</span>
                        <br />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="text-area-answer">
                  <p>
                    {find_answer?.answer_statement
                      ? find_answer?.answer_statement
                      : "No Answer Given"}
                  </p>
                </div>
              )}
            </>
          );
        })}
      </div>
    );
    const html2 = convertHtmlToString(html);
    doc.html(html2, {
      margin: [15, 25, 20, 20],
      autoPaging: "text",
      callback: (doc) => {
        doc.save();
        setIsDonwloadingPdf(false);
      },
    });
  };

  let breadCrumbQuarter = [
    {
      title: "Quarters",
      navigation: `/quarters`,
      active: false,
    },
    {
      title: moduleInfo.title,
      navigation: `/quarters/quarter-questions/answers?created_for_id=${moduleInfo._id}`,
      active: false,
    },
    {
      title: "Quarters Questions Detail",
      active: true,
    },
  ];

  let breadCrumbPage = [
    {
      title: `${show_proper_words(main_route)}`,
      navigation: `/${main_route}`,
    },
    {
      title: `Questions Answers  (${moduleInfo.sale_page_title})`,
      navigation: `/${main_route}/answers?created_for_id=${moduleInfo._id}`,
    },
    {
      title: "Quarters Questions Detail",
      active: true,
    },
  ];

  let breadCrumb90Day = [
    {
      title: "90 Day Answers",
      navigation: `/90-day-answers`,
      active: false,
    },
    {
      title: "90 Day Questions Detail",
      active: true,
    },
  ];

  let breadCrumbMenu = [
    {
      title: members_answers_list
        ? "Members Answers List"
        : delegate_answers_list
        ? "Delegate Answers List"
        : bookings_answers_list
        ? "Booking"
        : consultant_answers_list
        ? "Delegate"
        : members_list
        ? "Members"
        : "",
      navigation: members_answers_list
        ? `/members-answers-list`
        : delegate_answers_list
        ? `/delegate-answers-list`
        : bookings_answers_list
        ? `/bookings`
        : consultant_answers_list
        ? `/consultant`
        : members_list
        ? `/member`
        : ``,
      active: false,
    },
    {
      title:
        userInfo.first_name +
        " " +
        userInfo.last_name +
        " (" +
        userInfo.email +
        ")",
      active: true,
    },
  ];

  useEffect(() => {
    get_questions_list();
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 mb-4">
          <span>
            <ActiveLastBreadcrumb
              breadCrumbMenu={
                quarters_list
                  ? breadCrumbQuarter
                  : ninety_day_answers
                  ? breadCrumb90Day
                  : created_for === "page"
                  ? breadCrumbPage
                  : breadCrumbMenu
              }
            />
          </span>
        </div>
        <div className="col-6 ">
          <h2>Question Answer Detail</h2>
        </div>
        <div className="col-6 text-end">
          <button
            className="small-contained-button"
            onClick={handleGeneratePdf}
            disabled={isDonwloadingPdf}
          >
            {isDonwloadingPdf ? "Downloading..." : "Download PDF"}
          </button>
        </div>
      </div>
      <AnswersList questions={questions} />
    </div>
  );
}
