import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { useEffect } from "react";
import {
  _get_book_a_call_sale_pages,
  _mission_setting_list,
} from "src/DAL/MissionApi/MissionApiNew";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import GeneralCkeditor from "../../components/GeneralComponents/GeneralCkeditor";
import { styled } from "@mui/material/styles";
import { uploadImageOns3 } from "src/DAL/commonApi/commonApi";
import { s3baseUrl } from "src/config/config";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const Input = styled("input")({
  display: "none",
});

export default function AddOrUpdateChallengesSettings() {
  const navigate = useNavigate();
  const params = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [rewardOldImage, setrewardOldImage] = useState();
  const [RewardImage, setRewardImage] = useState();
  const [salePageList, setSalePageList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loadFirstTime, setLoadFirstTime] = useState(true);

  const [inputs, setInputs] = useState({
    schedule_heading: "",
    start_mission_button_text: "",
    level_not_match_description: "",
    start_mission_description: "",
    cancel_button_text: "",
    unlock_date_statement: "",
    lock_description: "",
    reward_feed_image: "",
    allow_user_to_create_reward_feed: false,
    reward_post_button_text: "",
    show_gif_image_on_reward_feed: false,
    allow_user_to_complete_old_schedule: false,
    missed_schedule_description: "",
    show_mission_on_boarding_questions: false,
    mission_complete_video: "",
    mission_complete_video_for_missed_schedule: "",
    complete_mission_description: "",
    mission_report_content: "",
    show_book_call_button: false,
    book_call_button_text: "",
    show_button_after_days: 1,
    button_visible_for_days: 1,
    show_membership_button_after_days: 1,
    membership_button_visible_for_days: 1,
    book_a_call_page: null,
    allow_user_to_start_mission: true,
    is_show_upgrade_button_on_complete: false,
    upgrade_button_text: "",
    upgrade_button_url: "",
    upgrade_for: "membership",
    user_unable_to_start_mission_discription: "",
    show_permission_checkbox: false,
    permission_checkbox_content: "",
    free_quest_purchase_button_text: "",
    paid_quest_purchase_button_text: "",
    already_purchase_button_text: "",
  });

  const capitalizeFirstLetter = (word) =>
    word.charAt(0).toUpperCase() + word.slice(1);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !inputs?.allow_user_to_start_mission &&
      inputs?.user_unable_to_start_mission_discription === ""
    ) {
      enqueueSnackbar("User Unable To Start Mission Description is required!", {
        variant: "error",
      });
      return;
    }

    if (inputs?.show_book_call_button && !inputs?.book_a_call_page) {
      enqueueSnackbar("Book A Call Page is required!", { variant: "error" });
      return;
    }

    setIsLoading(true);
    let postData = {
      content_settings: { ...inputs },
    };
    if ((rewardOldImage || RewardImage) && inputs.reward_feed_image) {
      postData.content_settings.reward_feed_image = inputs.reward_feed_image;
    }

    const result = await _mission_setting_list(postData, params.id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate("/mission");
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeOthers = (event, name) => {
    setInputs((values) => ({ ...values, [name]: event }));
  };

  const agreementHeaderLogoHandler = async (e) => {
    setRewardImage(URL.createObjectURL(e.target.files[0]));
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    formData.append("width", "600");

    const imageUpload = await uploadImageOns3(formData);
    if (imageUpload.code == 200) {
      setInputs({
        ...inputs,
        ["reward_feed_image"]: imageUpload.image_path,
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const getSalePageDetail = async () => {
    if (loadFirstTime) {
      setIsLoading(true);
    }

    const result = await _get_book_a_call_sale_pages({
      search: searchText,
    });
    console.log("result  ___get_book_a_call_sale_pages", result);
    if (result.code === 200) {
      console.log(result?.sale_pages);
      const pages_list = result?.sale_pages.map((item, index) => {});

      setSalePageList(result?.sale_pages);
      setIsLoading(false);
      setLoadFirstTime(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setLoadFirstTime(false);
    }
  };

  useEffect(() => {
    if (loadFirstTime) {
      getSalePageDetail();
    }
    if (params && params.id) {
      console.log("state __state", state);
      if (
        state &&
        state.content_settings &&
        Object.keys(state.content_settings).length > 0
      ) {
        setInputs({ ...state.content_settings });
        setInputs((values) => ({
          ...values,
          ["allow_user_to_create_reward_feed"]:
            state.content_settings.allow_user_to_create_reward_feed ?? false,
        }));
        setInputs((values) => ({
          ...values,
          ["show_gif_image_on_reward_feed"]:
            state.content_settings.show_gif_image_on_reward_feed ?? false,
          ["allow_user_to_complete_old_schedule"]:
            state.content_settings.allow_user_to_complete_old_schedule ?? false,
          ["show_mission_on_boarding_questions"]:
            state.content_settings.show_mission_on_boarding_questions ?? false,

          ["show_book_call_button"]:
            state.content_settings?.show_book_call_button ?? false,

          ["is_show_upgrade_button_on_complete"]:
            state.content_settings?.is_show_upgrade_button_on_complete ?? false,
          ["upgrade_for"]: state.content_settings?.upgrade_for ?? "membership",
          ["allow_user_to_start_mission"]:
            state.content_settings?.allow_user_to_start_mission ?? true,
          ["user_unable_to_start_mission_discription"]:
            state.content_settings?.user_unable_to_start_mission_discription ??
            "",
        }));

        setrewardOldImage(state?.content_settings?.reward_feed_image);
      }
    }
  }, []);

  useEffect(() => {
    if (!loadFirstTime) {
      const timeoutId = setTimeout(() => {
        getSalePageDetail();
      }, 500);

      return () => clearTimeout(timeoutId);
    }
  }, [searchText]);

  useEffect(() => {
    console.log("inputs   ___inputs", inputs);
  }, [inputs]);

  let breadCrumbMenu = [
    {
      title: `${capitalizeFirstLetter(state.type)} ${
        state ? " (" + state?.title + ") " : ""
      }`,
      navigation: `/mission`,
      active: false,
    },
    {
      title: `${capitalizeFirstLetter(state.type)} Settings`,
      navigation: ``,
      active: true,
    },
  ];
  console.log(state.type, "statestatestate");
  if (isLoading) {
    return <PageLoader />;
  }
  console.log(salePageList, "salePageListsalePageList");
  return (
    <div className="container">
      <div className="col-12 mb-3 d-flex">
        <span className="mt-2">
          <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
        </span>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Schedule Heading"
              variant="outlined"
              fullWidth
              name="schedule_heading"
              value={inputs.schedule_heading}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Lock Description"
              variant="outlined"
              fullWidth
              name="lock_description"
              value={inputs.lock_description}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label={`Start ${capitalizeFirstLetter(state.type)} Button Text`}
              variant="outlined"
              fullWidth
              name="start_mission_button_text"
              value={inputs.start_mission_button_text}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-6 mt-3">
            <TextField
              className="text-muted"
              id="outlined-basic"
              label="Cancel Button Text"
              variant="outlined"
              fullWidth
              name="cancel_button_text"
              onChange={handleChange}
              value={inputs.cancel_button_text}
              required={true}
            />
          </div>{" "}
          {state?.type == "quest" && (
            <>
              <div className="col-6 mt-3">
                <TextField
                  className="text-muted"
                  id="outlined-basic"
                  label="Free Quest Purchase Button Text"
                  variant="outlined"
                  fullWidth
                  name="free_quest_purchase_button_text"
                  onChange={handleChange}
                  value={inputs.free_quest_purchase_button_text}
                  required={true}
                />
              </div>{" "}
              <div className="col-6 mt-3">
                <TextField
                  className="text-muted"
                  id="outlined-basic"
                  label="Paid Quest Purchase Button Text"
                  variant="outlined"
                  fullWidth
                  name="paid_quest_purchase_button_text"
                  onChange={handleChange}
                  value={inputs.paid_quest_purchase_button_text}
                  required={true}
                />
              </div>{" "}
              <div className="col-6 mt-3">
                <TextField
                  className="text-muted"
                  id="outlined-basic"
                  label="Already Purchase Button Text"
                  variant="outlined"
                  fullWidth
                  name="already_purchase_button_text"
                  onChange={handleChange}
                  value={inputs.already_purchase_button_text}
                  required={true}
                />
              </div>
            </>
          )}
          <div className="col-6 mt-3">
            <TextField
              className="text-muted"
              id="outlined-basic"
              label="Unlock Schedule Date Statment"
              variant="outlined"
              fullWidth
              name="unlock_date_statement"
              onChange={handleChange}
              value={inputs.unlock_date_statement}
              required={true}
            />
          </div>
          <div className="col-6 mt-3">
            <TextField
              className="text-muted"
              id="outlined-basic"
              label="Reward Post Button Text"
              variant="outlined"
              fullWidth
              name="reward_post_button_text"
              onChange={handleChange}
              value={inputs.reward_post_button_text}
            />
          </div>
          <div className="col-6 mt-3">
            <TextField
              className="text-muted"
              id="outlined-basic"
              label={`${capitalizeFirstLetter(
                state.type
              )} Complete Video Url For Complete All Schedules`}
              variant="outlined"
              fullWidth
              type="url"
              name="mission_complete_video"
              onChange={handleChange}
              value={inputs?.mission_complete_video}
            />
          </div>
          <div className="col-6 mt-3">
            <TextField
              className="text-muted"
              id="outlined-basic"
              label={`${capitalizeFirstLetter(
                state.type
              )} Complete Video Url For Missed Schedules`}
              variant="outlined"
              fullWidth
              type="url"
              name="mission_complete_video_for_missed_schedule"
              onChange={handleChange}
              value={inputs?.mission_complete_video_for_missed_schedule}
            />
          </div>
          <div className="col-6 mt-3">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Allow User to Create Reward Post{" "}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="allow_user_to_create_reward_feed"
                value={inputs.allow_user_to_create_reward_feed}
                label="Allow User to Create Reward Feed"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-6 mt-3">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Show GIF Image on Reward Feed
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="show_gif_image_on_reward_feed"
                value={inputs.show_gif_image_on_reward_feed}
                label="Show  GIF Image on Reward Feed"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-6 mt-3">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Allow User to Complete Old Schedule
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="allow_user_to_complete_old_schedule"
                value={inputs.allow_user_to_complete_old_schedule}
                label="Allow User to Complete Old Schedule"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-6 mt-3">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Show {capitalizeFirstLetter(state.type)} Onboarding Questions
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="show_mission_on_boarding_questions"
                value={inputs.show_mission_on_boarding_questions}
                label={`Show ${capitalizeFirstLetter(
                  state.type
                )} Onboarding Questions`}
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          {/* <div className="col-6 mt-3">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Is Show Book A Call Button
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="show_book_call_button"
                value={inputs?.show_book_call_button}
                label="Is Show Book A Call Button"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>

          {inputs?.show_book_call_button && (
            <>
              <div className="col-6 mt-3">
                <TextField
                  className="text-muted"
                  id="outlined-basic"
                  label="Book Call Button Text"
                  variant="outlined"
                  fullWidth
                  name="book_call_button_text"
                  onChange={handleChange}
                  value={inputs?.book_call_button_text}
                  required
                />
              </div>

              <div className="col-6 mt-3">
                <TextField
                  className="text-muted"
                  id="outlined-basic"
                  type="number"
                  label="Show Button After Mission Start Days"
                  variant="outlined"
                  fullWidth
                  name="show_button_after_days"
                  onChange={handleChange}
                  value={inputs?.show_button_after_days}
                  required
                />
              </div>
              <div className="col-6 mt-3">
                <TextField
                  className="text-muted"
                  id="outlined-basic"
                  type="number"
                  label="Button Visible For Days"
                  variant="outlined"
                  fullWidth
                  name="button_visible_for_days"
                  onChange={handleChange}
                  value={inputs?.button_visible_for_days}
                  required
                />
              </div>

              <div className="col-6 mt-3">
                <MUIAutocomplete
                  inputLabel="Book A Call Page"
                  selectedOption={inputs?.book_a_call_page}
                  setSelectedOption={(e) => {
                    handleChangeOthers(e, "book_a_call_page");
                  }}
                  optionsList={salePageList}
                  autoComplete="new-password"
                  name="sale_page_title"
                  setCustomSearch={setSearchText}
                  required
                />
              </div>
            </>
          )} */}
          {/* <div className="col-6 mt-3">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Is Show Upgrade Button
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_show_upgrade_button_on_complete"
                value={inputs?.is_show_upgrade_button_on_complete}
                label="Is Show Upgrade Button"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>

          {inputs?.is_show_upgrade_button_on_complete && (
            <>
              <div className="col-6 mt-3">
                <TextField
                  className="text-muted"
                  id="outlined-basic"
                  label="Upgrade Button Text"
                  variant="outlined"
                  fullWidth
                  name="upgrade_button_text"
                  onChange={handleChange}
                  value={inputs?.upgrade_button_text}
                  required
                />
              </div>

              <div className="col-6 mt-3">
                <TextField
                  className="text-muted"
                  id="outlined-basic"
                  label="Upgrade Button Url"
                  variant="outlined"
                  fullWidth
                  type="url"
                  name="upgrade_button_url"
                  onChange={handleChange}
                  value={inputs?.upgrade_button_url}
                  required
                />
              </div>
              <div className="col-6 mt-3">
                <TextField
                  className="text-muted"
                  id="outlined-basic"
                  type="number"
                  label="Show Upgrade Button After Mission Start Days"
                  variant="outlined"
                  fullWidth
                  name="show_membership_button_after_days"
                  onChange={handleChange}
                  value={inputs?.show_membership_button_after_days}
                  required
                />
              </div>
              <div className="col-6 mt-3">
                <TextField
                  className="text-muted"
                  id="outlined-basic"
                  type="number"
                  label="Upgrade Button Visible For Days"
                  variant="outlined"
                  fullWidth
                  name="membership_button_visible_for_days"
                  onChange={handleChange}
                  value={inputs?.membership_button_visible_for_days}
                  required
                />
              </div>

              <div className="col-6 mt-3">
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">
                    Upgrade For
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="upgrade_for"
                    value={inputs?.upgrade_for}
                    label="Upgrade For"
                    onChange={handleChange}
                  >
                    <MenuItem value="membership">Membership</MenuItem>
                    <MenuItem value="sale_page">Sale Page</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </>
          )} */}
          <div className="col-6 mt-3">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Allow User To Start {capitalizeFirstLetter(state.type)}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="allow_user_to_start_mission"
                value={inputs?.allow_user_to_start_mission}
                label={`Allow User To Start ${capitalizeFirstLetter(
                  state.type
                )}`}
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-6 mt-3">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Show Access Permission to Client to view content?
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="show_permission_checkbox"
                value={inputs?.show_permission_checkbox}
                label="Show Access Permission to Client to view content?"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs?.show_permission_checkbox && (
            <div className="col-12 mt-3">
              <FormControl fullWidth>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Statement for Permission to view Content."
                  multiline
                  required
                  rows={4}
                  name="permission_checkbox_content"
                  value={inputs.permission_checkbox_content}
                  onChange={handleChange}
                />
              </FormControl>
            </div>
          )}
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p>Reward GIF Image</p>
                <FormHelperText className="pt-0">
                  (Recommended Size 1000 X 600) (gif)
                </FormHelperText>
              </div>
              <div className="col-2">
                {RewardImage ? (
                  <img src={RewardImage} height="50" />
                ) : (
                  rewardOldImage && (
                    <img src={s3baseUrl + rewardOldImage} height="50" />
                  )
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="reward_feed_image">
                  <Input
                    accept="image/gif"
                    id="reward_feed_image"
                    multiple
                    type="file"
                    name="reward_feed_image"
                    onChange={agreementHeaderLogoHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.reward_feed_image?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">
                {inputs?.reward_feed_image?.name}
              </p>
            )}
          </div>
          <div className="col-12 ">
            <p className="tracking_code_heading text-white">
              Start {capitalizeFirstLetter(state.type)} Description
            </p>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="start_mission_description"
              editorHeight={310}
            />
          </div>
          <div className="col-12 ">
            <p className="tracking_code_heading text-white">
              Missed Schedule Description
            </p>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="missed_schedule_description"
              editorHeight={310}
            />
          </div>
          <div className="col-12 ">
            <p className="tracking_code_heading text-white">
              Complete {capitalizeFirstLetter(state.type)} Description
            </p>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="complete_mission_description"
              editorHeight={310}
            />
          </div>
          <div className="col-12 ">
            <p className="tracking_code_heading text-white">
              {capitalizeFirstLetter(state.type)} Report Content
            </p>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="mission_report_content"
              editorHeight={310}
            />
          </div>
          {!inputs?.allow_user_to_start_mission && (
            <div className="col-12 ">
              <p className="tracking_code_heading text-white">
                User Unable To Start {capitalizeFirstLetter(state.type)}{" "}
                Description *
              </p>
              <GeneralCkeditor
                setInputs={setInputs}
                inputs={inputs}
                name="user_unable_to_start_mission_discription"
                editorHeight={310}
              />
            </div>
          )}
          <div className="text-end mt-4" id="fixedbutton">
            <button className="small-contained-button">Update</button>
          </div>
        </div>
      </form>
    </div>
  );
}
