import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { s3baseUrl } from "src/config/config";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  all_mentorship_video_list_api,
  delete_mentorship_video_api,
  list_all_mediatation_category_api,
} from "src/DAL/Mentorship/MentorshipApi";
import moment from "moment";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const TABLE_HEAD = [
  { id: "action", label: "Action", type: "action" },
  { id: "number", label: "#", type: "number" },
  { id: "title", label: " Title" },
  { id: "table_avatar", label: "Image", type: "thumbnail" },
  { id: "category", label: "Category" },
  // { id: "start_days", label: "Start Days" },
  // { id: "end_days", label: "End Days" },
  { id: "is_locked_status", label: "Is Locked ?" },
  { id: "is_defaults", label: "Is Default" },
  { id: "order", label: "Order" },
  { id: "status", label: "Status", type: "row_status" },
];

export default function MentorshipVideos() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [meditationData, setMeditationData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);

  const mentorship_video_list = async (value) => {
    setIsLoading(true);
    let postData = {
      category: value ? value._id : "",
    };
    const result = await all_mentorship_video_list_api(postData);
    if (result.code === 200) {
      const data = result.mentorship_video.map((mentorship) => {
        let category = "N/A";
        if (mentorship.mentorship_category) {
          category = mentorship.mentorship_category?.title;
        }
        return {
          ...mentorship,
          table_avatar: {
            src: s3baseUrl + mentorship.image.thumbnail_2,
            alt: mentorship?.title,
          },
          is_defaults: mentorship.is_default ? "Yes" : "No",
          is_locked_status: mentorship.is_locked ? "Yes" : "No",
          category,
        };
      });
      setMeditationData(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getCategoriesListing = async () => {
    const result = await list_all_mediatation_category_api();
    if (result.code == 200) {
      setCategoriesData(result.mentorship_category);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleEdit = (value) => {
    navigate(`/mentorship-video/edit-video/${value.mentorship_video_slug}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await delete_mentorship_video_api(
      deleteDoc.mentorship_video_slug
    );
    if (result.code === 200) {
      mentorship_video_list();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSelected = (value) => {
    setSelectedCategory(value);
    localStorage.setItem("mentorship_videos_filter", JSON.stringify(value));
    mentorship_video_list(value);
  };

  const handleNavigate = () => {
    navigate(`/mentorship-video/add-video`);
  };

  useEffect(() => {
    let selected_category = null;
    let filter = localStorage.getItem("mentorship_videos_filter");
    if (filter) {
      selected_category = JSON.parse(filter);
      setSelectedCategory(selected_category);
    }
    mentorship_video_list(selected_category);
  }, []);

  useEffect(() => {
    getCategoriesListing();
  }, []);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container-fluid">
        <div className="row mb-4 mt-2">
          <div className="col-lg-7 col-sm-12">
            <h2>Bonus Content Videos</h2>
          </div>
          <div className="col-lg-5 col-sm-12 mt-3 mt-lg-0 text-end">
            <div className="d-flex justify-content-end">
              <MUIAutocomplete
                inputLabel="Select Category"
                selectedOption={selectedCategory}
                setSelectedOption={handleSelected}
                optionsList={categoriesData}
                name="title"
                required
                size="small"
              />
              <button
                onClick={handleNavigate}
                className="ms-3 small-contained-button"
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                Add Video
              </button>
            </div>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={meditationData}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          pagePagination={true}
        />
      </div>
    </>
  );
}
