import React, { useEffect, useState } from "react";
import Message from "src/components/Message";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { delegateQuestionsListing } from "src/DAL/consultant/consultant";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import PageLoader from "src/components/GeneralComponents/PageLoader";

function DelegateQuestionsListView() {
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState();
  const [goalStatementQuestions, setGoalStatementQuestions] = useState([]);

  const getGoalStatementList = async () => {
    const result = await delegateQuestionsListing(params.id);
    if (result.code === 200) {
      const get_answer = (question_id) => {
        return result?.answers.filter((answer) => answer._id == question_id)[0]
          ?.answer_statement;
      };

      const data = result.delegate_ninety_day_question.map(
        (question, index) => {
          return {
            ...question,
            answer: get_answer(question._id),
          };
        }
      );
      setUserData(result.delegate);
      setGoalStatementQuestions(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  let breadCrumbMenu = [
    {
      title: "Delegates",
      navigation: -1,
      active: false,
    },
    {
      title: userData
        ? userData?.first_name +
          userData?.last_name +
          " (" +
          userData?.email +
          ")"
        : "Delegate 90 Day Questions",
      active: true,
    },
  ];

  useEffect(() => {
    getGoalStatementList();
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="col-12 mb-3">
        <span>
          <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
        </span>
      </div>
      <div className="col-12 d-flex">
        <h3>Delegate 90 Day Questions</h3>
      </div>
      <br></br>
      {goalStatementQuestions.length > 0 && (
        <div className="row ">
          <div className="col-12">
            <Message data={goalStatementQuestions} />
          </div>
        </div>
      )}
    </div>
  );
}

export default DelegateQuestionsListView;
