import React from "react";
import { Routes, Route } from "react-router-dom";
import ConsultantCalender from "src/pages/ConsultantEvents/ConsultantCalender";
import MemberCalender from "src/pages/MembersList/MemberCalender";
import MemberProfile from "src/pages/MembersList/MemberProfile";
import ManageNavItemsAccess from "src/pages/NavItems/ManageNavItemsAccess";
import AddOrUpdateAffiliateTransactions from "src/pages/Payments/AffiliateTransactions/AddOrUpdateAffiliateTransactions";
import AddConsultant from "src/pages/consultant/AddConsultant";
import CallTypeConfig from "src/pages/consultant/CallTypeConfig";
import ConsultantGoalStatementList from "src/pages/consultant/ConsultantGoalStatement";
import ConsultantGroupsList from "src/pages/consultant/ConsultantGroups";
import ConsultantMemberSubscribersList from "src/pages/consultant/ConsultantMemberSubscribers";
import DelegateMembers from "src/pages/consultant/DelegateMembers";
import ManageEventAccess from "src/pages/consultant/ManageEventAccess";
import ManageMainPorTalProgrammeAccess from "src/pages/consultant/ManageMainPorTalProgrammeAccess";
import MembersListConsultantNew from "src/pages/consultant/MemberListNew";
import PayCommission from "src/pages/consultant/PayCommission";
import ConsultantPodsList from "src/pages/consultant/PodsListing";
import ConsultantList from "src/pages/consultant/consultantList";
import ManageDelegateProgrammeAccess from "src/pages/consultant/ManageProgrammeAccess";
import ManageTemplateAccess from "src/pages/consultant/ManageTemplateAccess";
import QuestionsAnswersForUser from "src/pages/Questions/QuestionsAnswersForUser";
import QuestionsAnswers from "src/pages/Questions/QuestionsAnswers";
import MemberGroupsList from "src/pages/Members/MembersGroupList";
import DelegateToDoTrackerList from "src/DAL/delegateGroups/DelegateToDoTrackerList";
import DelegateAssessmentStudy from "src/pages/consultant/DelegateAssessmentStudy";
import DelegateQuestionsListView from "src/pages/consultant/Delegate90DaysQuestionDropDown";
import ConsultantRecordings from "src/pages/consultant/ConsultantRecordings";
import UpdateConsultantGeneralSetting from "src/pages/ConsultantPortal/GeneralSetting";
import TrackingHistory from "src/components/TracKingHistory/TrackingHistory";
import ChangeConsultantPassword from "src/pages/consultant/Changepassword";
import CommissionHistoryTabs from "src/pages/consultant/CommissionHistoryTabs";
import EditConsultant from "src/pages/consultant/EditConsultant";
import ConsultantRecordingDetail from "src/pages/consultant/RecordingDetail";
import ConsultantGoalstatementDetail from "src/pages/consultant/ConsultantGoalstatementDetail";
import ConsultantGroupDetail from "src/pages/consultant/ConsultantGroupDetail";
import ConsultantPodsDetail from "src/pages/consultant/PodsDetail";
import ManageCommissionAccess from "src/pages/consultant/ManageCommissionAccess";
import TeamGeneralSettingsSpacificData from "src/pages/DelegatesTeamSettings/TeamGeneralSettingsSpacificData";
import ManageEventTemplateAccess from "src/pages/consultant/ManageEventTemplateAccess";
import RecantAssociatedTransactions from "src/pages/consultant/RecantAssociatedTransactions";
import ManageMissionAccess from "src/pages/consultant/ManageMissionAccess";

const MarketingRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ConsultantList
            type={"marketing"}
            heading={"Marketing Team"}
            start_route={"/marketing"}
            csv_type={"Marketing-Team"}
          />
        }
      />
      <Route
        path="/manage-access/:id"
        element={
          <TeamGeneralSettingsSpacificData title={"Manage  Access Settings"} />
        }
      />
      <Route path="/mission-access/:id" element={<ManageMissionAccess />} />{" "}
      <Route path="/pay-commission" element={<PayCommission />} />
      <Route
        path="/subscriber/:id"
        element={<ConsultantMemberSubscribersList />}
      />
      <Route
        path="/programme-access/:id"
        element={<ManageDelegateProgrammeAccess />}
      />{" "}
      <Route
        path="/main-portalprogramme-access/:id"
        element={<ManageMainPorTalProgrammeAccess />}
      />
      <Route
        path="/template-event-access/:id"
        element={<ManageEventTemplateAccess type={"Marketing Team"} />}
      />
      <Route
        path="/event-access/:id"
        element={<ManageEventAccess heading={"Marketing Team"} />}
      />
      <Route path="/call-type-configuration/:id" element={<CallTypeConfig />} />
      <Route path="/pods-list/:id" element={<ConsultantPodsList />} />
      <Route path="/members-list/:id" element={<DelegateMembers />} />
      <Route path="/member-list/:id" element={<MembersListConsultantNew />} />
      <Route
        path="/add-consultant"
        element={
          <AddConsultant
            heading={"Marketing Team"}
            default_value={"marketing"}
          />
        }
      />
      <Route
        path="/add-transaction/:delegate_id"
        element={<AddOrUpdateAffiliateTransactions user_type="consultant" />}
      />
      <Route path="/member-profile/:id" element={<MemberProfile />} />
      <Route path="/groups/:id" element={<ConsultantGroupsList />} />
      <Route path="/events/:id" element={<ConsultantCalender />} />
      <Route
        path="/goal-statement-list/:id"
        element={<ConsultantGoalStatementList />}
      />{" "}
      <Route
        path="/manage-template-access/:id"
        element={<ManageTemplateAccess />}
      />
      <Route
        path="/manage-navitems-access/:user_id"
        element={
          <ManageNavItemsAccess
            type="delegate"
            title="Marketing Team"
            navigate="/consultant"
          />
        }
      />
      <Route
        path="/questions-answers"
        element={<QuestionsAnswersForUser user_type="delegate" />}
      />
      <Route
        path="/questions-answers/answer-detail"
        element={<QuestionsAnswers />}
      />
      <Route path="/member-groups/:id" element={<MemberGroupsList />} />
      <Route
        path="/manage-commission-access/:id"
        element={<ManageCommissionAccess />}
      />
      <Route path="/pods-detail/:id" element={<ConsultantPodsDetail />} />
      <Route
        path="/view-group-detail/:id"
        element={<ConsultantGroupDetail />}
      />
      <Route
        path="/goal-statement-detail/:id"
        element={<ConsultantGoalstatementDetail />}
      />
      <Route
        path="/recording-detail/:id"
        element={<ConsultantRecordingDetail />}
      />
      <Route
        path="/edit-consultant/:id"
        element={<EditConsultant heading={"Marketing Team"} />}
      />
      <Route
        path="/commission-history/:id"
        element={<CommissionHistoryTabs />}
      />
      <Route
        path="/recant-associated-transactions/:id"
        element={<RecantAssociatedTransactions />}
      />
      <Route
        path="/change-password/:id"
        element={<ChangeConsultantPassword />}
      />
      <Route path="/tracking-history/:id" element={<TrackingHistory />} />
      <Route path="/recordings/:id" element={<ConsultantRecordings />} />
      <Route
        path="/delegate-90-days-questions/:id"
        element={<DelegateQuestionsListView />}
      />
      <Route
        path="/assessment-study/:id"
        element={<DelegateAssessmentStudy />}
      />
      <Route path="/to-do-tracker/:id" element={<DelegateToDoTrackerList />} />
    </Routes>
  );
};

export default MarketingRoutes;
