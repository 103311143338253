import * as React from "react";
import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import {
  addMissionControlSettings,
  getMissionControlSettings,
} from "src/DAL/SiteSetting/siteSetting";
import { useEffect } from "react";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function MissionControlsSettings() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  const [inputs, setInputs] = useState({
    dashboard_level_info: "",
    dashboard_coins_info: "",
    dashboard_mission_info: "",
    source_journey_info: "",
    reward_and_badges_info: "",
    show_app_link: true,
  });

  const siteSettingData = async () => {
    setIsLoading(true);
    const result = await getMissionControlSettings();
    if (result.code == 200) {
      if (result?.content_setting) {
        setInputs(result?.content_setting);
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);
    let postData = {
      mission_control_setting: inputs,
    };

    const result = await addMissionControlSettings(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    siteSettingData();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-6">
          <h2>Mission Control Settings</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6 mt-4">
            <FormControl fullWidth>
              <InputLabel id="show_app_link-label">
                Show App Link In Affiliate Links
              </InputLabel>
              <Select
                labelId="show_app_link-label"
                id="show_app_link"
                name="show_app_link"
                value={inputs.show_app_link}
                label="Show App Link In Affiliate Links"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Dashboard Level Info</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="dashboard_level_info"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Dashboard Coins Info</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="dashboard_coins_info"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Dashboard Mission Info</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="dashboard_mission_info"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Source Journey Info</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="source_journey_info"
              editorHeight={350}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Rewards and Badges Info</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="reward_and_badges_info"
              editorHeight={350}
            />
          </div>
          <div className="text-end mt-4" id="fixedbutton">
            <button className="small-contained-button">
              {isLoadingForm ? "Updating" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
