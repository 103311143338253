import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import { usePGIMode } from "src/Hooks/PGIModeContext";
import ChallengesList from "./ChallengesList";
import MissionTabsType from "./MissionTabsType";

const MissionTabs = () => {
  const navigate = useNavigate();
  const { badgeLevels } = usePGIMode();
  const [tabValue, setTabValue] = useState("");
  const handleNavigate = () => {
    navigate(`/mission/addOrUpdate`);
  };
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    sessionStorage.setItem("mission_list_badge_level", newValue);
  };
  const TABS_OPTIONS = badgeLevels.map((level) => {
    return {
      title: level.title,
      component: <MissionTabsType level={level._id} />,
    };
  });
  useEffect(() => {
    let value = sessionStorage.getItem("mission_list_badge_level");
    if (value) {
      setTabValue(Number(value));
    } else {
      setTabValue(0);
    }
  }, []);
  return (
    <>
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col-lg-8 col-sm-12">
            <h2>Missions</h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Missions
            </button>
          </div>
        </div>
        <MUICustomTabs
          data={TABS_OPTIONS}
          value={tabValue}
          handleChange={handleChange}
        />
      </div>
    </>
  );
};

export default MissionTabs;
