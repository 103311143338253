import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";

import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  delete_programme_section_api,
  programme_sections_list_api,
} from "src/DAL/Programme/ProgrammeSections/ProgrammeSections";
import { programme_detail_api } from "src/DAL/Programme/Programme";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import PageLoader from "src/components/GeneralComponents/PageLoader";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false, type: "action" },
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "title", label: "Name", alignRight: false },
  {
    id: "status",
    label: "Status",
    type: "row_status",
  },
];

export default function ProgrammeSectionList() {
  const navigate = useNavigate();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [sectionsData, setSectionsData] = useState([]);
  const [programName, setProgramName] = useState("");
  const { state } = useLocation();
  const getSectionsListing = async () => {
    const result = await programme_sections_list_api(params.programme_slug);
    if (result.code == 200) {
      setSectionsData(result.program_section);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const getProgrammeDetail = async () => {
    setIsLoading(true);
    const result = await programme_detail_api(params.programme_slug);
    if (result.code === 200) {
      setProgramName(result?.program?.title);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_programme_section_api(deleteDoc.section_slug);
    if (result.code === 200) {
      setSectionsData((sectionsData) => {
        return sectionsData.filter((data) => data._id !== deleteDoc._id);
      });
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleEdit = (value) => {
    navigate(
      `/programmes/sections/${params.programme_slug}/edit-section/${value.section_slug}`,
      {
        state: value,
      }
    );
  };

  const handleNavigate = () => {
    navigate(`/programmes/sections/${params.programme_slug}/add-section`);
  };

  useEffect(() => {
    getSectionsListing();
  }, []);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  let breadCrumbMenu = [
    {
      title: "Programmes",
      navigation: `/programmes`,
      active: false,
    },

    {
      title: state ? state?.title : programName ? programName : "",
      navigation: `/programmes/programmes-detail/${params.programme_slug}`,
      active: false,
    },
    {
      title: "Section List",
      navigation: ``,
      active: true,
    },
  ];
  useEffect(() => {
    if (params.programme_slug) {
      if (state) {
      } else {
        getProgrammeDetail();
      }
    }
  }, []);
  useEffect(() => {
    getProgrammeDetail();
  }, []);
  if (isLoading == true) {
    return <PageLoader />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this section?"}
        handleAgree={handleDelete}
      />

      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-12">
            <span className="">
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
          </div>
          <div className="col-lg-6 col-sm-12 mt-3">
            <h2>Section List</h2>
          </div>
          <div className="col-lg-6 col-sm-12 text-end ">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Section
            </button>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={sectionsData}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          pagePagination={true}
        />
      </div>
    </>
  );
}
