import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import UpdateView from "./UpdateView";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import {
  _delete_portal_updates,
  _list_portal_updates,
} from "src/DAL/Updates/Updates";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { dd_date_format } from "src/utils/constant";
import PageLoader from "src/components/GeneralComponents/PageLoader";

let EMPTY_FILTER = {
  search_text: "",
  rowsPerPage: 50,
  page: 0,
};

let find_filter = localStorage.getItem(`updates_filter_data`);
if (find_filter) {
  EMPTY_FILTER = JSON.parse(find_filter);
}

export default function UpdatesList() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteDoc, setDeleteDoc] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState(EMPTY_FILTER.search_text);
  const [page, setPage] = useState(EMPTY_FILTER.page);
  const [rowsPerPage, setRowsPerPage] = useState(EMPTY_FILTER.rowsPerPage);
  const [openView, setOpenView] = useState("");
  const [openViewData, setOpenViewData] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleNavigate = () => {
    navigate(`/updates/add-update`);
  };

  const handleEdit = (value) => {
    navigate(`/updates/edit-update/${value._id}`, {
      state: value,
    });
  };

  const handleClickView = (title, data) => {
    setOpenView(title);
    setOpenViewData(data);
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getConsultantListing();
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);

    const result = await _delete_portal_updates(deleteDoc?._id);
    if (result.code === 200) {
      setUserList((old) =>
        old.filter((update) => update._id !== deleteDoc?._id)
      );
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getConsultantListing = async () => {
    setIsLoading(true);

    let postData = {
      search: searchText,
    };

    const result = await _list_portal_updates(page, rowsPerPage, postData);
    if (result.code === 200) {
      localStorage.setItem(
        "updates_filter_data",
        JSON.stringify({
          search_text: searchText,
          rowsPerPage: rowsPerPage,
          page: page,
        })
      );
      setTotalCount(result?.total_portal_updates_count);
      setTotalPages(result?.total_pages);
      let updates = result?.portal_updates.map((update) => {
        return { ...update, update_date: dd_date_format(update.date) };
      });
      setUserList(updates);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getConsultantListing();
  }, [rowsPerPage, page]);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    {
      id: "title",
      label: "Title",
    },
    {
      id: "update_date",
      label: "Date",
    },
    {
      id: "fixed_issues_description",
      label: "Update Description",
      renderData: (row) => (
        <UpdateView
          openViewData={row.fixed_issues_description}
          onClick={() =>
            handleClickView("issues", row.fixed_issues_description)
          }
        />
      ),
    },
  ];

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-12">
            <h2>Updates</h2>
          </div>

          <div className="col-lg-8 col-sm-12 text-end">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Update
            </button>
          </div>

          <div className="col-12 mt-3">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              MENU_OPTIONS={MENU_OPTIONS}
              data={userList}
              className="card-with-background"
              pagePagination={true}
              localSearchName="calender_group_searh"
              custom_search={{
                searchText: searchText,
                setSearchText: setSearchText,
                handleSubmit: searchFunction,
              }}
              custom_pagination={{
                total_count: totalCount,
                rows_per_page: rowsPerPage,
                page: page,
                handleChangePage: handleChangePage,
                onRowsPerPageChange: handleChangeRowsPerPage,
              }}
              pageCount={pageCount}
              totalPages={totalPages}
              handleChangePages={handleChangePages}
            />
          </div>
        </div>
      </div>

      <GeneralPopUpModel
        open={openView}
        setOpen={setOpenView}
        title={"Update Description"}
        componentToPassDown={
          <div
            className="dashboard_description mt-3"
            dangerouslySetInnerHTML={{
              __html: openViewData,
            }}
          ></div>
        }
      />
    </>
  );
}
