import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton } from "@mui/material";
import { useEffect } from "react";
import { urlPatternValidation } from "src/utils/constant";
import { s3baseUrl } from "src/config/config";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import {
  add_lesson_recording_api,
  delete_lesson_recording_audio,
  lesson_recording_detail_api,
  update_lesson_recording_api,
} from "src/DAL/Programme/Lessons/LessonRecordings/LessonRecordings";
import moment from "moment";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const Input = styled("input")({
  display: "none",
});

export default function AddOrUpdateLessonRecordings() {
  const navigate = useNavigate();
  const { lesson_slug, recording_slug } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [audioFile, setAudio] = useState();
  const [oldAudio, setOldAudio] = useState("");
  const [formType, setFormType] = useState("ADD");
  const [recordingDate, setRecordingDate] = useState(null);

  const [images, setImages] = useState({
    image: "",
  });

  const [oldImages, setOldImages] = useState({
    image: "",
  });

  const [inputs, setInputs] = useState({
    title: "",
    status: true,
    image: {},
    video_url: "",
    short_description: "",
    order: 0,
  });

  const getRecordingDetail = async () => {
    setIsLoading(true);
    const result = await lesson_recording_detail_api(recording_slug);
    if (result.code === 200) {
      handleFormat(result.recording);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fileChangedHandler = (e) => {
    const { name, files } = e.target;
    setImages({
      ...images,
      [name]: URL.createObjectURL(files[0]),
    });

    setInputs({
      ...inputs,
      [name]: files[0],
    });
  };

  const audioFileChange = (e) => {
    setAudio(e.target.files[0]);
  };

  const handldeDeleteAudio = async () => {
    setIsLoading(true);
    let postData = {
      status: "audio",
    };
    let result = await delete_lesson_recording_audio(postData, recording_slug);
    if (result.code == 200) {
      setIsLoading(false);
      setOldAudio();
      setAudio();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.video_url && urlPatternValidation(inputs.video_url) === false) {
      enqueueSnackbar("Enter Valid Video URL", { variant: "error" });
    } else {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("title", inputs.title);
      formData.append(
        "recording_date",
        moment(recordingDate).format("YYYY-MM-DD")
      );
      formData.append("short_description", inputs.short_description);
      formData.append("status", inputs.status);
      formData.append("video_url", inputs.video_url);

      if (formType == "EDIT") {
        if (images.image) {
          formData.append("image", inputs.image);
        }
        formData.append("order", inputs.order);
      } else {
        formData.append("lesson_slug", lesson_slug);
        formData.append("image", inputs.image);
      }
      if (audioFile) {
        formData.append("audio_file", audioFile);
      }

      const result =
        formType == "ADD"
          ? await add_lesson_recording_api(formData)
          : await update_lesson_recording_api(formData, recording_slug);
      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        handleNavigate();
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleFormat = (data) => {
    setInputs(data);
    setFormType("EDIT");
    setRecordingDate(data.recording_date);
    setOldImages({
      ...oldImages,
      ["image"]: data.recording_image.thumbnail_2,
    });
    setOldAudio(data.audio_recording);
    setIsLoading(false);
  };

  const handleNavigate = () => {
    if (formType == "ADD") {
      navigate(`/lessons/lesson-recordings/${lesson_slug}`);
    } else {
      navigate(`/lessons/lesson-recordings/recording-detail/${recording_slug}`);
    }
  };

  useEffect(() => {
    if (recording_slug) {
      if (state) {
        handleFormat(state);
      } else {
        getRecordingDetail();
      }
    }
  }, []);

  if (isLoading === true) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={handleNavigate}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2>{`${formType == "ADD" ? "Add" : "Edit"} Recording`}</h2>
        </div>
      </div>
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Recording Title"
            variant="outlined"
            fullWidth
            name="title"
            value={inputs.title}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Recording Date"
              value={recordingDate}
              inputFormat="dd-MM-yyyy"
              required
              onChange={(newValue) => {
                setRecordingDate(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} fullWidth required />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Video Url"
            variant="outlined"
            fullWidth
            name="video_url"
            value={inputs.video_url}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="status"
              value={inputs.status}
              label="Status"
              onChange={handleChange}
              required
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>

        {formType == "EDIT" && (
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Order"
              variant="outlined"
              fullWidth
              required
              name="order"
              value={inputs.order}
              onChange={handleChange}
            />
          </div>
        )}

        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Upload Audio</p>
              <FormHelperText className="pt-0">
                Audio mp3 (max 200mb)
              </FormHelperText>
            </div>
            <div className="col-2">
              {oldAudio && (
                <DeleteIcon
                  onClick={handldeDeleteAudio}
                  className="mt-3 icon-color"
                />
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="audio">
                <Input
                  accept="audio/mp3,audio/*;capture=microphone"
                  id="audio"
                  multiple
                  name="audio"
                  type="file"
                  onChange={audioFileChange}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          <p className="text-secondary">{audioFile && audioFile.name}</p>
          {oldAudio && (
            <audio className="w-100" src={s3baseUrl + oldAudio} controls />
          )}
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Upload Image *</p>
              <FormHelperText className="pt-0">
                Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-2">
              {(images.image || oldImages.image) && (
                <img
                  src={
                    images.image ? images.image : s3baseUrl + oldImages.image
                  }
                  height="50"
                />
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  name="image"
                  onChange={fileChangedHandler}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          {inputs?.image?.name == "" ? (
            ""
          ) : (
            <p className="text-secondary">{inputs?.image?.name}</p>
          )}
        </div>

        <div className="col-12 mt-4">
          <FormControl fullWidth>
            <TextField
              id="outlined-multiline-flexible"
              label="Short Description"
              multiline
              rows={6}
              name="short_description"
              value={inputs.short_description}
              onChange={handleChange}
            />
            <FormHelperText>Maximum limit 500 characters</FormHelperText>
          </FormControl>
        </div>

        <div
          className="text-end mt-4"
          id={formType == "ADD" ? "" : "fixedbutton"}
        >
          <button className="small-contained-button">
            {formType == "ADD" ? "Submit" : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
