import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  book_call_type_list_api,
  delete_book_call_type_api,
} from "src/DAL/booking/book_call_types";
import PageLoader from "src/components/GeneralComponents/PageLoader";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false, type: "action" },
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "title", label: "Title", alignRight: false },
  {
    id: "master_user",
    label: "Master User For Call",
    alignRight: false,
    renderData: (row) => {
      return row.master_user.length > 0
        ? row.master_user.map((item, index) => {
            return (
              <p className="mb-1">
                {item._id !== null
                  ? item._id.first_name +
                    " " +
                    item._id.last_name +
                    " " +
                    "(" +
                    item._id.email +
                    ")"
                  : "N/A"}
              </p>
            );
          })
        : "N/A";
    },
  },
  {
    id: "call_type_status",
    label: "Status",
    type: "row_status",
  },
];

export default function BookCallTypes() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [callTypesData, setCallTypesData] = useState([]);

  const getBookCallTypeList = async () => {
    const result = await book_call_type_list_api();
    if (result.code == 200) {
      const call_types = result.call_types.map((call_type) => {
        return {
          ...call_type,
          call_type_status: call_type.status,
        };
      });
      setCallTypesData(call_types);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_book_call_type_api(deleteDoc._id);
    if (result.code === 200) {
      setCallTypesData((callTypesData) => {
        return callTypesData.filter((data) => data._id !== deleteDoc._id);
      });
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleEdit = (value) => {
    navigate(`/book-call-types/edit-book-call-type/${value._id}`, {
      state: value,
    });
  };

  const handleNavigate = () => {
    navigate(`/book-call-types/add-book-call-type`);
  };

  useEffect(() => {
    getBookCallTypeList();
  }, []);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  if (isLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this call type?"}
        handleAgree={handleDelete}
      />

      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-lg-6 col-sm-12">
            <h2>Call Types</h2>
          </div>
          <div className="col-lg-6 col-sm-12 text-end ">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Call Type
            </button>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={callTypesData}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          pagePagination={true}
        />
      </div>
    </>
  );
}
