import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import {
  _get_detail_tab_configration,
  _update_tab_configration_v1,
} from "src/DAL/Programme/Programme";
import { useEffect } from "react";
import { show_proper_words } from "src/utils/constant";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import RecordNotFound from "src/components/RecordNotFound";

import PageLoader from "./PageLoader";

export default function GeneralTabConfiguration({
  type,
  isLoading,
  setIsLoading,
  setModuleInfo,
  breadCrumbMenu,
  tabsConfigurationTitle,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { module_id } = useParams();
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [tabsList, setTabsList] = useState([]);

  const getTabsConfigurationDetail = async () => {
    const result = await _get_detail_tab_configration(module_id, type);
    if (result.code === 200) {
      setTabsList(result.tab_configration);
      setModuleInfo(result.module_info);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);
    let tabsData = tabsList.map((tab, i) => ({ ...tab, order: i + 1 }));

    let data = {
      type: type,
      tab_configration: tabsData,
    };

    const result = await _update_tab_configration_v1(data, module_id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const newItems = Array.from(tabsList);

    if (result.type === "PARENT") {
      const [reorderedItem] = newItems.splice(result.source.index, 1);
      newItems.splice(result.destination.index, 0, reorderedItem);
    }

    newItems.forEach((item, index) => {
      item.order = index + 1;
    });

    setTabsList(newItems);
  };

  const handleChange = (event, index) => {
    const { name, value } = event.target;
    setTabsList((old) =>
      old.map((item, i) => (i === index ? { ...item, [name]: value } : item))
    );
  };

  const handleGetNames = (tab) => {
    const keys = Object.keys(tab);
    return {
      title: keys.find((k) => k.includes("title")),
      status: keys.find((k) => k.includes("status")),
    };
  };

  useEffect(() => {
    getTabsConfigurationDetail();
  }, []);

  if (isLoading === true) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <span className="mb-3">
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
        <div className="col-6 mt-3">
          <h2>{tabsConfigurationTitle}</h2>
        </div>
      </div>
      <form className="row" onSubmit={handleSubmit}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="parents" type="PARENT">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {tabsList.length > 0 ? (
                  tabsList.map((tab, index) => {
                    const { title, status } = handleGetNames(tab);

                    return (
                      <Draggable
                        key={index}
                        draggableId={`parent-${index}`}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <div className="card mt-2">
                              <div className="row main position-relative">
                                <div className="col-11">
                                  <div className="row p-3">
                                    <div className="col-md-6">
                                      <TextField
                                        id={title}
                                        label={show_proper_words(title)}
                                        variant="outlined"
                                        fullWidth
                                        name={title}
                                        value={tab[title]}
                                        onChange={(event) =>
                                          handleChange(event, index)
                                        }
                                        required
                                      />
                                    </div>
                                    <div className="col-md-6 mt-4 mt-md-0">
                                      <FormControl fullWidth required>
                                        <InputLabel id={`${status}-label`}>
                                          {show_proper_words(status)}
                                        </InputLabel>
                                        <Select
                                          labelId={`${status}-label`}
                                          id={status}
                                          name={status}
                                          value={tab[status]}
                                          label={show_proper_words(status)}
                                          onChange={(event) =>
                                            handleChange(event, index)
                                          }
                                          required
                                        >
                                          <MenuItem value={true}>
                                            Active
                                          </MenuItem>
                                          <MenuItem value={false}>
                                            Inactive
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="menu-box-option col-1"
                                  {...provided.dragHandleProps}
                                >
                                  <div className="menu_parent mt-2">
                                    <DragIndicatorIcon />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  })
                ) : (
                  <RecordNotFound title="Data" />
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <div className="text-end mt-4" id="fixedbutton">
          <button className="small-contained-button">
            {isLoadingForm ? "Updating..." : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
