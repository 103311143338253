import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton, CircularProgress, TextareaAutosize } from "@mui/material";
import {
  addTransactionApi,
  getAddTransactionDataListApi,
  getTransactionDetailApi,
  updateTransactionApi,
} from "src/DAL/Transaction/transactionApi";
import { TRANSACTION_STATUS } from "src/utils/constant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function AddTransaction() {
  const navigate = useNavigate();
  const classes = useStyles();
  const state = useLocation();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setProfileImage] = React.useState();
  const [pageList, setPageList] = React.useState([]);
  const [affiliateList, setAffiliateList] = React.useState([]);
  const [membersList, setMembersList] = useState([]);
  const [paymentRequestsList, setPaymentRequestsList] = React.useState([]);
  const [paymentPlansList, setPaymentPlansList] = React.useState([]);
  const [serchText, setSerchText] = useState("");
  const [serchTextAffiliate, setSerchTextAffiliate] = useState("");
  const [serchTextSalePage, setSerchTextsalePage] = useState("");
  const [member, setMember] = useState(null);
  const [affiliate, setAffiliate] = useState(null);
  const [affiliate2, setAffiliate2] = useState(null);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [apiPath, setApiPath] = useState("");
  const [commissionPaidTo, setCommissionPaidTo] = useState("");
  const [planInfo, setPlanInfo] = useState(null);
  const [apiSearchPath, setApiSearchPath] = useState(
    "/api/member/members_pages_request/list/?search_text="
  );
  const [inputs, setInputs] = React.useState({
    title: "",
    page: null,
    status: true,
    transaction_Type: "sale_page",
    transaction_status: "succeeded",
    amount: "",
    plan_type: "onetime",
    plan: "",
    referral_commission: "0",
    payment_made_by: "cash",
    dynamite_digital_commission: 0,
    first_affiliate_commission: 0,
    second_affiliate_commission: 0,
    // transection_by_name: "",
    transaction_note: "",
  });

  const getLists = async (type) => {
    let postData = {
      type: type ? type : "",
      user_id: member ? member._id : "",
      page_id: inputs.page,
      search_text: serchText,
    };
    const result = await getAddTransactionDataListApi(postData);
    if (result.code == 200) {
      setMembersList(result?.members);

      setAffiliateList(result?.affiliate);
      setPaymentRequestsList(result?.payment_requests);
    }
  };

  const getTransactionDetail = async () => {
    const result = await getTransactionDetailApi(params.transaction_id);
    if (result.code == 200) {
      let sale_page_created = "";
      if (
        result?.transaction?.sale_page?.action_info &&
        Object.keys(result?.transaction?.sale_page?.action_info).length > 0
      ) {
        if (result?.transaction?.sale_page.action_info.action_by == "Admin") {
          sale_page_created =
            "(" +
            result?.transaction?.sale_page.action_info.name +
            " " +
            "|" +
            " " +
            result?.transaction?.sale_page.action_info.email +
            " " +
            "|" +
            " " +
            "Admin" +
            ")";
        } else {
          sale_page_created =
            "(" +
            result?.transaction?.sale_page.action_info.name +
            " " +
            result?.transaction?.sale_page.action_info.email +
            "|" +
            "Delegate" +
            ")";
        }
      }
      let sale_page = {
        ...result?.transaction?.sale_page,
        actionBY: sale_page_created,
      };
      if (result.transaction.plan_info?.is_plan_free) {
        setPaymentPlansList((old) => [...old, result?.transaction?.plan]);
      }
      setInputs((inputs) => ({
        ...inputs,
        ["page"]: sale_page,
        ["transaction_status"]: result?.transaction?.transaction_status,
        ["payment_made_by"]: result?.transaction?.payment_made_by,
        ["dynamite_digital_commission"]:
          result?.transaction?.dynamite_commission,
        ["plan"]: result?.transaction?.plan?._id,
        ["amount"]: result?.transaction?.amount,
        ["first_affiliate_commission"]:
          result?.transaction?.referral_commission,
        ["second_affiliate_commission"]:
          result?.transaction?.transaction_referral_commission,
        ["transaction_Type"]:
          result?.transaction?.transaction_type == "payment_plan"
            ? "sale_page"
            : "payment_request",
        // ["transection_by_name"]: result?.transaction?.transection_by_name,
        ["transaction_note"]: result?.transaction?.transaction_note,
      }));
      setMember(result?.transaction?.member);
      setAffiliate(result?.transaction?.affliliate);
      setCommissionPaidTo(result?.transaction?.plan?.commission_pay_to);
      setAffiliate2(result?.transaction?.transaction_referral);
      setPaymentRequest(result?.transaction?.payment_request);
    }
  };

  const getPaymentLists = async (type) => {
    let postData = {
      type: "payment_request",
      user_id: member ? member?._id : "",
      page_id: inputs.page,
      search_text: "",
    };
    const result = await getAddTransactionDataListApi(postData);
    if (result.code == 200) {
      setPaymentRequestsList(result?.payment_requests);
    }
  };

  const getPaymentPlanLists = async (type) => {
    let postData = {
      type: "payment_plans",
      user_id: "",
      page_id: inputs.page,
      search_text: "",
    };
    const result = await getAddTransactionDataListApi(postData);
    if (result.code == 200) {
      setPaymentPlansList((old) => [...old, ...result?.payment_plans]);
    }
  };

  const getSearchGroupsAndMembers = async () => {
    let postData = {
      type: "",
      user_id: "",
      page_id: "",
      search_text: serchText,
    };
    const result = await getAddTransactionDataListApi(postData);
    if (result.code === 200) {
      const newArray = result.members.filter(({ _id }) => _id == member?._id);
      setMembersList(result.members);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  const getSearchsalePage = async () => {
    let postData = {
      type: "sale_page",
      user_id: "",
      page_id: "",
      search_text: serchTextSalePage,
    };
    const result = await getAddTransactionDataListApi(postData);
    if (result.code === 200) {
      let page_list = result?.sale_pages.map((item, index) => {
        let sale_page_created = "";
        if (item.action_info && Object.keys(item.action_info).length > 0) {
          if (item.action_info.action_by == "Admin") {
            sale_page_created =
              "(" +
              item.action_info.name +
              " " +
              "|" +
              " " +
              item.action_info.email +
              " " +
              "|" +
              " " +
              "Admin" +
              ")";
          } else {
            sale_page_created =
              "(" +
              item.action_info.name +
              " " +
              item.action_info.email +
              "|" +
              "Delegate" +
              ")";
          }
        }
        return {
          ...item,
          actionBY: sale_page_created,
        };
      });
      setPageList(page_list);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const getMemberAffiliate = async () => {
    if (member?._id && member.affliliate) {
      setAffiliate(member.affliliate);
    }
  };

  const getSearchAffiliate = async () => {
    let postData = {
      type: "affiliate",
      user_id: member ? member._id : "",
      page_id: inputs.page,
      search_text: serchTextAffiliate,
    };
    const result = await getAddTransactionDataListApi(postData);
    if (result.code === 200) {
      const newArray = result.affiliate.filter(
        ({ _id }) => _id == affiliate?._id
      );
      setAffiliateList(result?.affiliate);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSerchText(value);
  };

  const handleSearchAffiliate = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSerchTextAffiliate(value);
  };
  const handleSearchsalePage = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSerchTextsalePage(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let postData;

    if (inputs.transaction_Type == "sale_page") {
      postData = {
        amount: inputs.amount,
        transaction_type: "payment_plan",
        member: member._id,
        // payment_request: paymentRequest._id,
        affliliate: affiliate?._id ? affiliate?._id : "",
        sale_page: inputs.page?._id,
        plan: inputs.plan,
        referral_commission: inputs.first_affiliate_commission,
        dynamite_commission: inputs.dynamite_digital_commission,
        transaction_referral: affiliate2 ? affiliate2?._id : "",
        transaction_referral_commission: inputs.second_affiliate_commission,
        // transection_by_name: inputs.transection_by_name,
        transaction_note: inputs.transaction_note,
        transaction_status: inputs.transaction_status,
        payment_made_by: inputs.payment_made_by,
      };
    } else {
      postData = {
        amount: inputs.amount,
        transaction_type: "payment_request",
        member: member?._id,
        payment_request: paymentRequest._id,
        affliliate: affiliate._id,
        // sale_page: page,
        // plan: inputs.plan,
        referral_commission: inputs.first_affiliate_commission,
        dynamite_commission: inputs.dynamite_digital_commission,
        transaction_referral: affiliate2,
        transaction_referral_commission: inputs.second_affiliate_commission,
        // transection_by_name: inputs.transection_by_name,
        transaction_note: inputs.transaction_note,
        transaction_status: inputs.transaction_status,
        payment_made_by: inputs.payment_made_by,
      };
    }

    const result = params.transaction_id
      ? await updateTransactionApi(params.transaction_id, postData)
      : await addTransactionApi(postData);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleChangeNumber = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const nonNegativeValue = Math.max(0, parseFloat(value));
    setInputs((values) => ({ ...values, [name]: nonNegativeValue }));
  };

  React.useEffect(() => {
    getLists();
  }, []);

  React.useEffect(() => {
    if (params.transaction_id) {
      getTransactionDetail();
    }
  }, []);

  React.useEffect(() => {
    if (!!inputs.page) {
      getPaymentPlanLists();
    }
  }, [inputs.page]);

  React.useEffect(() => {
    getMemberAffiliate();
  }, [member?._id]);

  React.useEffect(() => {
    if (serchText.length % 3 === 0) {
      getSearchGroupsAndMembers();
    }
  }, [serchText]);
  React.useEffect(() => {
    if (serchTextSalePage.length % 3 === 0) {
      getSearchsalePage();
    }
  }, [serchTextSalePage]);

  React.useEffect(() => {
    getSearchAffiliate();
  }, [serchTextAffiliate]);

  React.useEffect(() => {
    let objectPlan = paymentPlansList.find((value) => value._id == inputs.plan);
    if (objectPlan) {
      setCommissionPaidTo(objectPlan.commission_pay_to);
    } else {
      setCommissionPaidTo("");
    }
  }, [inputs.plan]);

  React.useEffect(() => {
    if (inputs.transaction_Type == "payment_request") {
      if (member?._id) {
        getPaymentLists();
      }
    }
  }, [inputs.transaction_Type, member?._id]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex ">
          <span className="me-2">
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>

          <h2>
            {params.transaction_id ? "Edit Transaction" : "Add Transaction"}
          </h2>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <Autocomplete
              // multiple

              id="tags-outlined"
              options={membersList}
              getOptionLabel={(option) =>
                option.first_name +
                " " +
                option.last_name +
                " (" +
                option.email +
                ")"
              }
              filterSelectedOptions
              value={member}
              onChange={(event, newValue) => {
                setMember(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Members"
                  placeholder="Members"
                  onChange={handleSearch}
                  required
                />
              )}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Transaction type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="transaction_Type"
                value={inputs.transaction_Type}
                label="Transaction type"
                onChange={handleChange}
                disabled={params.transaction_id}
              >
                <MenuItem value="sale_page">Sale Page</MenuItem>
                {params.transaction_id && (
                  <MenuItem value="payment_request">Payment Request</MenuItem>
                )}
              </Select>
            </FormControl>
          </div>
          {inputs.transaction_Type == "sale_page" ? (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <Autocomplete
                  id="tags-outlined"
                  options={pageList}
                  getOptionLabel={(option) =>
                    option.sale_page_title + " " + option.actionBY ?? ""
                  }
                  filterSelectedOptions
                  value={inputs.page}
                  onChange={(event, newValue) => {
                    setInputs((values) => ({ ...values, ["page"]: newValue }));
                  }}
                  renderInput={(params, index) => (
                    <TextField
                      {...params}
                      key={index}
                      label="Sale Page"
                      placeholder="Sale Page"
                      onChange={handleSearchsalePage}
                      required
                    />
                  )}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">
                    Payment Plan
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="plan"
                    value={inputs.plan}
                    label="Payment Plan"
                    onChange={handleChange}
                  >
                    <MenuItem value="">None</MenuItem>
                    {paymentPlansList.map((value) => {
                      return (
                        <MenuItem value={value?._id}>
                          {value?.plan_title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Amount"
                  variant="outlined"
                  required
                  fullWidth
                  type="number"
                  name="amount"
                  value={inputs.amount}
                  onChange={handleChangeNumber}
                />
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Team Diego Commission"
              variant="outlined"
              required
              type="number"
              fullWidth
              name="dynamite_digital_commission"
              value={inputs.dynamite_digital_commission}
              onChange={handleChangeNumber}
            />
          </div>
          {inputs.transaction_Type == "payment_request" && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <Autocomplete
                // multiple

                id="tags-outlined"
                options={paymentRequestsList}
                getOptionLabel={(option) => option.request_title}
                filterSelectedOptions
                value={paymentRequest}
                onChange={(event, newValue) => {
                  setPaymentRequest(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Payment Request"
                    placeholder="Payment Request"
                    onChange={handleSearchAffiliate}
                    required
                  />
                )}
              />
            </div>
          )}

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <Autocomplete
              id="tags-outlined"
              options={affiliateList}
              getOptionLabel={(option) =>
                option?.affiliate_user_info?.affiliate_user_created_for ==
                "memberuser"
                  ? option?.affiliate_user_info?.first_name +
                    " " +
                    option?.affiliate_user_info?.last_name +
                    " (" +
                    option?.affiliate_user_info?.email +
                    ")" +
                    " | Member"
                  : option?.affiliate_user_info?.first_name +
                    " " +
                    option?.affiliate_user_info?.last_name +
                    " (" +
                    option?.affiliate_user_info?.email +
                    ")" +
                    " | Consultant"
              }
              filterSelectedOptions
              value={affiliate}
              onChange={(event, newValue) => {
                setAffiliate(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Referral User"
                  placeholder="Referral User"
                  onChange={handleSearchAffiliate}
                />
              )}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Referral User Commission"
              variant="outlined"
              fullWidth
              type="number"
              name="first_affiliate_commission"
              value={inputs.first_affiliate_commission}
              onChange={handleChangeNumber}
            />
          </div>
          {commissionPaidTo === "both" && (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <Autocomplete
                  // multiple

                  id="tags-outlined"
                  options={affiliateList}
                  getOptionLabel={(option) =>
                    option?.affiliate_user_info?.affiliate_user_created_for ==
                    "memberuser"
                      ? option?.affiliate_user_info?.first_name +
                        " " +
                        option?.affiliate_user_info?.last_name +
                        " (" +
                        option?.affiliate_user_info?.email +
                        ")" +
                        " | Member"
                      : option?.affiliate_user_info?.first_name +
                        " " +
                        option?.affiliate_user_info?.last_name +
                        " (" +
                        option?.affiliate_user_info?.email +
                        ")" +
                        " | Consultant"
                  }
                  filterSelectedOptions
                  value={affiliate2}
                  onChange={(event, newValue) => {
                    setAffiliate2(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Transaction Referral"
                      placeholder="Transaction Referral"
                      onChange={handleSearchAffiliate}
                      required={
                        inputs.second_affiliate_commission > 0 ? true : false
                      }
                    />
                  )}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Transaction Referral Commission"
                  variant="outlined"
                  fullWidth
                  type="number"
                  name="second_affiliate_commission"
                  value={inputs.second_affiliate_commission}
                  onChange={handleChangeNumber}
                />
              </div>
            </>
          )}
          {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Transaction By"
              variant="outlined"
              fullWidth
              type="text"
              name="transection_by_name"
              value={inputs.transection_by_name}
              onChange={handleChange}
            />
          </div> */}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Transaction Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="transaction_status"
                value={inputs.transaction_status}
                label="Transaction Status"
                onChange={handleChange}
              >
                {TRANSACTION_STATUS.map((status) => {
                  return (
                    <MenuItem value={status.value}>{status.name}</MenuItem>
                  );
                })}

                {/* <MenuItem value="payment_request">Payment Request</MenuItem> */}
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Payment Made By
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="payment_made_by"
                value={inputs.payment_made_by}
                label="Payment Made By"
                onChange={handleChange}
              >
                <MenuItem value={"cash"}>Cash</MenuItem>
                <MenuItem value={"stripe"}>Stripe</MenuItem>
                <MenuItem value={"other"}>Other</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-12 ">
            <p className="tracking_code_heading">Transaction Note</p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              value={inputs.transaction_note}
              name="transaction_note"
              onChange={handleChange}
              placeholder="Transaction Note"
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "8px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>

          <div
            className="text-end mt-4"
            id={params?.transaction_id ? "fixedbutton" : ""}
          >
            <button
              //  onClick={handleSubmit}
              className="small-contained-button"
            >
              {params?.transaction_id ? "Update" : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
