import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useEffect } from "react";
import { s3baseUrl } from "src/config/config";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import {
  ManageProductAccess,
  update_poduct_for_reward_api,
} from "src/DAL/Community/community";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ProductAccessCommunity() {
  const params = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [programsList, setProgramsList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [planDetail, setPlanDetail] = useState(null);
  const [isAllFiltered, setIsAllFiltered] = useState(false);
  const [input, setInput] = useState({
    send_gift: false,
  });
  console.log(selected, "selectedselectedselected");
  const handleChangeInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInput((values) => ({ ...values, [name]: value }));
  };
  const getProgramsList = async () => {
    const result = await ManageProductAccess(params.plan_id);
    if (result.code == 200) {
      setInput((values) => ({
        ...values,
        ["send_gift"]: result?.reward?.send_gift,
      }));
      setPlanDetail(result.reward_details);
      let selected_programs = [];
      result?.reward?.products?.map((prog) => {
        let fild;
        selected_programs.push({
          ...prog,
          _id: prog.product_id,
          already_exist: true,
        });
      });
      if (result?.reward?.products?.length == result?.products?.length) {
        setIsAllFiltered(true);
      }
      setSelected(selected_programs);
      var programArray = [];
      result.products.map((item) => {
        let is_exist = result?.reward?.products?.find(
          (object) => object.product_id == item._id
        );
        if (is_exist) {
          item.quantity = is_exist.quantity;
        }
        item.table_avatar = {
          src: s3baseUrl + item.image[0].image,
          alt: item.name,
        };
        item.is_exist = Boolean(is_exist);

        programArray.push(item);
      });
      setProgramsList(programArray);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);
    let dynamite_products = [];
    if (input.send_gift) {
      programsList.map((item) => {
        if (item.is_exist) {
          var program_object = {
            product_id: item._id,
            quantity: item.quantity,
          };
          dynamite_products.push(program_object);
        }
      });
    }

    let programObject = {
      products: dynamite_products,
      send_gift: input.send_gift,
    };

    const result = await update_poduct_for_reward_api(
      params.plan_id,
      programObject
    );
    if (result.code == 200) {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleChange = (event, name) => {
    const value = event.target.value;
    const program_id = event.target.name;

    setProgramsList((data) =>
      data.map((obj) => {
        if (obj._id === program_id) {
          return { ...obj, [name]: value };
        }
        return obj;
      })
    );

    setSelected((data) =>
      data.map((obj) => {
        if (obj._id === program_id) {
          return { ...obj, [name]: value };
        }
        return obj;
      })
    );
  };

  const handleClick = (e, row, index) => {
    let find = selected.find((item) => item._id == row._id);
    setProgramsList((old) =>
      old.map((select) => {
        if (select._id == row._id) {
          select.is_exist = !select.is_exist;
          // select.quantity = 1;
          select.quantity = find
            ? find.quantity
            : select.remaining_quantity <= 0
            ? 0
            : 1;
        }
        return select;
      })
    );
  };

  const handleChangeAll = async (e, row, index) => {
    let is_checked = e.target.checked;
    setIsAllFiltered(is_checked);
    setProgramsList((old) =>
      old.map((obj) => {
        return { ...obj, is_exist: is_checked, quantity: 1 };
      })
    );
  };

  const TABLE_HEAD = [
    {
      id: "is_exist",
      label: (
        <FormControlLabel
          control={<Checkbox checked={isAllFiltered} />}
          label=""
          onChange={handleChangeAll}
          className="px-2"
        />
      ),
      type: "checkbox",
      handleClick: handleClick,
    },
    {
      id: "table_avatar",
      label: "Image",
      type: "thumbnail",
      className: "p-0",
    },
    { id: "name", label: "Product Title", alignRight: false },
    { id: "product_sku", label: "Product Sku", alignRight: false },

    {
      id: "availableQuantity",
      label: "Available Quantity",
      renderData: (row) => {
        let availableQuantitys = row.remaining_quantity;

        return (
          <p className="mb-0">
            {availableQuantitys <= 0 ? 0 : availableQuantitys}
          </p>
        );
      },
    },
    {
      id: "status",
      label: "Status",
      type: "row_status",
    },

    {
      id: "no_of_limited_days",
      label: "Quantity",
      renderData: (row) => {
        let is_selected = selected.find((object) => row._id === object._id);
        let quantity;
        if (is_selected && is_selected.already_exist) {
          quantity = is_selected.quantity;
        } else {
          quantity = 1;
        }

        return (
          <TextField
            id="outlined-basic"
            variant="outlined"
            fullWidth
            size="small"
            type="number"
            required
            style={{ minWidth: "150px" }}
            disabled={Boolean(!row.is_exist)}
            name={row._id}
            className="default_consultant_commission_input"
            value={
              row.is_exist ? row.quantity : row.remaining_quantity <= 0 ? 0 : 1
            }
            inputProps={{
              min: 1, // Minimum value
              max: row.remaining_quantity,
            }}
            sx={{
              "& fieldset": { border: "none" },
            }}
            onChange={(e) => {
              handleChange(e, "quantity");
            }}
          />
        );
      },
    },
  ];

  useEffect(() => {
    getProgramsList();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  let breadCrumbMenu = [
    {
      title: planDetail.title,
      navigation: `/community`,
      active: false,
    },
    {
      title: "Manage Gift Access",
      active: true,
    },
  ];

  return (
    <form onSubmit={handleSubmit}>
      <div className="container-fluid">
        <div className="row">
          {planDetail && (
            <div className="col-12 mb-3">
              <span>
                <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
              </span>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-6 mb-3">
            <FormControl fullWidth className="mt-3">
              <InputLabel id="demo-simple-select-label">
                Send Reward Gift
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={input.send_gift}
                name="send_gift"
                label="Send Reward Gift"
                onChange={handleChangeInput}
              >
                <MenuItem value={true}>Yes</MenuItem>;
                <MenuItem value={false}>No</MenuItem>;
              </Select>
            </FormControl>
          </div>
          {input.send_gift && (
            <div className="col-12">
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={programsList}
                pagePagination={true}
              />
            </div>
          )}
        </div>
        <div className="ms-auto text-end" id="fixedbutton">
          <button className="small-contained-button mt-3 ml-auto">
            {isLoadingForm ? "Updating..." : "Update"}
          </button>
        </div>
      </div>
    </form>
  );
}
