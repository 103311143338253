import React from "react";
import { fShortenNumber } from "src/utils/formatNumber";

export default function MemberTopStats(props) {
  const { cardDetail } = props;
  return (
    <>
      <div className="col-12 col-md-3 mt-3">
        <div className="profile-card">
          <h3>£{fShortenNumber(cardDetail.total_amount_paid)}</h3>
          <h6 className="text-muted">Total Paid Amount</h6>
        </div>
      </div>
      <div className="col-12 col-md-3 mt-3">
        <div className="profile-card">
          <h3>£{fShortenNumber(cardDetail.total_commission)}</h3>
          <h6 className="text-muted">Total Referral Commission</h6>
        </div>
      </div>
      <div className="col-12 col-md-3 mt-3">
        <div className="profile-card">
          <h3>£{fShortenNumber(cardDetail.total_paid_commission_amount)}</h3>
          <h6 className="text-muted">Paid Referral Commission</h6>
        </div>
      </div>
      <div className="col-12 col-md-3 mt-3">
        <div className="profile-card">
          <h3>£{fShortenNumber(cardDetail.remaining_commission)}</h3>
          <h6 className="text-muted">Pending Referral Commission</h6>
        </div>
      </div>
    </>
  );
}
