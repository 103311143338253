import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import {
  _delete_membership_level_badge,
  _list_membership_level_badge,
} from "src/DAL/MembershipLevel/MembershipLevelBadges/MembershipLevelBadges";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const TABLE_HEAD = [
  { id: "number", label: "#", type: "number" },
  {
    id: "table_avatar",
    label: "Icon",
    alignRight: false,
    type: "thumbnail",
  },
  {
    id: "table_avatar_image",
    label: "Image",
    alignRight: false,
    type: "thumbnail",
  },
  { id: "title", label: "Title" },
  { id: "order", label: "Order" },
  { id: "status", label: "Status", type: "row_status" },
];

export default function ViewMembershipLevelBadgesList({ selectedRowData }) {
  const id = selectedRowData?._id;
  const [membershipLevelBadgeList, setMembershipLevelBadgeList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [badgeTitle, setBadgeTitle] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [searchText, setSearchText] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFormat = (data) => {
    setBadgeTitle(data?.title);
  };

  const getMembershipLevelBadgeListing = async () => {
    setIsLoading(true);

    let postData = {
      membership_level_id: id,
      search: searchText,
    };

    const result = await _list_membership_level_badge(
      page,
      rowsPerPage,
      postData
    );
    if (result.code === 200) {
      const data = result.membership_levels_badges.map((membership_level) => {
        return {
          ...membership_level,
          table_avatar: {
            src: s3baseUrl + membership_level?.icon?.thumbnail_1,
            alt: membership_level?.title,
          },
          table_avatar_image: {
            src: s3baseUrl + membership_level?.image?.thumbnail_1,
            alt: membership_level?.title,
          },
        };
      });
      setMembershipLevelBadgeList(data);
      setIsLoading(false);
      handleFormat(result?.membership_level);
      setTotalCount(result?.total_membership_level_badges_count);
      setTotalPages(result?.total_pages < 0 ? 0 : result?.total_pages);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const searchFunction = (e) => {
    e.preventDefault();
    setPage(0);
    setPageCount(1);
    getMembershipLevelBadgeListing();
  };

  useEffect(() => {
    getMembershipLevelBadgeListing();
  }, [rowsPerPage, page]);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="container-fluid">
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={membershipLevelBadgeList}
          className="card-with-background"
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={false}
          is_hide={true}
          hide_footer_pagination={true}
        />
      </div>
    </>
  );
}
