import { invokeApi } from "../../bl_libs/invokeApi";

export const adminUserList = async (page, limit, search) => {
  const requestObj = {
    path: `/admin_users/get_admin_users`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const Requested_csv_files_api = async () => {
  const requestObj = {
    path: `/csv_file_requests/list_csv_file_requests`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const sales_commissiions_api = async (data) => {
  const requestObj = {
    path: `/admin_users/sales_and_report`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const productCategoryDetailApi = async (slug) => {
  const requestObj = {
    path: `/api/product_category/product_category_detail/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const addAdminUSer = async (data) => {
  const requestObj = {
    path: `/admin_users/add_admin_user`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const ReminderUSerList = async (page, limit, data) => {
  const requestObj = {
    path: `/api/event/welcome_events/list?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const AdminNavItems = async (data) => {
  const requestObj = {
    path: `/admin_users/nav_items`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const adminChangePasswordApi = async (data, id) => {
  const requestObj = {
    path: `/admin_users/admin_change_password/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editAdminUser = async (data, id) => {
  const requestObj = {
    path: `/admin_users/update_admin_user/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editReminderUser = async (data, id) => {
  const requestObj = {
    path: `/api/event/welcome_event/update/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const ADDReminderUser = async (data) => {
  const requestObj = {
    path: `/api/event/welcome_reminder/add`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const adminUserDeleteApi = async (id) => {
  const requestObj = {
    path: `/admin_users/delete_admin_user/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const questUserDeleteApi = async (id) => {
  const requestObj = {
    path: `/api/mission/delete_member/quest/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const DeleteMessageApi = async (id) => {
  const requestObj = {
    path: `/admin_users/delete_welcome/reminder/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const delete_member_session_api = async (id) => {
  const requestObj = {
    path: `/api/member/delete_member_session/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const affirmationCategoryAddApi = async (data) => {
  const requestObj = {
    path: `/api/general_category`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const affirmationCategoryEditApi = async (data, id) => {
  const requestObj = {
    path: `/api/general_category/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const stripe_reader_listing = async (id) => {
  const requestObj = {
    path: `/api/stripe_reader/list_active_stripe_reader/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const manage_reader_access_for_admin = async (data) => {
  const requestObj = {
    path: `/api/stripe_reader/manage_reader_access_for_admin`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
