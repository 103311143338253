import * as React from "react";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { _update_payment_configuration } from "src/DAL/MissionApi/MissionApiNew";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import { usePGIMode } from "src/Hooks/PGIModeContext";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function QuestPaymentConfiguration() {
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const { badgeLevels } = usePGIMode();
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  const [inputs, setInputs] = useState({
    currency: "gbp",
    price: 1,
    payment_mode: "sandBox",
    description: "",
    is_basic_membership: false,
    no_of_days: 0,
    badge_id: "",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name == "price") {
      if (parseInt(value) >= 1 || value == "") {
        setInputs((values) => ({ ...values, [name]: value }));
      }
    } else {
      setInputs((values) => ({ ...values, [name]: value }));
    }
  };

  const handleFormatdata = (data) => {
    setInputs((values) => ({
      ...values,
      ...data?.payment_configuration,
    }));

    setIsLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs?.description == "") {
      enqueueSnackbar("Description is required!", { variant: "error" });
      return;
    }
    setIsLoading(true);

    let postData = {
      payment_configuration: {
        currency: inputs?.currency,
        price: inputs?.price,
        description: inputs?.description,
        payment_mode: inputs?.payment_mode,
        is_basic_membership: inputs?.is_basic_membership,
        no_of_days: inputs?.no_of_days,
        badge_id: inputs?.badge_id,
      },
    };

    const result = await _update_payment_configuration(
      postData,
      params?.mission_id
    );
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (state) {
      handleFormatdata(state);
    }
  }, []);

  let breadCrumbMenu = [
    {
      title: `Quest ${state ? " (" + state?.title + ") " : ""}`,
      navigation: `/mission`,
      active: false,
    },
    {
      title: "Quest Payment Configuration",
      navigation: ``,
      active: true,
    },
  ];

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="col-12 mb-3 d-flex">
        <span className="mt-2">
          <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
        </span>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className={`col-lg-6 col-md-6 col-sm-12 mt-4 `}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Currency *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="currency"
                value={inputs?.currency}
                label="Currency"
                onChange={handleChange}
              >
                <MenuItem value="usd">Dollar</MenuItem>
                <MenuItem value="gbp">UK Pounds</MenuItem>
                <MenuItem value="eur">Euro</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Price"
              variant="outlined"
              fullWidth
              required
              type="number"
              name="price"
              value={inputs?.price}
              onChange={handleChange}
              //   inputProps={{ min: 1 }}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Payment Mode
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="payment_mode"
                value={inputs?.payment_mode}
                label="Payment Mode"
                onChange={handleChange}
              >
                <MenuItem value={"sandBox"}>Sand Box</MenuItem>
                <MenuItem value={"live"}>Live</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Is Basic Membership
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_basic_membership"
                value={inputs?.is_basic_membership}
                label="Is Basic Membership"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs?.is_basic_membership && (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="No. Of Days"
                  variant="outlined"
                  fullWidth
                  required
                  type="number"
                  name="no_of_days"
                  value={inputs?.no_of_days}
                  onChange={handleChange}
                  inputProps={{ min: 0 }}
                />
              </div>
            </>
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Badge Level</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="badge_id"
                value={inputs?.badge_id}
                label="Badge Level"
                onChange={handleChange}
              >
                {badgeLevels.map((level, index) => (
                  <MenuItem key={index} value={level?._id}>
                    {level?.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="col-12 mt-4">
            <h4>Detail Description *</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="description"
              editorHeight={320}
            />
          </div>

          <div className="text-end mt-4" id="fixedbutton">
            <button className="small-contained-button">Update</button>
          </div>
        </div>
      </form>
    </div>
  );
}
