import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";

import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import {
  ClickFunnelsSettingApi,
  GetFunnelsApi,
  GetshopApi,
  shopSettingApi,
} from "src/DAL/SiteSetting/siteSetting";
import { useEffect } from "react";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ClickFunnelSettings() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [inputs, setInputs] = useState({
    api_access_token: "",
    workspace_id: "",
    workspace_url: "",
  });
  const GetwhatappApiList = async () => {
    setIsLoading(true);
    const result = await GetFunnelsApi();
    if (result.code == 200) {
      setInputs(result?.clickfunnel_settings);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);
    let data = inputs;
    let postData = {
      clickfunnel_settings: data,
    };
    const result = await ClickFunnelsSettingApi(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    GetwhatappApiList();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-6">
          <h2>Clickfunnels Settings</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Workspace Url"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              type="url"
              name="workspace_url"
              value={inputs?.workspace_url}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Workspace ID"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              name="workspace_id"
              value={inputs?.workspace_id}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Api Access Token"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              name="api_access_token"
              value={inputs?.api_access_token}
              onChange={handleChange}
            />
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">
              {isLoadingForm ? "Updating" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
