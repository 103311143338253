import React from "react";
import { formatFirebaseDuration } from "src/utils/firebase_utils";
import { get_kmb_number } from "src/utils/formatNumber";
import { Icon } from "@iconify/react";
import { Tooltip } from "@mui/material";

export default function AnalyticsDashboardStats(props) {
  const { cardDetail } = props;
  let icon_alert_circle = "eva:alert-circle-outline";

  const getStatsInfo = (event_name) => {
    console.log("event_name  _event_name", event_name);
    switch (event_name) {
      case "1_day_active_users":
        return (
          <>
            Shows the number of active users who used your app in the last 24
            hours.
            <br />
            This helps to analyze daily user engagement.
          </>
        );
      case "7_day_active_users":
        return (
          <>
            Shows the number of active users who used your app in the past 7
            days.
            <br />
            This helps to track weekly user engagement trends.
          </>
        );
      case "30_day_active_users":
        return (
          <>
            Shows the number of active users who used your app in the past 30
            days.
            <br />
            This helps to measure long-term user retention and activity.
          </>
        );
      case "monthly_active_user_growth":
        return (
          <>
            Shows the growth in the number of active users over the course of a
            month.
            <br />
            This helps to track how your user base is expanding each month.
          </>
        );
      case "engaged_sessions_per_active_user":
        return (
          <>
            Shows the number of engaged sessions per active user during a
            specific time frame.
            <br />
            This helps to measure the level of engagement and interaction from
            active users.
          </>
        );
      case "average_engagement_time_per_active_user":
        return (
          <>
            Shows the average amount of time each active user spends on your
            app.
            <br />
            This helps to evaluate the depth of user engagement on a per-user
            basis.
          </>
        );
      case "average_engagement_time_per_session":
        return (
          <>
            Shows the average amount of time spent by users during each session.
            <br />
            This helps to measure session quality and user interaction.
          </>
        );
      case "monthly_recurring_revenue":
        return (
          <>
            Shows the total monthly recurring revenue (MRR) generated by your
            app.
            <br />
            This helps to track your app's financial performance on a monthly
            basis.
          </>
        );
      case "yearly_recurring_revenue":
        return (
          <>
            Shows the total yearly recurring revenue (YRR) generated by your
            app.
            <br />
            This helps to track your app's financial performance on an annual
            basis.
          </>
        );
      case "total_recurring_revenue":
        return (
          <>
            Shows the total recurring revenue generated by your app to date.
            <br />
            This helps to track your app's overall subscription-based revenue.
          </>
        );
      case "avg_recurring_revenue_per_user":
        return (
          <>
            Shows the average recurring revenue generated per user.
            <br />
            This helps to understand the revenue contribution of each user.
          </>
        );
      case "total_subscription_revenue":
        return (
          <>
            Shows the total revenue generated from subscriptions.
            <br />
            This helps to track the app's income from subscription-based
            services.
          </>
        );
      case "total_monthly_bookings":
        return (
          <>
            Shows the total number of bookings made within the app in a given
            month.
            <br />
            This helps to track the volume of bookings and app activity.
          </>
        );
      case "total_app_downloads":
        return (
          <>
            Shows the total number of app downloads.
            <br />
            This helps to track the app's reach and adoption over time.
          </>
        );

      //
      case "Pending Calls":
        return (
          <>
            Shows the number of calls that are currently pending action or are
            in a waiting state.
            <br />
            This helps to track calls that still need to be addressed or
            processed.
          </>
        );
      case "No Show Calls":
        return (
          <>
            Shows the number of calls where the intended recipient did not show
            up or attend.
            <br />
            This helps to measure missed or unfulfilled calls within the system.
          </>
        );
      case "Cancel  Calls":
        return (
          <>
            Shows the number of calls that were canceled by the user or system.
            <br />
            This helps to track calls that were initiated but then canceled.
          </>
        );
      case "No Action Calls":
        return (
          <>
            Shows the number of calls that have not been acted upon or
            processed.
            <br />
            This helps to identify calls that were not resolved or completed.
          </>
        );
      case "Complete  Calls":
        return (
          <>
            Shows the number of calls that were successfully completed.
            <br />
            This helps to track the number of calls that were finished and
            processed successfully.
          </>
        );
      case "Reschedule Calls":
        return (
          <>
            Shows the number of calls that were rescheduled to a later time.
            <br />
            This helps to measure how many calls were delayed or moved to a
            different time.
          </>
        );
      //
      default:
        return "";
    }
  };

  return (
    <>
      <div className="col-12 mt-3">
        <h2>Active Users</h2>
      </div>
      <div className="col-12 col-md-3 mb-3">
        <div className="profile-card overview-stats">
          <div className="overview-stats-icon">
            <Tooltip title={getStatsInfo("1_day_active_users")}>
              <Icon className="information-icon" icon={icon_alert_circle} />
            </Tooltip>
          </div>

          <h3>{get_kmb_number(cardDetail["1_day_active_users"])}</h3>
          <h6 className="text-muted">Daily Active Users</h6>
        </div>
      </div>
      <div className="col-12 col-md-3 mb-3">
        <div className="profile-card overview-stats">
          <div className="overview-stats-icon">
            <Tooltip title={getStatsInfo("7_day_active_users")}>
              <Icon className="information-icon" icon={icon_alert_circle} />
            </Tooltip>
          </div>
          <h3>{get_kmb_number(cardDetail["7_day_active_users"])}</h3>
          <h6 className="text-muted">Weekly Active Users</h6>
        </div>
      </div>
      <div className="col-12 col-md-3 mb-3">
        <div className="profile-card overview-stats">
          <div className="overview-stats-icon">
            <Tooltip title={getStatsInfo("30_day_active_users")}>
              <Icon className="information-icon" icon={icon_alert_circle} />
            </Tooltip>
          </div>
          <h3>{get_kmb_number(cardDetail["30_day_active_users"])}</h3>
          <h6 className="text-muted">Monthly Active Users</h6>
        </div>
      </div>
      <div className="col-12 col-md-3 mb-3">
        <div className="profile-card overview-stats">
          <div className="overview-stats-icon">
            <Tooltip title={getStatsInfo("monthly_active_user_growth")}>
              <Icon className="information-icon" icon={icon_alert_circle} />
            </Tooltip>
          </div>
          <h3>{get_kmb_number(cardDetail["monthly_active_user_growth"])}</h3>
          <h6 className="text-muted">Monthly Growth in Active Users</h6>
        </div>
      </div>
      <div className="col-12">
        <h2>User Engagement </h2>
      </div>
      <div className="col-12 col-md-3 mb-3">
        <div className="profile-card overview-stats">
          <div className="overview-stats-icon">
            <Tooltip
              title={<>{getStatsInfo("engaged_sessions_per_active_user")}</>}
            >
              <Icon className="information-icon" icon={icon_alert_circle} />
            </Tooltip>
          </div>
          <h3>
            {get_kmb_number(cardDetail["engaged_sessions_per_active_user"])}
          </h3>
          <h6 className="text-muted">Engaged Sessions Per Active User</h6>
        </div>
      </div>
      <div className="col-12 col-md-3 mb-3">
        <div className="profile-card overview-stats">
          <div className="overview-stats-icon">
            <Tooltip
              title={
                <>{getStatsInfo("average_engagement_time_per_active_user")}</>
              }
            >
              <Icon className="information-icon" icon={icon_alert_circle} />
            </Tooltip>
          </div>
          <h3>
            {formatFirebaseDuration(
              cardDetail["average_engagement_time_per_active_user"]
            )}
          </h3>
          <h6 className="text-muted">
            Average Engagement Time Per Active User
          </h6>
        </div>
      </div>
      <div className="col-12 col-md-3 mb-3">
        <div className="profile-card overview-stats">
          <div className="overview-stats-icon">
            <Tooltip
              title={<>{getStatsInfo("average_engagement_time_per_session")}</>}
            >
              <Icon className="information-icon" icon={icon_alert_circle} />
            </Tooltip>
          </div>
          <h3>
            {formatFirebaseDuration(
              cardDetail["average_engagement_time_per_session"]
            )}
          </h3>
          <h6 className="text-muted">Average Engagement Time Per Session</h6>
        </div>
      </div>
      <div className="col-12">
        <h2>Revenues</h2>
      </div>
      <div className="col-12 col-md-3 mb-3">
        <div className="profile-card overview-stats">
          <div className="overview-stats-icon">
            <Tooltip title={getStatsInfo("monthly_recurring_revenue")}>
              <Icon className="information-icon" icon={icon_alert_circle} />
            </Tooltip>
          </div>
          <h3>£{get_kmb_number(cardDetail["monthly_recurring_revenue"])}</h3>
          <h6 className="text-muted">Monthly Recurring Revenue</h6>
        </div>
      </div>
      <div className="col-12 col-md-3 mb-3">
        <div className="profile-card overview-stats">
          <div className="overview-stats-icon">
            <Tooltip title={getStatsInfo("yearly_recurring_revenue")}>
              <Icon className="information-icon" icon={icon_alert_circle} />
            </Tooltip>
          </div>
          <h3>£{get_kmb_number(cardDetail["yearly_recurring_revenue"])}</h3>
          <h6 className="text-muted">Yearly Recurring Revenue</h6>
        </div>
      </div>
      <div className="col-12 col-md-3 mb-3">
        <div className="profile-card overview-stats">
          <div className="overview-stats-icon">
            <Tooltip title={getStatsInfo("total_recurring_revenue")}>
              <Icon className="information-icon" icon={icon_alert_circle} />
            </Tooltip>
          </div>
          <h3>£{get_kmb_number(cardDetail["total_recurring_revenue"])}</h3>
          <h6 className="text-muted">Total Recurring Revenue</h6>
        </div>
      </div>
      <div className="col-12 col-md-3 mb-3">
        <div className="profile-card overview-stats">
          <div className="overview-stats-icon">
            <Tooltip
              title={<>{getStatsInfo("avg_recurring_revenue_per_user")}</>}
            >
              <Icon className="information-icon" icon={icon_alert_circle} />
            </Tooltip>
          </div>
          <h3>
            £{get_kmb_number(cardDetail["avg_recurring_revenue_per_user"])}
          </h3>
          <h6 className="text-muted">Average Recurring Revenue Per User</h6>
        </div>
      </div>
      <div className="col-12">
        <h2>Subscriptions</h2>
      </div>
      <div className="col-12 col-md-3 mb-3">
        <div className="profile-card overview-stats">
          <div className="overview-stats-icon">
            <Tooltip title={getStatsInfo("total_subscription_revenue")}>
              <Icon className="information-icon" icon={icon_alert_circle} />
            </Tooltip>
          </div>
          <h3>{get_kmb_number(cardDetail["total_subscription_revenue"])}</h3>
          <h6 className="text-muted">Subscription Renews</h6>
        </div>
      </div>
      {/* <div className="col-12 col-md-3 mb-3">
        <div className="profile-card">
          <h3>{get_kmb_number(cardDetail["30_day_active_users"])}</h3>
          <h6 className="text-muted">
            Churn Rate : users who unsubscribed from membership
          </h6>
        </div>
      </div> */}
      {/* <div className="col-12 col-md-3 mb-3">
        <div className="profile-card">
          <h3>{get_kmb_number(cardDetail["30_day_active_users"])}</h3>
          <h6 className="text-muted">Monthly growth in Paid memberships</h6>
        </div>
      </div> */}
      <div className="col-12">
        <h2>Book Call Stats / Month</h2>
      </div>
      <div className="col-12 col-md-3 mb-3">
        <div className="profile-card overview-stats">
          <div className="overview-stats-icon">
            <Tooltip title={getStatsInfo("total_monthly_bookings")}>
              <Icon className="information-icon" icon={icon_alert_circle} />
            </Tooltip>
          </div>
          <h3>{get_kmb_number(cardDetail["total_monthly_bookings"])}</h3>
          <h6 className="text-muted">Total Bookings</h6>
        </div>
      </div>
      {cardDetail.booking_counts_array.map((call) => {
        return (
          <div className="col-12 col-md-3 mb-3">
            <div className="profile-card overview-stats">
              <div className="overview-stats-icon">
                <Tooltip title={getStatsInfo(call.title)}>
                  <Icon className="information-icon" icon={icon_alert_circle} />
                </Tooltip>
              </div>
              <h3>{get_kmb_number(call.count)}</h3>
              <h6 className="text-muted">{call.title}</h6>
            </div>
          </div>
        );
      })}

      <div className="col-12">
        <h2>Mobile App</h2>
      </div>
      <div className="col-12 col-md-3 mb-3">
        <div className="profile-card overview-stats">
          <div className="overview-stats-icon">
            <Tooltip title={getStatsInfo("total_app_downloads")}>
              <Icon className="information-icon" icon={icon_alert_circle} />
            </Tooltip>
          </div>
          <h3>{get_kmb_number(cardDetail["total_app_downloads"])}</h3>
          <h6 className="text-muted">Total App Downloads</h6>
        </div>
      </div>
    </>
  );
}
