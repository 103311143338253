import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { useNavigate } from "react-router-dom";
import FilteredChip from "src/components/FilteredChip";
import moment from "moment";
import { dd_date_format, show_proper_words } from "src/utils/constant";
import { Icon } from "@iconify/react";
import PageLoader from "src/components/GeneralComponents/PageLoader";
import { firebase_page_and_screen_views } from "src/DAL/FirebaseAnalytics/FirebaseAnalytics";
import AnalyticsFilter from "src/components/FirebaseAnalytics/AnalyticsFilter";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import { DEFAULT_DATES, filterFirebaseData } from "src/utils/firebase_utils";
import { get_kmb_number } from "src/utils/formatNumber";

const EMPTY_FILTER = {
  ...DEFAULT_DATES,
};

const formatDuration = (seconds) => {
  if (!seconds || seconds <= 0) {
    return `0m 0s`;
  }
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}m ${remainingSeconds}s`;
};

export default function FirebasePageAndScreenViews() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [dataList, setDataList] = useState([]);
  const [openFilter, setOpenfilter] = useState(false);
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [updateFilterData, setUpdateFilterData] = useState(EMPTY_FILTER);

  const getCategoriesList = async (filterData) => {
    setIsLoading(true);
    let postData = { ...filterData };
    if (postData.start_date) {
      postData.start_date = moment(postData.start_date).format("YYYY-MM-DD");
    }
    if (postData.end_date) {
      postData.end_date = moment(postData.end_date).format("YYYY-MM-DD");
    }

    const result = await firebase_page_and_screen_views(postData);
    if (result.code == 200) {
      let chipData = { ...filterData };
      if (chipData.start_date) {
        let date = `Start Date: ${dd_date_format(
          chipData.start_date
        )} End Date:  ${dd_date_format(chipData.end_date)}`;
        chipData.date = {
          chip_label: date,
          chip_value: date,
        };
        delete chipData.start_date;
        delete chipData.end_date;
      }
      let events = filterFirebaseData(result.report, "unifiedScreenClass").map(
        (event) => {
          return {
            ...event,
            event_name: show_proper_words(event.eventName),
            sessions_per_user: Number(event.sessionsPerUser).toFixed(2),
            count_per_active_user: Number(
              event.event_count_per_active_user
            ).toFixed(2),
            average_session_duration: formatDuration(
              event.avg_engagement_time_per_active_user
            ),
            screenPageViews: get_kmb_number(event.screenPageViews),
            activeUsers: get_kmb_number(event.activeUsers),
            eventCount: get_kmb_number(event.eventCount),
          };
        }
      );

      setDataList(events);
      setUpdateFilterData(chipData);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleClearFilter = () => {
    getCategoriesList(EMPTY_FILTER);
    setUpdateFilterData(EMPTY_FILTER);
    setFilterData(EMPTY_FILTER);
    localStorage.removeItem("analytics_filter_country");
    setOpenfilter(false);
  };

  const searchFunction = () => {
    getCategoriesList(filterData);
    setOpenfilter(false);
    localStorage.setItem(
      "analytics_filter_country",
      JSON.stringify(filterData)
    );
  };

  const handleDeleteChip = (data, item) => {
    const { key } = item;
    if (key === "date" || key === "range_type") {
      data = { ...data, ...DEFAULT_DATES };
    }
    getCategoriesList(data);
    localStorage.setItem("analytics_filter_country", JSON.stringify(data));
    setFilterData(data);
  };

  const handleOpenDawer = () => {
    setOpenfilter(true);
  };

  const handleClickEvent = (row) => {
    navigate(`/firebase-events/${row?.eventName}`);
  };

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("analytics_filter_country");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
    }
    setFilterData(filter_data);
    setUpdateFilterData(filter_data);
    getCategoriesList(filter_data);
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "unifiedScreenClass",
      label: "Page Title And Screen Class",
    },
    { id: "screenPageViews", label: "Screen Page Views" },
    { id: "activeUsers", label: "Active Users" },
    { id: "eventCount", label: "Event Count" },
    // { id: "count_per_active_user", label: "Count Per Active User" },
    {
      id: "average_session_duration",
      label: "Average engagement time per user",
    },
    // {
    //   id: "sessions_per_user",
    //   label: "Engaged sessions per active user",
    // },
  ];

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-lg-4">
            <h2>Screens And Pages</h2>
          </div>
          <div className="col-lg-8 text-end">
            <button
              onClick={handleOpenDawer}
              className="small-contained-button ms-2"
            >
              Filter &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>
          </div>
        </div>
        <FilteredChip
          data={updateFilterData}
          tempState={filterData}
          EMPTY_FILTER={EMPTY_FILTER}
          onDeleteChip={handleDeleteChip}
          onClear={handleClearFilter}
        />
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={dataList}
          className="card-with-background"
          is_hide={true}
        />
      </div>
      <CustomDrawer
        isOpenDrawer={openFilter}
        onOpenDrawer={() => setOpenfilter(true)}
        onCloseDrawer={() => setOpenfilter(false)}
        pageTitle="Filter"
        componentToPassDown={
          <AnalyticsFilter
            filterData={filterData}
            setFilterData={setFilterData}
            searchFunction={searchFunction}
            handleClearFilter={handleClearFilter}
          />
        }
      />
    </>
  );
}
