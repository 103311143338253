// here we add all base urls and keys

import { update_root_value } from "src/utils/domUtils";

// Base URLS
export const baseUri = process.env.REACT_APP_API_BASE_URL;
export const s3baseUrl = process.env.REACT_APP_PIC_BASE_URL;
export const socketUrl = process.env.REACT_APP_SOCKET_URL;
export const socketBaseUri = process.env.REACT_APP_API_SOCKET_BASE_URL;
export const project_name = process.env.REACT_APP_PROJECT_NAME;
export const client_Url = process.env.REACT_APP_CLIENT_URL;
export const clientPortalUrl = process.env.REACT_APP_CLIENT_PORTAL_URL;
export const delegate_Url = process.env.REACT_APP_DELEGATE_BASE_URL;
export const project_mode = process.env.REACT_APP_PROJECT_MODE;
export const member_import_sample_file =
  process.env.REACT_APP_MEMBER_SAMPLE_FILE;
export const event_import_sample_file = process.env.REACT_APP_EVENT_SAMPLE_FILE;

export const material_theme_palate = {
  lighter: "#d4bff2",
  light: "#ede7f6",
  main: "#ede7f6",
  dark: "#e4c073",
  darker: "#e4c073",
  contrastText: "#fff",
};
material_theme_palate.main = "#e4c073";
/* Main theme color */
update_root_value("--portal-theme-primary", "#e4c073");
update_root_value("--text-primary-color", "white");
update_root_value("--text-secondary-color", "rgb(150, 150, 150)");
/* Main theme color end */

/* Main background color*/
update_root_value("--background-primary-color", "black");
update_root_value("--background-secondary-color", "#1d1c1d");

/* Main background color end*/

/* Sidebar Properties */
update_root_value("--sidebars-text-color", "white");
update_root_value("--sidebars-background-color", "#1d1c1d");
/* Sidebar Properties */

/* Popup / Popover properties  */
update_root_value("--popup-background-color", "#1d1c1d");
update_root_value("--popup-text-color", "white");
/* Popup / Popover properties end  */

/* Element properties */
update_root_value("--input-text-color", "#fff");
update_root_value("--input-placeholder-color", "#fff");
update_root_value("--input-background-primary", "#1d1c1d");
update_root_value("--input-background-secondary", "#1d1c1d");
/* Element properties ends*/

/* Button Properties */

update_root_value("--button-background-color", "#1c1c1c");
update_root_value("--button-hover-color", "#e4c073");

/* Button Properties ends*/

/* Audio Properties */
update_root_value("--audio-color", "rgb(56, 54, 54)");
/* Audio Properties ends*/

/* calendar Properties */
update_root_value("--calendar-selected-box", "#8b8b8b");
