import React from "react";
import { show_proper_words } from "src/utils/constant";
import { get_kmb_number } from "src/utils/formatNumber";
import { Icon } from "@iconify/react";
import { Tooltip } from "@mui/material";

export default function OvervewStats({ notifications }) {
  console.log("notifications  __notifications", notifications);

  const getStatsInfo = (event_name) => {
    switch (event_name) {
      case "notification_receive":
        return (
          <>
            Shows the number of times the "Notification Receive" event was
            triggered in your app.
            <br />
            This helps to understand how often users are receiving notifications
            in your app.
          </>
        );
      case "notification_dismiss":
        return (
          <>
            Shows the number of times the "Notification Dismiss" event was
            triggered in your app.
            <br />
            This helps to analyze how often users are dismissing notifications
            that were received.
          </>
        );
      case "notification_foreground":
        return (
          <>
            Shows the number of times the "Notification Foreground" event was
            triggered in your app.
            <br />
            This helps to understand how many notifications were shown when the
            app was in the foreground.
          </>
        );
      case "notification_open":
        return (
          <>
            Shows the number of times the "Notification Open" event was
            triggered in your app.
            <br />
            This helps to analyze how often users interact with notifications
            after receiving them.
          </>
        );
      case "first_open":
        return (
          <>
            Shows the number of times the "First Open" event was triggered in
            your app.
            <br />
            This helps to track how many users open the app for the first time.
          </>
        );
      case "app_remove":
        return (
          <>
            Shows the number of times the "App Remove" event was triggered in
            your app.
            <br />
            This helps to understand how often users uninstall the app.
          </>
        );
      case "in_app_purchase":
        return (
          <>
            Shows the number of times the "In-App Purchase" event was triggered
            in your app.
            <br />
            This helps to track purchases made by users within the app.
          </>
        );
      default:
        return "";
    }
  };

  return (
    <>
      {notifications.map((notification) => {
        return (
          <div className="col-12 col-md-6 col-lg-3 mb-3">
            <div className="profile-card overview-stats">
              <div className="overview-stats-icon">
                <Tooltip title={<>{getStatsInfo(notification.eventName)}</>}>
                  <Icon
                    className="information-icon"
                    icon={"eva:alert-circle-outline"}
                  />
                </Tooltip>
              </div>

              <h3>{get_kmb_number(notification.eventCount)}</h3>
              <h6 className="text-muted">
                {show_proper_words(notification.eventName)}
              </h6>
            </div>
          </div>
        );
      })}
    </>
  );
}
