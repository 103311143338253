import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import PageLoader from "src/components/GeneralComponents/PageLoader";
import NotifyUser from "./NotifyUser";
import {
  _get_membership_levels_mission_detail,
  _get_notification_detail,
  _send_mission_reminder,
} from "src/DAL/MissionApi/MissionApiNew";
import { show_proper_words } from "src/utils/constant";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

const badge_object = {
  badge_level_id: "",
  membership_status: "all",
};

export default function SendNotifications() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [notifyLevel, setNotifyLevel] = useState([badge_object]);
  const [inputs, setInputs] = useState({
    notification_statement: "",
    notification_description: "",
    notify_users: false,
    title: "",
    type: "mission",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingButton(true);

    let postData = {
      mission_id: params?.id,
      notify_users: inputs?.notify_users,
    };

    if (inputs?.notify_users) {
      postData.notify_level = notifyLevel;
      postData.notification_statement = inputs?.notification_statement;
      postData.notification_description = inputs?.notification_description;
    }

    const result = await _send_mission_reminder(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingButton(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingButton(false);
    }
  };

  const getNotificationDetail = async () => {
    setIsLoading(true);
    const result = await _get_notification_detail(params?.id);
    if (result.code === 200) {
      let notifyLevelSelected = result?.mission?.notify_level;

      setInputs((values) => ({
        ...values,
        ...result?.mission,
      }));

      if (!notifyLevelSelected || notifyLevelSelected.length == 0) {
        notifyLevelSelected = [badge_object];
      }
      setNotifyLevel(notifyLevelSelected);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getNotificationDetail();
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  let breadCrumbMenu = [
    {
      title: `${show_proper_words(inputs?.type)} (${inputs?.title})`,
      navigation: `/mission`,
      active: false,
    },
    {
      title: `Send ${show_proper_words(inputs?.type)} Notifications`,
      active: true,
    },
  ];

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 mb-3">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
        <div className="col-6">
          <h2>Send {show_proper_words(inputs?.type)} Notifications</h2>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Do You Want To Send A Notification To Users
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="notify_users"
                value={inputs?.notify_users}
                label="Do You Want To Send A Notification To Users"
                onChange={handleChange}
                required
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>

          {inputs?.notify_users && (
            <>
              <div className="notification_contnet_setting mt-3">
                <div className="setting-card px-2 px-md-3">
                  <div className="col-6 mb-2">
                    <h4 className="notification_contnet mb-4 ms-1">
                      Notification Contnet
                    </h4>
                  </div>

                  <div className="col-12 mt-3">
                    <TextField
                      id="outlined-basic"
                      label="Notification Statement"
                      variant="outlined"
                      fullWidth
                      name="notification_statement"
                      value={inputs?.notification_statement}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="col-12 mt-3">
                    <FormControl fullWidth>
                      <TextField
                        id="outlined-multiline-flexible"
                        label="Notification Description"
                        multiline
                        required
                        rows={2}
                        name="notification_description"
                        value={inputs?.notification_description}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>
                </div>
              </div>

              <NotifyUser
                badgesArray={notifyLevel}
                setBadgesArray={setNotifyLevel}
                badge_object={badge_object}
              />
            </>
          )}
        </div>

        <div className="text-end mt-4" id="fixedbutton">
          <button className="small-contained-button" disabled={isLoadingButton}>
            {isLoadingButton ? "Please Wait..." : "Send"}
          </button>
        </div>
      </form>
    </div>
  );
}
