import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { s3baseUrl } from "src/config/config";
import { IconButton, CircularProgress } from "@mui/material";
import {
  _create_membership_level_badge,
  _detail_membership_level_badge,
  _get_membership_levels,
  _update_membership_level_badge,
} from "src/DAL/MembershipLevel/MembershipLevelBadges/MembershipLevelBadges";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function AddOrUpdateMembershipLevelBadges({ type }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const { id, badge_id } = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setProfileImage] = useState(null);
  const [fileIcon, setFileIcon] = useState(null);
  const [formType, setFormType] = useState("ADD");
  const [serchText, setSerchText] = useState("");
  const [oldImage, setOldImage] = useState("");
  const [oldIconImage, setOldIconImage] = useState("");
  const [levelsList, setLevelsList] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [badgeLevelTitle, setbadgeLevelTitle] = useState(null);
  const [inputs, setInputs] = useState({
    title: "",
    short_description: "",
    detailed_description: "",
    tagline: "",
    alias_title: "",
    color_code: "#000",
    // upgrade_button_url: "",
    // upgrade_button_text: "",
    status: true,
    icon: {},
    image: {},
    order: 1,
    badge_level_id: "",
  });

  const handleBack = () => {
    if (type === "badge-level-listing") {
      navigate(`/badge-level`);
    } else {
      navigate(`/membership-level/${id}/membership-level-badges`);
    }
  };

  const fileIconChangedHandler = (e) => {
    setFileIcon(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["icon"]: e.target.files[0],
    });
  };

  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleFormat = (data) => {
    setInputs(data);
    if (data?.icon) {
      setOldIconImage(data?.icon?.thumbnail_1);
    }
    if (data?.image) {
      setOldImage(data?.image?.thumbnail_1);
    }
    setIsLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (inputs?.color_code == "") {
      enqueueSnackbar("Color is required!", { variant: "error" });
      return;
    }
    if (selectedLevel?.title == "") {
      enqueueSnackbar("membership level id is required!", { variant: "error" });
      return;
    }
    console.log(selectedLevel?._id, "selectedLevel?._idselectedLevel?._id");
    const formData = new FormData();
    formData.append("title", inputs?.title);
    formData.append("status", inputs?.status);
    formData.append("membership_level_id", selectedLevel?._id ?? id);
    formData.append("color_code", inputs?.color_code);
    formData.append("short_description", inputs?.short_description);
    formData.append("detailed_description", inputs?.detailed_description);
    formData.append("tagline", inputs?.tagline);
    formData.append("alias_title", inputs?.alias_title);
    // formData.append("upgrade_button_url", inputs?.upgrade_button_url);
    // formData.append("upgrade_button_text", inputs?.upgrade_button_text);

    if (fileIcon) {
      formData.append("icon", inputs?.icon);
    } else if (oldIconImage == "") {
      enqueueSnackbar("Icon is required!", { variant: "error" });
      return;
    }

    if (file) {
      formData.append("image", inputs?.image);
    } else if (oldImage == "") {
      enqueueSnackbar("Image is required!", { variant: "error" });
      return;
    }

    if (formType == "EDIT") {
      formData.append("order", inputs?.order);
    }

    setIsLoading(true);
    const result =
      formType == "ADD"
        ? await _create_membership_level_badge(formData)
        : await _update_membership_level_badge(formData, badge_id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      handleBack();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const get_membership_level_detail = async () => {
    setIsLoading(true);
    const result = await _detail_membership_level_badge(badge_id);
    if (result.code == 200) {
      handleFormat(result?.membership_level_badge);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getMembersBadgeLevels = async () => {
    const result = await _get_membership_levels(badge_id);
    if (result.code == 200) {
      setLevelsList(result?.membership_levels);
      console.log(result?.membership_levels, "result  ");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSelectPage = (value) => {
    console.log(value, "value valie value");
    setSelectedLevel(value);
  };

  useEffect(() => {
    if (badge_id) {
      setFormType("EDIT");
      if (state) {
        setbadgeLevelTitle(state.title);
        handleFormat(state);
      } else {
        get_membership_level_detail();
      }
    } else {
      getMembersBadgeLevels();
    }
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => handleBack()}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>

          <h2 className="ms-1">{`${
            formType == "ADD" ? "Add Badge Level" : "Edit Badge Level"
          }  `}</h2>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              fullWidth
              required
              name="title"
              value={inputs.title}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Tag line"
              variant="outlined"
              fullWidth
              required
              name="tagline"
              value={inputs?.tagline}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                value={inputs.status}
                label="Status"
                onChange={handleChange}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="color"
              type="color"
              label="Color"
              name="color_code"
              className=""
              variant="outlined"
              required={true}
              value={inputs.color_code ?? "#000"}
              onChange={handleChange}
            />
          </div>

          {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Alias Title"
              variant="outlined"
              fullWidth
              required
              name="alias_title"
              value={inputs?.alias_title}
              onChange={handleChange}
              disabled={inputs?.alias_title != "" && formType == "EDIT"}
            />
          </div> */}

          {!id && (
            <div className="col-12 col-md-6 mt-4">
              <MUIAutocomplete
                inputLabel="Select Membership Level"
                selectedOption={selectedLevel}
                setSelectedOption={handleSelectPage}
                optionsList={levelsList}
                autoComplete="new-password"
                name="title"
              />
            </div>
          )}

          {/* {formType == "EDIT" && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Order"
                variant="outlined"
                fullWidth
                required
                name="order"
                value={inputs.order}
                onChange={handleChange}
              />
            </div>
          )} */}

          <div className="col-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Upload Icon * </p>
                <FormHelperText className="pt-0">
                  Icon Size(100 X 100) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {fileIcon ? (
                  <img src={fileIcon} height="50" />
                ) : oldIconImage ? (
                  <img src={s3baseUrl + oldIconImage} height="50" />
                ) : (
                  ""
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file-icon">
                  <Input
                    accept="image/*"
                    id="contained-button-file-icon"
                    multiple
                    type="file"
                    name="icon"
                    onChange={fileIconChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.icon?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.icon?.name}</p>
            )}
          </div>

          <div className="col-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Upload Image * </p>
                <FormHelperText className="pt-0">
                  Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {file ? (
                  <img src={file} height="50" />
                ) : oldImage ? (
                  <img src={s3baseUrl + oldImage} height="50" />
                ) : (
                  ""
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="image"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.image?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.image?.name}</p>
            )}
          </div>

          <div className="col-12 mt-4">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Short Description"
                multiline
                rows={6}
                name="short_description"
                value={inputs?.short_description}
                onChange={handleChange}
                required
              />
              <FormHelperText>
                Short Description (Maximum limit 500 characters)
              </FormHelperText>
            </FormControl>
          </div>

          <div className="col-12 mt-4">
            <h4>Detailed Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="detailed_description"
              editorHeight={320}
            />
          </div>

          <div
            className="text-end mt-4 mb-3"
            id={formType == "ADD" ? "" : "fixedbutton"}
          >
            <button className="small-contained-button">
              {formType == "ADD" ? "Submit" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
