import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { Chip, Tooltip } from "@mui/material";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { useSnackbar } from "notistack";
import moment from "moment";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import { DeleteBookingApi, bookingListApi } from "src/DAL/booking/bookingApi";
import UpdateBooking from "./UpdateBooking";
import BookingFilter from "./BookingFilter";
import ChangeBookingStatus from "./ChangeBookingStatus";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import { dd_date_format } from "src/utils/constant";
import FilteredChip from "src/components/FilteredChip";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const TABLE_HEAD = [
  {
    id: "action",
    label: "Action",
    type: "action",
    MENU_OPTIONS: "MENU_OPTIONS",
  },
  { id: "number", label: "#", type: "number" },
  { id: "name", label: "Member With Booked Calls" },
  { id: "nurture_name", label: "Member's Nurture" },
  { id: "consultant_name", label: "Consultant In Booked Calls" },
  { id: "sale_page", label: "Sale Page" },
  { id: "booking_date", label: "Booking Date" },
  { id: "booking_time", label: "Duration" },
  { id: "created_at", label: "Created" },
  {
    id: "status",
    label: "Booking Status",
    renderData: (row) => {
      let find_status = row.booking_status_info;
      if (find_status) {
        return (
          <Tooltip title={find_status?.title}>
            <Chip
              label={find_status?.title}
              variant="contained"
              className="booking-status-chip"
              style={{
                backgroundColor: find_status.background_color,
                color: find_status.text_color,
                height: "22px",
              }}
              size="small"
            />
          </Tooltip>
        );
      }
    },
  },
  { id: "register_url", label: "Booking Url" },
];

export default function BookingList() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [deleteDoc, setDeleteDoc] = useState("");
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [updateDrawerState, setUpdateDrawerState] = useState(false);
  const [bookingId, setBookingId] = useState();
  const [DelegateData, setDelegateData] = useState();
  const [selectedDelegate, setSelectedDelegate] = useState(null);
  const [selectedBooking, setSelectedBooking] = useState({});
  const [changeStatus, setChangeStatus] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");

  const EMPTY_FILTER = {
    start_date: null,
    end_date: null,
    booking_status: null,
    consultant_id: null,
    search_text: "",
    filter_by_dates: false,
    sale_page: [],
    date: null,
  };
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [updateFilterData, setUpdateFilterData] = useState(EMPTY_FILTER);

  const handleSelectOther = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  const handleOpenFilterDrawer = (data) => {
    setFilterDrawerState(true);
  };

  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const handleOpenDrawerUpdate = (data) => {
    setBookingId(data._id);
    setSelectedDelegate(data.consultant);
    setDelegateData(data);
    setUpdateDrawerState(true);
  };

  const handleCloseDrawerUpdate = () => {
    setUpdateDrawerState(false);
  };

  const getBookingListing = async (filter_data) => {
    setIsLoading(true);
    let postData = { ...filter_data };

    if (postData.consultant_id) {
      postData.consultant_id = filter_data.consultant_id._id;
    }

    if (postData.sale_page) {
      postData.sale_page = filter_data.sale_page.map((item) => {
        return item._id;
      });
    }
    if (postData.booking_status) {
      postData.booking_status = postData.booking_status._id;
    }

    if (postData.start_date) {
      postData.start_date = moment(filter_data.start_date).format("YYYY-MM-DD");
    }

    if (postData.end_date) {
      postData.end_date = moment(filter_data.end_date).format("YYYY-MM-DD");
    }

    handleCloseFilterDrawer();

    const result = await bookingListApi(page, rowsPerPage, postData);
    if (result.code == 200) {
      let chipData = { ...filter_data };
      if (chipData.end_date) {
        let date = `Start Date : ${dd_date_format(
          chipData.start_date
        )} End Date :  ${dd_date_format(chipData.end_date)}`;
        chipData.date = {
          chip_label: date,
          chip_value: date,
        };
        delete chipData.start_date;
        delete chipData.end_date;
      }
      if (chipData.search_text) {
        delete chipData.search_text;
      }
      setUpdateFilterData(chipData);
      const members = result?.booking_list?.map((member, index) => {
        const booked = member.booked_slot_info;

        let name = "N/A";
        let email = "N/A";
        let consultant_name = "N/A";
        let nurture_name = "N/A";
        let consultant_email = "N/A";
        let sale_page = "N/A";
        let booking_date = "N/A";
        let booking_time = "N/A";
        let created_at = "N/A";

        if (member.nurture.first_name) {
          nurture_name =
            member.nurture.first_name +
            " " +
            member.nurture.last_name +
            " (" +
            member.nurture.email +
            ")";
        }

        if (member.user_info) {
          name =
            member.user_info.first_name +
            " " +
            member.user_info.last_name +
            " (" +
            member.user_info.email +
            ")";
        }

        if (member.consultant) {
          consultant_name =
            member.consultant.first_name +
            " " +
            member.consultant.last_name +
            " (" +
            member.consultant.email +
            ")";
        }

        if (member.page) {
          sale_page = member.page.sale_page_title;
        }

        const { time, date, slot_duration, time_zone } =
          member?.booked_slot_info;

        if (time && date) {
          const startDateTime = moment(
            `${date.split("T")[0]} ${time}`,
            "YYYY-MM-DD hh:mm A"
          );
          const endDateTime = startDateTime
            .clone()
            .add(slot_duration, "minutes");

          booking_time =
            startDateTime.format("hh:mm A") +
            " - " +
            endDateTime.format("hh:mm A") +
            " (" +
            time_zone +
            ")";
        }

        if (date) {
          booking_date = moment(date).format("DD-MM-YYYY");
        }

        if (member.createdAt) {
          created_at = moment(member.createdAt).format("DD-MM-YYYY");
        }

        return {
          ...member,
          name,
          email,
          consultant_name,
          consultant_email,
          sale_page,
          booking_date,
          booking_time,
          nurture_name,
          created_at,
          MENU_OPTIONS: handleMenu(member),
        };
      });

      setData(members);
      setTotalCount(result.total_booking_count);
      setIsLoading(false);
      setTotalPages(result.total_pages);
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    filterData.search_text = searchText;
    getBookingListing(filterData);
    localStorage.setItem("booking_list_filter", JSON.stringify(filterData));
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);
    if (!data.date) {
      delete data.date;
      data.start_date = null;
      data.filter_by_dates = false;
      data.end_date = null;
    }
    localStorage.setItem("booking_list_filter", JSON.stringify(data));
    getBookingListing(data);
    setUpdateFilterData(data);
    setFilterData(data);
  };

  const handleClearFilter = () => {
    setPage(0);
    setPageCount(1);
    getBookingListing(EMPTY_FILTER);
    setUpdateFilterData(EMPTY_FILTER);
    setFilterData(EMPTY_FILTER);
    localStorage.removeItem("booking_list_filter");
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await DeleteBookingApi(deleteDoc._id);
    if (result.code === 200) {
      getBookingListing(filterData);
      setIsLoading(false);

      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChaneOthers = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const handleChangeStatus = (value) => {
    setSelectedBooking(value);
    setChangeStatus(true);
  };

  const handlePersonelNotes = (value) => {
    delete value.MENU_OPTIONS;

    navigate(`/bookings/notes/${value._id}`, { state: value });
  };

  const handleDetail = (value) => {
    value.MENU_OPTIONS;
    if (value.is_question_available) {
      navigate(
        `/bookings/answers-detail/?created_for_id=${value.page._id}&member_id=${value.user_info._id}`
      );
    }
  };

  const handleMenu = (row) => {
    let MENU_OPTIONS = [
      {
        label: "Update",
        icon: "akar-icons:edit",
        handleClick: handleOpenDrawerUpdate,
      },
      {
        label: "Booking Notes",
        icon: "gridicons:pages",
        handleClick: handlePersonelNotes,
      },
      {
        label: "Change Status",
        icon: "akar-icons:edit",
        handleClick: handleChangeStatus,
      },
      {
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: handleAgreeDelete,
      },
    ];
    if (row.is_question_available) {
      MENU_OPTIONS.push({
        label: "Question Answers Detail",
        icon: "akar-icons:edit",
        handleClick: handleDetail,
      });
    }
    return MENU_OPTIONS;
  };

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("booking_list_filter");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      if (filter_data.search_text) {
        setSearchText(filter_data.search_text);
      }
    }
    setFilterData(filter_data);
    setUpdateFilterData(filter_data);
    getBookingListing(filter_data);
  }, [rowsPerPage, page]);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-lg-6 col-sm-12">
            <h2>Booking List</h2>
          </div>
          <div className="col-6 text-end">
            <button
              className="small-contained-button me-2 mt-1 mb-4 "
              onClick={handleOpenFilterDrawer}
            >
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>
          </div>
        </div>
        <FilteredChip
          data={updateFilterData}
          tempState={filterData}
          EMPTY_FILTER={EMPTY_FILTER}
          onDeleteChip={handleDeleteChip}
          onClear={handleClearFilter}
        />
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={data}
          className="card-with-background"
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
      </div>

      <CustomDrawer
        isOpenDrawer={updateDrawerState}
        onOpenDrawer={handleOpenDrawerUpdate}
        onCloseDrawer={handleCloseDrawerUpdate}
        pageTitle="Update Booking"
        componentToPassDown={
          <UpdateBooking
            setSelectedDelegate={setSelectedDelegate}
            selectedDelegate={selectedDelegate}
            DelegateData={DelegateData}
            bookingId={bookingId}
            onCloseDrawer={handleCloseDrawerUpdate}
            reload={getBookingListing}
          />
        }
      />
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filter"
        componentToPassDown={
          <BookingFilter
            handleChaneOthers={handleChaneOthers}
            filterData={filterData}
            handleSelectOther={handleSelectOther}
            searchFunction={searchFunction}
            handleClearFilter={handleClearFilter}
          />
        }
      />
      <GeneralPopUpModel
        open={changeStatus}
        setOpen={setChangeStatus}
        title={"Change Booking Status"}
        componentToPassDown={
          <ChangeBookingStatus
            selectedObject={selectedBooking}
            bookingsData={getBookingListing}
            setOpen={setChangeStatus}
          />
        }
      />
    </>
  );
}
