import * as React from "react";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { styled } from "@mui/material/styles";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  get_app_settings_api,
  update_app_settings_api,
} from "src/DAL/SiteSetting/siteSetting";
import { useEffect } from "react";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { s3baseUrl } from "src/config/config";
import { uploadImageOns3 } from "src/DAL/commonApi/commonApi";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const Input = styled("input")({
  display: "none",
});

const PAYMENT_METHODS = [
  {
    value: "credit_card",
    label: "Credit Card",
  },
  {
    value: "google_pay",
    label: "Google Pay",
  },
  {
    value: "play_store",
    label: "Play Store",
  },
  {
    value: "paypal",
    label: "Paypal",
  },
];

export default function MobileAppSetting() {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [pagesList, setPagesList] = useState([]);
  const [plansList, setPlansList] = useState([]);
  const [discountedPlansList, setDiscountedPlansList] = useState([]);
  const [freePlansList, setFreePlansList] = useState([]);
  const [missionsList, setMissionsList] = useState([]);

  const [inputs, setInputs] = useState({
    app_logo: "",
    login_background_image_for_app: "",
    signup_background_image_for_app: "",
    forgot_password_background_image_for_app: "",
    welcome_screen_background_image_for_app: "",
    default_sale_page: null,
    free_default_sale_page: null,
    default_plan: null,
    free_default_plan: null,
    basic_membership_app_msg_for_subscription: "",
    basic_membership_app_msg_for_already_subscribed: "",
    privacy_policy_link: "",
    terms_and_condition_link: "",
    login_page_text: "",
    login_page_top_heading_text: "",
    signup_page_text: "",
    signup_page_top_heading_text: "",
    account_deletion_description: "",
    app_update_description: "",
    registration_process_page_content: "",
    default_mission_for_app: null,
    paid_default_mission_for_app: null,
    is_wheel_of_life_enable: true,
    enable_phone_number_verification: false,
    discounted_subscription_plan_description: "",
    sale_page_for_discounted_plan: null,
    discount_plan_for_app: null,
    source_v2_tour_content: "",
    membership_plan_title: "",
    discounted_subscription_plan_title: "",
    allowed_payment_methods: [
      {
        value: "credit_card",
        label: "Credit Card",
      },
      {
        value: "play_store",
        label: "Play Store",
      },
    ],
  });

  const getDefaultSetting = async () => {
    const result = await get_app_settings_api();
    if (result.code === 200) {
      delete result.default_setting?._id;
      let setting = result.default_setting;
      setPagesList(result.sale_pages);
      setMissionsList(result.mission_levels);
      if (setting.default_sale_page) {
        let find_page = result.sale_pages.find(
          (page) => page._id == setting.default_sale_page
        );
        setting.default_sale_page = find_page;
        setPlansList(find_page.payment_plans);
        if (setting.default_plan) {
          let find_plan = find_page.payment_plans.find(
            (plan) => plan._id == setting.default_plan
          );
          setting.default_plan = find_plan;
        }
      }

      if (setting?.sale_page_for_discounted_plan) {
        let find_page = result.sale_pages.find(
          (page) => page._id == setting?.sale_page_for_discounted_plan
        );
        setting.sale_page_for_discounted_plan = find_page;
        setDiscountedPlansList(find_page.payment_plans);
        if (setting?.discount_plan_for_app) {
          let find_plan = find_page.payment_plans.find(
            (plan) => plan._id == setting?.discount_plan_for_app
          );
          setting.discount_plan_for_app = find_plan;
        }
      }

      if (setting?.paid_default_mission_for_app) {
        let paid_mission = result.mission_levels.find(
          (m) => m._id == setting?.paid_default_mission_for_app
        );
        setting.paid_default_mission_for_app = paid_mission;
      }
      if (setting?.default_mission_for_app) {
        let free_mission = result.mission_levels.find(
          (m) => m._id == setting?.default_mission_for_app
        );
        setting.default_mission_for_app = free_mission;
      }

      if (setting.free_default_sale_page) {
        let find_free_page = result.sale_pages.find(
          (page) => page._id == setting.free_default_sale_page
        );
        setting.free_default_sale_page = find_free_page;
        setFreePlansList(find_free_page.free_payment_plans);
        if (setting.free_default_plan) {
          let find_plan = find_free_page.free_payment_plans.find(
            (plan) => plan._id == setting.free_default_plan
          );
          setting.free_default_plan = find_plan;
        }
      }
      setInputs((old) => ({ ...old, ...setting }));
      setIsLoading(false);
    }
  };

  const fileChangedHandler = async (e, i) => {
    const { name } = e.target;
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    formData.append("width", "600");
    const imageUpload = await uploadImageOns3(formData);
    if (imageUpload.code == 200) {
      setInputs((old) => ({ ...old, [name]: imageUpload.image_path }));

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);

    let postData = {
      app_logo: inputs?.app_logo,
      allowed_payment_methods: inputs?.allowed_payment_methods,
      source_v2_tour_content: inputs?.source_v2_tour_content,
      membership_plan_title: inputs?.membership_plan_title,
      discounted_subscription_plan_title:
        inputs?.discounted_subscription_plan_title,
      login_background_image_for_app: inputs?.login_background_image_for_app,
      signup_background_image_for_app: inputs?.signup_background_image_for_app,
      forgot_password_background_image_for_app:
        inputs?.forgot_password_background_image_for_app,
      welcome_screen_background_image_for_app:
        inputs?.welcome_screen_background_image_for_app,
      basic_membership_app_msg_for_subscription:
        inputs?.basic_membership_app_msg_for_subscription,
      basic_membership_app_msg_for_already_subscribed:
        inputs?.basic_membership_app_msg_for_already_subscribed,
      privacy_policy_link: inputs?.privacy_policy_link,
      terms_and_condition_link: inputs?.terms_and_condition_link,
      login_page_text: inputs?.login_page_text,
      login_page_top_heading_text: inputs?.login_page_top_heading_text,
      signup_page_text: inputs?.signup_page_text,
      signup_page_top_heading_text: inputs?.signup_page_top_heading_text,
      account_deletion_description: inputs?.account_deletion_description,
      app_update_description: inputs?.app_update_description,
      registration_process_page_content:
        inputs?.registration_process_page_content,
      discounted_subscription_plan_description:
        inputs?.discounted_subscription_plan_description,
      is_wheel_of_life_enable: inputs?.is_wheel_of_life_enable,
      enable_phone_number_verification:
        inputs?.enable_phone_number_verification,
      default_sale_page: "",
      free_default_sale_page: "",
      default_plan: "",
      free_default_plan: "",
      default_mission_for_app: "",
      paid_default_mission_for_app: "",
      sale_page_for_discounted_plan: "",
      discount_plan_for_app: "",
    };

    if (inputs?.default_sale_page) {
      postData.default_sale_page = inputs?.default_sale_page?._id;
    }
    if (inputs?.free_default_sale_page) {
      postData.free_default_sale_page = inputs.free_default_sale_page?._id;
    }
    if (inputs?.default_plan) {
      postData.default_plan = inputs?.default_plan?._id;
    }
    if (inputs?.free_default_plan) {
      postData.free_default_plan = inputs?.free_default_plan?._id;
    }

    if (inputs?.default_mission_for_app) {
      postData.default_mission_for_app = inputs?.default_mission_for_app?._id;
    }
    if (inputs?.paid_default_mission_for_app) {
      postData.paid_default_mission_for_app =
        inputs?.paid_default_mission_for_app?._id;
    }

    if (inputs?.sale_page_for_discounted_plan) {
      postData.sale_page_for_discounted_plan =
        inputs?.sale_page_for_discounted_plan?._id;
    }

    if (inputs.discount_plan_for_app) {
      postData.discount_plan_for_app = inputs?.discount_plan_for_app?._id;
    }

    delete postData?.tinymce_key;

    const result = await update_app_settings_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeMethod = (value) => {
    const requiredMethods = ["credit_card", "play_store"];
    let requiredExists = requiredMethods.every((method) =>
      value.some(({ value }) => value === method)
    );
    if (requiredExists) {
      setInputs((values) => ({ ...values, allowed_payment_methods: value }));
    }
  };

  const handleDeleteImage = (path) => {
    setInputs((prev) => ({ ...prev, [path]: "" }));
  };

  useEffect(() => {
    getDefaultSetting();
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  const handleSelectPageDiscountedPlan = (value) => {
    setDiscountedPlansList(value.payment_plans);
    setInputs((old) => {
      return {
        ...old,
        discount_plan_for_app: null,
        sale_page_for_discounted_plan: value,
      };
    });
  };
  const handleSelectPage = (value) => {
    setPlansList(value.payment_plans);
    setInputs((old) => {
      return { ...old, default_plan: null, default_sale_page: value };
    });
  };

  const handleSelectFreePage = (value) => {
    setFreePlansList(value.free_payment_plans);
    setInputs((old) => {
      return { ...old, free_default_plan: null, free_default_sale_page: value };
    });
  };

  const handleSelectedPlan = (name, value) => {
    setInputs((old) => {
      return { ...old, [name]: value };
    });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-6">
          <h2>App Setting</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-sm-12 mt-4">
          <MUIAutocomplete
            inputLabel="Basic Membership Sale Page For App"
            selectedOption={inputs.default_sale_page}
            setSelectedOption={handleSelectPage}
            optionsList={pagesList}
            autoComplete="new-password"
            name="sale_page_title"
            required
          />
        </div>
        {plansList.length > 0 && (
          <div className="col-md-6 col-sm-12 mt-4">
            <MUIAutocomplete
              inputLabel="Basic Membership Plan For App"
              selectedOption={inputs.default_plan}
              setSelectedOption={(e) => {
                handleSelectedPlan("default_plan", e);
              }}
              optionsList={plansList}
              autoComplete="new-password"
              name="plan_title"
              required
            />
          </div>
        )}
        <div className="col-md-6 col-sm-12 mt-4">
          <MUIAutocomplete
            inputLabel="Discounted Sale Page For Discounted Plan"
            selectedOption={inputs.sale_page_for_discounted_plan}
            setSelectedOption={handleSelectPageDiscountedPlan}
            optionsList={pagesList}
            autoComplete="new-password"
            name="sale_page_title"
            required
          />
        </div>
        {discountedPlansList.length > 0 && (
          <div className="col-md-6 col-sm-12 mt-4">
            <MUIAutocomplete
              inputLabel="Discount Plan For App"
              selectedOption={inputs?.discount_plan_for_app}
              setSelectedOption={(e) => {
                handleSelectedPlan("discount_plan_for_app", e);
              }}
              optionsList={discountedPlansList}
              autoComplete="new-password"
              name="plan_title"
              required
            />
          </div>
        )}
        <div className="col-md-6 col-sm-12 mt-4">
          <MUIAutocomplete
            inputLabel="Free Sale Page For App"
            selectedOption={inputs.free_default_sale_page}
            setSelectedOption={handleSelectFreePage}
            optionsList={pagesList}
            autoComplete="new-password"
            name="sale_page_title"
            required
          />
        </div>
        {freePlansList.length > 0 && (
          <div className="col-md-6 col-sm-12 mt-4">
            <MUIAutocomplete
              inputLabel="Free Plan For App"
              selectedOption={inputs.free_default_plan}
              setSelectedOption={(e) => {
                handleSelectedPlan("free_default_plan", e);
              }}
              optionsList={freePlansList}
              autoComplete="new-password"
              name="plan_title"
              required
            />
          </div>
        )}
        <div className="col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Privacy Policy Link"
            variant="outlined"
            fullWidth
            name="privacy_policy_link"
            value={inputs.privacy_policy_link}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Terms And Condition Link"
            variant="outlined"
            fullWidth
            name="terms_and_condition_link"
            value={inputs.terms_and_condition_link}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-md-6 col-sm-12 mt-4">
          <TextField
            id="source_v2_tour_content"
            label="Source V2 Tour Video URL"
            variant="outlined"
            fullWidth
            name="source_v2_tour_content"
            value={inputs.source_v2_tour_content}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-md-6 col-sm-12 mt-4">
          <TextField
            id="membership_plan_title"
            label="Membership Plan Title"
            variant="outlined"
            fullWidth
            name="membership_plan_title"
            value={inputs.membership_plan_title}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-md-6 col-sm-12 mt-4">
          <TextField
            id="discounted_subscription_plan_title"
            label="Discounted Subscription Plan Title"
            variant="outlined"
            fullWidth
            name="discounted_subscription_plan_title"
            value={inputs.discounted_subscription_plan_title}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Is Wheel of Life Enable For Free User *
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="is_wheel_of_life_enable"
              value={inputs.is_wheel_of_life_enable}
              label="Is Wheel of Life Enable For Free User"
              onChange={handleChange}
              required
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
        </div>
        {missionsList.length > 0 && (
          <>
            <div className="col-md-6 col-sm-12 mt-4">
              <MUIAutocomplete
                inputLabel="Free Default Mission"
                selectedOption={inputs.default_mission_for_app}
                setSelectedOption={(e) => {
                  handleSelectedPlan("default_mission_for_app", e);
                }}
                optionsList={missionsList}
                autoComplete="new-password"
                name="title"
              />
            </div>
            <div className="col-md-6 col-sm-12 mt-4">
              <MUIAutocomplete
                inputLabel="Paid Default Mission"
                selectedOption={inputs.paid_default_mission_for_app}
                setSelectedOption={(e) => {
                  handleSelectedPlan("paid_default_mission_for_app", e);
                }}
                optionsList={missionsList}
                autoComplete="new-password"
                name="title"
              />
            </div>
          </>
        )}
        <div className="col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Is Enable Phone Number Verification *
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="enable_phone_number_verification"
              value={inputs.enable_phone_number_verification}
              label=" Is Enable Phone Number Verification *"
              onChange={handleChange}
              required
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <MUIAutocomplete
            inputLabel="Allowed Payment Methods"
            selectedOption={inputs.allowed_payment_methods}
            setSelectedOption={handleChangeMethod}
            optionsList={PAYMENT_METHODS.filter(
              (method) => method.value !== "fire"
            )}
            name="label"
            required
            multiple={true}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0 mb-2">
            <div className="col-5">
              <p className="">App Logo image</p>
              <FormHelperText className="pt-0">
                Recommended Size (50 X 600) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-2">
              {inputs?.app_logo && (
                <div className="del_img_icon">
                  <span
                    className="pointer_cursor"
                    onClick={() => {
                      handleDeleteImage("app_logo");
                    }}
                  >
                    X
                  </span>
                  <img
                    src={inputs?.app_logo ? s3baseUrl + inputs?.app_logo : ""}
                    className="imgage_border"
                    height="50"
                  />
                </div>
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file235">
                <Input
                  accept="image/*"
                  id="contained-button-file235"
                  multiple
                  type="file"
                  name="app_logo"
                  onChange={fileChangedHandler}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0 mb-2">
            <div className="col-5">
              <p className="">Login Page Background image</p>
              <FormHelperText className="pt-0">
                Recommended Size (470 X 1000) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-2">
              {inputs?.login_background_image_for_app && (
                <div className="del_img_icon">
                  <span
                    className="pointer_cursor"
                    onClick={() => {
                      handleDeleteImage("login_background_image_for_app");
                    }}
                  >
                    X
                  </span>
                  <img
                    src={
                      inputs?.login_background_image_for_app
                        ? s3baseUrl + inputs?.login_background_image_for_app
                        : ""
                    }
                    className="imgage_border"
                    height="50"
                  />
                </div>
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file2">
                <Input
                  accept="image/*"
                  id="contained-button-file2"
                  multiple
                  type="file"
                  name="login_background_image_for_app"
                  onChange={fileChangedHandler}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0 mb-2">
            <div className="col-5">
              <p className="">Signup Page Background image</p>
              <FormHelperText className="pt-0">
                Recommended Size (470 X 1000) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-2">
              {inputs?.signup_background_image_for_app && (
                <div className="del_img_icon">
                  <span
                    className="pointer_cursor"
                    onClick={() => {
                      handleDeleteImage("signup_background_image_for_app");
                    }}
                  >
                    X
                  </span>
                  <img
                    src={
                      inputs?.signup_background_image_for_app
                        ? s3baseUrl + inputs?.signup_background_image_for_app
                        : ""
                    }
                    className="imgage_border"
                    height="50"
                  />
                </div>
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file2su">
                <Input
                  accept="image/*"
                  id="contained-button-file2su"
                  multiple
                  type="file"
                  name="signup_background_image_for_app"
                  onChange={fileChangedHandler}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
        </div>{" "}
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0 mb-2">
            <div className="col-5">
              <p className="">Forgot Page Password Background Image</p>
              <FormHelperText className="pt-0">
                Recommended Size (470 X 1000) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-2">
              {inputs?.forgot_password_background_image_for_app && (
                <div className="del_img_icon">
                  <span
                    className="pointer_cursor"
                    onClick={() => {
                      handleDeleteImage(
                        "forgot_password_background_image_for_app"
                      );
                    }}
                  >
                    X
                  </span>
                  <img
                    src={
                      inputs?.forgot_password_background_image_for_app
                        ? s3baseUrl +
                          inputs?.forgot_password_background_image_for_app
                        : ""
                    }
                    className="imgage_border"
                    height="50"
                  />
                </div>
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file2fg">
                <Input
                  accept="image/*"
                  id="contained-button-file2fg"
                  multiple
                  type="file"
                  name="forgot_password_background_image_for_app"
                  onChange={fileChangedHandler}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0 mb-2">
            <div className="col-5">
              <p className="">Welcome Screen Background Image For App</p>
              <FormHelperText className="pt-0">
                Recommended Size (470 X 1000) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-2">
              {inputs?.welcome_screen_background_image_for_app && (
                <div className="del_img_icon">
                  <span
                    className="pointer_cursor"
                    onClick={() => {
                      handleDeleteImage(
                        "welcome_screen_background_image_for_app"
                      );
                    }}
                  >
                    X
                  </span>
                  <img
                    src={
                      inputs?.welcome_screen_background_image_for_app
                        ? s3baseUrl +
                          inputs?.welcome_screen_background_image_for_app
                        : ""
                    }
                    className="imgage_border"
                    height="50"
                  />
                </div>
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file2fg1">
                <Input
                  accept="image/*"
                  id="contained-button-file2fg1"
                  multiple
                  type="file"
                  name="welcome_screen_background_image_for_app"
                  onChange={fileChangedHandler}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
        </div>
        <div className="col-12 mt-4">
          <h4>Basic Membership App Message For Create Subscription *</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="basic_membership_app_msg_for_subscription"
            editorHeight={320}
          />
        </div>
        <div className="col-12 mt-4">
          <h4>Basic Membership App Message For Already Subscribed *</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="basic_membership_app_msg_for_already_subscribed"
            editorHeight={320}
          />
        </div>
        <div className="col-12 mt-4">
          <h4>Login Page Text *</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="login_page_text"
            editorHeight={320}
          />
        </div>
        <div className="col-12 mt-4">
          <h4>Login Page Top Heading Text *</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="login_page_top_heading_text"
            editorHeight={320}
          />
        </div>
        <div className="col-12 mt-4">
          <h4>Signup Page Text *</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="signup_page_text"
            editorHeight={320}
          />
        </div>
        <div className="col-12 mt-4">
          <h4>Signup Page Top Heading Text *</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="signup_page_top_heading_text"
            editorHeight={320}
          />
        </div>
        <div className="col-12 mt-4">
          <h4>Account Deletion Description *</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="account_deletion_description"
            editorHeight={320}
          />
        </div>
        <div className="col-12 mt-4">
          <h4>App Update Description *</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="app_update_description"
            editorHeight={320}
          />
        </div>
        <div className="col-12 mt-4">
          <h4>App Introduction Text *</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="registration_process_page_content"
            editorHeight={320}
          />
        </div>
        <div className="col-12 mt-4">
          <h4>Discounted Subscription Plan Description *</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="discounted_subscription_plan_description"
            editorHeight={320}
          />
        </div>
        <div className="text-end mt-4">
          <button
            onClick={handleSubmit}
            className="small-contained-button"
            id="fixedbutton"
            disabled={isLoadingForm}
          >
            {isLoadingForm ? "Updating" : "Update"}
          </button>
        </div>
      </div>
    </div>
  );
}
