import React from "react";
import MoveToNextPage from "./MoveToNextPage";
import { filterFirebaseData } from "src/utils/firebase_utils";
import { get_kmb_number } from "src/utils/formatNumber";

export default function OverviewEvents({ data }) {
  return (
    <>
      <div className="thirty-minutes-report">
        <div className="d-flex justify-content-between mt-3">
          <h3>Event Name</h3>
          <h3>Event Count</h3>
        </div>
        <hr />
        {filterFirebaseData(data)?.map((user) => {
          const { eventName, eventCount } = user;
          return (
            <>
              <div className="d-flex justify-content-between ">
                <div>{eventName}</div>
                <div>{get_kmb_number(eventCount)}</div>
              </div>
              <hr />
            </>
          );
        })}
      </div>
      <MoveToNextPage text="View all events" move_path="/firebase-events" />
    </>
  );
}
