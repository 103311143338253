import React from "react";
import { Avatar, Chip, Tooltip } from "@mui/material";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { useState } from "react";
import { useEffect } from "react";
import arrowCircleDownFill from "@iconify/icons-eva/arrow-circle-down-fill";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import moment from "moment";
import { Icon } from "@iconify/react";
import { download_csv_file } from "src/utils/convertHtml";
import FilteredChip from "src/components/FilteredChip";
import GeneralRequestForCsv from "../MembersList/GeneralRequestForCsv";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import PageLoader from "src/components/GeneralComponents/PageLoader";
import { useNavigate } from "react-router-dom";

let member_options = [
  {
    label: "First Name",
    value: "first_name",
    is_checked: true,
    is_disabled: true,
  },
  {
    label: "Last Name",
    value: "last_name",
    is_checked: true,
    is_disabled: true,
  },
  { label: "Email", value: "email", is_checked: true, is_disabled: true },
  {
    label: "Contact Number",
    value: "contact_number",
    is_checked: true,
    is_disabled: true,
  },
  {
    label: "Consultant",
    value: "consultant",
    is_checked: false,
  },
  {
    label: "Sale Page",
    value: "sale_page",
    is_checked: false,
  },
  {
    label: "Booking Date",
    value: "booking_date",
    is_checked: false,
  },
  {
    label: "Duration",
    value: "duration",
    is_checked: false,
  },
  {
    label: "Booking Url",
    value: "booking_url",
    is_checked: false,
  },
];

export default function BookingHistory({
  tabData,
  searchFunction,
  searchText,
  setSearchText,
  pageCount,
  totalPages,
  handleChangePages,
  custom_pagination,
  handleOpenFilterDrawer,
  filterStateUpdated,
  filterData,
  EMPTY_FILTER,
  ALTER_FILTER,
  handleDeleteChip,
  handleClearFilter,
  filterQuery,
}) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [membersData, setMembersData] = useState([]);
  const [data, setData] = useState([]);
  const [showExportcsvFile, setShowExportcsvFile] = useState(false);

  const moveToMemberProfile = (value) => {
    console.log("value  __moveToMemberProfile", value);
    navigate(`/member/member-profile/${value?.user_info?._id}`, {
      state: {
        ...value,
        move_from_page_title: "History",
        move_from_page_path: "/history",
      },
    });
  };
  const showPopUPcsv = () => {
    setShowExportcsvFile(true);
  };
  const getProgramsList = () => {
    setIsLoading(true);
    const members = tabData.map((member, index) => {
      const dateTime = moment().format("YYYY-MM-DD") + " " + member.time;
      let name = "N/A";
      let email = "N/A";
      let consultant_name = "N/A";
      let consultant_email = "N/A";
      let sale_page = "N/A";
      let booking_date = "N/A";
      let booking_time = "N/A";
      let created_at = "N/A";
      let booking_status = "";
      if (member.booking_status_info) {
        booking_status = member.booking_status_info.title;
      }

      if (member.user_info) {
        name = member.user_info.first_name + " " + member.user_info.last_name;
        email = member.user_info.email;
      }

      if (member.consultant) {
        consultant_name =
          member.consultant.first_name + " " + member.consultant.last_name;
        consultant_email = member.consultant.email;
      }

      if (member.page) {
        sale_page = member.page.sale_page_title;
      }

      if (member.date) {
        booking_date = moment(member.date).format("DD-MM-YYYY");
      }
      if (member.time) {
        booking_time =
          member.time +
          " to " +
          moment(dateTime, "DD-MM-YYYY hh:mm A")
            .add(member.slot_duration, "minutes")
            .format("hh:mm A");
      }
      if (member.createdAt) {
        created_at = moment(member.createdAt).format("DD-MM-YYYY");
      }

      return {
        ...member,
        name,
        email,
        consultant_name,
        booking_status,
        consultant_email,
        sale_page,
        booking_date,
        booking_time,
        created_at,
      };
    });

    setData(members);
    setIsLoading(false);
  };

  useEffect(() => {
    getProgramsList();
  }, [tabData]);
  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "name",
      label: "User Name",
      renderData: (row) => {
        return (
          <p
            className="mb-0"
            style={{ cursor: "pointer" }}
            onClick={() => moveToMemberProfile(row)}
          >
            {row?.name ? row?.name : "N/A"}
          </p>
        );
      },
    },
    {
      id: "email",
      label: "User Email",
      renderData: (row) => {
        return (
          <p
            className="mb-0"
            style={{ cursor: "pointer" }}
            onClick={() => moveToMemberProfile(row)}
          >
            {row?.email ? row?.email : "N/A"}
          </p>
        );
      },
    },
    { id: "consultant_name", label: "Consultant" },
    { id: "consultant_email", label: "Consultant Email" },
    { id: "sale_page", label: "Sale Page" },
    { id: "booking_date", label: "Booking Date" },
    { id: "booking_time", label: "Duration" },
    { id: "created_at", label: "Created At" },
    {
      id: "status",
      label: "Booking Status",
      renderData: (row) => {
        let find_status = row.booking_status_info;
        if (find_status) {
          return (
            <Tooltip title={find_status?.title}>
              <Chip
                label={find_status?.title}
                variant="contained"
                className="booking-status-chip"
                style={{
                  backgroundColor: find_status.background_color,
                  color: find_status.text_color,
                  height: "22px",
                }}
                size="small"
              />
            </Tooltip>
          );
        }
      },
    },
    { id: "register_url", label: "Booking Url" },
  ];
  const headers = [
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Consultant", key: "consultant_name" },
    { label: "Consultant Email", key: "consultant_email" },
    { label: "Sale Page", key: "sale_page" },
    { label: "Booking Date", key: "booking_date" },
    { label: "Booking Status", key: "booking_status" },
    { label: "Duration", key: "booking_time" },
    { label: "Created at", key: "created_at" },
  ];
  const handleExportClick = () => {
    download_csv_file(headers, data);
  };
  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="">
        <div className="button-history">
          <button
            className="small-contained-button me-2 mt-1 mb-4"
            onClick={showPopUPcsv}
          >
            Request For CSV &nbsp;&nbsp; <Icon icon={arrowCircleDownFill} />
          </button>
          <button
            className="small-contained-button ms-2"
            onClick={handleOpenFilterDrawer}
          >
            Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
          </button>
        </div>
        <FilteredChip
          data={filterStateUpdated}
          tempState={filterData}
          EMPTY_FILTER={EMPTY_FILTER}
          onDeleteChip={handleDeleteChip}
          onClear={handleClearFilter}
        />
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={data}
          className="card-with-background mt-3"
          custom_pagination={custom_pagination}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
        <GeneralPopUpModel
          open={showExportcsvFile}
          setOpen={setShowExportcsvFile}
          title="Export CSV file request"
          componentToPassDown={
            <GeneralRequestForCsv
              options_array={member_options}
              collection_name={"bookings_history"}
              filter_data={filterQuery}
              setShowExportcsvFile={setShowExportcsvFile}
            />
          }
        />
      </div>
    </>
  );
}
