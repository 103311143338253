import React, { useEffect, useState } from "react";
import Tab from "@mui/material/Tab";
import { Box, InputAdornment, OutlinedInput, Tabs } from "@mui/material";
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
import { styled } from "@mui/material/styles";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import AnalyticsFilter from "./AnalyticsFilter";
import FilteredChip from "src/components/FilteredChip";
import { useSnackbar } from "notistack";
import { dd_date_format } from "src/utils/constant";
import { google_analytics_report_overview } from "src/DAL/Analytics/Analytics";
import moment from "moment";
import AnalyticsChart from "./AnalyticsChart";
import PageLoader from "src/components/GeneralComponents/PageLoader";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  height: 44,
  color: "#fff",
  border: "1px solid #e4c073",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    boxShadow: theme.customShadows.z8,
    border: "1px solid #e4c073",
    width: 280,
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const formatDuration = (seconds) => {
  if (!seconds || seconds <= 0) {
    return `0m 0s`;
  }
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}m ${remainingSeconds}s`;
};

const AnalyticsReport = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [reportList, setReportList] = useState({});
  const [value, setValue] = useState(0);
  const [openFilter, setOpenfilter] = useState(false);
  const [searchText, setSearchText] = useState("");
  const EMPTY_FILTER = {
    start_date: new Date(new Date().setDate(new Date().getDate() - 6)),
    end_date: new Date(),
  };

  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [updateFilterData, setUpdateFilterData] = useState(EMPTY_FILTER);

  const handleSelectOther = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const getCategoriesList = async (filterData) => {
    let postData = { ...filterData };
    if (postData.start_date) {
      postData.start_date = moment(postData.start_date).format("YYYY-MM-DD");
    }
    if (postData.end_date) {
      postData.end_date = moment(postData.end_date).format("YYYY-MM-DD");
    }
    if (searchText) {
      postData.search = searchText;
    }
    setIsLoading(true);
    const result = await google_analytics_report_overview(postData);
    if (result.code == 200) {
      let chipData = { ...filterData };
      if (chipData.start_date) {
        let date = `Start Date: ${dd_date_format(
          chipData.start_date
        )} End Date:  ${dd_date_format(chipData.end_date)}`;
        chipData.date = {
          chip_label: date,
          chip_value: date,
        };
        delete chipData.start_date;
        delete chipData.end_date;
      }
      setDataList(result?.graphical_report);
      setReportList(result?.report);
      setUpdateFilterData(chipData);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleClearFilter = () => {
    getCategoriesList(EMPTY_FILTER);
    setUpdateFilterData(EMPTY_FILTER);
    setFilterData(EMPTY_FILTER);
    localStorage.removeItem("analytics_filter_overview");
    setOpenfilter(false);
  };

  const searchFunction = () => {
    setOpenfilter(false);
    getCategoriesList(filterData);
    localStorage.setItem(
      "analytics_filter_overview",
      JSON.stringify(filterData)
    );
  };

  const handleDeleteChip = (data) => {
    if (!data.date) {
      data.start_date = new Date(new Date().setDate(new Date().getDate() - 6));
      data.end_date = new Date();
    }
    getCategoriesList(data);
    localStorage.setItem("analytics_filter_overview", JSON.stringify(data));
    setFilterData(data);
  };

  const handleOpenDawer = () => {
    setOpenfilter(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSearchText = (event) => {
    setSearchText(event.target.value);
    localStorage.setItem("search_text_report", event.target.value);
  };

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("analytics_filter_overview");
    let find_search_text = localStorage.getItem("search_text_report");
    if (find_search_text) {
      setSearchText(find_search_text);
    }
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
    }

    setFilterData(filter_data);
    setUpdateFilterData(filter_data);
    getCategoriesList(filter_data);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      const timeoutId = setTimeout(() => {
        getCategoriesList(filterData);
      }, 800);

      return () => clearTimeout(timeoutId);
    }
  }, [searchText]);

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-3 mt-3">
          <div className="col-md-6">
            <h2>Overview</h2>
          </div>
          <div className="col-md-6 text-end">
            <SearchStyle
              className="ms-auto"
              value={searchText}
              onChange={handleSearchText}
              placeholder="Search Url"
              startAdornment={
                <InputAdornment position="start">
                  <Box
                    component={Icon}
                    icon={searchFill}
                    sx={{ color: "text.disabled" }}
                  />
                </InputAdornment>
              }
            />
            <button
              onClick={handleOpenDawer}
              className="small-contained-button ms-2"
            >
              Filter
            </button>
          </div>
        </div>
        {!isLoading && (
          <FilteredChip
            data={updateFilterData}
            tempState={filterData}
            EMPTY_FILTER={EMPTY_FILTER}
            onDeleteChip={handleDeleteChip}
            onClear={handleClearFilter}
          />
        )}
        {isLoading ? (
          <PageLoader />
        ) : (
          <div className="col-12 mt-3">
            <div className="card b_radius_16">
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    className="pb-0"
                    label={
                      <>
                        Total User
                        <br />
                        <h3 className="me-auto">
                          {reportList?.totalUsers ?? 0}
                        </h3>
                      </>
                    }
                    {...a11yProps(0)}
                  />
                  <Tab
                    className="pb-0"
                    label={
                      <>
                        Active User
                        <br />{" "}
                        <h3 className="me-auto">
                          {reportList?.activeUsers ?? 0}
                        </h3>
                      </>
                    }
                    {...a11yProps(1)}
                  />
                  <Tab
                    className="pb-0"
                    label={
                      <>
                        New User
                        <br />{" "}
                        <h3 className="me-auto">{reportList?.newUsers ?? 0}</h3>
                      </>
                    }
                    {...a11yProps(2)}
                  />
                  <Tab
                    className="pb-0"
                    label={
                      <>
                        Average engagement time
                        <br />{" "}
                        <h3 className="me-auto">
                          {formatDuration(reportList?.averageSessionDuration)}
                        </h3>
                      </>
                    }
                    {...a11yProps(3)}
                  />
                </Tabs>
              </Box>
              {dataList.length > 0 && (
                <>
                  <CustomTabPanel value={value} index={0}>
                    <AnalyticsChart dataList={dataList} metric="Users" />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <AnalyticsChart dataList={dataList} metric="Active Users" />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={2}>
                    <AnalyticsChart dataList={dataList} metric="New Users" />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={3}>
                    <AnalyticsChart
                      dataList={dataList}
                      metric="Sessions Per User"
                    />
                  </CustomTabPanel>
                </>
              )}
            </div>
          </div>
        )}
      </div>
      <GeneralPopUpModel
        open={openFilter}
        setOpen={setOpenfilter}
        title={"Filter"}
        componentToPassDown={
          <AnalyticsFilter
            filterData={filterData}
            handleSelectOther={handleSelectOther}
            searchFunction={searchFunction}
            handleClearFilter={handleClearFilter}
          />
        }
      />
    </>
  );
};

export default AnalyticsReport;
