import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { addChallengeCoinConfigurationApi } from "src/DAL/challenges/challenges";
import { useSnackbar } from "notistack";
import { countries } from "src/utils/country";
import Autocomplete from "@mui/material/Autocomplete";
import { addTax, updateTaxApi } from "src/DAL/Tax/TaxApi";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";

const TaxesConfiguration = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [addArray, setaddArray] = useState([{ tax_type: "", percentage: "" }]);
  const [chooseTax, setChooseTax] = useState("");
  const [country, setCountry] = useState({
    code: "IE",
    label: "Ireland",
    phone: "353",
  });
  const [inputs, setInputs] = useState({
    title: "",
    status: true,
    percentage: "",
    short_description: "",
  });
  const handleAdd = () => {
    const addedElement = [...addArray, { tax_type: "", tax_percent: "" }];
    setaddArray(addedElement);
  };
  const handleDelete = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);
    setaddArray(delValue);
  };
  const handleChangeInputs = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...addArray];
    list[index][name] = value;
    setaddArray(list);
  };
  const handleChangeCountry = (index, value) => {
    // const { name, value } = e.target;
    const list = [...addArray];
    list[index]["country"] = value;
    setaddArray(list);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (addArray.length == 2) {
    //   if (addArray[0].tax_type == addArray[1].tax_type) {
    //     enqueueSnackbar("Tax Types Can Not Be Same !", { variant: "error" });
    //     return;
    //   }
    // }

    setIsLoading(true);
    let postData = {
      // want_to_configure_tex: chooseTax,
      // tex_items: chooseTax ? addArray : [],
      country: country?.label,
      tax_type_title: inputs.title,
      status: inputs.status,
      short_description: inputs.short_description,
      percentage: inputs.percentage,
    };
    // console.log(postData, "challenge_coin_reward");
    const result = id
      ? await updateTaxApi(id, postData)
      : await addTax(postData);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate("/taxes");
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (state) {
      let selectedCountry = countries.find(
        (element) => element.label === state.country
      );

      setCountry(selectedCountry);
      setInputs(state);
    }
  }, [state]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 d-flex mb-3">
          <span>
            <IconButton
              className="back-screen-button"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2>{id ? "Edit" : "Add"} Taxes</h2>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 ">
              <TextField
                id="outlined-basic"
                label="Tax Type Title"
                variant="outlined"
                fullWidth
                required
                name="title"
                value={inputs.title}
                onChange={handleChangeInputs}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 ">
              <Autocomplete
                id="country-select-demo"
                options={countries}
                autoHighlight
                value={country}
                onChange={(event, newValue) => {
                  setCountry(newValue);
                }}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.label} ({option.code}) +{option.phone}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose a country"
                    name="country"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
              <TextField
                id="outlined-basic"
                label="Tax Up to %"
                variant="outlined"
                name="percentage"
                fullWidth
                required
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                  inputProps: { max: 100 },
                }}
                value={inputs.percentage}
                onChange={handleChangeInputs}
                sx={{ background: "#141717", borderRadius: "5px" }}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-3 ">
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">Status </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  required
                  value={inputs.status}
                  label="Status *"
                  onChange={handleChangeInputs}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-12 mt-4">
              <h4>Description </h4>
              <GeneralCkeditor
                setInputs={setInputs}
                inputs={inputs}
                name="short_description"
                editorHeight={320}
              />
            </div>

            {/* {chooseTax &&
              addArray?.map((data, index) => {
                // console.log(data, "data");
                return (
                  <div className="mb-3 mt-3 d-flex">
                    <div className="col-lg-6 me-2">
                      <FormControl fullWidth required>
                        <InputLabel required id="demo-simple-select-label">
                          Tax Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="tax_type"
                          value={data.tax_type}
                          label="Tax Type"
                          onChange={(e) => handleChange(e, index)}
                        >
                          <MenuItem value="sale">Sale</MenuItem>
                          <MenuItem value="vat">Vat</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-lg-5 me-2">
                      <TextField
                        id="outlined-basic"
                        label="Tax Up to %"
                        variant="outlined"
                        name="tax_percent"
                        fullWidth
                        required
                        type="number"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                          inputProps: { min: 0, max: 100 },
                        }}
                        // InputProps={{ inputProps: { min: 0, max: 100 } }}
                        value={data.tax_percent}
                        onChange={(e) => handleChange(e, index)}
                        sx={{ background: "#141717", borderRadius: "5px" }}
                      />
                    </div>
                    <span className="cross-icon">
                      {addArray.length > 1 ? (
                        <Tooltip title="Remove">
                          <RemoveCircleOutlineIcon
                            onClick={() => handleDelete(index)}
                            className="diary-icon-remove"
                          />
                        </Tooltip>
                      ) : (
                        ""
                      )}

                      {addArray.length < 2 && (
                        <Tooltip title="Add">
                          <AddCircleOutlineIcon
                            onClick={() => handleAdd()}
                            className="diary-icon-add"
                          />
                        </Tooltip>
                      )}
                    </span>
                  </div>
                );
              })} */}
          </div>
          <div className="text-end mt-4" id={id ? "fixedbutton" : ""}>
            <button className="small-contained-button">
              {isLoading ? "Submitting..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TaxesConfiguration;
