import { Chip } from "@mui/material";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { dd_date_format } from "src/utils/constant";

export default function MemberMissions({ missionList }) {
  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "title",
      label: " Title",
    },
    {
      id: "mission_duration",
      label: "Duration (Days)",
    },
    { id: "mission_attracted_coins", label: "Coins Earned" },
    { id: "mission_reward_coins", label: "Achievable Coins" },
    { id: "badge_level_info", label: "Badge Level" },
    {
      id: "mission_start_date",
      label: "Start Date",
      renderData: (row) => {
        return (
          <span>
            {row?.mission_start_date
              ? dd_date_format(row?.mission_start_date)
              : "N/A"}
          </span>
        );
      },
    },

    {
      id: "mission_status",
      label: "Status",
      renderData: (row) => {
        let status = row?.mission_status;
        let mission_completed_date = row?.mission_completed_date ?? "";
        let color = row?.mission_status == "Completed" ? "success" : "info";
        if (mission_completed_date) {
          mission_completed_date = `On ${dd_date_format(
            mission_completed_date
          )}`;
        }
        return (
          <span>
            <Chip
              label={status + " " + mission_completed_date}
              color={color}
              // variant="outlined"
              size="small"
            />
          </span>
        );
      },
    },
  ];

  return (
    <>
      <div className="mt-2">
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={missionList}
          className="card-with-background"
          pagePagination={true}
        />
      </div>
    </>
  );
}
