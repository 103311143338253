import { useEffect, useState } from "react";
import {
  Avatar,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import { useSnackbar } from "notistack";
import CircleIcon from "@mui/icons-material/Circle";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RecordNotFound from "src/components/RecordNotFound";
import {
  delete_lesson_recording_api,
  lesson_recording_list_api,
} from "src/DAL/Programme/Lessons/LessonRecordings/LessonRecordings";
import { lesson_detail_api } from "src/DAL/Programme/Lessons/Lessons";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import { usePGIMode } from "src/Hooks/PGIModeContext";
import ListIcon from "@mui/icons-material/List";
import GridViewIcon from "@mui/icons-material/GridView";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import PageLoader from "src/components/GeneralComponents/PageLoader";

// ----------------------------------------------------------------------

const Progrmme_status = [
  {
    name: "Active",
    value: true,
    class: "active_bg_program",
  },
  {
    name: "InActive",
    value: false,
    class: "inactive_bg_program",
  },
];
export default function LessonRecordings() {
  const { lesson_slug } = useParams();
  const { viewModeLessonsRecording, setViewModeLessonsRecording } =
    usePGIMode();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [recordings, setRecordings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [lessonName, setlessonName] = useState("");
  const [lessonDetailInfo, setLessonsDetailInfo] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [recordingDetail, setRecordingDetail] = useState();

  const { state } = useLocation();
  let programName = lessonDetailInfo?.program[0]?._id?.title;
  let program_slug_navgation = lessonDetailInfo?.program[0]?._id?.program_slug;
  const toggleView = () => {
    setViewModeLessonsRecording((prevMode) =>
      prevMode === "grid" ? "table" : "grid"
    );
  };
  const getFilteredData = (query) => {
    let dataToFilter = [...recordings];
    if (query) {
      let resultFilter = dataToFilter.filter((post) =>
        post.title.toLowerCase().includes(query.toLowerCase())
      );
      return resultFilter;
    } else {
      return dataToFilter;
    }
  };
  const handleChangeSearch = (e) => {
    setQuery(e.target.value);
  };

  const recordingList = async () => {
    const result = await lesson_recording_list_api(lesson_slug);
    if (result.code == 200) {
      setIsLoading(false);
      setRecordings(result.recording);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleDetail = async () => {
    setIsLoading(true);
    let result = await lesson_detail_api(lesson_slug);
    if (result.code === 200) {
      setLessonsDetailInfo(result.lesson);

      setlessonName(result.lesson.title);

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleNavigateDetail = (value) => {
    navigate(
      `/lessons/lesson-recordings/recording-detail/${value.recording_slug}`,
      {
        state: value,
      }
    );
  };
  let breadCrumbMenu = [
    {
      title: "Programmes",
      navigation: `/programmes`,
      active: false,
    },
    {
      title: programName,
      navigation: `/programmes/programmes-detail/${program_slug_navgation}`,
      active: false,
    },
    {
      title: "Lessons",
      navigation: `/lessons/${program_slug_navgation}`,
      active: false,
    },
    {
      title: lessonDetailInfo?.title,
      navigation: `/lessons/lesson-detail/${lesson_slug}`,
      active: false,
    },
    {
      title: "Recordings",
      navigation: ``,
      active: true,
    },
  ];
  const handleOpenDelete = (value) => {
    setOpenDelete(true);
    setRecordingDetail(value);
  };
  const handleCloseDelete = () => setOpenDelete(false);
  const handleDelete = async () => {
    handleCloseDelete();
    setIsLoading(true);
    let result = await delete_lesson_recording_api(
      recordingDetail.recording_slug
    );
    if (result.code === 200) {
      recordingList();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleNavigateEdit = (value) => {
    navigate(
      `/lessons/lesson-recordings/edit-recording/${value.recording_slug}`,
      {
        state: recordingDetail,
      }
    );
  };
  let MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleNavigateEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleOpenDelete,
    },
  ];
  const TABLE_HEAD = [
    { id: "action", label: "Action", alignRight: false, type: "action" },
    { id: "number", label: "#", alignRight: false, type: "number" },

    {
      id: "title",
      label: "Image",
      alignRight: false,
      renderData: (row) => {
        console.log(row, "rowrow");
        return (
          <>
            {" "}
            {
              <Avatar
                src={s3baseUrl + row.recording_image.thumbnail_1}
                alt={row.title}
              />
            }
          </>
        );
      },
    },
    {
      id: "title",
      label: "Title",
      alignRight: false,
      renderData: (row) => {
        console.log(row, "rowrowrow");
        let find_status = Progrmme_status.find(
          (status) => status.value == row.status
        );
        const iconStyle = {
          color: "blue !important",
        };

        return (
          <div className="d-flex pointer">
            <Tooltip title={find_status?.name} className="pointer">
              <CircleIcon
                style={iconStyle}
                className={`transation-status-icon ${find_status?.class}`}
              />
            </Tooltip>
            <p
              className="mb-0 "
              style={{ cursor: "pointer" }}
              onClick={() => handleNavigateDetail(row)}
            >
              {" "}
              {row.title}
            </p>
          </div>
        );
      },
    },

    { id: "order", label: "Order", alignRight: false },
    { id: "recording_date", label: "Recording Date", alignRight: false },
  ];

  useEffect(() => {
    recordingList();
  }, []);
  useEffect(() => {
    if (state) {
      setLessonsDetailInfo(state);
    } else {
      handleDetail();
    }
  }, []);

  if (isLoading === true) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 d-flex mb-3">
          <span className="mt-2">
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>

        <div className="col-lg-4 col-md-8 ">
          <h2>Recordings</h2>
        </div>
        <div
          className="col-lg-8 col-md-4 text-end"
          style={{ display: "flex", justifyContent: "end" }}
        >
          <div>
            <FormControl variant="outlined" style={{ marginTop: "2px" }}>
              <InputLabel htmlFor="outlined-adornment-password">
                Search
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type="text"
                variant="small"
                onChange={handleChangeSearch}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label="Search"
              />
            </FormControl>
          </div>
          <button
            onClick={() =>
              navigate(
                `/lessons/lesson-recordings/${lesson_slug}/add-recording`
              )
            }
            className="ms-2 mt-1 small-contained-button"
          >
            Add Recording
          </button>
          <button
            onClick={toggleView}
            className="ms-1 mt-1 small-contained-button"
          >
            {viewModeLessonsRecording == "grid" ? (
              <>
                <GridViewIcon />
              </>
            ) : (
              <>
                <ListIcon />
              </>
            )}
          </button>
        </div>
        {viewModeLessonsRecording == "grid" ? (
          <>
            {getFilteredData(query).length < 1 ? (
              <RecordNotFound title="Recordings" />
            ) : (
              getFilteredData(query).map((value, index) => {
                return (
                  <>
                    <div
                      className="col-lg-4 col-md-4 col-sm-12 mb-4"
                      key={index}
                    >
                      <div className="card mt-4 pods-cards-shadow cursor h-100">
                        <img
                          src={s3baseUrl + value.recording_image.thumbnail_1}
                          className="card-img-top pods-image"
                          alt="Pods"
                          onClick={() => handleNavigateDetail(value)}
                        />
                        <div className="card-body">
                          <div className="row">
                            <div className="col-12">
                              <h3 className="h2-heading">
                                {htmlDecode(value.title)}
                              </h3>
                            </div>
                          </div>
                          <p className="programme-card-desc mb-3">
                            {htmlDecode(value.short_description)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            )}
          </>
        ) : (
          <div className="mt-4">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              MENU_OPTIONS={MENU_OPTIONS}
              data={getFilteredData(query)}
              className="card-with-background"
              pagePagination={true}
              is_hide={true}
            />
          </div>
        )}
        <CustomConfirmation
          open={openDelete}
          setOpen={setOpenDelete}
          title={"Are you sure you want to delete this recording?"}
          handleAgree={handleDelete}
        />
      </div>
    </div>
  );
}
