import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import FilteredChip from "src/components/FilteredChip";
import { useSnackbar } from "notistack";
import { dd_date_format } from "src/utils/constant";
import moment from "moment";
import PageLoader from "src/components/GeneralComponents/PageLoader";
import ActiveUsersChart from "src/components/FirebaseAnalytics/ActiveUsersChart";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import { firebase_analytics_overview } from "src/DAL/FirebaseAnalytics/FirebaseAnalytics";
import AnalyticsFilter from "src/components/FirebaseAnalytics/AnalyticsFilter";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import EngagementChart from "src/components/FirebaseAnalytics/EngagementChart";
import ThirtyMinutesReport from "src/components/FirebaseAnalytics/ThirtyMinutesReport";
import AnalyticsChart from "src/components/FirebaseAnalytics/AnalyticsChart";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import OverviewEvents from "src/components/FirebaseAnalytics/OverviewEvents";
import PageAndScreenViews from "src/components/FirebaseAnalytics/PageAndScreenViews";
import OvervewStats from "src/components/FirebaseAnalytics/OvervewStats";
import {
  DEFAULT_DATES,
  formatFirebaseDuration,
} from "src/utils/firebase_utils";
import { Tooltip } from "@mui/material";

const EMPTY_FILTER = {
  ...DEFAULT_DATES,
};
const FirebaseOverview = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [overviewData, setOverviewData] = useState({});
  const [engagementCard, setEngagementCard] = useState({});
  const [openFilter, setOpenfilter] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [updateFilterData, setUpdateFilterData] = useState(EMPTY_FILTER);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getCategoriesList = async (filterData) => {
    setIsLoading(true);
    let postData = { ...filterData };
    if (postData.start_date) {
      postData.start_date = moment(postData.start_date).format("YYYY-MM-DD");
    }
    if (postData.end_date) {
      postData.end_date = moment(postData.end_date).format("YYYY-MM-DD");
    }
    const result = await firebase_analytics_overview(postData);
    if (result.code == 200) {
      let chipData = { ...filterData };
      if (chipData.start_date) {
        let date = `Start Date: ${dd_date_format(
          chipData.start_date
        )} End Date:  ${dd_date_format(chipData.end_date)}`;
        chipData.date = {
          chip_label: date,
          chip_value: date,
        };
        delete chipData.start_date;
        delete chipData.end_date;
      }
      setOverviewData(result);
      setEngagementCard(result.engagement_card);
      setUpdateFilterData(chipData);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleClearFilter = () => {
    getCategoriesList(EMPTY_FILTER);
    setUpdateFilterData(EMPTY_FILTER);
    setFilterData(EMPTY_FILTER);
    localStorage.removeItem("analytics_filter_overview");
    setOpenfilter(false);
  };

  const searchFunction = () => {
    setOpenfilter(false);
    getCategoriesList(filterData);
    localStorage.setItem(
      "analytics_filter_overview",
      JSON.stringify(filterData)
    );
  };

  const handleDeleteChip = (data, item) => {
    const { key } = item;
    if (key === "date" || key === "range_type") {
      data = { ...data, ...DEFAULT_DATES };
    }
    getCategoriesList(data);
    localStorage.setItem("analytics_filter_overview", JSON.stringify(data));
    setFilterData(data);
  };

  const handleOpenDawer = () => {
    setOpenfilter(true);
  };

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("analytics_filter_overview");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
    }

    setFilterData(filter_data);
    setUpdateFilterData(filter_data);
    getCategoriesList(filter_data);
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  const TABS_OPTIONS = [
    {
      title: (
        <>
          <div className="d-flex justify-content-between">
            <span>
              Average Engagement time per Active User (
              {formatFirebaseDuration(
                engagementCard?.times?.avg_engagement_time
              )}
              )
            </span>

            <Tooltip
              title={
                <>
                  Average engagement time per active user for the time period
                  selected.
                </>
              }
            >
              <Icon
                className="information-icon"
                icon={"eva:alert-circle-outline"}
              />
            </Tooltip>
          </div>
        </>
      ),
      // title: `Average Engagement time per Active User (${formatFirebaseDuration(
      //   engagementCard?.times?.avg_engagement_time
      // )})`,
      component: (
        <EngagementChart
          data={engagementCard?.avg_engagement_time_graph}
          metric="Average Engagement time per Active User"
        />
      ),
    },
    {
      // title: `Engaged sessions per active user (${Number(
      //   engagementCard.times.sessionsPerUser
      // ).toFixed(2)})`,

      title: (
        <>
          <div className="d-flex justify-content-between">
            <span>
              Engaged sessions per active user (
              {Number(engagementCard.times.sessionsPerUser).toFixed(2)})
            </span>

            <Tooltip
              title={
                <>
                  Average session count per active user for the time period
                  selected.
                </>
              }
            >
              <Icon
                className="information-icon"
                icon={"eva:alert-circle-outline"}
              />
            </Tooltip>
          </div>
        </>
      ),

      component: (
        <AnalyticsChart
          data={engagementCard.avg_session_count_per_user_graph}
          metric={`Engaged sessions per active user`}
          name="sessionsPerUser"
        />
      ),
    },
    {
      // title: `Average engagement time per session (${formatFirebaseDuration(
      //   engagementCard.times.averageSessionDuration
      // )})`,

      title: (
        <>
          <div className="d-flex justify-content-between">
            <span>
              Average engagement time per session (
              {formatFirebaseDuration(
                engagementCard.times.averageSessionDuration
              )}
              )
            </span>

            <Tooltip title={<>User engagement duration per session.</>}>
              <Icon
                className="information-icon"
                icon={"eva:alert-circle-outline"}
              />
            </Tooltip>
          </div>
        </>
      ),

      component: (
        <EngagementChart
          data={engagementCard?.avg_engagement_time_per_sessions_graph}
          metric="Average engagement time per session"
          name="averageSessionDuration"
        />
      ),
    },
  ];

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-3 mb-3">
          <div className="col-md-6">
            <h2 className="theme-color">Overview</h2>
          </div>
          <div className="col-md-6 text-end">
            <button
              onClick={handleOpenDawer}
              className="small-contained-button ms-2"
            >
              Filter &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>
          </div>
          <FilteredChip
            data={updateFilterData}
            tempState={filterData}
            EMPTY_FILTER={EMPTY_FILTER}
            onDeleteChip={handleDeleteChip}
            onClear={handleClearFilter}
          />
        </div>
        <div className="row">
          <OvervewStats notifications={overviewData.notification_analytics} />
          <div className="col-lg-8 mb-3">
            <div className="card p-3 height-100">
              <ActiveUsersChart
                data={overviewData.new_and_active_users}
                metric="Active Users by filter date range"
              />
            </div>
          </div>
          <div className="col-lg-4 mb-3">
            <div className="card p-3 height-100 thirty-minutes-report-card pb-5">
              <div className="d-flex justify-content-between">
                <h3 className="heading">Active users in last 30 minutes</h3>
                <Tooltip title="Shows the number of active users who visited your website or app in the last 30 minutes. Each bar in the chart shows the number of active users who visited in one minute">
                  <Icon
                    className="information-icon"
                    icon={"eva:alert-circle-outline"}
                  />
                </Tooltip>
              </div>

              <ThirtyMinutesReport data={overviewData.real_time_users_report} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-3">
            <div className="card p-3 pb-5 height-100 thirty-minutes-report-card">
              {/* <h3 className="heading">Event count by event name</h3> */}

              <div className="d-flex justify-content-between">
                <h3 className="heading">Event count by event name</h3>
                <Tooltip
                  title={
                    <>
                      Shows the number of events triggered in your app,
                      categorized by event name.
                      <br />
                      Each bar in the chart represents the count of a specific
                      event triggered.
                    </>
                  }
                >
                  <Icon
                    className="information-icon"
                    icon={"eva:alert-circle-outline"}
                  />
                </Tooltip>
              </div>

              <OverviewEvents data={overviewData.firebase_event_list} />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="card p-3 pb-5 height-100 thirty-minutes-report-card">
              {/* <h3 className="heading">Views by Page title and screen class</h3> */}
              <div className="d-flex justify-content-between">
                <h3 className="heading">
                  Views by Page title and screen class
                </h3>
                <Tooltip
                  title={
                    <>
                      Shows the number of views for each page or screen in your
                      app.
                      <br />
                      This helps to analyze the performance and popularity of
                      different pages/screens.
                    </>
                  }
                >
                  <Icon
                    className="information-icon"
                    icon={"eva:alert-circle-outline"}
                  />
                </Tooltip>
              </div>

              <PageAndScreenViews data={overviewData.pages_and_screen_list} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-3">
            <div className="card p-3 engagement-overview-card">
              <h2 className="heading mb-4">Engagement overview</h2>
              <MUICustomTabs
                data={TABS_OPTIONS}
                value={tabValue}
                handleChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={openFilter}
        onOpenDrawer={() => setOpenfilter(true)}
        onCloseDrawer={() => setOpenfilter(false)}
        pageTitle="Filter"
        componentToPassDown={
          <AnalyticsFilter
            filterData={filterData}
            setFilterData={setFilterData}
            searchFunction={searchFunction}
            handleClearFilter={handleClearFilter}
          />
        }
      />
    </>
  );
};

export default FirebaseOverview;
