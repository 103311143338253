import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import "react-tagsinput/react-tagsinput.css";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import { TextareaAutosize } from "@mui/material";
import { useParams } from "react-router-dom";
import LinkPopup from "src/pages/GeneralSetting/LinkPopup";
import { _update_notify_schedule } from "src/DAL/member/member";

export default function PushNotificationSetting({
  onCloseDrawer,
  data,
  _id,
  setRow,
}) {
  const member_id = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    notification_title: "",
    description: "",
  });
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const [message, setMessage] = useState("");
  const [openLink, setOpenLink] = useState(false);

  const handleAddLink = (values) => {
    let make_link = ` [${values.display_text}](${values.link_address}) `;
    const inputField = document.getElementById("inputField");
    const start = inputField.selectionStart;
    const end = inputField.selectionEnd;
    setMessage(message?.slice(0, start) + make_link + message?.slice(end));
    inputField.setSelectionRange(
      start + make_link.length,
      start + make_link.length
    );
    setOpenLink(false);
  };

  const updateListObject = (newItem) => {
    setRow(newItem);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let postData = {
      member_id: member_id.id,
      push_notification_info: {
        notification_title: inputs.notification_title,
        description: inputs.description,
      },
    };

    const result = await _update_notify_schedule(postData, _id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      updateListObject(result?.event);
      onCloseDrawer();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  function handleEditorInit() {
    setTimeout(() => {
      let containerWarning = document.getElementById("inputField");
      console.log(containerWarning, "containerWarningcontainerWarning");
      if (containerWarning) {
        containerWarning.style.height = "150px";
      }
    }, 100);
  }
  React.useEffect(() => {
    handleEditorInit();
  }, []);
  React.useEffect(() => {
    setInputs(data.push_notification_info);
    setInputs((items) => ({
      ...items,
      ["notification_title"]: data.push_notification_info
        ? data.push_notification_info.notification_title
        : "",
      ["description"]: data.push_notification_info
        ? data.push_notification_info.description
        : "",
    }));
  }, [data]);
  return (
    <div className="container-fluid new-memories">
      <GeneralPopUpModel
        open={openLink}
        setOpen={setOpenLink}
        title={"Insert Link"}
        componentToPassDown={<LinkPopup handleAddLink={handleAddLink} />}
      />
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <TextField
              className="mt-3"
              id="outlined-basic"
              label="Notification Title"
              variant="outlined"
              name="notification_title"
              value={inputs?.notification_title}
              required={true}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <p className="tracking_code_heading">Notification Statement*</p>
            <TextareaAutosize
              aria-label="empty textarea"
              className="textarea-autosize"
              placeholder="Notification Statement"
              required
              name="description"
              value={inputs?.description}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "200px",
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "10px",
                paddingTop: "13px",
                paddingLeft: "5px",
                focus: "green",
                outline: "none",
              }}
            />
          </div>
          <div className="text-end mt-4">
            <button type="submit" className="small-contained-button">
              {isLoading ? "Updating" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
