import React, { useEffect, useState } from "react";
import {
  FormControlLabel,
  Checkbox,
  Button,
  Box,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import { get_root_value } from "src/utils/domUtils";
import { Icon } from "@iconify/react";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker } from "@mui/lab";
import {
  active_booking_status_api,
  bookingPageList,
  consultantSearchListApi,
} from "src/DAL/booking/bookingApi";
import { makeStyles } from "@mui/styles";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { min } from "lodash";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function StripeFilter({
  filterData,
  handleSelectOther,
  searchFunction,
  handleClearFilter,
  Filter_from,
}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  return (
    <div className="container-fluid new-memories transaction-filter">
      <>
        <div className="col-12 mt-3">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">Sort By</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="sort_by"
              label="Sort By"
              value={filterData.sort_by}
              onChange={(e) => {
                handleSelectOther("sort_by", e.target.value);
              }}
            >
              <MenuItem value="greater_then_or_equal">
                Greater Then Or Equal
              </MenuItem>
              <MenuItem value="less_then_or_equal">Less Then Or Equal</MenuItem>
              <MenuItem value="is_between">Between Date Range</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="col-12 mt-3">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={
                filterData.sort_by == "is_between"
                  ? "Start Date"
                  : "Created Date"
              }
              name="created"
              inputFormat="dd-MM-yyyy"
              value={filterData.created ?? new Date()}
              onChange={(e) => {
                handleSelectOther("created", e);
              }}
              format="DD-MM-YYYY"
              renderInput={(params) => (
                <TextField {...params} className="inputs-fields" fullWidth />
              )}
            />
          </LocalizationProvider>
        </div>
        {filterData.sort_by == "is_between" && (
          <div className="col-12 mt-3">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="End Date"
                name="end_date"
                inputFormat="dd-MM-yyyy"
                value={filterData.end_date ?? new Date()}
                onChange={(e) => {
                  handleSelectOther("end_date", e);
                }}
                minDate={filterData.created ?? new Date()}
                format="DD-MM-YYYY"
                renderInput={(params) => (
                  <TextField {...params} className="inputs-fields" fullWidth />
                )}
              />
            </LocalizationProvider>
          </div>
        )}
      </>

      <Box sx={{ py: 2 }}>
        <Button
          fullWidth
          size="large"
          type="submit"
          color="inherit"
          variant="outlined"
          onClick={() => searchFunction("filter")}
          startIcon={
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: 5 }}
            >
              <path
                d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                fill={get_root_value("--portal-theme-primary")}
              />
            </svg>
          }
        >
          Filter
        </Button>
      </Box>
      <Button
        fullWidth
        size="large"
        type="submit"
        color="inherit"
        variant="outlined"
        onClick={handleClearFilter}
        startIcon={<Icon icon={roundClearAll} />}
      >
        Clear All
      </Button>
    </div>
  );
}
