import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import {
  _add_automated_responded_message,
  departmentListAPIs,
} from "src/DAL/Community/DailyMessages";
import { _Update_automated_responded_message } from "src/DAL/AutoRespondedMessage/AutoRespondedMessage";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function UpdateAutoRespondedMessage() {
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const id = params.message_id;
  const [isLoading, setIsLoading] = useState(false);
  const [departmentsData, setDepartmentsData] = useState([]);
  const [inputs, setInputs] = useState({
    label: "",
    detail_description: "",
    type: "general",
    departments: " ",
    order: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let postData = {
      title: inputs.label,
      message: inputs.detail_description,
      message_type: inputs.type,
      department: inputs.departments,
      order: inputs.order,
    };

    const result = await _Update_automated_responded_message(postData, id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const getDepartmentListing = async () => {
    setIsLoading(true);
    const result = await departmentListAPIs();
    if (result.code === 200) {
      setDepartmentsData(result.department);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    getDepartmentListing();
  }, []);
  useEffect(() => {
    setInputs((inputs) => ({
      ...inputs,
      ["label"]: state?.title,
      ["detail_description"]: state?.message,
      ["order"]: state?.order,
      ["type"]: state?.message_type,
      ["departments"]: state?.department?._id,
    }));
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2>{`Edit Message`}</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              fullWidth
              required
              name="label"
              value={inputs.label}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="order"
              variant="outlined"
              fullWidth
              name="order"
              value={inputs.order}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Choose Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="type"
                required
                value={inputs.type}
                label="Choose Type"
                onChange={handleChange}
              >
                <MenuItem value="general">General</MenuItem>
                <MenuItem value="department">department</MenuItem>
                <MenuItem value="personal_notes">Personal Notes</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs.type == "department" && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Departments *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="departments"
                  label="Departments*"
                  required
                  value={inputs.departments}
                  onChange={handleChange}
                >
                  {departmentsData?.map((department, index) => (
                    <MenuItem
                      value={department?._id}
                      eventKey={index}
                      key={department?._id}
                    >
                      {department.title +
                        " " +
                        " " +
                        "(" +
                        department.created_for +
                        ")"}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          <div className="col-12 mt-4">
            <h4>Message *</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="detail_description"
              editorHeight={320}
            />
          </div>
          <div className="text-end mt-4" id="fixedbutton">
            <button className="small-contained-button">{"Update"}</button>
          </div>
        </div>
      </form>
    </div>
  );
}
