import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { htmlDecode } from "src/utils/convertHtml";
import { dd_date_time_format } from "src/utils/constant";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import FullPagePopupForTitle from "src/pages/GeneralSetting/FullPagePopupForTitle";
import EmailNotificationSetting from "./EmailNotificationSetting";
import MessageNotificationSetting from "./MessageNotificationSetting";
import WhatsappNotificationSetting from "./WhatsappNotificationSetting";
import PushNotificationSetting from "./PushNotificationSetting";
import SMSNotificationSetting from "./SMSNotificationSetting";
import DateAndTimeUpdate from "./DateAndTimeUpdate";

import {
  _add_wheel_of_life,
  _delete_wheel_of_life,
  _get_wheel_of_life,
  _update_wheel_of_life,
} from "src/DAL/QuestionSurvey/QuestionSurvey";
import NotificationContent from "./NotificationContent";

export default function NotificationScheduleOnlyView({
  data,
  tabData,
  setTabData,
}) {
  const [EmailNotification, setEmailNotification] = useState(false);
  const [drawerState, setDrawerState] = useState("");
  const [selectedObject, setSelectedObject] = useState({});
  const [selectedID, setSelectedID] = useState("");
  const [row, setRow] = useState(data ?? {});

  const handleClose = () => {
    setDrawerState("");
    setSelectedObject({});
    setSelectedID("");
    setEmailNotification(false);
  };

  const handleClosePopup = () => {
    setSelectedID("");
    setSelectedObject({});
  };

  const handleOpen = (data, name, _id) => {
    setSelectedID(_id);
    setSelectedObject(data);
    if (name == "email_notification_access") {
      setEmailNotification(true);
    } else {
      setDrawerState(name);
    }
  };

  const handleMenu = (row) => {
    let email_notification_info_object = null;
    let push_notification_info_object = null;
    let whatsapp_notification_info_object = null;
    let message_notification_info_object = null;
    let sms_notification_info_object = null;

    if (row?.email_notification_info) {
      let { email_notification_info } = row;

      email_notification_info_object = {
        title: email_notification_info?.title,
        email_subject: email_notification_info?.email_subject,
        email_message: email_notification_info?.email_message,
        status: email_notification_info?.status,
        cc_emails: email_notification_info?.cc_emails ?? [],
        main_email: email_notification_info?.main_email,
      };
    }

    if (row?.push_notification_info) {
      let { push_notification_info } = row;

      push_notification_info_object = {
        notification_title: push_notification_info?.notification_title,
        description: push_notification_info?.description,
      };
    }

    if (row?.whatsapp_notification_info) {
      let { whatsapp_notification_info } = row;

      whatsapp_notification_info_object = {
        type: whatsapp_notification_info?.type,
      };

      if (whatsapp_notification_info.type == "message") {
        whatsapp_notification_info_object.whatsappMessage =
          whatsapp_notification_info?.whatsappMessage;
      } else {
        whatsapp_notification_info_object.template =
          whatsapp_notification_info?.template;
      }
    }

    if (row?.message_notification_info) {
      let { message_notification_info } = row;

      message_notification_info_object = {
        message: message_notification_info?.message,
      };
    }

    if (row?.sms_notification_info) {
      let { sms_notification_info } = row;

      sms_notification_info_object = {
        message: sms_notification_info?.message,
      };
    }

    const MENU_OPTIONS = [
      {
        label: `Update Date And Time Setting`,
        icon: "akar-icons:edit",
        handleClick: (e) => handleOpen(row, "date_and_time_update", row?._id),
      },
    ];

    row.notification_send_type.map((item) => {
      if (item?.name == "email_notification_access") {
        MENU_OPTIONS.push({
          label: `Update ${item.label} Setting`,
          icon: "akar-icons:edit",
          handleClick: (e) =>
            handleOpen(
              {
                ...item,
                email_notification_info: email_notification_info_object,
              },
              item.name,
              row?._id
            ),
        });
      } else if (item?.name == "push_notification_access") {
        MENU_OPTIONS.push({
          label: `Update ${item.label} Setting`,
          icon: "akar-icons:edit",
          handleClick: (e) =>
            handleOpen(
              {
                ...item,
                push_notification_info: push_notification_info_object,
              },
              item.name,
              row?._id
            ),
        });
      } else if (item?.name == "whatsapp_notification_access") {
        MENU_OPTIONS.push({
          label: `Update ${item.label} Setting`,
          icon: "akar-icons:edit",
          handleClick: (e) =>
            handleOpen(
              {
                ...item,
                whatsapp_notification_info: whatsapp_notification_info_object,
              },
              item.name,
              row?._id
            ),
        });
      } else if (item?.name == "message_notification_access") {
        MENU_OPTIONS.push({
          label: `Update ${item.label} Setting`,
          icon: "akar-icons:edit",
          handleClick: (e) =>
            handleOpen(
              {
                ...item,
                message_notification_info: message_notification_info_object,
              },
              item.name,
              row?._id
            ),
        });
      } else if (item?.name == "sms_notification_access") {
        MENU_OPTIONS.push({
          label: `Update ${item.label} Setting`,
          icon: "akar-icons:edit",
          handleClick: (e) =>
            handleOpen(
              {
                ...item,
                sms_notification_info: sms_notification_info_object,
              },
              item.name,
              row?._id
            ),
        });
      } else {
        MENU_OPTIONS.push({
          label: `Update ${item.label} Setting`,
          icon: "akar-icons:edit",
          handleClick: (e) => handleOpen(item, item.name, row?._id),
        });
      }
    });

    return MENU_OPTIONS;
  };

  let drawer_data = {
    onCloseDrawer: handleClose,
    data: selectedObject,
    _id: selectedID,
    tabData: tabData,
    setTabData: setTabData,
    row: row,
    setRow: setRow,
  };

  const handle_drawer = () => {
    if (drawerState === "message_notification_access") {
      return {
        label: "Message Notification Setting",
        component: <MessageNotificationSetting {...drawer_data} />,
      };
    } else if (drawerState === "whatsapp_notification_access") {
      return {
        label: "Whatsapp Notification Setting",
        component: <WhatsappNotificationSetting {...drawer_data} />,
      };
    } else if (drawerState === "push_notification_access") {
      return {
        label: "Push Notification Setting",
        component: <PushNotificationSetting {...drawer_data} />,
      };
    } else if (drawerState === "sms_notification_access") {
      return {
        label: "SMS Notification Setting",
        component: <SMSNotificationSetting {...drawer_data} />,
      };
    } else if (drawerState === "date_and_time_update") {
      return {
        label: "Date And Time Setting Update",
        component: <DateAndTimeUpdate {...drawer_data} />,
      };
    }
  };

  return (
    <>
      <div className="event-details">
        <h1 className="mb-0 notification-update">{htmlDecode(data?.title)}</h1>
        <div className="container">
          <div className="row">
            <div className="col-12 mt-1">
              {row?.notify_schedule &&
                row?.notify_schedule.map((notify, index) => (
                  <div
                    key={index}
                    className="card-notification mt-2 p-1 pt-2 py-2 row"
                  >
                    <div className="col-11 mb-2">
                      <div className="d-flex me-4">
                        <span style={{ color: "#e4c073" }}>
                          Notify Date Time :{" "}
                        </span>
                        <p className="ms-1 text-white mb-0">
                          {dd_date_time_format(notify?.notify_date_time)}
                        </p>
                      </div>
                    </div>
                    <div className="col-1">
                      <div className="d-flex justify-content-end">
                        {notify.is_notification_send ? null : (
                          <CustomPopoverSection
                            menu={handleMenu(notify)}
                            data={null}
                          />
                        )}
                      </div>
                    </div>

                    {notify?.email_notification_info && (
                      <NotificationContent type="Email" data={notify} />
                    )}

                    {notify?.push_notification_info && (
                      <NotificationContent type="Notification" data={notify} />
                    )}

                    {notify?.whatsapp_notification_info && (
                      <NotificationContent type="WhatsApp" data={notify} />
                    )}

                    {notify?.message_notification_info && (
                      <NotificationContent type="Message" data={notify} />
                    )}

                    {notify?.sms_notification_info && (
                      <NotificationContent type="SMS" data={notify} />
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      <CustomDrawer
        isOpenDrawer={Boolean(drawerState)}
        onCloseDrawer={handleClose}
        pageTitle={handle_drawer()?.label}
        componentToPassDown={handle_drawer()?.component}
        zIndex={1300}
      />

      <FullPagePopupForTitle
        open={EmailNotification}
        setOpen={setEmailNotification}
        title={selectedObject}
        handleClosePopup={handleClosePopup}
        componentToPassDown={
          <>
            <EmailNotificationSetting {...drawer_data} />
          </>
        }
      />
    </>
  );
}
