import React, { useEffect, useState } from "react";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { dd_date_time_format } from "src/utils/constant";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import NotificationScheduleOpenView from "./Components/NotificationScheduleOpenView";
import NotificationScheduleOnlyView from "./Components/NotificationScheduleOnlyView";
import { useNavigate, useParams } from "react-router-dom";
import {
  _event_detail_specific_iteration,
  memberDetailProfileApiWithPagination,
} from "src/DAL/member/member";
import { useSnackbar } from "notistack";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const NotificationSchedule = ({ tabValue }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const member_id = useParams();
  const [openView, setOpenView] = useState("");
  const [openViewData, setOpenViewData] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [tabData, setTabData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTab, setIsLoadingTab] = useState(false);

  const EMPTY_FILTER = {
    start_date: null,
    end_date: null,
    date: null,
    payment_made_by: "all",
    sale_page: null,
    payment_plan: null,
    filter_by_plateform: null,
    payment_request: [],
    transaction_type: "all",
    transaction_for: "all",
    user_id: null,
    transaction_status: "succeeded",
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickView = async (data) => {
    navigate(
      `/member/notification-schedule/update-schedule/${member_id.id}/${data?._id}`
    );
  };

  let TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    { id: "title", label: "Title" },
    {
      id: "start_date_time",
      label: "Start Date Time",
      renderData: (row) => {
        return (
          <div className="d-flex">
            <p className="mb-1 mt-1">
              {dd_date_time_format(row.start_date_time)}
            </p>
          </div>
        );
      },
    },
    {
      id: "end_date_time",
      label: "End Date Time",
      renderData: (row) => {
        return (
          <div className="d-flex">
            <p className="mb-1 mt-1">
              {dd_date_time_format(row.end_date_time)}
            </p>
          </div>
        );
      },
    },

    {
      id: "notify_schedule",
      label: "Notify Schedule",
      renderData: (row) => (
        <NotificationScheduleOpenView
          openViewData={row}
          onClick={() => handleClickView(row)}
        />
      ),
    },
  ];

  const memberProfileInfo = async () => {
    setIsLoading(true);

    const result = await memberDetailProfileApiWithPagination(
      member_id.id,
      tabValue == 1
        ? "notification_schedules"
        : "pending_notification_schedules",
      EMPTY_FILTER,
      page,
      rowsPerPage
    );

    if (result.code == 200) {
      setTabData(result.tab_data);
      setTotalCount(result?.tab_data?.total_count);
      localStorage.setItem("notification_schedule_tab_value", tabValue);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    memberProfileInfo();
  }, [tabValue, rowsPerPage, page]);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        data={tabData?.notification_schedules ?? []}
        className="card-with-background"
        hide_search={true}
        is_hide={true}
        pagePagination={true}
        custom_pagination={{
          total_count: totalCount,
          rows_per_page: rowsPerPage,
          page: page,
          handleChangePage: handleChangePage,
          onRowsPerPageChange: handleChangeRowsPerPage,
        }}
      />

      <GeneralPopUpModel
        open={openView}
        setOpen={setOpenView}
        title="Notification Schedule View"
        componentToPassDown={
          isLoadingTab ? (
            <PageLoader />
          ) : (
            <NotificationScheduleOnlyView
              data={openViewData}
              tabData={tabData}
              setTabData={setTabData}
            />
          )
        }
      />
    </>
  );
};

export default NotificationSchedule;
