import * as React from "react";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { CircularProgress, TextField } from "@mui/material";
import {
  call_reminder_setting,
  update_welcome_reminder_setting,
} from "src/DAL/WebsitePages/WebsitePages";
import { useEffect } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { clientSettingApi } from "src/DAL/ClientSetting/ClientSetting";
import GeneralIterativeCkeditor from "src/components/GeneralComponents/GeneralIterativeCkeditor";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function WelcomeReminderSetting() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [inputList, setInputList] = useState([
    {
      title: "",
      reminder_days: "",
      reminder_message: "",
      notify_time: "00:00",
    },
  ]);

  const handleAddClick = (index) => {
    setInputList([
      ...inputList.slice(0, index),
      {
        title: "",
        reminder_days: "10",
        reminder_message: "",
        notify_time: "00:00",
      },
      ...inputList.slice(index),
    ]);
  };
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoadingForm(true);
    let postData = {
      welcome_reminder_setting: inputList,
    };

    const result = await update_welcome_reminder_setting(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const clientDetail = async () => {
    setIsLoading(true);
    const result = await clientSettingApi();
    if (result.code == 200) {
      if (result?.content_setting?.welcome_reminder_setting.length > 0) {
        setInputList(result?.content_setting?.welcome_reminder_setting);
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    clientDetail();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mb-3">
        <div className="col-6">
          <h2>Welcome Reminder Setting</h2>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        {inputList.map((item, index) => {
          return (
            <>
              <div className="row mb-4 setting-card">
                <div className="col-10">
                  <div className="row">
                    <div className="col-6">
                      <TextField
                        id="outlined-basic"
                        label="Title"
                        variant="outlined"
                        fullWidth
                        required
                        type="text"
                        name="title"
                        value={item.title}
                        onChange={(e) => handleInputChange(e, index)}
                      />
                    </div>
                    <div className="col-6">
                      <TextField
                        id="outlined-basic"
                        label="Days"
                        variant="outlined"
                        fullWidth
                        required
                        type="number"
                        name="reminder_days"
                        value={item.reminder_days}
                        onChange={(e) => handleInputChange(e, index)}
                      />
                    </div>
                    <div className="col-6">
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="time"
                        label="Notify Time"
                        type="time"
                        required
                        className="mt-3 inputs-fields"
                        name="notify_time"
                        value={item.notify_time}
                        onChange={(e) => handleInputChange(e, index)}
                      />
                    </div>
                    <div className="col-12 mt-4">
                      <h4>Reminder Message</h4>
                      <GeneralIterativeCkeditor
                        setInputs={setInputList}
                        inputs={inputList}
                        required
                        name="reminder_message"
                        index={index}
                        editorHeight={320}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-2">
                  <AddCircleOutlineIcon
                    className="question-survey"
                    onClick={() => handleAddClick(index + 1)}
                  />
                  {inputList.length !== 1 && (
                    <RemoveCircleOutlineIcon
                      className="question-survey-cross"
                      onClick={() => handleRemoveClick(index)}
                    />
                  )}
                </div>
              </div>
            </>
          );
        })}
        <div className="text-end mt-4" id="fixedbutton">
          <button className="small-contained-button">
            {isLoadingForm ? "Updating..." : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
