import React from "react";
import { CircularProgress, Tooltip } from "@mui/material";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useEffect } from "react";
import { s3baseUrl } from "src/config/config";
import moment from "moment";
import CircleIcon from "@mui/icons-material/Circle";
import PopOverViewMoreNav from "src/components/PopOverViewMoreNav";
import { show_proper_words } from "src/utils/constant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Progrmme_status = [
  {
    name: "Active",
    value: true,
    class: "active_bg_program",
  },
  {
    name: "InActive",
    value: false,
    class: "inactive_bg_program",
  },
];

const get_delegate_member = (data) => {
  if (data.consultant) {
    return data.consultant.first_name + " " + data.consultant.last_name;
  } else {
    return "N/A";
  }
};

const handle_nav_items = (data, delegate) => {
  let nav_items = [];
  data &&
    data.map((nav) => {
      if (nav.value == "delegate_recordings") {
        let name = get_delegate_member(delegate);
        nav.title = nav.title.replace("${delegate_name}", `${name}'s`);
      }
      if (nav.child_options) {
        nav.child_options.map((child) => {
          nav_items.push(child.title);
        });
      } else {
        nav_items.push(nav.title);
      }
    });
  return nav_items;
};

export default function ManageProgrammeAccess({ tabData, memberDetail }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [programsList, setProgramsList] = useState([]);
  const [event, setEvent] = useState([]);
  const [anchorElEvnet, setAnchorElEvnet] = useState(null);
  const opensEvent = Boolean(anchorElEvnet);
  const idevent = opensEvent ? "simple-popover" : undefined;

  const handleClickPopUPEvent = (event, row) => {
    setEvent(row.nav_items);
    setAnchorElEvnet(event.currentTarget);
  };
  const handleClosePopUpEvent = () => {
    setAnchorElEvnet(null);
  };

  const getProgramsList = () => {
    setIsLoading(true);
    let data = tabData?.map((item) => {
      let date_expiry = "No Expiry";
      if (item.expiry_date) {
        date_expiry = moment(item?.expiry_date).format("DD-MM-YYYY");
      }
      let purchase_date = "";
      if (item.purchase_date_time) {
        purchase_date = moment(item.purchase_date_time).format("DD-MM-YYYY");
      }
      let nav_items = handle_nav_items(item.nav_items, memberDetail);

      return {
        ...item,
        table_avatar: {
          src: s3baseUrl + item?.program_images?.thumbnail_1,
          alt: item?.title,
        },
        nav_items: handle_nav_items(item.nav_items, memberDetail),
        purchase_date,
        date_expiry,
      };
    });
    setProgramsList(data);
    setIsLoading(false);
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "table_avatar",
      label: "Image",
      type: "thumbnail",
    },
    {
      id: "title",
      label: "Programme Title",
    },
    { id: "purchase_date", label: "Start Date" },
    { id: "date_expiry", label: "Expiry Date" },
    {
      id: "title",
      label: "Nav Items",
      renderData: (row) => {
        const firstFiveNav = row.nav_items?.slice(0, 5);
        return (
          <div>
            {firstFiveNav.length > 0
              ? firstFiveNav.map((navitem, i) => {
                  if (navitem !== "[]") {
                    return (
                      <>
                        <div key={i}>{show_proper_words(navitem + ",")}</div>
                        {row.nav_items.length > 5 && i == 4 && (
                          <p
                            className="mb-1 mt-1 view-more"
                            aria-describedby={idevent}
                            variant="contained"
                            onClick={(e) => handleClickPopUPEvent(e, row)}
                          >
                            view more
                          </p>
                        )}
                      </>
                    );
                  } else {
                    return "N/A";
                  }
                })
              : "N/A"}
          </div>
        );
      },
    },
    { id: "status", label: "Status", type: "row_status" },
  ];

  useEffect(() => {
    getProgramsList();
  }, [tabData]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="mt-3">
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={programsList}
          className="card-with-background"
        />
      </div>
      <PopOverViewMoreNav
        handleClick={handleClickPopUPEvent}
        handleClose={handleClosePopUpEvent}
        anchorEl={anchorElEvnet}
        id={idevent}
        open={opensEvent}
        program={event}
        title="Nav Itmes"
      />
    </>
  );
}
