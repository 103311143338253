import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Chip, IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  DeletePaymentRequestApi,
  memberBillingListApi,
} from "src/DAL/member/member";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { convertCurrencyToSign } from "src/utils/constant";
import PageLoader from "src/components/GeneralComponents/PageLoader";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false, type: "action" },
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "request_title", label: "Request Title", alignRight: false },
  { id: "product_name", label: "Product", alignRight: false },
  { id: "template_name", label: "Payment Template", alignRight: false },
  { id: "program_name", label: "Program", alignRight: false },
  {
    id: "request_type",
    label: "Request Type",
    alignRight: false,
    className: "text-capitalize",
  },
  {
    id: "total_amount",
    label: "Total Amount",
    alignRight: false,
    renderData: (row) => {
      return (
        <>
          {convertCurrencyToSign(row.currency)}
          {row.total_amount}
        </>
      );
    },
  },
  {
    id: "initial_amount",
    label: "Initial Amount",
    alignRight: false,
    renderData: (row) => {
      return (
        <>
          {convertCurrencyToSign(row.currency)}
          {row.initial_amount}
        </>
      );
    },
  },
  {
    id: "installment_amount",
    label: "Installment Amount",
    alignRight: false,
    renderData: (row) => {
      return (
        <>
          {convertCurrencyToSign(row.currency)}
          {row.installment_amount}
        </>
      );
    },
  },
  {
    id: "month",
    label: "No Of Installments",
    alignRight: false,
  },
  {
    id: "status",
    label: "Status",
    type: "row_status",
  },
  {
    id: "status",
    label: "First Paid",
    renderData: (row) => {
      return (
        <Chip
          className="manage-program-chip-success"
          size="small"
          variant="contained"
          color={row.is_first_paid === false ? "error" : "success"}
          label={row.is_first_paid === false ? "Pending" : "Paid"}
        />
      );
    },
  },
];

export default function ViewBillings() {
  const navigate = useNavigate();
  const { member_id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [billingsData, setBillingsData] = useState([]);

  const getBillingsListing = async () => {
    const result = await memberBillingListApi(member_id);
    if (result.code == 200) {
      const billings = result.payment_request.map((request) => {
        let product_name = "";
        if (request.product?.name) {
          product_name = request.product.name;
        }

        let template_name = "N/A";
        if (request.payment_template?.title) {
          template_name = request.payment_template.title;
        }

        let program_name = "N/A";
        if (request.program?.title) {
          program_name = request.program.title;
        }

        return {
          ...request,
          product_name: product_name,
          template_name: template_name,
          program_name: program_name,
        };
      });
      setBillingsData(billings);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await DeletePaymentRequestApi(
      deleteDoc.payment_request_slug
    );
    if (result.code === 200) {
      // setBillingsData((billingsData) => {
      //   return billingsData.filter((data) => data._id !== deleteDoc._id);
      // });
      getBillingsListing();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleBilling = (value) => {
    navigate(`/member/billing-detail/${value.payment_request_slug}`, {
      state: value,
    });
  };

  const handleNavigate = () => {
    navigate(`/member/add-payment-request/${member_id}`);
  };

  useEffect(() => {
    getBillingsListing();
  }, []);

  const MENU_OPTIONS = [
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "View Detail",
      icon: "akar-icons:eye",
      handleClick: handleBilling,
    },
  ];

  if (isLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this request?"}
        handleAgree={handleDelete}
      />

      <div className="container-fluid">
        <div className="row mobile-margin display-flex">
          <div className="col-12">
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(`/member`)}
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-lg-6 col-sm-12">
            <h2>Payment Requests</h2>
          </div>
          <div className="col-lg-6 col-sm-12 text-end ">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Payment Request
            </button>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={billingsData}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          pagePagination={true}
        />
      </div>
    </>
  );
}
