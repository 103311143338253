import React from "react";
import { useParams } from "react-router-dom";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useEffect } from "react";
import { s3baseUrl } from "src/config/config";
import PageLoader from "src/components/GeneralComponents/PageLoader";
import {
  _get_module_manage_mission_access,
  _update_module_manage_mission_access,
} from "src/DAL/MissionApi/MissionApiNew";
import ActiveLastBreadcrumb from "../GeneralComponents/BreadCrums";

export default function ManageMissionAccessComponent({ module_type }) {
  const { enqueueSnackbar } = useSnackbar();
  const { id, page_id } = useParams();
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowPerPage, setrowPerPage] = useState(50);
  const [moduleDetail, setModuleDetail] = useState({});
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [missionsQuestsList, setMissionsQuestsList] = useState([]);
  const is_website_pages = window.location.pathname.includes("website-pages");

  let list_name = "/template-pages";
  if (is_website_pages) {
    list_name = "/website-pages";
  }

  const get_mission_quests = async () => {
    let dataPost = {
      type: module_type,
      list_type: "mission",
    };
    const result = await _get_module_manage_mission_access(id, dataPost);
    if (result.code == 200) {
      setModuleDetail(result.module_info);
      if (result.module_info.missions?.length > 0) {
        let selected_missions_quests = result.module_info.missions.map(
          (mission_quest) => {
            return { ...mission_quest, _id: mission_quest?.mission_id };
          }
        );
        setSelected(selected_missions_quests);
      }
      setrowPerPage(result.missions.length);
      var missionQuestsArray = [];
      result.missions.map((item) => {
        let no_of_days = item.mission_duration + item.replay_days;
        item.no_of_days = no_of_days;
        item.table_avatar = {
          src: s3baseUrl + item.image.thumbnail_2,
          alt: item.title,
        };
        item.badge_level = item?.membership_level_info?.badge_level_info
          ? item?.membership_level_info?.badge_level_info?.title
          : "N/A";
        missionQuestsArray.push(item);
      });
      setMissionsQuestsList(missionQuestsArray);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    setIsLoadingForm(true);
    let selected_array = [];
    selected.map((item) => {
      var quest_object = {
        mission_id: item?._id,
        title: item?.title,
        no_of_days: item?.no_of_days,
        type: item?.type,
      };

      selected_array.push(quest_object);
    });

    let postData = {
      type: module_type,
      missions: selected_array,
    };

    const result = await _update_module_manage_mission_access(id, postData);
    if (result.code == 200) {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    get_mission_quests();
  }, []);

  if (isLoading == true) {
    return <PageLoader />;
  }

  const TABLE_HEAD = [
    {
      id: "table_avatar",
      label: "Image",
      type: "thumbnail",
      className: "p-0",
    },
    { id: "title", label: "Mission Title" },
    { id: "mission_duration", label: "Mission Duration" },
    {
      id: "badge_level",
      label: "Badge Level",
    },
    {
      id: "status",
      label: "Status",
      type: "row_status",
    },
  ];

  const getBreadCrumbMenu = () => {
    let breadCrumbMenu = [];
    if (module_type === "payment_plan") {
      if (page_id) {
        breadCrumbMenu.push(
          {
            title: is_website_pages ? "Pages" : "Template Pages",
            navigation: list_name,
            active: false,
          },
          {
            title: moduleDetail?.plan_title,
            navigation: `${list_name}/${page_id}/payment-plans`,
            active: false,
          }
        );
      } else {
        breadCrumbMenu.push({
          title: "Payment Plans",
          navigation: `/payment-plans`,
          active: false,
        });
      }
    } else if (module_type === "payment_template") {
      breadCrumbMenu.push({
        title: `Payment Template - ${moduleDetail?.title}`,
        navigation: "/payment-template",
        active: false,
      });
    } else if (module_type === "payment_request") {
      breadCrumbMenu.push({
        title: `Payment Request - ${moduleDetail?.request_title}`,
        navigation: "/payment-request",
        active: false,
      });
    }

    breadCrumbMenu.push({
      title: "Manage Missions Access",
      active: true,
    });

    return breadCrumbMenu;
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 mb-3">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={getBreadCrumbMenu()} />
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={missionsQuestsList}
            selected={selected}
            setSelected={setSelected}
            checkbox_selection={true}
            pagePagination={false}
            rows_per_page_count={rowPerPage}
            hide_footer_pagination={true}
          />
        </div>
      </div>
      <div className="ms-auto text-end" id="fixedbutton">
        <button
          className="small-contained-button mt-3 ml-auto"
          onClick={handleSubmit}
        >
          {isLoadingForm ? "Updating..." : "Update"}
        </button>
      </div>
    </div>
  );
}
