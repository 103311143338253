import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  convertCurrencyToSign,
  convertTimestampToDateWithTimezone,
  dd_date_format,
} from "src/utils/constant";
import { subscription_stripe_list } from "src/DAL/WebsitePages/WebsitePages";
import moment from "moment";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import SubscriptionListFilter from "./SubscriptionListFilter";
import { Icon } from "@iconify/react";
import Label from "src/components/Label";
import CustomPopover from "src/components/GeneralComponents/CustomPopover";
import CopyToClipboard from "react-copy-to-clipboard";
import SubscriptionDetail from "./SubscriptionDetail";
import StripeFilter from "./StripeFilter";
import FilteredChip from "src/components/FilteredChip";
import PageLoader from "src/components/GeneralComponents/PageLoader";

export default function StripeTransactionsList({
  status,
  state,
  setDownloadCSVData,
  filterDrawerState,
  handleOpenFilterDrawer,
  handleCloseFilterDrawer,
  setFilterDrawerState,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [pageTemp, setPageTemp] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isLoadingSection, setIsLoadingSection] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [subscriptionsData, setSubscriptionsData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [lastId, setLastId] = useState("");
  const [FirstId, setFirstId] = useState("");
  const [islastId, setIsLast] = useState(false); // for pagination [last id
  const [IsFirst, setIsFirst] = useState(false); // for pagination [last id

  const [showPop, setshowPop] = useState(false);
  const [getrecord, setGetrecord] = useState("");
  const [value, setValue] = useState({});

  const EMPTY_FILTER = {
    created: null,
    end_date: null,
    sort_by: "greater_then_or_equal",
  };

  let _filter_data = EMPTY_FILTER;
  let find_filter = localStorage.getItem("transactions_list_data");
  if (find_filter) {
    _filter_data = JSON.parse(find_filter);
  }

  let ALTER_FILTER = {
    expired: "",
  };
  const [filterData, setFilterData] = useState(_filter_data);
  const [updateFilterData, setUpdateFilterData] = useState(EMPTY_FILTER);
  const [filterState, setFilterState] = useState(EMPTY_FILTER);
  const [filterStateUpdated, setFilterStateUpdated] = useState(EMPTY_FILTER);
  const handleOpenPopup = (row) => {
    setValue(row);
    setshowPop(true);
  };
  const handleChangeOther = (name, value) => {
    setFilterState((values) => ({ ...values, [name]: value }));
  };
  const handleSelectOther = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const handleChangePage = (event, newPage) => {
    // const [pageTemp, setPageTemp] = useState(0);
    if (newPage <= pageTemp) {
      if (IsFirst) {
        setGetrecord("previous");
        setPageCount(1);
        setPage(newPage);
        setPageTemp(newPage);
        console.log("previous  previousprevious __handleChangePage");
      }
    } else {
      if (islastId) {
        setGetrecord("next");
        setPageCount(newPage + 1);
        setPage(newPage);
        setPageTemp(newPage);
        console.log("next  nextnextnext __handleChangePage");
      }
    }
    console.log("newPage  __handleChangePage", newPage);
    console.log("IsFirst  __handleChangePage", IsFirst);
    console.log("islastId  __handleChangePage", islastId);
  };

  const getMembersList = async (filterData, type) => {
    setIsLoadingSection(true);
    setDownloadCSVData([]);
    let postData = {
      retrive_array: ["stripe_payments"],
      status: status,
    };
    if (filterData.created) {
      postData.sort_by = filterData.sort_by;
      postData.created = moment(filterData.created).format("YYYY-MM-DD");
    }

    if (
      filterData.sort_by == "is_between" &&
      filterData.created &&
      filterData.end_date
    ) {
      postData.sort_by = filterData.sort_by;
      postData.end_date = moment(filterData.end_date).format("YYYY-MM-DD");
    }

    let recordId = "";
    if (getrecord == "next") {
      recordId = lastId;
    } else if (getrecord == "previous") {
      recordId = FirstId;
    } else {
      recordId = "";
    }
    let searchTextData = searchText;

    if (type == "clear_data") {
      searchTextData = "";
      setSearchText("");
    } else if (state && state.email) {
      searchTextData = state.email;
      setSearchText(searchTextData);
    }

    if (
      type == "from_search" ||
      type == "clear_data" ||
      type == "delete_data"
    ) {
      recordId = "";
    }
    if (page == 0) {
      recordId = "";
    }

    localStorage.setItem("transactions_list_data", JSON.stringify(filterData));
    setFilterData(filterData);
    const result = await subscription_stripe_list(
      recordId,
      rowsPerPage,
      searchTextData,
      postData,
      getrecord
    );
    if (result.code == 200) {
      let subscription_list = [];
      let download_csv_list = [];
      let array = [];
      let lastObject = {};
      let firstObject = {};
      let id = "";
      let firstId = "";
      if (result.stripe_payments.data) {
        array = result.stripe_payments.data;
        lastObject = array[array.length - 1];
      }
      if (array.length > 0) {
        firstObject = array[0];
      }
      if (lastObject?.id) {
        id = lastObject.id;
      }
      if (firstObject?.id) {
        firstId = firstObject.id;
      }
      if (firstId) {
        setFirstId(firstId);
      }
      if (id) {
        setLastId(id);
      }
      if (
        result.stripe_payments.data &&
        result.stripe_payments.data.length > 0
      ) {
        subscription_list = result.stripe_payments.data.map((event, index) => {
          let member_name = "N/A";
          if (event.customer && event.customer.email) {
            member_name =
              event.customer.name + " (" + event.customer.email + ")";
          }
          let event_plan = event.plan;
          let plan_title = "N/A";
          if (event_plan?.plan_title) {
            plan_title = event_plan?.plan_title;
          }
          let payment_access = event_plan?.payment_access;
          if (plan_title != "N/A") {
            if (event_plan?.is_plan_free) {
              plan_title = plan_title + " (Free)";
            } else if (payment_access == "recursion") {
              plan_title = plan_title + " (Recurring)";
            } else if (payment_access == "onetime") {
              plan_title = plan_title + " (OneTime)";
            } else {
              plan_title = plan_title;
            }
          }

          let product = "N/A";
          if (event.product) {
            product = event.product.name;
          }
          return {
            ...event,
            product,
            member_name: member_name,
            // created_at: moment(event.created * 1000).format("DD-MM-YYYY"),
            created_at: convertTimestampToDateWithTimezone(
              event?.created,
              "Europe/Dublin"
            ),
            amount:
              convertCurrencyToSign(event.currency) +
              (event.amount / 100).toFixed(2),
          };
        });

        download_csv_list = result.stripe_payments.data.map((event, index) => {
          let member_name = "N/A";
          let member_email = "N/A";
          if (event.customer && event.customer.name) {
            member_name = event.customer.name;
          }
          if (event.customer && event.customer.email) {
            member_email = event.customer.email;
          }
          return {
            ...event,
            member_name: member_name,
            member_email: member_email,
            // created_at: moment(event?.created * 1000).format("DD-MM-YYYY"),
            created_at: convertTimestampToDateWithTimezone(
              event?.created,
              "Europe/Dublin"
            ),
            amount: (event?.amount / 100).toFixed(2),
            status: event?.status === "succeeded" ? "Succeeded" : "Failed",
          };
        });
      }
      let chip_data = { ...filterData };
      if (chip_data.created) {
        let date = `${
          filterData.sort_by == "is_between" ? "Start" : "Created"
        } Date : ${moment(chip_data.created).format("DD-MM-YYYY")} `;
        chip_data.date = {
          chip_label: date,
          chip_value: date,
        };
        delete chip_data.created;
      }

      if (filterData.sort_by == "is_between" && chip_data.end_date) {
        let date_end = `End Date : ${moment(chip_data.end_date).format(
          "DD-MM-YYYY"
        )} `;
        chip_data.date_end = {
          chip_label: date_end,
          chip_value: date_end,
        };
        delete chip_data.end_date;
      }

      if (!chip_data.date) {
        delete chip_data.created;
        delete chip_data.sort_by;
      }

      if (!chip_data.date_end) {
        delete chip_data.end_date;
      }
      setUpdateFilterData(chip_data);
      setIsLast(result?.has_more_next);
      setIsFirst(result?.has_more_previous);

      console.log(
        "result?.has_more_previous IsFirst  __handleChangePage",
        result?.has_more_previous
      );
      console.log(
        "result?.has_more_next islastId  __handleChangePage",
        result?.has_more_next
      );

      setSubscriptionsData(subscription_list);
      // setDownloadCSVData(result?.stripe_payments);
      setDownloadCSVData(download_csv_list);
      setTotalCount(result.total_subscription_count);
      setTotalPages(result.total_pages);
      setIsLoadingSection(false);
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      setIsLoadingSection(false);
    }
  };

  const searchFunction = (type) => {
    setPage(0);
    setPageTemp(0);
    setPageCount(1);
    let filter_data = {
      ...filterData,
      // created: filterData?.created ?? new Date(),
      // end_date: filterData?.end_date ?? new Date(),
    };

    if (type && type == "filter") {
      filter_data = {
        ...filterData,
        created: filterData?.created ?? new Date(),
        end_date: filterData?.end_date ?? new Date(),
      };
    }
    getMembersList(filter_data, "from_search");
    setFilterDrawerState(false);
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageTemp(0);
    setPageCount(1);

    if (typeof data.date == "undefined") {
      delete data.date;
      delete data.date_end;
      data.created = null;
      data.end_date = null;
    }
    if (data.date) {
      delete data.date;
      delete data.created;
      delete data.end_date;
    }
    if (data.date_end) {
      delete data.date;
      delete data.created;
      delete data.end_date;
      delete data.date_end;
    }

    if (data.sort_by == "") {
      delete data.date;
      delete data.created;
      delete data.end_date;
      delete data.date_end;
    }

    getMembersList(data, "delete_data");
    setFilterData(data);
    setFilterState(data);
    localStorage.setItem("transactions_list_data", JSON.stringify(data));
  };

  const handleClearFilter = () => {
    let clear_data = {
      created: null,
      end_date: null,
      sort_by: "greater_then_or_equal",
    };

    setLastId("");
    setFirstId("");
    setPage(0);
    setPageTemp(0);
    setPageCount(1);
    setFilterData(clear_data);
    getMembersList(clear_data, "clear_data");
    localStorage.removeItem("transactions_list_data");
    setFilterDrawerState(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setGetrecord("");
    setFirstId("");
    SetLastId("");
    setPage(0);
    setPageTemp(0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageTemp(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageTemp(newPage - 1);
      setPageCount(newPage);
    }
  };
  const handleChaneOthers = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFilterState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleChangeDate = (name, value) => {
    setFilterState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  const handleCopiedUrl = () => {
    enqueueSnackbar("Subscription Id Copied to clipboard", {
      variant: "success",
    });
  };
  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    ...(state?.email
      ? []
      : [
          {
            id: "member_name",
            label: "Member",
          },
        ]),
    { id: "amount", label: "Amount" },
    {
      id: "receipt_url",
      label: "Receipt URL",
      renderData: (row) => {
        return (
          <>
            {row.receipt_url ? (
              <a
                href={row.receipt_url}
                className="anchor-style tex-center"
                target="_blank"
                rel="noreferrer"
              >
                Preview
              </a>
            ) : (
              "N/A"
            )}
          </>
        );
      },
    },
    {
      id: "created_at",
      label: "Transaction Date",
    },
    {
      id: "statusDate",
      label: "Status",
      renderData: (row) => {
        let className = "pending-ticket";
        return (
          <Label
            variant="ghost"
            color={row.status === "succeeded" ? "success" : "error"}
          >
            {row.status === "succeeded" ? "Succeeded" : "Failed"}
          </Label>
        );
      },
    },
  ];

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("transactions_list_data");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      if (filter_data.search_text) {
        setSearchText(filter_data.search_text);
      }
    }
    setFilterState(filter_data);
    setFilterStateUpdated(filter_data);
    getMembersList(filter_data, "not_from_search");
  }, [rowsPerPage, page]);
  const MENU_OPTIONS = [
    {
      label: "View Detail",
      icon: "iconoir:page-search",
      handleClick: handleOpenPopup,
    },
  ];

  if (isLoadingSection) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="">
        <div className=" mt-3">
          <FilteredChip
            data={updateFilterData}
            tempState={filterData}
            EMPTY_FILTER={EMPTY_FILTER}
            onDeleteChip={handleDeleteChip}
            onClear={handleClearFilter}
          />
          <div className="col-12">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              // MENU_OPTIONS={MENU_OPTIONS}
              data={subscriptionsData}
              rowArray={[20]}
              is_hide={state?.email ? true : false}
              className="card-with-background hide-pagination"
              custom_pagination={{
                total_count: totalCount,
                rows_per_page: rowsPerPage,
                page: page,
                handleChangePage: handleChangePage,
                onRowsPerPageChange: handleChangeRowsPerPage,
              }}
              custom_search={{
                searchText: searchText,
                setSearchText: setSearchText,
                handleSubmit: searchFunction,
                placeholder: "Search by Email",
              }}
              pageCount={pageCount}
              totalPages={totalPages}
              // handleChangePages={handleChangePages}
              // pagePagination={true}
            />
          </div>
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <StripeFilter
            handleChaneOthers={handleChaneOthers}
            filterData={filterData}
            handleSelectOther={handleSelectOther}
            searchFunction={searchFunction}
            handleClearFilter={handleClearFilter}
          />
        }
      />
      <CustomPopover
        isOpenPop={showPop}
        isClosePop={setshowPop}
        heigh="200px"
        title={value?.member_name ?? "detail"}
        componentToPassDown={<SubscriptionDetail value={value} />}
      />
    </>
  );
}
