import { invokeApi } from "../../bl_libs/invokeApi";

export const addStripeApi = async (data) => {
  const requestObj = {
    path: `/api/site_setting/add_stripe_setting`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addMissionControlSettings = async (data) => {
  const requestObj = {
    path: `/api/content_setting/update/mission_control_setting`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const add_fire_setting = async (data) => {
  const requestObj = {
    path: `/api/site_setting/add_fire_setting`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const add_bitly_settings = async (data) => {
  const requestObj = {
    path: `/api/default_setting/update/bitly_settings`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_twilio_settings = async (data) => {
  const requestObj = {
    path: `/api/default_setting/update_twilio_settings`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const get_bitly_settings = async () => {
  const requestObj = {
    path: `/api/default_setting/get/bitly_settings`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_twilio_settings = async () => {
  const requestObj = {
    path: `/api/default_setting/get_twilio_settings`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const WhatsappSettingApi = async (data) => {
  const requestObj = {
    path: `/api/default_setting/update/whatsapp_settings`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const shopSettingApi = async (data) => {
  const requestObj = {
    path: `/api/default_setting/update/shop_settings`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const ClickFunnelsSettingApi = async (data) => {
  const requestObj = {
    path: `/api/default_setting/update_clickfunnel_settings`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const SageSettingApi = async (data) => {
  const requestObj = {
    path: `/api/default_setting/update_sage_settings`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const trackerSettingApi = async (data) => {
  const requestObj = {
    path: `/api/default_setting/update/accountability_tracker_setting`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const GetwhatappApi = async (data) => {
  const requestObj = {
    path: `/api/default_setting/get/whatsapp_settings`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const GetshopApi = async (data) => {
  const requestObj = {
    path: `/api/default_setting/get/shop_settings`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const GetFunnelsApi = async (data) => {
  const requestObj = {
    path: `/api/default_setting/get_clickfunnel_settings`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const GetSageSettingsApi = async (data) => {
  const requestObj = {
    path: `/api/default_setting/get_sage_settings`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const GetTrackerApi = async (data) => {
  const requestObj = {
    path: `/api/default_setting/get/accountability_tracker_setting`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const addCalendarSettingApi = async (data) => {
  const requestObj = {
    path: `/api/site_setting/add_calendar_setting`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addNOtificationSetting = async (data) => {
  const requestObj = {
    path: `/api/content_setting/update_notification_setting`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addCampaignApi = async (data) => {
  const requestObj = {
    path: `/api/site_setting/add_active_compaign_setting`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addZoomSettingApi = async (data) => {
  const requestObj = {
    path: `/api/site_setting/zoom_settings`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addDhlSettingApi = async (data) => {
  const requestObj = {
    path: `/api/default_setting/update/dhl_settings`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addSandGrid = async (data) => {
  const requestObj = {
    path: `/api/site_setting/add_sendgrid_setting`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const siteSettingApi = async (data) => {
  const requestObj = {
    path: `/api/site_setting`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const getMissionControlSettings = async (data) => {
  const requestObj = {
    path: `/api/content_setting/get/mission_control_setting`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const getDhlSettingApi = async (data) => {
  const requestObj = {
    path: `/api/default_setting/get/dhl_settings`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const GetNotificationSettingApi = async (data) => {
  const requestObj = {
    path: `/api/content_setting/get_notification_settings`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const defaultSettingApi = async (data) => {
  const requestObj = {
    path: `/api/default_setting/`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const admin_logut_api = async (data) => {
  const requestObj = {
    path: `/admin_users/logout/admin?logout_from=${data}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_app_settings_api = async (data) => {
  const requestObj = {
    path: `/api/default_setting/get/app_settings`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_onboarding_settings = async () => {
  const requestObj = {
    path: `/api/default_setting/get/onboarding_settings`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_landingscreen_settings = async () => {
  const requestObj = {
    path: `/api/default_setting/get/landingscreen_settings`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const addDefaultSettingApi = async (data) => {
  const requestObj = {
    path: `/api/default_setting/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };

  return invokeApi(requestObj);
};

export const update_onboarding_settings_api = async (data) => {
  const requestObj = {
    path: `/api/default_setting/update/onboarding_settings`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };

  return invokeApi(requestObj);
};

export const update_landingscreen_settings_api = async (data) => {
  const requestObj = {
    path: `/api/default_setting/update/landingscreen_settings`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };

  return invokeApi(requestObj);
};

export const update_app_settings_api = async (data) => {
  const requestObj = {
    path: `/api/default_setting/update/app_settings`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };

  return invokeApi(requestObj);
};

export const addInvoiceSettingApi = async (data) => {
  const requestObj = {
    path: `/api/content_setting/invoice_setting`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };

  return invokeApi(requestObj);
};
export const emailTemplateApi = async (data) => {
  const requestObj = {
    path: `/api/email_template/`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
export const editEmailTemplateApi = async (data, id) => {
  const requestObj = {
    path: `/api/email_template/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };

  return invokeApi(requestObj);
};
export const detailEmailTemplateApi = async (id) => {
  const requestObj = {
    path: `/api/email_template/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
