import { invokeApi } from "../../bl_libs/invokeApi";

export const _create_portal_updates = async (data) => {
  const requestObj = {
    path: `/api/portal_updates/create_portal_updates`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_portal_updates = async (id, data) => {
  const requestObj = {
    path: `/api/portal_updates/update_portal_updates/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _list_portal_updates = async (page, limit, data) => {
  const requestObj = {
    path: `/api/portal_updates/list_portal_updates?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _detail_portal_updates = async (id) => {
  const requestObj = {
    path: `/api/portal_updates/detail_portal_updates/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _delete_portal_updates = async (id) => {
  const requestObj = {
    path: `/api/portal_updates/delete_portal_updates/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
