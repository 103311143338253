import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import {
  delete_lead_status,
  lead_status_list,
} from "src/DAL/SaleSections/LeadStatus";
import moment from "moment";
import PageLoader from "src/components/GeneralComponents/PageLoader";

export default function LeadStatus() {
  const navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);

  const getSaleLeadList = async () => {
    const result = await lead_status_list();
    if (result.code === 200) {
      const lead_status = result.lead_status.map((status) => {
        console.log(status.icon, "status__status");
        return {
          ...status,
          title: status.title,
          table_avatar: {
            src: s3baseUrl + status.icon?.thumbnail_1,
            alt: status.title,
          },
        };
      });
      setUserList(lead_status);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleEdit = (value) => {
    navigate(`/lead-status/edit-lead-status/${value._id}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await delete_lead_status(deleteDoc._id);
    if (result.code === 200) {
      getSaleLeadList();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleNavigate = () => {
    navigate(`/lead-status/add-lead-status`);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const TABLE_HEAD = [
    { id: "action", label: "Action", alignRight: false, type: "action" },
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "title",
      label: "Title",
      alignRight: false,
    },
    {
      id: "table_avatar",
      label: "Icon",
      alignRight: false,
      type: "thumbnail",
    },
    { id: "order", label: "Order", alignRight: false },
    {
      id: "createdAt",
      label: "Created At",
      renderData: (row) => {
        return (
          <>
            <span>{moment(row.createdAt).format("DD-MM-YYYY")}</span>
          </>
        );
      },
    },
    { id: "status", label: "Status", alignRight: false, type: "row_status" },
  ];

  useEffect(() => {
    getSaleLeadList();
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col-lg-8 col-sm-12">
            <h2>Lead Status</h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Lead Status
            </button>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={userList}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          pagePagination={true}
        />
      </div>
    </>
  );
}
