import * as React from "react";
import { useState } from "react";

import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Tooltip,
  Checkbox,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { addSalesApi, EditsaleApi } from "src/DAL/SaleSections/saleSection";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Icon } from "@iconify/react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import {
  addMissionScadule,
  mission_schedule_detail_api,
  UPdate_mission_Notification_settings,
  UPdate_mission_scadule_list,
  UPdate_mission_scadule_list_list,
  UPdate_mission_scadule_notification_list,
} from "src/DAL/MissionApi/MissionApiNew";
import { s3baseUrl } from "src/config/config";
import { dynamite } from "src/assets";
import SearchNotFound from "src/components/NotFound/SearchNotFound";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import FullPagePopupForTitle from "../GeneralSetting/FullPagePopupForTitle";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import dayjs from "dayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import EmailNotifySetting from "src/components/GeneralComponents/CalendarList/EmailNotifySetting";
import MessageSetting from "src/components/GeneralComponents/CalendarList/MessageSetting";
import PushNotifySetting from "src/components/GeneralComponents/CalendarList/PushNotifySetting";
import WhatsappNotifySetting from "src/components/GeneralComponents/CalendarList/WhatsappNotifySetting";
import SMSSettingCalender from "src/components/GeneralComponents/CalendarList/SMSSettingCalender";
import moment from "moment";
import { get_root_value } from "src/utils/domUtils";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  paper: {
    background: get_root_value("--popup-background-color"),
    color: get_root_value("--input-text-color"),
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const Input = styled("input")({
  display: "none",
});
const notification_object = {
  notify_before_unit: "minutes",
  notify_before_time: 30,
  notification_send_type: [],
};
const array = [
  {
    label: "Email",
    name: "email_notification_access",
    show_preview: true,
  },
  {
    label: "Notification",
    name: "push_notification_access",
  },
  {
    label: "WhatsApp",
    name: "whatsapp_notification_access",
  },
  {
    label: "Message",
    name: "message_notification_access",
  },
];

export default function MissionNotificationsSettings() {
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const { state } = useLocation();
  const [notificationArray, setNotificationArray] = useState([
    notification_object,
  ]);
  const [newNotification, setNewNotification] = useState(array);
  const [EmailNotification, setEmailNotification] = useState(false);

  const [drawerState, setDrawerState] = useState("");
  const [selectedObject, setSelectedObject] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [formType, setFormType] = useState("ADD");
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [scheduleDetails, setScheduleDetails] = useState({
    mission_Title: "",
    title: "",
  });

  const handleAddNotification = () => {
    setNotificationArray((old_array) => [...old_array, notification_object]);
  };

  const handle_drawer = () => {
    if (drawerState === "message_notification_access") {
      return {
        label: "Message Notification Setting",
        component: <MessageSetting {...drawer_data} />,
      };
    } else if (drawerState === "whatsapp_notification_access") {
      return {
        label: "Whatsapp Notification Setting",
        component: <WhatsappNotifySetting {...drawer_data} />,
      };
    } else if (drawerState === "push_notification_access") {
      return {
        label: "Push Notification Setting",
        component: <PushNotifySetting {...drawer_data} />,
      };
    } else if (drawerState === "sms_notification_access") {
      return {
        label: "SMS Notification Setting",
        component: <SMSSettingCalender {...drawer_data} />,
      };
    }
  };
  const handleChangeNewNotification = (event, value, index) => {
    let checked = event.target.checked;
    let temp_state = [...notificationArray];
    let temp_element = { ...temp_state[index] };
    if (checked) {
      temp_element.notification_send_type = [
        ...temp_element.notification_send_type,
        value,
      ];
    } else {
      temp_element.notification_send_type =
        temp_element.notification_send_type.filter(
          (item) => item.name !== value.name
        );
    }
    temp_state[index] = temp_element;
    setNotificationArray(temp_state);
  };

  const handleRemoveNotification = (index) => {
    const updatedItems = [...notificationArray];
    updatedItems.splice(index, 1);
    setNotificationArray(updatedItems);
  };

  const handleChangeNotification = (event, index) => {
    const { name, value } = event.target;
    let temp_state = [...notificationArray];
    let temp_element = { ...temp_state[index] };
    temp_element[name] = value;
    temp_state[index] = temp_element;
    setNotificationArray(temp_state);
  };
  const handleOpen = (data, index) => {
    setSelectedObject(data);
    setSelectedIndex(index);
    if (data.name == "email_notification_access") {
      setEmailNotification(true);
    } else {
      setDrawerState(data.name);
    }
  };

  const handleClose = (selected_obj, drawer_state) => {
    let temp_state = [...notificationArray];
    let temp_element = { ...temp_state[selectedIndex] };
    let selected_types = temp_element.notification_send_type;
    if (selected_types.length > 0) {
      selected_types = selected_types.map((item) => {
        if (item.name == drawer_state) {
          item = selected_obj ? selected_obj : selectedObject;
        }
        return item;
      });
    }
    temp_element.notification_send_type = selected_types;
    temp_state[selectedIndex] = temp_element;

    console.log(temp_element, "temp_elementtemp_element");

    setNotificationArray(temp_state);
    setDrawerState("");
    setSelectedObject({});
    setEmailNotification(false);
    setSelectedIndex(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let postData = {
      notification_settings: notificationArray,
    };
    console.log("postData  __postData", postData);
    const result = await UPdate_mission_Notification_settings(
      postData,
      params.id
    );
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  let breadCrumbMenu = [
    {
      title: `Missions ${state ? " (" + state?.title + ") " : ""}`,
      navigation: `/mission`,
      active: false,
    },
    {
      title: "Mission Notification Settings",
      navigation: ``,
      active: true,
    },
  ];

  let drawer_data = {
    onCloseDrawer: handleClose,
    data: selectedObject,
    drawerState: drawerState,
  };

  useEffect(() => {
    if (params && params.id) {
      if (state) {
        console.log(state, "statestatestate");

        setFormType("EDIT");
        if (
          state?.notification_settings &&
          state?.notification_settings.length > 0
        ) {
          const formattedNotifications = state.notification_settings.map(
            (notification) => ({
              ...notification,
              notification_time: `01-01-2025 ${notification.notification_time}`,
            })
          );
          setNotificationArray(formattedNotifications);
        }
      }
    }
  }, []);

  console.log(notificationArray, "notificationArraynotificationArray");

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-lg-12 col-sm-12">
          <div className="col-12 mb-4 ms-2">
            <span>
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
          </div>
        </div>
      </div>
      <h3
        style={{
          color: "#e4c073",
        }}
      >
        Mission Notification
      </h3>
      <div
        className="row"
        style={{
          background: "#1d1c1d",
          padding: "20px 10px",
          borderRadius: "10px",
        }}
      >
        {notificationArray.length > 0 &&
          notificationArray.map((notification, index) => {
            return (
              <>
                <div className="col-11 col-md-5 col-lg-6 mt-4">
                  {newNotification.map((notify) => {
                    let find_obj = null;
                    if (notification.notification_send_type?.length > 0) {
                      find_obj = notification.notification_send_type.find(
                        (item) => item.name == notify.name
                      );
                    }

                    return (
                      <span className="position-relative color-white">
                        {find_obj && (
                          <Icon
                            fontSize="18"
                            style={{
                              color: "var(--portal-theme-primary)",
                            }}
                            className="me-2 setIconPosition"
                            icon="circum:edit"
                            onClick={() => handleOpen(find_obj, index)}
                          />
                        )}
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) =>
                                handleChangeNewNotification(e, notify, index)
                              }
                              checked={Boolean(find_obj)}
                              className="p-1"
                            />
                          }
                          label={notify.label}
                          className="me-3"
                        />
                      </span>
                    );
                  })}
                </div>
                <div className="col-11 col-md-6 col-lg-2 ">
                  <FormControl variant="outlined" className="mt-3" size="small">
                    <InputLabel id="demo-simple-select-outlined-label">
                      Notify After
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={notification.notify_before_unit}
                      onChange={(e) => {
                        handleChangeNotification(e, index);
                      }}
                      label="Notify After"
                      name="notify_before_unit"
                      className="inputs-fields svg-color"
                      MenuProps={{
                        classes: {
                          paper: classes.paper,
                        },
                      }}
                      sx={{
                        color: get_root_value("--input-text-color"),
                      }}
                    >
                      <MenuItem value="minutes">Minutes</MenuItem>
                      <MenuItem value="hours">Hours</MenuItem>
                      <MenuItem value="days">Days</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col-11 col-md-5 col-lg-2">
                  <TextField
                    className="mt-3"
                    id="outlined-basic"
                    size="small"
                    label={`${
                      notification.notify_before_unit == "days"
                        ? "Days"
                        : notification.notify_before_unit == "hours"
                        ? "Hours"
                        : "Minutes"
                    }`}
                    variant="outlined"
                    name="notify_before_time"
                    type="number"
                    value={notification.notify_before_time}
                    required={true}
                    onChange={(e) => {
                      handleChangeNotification(e, index);
                    }}
                    inputProps={{
                      min: 0,
                      max:
                        notification.notify_before_unit == "days"
                          ? 100
                          : notification.notify_before_unit == "hours"
                          ? 24
                          : 60,
                    }}
                  />
                </div>

                <div className="col-1  p-0 mt-4">
                  <Tooltip title="Remove">
                    <RemoveCircleOutlineIcon
                      onClick={() => handleRemoveNotification(index)}
                      className="diary-icon-remove"
                    />
                  </Tooltip>
                </div>
              </>
            );
          })}
        <div className="col-12 mt-3 mb-4">
          <FormControlLabel
            control={<AddCircleOutlineIcon className="diary-icon-add mx-2" />}
            label="Add Notification"
            onClick={handleAddNotification}
            className="heading-calender"
          />
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row notification-statement" id="fixedbutton">
          <div className="text-end mt-4">
            <button className="small-contained-button">Submit</button>
          </div>
        </div>
      </form>
      <FullPagePopupForTitle
        open={EmailNotification}
        setOpen={setEmailNotification}
        handleClosePopup={handleClose}
        title={selectedObject}
        componentToPassDown={
          <EmailNotifySetting {...drawer_data} open={EmailNotification} />
        }
      />
      <CustomDrawer
        isOpenDrawer={Boolean(drawerState)}
        onCloseDrawer={handleClose}
        pageTitle={handle_drawer()?.label}
        componentToPassDown={handle_drawer()?.component}
      />
    </div>
  );
}
