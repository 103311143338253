import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { s3baseUrl } from "src/config/config";
import {
  badge_list_api,
  delete_bedge_api,
  delete_category_api,
  help_video_categories_list_api,
} from "src/DAL/HelpVideos/Categories";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import PageLoader from "src/components/GeneralComponents/PageLoader";

// ----------------------------------------------------------------------

export default function Badges({ type }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [categoriesData, setCategoriesData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState("");
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const getCategoriesListing = async (text) => {
    setIsLoading(true);
    const result = await badge_list_api(page, rowsPerPage, text);
    if (result.code == 200) {
      const categories = result.badges.map((category) => {
        return {
          ...category,
          table_avatar: {
            src: s3baseUrl + category?.icon.thumbnail_2,
            alt: category.title,
          },
          table_avatar_after_earned_icon: {
            src: s3baseUrl + category?.after_earned_icon?.thumbnail_2,
            alt: category.title,
          },
          table_avatar_general_icon: {
            src: s3baseUrl + category?.general_icon?.thumbnail_2,
            alt: category.title,
          },
          category_status: category.status,
        };
      });
      setCategoriesData(categories);
      setTotalPages(result.total_pages);
      setTotalCount(result.total_count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageCount(1);
    setPage(0);
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_bedge_api(deleteDoc._id);
    if (result.code === 200) {
      setCategoriesData((categoriesData) => {
        return categoriesData.filter((data) => data._id !== deleteDoc._id);
      });
      // getCategoriesListing();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleEdit = (value) => {
    navigate(`/rewards/edit-reward/${value._id}`, {
      state: value,
    });
  };

  const handleNavigate = () => {
    navigate(`/rewards/add-reward`);
  };
  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    localStorage.setItem("bedge_search", searchText);

    getCategoriesListing(searchText);
  };
  useEffect(() => {
    let search_text = localStorage.getItem("bedge_search");
    if (search_text) {
      setSearchText(search_text);
      getCategoriesListing(search_text);
    } else {
      setSearchText("");
      getCategoriesListing("");
    }
  }, [page, rowsPerPage]);
  const TABLE_HEAD = [
    { id: "action", label: "Action", alignRight: false, type: "action" },
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "title",
      label: "Title",
      alignRight: false,
    },
    {
      id: "table_avatar",
      label: "Before Earned Icon",
      alignRight: false,
      type: "thumbnail",
    },
    {
      id: "table_avatar_after_earned_icon",
      label: "After Earned Icon",
      alignRight: false,
      type: "thumbnail",
    },
    {
      id: "table_avatar_general_icon",
      label: "Icon",
      alignRight: false,
      type: "thumbnail",
    },
    {
      id: "category_status",
      label: "Status",
      type: "row_status",
    },
  ];

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
      color: "#da3030",
    },
  ];

  if (isLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Reward?"}
        handleAgree={handleDelete}
      />

      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-lg-6 col-sm-12">
            <h2>Rewards</h2>
          </div>
          <div className="col-lg-6 col-sm-12 text-end ">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Reward
            </button>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={categoriesData}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          pagePagination={true}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
        />
      </div>
    </>
  );
}
