import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {
  UpdatePopUpContentSetting,
  UpdateShopContentSetting,
} from "src/DAL/ClientSetting/ClientSetting";
import { useEffect } from "react";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import {
  _get_attitude_accessment_setting,
  _get_mission_popup_settings,
  _get_store_house_setting,
} from "src/DAL/AttitudeAccessmentQuestions/AttitudeAccessmentSetting";
import { project_mode } from "src/config/config";
import { usePGIMode } from "src/Hooks/PGIModeContext";
import { isValidObject } from "src/utils/constant";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { _get_book_a_call_sale_pages } from "src/DAL/MissionApi/MissionApiNew";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Upgrade_popup = {
  is_show_popup: false,
  description: "",
  badge_level: [],
  upgrade_button_text: "",
  upgrade_button_url: "",
  upgrade_for: "membership",
  show_after_times_portal_opens: 0,
  show_to_members: "all",
};
const book_a_call_popup = {
  is_show_popup: false,
  description: "",
  badge_level: [],
  book_a_call_page: null,
  book_call_button_text: "",
  show_after_times_portal_opens: 0,
  show_to_members: "all",
};

export default function UpgradePopupSettings() {
  const classes = useStyles();
  const { badgeLevels } = usePGIMode();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [missions, setMission] = useState([]);
  const [salePageList, setSalePageList] = useState([]);
  const [searchText, setSearchText] = useState("");

  const [inputs, setInputs] = React.useState({
    mission_upgrade_popup_setting: Upgrade_popup,
    mission_book_call_popup_setting: book_a_call_popup,
    book_a_call_description: "",
    upgrade_popup_description: "",
  });

  const clientDetail = async () => {
    setIsLoading(true);
    const result = await _get_mission_popup_settings();
    if (result.code == 200) {
      setMission(result.missions);
      setInputs((prevValues) => ({
        ...prevValues,

        book_a_call_description:
          result?.content_settings?.mission_book_call_popup_setting
            ?.description,
        upgrade_popup_description:
          result?.content_settings?.mission_upgrade_popup_setting?.description,

        mission_upgrade_popup_setting: isValidObject(
          result?.content_settings?.mission_upgrade_popup_setting
        )
          ? result?.content_settings?.mission_upgrade_popup_setting
          : Upgrade_popup,
        mission_book_call_popup_setting: isValidObject(
          result?.content_settings?.mission_book_call_popup_setting
        )
          ? result?.content_settings?.mission_book_call_popup_setting
          : book_a_call_popup,
      }));
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getSalePageDetail = async () => {
    const result = await _get_book_a_call_sale_pages({
      search: searchText,
    });
    if (result.code === 200) {
      setSalePageList(result?.sale_pages);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);
    if (
      inputs?.mission_upgrade_popup_setting?.is_show_popup &&
      !inputs.upgrade_popup_description
    ) {
      enqueueSnackbar("upgrade popup description is required", {
        variant: "error",
      });
      return;
    }
    if (
      inputs?.book_a_call_description?.is_show_popup &&
      !inputs.book_a_call_description
    ) {
      enqueueSnackbar("book call popup description is required", {
        variant: "error",
      });
      return;
    }

    let postData = {
      mission_book_call_popup_setting: inputs?.mission_book_call_popup_setting,
      mission_upgrade_popup_setting: inputs?.mission_upgrade_popup_setting,
    };
    if (inputs?.mission_upgrade_popup_setting?.is_show_popup) {
      postData.mission_upgrade_popup_setting.description =
        inputs?.upgrade_popup_description;
    }
    if (inputs?.mission_book_call_popup_setting?.is_show_popup) {
      postData.mission_book_call_popup_setting.description =
        inputs?.book_a_call_description;
    }
    const result = await UpdatePopUpContentSetting(postData);
    if (result.code === 200) {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };
  const handleChangeOthers = (event, name) => {
    setInputs((prevValues) => ({
      ...prevValues,
      mission_book_call_popup_setting: {
        ...prevValues.mission_book_call_popup_setting,
        [name]: event,
      },
    }));
  };

  const handleChange = (event, object) => {
    const name = event.target.name;
    const value = event.target.value;

    if (object === "mission_upgrade_popup_setting") {
      setInputs((prevValues) => ({
        ...prevValues,
        mission_upgrade_popup_setting: {
          ...prevValues.mission_upgrade_popup_setting,
          [name]: value,
        },
      }));
    } else {
      setInputs((prevValues) => ({
        ...prevValues,
        mission_book_call_popup_setting: {
          ...prevValues.mission_book_call_popup_setting,
          [name]: value,
        },
      }));
    }
  };
  const handleChangeMission = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  useEffect(() => {
    clientDetail();
    getSalePageDetail();
  }, []);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getSalePageDetail();
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [searchText]);
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin">
        <div className="col-6">
          <h2>Popup Settings</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div class="col-12 wheel-of-life-setting-section-heading">
            <h3 className="mt-3">Upgrade Popup settings</h3>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Is Show Popup
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_show_popup"
                value={inputs?.mission_upgrade_popup_setting?.is_show_popup}
                label="Is Show Popup"
                onChange={(e) =>
                  handleChange(e, "mission_upgrade_popup_setting")
                }
                required
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>

          {inputs?.mission_upgrade_popup_setting?.is_show_popup && (
            <>
              <div className="col-6 mt-4">
                <TextField
                  className="text-muted"
                  id="outlined-basic"
                  label="App/Portal Open Count for Popup"
                  variant="outlined"
                  fullWidth
                  type="number"
                  inputProps={{
                    min: 0,
                  }}
                  name="show_after_times_portal_opens"
                  onChange={(e) =>
                    handleChange(e, "mission_upgrade_popup_setting")
                  }
                  value={
                    inputs?.mission_upgrade_popup_setting
                      ?.show_after_times_portal_opens
                  }
                  required
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="demo-multiple-select-label">
                    Badge Level
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-select-label"
                    id="demo-multiple-select"
                    name="badge_level"
                    label="Badge Level"
                    multiple
                    value={
                      inputs?.mission_upgrade_popup_setting?.badge_level || []
                    }
                    onChange={(e) =>
                      handleChange(e, "mission_upgrade_popup_setting")
                    }
                    renderValue={(selected) =>
                      badgeLevels
                        .filter((level) => selected.includes(level._id))
                        .map((level) => level.title)
                        .join(", ")
                    }
                  >
                    {badgeLevels.map((level, index) => (
                      <MenuItem key={index} value={level._id}>
                        {level.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">
                    Show To Members
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="show_to_members"
                    value={
                      inputs?.mission_upgrade_popup_setting?.show_to_members
                    }
                    label="Show To Members"
                    onChange={(e) =>
                      handleChange(e, "mission_upgrade_popup_setting")
                    }
                    required
                  >
                    <MenuItem value={"all"}>All Members</MenuItem>
                    <MenuItem value={"active"}>Membership Active</MenuItem>
                    <MenuItem value={"expired"}>Membership Expired</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-6 mt-3">
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">
                    Upgrade For
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="upgrade_for"
                    value={inputs?.mission_upgrade_popup_setting?.upgrade_for}
                    label="Upgrade For"
                    onChange={(e) =>
                      handleChange(e, "mission_upgrade_popup_setting")
                    }
                  >
                    <MenuItem value="membership">Membership</MenuItem>
                    <MenuItem value="sale_page">Sale Page</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-6 mt-3">
                <TextField
                  className="text-muted"
                  id="outlined-basic"
                  label="Upgrade Button Text"
                  variant="outlined"
                  fullWidth
                  name="upgrade_button_text"
                  onChange={(e) =>
                    handleChange(e, "mission_upgrade_popup_setting")
                  }
                  value={
                    inputs?.mission_upgrade_popup_setting?.upgrade_button_text
                  }
                  required
                />
              </div>
              <div className="col-6 mt-3">
                <TextField
                  className="text-muted"
                  id="outlined-basic"
                  label="Upgrade Button Url"
                  variant="outlined"
                  fullWidth
                  type="url"
                  name="upgrade_button_url"
                  onChange={(e) =>
                    handleChange(e, "mission_upgrade_popup_setting")
                  }
                  value={
                    inputs?.mission_upgrade_popup_setting?.upgrade_button_url
                  }
                  required
                />
              </div>
              <div className="col-12 mt-4">
                <h4 className="mb-3">Description *</h4>
                <GeneralCkeditor
                  setInputs={setInputs}
                  inputs={inputs}
                  name="upgrade_popup_description"
                  editorHeight={250}
                />
              </div>
            </>
          )}

          <div class="row wheel-of-life-setting-section-heading">
            <h3 className="mt-4">Book Call Popup Setting</h3>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">
                  Is Show Popup
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="is_show_popup"
                  value={inputs?.mission_book_call_popup_setting?.is_show_popup}
                  label="Is Show Popup"
                  onChange={(e) =>
                    handleChange(e, "mission_book_call_popup_setting")
                  }
                  required
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </div>
            {inputs?.mission_book_call_popup_setting?.is_show_popup && (
              <>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <FormControl fullWidth required>
                    <InputLabel id="demo-multiple-select-label">
                      Badge Level
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-select-label"
                      id="demo-multiple-select"
                      name="badge_level"
                      label="Badge Level"
                      multiple
                      value={
                        inputs?.mission_book_call_popup_setting?.badge_level ||
                        []
                      }
                      onChange={(e) =>
                        handleChange(e, "mission_book_call_popup_setting")
                      }
                      renderValue={(selected) =>
                        badgeLevels
                          .filter((level) => selected.includes(level._id))
                          .map((level) => level.title)
                          .join(", ")
                      }
                    >
                      {badgeLevels.map((level, index) => (
                        <MenuItem key={index} value={level._id}>
                          {level.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-6 mt-4">
                  <TextField
                    className="text-muted"
                    id="outlined-basic"
                    label="App/Portal Open Count for Popup"
                    variant="outlined"
                    fullWidth
                    type="number"
                    inputProps={{
                      min: 0,
                    }}
                    name="show_after_times_portal_opens"
                    onChange={(e) =>
                      handleChange(e, "show_after_times_portal_opens")
                    }
                    value={
                      inputs?.mission_book_call_popup_setting
                        ?.show_after_times_portal_opens
                    }
                    required
                  />
                </div>
                <div className="col-6 mt-4">
                  <MUIAutocomplete
                    inputLabel="Book A Call Page"
                    selectedOption={
                      inputs?.mission_book_call_popup_setting?.book_a_call_page
                    }
                    setSelectedOption={(e) => {
                      handleChangeOthers(e, "book_a_call_page");
                    }}
                    optionsList={salePageList}
                    autoComplete="new-password"
                    name="sale_page_title"
                    setCustomSearch={setSearchText}
                    required
                  />
                </div>
                <div className="col-6 mt-3">
                  <TextField
                    className="text-muted"
                    id="outlined-basic"
                    label="Book Call Button Text"
                    variant="outlined"
                    fullWidth
                    name="book_call_button_text"
                    onChange={(e) =>
                      handleChange(e, "mission_book_call_popup_setting")
                    }
                    value={
                      inputs?.mission_book_call_popup_setting
                        ?.book_call_button_text
                    }
                    required
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                  <FormControl fullWidth required>
                    <InputLabel id="demo-simple-select-label">
                      Show To Members
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="show_to_members"
                      value={
                        inputs?.mission_book_call_popup_setting?.show_to_members
                      }
                      label="Show To Members"
                      onChange={(e) =>
                        handleChange(e, "mission_book_call_popup_setting")
                      }
                      required
                    >
                      <MenuItem value={"all"}>All Members</MenuItem>
                      <MenuItem value={"active"}>Membership Active</MenuItem>
                      <MenuItem value={"expired"}>Membership Expired</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col-12 mt-4">
                  <h4 className="mb-3">Description *</h4>
                  <GeneralCkeditor
                    setInputs={setInputs}
                    inputs={inputs}
                    name="book_a_call_description"
                    editorHeight={250}
                  />
                </div>
              </>
            )}
          </div>

          <div className="text-end mt-4" id="fixedbutton">
            <button className="small-contained-button">
              {isLoadingForm ? "Updating" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
