import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { s3baseUrl } from "src/config/config";
import {
  delete_video_api,
  help_videos_list_api,
} from "src/DAL/HelpVideos/HelpVideos";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import PageLoader from "src/components/GeneralComponents/PageLoader";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false, type: "action" },
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "title", label: "Title", alignRight: false },

  { id: "table_avatar", label: "Image", alignRight: false, type: "thumbnail" },
  { id: "order", label: "Order", alignRight: false },
  {
    id: "video_status",
    label: "Status",
    type: "row_status",
  },
];

export default function HelpVideoList() {
  const navigate = useNavigate();
  const { type, slug } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [videosData, setVideosData] = useState([]);
  const [data, setData] = useState([]);

  const getVideosListing = async () => {
    setIsLoading(true);
    const result = await help_videos_list_api(slug, type);
    if (result.code == 200) {
      setData(result);
      const videos = result.help_video.map((video) => {
        return {
          ...video,
          category: video.help_video_category?.title,
          table_avatar: {
            src: s3baseUrl + video.image.thumbnail_2,
            alt: video.title,
          },
          video_status: video.status,
        };
      });
      setVideosData(videos);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_video_api(deleteDoc.help_video_slug);
    if (result.code === 200) {
      getVideosListing();
      setVideosData((videosData) => {
        return videosData.filter((data) => data._id !== deleteDoc._id);
      });
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleEdit = (value) => {
    if (type == "admin") {
      navigate(
        `/help-video-categories/help-videos/edit-video/${value.help_video_slug}`,
        {
          state: value,
        }
      );
    }
    if (type == "delegate") {
      navigate(
        `/delegate-help-video-categories/help-videos/edit-video/${value.help_video_slug}`,
        {
          state: value,
        }
      );
    }
    if (type == "client") {
      navigate(
        `/client-help-video-categories/help-videos/edit-video/${value.help_video_slug}`,
        {
          state: value,
        }
      );
    }
  };

  const handleNavigate = () => {
    if (type == "admin") {
      navigate(
        `/help-video-categories/help-videos/add-video/${type}/${slug}`,
        {}
      );
    }
    if (type == "delegate") {
      navigate(
        `/delegate-help-video-categories/help-videos/add-video/${type}/${slug}`,
        {}
      );
    }
    if (type == "client") {
      navigate(
        `/client-help-video-categories/help-videos/add-video/${type}/${slug}`,
        {}
      );
    }
  };

  useEffect(() => {
    getVideosListing();
  }, []);

  let breadCrumbMenu = [
    {
      title: "Help Video Categories",
      navigation:
        type == "admin"
          ? `/help-video-categories`
          : type == "client"
          ? `/client-help-video-categories`
          : `/delegate-help-video-categories`,
      active: false,
    },
    {
      title: data?.help_video_category?.title,
      navigation:
        type == "admin"
          ? `/help-video-categories`
          : type == "client"
          ? `/client-help-video-categories`
          : `/delegate-help-video-categories`,
      active: false,
    },
    {
      title: "Videos",
      navigation: ``,
      active: true,
    },
  ];

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  if (isLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this video?"}
        handleAgree={handleDelete}
      />

      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-lg-6 col-sm-12">
            <span>
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
            <h2 className="ms-2 mt-3">Help Videos </h2>
          </div>
          <div className="col-lg-6 col-sm-12 text-end ">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Video
            </button>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={videosData}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          pagePagination={true}
        />
      </div>
    </>
  );
}
