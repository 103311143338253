import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import { useEffect } from "react";
import {
  add_automated_group_api,
  automated_group_detail_api,
  update_automated_group_api,
} from "src/DAL/Programme/AutomatedGroups/AutomatedGroups";
import PageLoader from "src/components/GeneralComponents/PageLoader";

export default function AddOrUpdateAutomatedGroup() {
  const navigate = useNavigate();
  const params = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [formType, setFormType] = useState("ADD");
  const [inputs, setInputs] = useState({
    title: "",
    automated_group_start_day: 0,
    automated_group_end_day: 0,
    status: true,
  });

  const handleNavigate = () => {
    navigate(`/programmes/automated-groups/${params.programme_slug}`);
  };

  const getGroupData = async () => {
    setIsLoading(true);
    let result = await automated_group_detail_api(params.group_slug);
    if (result.code == 200) {
      setFormType("EDIT");
      setInputs(result.group);
      setIsLoading(false);
    } else {
      handleNavigate();
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let formData = {
      title: inputs.title,
      status: inputs.status,
      program_slug: params.programme_slug,
      automated_group_start_day: inputs.automated_group_start_day,
      automated_group_end_day: inputs.automated_group_end_day,
    };

    const result =
      formType === "ADD"
        ? await add_automated_group_api(formData)
        : await update_automated_group_api(formData, params.group_slug);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      handleNavigate();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    if (params && params.group_slug) {
      if (state) {
        setFormType("EDIT");
        setInputs(state);
      } else {
        getGroupData();
      }
    }
  }, []);

  if (isLoading === true) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={handleNavigate}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2>{`${formType === "ADD" ? "Add" : "Edit"} Automated Group`}</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Group Name"
              variant="outlined"
              fullWidth
              required
              name="title"
              value={inputs.title}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Group Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                required
                value={inputs.status}
                label="Group Status"
                onChange={handleChange}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Group Start Day"
              variant="outlined"
              fullWidth
              required
              type="number"
              name="automated_group_start_day"
              value={inputs.automated_group_start_day}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Group End Day"
              variant="outlined"
              fullWidth
              required
              type="number"
              name="automated_group_end_day"
              value={inputs.automated_group_end_day}
              onChange={handleChange}
            />
          </div>

          <div className="text-end mt-4">
            <button className="small-contained-button">
              {formType == "ADD" ? "Submit" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
