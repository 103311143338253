import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { Icon } from "@iconify/react";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import SaleCommisssionFilter from "./SaleCommisssionFilter";
import {
  Requested_csv_files_api,
  sales_commissiions_api,
} from "src/DAL/AdminUserApi/AdminUserApi";
import moment from "moment";
import FilteredChip from "src/components/FilteredChip";
import { dd_date_format } from "src/utils/constant";
import { download_csv_file } from "src/utils/convertHtml";
import GeneralRequestForCsv from "../MembersList/GeneralRequestForCsv";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const subcribers_csv = [
  { label: "Product", value: "page", is_checked: true, is_disabled: true },
  {
    label: "Total Earnings",
    value: "total_earnings",
    is_checked: true,
    is_disabled: true,
  },
  {
    label: "Total Commission",
    value: "total_commission",
    is_checked: true,
    is_disabled: true,
  },
  {
    label: "Total Team Diego Commission",
    value: "total_dynamite_digital_commission",
    is_checked: true,
    is_disabled: true,
  },
  {
    label: "Total DP Commission",
    value: "total_dp_commission",
    is_checked: true,
    is_disabled: true,
  },
];

const SaleCommissionReport = () => {
  const [categoryList, setCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openFilter, setOpenfilter] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );
  const EMPTY_FILTER = {
    date_from: firstDayOfMonth,
    date_to: lastDayOfMonth,
    user_id: null,
    user_type: "all",
    pages: [],
    page_type: "all",
  };

  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [updateFilterData, setUpdateFilterData] = useState(EMPTY_FILTER);
  const [showExportcsvFile, setShowExportcsvFile] = useState(false);
  const [filterQuery, setFilterQuery] = useState({});
  const showPopUPcsv = () => {
    setShowExportcsvFile(true);
  };

  const handleSelectOther = (name, value) => {
    setFilterData((values) => {
      let newValues = { ...values, [name]: value };
      if (name === "date_from" || name === "date_to") {
        const { date_from, date_to } = newValues;

        if (date_from && date_to) {
          const diffInMonths = moment(date_to).diff(
            moment(date_from),
            "months"
          );

          if (diffInMonths >= 1) {
            newValues.date_to = null;
          }
        }
      }
      return newValues;
    });
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFilterData((values) => ({ ...values, [name]: value }));
  };
  console.log(filterData, "filterDatafilterDatafilterData");

  const searchFunction = () => {
    if (!filterData.date_to) {
      enqueueSnackbar("please select to date", {
        variant: "error",
      });
      return;
    }
    if (filterData.page_type == "specific") {
      if (filterData.pages.length <= 0) {
        enqueueSnackbar("please select atleast one product", {
          variant: "error",
        });
        return;
      }
    }

    getProductCategories(filterData);
    setOpenfilter(false);
    localStorage.setItem("sale_commission_filter", JSON.stringify(filterData));
  };

  const handleClearFilter = () => {
    getProductCategories(EMPTY_FILTER);
    setUpdateFilterData(EMPTY_FILTER);
    setFilterData(EMPTY_FILTER);
    localStorage.removeItem("sale_commission_filter");
    setOpenfilter(false);
  };
  const handleOPenDawer = () => {
    setOpenfilter(true);
  };
  const getProductCategories = async (filterData) => {
    let postData = { ...filterData };
    if (postData.page_type == "specific") {
      if (postData.pages.length > 0) {
        postData.pages = postData.pages.map((item) => {
          return item._id;
        });
      }
    } else {
      delete postData.pages;
    }
    if (postData.user_type == "delegate_user" && postData.user_id) {
      postData.user_id = postData.user_id._id;
    } else {
      delete postData.user_id;
    }
    if (postData.date_from) {
      postData.date_from = moment(postData.date_from).format("YYYY-MM-DD");
    }
    if (postData.date_to) {
      postData.date_to = moment(postData.date_to).format("YYYY-MM-DD");
    }

    setIsLoading(true);
    const result = await sales_commissiions_api(postData);

    if (result.code == 200) {
      const ListData = result.data.map((items) => {
        return {
          ...items,
          total_dynamite_fire_payment_fee_amount:
            "£" + items.total_dynamite_fire_payment_fee_amount?.toFixed(2),
          total_dynamite_commission:
            "£" + items.total_dynamite_commission?.toFixed(2),
          total_amount: "£" + items.total_amount?.toFixed(2),
          total_commission: "£" + items.total_commission?.toFixed(2),
          total_dynamite_fire_payment_fee_amount_export:
            items.total_dynamite_fire_payment_fee_amount?.toFixed(2),
          total_dynamite_commission_export:
            items.total_dynamite_commission?.toFixed(2),
          total_amount_export: items.total_amount?.toFixed(2),
          total_commission_export: items.total_commission?.toFixed(2),
        };
      });
      setCategoryList(ListData);
      setFilterQuery(result.query);
      let chipData = { ...filterData };
      if (chipData.user_type !== "delegate_user") {
        delete chipData.user_id;
      }
      if (chipData.page_type !== "specific") {
        delete chipData.pages;
        setFilterData((values) => ({ ...values, ["pages"]: [] }));
      }
      if (chipData.date_from) {
        let date = `Start Date : ${dd_date_format(
          chipData.date_from
        )} End Date :  ${dd_date_format(chipData.date_to)}`;
        chipData.date = {
          chip_label: date,
          chip_value: date,
        };
        delete chipData.date_to;
        delete chipData.date_from;
      }
      setUpdateFilterData(chipData);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },

    { id: "page", label: "Product" },
    { id: "total_amount", label: "Total Earnings" },
    { id: "total_commission", label: "Total Commission" },
    {
      id: "total_dynamite_commission",
      label: "Total Team Diego Commission",
    },
    {
      id: "total_dynamite_fire_payment_fee_amount",
      label: "Total DP Commission",
    },
  ];

  const headers = [
    { label: "Product", key: "page" },
    { label: "Total Earnings", key: "total_amount_export" },
    { label: "Total Commission", key: "total_commission_export" },
    {
      label: "Total Team Diego Commission",
      key: "total_dynamite_commission_export",
    },
    {
      label: "Total DP Commission",
      key: "total_dynamite_fire_payment_fee_amount_export",
    },
  ];
  const handleChaneOthers = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };
  const handleDeleteChip = (data) => {
    console.log(data, "datadatadata");

    getProductCategories(data);
    localStorage.setItem("sale_commission_filter", JSON.stringify(data));
    setFilterData(data);
  };
  const handleExportClick = () => {
    download_csv_file(headers, categoryList);
  };
  useEffect(() => {
    getProductCategories(filterData);
  }, []);
  if (isLoading == true) {
    return <PageLoader />;
  }
  return (
    <>
      {" "}
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-lg-8 col-sm-12 text-end d-flex">
            <h2>Sales & Commission Report</h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end d-flex justify-content-end">
            <div>
              <button
                className="small-contained-button me-2 mt-1 mb-2"
                onClick={handleOPenDawer}
              >
                Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
              </button>
              {categoryList.length > 0 && (
                <button
                  className="small-contained-button me-2"
                  onClick={showPopUPcsv}
                >
                  Request For CSV
                </button>
              )}
            </div>
          </div>
          <div className="col-lg-12 col-sm-12 mt-3">
            <FilteredChip
              data={updateFilterData}
              tempState={filterData}
              EMPTY_FILTER={EMPTY_FILTER}
              onDeleteChip={handleDeleteChip}
              onClear={handleClearFilter}
            />
          </div>
        </div>{" "}
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={categoryList}
          className="card-with-background"
          // hide_footer_pagination={true}
          // pagePagination={true}

          is_hide={true}
        />
        <GeneralPopUpModel
          open={openFilter}
          setOpen={setOpenfilter}
          title={" Sales & Commission Report Filter"}
          componentToPassDown={
            <SaleCommisssionFilter
              handleChaneOthers={handleChaneOthers}
              filterData={filterData}
              handleSelectOther={handleSelectOther}
              searchFunction={searchFunction}
              handleClearFilter={handleClearFilter}
              handleChange={handleChange}
            />
          }
        />
        <GeneralPopUpModel
          open={showExportcsvFile}
          setOpen={setShowExportcsvFile}
          title={"Export CSV file request  "}
          componentToPassDown={
            <GeneralRequestForCsv
              options_array={subcribers_csv}
              collection_name={"sales_commission_report"}
              filter_data={filterQuery}
              setShowExportcsvFile={setShowExportcsvFile}
            />
          }
        />
      </div>
    </>
  );
};

export default SaleCommissionReport;
