import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import {
  addPaymentRequest,
  paymentTemplateListApi,
  productListApi,
} from "src/DAL/member/member";
import { useEffect } from "react";
import { programmeListing } from "src/DAL/Programme/Programme";
import PageLoader from "src/components/GeneralComponents/PageLoader";

export default function AddBillingsRequest() {
  const navigate = useNavigate();
  const { member_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [products, setProducts] = useState([]);
  const [programmes, setProgrammes] = useState([]);
  const [installmentAmount, setInstallmentAmount] = useState("");

  const [inputs, setInputs] = useState({
    request_title: "",
    currency: "gbp",
    total_amount: "",
    vat_number: "",
    transaction_note: "",
    template_type: "",
    payment_request_type: "",
    product: "",
    status: true,
    programme: "",
    initial_amount: "",
    month: "",
    installment_amount: "",
    show_on_consultant: "no",
  });

  const programmeList = async () => {
    let result = await programmeListing();
    if (result.code == 200) {
      setProgrammes(result.program);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    }
  };

  const productList = async () => {
    setIsLoading(true);
    let result = await productListApi();
    if (result.code == 200) {
      setProducts(result.product);
      programmeList();
    } else {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    }
  };

  const paymentTemplates = async () => {
    setIsLoading(true);
    let result = await paymentTemplateListApi();
    if (result.code == 200) {
      setTemplates(result.payment_template);
      productList();
    } else {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    }
  };

  const handleChangeInputTemplate = async (event) => {
    const value = event.target.value;
    setInputs({ ...inputs, ["template_type"]: value });
    setInputs((prevState) => ({
      ...prevState,
      request_title: value.title,
      currency: value.currency,
      total_amount: value.total_amount,
      vat_number: value.vat_number,
      transaction_note: value.short_description,
      payment_request_type: value.template_type,
      product: value.product?._id,
      programme: value.program?.program_slug,
      month: value.no_of_installment,
      initial_amount: value.initial_amount,
      installment_amount: value.installment_amount,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let postData = {
      request_title: inputs.request_title,
      request_type: inputs.payment_request_type,
      total_amount: inputs.total_amount,
      currency: inputs.currency,
      vat_number: inputs.vat_number,
      transaction_note: inputs.transaction_note,
      payment_template: inputs.template_type._id,
      member_id: member_id,
      program_slug: inputs.programme,
      product: inputs.product,
      status: inputs.status,
      show_on_consultant: inputs.show_on_consultant,
    };
    if (inputs.payment_request_type === "recurring") {
      postData.initial_amount = inputs.initial_amount;
      postData.month = inputs.month;
    }

    const result = await addPaymentRequest(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      handleBack();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleBack = () => {
    navigate(`/member/view-billing-list/${member_id}`);
  };

  useEffect(() => {
    paymentTemplates();
  }, []);

  useEffect(() => {
    let installment = inputs.total_amount / inputs.month;
    setInstallmentAmount(installment);
  }, [inputs.total_amount, inputs.month]);

  if (isLoading === true) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton className="back-screen-button mb-4" onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2>Add Payment Request</h2>
        </div>
      </div>
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Request Title"
            variant="outlined"
            fullWidth
            required
            name="request_title"
            value={inputs.request_title}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="status"
              value={inputs.status}
              label="Status"
              onChange={handleChange}
              required
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Delegates Access
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="show_on_consultant"
              value={inputs.show_on_consultant}
              label="Delegates Access"
              onChange={handleChange}
              required
            >
              <MenuItem value="no">No Access</MenuItem>
              <MenuItem value="list">Listing Access</MenuItem>
              <MenuItem value="all">All Access</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">Currency</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="currency"
              value={inputs.currency}
              label="currency"
              onChange={handleChange}
              required
            >
              <MenuItem value="usd">Dollar</MenuItem>
              <MenuItem value="gbp">UK Pounds</MenuItem>
              <MenuItem value="eur">Euro</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Payment Template Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="template_type"
              value={inputs.template_type}
              label="Payment Template Type"
              onChange={handleChangeInputTemplate}
            >
              {templates.map((template) => {
                return <MenuItem value={template}>{template?.title}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Product</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="product"
              value={inputs.product}
              label="product"
              onChange={handleChange}
            >
              {products.map((product) => {
                return <MenuItem value={product._id}>{product.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Programme</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="programme"
              value={inputs.programme}
              label="Programme"
              onChange={handleChange}
            >
              {programmes.map((programme) => {
                return (
                  <MenuItem value={programme.program_slug}>
                    {programme.title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Payment request Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="payment_request_type"
              value={inputs.payment_request_type}
              label="Payment Request Type"
              onChange={handleChange}
            >
              <MenuItem value="onetime">One Time</MenuItem>
              <MenuItem value="recurring">Recurring</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Total Amount"
            variant="outlined"
            fullWidth
            type="number"
            required
            name="total_amount"
            value={inputs.total_amount}
            onChange={handleChange}
          />
        </div>
        {inputs.payment_request_type === "recurring" && (
          <>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Initial Amount"
                variant="outlined"
                fullWidth
                type="number"
                required
                name="initial_amount"
                value={inputs.initial_amount}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Month"
                variant="outlined"
                fullWidth
                type="number"
                required
                name="month"
                value={inputs.month}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Installment Amount"
                variant="outlined"
                fullWidth
                type="number"
                required
                aria-readonly
                name="installment_amount"
                value={installmentAmount}
              />
            </div>
          </>
        )}
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Vat Number"
            variant="outlined"
            fullWidth
            type="number"
            name="vat_number"
            value={inputs.vat_number}
            onChange={handleChange}
          />
        </div>

        <div className="col-12 mt-4">
          <FormControl fullWidth>
            <TextField
              id="outlined-multiline-flexible"
              label="Transaction Note"
              multiline
              rows={6}
              name="transaction_note"
              value={inputs.transaction_note}
              onChange={handleChange}
            />
          </FormControl>
        </div>

        <div className="text-end mt-4">
          <button className="small-contained-button">Submit</button>
        </div>
      </form>
    </div>
  );
}
