import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import { useEffect } from "react";
import { s3baseUrl } from "src/config/config";
import {
  addQuotesApi,
  editQuotesApi,
  quotes_detail_api,
} from "src/DAL/quotes/Quotes";
import PageLoader from "src/components/GeneralComponents/PageLoader";
import { usePGIMode } from "src/Hooks/PGIModeContext";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";

const Input = styled("input")({
  display: "none",
});

export default function AddOrUpdateQuotes() {
  const navigate = useNavigate();
  const { quote_id } = useParams();
  const { badgeLevels } = usePGIMode();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setProfileImage] = useState();
  const [formType, setFormType] = useState("ADD");
  const [oldImage, setOldImage] = useState("");
  const [inputs, setInputs] = useState({
    title: "",
    status: true,
    order: 0,
    description: "",
    notify_users: false,
    notification_statement: "",
    notification_description: "",
    image: {},
    document_file: {},
    badge_levels: [],
    membership_status: "all",
  });

  const handleFormatData = (data) => {
    setFormType("EDIT");
    setInputs(data);
    setOldImage(data.quotation_images.thumbnail_2);
    setIsLoading(false);
    window.history.replaceState({}, document.title);
  };

  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };

  const getQuoteData = async () => {
    setIsLoading(true);
    let result = await quotes_detail_api(quote_id);
    if (result.code == 200) {
      handleFormatData(result.quotation);
    } else {
      navigate(`/quotes-list`);
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let notify_level = {
      level: inputs?.badge_levels,
      membership_status: inputs.membership_status,
    };
    const formData = new FormData();
    formData.append("description", inputs.description);
    formData.append("status", inputs.status);

    if (formType === "EDIT") {
      if (file) {
        formData.append("main_image", inputs.image);
      }
    } else {
      formData.append("main_image", inputs.image);
      formData.append("notify_users", inputs.notify_users);
      if (inputs.notify_users) {
        formData.append(
          "notification_statement",
          inputs.notification_statement
        );
        formData.append(
          "notification_description",
          inputs.notification_description
        );
        formData.append("notify_level", JSON.stringify(notify_level));
      }
    }

    const result =
      formType === "ADD"
        ? await addQuotesApi(formData)
        : await editQuotesApi(formData, quote_id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(`/quotes-list`);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleChangeMembers = (name, member) => {
    setInputs((values) => ({ ...values, [name]: member }));
  };

  useEffect(() => {
    if (quote_id) {
      if (state) {
        handleFormatData(state);
      } else {
        getQuoteData();
      }
    }
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(`/quotes-list`)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>

          <h2 className="ms-1">{`${
            formType === "ADD" ? "Add" : "Edit"
          } Quotes`}</h2>
        </div>
      </div>

      <form className="row" onSubmit={handleSubmit}>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Upload Image *</p>
              <FormHelperText className="pt-0">
                Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-2">
              {file ? (
                <img src={file} height="50" />
              ) : (
                oldImage && <img src={s3baseUrl + oldImage} height="50" />
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  name="image"
                  onChange={(e) => {
                    fileChangedHandler(e, "image");
                  }}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          {inputs.image?.name == "" ? (
            ""
          ) : (
            <p className="text-secondary">{inputs.image?.name}</p>
          )}
        </div>
        <div className="col-6 mt-2">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Status </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="status"
              required
              value={inputs.status}
              label="Status *"
              onChange={handleChange}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>{" "}
        {formType === "ADD" && (
          <div className="col-6 mt-2">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Do You Want To Send A Notification To Users
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="notify_users"
                required
                value={inputs.notify_users}
                label="Do You Want To Send A Notification To Users"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
        )}
        <div className="col-12 mt-3">
          <FormControl fullWidth>
            <TextField
              id="outlined-multiline-flexible"
              label="Short Description *"
              multiline
              rows={6}
              name="description"
              value={inputs.description}
              onChange={handleChange}
            />
          </FormControl>
        </div>
        {formType === "ADD" && (
          <>
            {inputs.notify_users && (
              <>
                <>
                  <div className="px-2 mt-4">
                    <div className="setting-card px-2 px-md-3">
                      <div className="col-6 mb-2">
                        <h4 className="notification_contnet">
                          Notification Users
                        </h4>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 mt-3">
                          <MUIAutocomplete
                            inputLabel="Select Badge Levels"
                            limitTags={3}
                            selectedOption={inputs.badge_levels ?? []}
                            setSelectedOption={(member) =>
                              handleChangeMembers("badge_levels", member)
                            }
                            optionsList={badgeLevels}
                            multiple
                            required={true}
                            name="title"
                          />
                        </div>

                        <div className="col-6 mt-3">
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Membership Status
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name="membership_status"
                              value={inputs?.membership_status}
                              label="Membership Status"
                              onChange={handleChange}
                            >
                              <MenuItem value={"all"}>All</MenuItem>
                              <MenuItem value={"active"}>Active</MenuItem>
                              <MenuItem value={"expire"}>Expire</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </>
            )}
            {inputs.notify_users && (
              <>
                <>
                  <div className="px-2 mt-2">
                    <div className="setting-card px-2 px-md-3">
                      <div className="col-6 mb-2">
                        <h4 className="notification_contnet">
                          Notification Contnet
                        </h4>
                      </div>

                      <div className="col-lg-12 mt-3">
                        <TextField
                          id="outlined-basic"
                          label="Notification Statement"
                          variant="outlined"
                          fullWidth
                          required
                          name="notification_statement"
                          type="text"
                          value={inputs.notification_statement}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <FormControl fullWidth>
                          <TextField
                            id="outlined-multiline-flexible"
                            label="Notification Description "
                            required
                            multiline
                            rows={2}
                            name="notification_description"
                            value={inputs.notification_description}
                            onChange={handleChange}
                          />
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </>
              </>
            )}
          </>
        )}
        <div className="text-end mt-4" style={{ position: "relative" }}>
          <button className="small-contained-button fixed-button">
            {formType == "ADD" ? "Submit" : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
