import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import {
  communityListing,
  deleteCommunityRewardApi,
} from "src/DAL/Community/community";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import { pods_listing_by_member } from "src/DAL/member/member";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "title", label: " Title", alignRight: false },
  { id: "table_avatar", label: "Image", alignRight: false, type: "thumbnail" },
  {
    id: "room_type",
    label: "Type",
    alignRight: false,
    className: "text-capitalize",
  },
  {
    id: "roomUsers",
    label: "Room Users",
    alignRight: false,
  },

  { id: "status", label: "Status", alignRight: false, type: "row_status" },
];

export default function MemberPods() {
  const navigate = useNavigate();
  const [communityList, setCommunityList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [useInfo, setuseInfo] = useState();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const params = useParams();
  const podsListing = async () => {
    const result = await pods_listing_by_member(params.id);
    if (result.code === 200) {
      setIsLoading(false);
      const data = result.rooms.map((rooms) => {
        return {
          ...rooms,
          roomUsers: rooms.room_user.length,
          table_avatar: {
            src: s3baseUrl + rooms.room_image?.thumbnail_1,
            alt: rooms.title,
          },
        };
      });
      setCommunityList(data);
      setuseInfo(result.member);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    podsListing();
  }, []);

  if (isLoading == true) {
    return <PageLoader />;
  }
  return (
    <>
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-12 mb-3 reply-anchor">
            <IconButton
              className="back-screen-button"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
            {useInfo && (
              <span className="sale-page-title">
                {useInfo?.first_name +
                  " " +
                  useInfo?.last_name +
                  " (" +
                  useInfo?.email +
                  ")"}
              </span>
            )}
          </div>
          <div className="col-lg-8 col-sm-12">
            <h2>Pods</h2>
          </div>
        </div>

        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={communityList}
          // MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          pagePagination={true}
        />
      </div>
    </>
  );
}
