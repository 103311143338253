import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
} from "@mui/material";

import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useEffect } from "react";
import {
  country_list,
  update_currency,
} from "src/DAL/WebsitePages/paymentPlan";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ManageCountry() {
  const params = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const [rowPerPage, setrowPerPage] = useState("");
  const [allCheck, setallCheck] = useState(false);

  const getCountriesList = async () => {
    const result = await country_list();
    if (result.code == 200) {
      setrowPerPage(result.currencies.length);
      setCountriesList(result.currencies);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    setIsLoadingForm(true);

    let programObject = {
      currencies: countriesList,
    };

    const result = await update_currency(programObject);
    if (result.code == 200) {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleChange = (event, name, id) => {
    const value = event.target.value;
    setCountriesList((data) =>
      data.map((obj) => {
        if (obj._id === id) {
          return { ...obj, [name]: value };
        }
        return obj;
      })
    );
  };

  function handleCheckboxChange(id, name, data) {
    const arrayCopy = [...countriesList];
    const selectedArray = arrayCopy[id];
    selectedArray[name] = !selectedArray[name];
    setCountriesList(arrayCopy);
  }

  function handleCheckboxall(e) {
    setallCheck(e.target.checked);
    let arrayCopy = countriesList.map((item) => ({
      ...item,
      fire_payment_enabled: e.target.checked,
    }));
    setCountriesList(arrayCopy);
  }

  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "checkboxes",
      label: (
        <>
          <Checkbox
            onChange={(e) => handleCheckboxall(e)}
            style={{ marginRight: "10px" }}
            checked={allCheck}
          />
          Fire Enable
        </>
      ),
      renderData: (row, index) => {
        return (
          <>
            <span className="position-relative">
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() =>
                      handleCheckboxChange(index, "fire_payment_enabled", row)
                    }
                    checked={row.fire_payment_enabled}
                    style={{ marginLeft: "10px" }}
                  />
                }
                label=""
                className="me-5"
              />
            </span>
          </>
        );
      },
    },
    { id: "label", label: "Country", alignRight: false },
    { id: "value", label: "Abbreviation ", alignRight: false },
    {
      label: "Conversion Rate",
      alignRight: false,
      renderData: (row) => {
        return (
          <TextField
            id="outlined-basic"
            variant="outlined"
            size="small"
            type="number"
            required
            name={row.cost_in_one_pound}
            className="default_consultant_commission_input w-180px"
            value={row.cost_in_one_pound}
            InputProps={{
              endAdornment: <span>£</span>,
            }}
            sx={{
              "& fieldset": { border: "none" },
            }}
            onChange={(e) => {
              handleChange(e, "cost_in_one_pound", row._id);
            }}
          />
        );
      },
    },
    {
      id: "no_of_start_days",
      label: "Currency",
      renderData: (row) => {
        return (
          <div className="col-12">
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label"></InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="which_currency_using"
                value={row.which_currency_using}
                label=""
                onChange={(e) => {
                  handleChange(e, "which_currency_using", row._id);
                }}
              >
                <MenuItem value="GBP">UK Pounds</MenuItem>
                <MenuItem value="EUR">Euro</MenuItem>
              </Select>
            </FormControl>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getCountriesList();
  }, []);

  useEffect(() => {
    const result = countriesList.every(
      (element) => element.fire_payment_enabled === true
    );
    setallCheck(result);
  }, [countriesList]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mb-4">
        <div className="col-6">
          <h2>Manage Currencies </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={countriesList}
            pagePagination={false}
            rows_per_page_count={rowPerPage}
            hide_footer_pagination={true}
          />
        </div>
      </div>
      <div className="ms-auto text-end" id="fixedbutton">
        <button
          className="small-contained-button mt-3 ml-auto"
          onClick={handleSubmit}
        >
          {isLoadingForm ? "Updating..." : "Update"}
        </button>
      </div>
    </div>
  );
}
