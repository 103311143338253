import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { memberGroupListApi } from "src/DAL/member/member";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { show_proper_words } from "src/utils/constant";
import MemberProgramPopover from "../memberProgramPopOver";
import PopOverViewMore from "src/components/PopOverViewMore";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function MembersGroupList({ tabData }) {
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [program, setprogram] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [event, setEvent] = useState([]);
  const [anchorElEvnet, setAnchorElEvnet] = useState(null);
  const opens = Boolean(anchorEl);
  const opensEvent = Boolean(anchorElEvnet);
  const id = opens ? "simple-popover" : undefined;
  const idevent = opensEvent ? "simple-popover" : undefined;

  const getConsultantListing = async () => {
    setIsLoading(true);
    const data = tabData?.map((group) => {
      return {
        ...group,
      };
    });
    setUserList(data);
    setIsLoading(false);
  };

  const handleClickPopUP = (event, row) => {
    setprogram(row.program);
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopUp = () => {
    setAnchorEl(null);
  };

  const handleClickPopUPEvent = (event, row) => {
    setEvent(row.event);
    setAnchorElEvnet(event.currentTarget);
  };

  const handleClosePopUpEvent = () => {
    setAnchorElEvnet(null);
  };

  useEffect(() => {
    getConsultantListing();
  }, [tabData]);

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "title", label: " Name" },
    {
      id: "program",
      label: "Programme / Event",
      renderData: (row) => {
        const firstFiveprograms = row.program?.slice(0, 5);
        const firstFiveEvent = row.event?.slice(0, 5);
        return (
          <div>
            {firstFiveprograms && firstFiveprograms.length > 0
              ? firstFiveprograms?.map((program, i) => {
                  if (program._id !== null) {
                    return (
                      <>
                        <div key={i}>
                          <p>{program?._id?.title + ","}</p>
                        </div>
                        {i == 4 && (
                          <p
                            className="mb-1 mt-1 view-more"
                            aria-describedby={id}
                            variant="contained"
                            onClick={(e) => handleClickPopUP(e, row)}
                          >
                            view more
                          </p>
                        )}
                      </>
                    );
                  }
                })
              : firstFiveEvent &&
                firstFiveEvent.length > 0 &&
                firstFiveEvent.map((event, i) => {
                  if (event._id !== null) {
                    return (
                      <>
                        <div key={i}>
                          <p>{event._id?.title + ","}</p>
                        </div>
                        {i == 4 && (
                          <p
                            className="mb-1 mt-1 view-more"
                            aria-describedby={id}
                            variant="contained"
                            onClick={(e) => handleClickPopUPEvent(e, row)}
                          >
                            view more
                          </p>
                        )}
                      </>
                    );
                  }
                })}
          </div>
        );
      },
    },
    {
      id: "group_type",
      label: "Type",
      renderData: (row) => {
        return show_proper_words(row.group_type);
      },
    },
    {
      id: "group_by",
      label: "Group By",
      renderData: (row) => {
        return show_proper_words(row.group_by);
      },
    },
    {
      id: "action_by",
      label: "Created By",
    },
    { id: "status", label: "Status", type: "row_status" },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="mt-3">
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={userList}
          className="card-with-background mt-3 "
        />
      </div>
      <MemberProgramPopover
        handleClick={handleClickPopUP}
        handleClose={handleClosePopUp}
        anchorEl={anchorEl}
        id={id}
        open={opens}
        program={program}
        navigatePage={true}
      />
      <PopOverViewMore
        handleClick={handleClickPopUPEvent}
        handleClose={handleClosePopUpEvent}
        anchorEl={anchorElEvnet}
        id={idevent}
        open={opensEvent}
        program={event}
        title="Event"
      />
    </>
  );
}
