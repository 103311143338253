import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import { useEffect } from "react";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { memberHistoryApi } from "src/DAL/member/member";
import { s3baseUrl } from "src/config/config";
import { coinImage, gratitudeIcon, likeIcon } from "src/assets";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PageLoader from "src/components/GeneralComponents/PageLoader";

export default function ChallengeTabs() {
  const navigate = useNavigate();
  const { member_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [historyList, setHistoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  //Changing tab values
  const handleChange = (event, newValue) => {
    setPage(0);
    setPageCount(1);
    setTabValue(newValue);
  };

  const getHistoryList = async () => {
    setIsLoading(true);
    let action_type = "all";
    if (tabValue == 1) {
      action_type = "like";
    } else if (tabValue == 2) {
      action_type = "gratitude";
    }

    let formData = new FormData();
    formData.append("member", member_id);
    formData.append("action_type", action_type);

    const result = await memberHistoryApi(formData, page, rowsPerPage);
    if (result.code === 200) {
      let histories = result.feeds.map((feed) => {
        return {
          ...feed,
          user_name: feed.user_info_action_by.name,
          table_avatar: {
            src: feed?.user_info_action_by?.profile_image
              ? s3baseUrl + feed.user_info_action_by.profile_image
              : "",
            alt: feed.user_info_action_by.name,
          },
        };
      });
      setHistoryList(histories);
      setTotalCount(result.total_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "table_avatar",
      label: "Image",
      alignRight: false,
      type: "thumbnail",
    },
    { id: "user_name", label: "Name", alignRight: false },
    { id: "activity_date_time", label: "Date Time", alignRight: false },
  ];

  if (tabValue == 0) {
    TABLE_HEAD.push({
      label: "Activity Type",
      alignRight: false,
      renderData: (row) => {
        let activity_icon = likeIcon;
        if (row.activity_type == "gratitude") {
          activity_icon = gratitudeIcon;
        }
        return (
          <div className="d-flex">
            <img src={activity_icon} alt="" className="history-image-icon" />
            <p>{row.activity_type}</p>
          </div>
        );
      },
      className: "p-0",
    });
  }

  if (tabValue == 2) {
    TABLE_HEAD.push({
      label: "Activity Type",
      alignRight: false,
      renderData: (row) => {
        return (
          <div className="d-flex">
            <img src={coinImage} alt="" className="history-coin-icon" />
            <p className="mb-0 mt-2">{row.coins_count_by_action}</p>
          </div>
        );
      },
      className: "p-0",
    });
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPageCount(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  useEffect(() => {
    getHistoryList();
  }, [rowsPerPage, page, tabValue]);

  if (isLoading == true) {
    return <PageLoader />;
  }

  let table_props = {
    TABLE_HEAD: TABLE_HEAD,
    data: historyList,
    className: "card-with-background mt-3",
    custom_pagination: {
      total_count: totalCount,
      rows_per_page: rowsPerPage,
      page: page,
      handleChangePage: handleChangePage,
      onRowsPerPageChange: handleChangeRowsPerPage,
    },
    pageCount: pageCount,
    totalPages: totalPages,
    handleChangePages: handleChangePages,
    pagePagination: true,
  };

  const TABS_OPTIONS = [
    {
      title: "All",
      component: <CustomMUITable {...table_props} />,
    },
    {
      title: "Likes",
      component: <CustomMUITable {...table_props} />,
    },
    {
      title: "Gratitude",
      component: <CustomMUITable {...table_props} />,
    },
  ];

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-lg-8 col-sm-12">
          <h2>{"Member History"}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <MUICustomTabs
            data={TABS_OPTIONS}
            value={tabValue}
            handleChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}
