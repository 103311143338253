import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";

import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {
  GetTrackerApi,
  trackerSettingApi,
} from "src/DAL/SiteSetting/siteSetting";
import { useEffect } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import GeneralIterativeCkeditor from "src/components/GeneralComponents/GeneralIterativeCkeditor";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { uploadFileOns3 } from "src/DAL/commonApi/commonApi";
import { s3baseUrl } from "src/config/config";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import faker from "faker";
import {
  getAllDelegatesListingApi,
  memberAndGroupsEventInCalendarGroupsListApi,
} from "src/DAL/member/member";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { show_proper_words } from "src/utils/constant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function TrackerSettings() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [serchText, setSerchText] = useState("");
  const [membersList, setMembersList] = useState([]);
  const [inputs, setInputs] = useState({
    main_heading: "",
    morning_heading: "",
    streak_performance_days_reminder: "",
    evening_heading: "",
    affirm_title: "",
    affirm_placeHolder: "",
    intentions_heading: "",
    completed_heading: "",
    performance_streak_heading: "",
    rate_your_performance_streak_heading: "",
    win_from_today_heading: "",
    win_from_today_placeholder: "",
    your_attitude_text: "",
    your_focus_text: "",
    your_desire_text: "",
    your_discipline_text: "",
    rate_this_win_text: "",
    past_activities_button_text_performance: "",
    past_activities_performance_heading: "",
    streack_count_text: "",
    total_score_text: "",
  });
  const [inputList, setInputList] = useState([
    {
      statement: "",
      content_type: "audio",
      content: "",
      is_required: false,
      image: "",
      status: false,
      listen_count: 0,
      is_text_box_shown: false,
      text_box_content: "",
      show_question_to: [],
      id: faker.datatype.uuid(),
      selected_delegates: [],
    },
  ]);
  const get_full_name = (user) => {
    return (
      user.first_name +
      " " +
      user.last_name +
      " (" +
      user.email +
      ")" +
      " " +
      "|" +
      " " +
      show_proper_words(user.team_type)
    );
  };

  const handleAddClick = (index) => {
    setInputList([
      ...inputList.slice(0, index),
      {
        statement: "",
        content_type: "audio",
        content: "",
        is_required: false,
        image: "",
        status: false,
        listen_count: 0,
        is_text_box_shown: false,
        text_box_content: "",
        show_question_to: [],
        id: faker.datatype.uuid(),
        selected_delegates: [],
      },
      ...inputList.slice(index),
    ]);
  };
  const fileChangedHandlerFB = async (e, index) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    // formData.append("width", "300");
    const imageUpload = await uploadFileOns3(formData);
    if (imageUpload.code == 200) {
      const copyArray = [...inputList];
      const selectedObject = copyArray[index];
      selectedObject["content"] = imageUpload.image_path;
      setInputList(copyArray);
    }
  };
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    if (name == "content_type") {
      const list = [...inputList];
      list[index]["content"] = "";
      setInputList(list);
    }
  };
  const handleChangeMembers = (name, member, index) => {
    console.log(member, name, index, "member");
    const list = [...inputList];
    list[index][name] = member;
    setInputList(list);
  };
  const GetTrackerApiList = async () => {
    setIsLoading(true);
    const result = await GetTrackerApi();
    if (result.code == 200) {
      let newobj = {
        ...result?.accountability_tracker_setting,
        ...result?.streak_performance_setting,
      };
      if (
        result?.accountability_tracker_setting.tracker_intentions &&
        result?.accountability_tracker_setting.tracker_intentions.length > 0
      ) {
        setInputList(result?.accountability_tracker_setting.tracker_intentions);
      }

      setInputs(newobj);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  function getFirstErrorString(data) {
    for (const item of data) {
      if (!item.content) {
        return item;
      }
    }
    return null; // Return null if no error is found
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    const errorMessage = getFirstErrorString(inputList);
    if (errorMessage) {
      let message = "Content is required";
      if (errorMessage.content_type == "audio") {
        message = "Audio is required";
      }
      enqueueSnackbar(message, { variant: "error" });
      return;
    }

    setIsLoadingForm(true);
    let data = {
      main_heading: inputs?.main_heading,
      morning_heading: inputs?.morning_heading,
      evening_heading: inputs?.evening_heading,
      affirm_title: inputs?.affirm_title,
      affirm_placeHolder: inputs?.affirm_placeHolder,
      intentions_heading: inputs?.intentions_heading,
      completed_heading: inputs?.completed_heading,

      tracker_intentions: inputList,
    };
    let data2 = {
      performance_streak_heading: inputs?.performance_streak_heading,
      rate_your_performance_streak_heading:
        inputs?.rate_your_performance_streak_heading,
      win_from_today_heading: inputs?.win_from_today_heading,
      win_from_today_placeholder: inputs?.win_from_today_placeholder,
      your_attitude_text: inputs?.your_attitude_text,
      your_focus_text: inputs?.your_focus_text,
      your_desire_text: inputs?.your_desire_text,
      your_discipline_text: inputs?.your_discipline_text,
      rate_this_win_text: inputs?.rate_this_win_text,
      past_activities_button_text_performance:
        inputs?.past_activities_button_text_performance,
      past_activities_performance_heading:
        inputs?.past_activities_performance_heading,
      streack_count_text: inputs?.streack_count_text,
      total_score_text: inputs?.total_score_text,
      streak_performance_days_reminder:
        inputs?.streak_performance_days_reminder,
    };
    let postData = {
      accountability_tracker_setting: data,
      streak_performance_setting: data2,
    };
    const result = await trackerSettingApi(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const getSearchExcludeMembers = async () => {
    const result = await getAllDelegatesListingApi(serchText);
    if (result.code === 200) {
      let members = [];
      if (result.data.length > 0) {
        members = result.data.map((user) => {
          user.full_name = get_full_name(user);
          return user;
        });
      }
      setMembersList(members);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (!isLoading) {
      const timeoutId = setTimeout(() => {
        getSearchExcludeMembers();
      }, 300);
      return () => clearTimeout(timeoutId);
    }
  }, [serchText]);

  useEffect(() => {
    GetTrackerApiList();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <form onSubmit={handleSubmit} className="container-fluid">
        <div className="row">
          {" "}
          <div className="row mobile-margin display-flex mt-4">
            <div className="col-12">
              <h2 style={{ color: "#e4c073" }}>Streak Performance</h2>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Performance Streak Heading"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              type="text"
              name="performance_streak_heading"
              value={inputs?.performance_streak_heading}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Rate Your Performance Heading"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              type="text"
              name="rate_your_performance_streak_heading"
              value={inputs?.rate_your_performance_streak_heading}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="win From Today Heading"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              type="text"
              name="win_from_today_heading"
              value={inputs?.win_from_today_heading}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="win From Today Placeholder"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              type="text"
              name="win_from_today_placeholder"
              value={inputs?.win_from_today_placeholder}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Your Attitude Text"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              type="text"
              name="your_attitude_text"
              value={inputs?.your_attitude_text}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Your Focus Text"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              type="text"
              name="your_focus_text"
              value={inputs?.your_focus_text}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Your Desire Text"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              type="text"
              name="your_desire_text"
              value={inputs?.your_desire_text}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Your Discipline Text"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              type="text"
              name="your_discipline_text"
              value={inputs?.your_discipline_text}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Rate This Win Text"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              type="text"
              name="rate_this_win_text"
              value={inputs?.rate_this_win_text}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Past Activities Button Text"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              type="text"
              name="past_activities_button_text_performance"
              value={inputs?.past_activities_button_text_performance}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Past Activities Page Heading"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              type="text"
              name="past_activities_performance_heading"
              value={inputs?.past_activities_performance_heading}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="streak count text"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              type="text"
              name="streack_count_text"
              value={inputs?.streack_count_text}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Total Score Text"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              type="text"
              name="total_score_text"
              value={inputs?.total_score_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 mt-4">
            <h4>Streak Performance Reminder Message*</h4>

            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="streak_performance_days_reminder"
              editorHeight={320}
              required
            />
          </div>
          <div className="col-12 mt-3">
            <h2 style={{ color: "#e4c073" }}>
              Daily Dynamite Accountability Tracker
            </h2>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Main Heading"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              type="text"
              name="main_heading"
              value={inputs?.main_heading}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Morning Reminder Heading"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              type="text"
              name="morning_heading"
              value={inputs?.morning_heading}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Evening Reminder Heading"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              type="text"
              name="evening_heading"
              value={inputs?.evening_heading}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Today Affirm Title"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              type="text"
              name="affirm_title"
              value={inputs?.affirm_title}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Today Affirm Placeholder"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              type="text"
              name="affirm_placeHolder"
              value={inputs?.affirm_placeHolder}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Intentions Heading"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              type="text"
              name="intentions_heading"
              value={inputs?.intentions_heading}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Have You Completed Heading"
              variant="outlined"
              fullWidth
              aria-readonly
              type="text"
              name="completed_heading"
              value={inputs?.completed_heading}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="Container mt-3">
            {inputList.map((item, index) => {
              return (
                <div className="setting-card mb-4 ">
                  <div className="row  ">
                    <div className="col-11">
                      <div className="row">
                        <div className="col-6">
                          <TextField
                            id="outlined-basic"
                            label="Statement"
                            variant="outlined"
                            fullWidth
                            required
                            type="text"
                            name="statement"
                            value={item.statement}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                        </div>{" "}
                        <div className="col-6">
                          <FormControl fullWidth required>
                            <InputLabel id="demo-simple-select-label">
                              Is Required?
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name="is_required"
                              value={item.is_required}
                              label=" Is Required?"
                              onChange={(e) => handleInputChange(e, index)}
                            >
                              <MenuItem value={true}>Yes</MenuItem>
                              <MenuItem value={false}>No</MenuItem>
                            </Select>
                          </FormControl>
                        </div>{" "}
                        <div className="col-6 mt-3">
                          <FormControl fullWidth required>
                            <InputLabel id="demo-simple-select-label">
                              Content Type
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name="content_type"
                              value={item.content_type}
                              label="Content Type"
                              onChange={(e) => handleInputChange(e, index)}
                            >
                              <MenuItem value="audio">Audio</MenuItem>
                              <MenuItem value="other">Other</MenuItem>
                            </Select>
                          </FormControl>
                        </div>{" "}
                        <div className="col-6 mt-3">
                          <FormControl fullWidth required>
                            <InputLabel id="demo-simple-select-label">
                              Is Show Comment Text Box ?
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name="is_text_box_shown"
                              value={item.is_text_box_shown}
                              label="Is Show Comment Text Box ?"
                              onChange={(e) => handleInputChange(e, index)}
                            >
                              <MenuItem value={true}>Yes</MenuItem>
                              <MenuItem value={false}>No</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <div className="col-6 mt-3">
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Show Question To
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name="show_question_to"
                              value={item.show_question_to}
                              onChange={(e) => handleInputChange(e, index)}
                              label="Show Question To"
                              multiple
                            >
                              <MenuItem value="consultant">
                                Consultant{" "}
                              </MenuItem>
                              <MenuItem value="delegate">Delegate</MenuItem>
                              <MenuItem value="marketing">
                                Marketing Team
                              </MenuItem>
                              <MenuItem value="inner_circle">
                                Inner Circle
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                          <MUIAutocomplete
                            inputLabel="Select Delegates"
                            limitTags={3}
                            selectedOption={item.selected_delegates ?? []}
                            setSelectedOption={(member) =>
                              handleChangeMembers(
                                "selected_delegates",
                                member,
                                index
                              )
                            }
                            optionsList={membersList}
                            setCustomSearch={setSerchText}
                            multiple
                            name="full_name"
                          />
                        </div>
                        {item.content_type == "audio" ? (
                          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                            <div className="row w-100 div-style ms-0 pt-0">
                              <div className="col-5">
                                <p className="">Upload Audio *</p>
                                <FormHelperText className="pt-0">
                                  Allowed Formats is "Mp3",200Mb
                                </FormHelperText>
                              </div>
                              <div className="col-2"></div>
                              <div className="col-5 text-end pt-2">
                                <label
                                  id={`contained-button-fileFb${index + 3324}`}
                                >
                                  <Input
                                    accept="audio/mp3,audio/*;capture=microphone"
                                    id={`contained-button-fileFb${
                                      index + 3324
                                    }`}
                                    multiple
                                    name="audio"
                                    type="file"
                                    onChange={(e) =>
                                      fileChangedHandlerFB(e, index)
                                    }
                                  />

                                  <Button
                                    className="small-contained-button"
                                    startIcon={<FileUploadIcon />}
                                    component="span"
                                  >
                                    Upload
                                  </Button>
                                </label>
                              </div>
                            </div>

                            {item.content && (
                              <audio
                                className="w-100 mt-2"
                                src={s3baseUrl + item.content}
                                controls
                              />
                            )}
                          </div>
                        ) : (
                          <div className="col-12 mt-4">
                            <h4>Other Content</h4>
                            <GeneralIterativeCkeditor
                              setInputs={setInputList}
                              inputs={inputList}
                              required
                              name="content"
                              index={index}
                              editorHeight={320}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-1">
                      <AddCircleOutlineIcon
                        className="question-survey-update"
                        onClick={() => handleAddClick(index + 1)}
                      />
                      {inputList.length !== 1 && (
                        <RemoveCircleOutlineIcon
                          className="question-survey-cross-update"
                          onClick={() => handleRemoveClick(index)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="text-end mt-4" id="fixedbutton">
            <button className="small-contained-button">
              {isLoadingForm ? "Updating" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
