import { useEffect, useState } from "react";
// material
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { gratitudes_list_api } from "src/DAL/gratitude/gratitudeApi";
import Label from "src/components/Label";
import RecordNotFound from "src/components/RecordNotFound";
import PageLoader from "src/components/GeneralComponents/PageLoader";

// ----------------------------------------------------------------------

export default function GratitudeList() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [gratitudes, setGratitudes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getGratitudesList = async () => {
    const result = await gratitudes_list_api();
    if (result.code == 200) {
      setIsLoading(false);
      setGratitudes(result.gratitude);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleNavigateDetail = (value) => {
    navigate(`/gratitude/${value._id}`, {
      state: value,
    });
  };

  useEffect(() => {
    getGratitudesList();
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-7 col-sm-12">
          <h2>Gratitude </h2>
        </div>
        <div className="col-lg-5 col-sm-12 text-end">
          <button
            onClick={() => navigate(`/gratitude/add-gratitude`)}
            className="small-contained-button"
          >
            Add Gratitude
          </button>
        </div>
        {gratitudes.length == 0 ? (
          <RecordNotFound title="Gratitude" />
        ) : (
          gratitudes.map((value, index) => {
            return (
              <>
                <div className="col-lg-4 col-md-4 col-sm-12 mb-4" key={index}>
                  <div className="card mt-4 pods-cards-shadow cursor h-100">
                    <img
                      src={
                        s3baseUrl + value.general_gratitude_image.thumbnail_1
                      }
                      className="card-img-top pods-image"
                      alt="gratitudesme"
                      onClick={() => handleNavigateDetail(value)}
                    />
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12">
                          <p>{htmlDecode(value.general_gratitude_text)}</p>
                        </div>
                      </div>

                      <div className="row recording-card-date">
                        <div className="col-8 text-end ms-auto">
                          <Label
                            variant="ghost"
                            color={value.status === false ? "error" : "success"}
                          >
                            {value.status === false ? "InActive" : "Active"}
                          </Label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })
        )}
      </div>
    </div>
  );
}
