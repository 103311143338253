import { invokeApi } from "../../bl_libs/invokeApi";

export const transactionListApi = async (page, rowsPerPage, data) => {
  // 'admin_users/all_transaction_list?page=0&limit=50'
  const requestObj = {
    path: `/admin_users/all_transaction_list/v1?page=${page}&limit=${rowsPerPage}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const transactions_list_api = async (page, rowsPerPage, data) => {
  const requestObj = {
    path: `/admin_users/all_transaction_list/v1/v2?page=${page}&limit=${rowsPerPage}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const transactions_list_new_api = async (page, rowsPerPage, data) => {
  const requestObj = {
    path: `/admin_users/all_transaction_list/v4?page=${page}&limit=${rowsPerPage}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const trash_transactions_list_new_api = async (
  page,
  rowsPerPage,
  search
) => {
  const requestObj = {
    path: `/admin_users/trash_transactions/list?page=${page}&limit=${rowsPerPage}&search_text=${search}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_commission_report_for_transection_api = async (id) => {
  const requestObj = {
    path: `/admin_users/get_commission_report_for_transection/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const get_commission_report_trash_for_transection_api = async (id) => {
  const requestObj = {
    path: `/admin_users/trash_transaction_commission_report/get/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const update_transaction_status_api = async (slug, data) => {
  const requestObj = {
    path: `/admin_users/update_transaction_status/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const Refund_transaction_api = async (data) => {
  const requestObj = {
    path: `/app/refund_payment_by_admin`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const Csv_request_api = async (data) => {
  const requestObj = {
    path: `/csv_file_requests/request_new_csv_file`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const transactionListFilterPageRequestApi = async (page_type) => {
  const requestObj = {
    path: `/admin_users/payment_request_and_pages_list?page_type=${page_type}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const orderDetailList = async (id) => {
  const requestObj = {
    path: `/app/dynamite_order_detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const Ga4_stream_list = async () => {
  const requestObj = {
    path: `/app/ga4_streams_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const Ga4_stream_analytics_list = async (data, page, limit) => {
  const requestObj = {
    path: `/app/ga4_streams_report?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const Ga4_stream_analytics_list_detail = async (data, page, limit) => {
  const requestObj = {
    path: `/app/get_page_details_by_title`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const DeleteTransactionApi = async (id) => {
  const requestObj = {
    path: `/admin_users/delete_transaction/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const DeleteTrashTransactionApi = async (id) => {
  const requestObj = {
    path: `/admin_users/trash_transaction/delete/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const getAddTransactionDataListApi = async (data) => {
  const requestObj = {
    path: "/api/member/members_pages_request/list",
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const getTransactionDetailApi = async (id) => {
  const requestObj = {
    path: `/admin_users/transaction/detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const getTransactionDetailApiNew = async (id) => {
  const requestObj = {
    path: `/admin_users/transaction_details_and_users/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const addTransactionApi = async (data) => {
  const requestObj = {
    path: "/admin_users/transaction/add",
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const billingTicketsApi = async (page, limit, data, search) => {
  const requestObj = {
    path: `/admin_users/all_billing/tickets?page=${page}&limit=${limit}&search_text=${search}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const checkiInTicketsApi = async (page, limit, data, search) => {
  const requestObj = {
    path: `/app/list_checkin/stats?page=${page}&limit=${limit}&search_text=${search}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const giftDelieverdApi = async (data) => {
  const requestObj = {
    path: `/app/gift_pack/deliverd`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateTransactionApi = async (id, data) => {
  const requestObj = {
    path: `/admin_users/transaction/update/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateTransactionCommissionApi = async (id, data) => {
  const requestObj = {
    path: `/admin_users/transaction/update_commission/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const transaction_details_and_users_api = async (id) => {
  const requestObj = {
    path: `/admin_users/transaction_details_and_users/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_billing_ticket_api = async (data) => {
  const requestObj = {
    path: `/app/add_billing/ticket`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const sent_billing_email = async (data) => {
  const requestObj = {
    path: `/app/send_ticket/email`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const check_in_by_admin_api = async (ticket_id) => {
  const requestObj = {
    path: `/app/scan_billing/ticket_by_admin/${ticket_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const delete_checked_in = async (ticket_id) => {
  const requestObj = {
    path: `/app/delete_billing/ticket/${ticket_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const delete_checked_in_api = async (ticket_id) => {
  const requestObj = {
    path: `/app/delete_checkin/stats/${ticket_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const check_in_member_api = async (search, page, plan) => {
  const requestObj = {
    path: `/app/list_member/tickets?search_text=${
      search ? search : ""
    }&sale_page=${page ? page : ""}&plan=${plan ? plan : ""}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_transaction_internal_note = async (data) => {
  const requestObj = {
    path: `/api/transaction_internal_note`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const edit_transaction_internal_note = async (id, data) => {
  const requestObj = {
    path: `/api/transaction_internal_note/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_transaction_internal_note = async (t_id, id) => {
  const requestObj = {
    path: `/api/transaction_internal_note/delete/${t_id}/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const transaction_info_detail = async (id) => {
  const requestObj = {
    path: `/admin_users/get_complete_info_for_transction/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const trash_transaction_info_detail = async (id) => {
  const requestObj = {
    path: `/admin_users/trash_transaction/get/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
