import React, { useEffect, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { useNavigate, useParams } from "react-router-dom";
import FilteredChip from "src/components/FilteredChip";
import { useSnackbar } from "notistack";
import moment from "moment";
import { dd_date_format } from "src/utils/constant";
import PageLoader from "src/components/GeneralComponents/PageLoader";
import AnalyticsFilter from "src/components/FirebaseAnalytics/AnalyticsFilter";
import AnalyticsChart from "src/components/FirebaseAnalytics/AnalyticsChart";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import { firebase_event_details } from "src/DAL/FirebaseAnalytics/FirebaseAnalytics";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import { Icon } from "@iconify/react";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { DEFAULT_DATES, filterFirebaseData } from "src/utils/firebase_utils";
const EMPTY_FILTER = {
  ...DEFAULT_DATES,
};

const FirebaseEventDetail = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [selectedEvent, setSelectedEvent] = useState({
    eventName: params.event_name,
  });
  const [eventList, setEventsList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [reportList, setReportList] = useState([]);
  const [reportOverview, setReportOverview] = useState({});
  const [openFilter, setOpenfilter] = useState(false);
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [updateFilterData, setUpdateFilterData] = useState(EMPTY_FILTER);
  const [tabValue, setTabValue] = useState(0);

  //Changing tab values
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSelectEvent = (value) => {
    setSelectedEvent(value);
  };

  const getCategoriesList = async (filterData, all_events) => {
    setIsLoading(true);
    let postData = { ...filterData };
    if (postData.start_date) {
      postData.start_date = moment(postData.start_date).format("YYYY-MM-DD");
    }
    if (postData.end_date) {
      postData.end_date = moment(postData.end_date).format("YYYY-MM-DD");
    }
    if (all_events) {
      postData.all_events = true;
    }
    postData.dimension_value = selectedEvent.eventName;

    const result = await firebase_event_details(postData);
    if (result.code == 200) {
      let chipData = { ...filterData };
      if (chipData.start_date) {
        let date = `Start Date: ${dd_date_format(
          chipData.start_date
        )} End Date:  ${dd_date_format(chipData.end_date)}`;
        chipData.date = {
          chip_label: date,
          chip_value: date,
        };
        delete chipData.start_date;
        delete chipData.end_date;
      }

      setReportList(result.graphical_report);
      setReportOverview(result.report);
      if (all_events) {
        setEventsList(filterFirebaseData(result.all_events));
      }
      setUpdateFilterData(chipData);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleClearFilter = () => {
    getCategoriesList(EMPTY_FILTER);
    setUpdateFilterData(EMPTY_FILTER);
    setFilterData(EMPTY_FILTER);
    localStorage.removeItem("analytics_filter_detail");
    setOpenfilter(false);
  };

  const searchFunction = () => {
    getCategoriesList(filterData);
    setOpenfilter(false);
    localStorage.setItem("analytics_filter_detail", JSON.stringify(filterData));
  };

  const handleDeleteChip = (data, item) => {
    const { key } = item;
    if (key === "date" || key === "range_type") {
      data = { ...data, ...DEFAULT_DATES };
    }
    getCategoriesList(data);
    localStorage.setItem("analytics_filter_detail", JSON.stringify(data));
    setFilterData(data);
  };

  const handleOpenDawer = () => {
    setOpenfilter(true);
  };

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("analytics_filter_detail");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
    }
    setFilterData(filter_data);
    setUpdateFilterData(filter_data);
    getCategoriesList(filter_data, true);
  }, [selectedEvent]);

  const TABS_OPTIONS = [
    {
      // title: `Total Users (${reportOverview.totalUsers || 0})`,
      title: (
        <>
          <div className="d-flex justify-content-between">
            <span>Total Users ({reportOverview.totalUsers || 0})</span>

            <Tooltip
              title={
                <>
                  Shows the total number of users who have interacted with your
                  app.
                  <br />
                  This helps to measure the overall reach and user base of your
                  app.
                </>
              }
            >
              <Icon
                className="information-icon"
                icon={"eva:alert-circle-outline"}
              />
            </Tooltip>
          </div>
        </>
      ),

      component: (
        <AnalyticsChart
          data={reportList}
          metric={`Total Users (${reportOverview.totalUsers || 0})`}
          name="totalUsers"
        />
      ),
    },
    {
      // title: `Active Users (${reportOverview.activeUsers || 0})`,
      title: (
        <>
          <div className="d-flex justify-content-between">
            <span>Active Users ({reportOverview.activeUsers || 0})</span>

            <Tooltip
              title={
                <>
                  Shows the number of users who have interacted with your app
                  within a specific time frame (e.g., daily, weekly).
                  <br />
                  This helps to track user engagement and activity in the app.
                </>
              }
            >
              <Icon
                className="information-icon"
                icon={"eva:alert-circle-outline"}
              />
            </Tooltip>
          </div>
        </>
      ),
      component: (
        <AnalyticsChart
          data={reportList}
          metric={`Active Users (${reportOverview.activeUsers || 0})`}
          name="activeUsers"
        />
      ),
    },
    {
      // title: `New Users (${reportOverview.newUsers || 0})`,
      title: (
        <>
          <div className="d-flex justify-content-between">
            <span>New Users ({reportOverview.newUsers || 0})</span>

            <Tooltip
              title={
                <>
                  Shows the number of new users who have installed and used your
                  app for the first time.
                  <br />
                  This helps to measure user acquisition and growth.
                </>
              }
            >
              <Icon
                className="information-icon"
                icon={"eva:alert-circle-outline"}
              />
            </Tooltip>
          </div>
        </>
      ),
      component: (
        <AnalyticsChart
          data={reportList}
          metric={`Active Users (${reportOverview.newUsers || 0})`}
          name="newUsers"
        />
      ),
    },
    {
      // title: `Engaged sessions per active user (${Number(
      //   reportOverview.sessionsPerUser || 0
      // ).toFixed(2)})`,

      title: (
        <>
          <div className="d-flex justify-content-between text-nowrap">
            <span>
              Engaged sessions per active user (
              {Number(reportOverview.sessionsPerUser || 0).toFixed(2)})
            </span>

            <Tooltip
              title={
                <>
                  Shows the average number of engaged sessions each active user
                  has.
                  <br />
                  This helps to measure the level of interaction and user
                  engagement with your app.
                </>
              }
            >
              <Icon
                className="information-icon"
                icon={"eva:alert-circle-outline"}
              />
            </Tooltip>
          </div>
        </>
      ),
      component: (
        <AnalyticsChart
          data={reportList}
          metric={`Engaged sessions per active user (${Number(
            reportOverview.sessionsPerUser || 0
          ).toFixed(2)})`}
          name="sessionsPerUser"
        />
      ),
    },
  ];

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-12 d-flex">
            <span>
              <IconButton
                className="back-screen-button mb-4"
                onClick={() => navigate(`/firebase-events`)}
              >
                <ArrowBackIcon />
              </IconButton>
            </span>
            <h2 className="ms-2">Event Detail</h2>
          </div>
          <div className="col-md-4">
            <MUIAutocomplete
              inputLabel="Event List"
              selectedOption={selectedEvent}
              setSelectedOption={handleSelectEvent}
              optionsList={eventList}
              autoComplete="new-password"
              name="eventName"
              required
            />
          </div>
          <div className="col-lg-8 text-end">
            <button
              onClick={handleOpenDawer}
              className="small-contained-button ms-2"
            >
              Filter &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>
          </div>
        </div>
        {!isLoading && (
          <FilteredChip
            data={updateFilterData}
            tempState={filterData}
            EMPTY_FILTER={EMPTY_FILTER}
            onDeleteChip={handleDeleteChip}
            onClear={handleClearFilter}
          />
        )}
        {isLoading ? (
          <PageLoader />
        ) : (
          <>
            <div className="col-12 mt-3">
              <MUICustomTabs
                data={TABS_OPTIONS}
                value={tabValue}
                handleChange={handleChange}
              />
            </div>
          </>
        )}
      </div>
      <CustomDrawer
        isOpenDrawer={openFilter}
        onOpenDrawer={() => setOpenfilter(true)}
        onCloseDrawer={() => setOpenfilter(false)}
        pageTitle="Filter"
        componentToPassDown={
          <AnalyticsFilter
            filterData={filterData}
            setFilterData={setFilterData}
            searchFunction={searchFunction}
            handleClearFilter={handleClearFilter}
          />
        }
      />
    </>
  );
};

export default FirebaseEventDetail;
