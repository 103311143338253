import React from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { client_Url } from "src/config/config";
import {
  get_page_list_for_consultant_api,
  manage_template_access_for_consultant_api,
  pagesListingApi,
} from "src/DAL/WebsitePages/WebsitePages";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import PageLoader from "src/components/GeneralComponents/PageLoader";

export default function ManageAccessConsultantList() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const { state } = useLocation();
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [templateData, setTemplateData] = useState([]);

  console.log(state, "statestatestate");

  const get_consultant_detail = async () => {
    const result = await get_page_list_for_consultant_api(params.id);
    if (result.code == 200) {
      if (result.Sale_page) {
        setSelected(result.Sale_page);
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const TABLE_HEAD = [
    { id: "sale_page_title", label: "Page Title", alignRight: false },
    {
      id: "preview_link_data",
      label: "URL",
      type: "link",
      alignRight: false,
    },
  ];

  const getTemplateConfig = async () => {
    const result = await pagesListingApi("template");
    if (result.code === 200) {
      let result_array = [];
      result.Sale_page.map((template) => {
        result_array.push({
          ...template,
          preview_link_data: {
            to: client_Url + template.sale_page_title_slug,
            target: "_blank",
            show_text: "Preview",
            className: "anchor-style",
          },
        });
      });
      setTemplateData(result_array);
      get_consultant_detail();
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    let selectedTemplates = [];
    selected.map((template) => {
      selectedTemplates.push({
        page_id: template._id,
      });
    });
    let sale_pages = {
      sale_page: selectedTemplates,
    };

    const result = await manage_template_access_for_consultant_api(
      params.id,
      sale_pages
    );
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(`/consultant`);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  let breadCrumbMenu = [
    {
      title: "Delegates",
      navigation: -1,
      active: false,
    },
    {
      title: state
        ? state?.first_name + state?.last_name + " (" + state?.email + ")"
        : "Manage Templates Access",
      active: true,
    },
  ];

  useEffect(() => {
    getTemplateConfig();
  }, []);

  if (isLoading == true) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex  mb-2">
        <div className="col-12 mb-3">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
        <div className="col-6">
          <h2>Manage Templates Access</h2>
        </div>
      </div>
      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        data={templateData}
        selected={selected}
        setSelected={setSelected}
        checkbox_selection={true}
        pagePagination={true}
      />
      <div className="ms-auto text-end" id="fixedbutton">
        <button
          className="small-contained-button mt-3 ml-auto"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
}
