import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton } from "@mui/material";
import { useEffect } from "react";
import { s3baseUrl } from "src/config/config";
import {
  add_programme_document_api,
  edit_programme_document_api,
  programme_document_detail_api,
} from "src/DAL/Programme/ProgrammeDocuments/ProgrammeDocuments";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const Input = styled("input")({
  display: "none",
});

export default function AddOrUpdateProgramDocument() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const params = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [audioFile, setAudio] = useState(null);
  const [formType, setFormType] = useState("ADD");
  const [oldAudio, setOldAudio] = useState("");

  const [images, setImages] = useState({
    image: "",
    image_thumbnail: "",
    document_file: "",
  });

  const [oldImages, setOldImages] = useState({
    image: "",
    image_thumbnail: "",
    document_file: "",
  });

  const [inputs, setInputs] = useState({
    title: "",
    status: true,
    document_type: "image",
    image: {},
    image_thumbnail: {},
    document_file: {},
    detailed_description: "",
    document_file_url: "",
  });

  const fileChangedHandler = (e) => {
    const { name, files } = e.target;
    setImages({
      ...images,
      [name]: URL.createObjectURL(files[0]),
    });

    setInputs({
      ...inputs,
      [name]: files[0],
    });
  };

  const audioFileChange = (e) => {
    setAudio(e.target.files[0]);
  };

  const handldeDeleteAudio = () => {
    setAudio(null);
  };

  const get_document_detail = async () => {
    setIsLoading(true);
    const result = await programme_document_detail_api(params.document_slug);
    if (result.code == 200) {
      handleFormat(result.program_document);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("title", inputs.title);
    formData.append("detailed_description", inputs.detailed_description);
    formData.append("status", inputs.status);
    formData.append("document_type", inputs.document_type);

    if (images.image_thumbnail) {
      formData.append("document_thumbnail", inputs.image_thumbnail);
    }
    if (formType == "ADD") {
      formData.append("program_slug", params.programme_slug);
    }

    if (inputs.document_type == "audio" && audioFile) {
      formData.append("document_file", audioFile);
    } else if (inputs.document_type == "image" && images.image) {
      formData.append("document_file", inputs.image);
    } else if (
      inputs.document_type == "other_document" &&
      images.document_file
    ) {
      formData.append("document_file", inputs.document_file);
    }

    const result =
      formType == "ADD"
        ? await add_programme_document_api(formData)
        : await edit_programme_document_api(formData, params.document_slug);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      handleNavigate();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleNavigate = () => {
    navigate(`/programmes/programm-document/${params.programme_slug}`);
  };

  const handleFormat = (data) => {
    let document_file_url = data.document_file_url;
    if (data.document_type == "image") {
      document_file_url = data.document_images_url.thumbnail_2;
    } else if (data.document_type == "audio") {
      setOldAudio(document_file_url);
    }
    setInputs(data);
    setOldImages({
      ...oldImages,
      [data.document_type]: document_file_url,
      ["image_thumbnail"]: data.document_thumbnail,
    });
    setFormType("EDIT");
    setIsLoading(false);
  };

  useEffect(() => {
    if (params.document_slug) {
      if (state) {
        handleFormat(state);
      } else {
        get_document_detail();
      }
    }
  }, []);

  if (isLoading === true) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={handleNavigate}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h2>{`${formType == "ADD" ? "Add" : "Edit"} Document`}</h2>
        </div>
      </div>
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Document Title"
            variant="outlined"
            fullWidth
            name="title"
            value={inputs.title}
            onChange={handleChange}
            required
          />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">Document Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="document_type"
              value={inputs.document_type}
              label="Document Type"
              onChange={handleChange}
              required
            >
              <MenuItem value="image">Image</MenuItem>
              <MenuItem value="audio">Audio</MenuItem>
              <MenuItem value="other_document">Other</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Document Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="status"
              value={inputs.status}
              label="Document Status"
              onChange={handleChange}
              required
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>

        {inputs.document_type === "audio" && (
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Upload Audio *</p>
                <FormHelperText className="pt-0">
                  Allowed Formats is "Mp3",200Mb
                </FormHelperText>
              </div>
              <div className="col-2">
                {audioFile && (
                  <DeleteIcon
                    onClick={handldeDeleteAudio}
                    className="mt-3 icon-color"
                  />
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="audio">
                  <Input
                    accept="audio/mp3,audio/*;capture=microphone"
                    id="audio"
                    multiple
                    name="audio"
                    type="file"
                    onChange={audioFileChange}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            <p className="text-secondary">{audioFile && audioFile.name}</p>
            {oldAudio && (
              <audio className="w-100" src={s3baseUrl + oldAudio} controls />
            )}
          </div>
        )}
        {inputs.document_type == "image" && (
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Upload Image *</p>
                <FormHelperText className="pt-0">
                  Image Size( 1000*670) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {(images.image || oldImages.image) && (
                  <img
                    src={
                      images.image ? images.image : s3baseUrl + oldImages.image
                    }
                    height="50"
                  />
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="image"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.image?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs?.image?.name}</p>
            )}
          </div>
        )}
        {inputs.document_type == "other_document" && (
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Upload Other Document *</p>
                <FormHelperText className="pt-0">
                  Other Document *("PDF", "DOC", "XLSX", "DOCX","CSV","200MB")
                </FormHelperText>
              </div>
              <div className="col-2"></div>
              <div className="col-5 text-end pt-2">
                {inputs.document_file_url && (
                  <a
                    href={s3baseUrl + inputs.document_file_url}
                    target="_blank"
                    className="me-2 small-contained-button"
                  >
                    View File
                  </a>
                )}
                <label htmlFor="contained-button-file-other">
                  <Input
                    accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf,.png,.csv"
                    id="contained-button-file-other"
                    multiple
                    type="file"
                    name="document_file"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.document_file?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs?.document_file?.name}</p>
            )}
          </div>
        )}

        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Upload Document Thumbnail </p>
              <FormHelperText className="pt-0">
                Image Size (100*100) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-2">
              {(images.image_thumbnail || oldImages.image_thumbnail) && (
                <img
                  src={
                    images.image_thumbnail
                      ? images.image_thumbnail
                      : s3baseUrl + oldImages.image_thumbnail
                  }
                  height="50"
                />
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file-thumbnail">
                <Input
                  accept="image/*"
                  id="contained-button-file-thumbnail"
                  multiple
                  type="file"
                  name="image_thumbnail"
                  onChange={fileChangedHandler}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          {inputs?.image_thumbnail?.name == "" ? (
            ""
          ) : (
            <p className="text-secondary">{inputs?.image_thumbnail?.name}</p>
          )}
        </div>

        <div className="col-12 mt-3">
          <FormControl fullWidth>
            <TextField
              id="outlined-multiline-flexible"
              label="Short Description "
              multiline
              rows={6}
              name="detailed_description"
              value={inputs.detailed_description}
              onChange={handleChange}
            />
            <FormHelperText>Maximum limit 500 characters</FormHelperText>
          </FormControl>
        </div>

        <div
          className="text-end mt-4"
          id={formType == "ADD" ? "" : "fixedbutton"}
        >
          <button className="small-contained-button">
            {formType == "ADD" ? "Submit" : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
