import { Icon } from "@iconify/react";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import EngagementsFilter from "./EngagementsFilter";
import LinearWithValueLabel from "./Progress";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import { useSnackbar } from "notistack";
import { ManageEngageAPi } from "src/DAL/DelegatesRecordings/DelegatesRecordings";
import { useEffect } from "react";
import { dd_date_format } from "src/utils/constant";
import moment from "moment";
import { useLocation } from "react-router-dom";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const Engagements = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const currentDate = new Date();
  const { state } = useLocation();
  const oneWeekAgo = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 7
  );

  const EMPTY_FILTER = {
    date_from: oneWeekAgo,
    date_to: currentDate,
  };
  const [filterData, setFilterData] = useState(EMPTY_FILTER);

  const [openFilter, setOpenfilter] = useState(false);
  const [data, setData] = useState(null);
  const today = new Date();
  const handleOPenDawer = () => {
    setOpenfilter(true);
  };
  const handleChaneOthers = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };
  const handleSelectOther = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };
  const manageENgage = async (data) => {
    setIsLoading(true);

    let postData = {
      start_date: moment(data.date_from).format("YYYY-MM-DD"),
      end_date: moment(data.date_to).format("YYYY-MM-DD"),
      short_link: state.short_link_id,
    };
    const result = await ManageEngageAPi(postData);
    if (result.code == 200) {
      console.log(result, "resultresultresultresult");
      setData(result.stats);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const [options, setOptions] = useState({
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "20%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    colors: ["#e4c073"],
    xaxis: {
      categories: [], // Initially empty, will be set dynamically
      labels: {
        style: {
          colors: "#FFFFFF",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#FFFFFF",
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  });

  const [series, setSeries] = useState([
    {
      name: "Link Clicks",
      data: [], // Initially empty, will be set dynamically
    },
  ]);
  let breadCrumbMenu = [
    {
      title: "Short Links",
      navigation: -1,
      active: false,
    },
    {
      title: state.title,
      active: true,
    },
  ];

  const searchFunction = () => {
    manageENgage(filterData);
    localStorage.setItem("enegagements_filter", JSON.stringify(filterData));
    setOpenfilter(false);
  };
  useEffect(() => {
    if (data) {
      const updatedDates = data?.engagements.map((item) =>
        dd_date_format(item.date)
      );
      const updatedClicksData = data?.engagements.map((item) => item.clicks);

      const maxClicks = Math.max(...updatedClicksData);
      const maxYaxisValue = maxClicks > 10 ? maxClicks : 10; // Ensure y-axis max is at least 10

      setSeries([{ name: "Link Clicks", data: updatedClicksData.reverse() }]);
      setOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: { ...prevOptions.xaxis, categories: updatedDates.reverse() },
        yaxis: {
          ...prevOptions.yaxis,
          min: 0,
          max: maxYaxisValue,
        },
      }));
    }
  }, [data]);
  useEffect(() => {
    let filter_data = EMPTY_FILTER;

    let find_filter = localStorage.getItem(`enegagements_filter`);

    if (find_filter) {
      filter_data = JSON.parse(find_filter);
    }
    setFilterData(filter_data);
    manageENgage(filter_data);
  }, []);
  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 mb-3">
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </div>{" "}
          <div className="col-md-6 mb-3">
            <div className="d-flex justify-content-end align-items-center">
              <button
                className="small-contained-button ms-2 "
                onClick={handleOPenDawer}
              >
                Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="stats-container">
        <div class="stat-card me-3">
          <p class="stat-title">Engagements</p>
          <p class="stat-value">{data.total_engagments}</p>
        </div>
        <div class="stat-card ">
          <p class="stat-title">Last 7 days</p>
          <p class="stat-value">{data.last_7_days_clicks}</p>
        </div>
        {/* <div class="stat-card ms-3">
          <p class="stat-title">Weekly change</p>
          <p class="stat-value negative-change">
            {" "}
            <Icon icon="hugeicons:trade-down" />
            -100%
          </p>
        </div> */}
      </div>
      <div>
        <div className="card mt-3">
          <div className="d-flex justify-content-between">
            <h3 className="mui_table_title"> Enegagements over time</h3>
          </div>
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height={350}
          />
        </div>
        {data.countries_engagments && data.countries_engagments.length > 0 && (
          <div className="card mt-3">
            <div className="d-flex justify-content-between">
              <h3 className="mui_table_title">Locations</h3>
            </div>

            <div
              className="container-fluid"
              style={{ padding: "20px", paddingTop: "0px" }}
            >
              <div className="row mb-3">
                <div className="col-1">
                  <span className="heading-subtitle">#</span>
                </div>
                <div className="col-3">
                  <span className="heading-subtitle">Country</span>
                </div>
                <div className="col-4"></div>
                <div className="col-2">
                  <span className="heading-subtitle">Engagement</span>
                </div>
                <div className="col-2">
                  <span className="heading-subtitle">percentage</span>
                </div>
              </div>{" "}
              {data.countries_engagments.map((item, index) => {
                return (
                  <div className="row mb-1 mt-1">
                    <div className="col-1">
                      <span className="contents-added">{index + 1}</span>
                    </div>
                    <div className="col-3">
                      <span className="contents-added">
                        {item.country_name}
                      </span>
                    </div>
                    <div className="col-4" style={{ marginTop: "10px" }}>
                      <span>
                        <LinearWithValueLabel number={item.percentage} />
                      </span>
                    </div>
                    <div className="col-2">
                      <span className="contents-added">{item.clicks}</span>
                    </div>
                    <div className="col-2">
                      <span className="contents-added">{item.percentage}%</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>

      <GeneralPopUpModel
        open={openFilter}
        setOpen={setOpenfilter}
        title={"Filter"}
        componentToPassDown={
          <EngagementsFilter
            handleChaneOthers={handleChaneOthers}
            filterData={filterData}
            searchFunction={searchFunction}
            handleSelectOther={handleSelectOther}
          />
        }
      />
    </>
  );
};

export default Engagements;
