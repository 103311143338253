import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { useSnackbar } from "notistack";
import { transactionListApiNew } from "src/DAL/consultant/consultant";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import CommissionHistory from "./CommissionHistory";
import CommissionHistoryPaid from "./CommissionHistoryPaid";
import TransactionsStatCard from "../Payments/TransactionsStatCard";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import { Icon } from "@iconify/react";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import DelegateFilter from "./DelegateFilter";
import moment from "moment";
import FilteredChip from "src/components/FilteredChip";
import { dd_date_format } from "src/utils/constant";
import PageLoader from "src/components/GeneralComponents/PageLoader";

export default function CommissionHistoryTabs() {
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [tabValue, setTabValue] = useState(0);
  const [page, setPage] = useState(0);
  const [UserName, setUserName] = useState("");
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [userList, setUserList] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isTabLoading, setTabIsLoading] = useState(false);
  const [filterDrawerState, setFilterDrawerState] = useState(false);

  const EMPTY_FILTER = {
    start_date: null,
    end_date: null,
    date: null,
    sale_page: null,
    payment_plan: null,
    payment_request: [],
    transaction_type: "all",
  };

  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [filterStateUpdated, setFilterStateUpdated] = useState(EMPTY_FILTER);
  const [isAllDates, setIsAllDates] = useState(false);

  const getConsultantListing = async (filter_data) => {
    setTabIsLoading(true);
    let postData = { ...filter_data };

    postData.type = "credit";

    if (postData.transaction_type == "sale_page") {
      postData.sale_page = filter_data.sale_page
        ? [filter_data.sale_page._id]
        : [];
    } else {
      postData.sale_page = [];
    }

    if (postData.payment_plan) {
      postData.payment_plan = filter_data.payment_plan._id;
    }

    if (postData.transaction_type == "payment_request") {
      postData.payment_request = filter_data.payment_request.map((item) => {
        return item._id;
      });
    }

    if (postData.start_date) {
      postData.start_date = moment(filter_data.start_date).format("YYYY-MM-DD");
    }

    if (postData.end_date) {
      postData.end_date = moment(filter_data.end_date).format("YYYY-MM-DD");
    }

    if (postData.transaction_type !== "sale_page") {
      postData.sale_page = null;
      postData.payment_plan = null;
    }

    if (postData.transaction_type !== "payment_request") {
      postData.payment_request = [];
    }

    if (!isAllDates) {
      postData.start_date = null;
      postData.end_date = null;
    }

    handleCloseFilterDrawer();
    const result = await transactionListApiNew(
      params.id,
      page,
      rowsPerPage,
      postData
    );

    if (result.code == 200) {
      let chipData = { ...filter_data };

      if (chipData.start_date) {
        let date = `Start Date : ${dd_date_format(
          chipData.start_date
        )} End Date :  ${dd_date_format(chipData.end_date)}`;
        chipData.date = {
          chip_label: date,
          chip_value: date,
        };
        delete chipData.start_date;
        delete chipData.end_date;
      }

      if (!isAllDates) {
        delete chipData.date;
        delete chipData.start_date;
        delete chipData.end_date;
      }
      if (chipData.transaction_type == "all") {
        delete chipData.transaction_type;
      }

      if (chipData.transaction_type !== "sale_page") {
        delete chipData.sale_page;
        delete chipData.payment_plan;
      }

      if (chipData.transaction_type !== "payment_request") {
        delete chipData.payment_request;
      }

      if (chipData.transaction_type !== "payment_request") {
        delete chipData.payment_request;
      }

      setFilterStateUpdated(chipData);
      setUserList(result);
      setUserName(result.consultant);
      setTotalPages(result?.total_pages);
      setTotalCount(result?.total_member_count);
      setIsLoading(false);
      setTabIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setTabIsLoading(false);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    filterData.search_text = searchText;
    getConsultantListing(filterData);
    localStorage.setItem("delegate_filter_data", JSON.stringify(filterData));
  };

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };

  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const handleChangeOthers = (name, value) => {
    setFilterData((prevState) => {
      let newState = { ...prevState, [name]: value };

      if (newState.transaction_type !== "sale_page") {
        newState.sale_page = null;
        newState.payment_plan = null;
      }

      if (newState.transaction_type !== "payment_request") {
        newState.payment_request = [];
      }

      return newState;
    });
  };

  const handleClearFilter = () => {
    setFilterData(EMPTY_FILTER);
    getConsultantListing(EMPTY_FILTER);
    handleCloseFilterDrawer();
    localStorage.removeItem("delegate_filter_data");
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);
    data.search_text = searchText;
    if (!data.date) {
      delete data.date;
      data.start_date = null;
      data.end_date = null;
      setIsAllDates(false);
    }
    getConsultantListing(data);
    setFilterData(data);
    setFilterStateUpdated(data);
    localStorage.setItem("delegate_filter_data", JSON.stringify(data));
  };

  let breadCrumbMenu = [
    {
      title: "Delegates",
      navigation: -1,
      active: false,
    },
    {
      title: UserName
        ? UserName?.first_name +
          " " +
          UserName?.last_name +
          " (" +
          UserName.email +
          ")"
        : "Commission History",
      active: true,
    },
  ];

  useEffect(() => {
    let filter_data = filterData;
    let find_filter = localStorage.getItem("delegate_filter_data");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      if (filter_data.search_text) {
        setSearchText(filter_data.search_text);
      }
    }
    setFilterData(filter_data);
    setFilterStateUpdated(filter_data);
    getConsultantListing(filter_data);
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (isAllDates) {
      setFilterData((old) => {
        return {
          ...old,
          start_date: null,
          end_date: null,
          date: null,
        };
      });
    }
  }, [isAllDates]);

  const data = {
    userData: userList,
    total_count: totalCount,
    rows_per_page: rowsPerPage,
    page: page,
    handleChangePage: handleChangePage,
    onRowsPerPageChange: handleChangeRowsPerPage,
    searchText: searchText,
    setSearchText: setSearchText,
    handleSubmit: searchFunction,
    pageCount: pageCount,
    totalPages: totalPages,
    handleChangePages: handleChangePages,
  };

  const TABS_OPTIONS = [
    {
      title: "Credit",
      component: isTabLoading ? (
        <PageLoader />
      ) : (
        <CommissionHistory data={data} />
      ),
    },
    {
      title: "Paid",
      component: isTabLoading ? <PageLoader /> : <CommissionHistoryPaid />,
    },
  ];

  if (isLoading == true) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 mb-4">
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </div>
          <TransactionsStatCard
            count={userList.total_commission}
            title="Total Commission"
            className="col-lg-4"
          />
          <TransactionsStatCard
            count={userList.paid_commission}
            title="Paid Commission"
            className="col-lg-4"
          />
          <TransactionsStatCard
            count={userList.remaining_commission}
            title="Pending Commission"
            className="col-lg-4"
          />
        </div>
      </div>
      {tabValue == 0 && (
        <>
          <div className="text-end">
            <button
              className="small-contained-button me-2 mt-1"
              onClick={handleOpenFilterDrawer}
            >
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>
          </div>
          {!isTabLoading && (
            <FilteredChip
              data={filterStateUpdated}
              tempState={filterData}
              EMPTY_FILTER={EMPTY_FILTER}
              onDeleteChip={handleDeleteChip}
              onClear={handleClearFilter}
            />
          )}
        </>
      )}
      <MUICustomTabs
        data={TABS_OPTIONS}
        value={tabValue}
        handleChange={handleChange}
      />
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <DelegateFilter
            filterData={filterData}
            isAllDates={isAllDates}
            setIsAllDates={setIsAllDates}
            handleClearFilter={handleClearFilter}
            handleChangeOthers={handleChangeOthers}
            searchSubmitFilter={searchFunction}
          />
        }
      />
    </>
  );
}
