import { useEffect, useState } from "react";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import AddOrUpdateFunnel from "./AddOrUpdateFunnel";
import CustomPopover from "src/components/GeneralComponents/CustomPopover";
import { useNavigate } from "react-router-dom";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { useSnackbar } from "notistack";
import {
  delete_funnel_report,
  list_funnel_report,
} from "src/DAL/FunnelPage/FunnelPage";
import { Box, InputAdornment, OutlinedInput } from "@mui/material";
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
import { styled } from "@mui/material/styles";
import moment from "moment";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  height: 44,
  color: "#fff",
  border: "1px solid #e4c073",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    boxShadow: theme.customShadows.z8,
    border: "1px solid #e4c073",
    width: 280,
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const FunnelStep = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openFilter, setOpenfilter] = useState(false);
  const [deleteDoc, setDeleteDoc] = useState("");
  const [editDoc, setEditDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [formType, setFormType] = useState("ADD");
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [userList, setUserList] = useState([]);
  const [funnelTitle, setFunnelTitle] = useState("");
  const [notificationArray, setNotificationArray] = useState([
    {
      title: "",
      fieldName: "pageTitle",
      matchType: "contains",
      page_name: "",
    },
    {
      title: "",
      fieldName: "pageTitle",
      matchType: "contains",
      page_name: "",
    },
  ]);

  const handleEdit = (value) => {
    setFormType("EDIT");
    setEditDoc(value._id);
    if (value.funnel_steps && value.funnel_steps.length > 0) {
      let data = value.funnel_steps.map((item) => {
        const array_value =
          item.filterExpression.andGroup.expressions[0].funnelFieldFilter;
        return {
          title: item.name,
          fieldName: array_value.fieldName,
          matchType: array_value.stringFilter.matchType,
          page_name: array_value.stringFilter.value,
        };
      });
      setNotificationArray(data);
      setFunnelTitle(value?.title);
    }
    setOpenfilter(true);
  };

  const handleViewDetail = (value) => {
    navigate(`/funnel-exploration/funnel-detail/${value._id}`, {
      state: value,
    });
  };

  const getSetpList = async (data) => {
    setIsLoading(true);
    let postData = {
      search: data ? data : "",
    };
    const result = await list_funnel_report(postData);
    if (result.code === 200) {
      if (result.ga4_funnels.length == 0 && !searchText) {
        setOpenfilter(true);
      }
      setUserList(result.ga4_funnels);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSearchText = (event) => {
    setSearchText(event.target.value);
    localStorage.setItem("funnel_step_search", event.target.value);
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleOpenDawer = () => {
    setFormType("ADD");
    setNotificationArray([
      {
        title: "",
        fieldName: "pageTitle",
        matchType: "contains",
        page_name: "",
      },
      {
        title: "",
        fieldName: "pageTitle",
        matchType: "contains",
        page_name: "",
      },
    ]);
    setFunnelTitle("");
    setOpenfilter(true);
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    {
      id: "title",
      label: "Title",
      renderData: (row) => {
        return (
          <div
            className="pointer_cursor"
            onClick={() => {
              handleViewDetail(row);
            }}
          >
            {row.title}
          </div>
        );
      },
    },
    {
      id: "createdAt",
      label: "Created At",
      renderData: (row) => {
        return moment(row.createdAt).format("DD MMM YYYY");
      },
    },
    {
      id: "updatedAt",
      label: "Last Modified",
      renderData: (row) => {
        return moment(row.updatedAt).format("DD MMM YYYY");
      },
    },
  ];

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "View Detail",
      icon: "eva:eye-fill",
      handleClick: handleViewDetail,
    },
  ];

  const handleDelete = async () => {
    setIsLoading(true);
    setOpenDelete(false);
    const result = await delete_funnel_report(deleteDoc._id);
    if (result.code === 200) {
      getSetpList(searchText);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let find_search = localStorage.getItem("funnel_step_search");
    if (find_search) {
      setSearchText(find_search);
    }
    getSetpList(find_search);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      const timeoutId = setTimeout(() => {
        getSetpList(searchText);
      }, 300);
      return () => clearTimeout(timeoutId);
    }
  }, [searchText]);

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete Funnel Steps ?"}
        handleAgree={handleDelete}
      />
      <div className="container-fluid">
        <div className="row mb-3 mt-2">
          <div className="col-lg-4">
            <h2>Funnel Exploration</h2>
          </div>
          <div className="col-lg-8 d-flex justify-content-end">
            <SearchStyle
              className="ms-auto"
              value={searchText}
              onChange={handleSearchText}
              placeholder="Search"
              startAdornment={
                <InputAdornment position="start">
                  <Box
                    component={Icon}
                    icon={searchFill}
                    sx={{ color: "text.disabled" }}
                  />
                </InputAdornment>
              }
            />
            <button
              onClick={handleOpenDawer}
              className="small-contained-button ms-2"
            >
              Funnel Steps
            </button>
          </div>
        </div>
        {isLoading ? (
          <PageLoader />
        ) : (
          <div className="mt-3">
            <CustomMUITable
              MENU_OPTIONS={MENU_OPTIONS}
              TABLE_HEAD={TABLE_HEAD}
              data={userList}
              className="card-with-background"
              is_hide={true}
            />
          </div>
        )}
        <CustomPopover
          isOpenPop={openFilter}
          isClosePop={setOpenfilter}
          title={"Funnel Steps"}
          componentToPassDown={
            <AddOrUpdateFunnel
              setOpenfilter={setOpenfilter}
              setNotificationArray={setNotificationArray}
              notificationArray={notificationArray}
              setFunnelTitle={setFunnelTitle}
              funnelTitle={funnelTitle}
              formType={formType}
              setUserList={setUserList}
              userList={userList}
              edit_id={editDoc}
            />
          }
        />
      </div>
    </>
  );
};

export default FunnelStep;
