import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import { useEffect } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import moment from "moment";
import { addCoupen, updateCoupen } from "src/DAL/CoupenApi/CoupenApi";
import { project_mode } from "src/config/config";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function AddOrUpDateCoupen() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    name: "",
    discount_type: "amount",
    amount: "",
    percentage: "",
    currency: "USD",
    duration: "once",
    max_redemptions: "",
    redeem_by: moment().add(1, "days").toDate(),
    coupon_mode: project_mode == "dev" ? "sandBox" : "live",
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let postData = {
      name: inputs.name,
      discount_type: inputs.discount_type,
      duration: inputs.duration,
      max_redemptions: inputs.max_redemptions,
      description: inputs.description,
      redeem_by: inputs.redeem_by
        ? moment(inputs.redeem_by).format("YYYY-MM-DD")
        : null,
      coupon_mode: inputs.coupon_mode,
    };
    if (inputs.discount_type === "percentage") {
      postData.percentage = inputs.percentage;
    } else {
      postData.amount = inputs.amount;
      postData.currency = inputs.currency;
    }
    if (params?.id) {
      delete postData.duration;
      delete postData.max_redemptions;
      delete postData.currency;
      delete postData.percentage;
      delete postData.amount;
      delete postData.discount_type;
      delete postData.redeem_by;
      delete postData.coupon_mode;
    }
    const result = params.id
      ? await updateCoupen(params.id, postData)
      : await addCoupen(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleChangeOthers = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handlechageNUmberPositive = (event) => {
    const name = event.target.name;
    let value = parseFloat(event.target.value);
    if (name === "percentage") {
      value = Math.min(100, Math.max(0, value));
    } else {
      value = Math.max(0, value);
    }
    event.target.value = value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  useEffect(() => {
    if (params?.id && state) {
      console.log("stateData", state);
      setInputs(state);
      setInputs((values) => ({
        ...values,
        description: state?.metadata?.description,
      }));
    }
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex align-items-center">
          <span className="me-2">
            <IconButton
              className="back-screen-button "
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>

          <h2>{params?.id ? "Edit Coupon" : "Add Coupon"}</h2>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          {params.id ? (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  required
                  name="name"
                  value={inputs.name}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Description"
                  variant="outlined"
                  fullWidth
                  name="description"
                  value={inputs.description}
                  onChange={handleChange}
                  multiline
                  minRows={3}
                />
              </div>
            </>
          ) : (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  required
                  name="name"
                  value={inputs.name}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">
                    Discount Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="discount_type"
                    value={inputs.discount_type}
                    label="Discount Type"
                    onChange={handleChange}
                  >
                    <MenuItem value="amount">Amount</MenuItem>
                    <MenuItem value="percentage">Percentage</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {inputs.discount_type === "amount" && (
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Amount Off"
                    variant="outlined"
                    fullWidth
                    required
                    name="amount"
                    type="number"
                    value={inputs.amount}
                    onChange={handlechageNUmberPositive}
                  />
                </div>
              )}
              {inputs.discount_type === "percentage" && (
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Percentage Off"
                    variant="outlined"
                    fullWidth
                    required
                    name="percentage"
                    type="number"
                    value={inputs.percentage}
                    onChange={handlechageNUmberPositive}
                  />
                </div>
              )}
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">
                    Currency
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="currency"
                    value={inputs.currency}
                    label="Currency"
                    onChange={handleChange}
                  >
                    <MenuItem value="USD">USD</MenuItem>
                    <MenuItem value="GBP">GBP</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">
                    Duration
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="duration"
                    value={inputs.duration}
                    label="Duration"
                    onChange={handleChange}
                  >
                    <MenuItem value="once">Once</MenuItem>
                    <MenuItem value="forever">Forever</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Max Redemptions"
                  variant="outlined"
                  fullWidth
                  name="max_redemptions"
                  type="number"
                  inputProps={{ min: 1 }}
                  value={inputs.max_redemptions}
                  onChange={handlechageNUmberPositive}
                />
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Redeem By"
                    inputFormat="dd-MM-yyyy"
                    value={inputs.redeem_by}
                    onChange={(e) => handleChangeOthers("redeem_by", e)}
                    minDate={moment().add(1, "days").toDate()}
                    renderInput={(params) => (
                      <TextField {...params} required fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">
                    Coupon Mode
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="coupon_mode"
                    value={inputs.coupon_mode}
                    label="Coupon Mode"
                    onChange={handleChange}
                  >
                    <MenuItem value="sandBox">Sandbox</MenuItem>
                    <MenuItem value="live">Live</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Description"
                  variant="outlined"
                  fullWidth
                  name="description"
                  value={inputs.description}
                  onChange={handleChange}
                  multiline
                  minRows={3}
                />
              </div>
            </>
          )}
          <div className="text-end mt-4">
            <button className="small-contained-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
}
