import * as React from "react";
import { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import NotificationSchedule from "./ProfileTabs/NotificationSchedule";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";

const NotificationScheduleList = ({ type }) => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(
    localStorage.getItem("notification_schedule_tab_value")
      ? parseInt(localStorage.getItem("notification_schedule_tab_value"))
      : 0
  );

  const TABS_OPTIONS = [
    {
      title: "Notification Pending",
      component: <NotificationSchedule tabValue={tabValue} />,
    },

    {
      title: "Notification Sent",
      component: <NotificationSchedule tabValue={tabValue} />,
    },
  ];

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className="container-fluid">
      {type != "detail" && (
        <div className="row mobile-margin display-flex">
          <div className="col-12 d-flex">
            <span>
              <IconButton
                className="back-screen-button mb-4"
                onClick={() => navigate("/member")}
              >
                <ArrowBackIcon />
              </IconButton>
            </span>

            <h2 className="ms-1">Notification Schedule</h2>
          </div>
        </div>
      )}

      <MUICustomTabs
        data={TABS_OPTIONS}
        value={tabValue}
        handleChange={handleChange}
      />
    </div>
  );
};

export default NotificationScheduleList;
