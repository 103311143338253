import React from "react";
import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { Chip, Tooltip } from "@mui/material";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { useSnackbar } from "notistack";
import moment from "moment";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import { bookingListStatApi } from "src/DAL/booking/bookingApi";
import { dd_date_format } from "src/utils/constant";
import FilteredChip from "src/components/FilteredChip";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import BookingFilter from "../Booking/BookingFilter";
import CallStatsCard from "./CallStatsCard";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const TABLE_HEAD = [
  { id: "number", label: "#", type: "number" },
  { id: "member_name", label: "Member With Booked Calls", alignRight: false },
  {
    id: "consultant_name",
    label: "Consultant In Booked Calls",
    alignRight: false,
  },
  { id: "sale_page", label: "Sale Page", alignRight: false },
  { id: "booking_date", label: "Booking Date", alignRight: false },
  { id: "time", label: "Duration", alignRight: false },
  { id: "created_at", label: "Created", alignRight: false },
  {
    id: "status",
    label: "Booking Status",
    renderData: (row) => {
      let find_status = row.booking_status_info;
      if (find_status) {
        return (
          <Tooltip title={find_status?.title}>
            <Chip
              label={find_status?.title}
              variant="contained"
              className="booking-status-chip"
              style={{
                backgroundColor: find_status.background_color,
                color: find_status.text_color,
                height: "22px",
              }}
              size="small"
            />
          </Tooltip>
        );
      }
    },
  },
];

export default function CallStats() {
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [loadFullPage, setLoadFullPage] = useState(true);
  const [callCount, setcallCount] = useState([]);

  const EMPTY_FILTER = {
    start_date: null,
    end_date: null,
    booking_status: null,
    consultant_id: null,
    search_text: "",
    filter_by_dates: false,
    sale_page: [],
    date: null,
  };
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [updateFilterData, setUpdateFilterData] = useState(EMPTY_FILTER);

  const handleSelectOther = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const handleOpenFilterDrawer = (data) => {
    setFilterDrawerState(true);
  };

  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const getBookingListing = async (filter_data) => {
    let postData = { ...filter_data };

    if (postData.consultant_id) {
      postData.consultant_id = filter_data.consultant_id._id;
    }

    if (postData.sale_page) {
      postData.sale_page = filter_data.sale_page.map((item) => {
        return item._id;
      });
    }

    if (postData.start_date) {
      postData.start_date = moment(filter_data.start_date).format("YYYY-MM-DD");
    }

    if (postData.end_date) {
      postData.end_date = moment(filter_data.end_date).format("YYYY-MM-DD");
    }

    if (postData.booking_status) {
      postData.booking_status = postData.booking_status._id;
    }

    setIsLoading(true);
    handleCloseFilterDrawer();
    const result = await bookingListStatApi(
      page,
      rowsPerPage,
      postData,
      searchText
    );

    if (result.code == 200) {
      let chipData = { ...filter_data };
      if (chipData.end_date) {
        let date = `Start Date : ${dd_date_format(
          chipData.start_date
        )} End Date :  ${dd_date_format(chipData.end_date)}`;
        chipData.date = {
          chip_label: date,
          chip_value: date,
        };
        delete chipData.start_date;
        delete chipData.end_date;
      }
      if (chipData.search_text) {
        delete chipData.search_text;
      }
      setUpdateFilterData(chipData);
      const members = result?.booking_list?.map((member, index) => {
        const dateTime = moment().format("YYYY-MM-DD") + " " + member.time;

        let member_name = "N/A";
        let consultant_name = "N/A";

        if (member.user_info) {
          member_name =
            member.user_info.first_name +
            " " +
            member.user_info.last_name +
            " (" +
            member.user_info.email +
            ")";
        }

        if (member.consultant) {
          consultant_name =
            member.consultant.first_name +
            " " +
            member.consultant.last_name +
            " (" +
            member.consultant.email +
            ")";
        }
        return {
          ...member,
          member_name,
          consultant_name,
          id: member._id,
          message: member.questions,
          sale_page: member?.page?.sale_page_title,
          booking_date: member?.date
            ? moment(member?.date).format("DD-MM-YYYY")
            : "",
          time:
            member.time +
            " to " +
            moment(dateTime, "DD-MM-YYYY hh:mm A")
              .add(member.slot_duration, "minutes")
              .format("hh:mm A"),
          register_url: member.register_url,
          object: member,
          count: index + 1 + rowsPerPage * page,
          created_at: moment(member?.createdAt).format("DD-MM-YYYY"),
        };
      });

      setData(members);
      setcallCount(result.booking_counts_array);
      setTotalCount(result.total_booking_count);
      setIsLoading(false);
      setLoadFullPage(false);
      setTotalPages(result.total_pages);
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    filterData.search_text = searchText;
    getBookingListing(filterData);
    localStorage.setItem(
      "booking_list_filter_stat",
      JSON.stringify(filterData)
    );
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);
    if (!data.filter_by_dates) {
      delete data.date;
      data.start_date = null;
      data.filter_by_dates = false;
      data.end_date = null;
    }
    localStorage.setItem("booking_list_filter_stat", JSON.stringify(data));
    getBookingListing(data);
    setUpdateFilterData(data);
    setFilterData(data);
  };

  const handleClearFilter = () => {
    setPage(0);
    setPageCount(1);
    getBookingListing(EMPTY_FILTER);
    setUpdateFilterData(EMPTY_FILTER);
    setFilterData(EMPTY_FILTER);
    localStorage.removeItem("booking_list_filter_stat");
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    filterData.search_text = searchText;
    let find_filter = localStorage.getItem("booking_list_filter_stat");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
    }
    setFilterData(filter_data);
    setUpdateFilterData(filter_data);
    getBookingListing(filter_data);
  }, [rowsPerPage, page]);

  if (loadFullPage) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          {callCount.length > 0 &&
            callCount.map((stat) => {
              return <CallStatsCard stat={stat} />;
            })}
        </div>
        <div className="row mb-2 mt-5">
          <div className="col-lg-6 col-sm-12">
            <h2>Booking List</h2>
          </div>
          <div className="col-6 text-end">
            <button
              className="small-contained-button me-2 mt-1 mb-4 "
              onClick={handleOpenFilterDrawer}
            >
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>
          </div>
        </div>

        <>
          {isLoading ? (
            <PageLoader />
          ) : (
            <>
              <FilteredChip
                data={updateFilterData}
                tempState={filterData}
                EMPTY_FILTER={EMPTY_FILTER}
                onDeleteChip={handleDeleteChip}
                onClear={handleClearFilter}
              />
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={data}
                className="card-with-background"
                pagePagination={true}
                custom_pagination={{
                  total_count: totalCount,
                  rows_per_page: rowsPerPage,
                  page: page,
                  handleChangePage: handleChangePage,
                  onRowsPerPageChange: handleChangeRowsPerPage,
                }}
                custom_search={{
                  searchText: searchText,
                  setSearchText: setSearchText,
                  handleSubmit: searchFunction,
                }}
                pageCount={pageCount}
                totalPages={totalPages}
                handleChangePages={handleChangePages}
              />
            </>
          )}
        </>
      </div>

      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filter"
        componentToPassDown={
          <BookingFilter
            filterData={filterData}
            handleSelectOther={handleSelectOther}
            searchFunction={searchFunction}
            handleClearFilter={handleClearFilter}
          />
        }
      />
    </>
  );
}
