import React from "react";
import Chart from "react-apexcharts";

const transformData = (activeUsers, metric) => {
  const categories = activeUsers.map((item) => `Day ${parseInt(item.day, 10)}`);
  const metricData = activeUsers.map((item) => item.retentionRate);
  return {
    categories,
    series: [
      {
        name: metric,
        data: metricData,
        color: "#e4c073",
      },
    ],
  };
};

const RetentionGraph = ({ activeUsers, metric }) => {
  const transformedData = transformData(activeUsers, metric);

  const options = {
    chart: {
      type: "line",
    },
    xaxis: {
      categories: transformedData?.categories,
      labels: {
        style: {
          colors: Array(transformedData?.categories?.length).fill("#fff"),
        },
      },
      tickAmount: 5,
    },
    yaxis: {
      labels: {
        style: {
          colors: ["#fff"],
        },
        formatter: (value) => `${value}%`, // Append % sign
      },
    },
    stroke: {
      curve: "smooth",
    },
    fill: {
      opacity: 1,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      theme: "dark",
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
  };

  return (
    <Chart
      options={options}
      series={transformedData.series}
      type="line"
      height={340}
    />
  );
};

export default RetentionGraph;
