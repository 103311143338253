import React from "react";
import MoveToNextPage from "./MoveToNextPage";
import { filterFirebaseData } from "src/utils/firebase_utils";
import { get_kmb_number } from "src/utils/formatNumber";

export default function PageAndScreenViews({ data }) {
  return (
    <>
      <div className="thirty-minutes-report">
        <div className="d-flex justify-content-between mt-3">
          <h3>Page title and screen class</h3>
          <h3>Views</h3>
        </div>
        <hr />
        {filterFirebaseData(data, "unifiedScreenName").map((user) => {
          const { unifiedScreenClass, screenPageViews } = user;
          return (
            <>
              <div className="d-flex justify-content-between">
                <div>{unifiedScreenClass}</div>
                <div>{get_kmb_number(screenPageViews)}</div>
              </div>
              <hr />
            </>
          );
        })}
      </div>
      <MoveToNextPage
        text="View all Pages and screens"
        move_path="/firebase-screens-and-page"
      />
    </>
  );
}
