import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import { dynamite_product_list_filter } from "src/DAL/ShopProduct/shopProduct";
import { convertCurrencyToSign } from "src/utils/constant";
import moment from "moment";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { productCategoryListApi } from "src/DAL/ShopProductCategoryList/ProductCategory";
import FilteredChip from "src/components/FilteredChip";
import { useNavigate } from "react-router-dom";
import PageLoader from "src/components/GeneralComponents/PageLoader";

export default function ShopProduct() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [filterList, setFilterList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [productsList, setProductList] = useState([]);

  const EMPTY_FILTER = {
    category_id: null,
    search_text: "",
  };

  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [filterStateUpdated, setFilterStateUpdated] = useState(EMPTY_FILTER);

  const getProductsList = async (filter_data) => {
    setIsLoading(true);
    let postData = { ...filter_data };
    if (postData.category_id) {
      postData.category_id = postData.category_id._id;
    }
    const result = await dynamite_product_list_filter(
      page,
      rowsPerPage,
      postData
    );
    if (result.code == 200) {
      let chipData = { ...filter_data };
      if (chipData.category_id) {
        chipData.category_id = chipData.category_id.title;
      }
      delete chipData.search_text;

      setFilterStateUpdated(chipData);
      const productsListData = result.product.map((product) => {
        let amount = convertCurrencyToSign(product.currency) + product.price;
        let created_at = "N/A";
        if (product.createdAt) {
          created_at = moment(product.createdAt).format("DD-MM-YYYY");
        }
        return {
          ...product,
          table_avatar: {
            src: s3baseUrl + product?.image[0]?.image,
            alt: product.name,
          },
          status: product.status,
          amount: amount,
          created_at,
        };
      });
      setProductList(productsListData);
      setTotalCount(result.total_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getProductCategories = async () => {
    const result = await productCategoryListApi();
    if (result.code == 200) {
      setFilterList(result.category);
    }
  };

  const handleChangeOthers = (event, name) => {
    let filter_Data = {
      ...filterData,
      [name]: event,
    };

    setFilterData(filter_Data);
    getProductsList(filter_Data);
    localStorage.setItem("shop_filter_data", JSON.stringify(filter_Data));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    filterData.search_text = searchText;
    getProductsList(filterData);
    localStorage.setItem("shop_filter_data", JSON.stringify(filterData));
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);
    data.search_text = searchText;
    getProductsList(data);
    setFilterData(data);
    localStorage.setItem("shop_filter_data", JSON.stringify(data));
  };

  const handleClearFilter = () => {
    setFilterData(EMPTY_FILTER);
    getProductsList(EMPTY_FILTER);
    localStorage.removeItem("shop_filter_data");
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    { id: "table_avatar", label: "Image", type: "thumbnail" },
    { id: "name", label: "Title" },
    {
      id: "product_category",
      label: "Category",
      renderData: (row) => {
        return (
          <>
            {row.product_category ? (
              <p className="mb-0">{row.product_category.title}</p>
            ) : (
              "N/A"
            )}
          </>
        );
      },
    },
    { id: "product_sku", label: "Product Sku" },
    { id: "amount", label: "Price" },
    { id: "sold_quantity", label: "Sold Quantity" },
    {
      id: "remaining_quantity",
      label: "Remaining Quantity",
      renderData: (row) => {
        return <p className="mb-0">{row.remaining_quantity}</p>;
      },
    },
    { id: "quantity", label: "Total Quantity" },
    { id: "created_at", label: "Created At" },
    { id: "order", label: "Order" },
    { id: "status", label: "Status", type: "row_status" },
  ];

  const handleMeasureConfiguration = (value) => {
    navigate(`/shop-products/measure-configuration/${value._id}`, {
      state: value,
    });
  };

  const MENU_OPTIONS = [
    {
      label: "Measurement Configuration",
      icon: "akar-icons:edit",
      handleClick: handleMeasureConfiguration,
    },
  ];
  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("shop_filter_data");

    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      if (filter_data.search_text) {
        setSearchText(filter_data.search_text);
      }
    }
    setFilterData(filter_data);
    setFilterStateUpdated(filter_data);
    getProductsList(filter_data);
  }, [page, rowsPerPage]);

  useEffect(() => {
    getProductCategories();
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <div className="container-fluid">
        <div className="row mb-5 mt-1">
          <div className="col-lg-8 col-sm-12">
            <h2>Shop Products List</h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            <MUIAutocomplete
              inputLabel="Categories"
              size="small"
              selectedOption={filterData.category_id}
              setSelectedOption={(e) => {
                handleChangeOthers(e, "category_id");
              }}
              optionsList={filterList}
              name="title"
            />
          </div>
        </div>
        <FilteredChip
          data={filterStateUpdated}
          tempState={filterData}
          EMPTY_FILTER={EMPTY_FILTER}
          onDeleteChip={handleDeleteChip}
          onClear={handleClearFilter}
        />
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={productsList}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
      </div>
    </>
  );
}
