import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { usePGIMode } from "src/Hooks/PGIModeContext";

export default function NotifyUser({
  badgesArray,
  setBadgesArray,
  badge_object,
}) {
  const { badgeLevels } = usePGIMode();

  const handleAddBadge = () => {
    setBadgesArray((old_array) => [...old_array, badge_object]);
  };

  const handleRemoveBadge = (index) => {
    const updatedItems = [...badgesArray];
    updatedItems.splice(index, 1);
    setBadgesArray(updatedItems);
  };

  const handleChangeNotification = (event, index) => {
    const { name, value } = event.target;
    let temp_state = [...badgesArray];
    let temp_element = { ...temp_state[index] };
    temp_element[name] = value;
    temp_state[index] = temp_element;
    setBadgesArray(temp_state);
  };

  return (
    <div className="container">
      <div className="notification_contnet_setting setting-card mt-3">
        <h4 className="notification_contnet ms-1 mb-4">Notify User</h4>
        {badgesArray.length > 0 &&
          badgesArray.map((badge, index) => {
            return (
              <div className="row mt-3">
                <div className="col-11">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <FormControl fullWidth required>
                        <InputLabel id="demo-simple-select-label">
                          Select Badge Level
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="badge_level_id"
                          label="Select Badge Level"
                          value={badge.badge_level_id || ""}
                          onChange={(e) => {
                            handleChangeNotification(e, index);
                          }}
                        >
                          {badgeLevels
                            .filter(
                              (item) =>
                                item._id === badge.badge_level_id || // Always include the selected badge
                                !badgesArray.some(
                                  (b, i) =>
                                    i !== index && b.badge_level_id === item._id
                                ) // Hide if selected in other dropdowns
                            )
                            .map((item, idx) => (
                              <MenuItem value={item._id} key={idx}>
                                {item.title}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-12 col-md-6 mt-3 mt-md-0">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Membership Status
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="membership_status"
                          value={badge?.membership_status}
                          label="Membership Status"
                          onChange={(e) => {
                            handleChangeNotification(e, index);
                          }}
                        >
                          <MenuItem value={"all"}>All</MenuItem>
                          <MenuItem value={"active"}>Active</MenuItem>
                          <MenuItem value={"expire"}>Expire</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div className="col-1 duplicate-icon-box">
                  {badgesArray.length !== badgeLevels.length && (
                    <AddCircleOutlineIcon
                      className="duplicate-icon mb-2"
                      onClick={handleAddBadge}
                    />
                  )}
                  {badgesArray.length !== 1 && (
                    <RemoveCircleOutlineIcon
                      className="duplicate-icon"
                      onClick={() => handleRemoveBadge(index)}
                    />
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
