import React from "react";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import Label from "src/components/Label";
import { CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { DeletePaymentRequestApi } from "src/DAL/member/member";
import {
  change_status_payment_request_api,
  paymentRequestListApi,
} from "src/DAL/PaymentRequest/paymentRequestApi";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import PaymentRequestFilter from "./PaymentRequestFilter";
import {
  convertCurrencyToSign,
  dd_date_format,
  show_proper_words,
} from "src/utils/constant";
import FilteredChip from "src/components/FilteredChip";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import ChangePaymentRequestStatus from "./ChangePaymentRequestStatus";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const get_first_paid_status = (row) => {
  return (
    <>
      <Label
        variant="ghost"
        color={row.is_first_paid === false ? "error" : "success"}
      >
        {row.is_first_paid === true
          ? `Paid on ${dd_date_format(row.subscription_date)}`
          : row.payment_status == "cancelled"
          ? `Cancelled on ${dd_date_format(row.cancel_date)}`
          : show_proper_words(row.payment_status)}
      </Label>
    </>
  );
};

const get_is_basic_membership = (row) => {
  return (
    <>
      <Label
        variant="ghost"
        color={
          !row.is_basic_membership || !row.basic_membership_days
            ? "error"
            : "success"
        }
      >
        {row.basic_membership_days && row.is_basic_membership
          ? `Membership Days ${row.basic_membership_days}`
          : "No"}
      </Label>
    </>
  );
};

export default function PaymentRequestList() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [openChangePopup, setOpenChangePopup] = useState(false);
  const [openChangePopupCancelle, setOpenChangeCancelle] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [searchText, setSearchText] = useState("");

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const EMPTY_FILTER = {
    type: "all",
    sortBy: "all",
    id: null,
  };

  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [updateFilterData, setUpdateFilterData] = useState(EMPTY_FILTER);

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getMemberListing(filterData);
    localStorage.setItem("payment_request_data", JSON.stringify(filterData));
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);
    if (data.type !== "consultant") {
      data.id = null;
    }
    setFilterData(data);
    getMemberListing(data);
    localStorage.setItem("payment_request_data", JSON.stringify(data));
  };

  const handleClearFilter = () => {
    setFilterData(EMPTY_FILTER);
    getMemberListing(EMPTY_FILTER);
    localStorage.removeItem("payment_request_data");
  };

  const handleSelectOther = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const getMemberListing = async (filter_data) => {
    setIsLoading(true);
    let postData = { ...filter_data };

    if (postData.id) {
      postData.id = filter_data.id._id;
    } else {
      postData.id = "";
    }

    let search_keyword =
      localStorage.getItem("paymentReq_search_text") == null
        ? searchText
        : localStorage.getItem("paymentReq_search_text");
    if (search_keyword !== null) {
      setSearchText(search_keyword);
    }
    handleCloseFilterDrawer();
    const result = await paymentRequestListApi(
      page,
      rowsPerPage,
      postData,
      search_keyword
    );
    if (result.code === 200) {
      setIsLoading(false);
      setTotalCount(result.total_payment_request_count);
      let chipData = { ...filter_data };
      if (chipData.type !== "consultant") {
        delete chipData.id;
      }
      setUpdateFilterData(chipData);

      const data = result.payment_request.map((billing, index) => {
        let member_name = "N/A";
        let product_name = "N/A";
        let lead_status_title = "N/A";
        let consider_purchasing_user = "N/A";
        let initial_amount = 0;
        let installment_amount = 0;
        let month = 0;

        if (billing?.lead_status) {
          lead_status_title = billing?.lead_status?.title;
        }

        if (billing?.consider_purchasing_user) {
          consider_purchasing_user = billing?.consider_purchasing_user;
        }

        let payment_template_name = "N/A";
        if (billing.member_info) {
          member_name =
            billing.member_info.first_name +
            " " +
            billing.member_info.last_name +
            " (" +
            billing.member_info.email +
            ")";
        }
        if (billing.product) {
          product_name = billing.product.name;
        }
        if (billing.payment_template) {
          payment_template_name = billing.payment_template.title;
        }
        if (billing.request_type == "recurring") {
          initial_amount = billing.initial_amount;
          installment_amount = billing.installment_amount;
          month = billing.month;
        }

        let actionBy =
          billing.action_by == "admin_user"
            ? "Admin (" +
              billing.action_info?.name +
              " | " +
              billing.action_info?.email +
              ")"
            : billing.action_by == "consultant_user"
            ? "Delegate (" +
              billing.action_info?.name +
              " | " +
              billing.action_info?.email +
              ")"
            : "N/A";

        return {
          ...billing,
          lead_status_title: lead_status_title,
          consider_purchasing_user_type: consider_purchasing_user,
          member_name,
          product_name,
          payment_template_name,
          month,
          total_amount_name:
            convertCurrencyToSign(billing.currency) + billing.total_amount,
          initial_amount_name:
            convertCurrencyToSign(billing.currency) + initial_amount,
          installment_amount_name:
            convertCurrencyToSign(billing.currency) + installment_amount,
          actionBy,
          member_id: billing?.member?._id,
          created_at: billing.createdAt
            ? dd_date_format(billing.createdAt)
            : "N/A",
          MENU_OPTIONS: handleMenu(billing),
        };
      });
      setUserList(data);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangeRoute = (route, data) => {
    delete data.MENU_OPTIONS;
    navigate(route, { state: data });
  };

  const handleQuestAccess = (value) => {
    let route = `/payment-request/quest-access/${value._id}`;
    handleChangeRoute(route, value);
  };

  const handleMissionAccess = (value) => {
    let route = `/payment-request/mission-access/${value._id}`;
    handleChangeRoute(route, value);
  };

  const handleEdit = (value) => {
    let route = `/payment-request/edit-payment-request/${value.payment_request_slug}`;
    handleChangeRoute(route, value);
  };
  const handleOpenChangeStatusPopUp = (value) => {
    setOpenChangePopup(true);
    setSelectedObject(value);
  };
  const handleOpenChangeStatusCancle = (value) => {
    setOpenChangeCancelle(true);
    setSelectedObject(value);
  };

  const handleBilling = (value) => {
    let route = `/payment-request/payment-request-detail/${value.payment_request_slug}`;
    handleChangeRoute(route, value);
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await DeletePaymentRequestApi(
      deleteDoc.payment_request_slug
    );
    if (result.code === 200) {
      getMemberListing(filterData);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleChangeStatus = async () => {
    setIsLoading(true);
    let postData = {
      type: "cancelled",
      payment_request_slug: selectedObject.payment_request_slug,
    };

    const result = await change_status_payment_request_api(postData);

    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
      setOpenChangeCancelle(false);
      getMemberListing(filterData);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleNavigate = () => {
    navigate(`/payment-request/add-payment-request`);
  };

  const handleAgreementConfig = (value) => {
    let route = `/payment-request/payment-agreement-config/${value._id}`;
    handleChangeRoute(route, value);
  };
  const handlecommissionConfiguration = (value) => {
    let route = `/payment-request/commission-configuration/${value._id}`;
    handleChangeRoute(route, value);
  };
  const handlemanageProgramAccess = (value) => {
    let route = `/payment-request/payment-program-access/${value._id}`;
    handleChangeRoute(route, value);
  };
  const handlemanageEventAccess = (value) => {
    let route = `/payment-request/payment-event-access/${value._id}`;
    handleChangeRoute(route, value);
  };

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("payment_request_data");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
    }
    setFilterData(filter_data);
    setUpdateFilterData(filter_data);
    getMemberListing(filter_data);
  }, [page, rowsPerPage]);

  useEffect(() => {
    localStorage.setItem("paymentReq_search_text", searchText);
    if (searchText.length < 1) {
      localStorage.setItem("paymentReq_search_text", "");
    } else {
      localStorage.setItem("paymentReq_search_text", searchText);
    }
  }, [searchText]);

  const handleMenu = (row) => {
    console.log(row.request_type, "----row");
    const MENU_OPTIONS = [
      {
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: handleAgreeDelete,
      },
      {
        label: "View Detail",
        icon: "akar-icons:eye",
        handleClick: handleBilling,
      },
      {
        label: "Commission Configuration",
        icon: "akar-icons:edit",
        handleClick: handlecommissionConfiguration,
      },
      {
        label: " Agreement Configuration",
        icon: "akar-icons:edit",
        handleClick: handleAgreementConfig,
      },
      {
        label: "Manage Programme Access",
        icon: "akar-icons:edit",
        handleClick: handlemanageProgramAccess,
      },
      {
        label: "Manage Event Access",
        icon: "akar-icons:edit",
        handleClick: handlemanageEventAccess,
      },
      {
        label: "Manage Mission Access",
        icon: "ant-design:key",
        handleClick: handleMissionAccess,
      },
      {
        label: "Manage Quest Access",
        icon: "ant-design:key",
        handleClick: handleQuestAccess,
      },
    ];
    if (row.is_first_paid === false) {
      MENU_OPTIONS.unshift({
        label: "Edit",
        icon: "akar-icons:edit",
        handleClick: handleEdit,
      });
      if (row.request_type === "onetime" && row.payment_status === "pending") {
        MENU_OPTIONS.push({
          label: "Mark Request As paid",
          icon: "akar-icons:edit",
          handleClick: handleOpenChangeStatusPopUp,
        });
      }
      if (row.payment_status === "pending") {
        MENU_OPTIONS.push({
          label: "Mark Request As Cancelled",
          icon: "akar-icons:edit",
          handleClick: handleOpenChangeStatusCancle,
        });
      }
    }

    return MENU_OPTIONS;
  };

  const TABLE_HEAD = [
    {
      id: "action",
      label: "Action",
      type: "action",
      MENU_OPTIONS: "MENU_OPTIONS",
    },
    { id: "number", label: "#", type: "number" },
    {
      id: "member_name",
      label: "Member",
      renderData: (row) => {
        return (
          <Tooltip title="View Detail" placement="top" arrow>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => handleBilling(row)}
            >
              {row.member_name}
            </span>
          </Tooltip>
        );
      },
    },
    { id: "request_title", label: "Request Title" },
    { id: "product_name", label: "Product" },
    { id: "payment_template_name", label: "Payment Template" },
    { id: "request_type", label: "Request Type", className: "text-capitalize" },
    {
      id: "consider_purchasing_user_type",
      label: "Consider Purchasing User",
      className: "text-capitalize",
    },
    { id: "lead_status_title", label: "Lead Status Title" },
    { id: "total_amount_name", label: "Total Amount" },
    { id: "initial_amount_name", label: "Initial Amount" },
    { id: "installment_amount_name", label: "Installment Amount" },
    { id: "month", label: "Month" },
    { id: "actionBy", label: "Action By" },
    {
      id: "firstPaid",
      label: "First Paid",
      renderData: (row) => get_first_paid_status(row),
    },
    {
      id: "is_basic_membership",
      label: "Is Basic Membership",
      renderData: (row) => get_is_basic_membership(row),
    },
    { id: "status", label: "Status", type: "row_status" },
    { id: "created_at", label: "Created At" },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />{" "}
      <CustomConfirmation
        open={openChangePopupCancelle}
        setOpen={setOpenChangeCancelle}
        title={"Are you sure you want to cancel this payment request?"}
        handleAgree={handleChangeStatus}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <h2>Payment Requests</h2>
          </div>
          <div className="col-lg-6 col-sm-12 text-end">
            <button
              className="small-contained-button me-2 mt-1 mb-4"
              onClick={handleOpenFilterDrawer}
            >
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>
            <button
              onClick={() => handleNavigate()}
              className="small-contained-button"
            >
              Add Payment Requests
            </button>
          </div>
        </div>
        <FilteredChip
          data={updateFilterData}
          tempState={filterData}
          EMPTY_FILTER={EMPTY_FILTER}
          onDeleteChip={handleDeleteChip}
          onClear={handleClearFilter}
        />
        <div className="row">
          <div className="col-12">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={userList}
              className="card-with-background"
              custom_pagination={{
                total_count: totalCount,
                rows_per_page: rowsPerPage,
                page: page,
                handleChangePage: handleChangePage,
                onRowsPerPageChange: handleChangeRowsPerPage,
              }}
              custom_search={{
                searchText: searchText,
                setSearchText: setSearchText,
                handleSubmit: searchFunction,
              }}
              pageCount={pageCount}
              totalPages={totalPages}
              handleChangePages={handleChangePages}
              pagePagination={true}
            />
          </div>
        </div>
      </div>
      <GeneralPopUpModel
        open={openChangePopup}
        setOpen={setOpenChangePopup}
        title={
          selectedObject?.request_title ? selectedObject?.request_title : ""
        }
        componentToPassDown={
          <ChangePaymentRequestStatus
            selectedObject={selectedObject}
            setOpenChangePopup={setOpenChangePopup}
            getMemberListing={getMemberListing}
            filterData={filterData}
          />
        }
      />
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <PaymentRequestFilter
            filterData={filterData}
            handleSelectOther={handleSelectOther}
            searchFunction={searchFunction}
            handleClearFilter={handleClearFilter}
          />
        }
      />
    </>
  );
}
