import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { s3baseUrl } from "src/config/config";
import {
  delete_category_api,
  help_video_categories_list_api,
} from "src/DAL/HelpVideos/Categories";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import PageLoader from "src/components/GeneralComponents/PageLoader";

// ----------------------------------------------------------------------

export default function HelpVideoCategories({ type }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [categoriesData, setCategoriesData] = useState([]);

  const getCategoriesListing = async () => {
    setIsLoading(true);
    const result = await help_video_categories_list_api(type);
    if (result.code == 200) {
      const categories = result.help_video_category.map((category) => {
        return {
          ...category,
          table_avatar: {
            src: s3baseUrl + category.icon.thumbnail_2,
            alt: category.title,
          },
          category_status: category.status,
        };
      });
      setCategoriesData(categories);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_category_api(
      deleteDoc.help_video_category_slug
    );
    if (result.code === 200) {
      setCategoriesData((categoriesData) => {
        return categoriesData.filter((data) => data._id !== deleteDoc._id);
      });
      getCategoriesListing();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleEdit = (value) => {
    if (type == "admin") {
      navigate(
        `/help-video-categories/edit-category/${value.help_video_category_slug}`,
        {
          state: value,
        }
      );
    }
    if (type == "delegate") {
      navigate(
        `/delegate-help-video-categories/help-video-categories/edit-category-delegate/${value.help_video_category_slug}`,
        {
          state: value,
        }
      );
    }
    if (type == "client") {
      navigate(
        `/client-help-video-categories/help-video-categories/edit-category-delegate/${value.help_video_category_slug}`,
        {
          state: value,
        }
      );
    }
  };

  const handleNavigate = () => {
    if (type == "admin") {
      navigate(`/help-video-categories/add-category`);
    }
    if (type == "delegate") {
      navigate(
        `/delegate-help-video-categories/help-video-categories/add-category-delegate`
      );
    }
    if (type == "client") {
      navigate(
        `/client-help-video-categories/help-video-categories/add-category-delegate`
      );
    }
  };
  const handleVideos = (value) => {
    if (type == "admin") {
      navigate(
        `/help-video-categories/help-videos/${value.type}/${value.help_video_category_slug}`,
        {
          state: value,
        }
      );
    }
    if (type == "delegate") {
      navigate(
        `/delegate-help-video-categories/help-videos/${value.type}/${value.help_video_category_slug}`,
        {
          state: value,
        }
      );
    }
    if (type == "client") {
      navigate(
        `/client-help-video-categories/help-videos/${value.type}/${value.help_video_category_slug}`,
        {
          state: value,
        }
      );
    }

    // navigate(`/help-videos`);
  };
  useEffect(() => {
    getCategoriesListing();
  }, [type]);
  const TABLE_HEAD = [
    { id: "action", label: "Action", alignRight: false, type: "action" },
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "title",
      label: "Title",
      alignRight: false,
      handleClick: handleVideos,
      className: "cursor-pointer",
    },
    { id: "table_avatar", label: "Icon", alignRight: false, type: "thumbnail" },
    { id: "order", label: "Order", alignRight: false },
    {
      id: "category_status",
      label: "Status",
      type: "row_status",
    },
  ];

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "Videos",
      icon: "bxs:videos",
      handleClick: handleVideos,
    },
  ];

  if (isLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this category?"}
        handleAgree={handleDelete}
      />

      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-lg-6 col-sm-12">
            <h2>Help Video Categories</h2>
          </div>
          <div className="col-lg-6 col-sm-12 text-end ">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Category
            </button>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={categoriesData}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          pagePagination={true}
        />
      </div>
    </>
  );
}
