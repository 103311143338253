import React from "react";
import {
  Button,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import { get_root_value } from "src/utils/domUtils";
import { Icon } from "@iconify/react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker, DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import { usePGIMode } from "src/Hooks/PGIModeContext";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { show_proper_words } from "src/utils/constant";

export default function MissionQuestFilter({
  filterData,
  handleChangeAll,
  handleChangeOthers,
  searchSubmitFilter,
  handleClearFilter,
  handleChangeBadgeLevels,
}) {
  const { badgeLevels } = usePGIMode();
  const mission_type = new URLSearchParams(location.search).get("type");

  let badge_levels_array = badgeLevels.map((level) => {
    return {
      ...level,
      chip_label: level.title,
      chip_value: level._id,
    };
  });

  return (
    <div className="container new-memories">
      <form className="row" onSubmit={searchSubmitFilter}>
        <div className="col-lg-12 mt-3">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              {show_proper_words(mission_type)} Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="mission_status"
              value={filterData?.mission_status}
              label={`${show_proper_words(mission_type)} Status`}
              onChange={handleChangeOthers}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="completed">Completed</MenuItem>
              <MenuItem value="in_progress">In Progress</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="col-lg-12 mt-3">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Filter Members by Badge Level
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="badge_type"
              value={filterData?.badge_type}
              label={"Filter Members by Badge Level"}
              onChange={handleChangeOthers}
            >
              <MenuItem value="current">Member's Current Badge Level</MenuItem>
              <MenuItem value="accept_time">
                Acceptance Time User Badge Level
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        {filterData?.badge_type && (
          <div className="mt-3">
            <MUIAutocomplete
              inputLabel={
                filterData?.badge_type == "current"
                  ? "User Cuurent Badge Levels"
                  : "Acceptance Time User Badge Level"
              }
              selectedOption={filterData?.badge_levels ?? []}
              setSelectedOption={handleChangeBadgeLevels}
              optionsList={badge_levels_array}
              multiple
              name="title"
            />
          </div>
        )}

        <FormControlLabel
          className="mt-2"
          control={
            <Checkbox
              checked={filterData?.start_date_range}
              onChange={(e) => {
                handleChangeAll(e.target.checked, "start_date_range");
              }}
            />
          }
          label="Search By Start Date Range"
        />
        {filterData?.start_date_range && (
          <div className="col-12">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Start Date From"
                name="from_start_date"
                value={filterData.from_start_date}
                format="YYYY-MM-DD"
                inputFormat="dd-MM-yyyy"
                onChange={(e) => handleChangeAll(e, "from_start_date")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="mt-3 inputs-fields"
                    required={filterData.to_start_date}
                  />
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Start Date To"
                name="to_start_date"
                value={filterData.to_start_date}
                format="YYYY-MM-DD"
                inputFormat="dd-MM-yyyy"
                onChange={(e) => handleChangeAll(e, "to_start_date")}
                minDate={filterData?.from_start_date}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="mt-3 inputs-fields"
                    required={filterData?.from_start_date}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
        )}

        {mission_type == "quest" && (
          <FormControlLabel
            className="mt-2"
            control={
              <Checkbox
                checked={filterData?.end_date_range}
                onChange={(e) => {
                  handleChangeAll(e.target.checked, "end_date_range");
                }}
              />
            }
            label="Search By End Date Range"
          />
        )}

        {filterData?.end_date_range && mission_type == "quest" && (
          <div className="col-12">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="End Date From"
                name="from_end_date"
                value={filterData.from_end_date}
                format="YYYY-MM-DD"
                inputFormat="dd-MM-yyyy"
                onChange={(e) => handleChangeAll(e, "from_end_date")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="mt-3 inputs-fields"
                    required={filterData.to_end_date}
                  />
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="End Date To"
                name="to_end_date"
                value={filterData.to_end_date}
                format="YYYY-MM-DD"
                inputFormat="dd-MM-yyyy"
                onChange={(e) => handleChangeAll(e, "to_end_date")}
                minDate={filterData?.from_end_date}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="mt-3 inputs-fields"
                    required={filterData?.from_end_date}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
        )}

        <FormControlLabel
          className="mt-2"
          control={
            <Checkbox
              checked={filterData.coins_range}
              onChange={(e) => {
                handleChangeAll(e.target.checked, "coins_range");
              }}
            />
          }
          label="Search By Attracted Coins"
        />

        {filterData?.coins_range && (
          <>
            <div className="col-6">
              <TextField
                className="mt-3 inputs-fields"
                type="number"
                id="coins_from"
                label="Coins From"
                variant="outlined"
                name="coins_from"
                value={filterData?.coins_from}
                required
                InputProps={{ inputProps: { min: 0 } }}
                onChange={handleChangeOthers}
              />
            </div>
            <div className="col-6">
              <TextField
                className="mt-3 inputs-fields"
                type="number"
                id="coins_to"
                label="Coins To"
                variant="outlined"
                name="coins_to"
                value={filterData?.coins_to}
                required
                InputProps={{
                  inputProps: { min: filterData.coins_from },
                }}
                onChange={handleChangeOthers}
              />
            </div>
          </>
        )}

        <div className="col-12">
          <Box sx={{ py: 2 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              color="inherit"
              variant="outlined"
              startIcon={
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginRight: 5 }}
                >
                  <path
                    d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                    fill={get_root_value("--portal-theme-primary")}
                  />
                </svg>
              }
            >
              Filter
            </Button>
          </Box>
        </div>
        <div className="col-12">
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            className="mb-3"
            onClick={handleClearFilter}
            startIcon={<Icon icon={roundClearAll} />}
          >
            Clear All
          </Button>
        </div>
      </form>
    </div>
  );
}
