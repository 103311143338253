import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import { urlPatternValidation } from "src/utils/constant";
import { useEffect } from "react";
import { s3baseUrl } from "src/config/config";
import {
  AddStudySessionRecordingApi,
  studySessionActiveListing,
  study_session_recording_detail,
  UpdateStudySessionRecordingApi,
} from "src/DAL/studySession/studySessionApi";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const Input = styled("input")({
  display: "none",
});

export default function AddOrUpdateSessionRecordings() {
  const navigate = useNavigate();
  const { recording_slug } = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [file, setProfileImage] = useState();
  const [audioFile, setAudio] = useState();
  const [sessionsList, setSessionsList] = useState([]);
  const [formType, setFormType] = useState("ADD");
  const [oldImage, setOldImage] = useState("");
  const [oldAudio, setOldAudio] = React.useState();

  const [inputs, setInputs] = useState({
    title: "",
    zoomLink: "",
    status: true,
    program: "",
    image: {},
    password: "",
    areaCode: "",
    video_url: "",
    groups: [],
    members: [],
    short_description: "",
    detailed_description: "",
    study_session_slug: null,
  });

  const getSessionsList = async () => {
    const result = await studySessionActiveListing();
    if (result.code === 200) {
      const sessionsData = result.study_session;
      if (!recording_slug) {
        setInputs((values) => ({
          ...values,
          study_session_slug: sessionsData[0],
        }));
      }
      setSessionsList(sessionsData);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleFormatData = (data) => {
    setFormType("EDIT");
    setInputs({
      ...data,
      study_session_slug: data.study_session ? data.study_session : "",
    });
    setOldImage(data.session_recording_images.thumbnail_2);
    if (data.audio_recording) {
      setOldAudio(data.audio_recording);
    }
    getSessionsList();
    window.history.replaceState({}, document.title);
  };

  const get_recording_detail = async () => {
    let result = await study_session_recording_detail(recording_slug);
    if (result.code == 200) {
      handleFormatData(result.study_session_recording);
    } else {
      navigate(`/session-recording`);
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };

  const audioFileChange = (e) => {
    setAudio(e.target.files[0]);
  };

  const handldeDeleteAudio = () => {
    setAudio();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      inputs.video_url !== "" &&
      urlPatternValidation(inputs.video_url) === false
    ) {
      enqueueSnackbar("Enter Valid Video URL", { variant: "error" });
    } else {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("title", inputs.title);
      formData.append("short_description", inputs.short_description);
      formData.append("detailed_description", inputs.detailed_description);
      formData.append("status", inputs.status);
      formData.append("video_url", inputs.video_url);
      formData.append(
        "study_session_slug",
        inputs.study_session_slug.study_session_slug
      );

      if (audioFile) {
        formData.append("audio_file", audioFile);
      }

      if (formType === "EDIT") {
        if (file) {
          formData.append("image", inputs.image);
        }
      } else {
        formData.append("image", inputs.image);
      }

      const result =
        formType === "ADD"
          ? await AddStudySessionRecordingApi(formData)
          : await UpdateStudySessionRecordingApi(formData, recording_slug);
      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        navigate(`/session-recording`);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeSession = (value) => {
    setInputs((values) => ({ ...values, study_session_slug: value }));
  };

  useEffect(() => {
    if (recording_slug) {
      if (state) {
        handleFormatData(state);
      } else {
        get_recording_detail();
      }
    } else {
      getSessionsList();
    }
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(`/session-recording`)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2 className="ms-2">{`${
            formType === "ADD" ? "Add" : "Edit"
          } Study Session Recording`}</h2>
        </div>
      </div>

      <form className="row" onSubmit={handleSubmit}>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Recording Title "
            variant="outlined"
            fullWidth
            name="title"
            value={inputs.title}
            required
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Video Url"
            variant="outlined"
            fullWidth
            type="url"
            name="video_url"
            value={inputs.video_url}
            onChange={handleChange}
          />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Recording Status *
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="status"
              value={inputs.status}
              label="Recording Status *"
              onChange={handleChange}
            >
              <MenuItem value="true">Active</MenuItem>
              <MenuItem value="false">Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <MUIAutocomplete
            inputLabel="Study Session"
            selectedOption={inputs.study_session_slug}
            setSelectedOption={handleChangeSession}
            optionsList={sessionsList}
            name="title"
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0 ">
            <div className="col-lg-7 col-md-5 col-sm-12">
              <p className="">Upload Audio</p>
              <FormHelperText className="pt-0">
                Audio mp3 (max 200mb)
              </FormHelperText>
            </div>
            <div className="col-4 col-md-2">
              {audioFile && (
                <DeleteIcon
                  onClick={handldeDeleteAudio}
                  className="mt-3 icon-color"
                />
              )}
            </div>
            <div className="col-lg-3 col-md-5 col-8 text-end pt-2 ps-0">
              <label htmlFor="audio">
                <Input
                  accept="audio/mp3,audio/*;capture=microphone"
                  id="audio"
                  multiple
                  name="audio"
                  type="file"
                  onChange={audioFileChange}
                />
                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          <div className="col-12 mt-2">
            {oldAudio && (
              <audio className="w-100" src={s3baseUrl + oldAudio} controls />
            )}
          </div>
          <p className="text-secondary">{audioFile && audioFile.name}</p>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Upload Image *</p>
              <FormHelperText className="pt-0">
                Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-2">
              {file ? (
                <img src={file} height="50" />
              ) : (
                oldImage && <img src={s3baseUrl + oldImage} height="50" />
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  name="image"
                  onChange={fileChangedHandler}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          {inputs.image?.name == "" ? (
            ""
          ) : (
            <p className="text-secondary">{inputs.image?.name}</p>
          )}
        </div>

        <div className="col-12 mt-5">
          <FormControl fullWidth>
            <TextField
              id="outlined-multiline-flexible"
              label="Short Description"
              multiline
              rows={6}
              name="short_description"
              value={inputs.short_description}
              onChange={handleChange}
              required
            />
          </FormControl>
        </div>
        <div className="col-12 mt-4">
          <h4>Detailed Description</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="detailed_description"
            editorHeight={320}
          />
        </div>

        <div
          className="text-end mt-4"
          id={formType == "ADD" ? "" : "fixedbutton"}
        >
          <button className="small-contained-button">
            {formType == "ADD" ? "Submit" : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
