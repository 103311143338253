import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  road_Delete_Api,
  _bonus_listApi,
} from "src/DAL/BonusRoadMAp/BonusRoadMApApi";
import { s3baseUrl } from "src/config/config";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import StripeSubscriptionList from "./StripeSubscriptionList";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false, type: "action" },
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "title", label: "Title", alignRight: false },
  {
    id: "table_avatar",
    label: "Reached Status",
    alignRight: false,
    type: "thumbnail",
  },
  {
    id: "without_border_icon",
    label: "Before Status",
    alignRight: false,
    type: "thumbnail",
  },
  { id: "coins", label: "Coins", alignRight: false },
  { id: "order", label: "order", alignRight: false },
  { id: "status", label: "Status", alignRight: false, type: "row_status" },
];

const RenderStripeSubscriptions = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteValue, setDeleteValue] = useState("");
  let selected_index = 0;

  const [tabValue, setTabValue] = useState(selected_index);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleAgreeDelete = (value) => {
    setDeleteValue(value._id);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await road_Delete_Api(deleteValue);
    if (result.code === 200) {
      setIsLoading(false);

      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  console.log(state, "state");

  if (isLoading) {
    return <PageLoader />;
  }

  const TABS_OPTIONS = [
    {
      title: "All",
      component: <StripeSubscriptionList status="all" state={state} />,
    },
    {
      title: "Active",
      component: <StripeSubscriptionList status="active" state={state} />,
    },
    {
      title: "Canceled",
      component: <StripeSubscriptionList status="canceled" state={state} />,
    },
  ];
  let breadCrumbMenu = [
    {
      title: state
        ? state?.first_name + " " + state?.last_name + " (" + state.email + ")"
        : "Member",
      navigation: -1,
      active: false,
    },
    {
      title: "Stripe Subscriptions",
      active: true,
    },
  ];
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container-fluid">
        {state?.email ? (
          <div className="row">
            <div className="col-12 display-flex mb-4">
              <span>
                <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
              </span>
            </div>
          </div>
        ) : (
          <div className="row mb-4">
            <div className="col-lg-8 col-sm-12">
              <h2>Subscriptions</h2>
            </div>
          </div>
        )}

        <MUICustomTabs
          data={TABS_OPTIONS}
          value={tabValue}
          handleChange={handleChange}
        />
      </div>
    </>
  );
};

export default RenderStripeSubscriptions;
