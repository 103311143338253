import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Avatar, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import { delete_lead_status } from "src/DAL/SaleSections/LeadStatus";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import EditSchedule from "./EditSchedule";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import {
  delete_mission_schadule_list,
  get_mission_scadule_list,
} from "src/DAL/MissionApi/MissionApiNew";
import { show_proper_words } from "src/utils/constant";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const daysArray = [
  {
    title: "Day 1",
    description: "Introduction to the topic and overview of objectives.",
    No_of_start_days: 1,
    No_of_end_days: 5,
  },
  {
    title: "Day 2",
    description: "Deep dive into key concepts with examples.",
    No_of_start_days: 2,
    No_of_end_days: 4,
  },
  {
    title: "Day 3",
    description: "Hands-on exercises and practical applications.",
    No_of_start_days: 3,
    No_of_end_days: 3,
  },
  {
    title: "Day 4",
    description: "Group discussions and collaborative projects.",
    No_of_start_days: 4,
    No_of_end_days: 2,
  },
  {
    title: "Day 5",
    description: "Review, Q&A, and conclusion of the module.",
    No_of_start_days: 5,
    No_of_end_days: 1,
  },
];

export default function SchedulesList() {
  const navigate = useNavigate();
  const [userList, setUserList] = useState(daysArray);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [referredpop, setReferredpop] = useState(false);
  const [levelsArray, setLevelsArray] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedObject, setSelectedObject] = useState(null);
  const params = useParams();

  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState("");
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const [previousInfo, setPreviousInfo] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageCount(1);
    setPage(0);
  };

  const searchFunction = (e) => {
    if (e) {
      e.preventDefault();
    }
    setPage(0);
    setPageCount(1);
    localStorage.setItem("mission_schedule", searchText);

    getSaleLeadList(searchText);
  };

  const getSaleLeadList = async (text) => {
    let postData = {
      search_text: text ? text : "",
    };
    const result = await get_mission_scadule_list(
      postData,
      params.id,
      page,
      rowsPerPage
    );
    if (result.code === 200) {
      setPreviousInfo(result?.mission_info);
      const lead_status = result.mission_schedule.map((status) => {
        let default_lock = "Yes";
        if (status.is_locked === false) {
          default_lock = "No";
        }

        return {
          ...status,
          default_lock,
          title: status.title,
          table_avatar: {
            src: s3baseUrl + status.image?.thumbnail_1,
            alt: status.title,
          },
        };
      });
      setUserList(lead_status);
      setTotalPages(result.total_pages);
      setTotalCount(result.total_number_count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleEdit = (value) => {
    const newValue = {
      ...previousInfo,
      ...value,
    };
    navigate(
      `/mission/Challenges/schedule/addOrUpdate/${params.id}/${value._id}`,
      {
        state: newValue,
      }
    );
  };
  const handlesetting = (value) => {
    const newValue = {
      ...value,
      type: previousInfo?.type,
    };

    navigate(
      `/mission/Challenges/schedule/mission-settings/${params.id}/${value._id}`,
      {
        state: newValue,
      }
    );
  };
  const handleManageScadule = (value) => {
    navigate(
      `/mission/Challenges/schedule/manage-scadule/${params.id}/${value._id}`,
      {
        state: value,
      }
    );
  };

  const handleNotificationsSettings = (value) => {
    navigate(
      `/mission/Challenges/schedule/notifications-settings/${params.id}/${value._id}`,
      {
        state: value,
      }
    );
  };
  const handleManageQuestions = (value) => {
    navigate(
      `/mission/challenges/schedules/manage-schedules-questions/${value._id}`,
      {
        state: value,
      }
    );
  };

  const handleschedules = (value) => {
    navigate(`/mission/challenge/schedules/vedios/${value._id}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await delete_mission_schadule_list(deleteDoc._id);
    if (result.code === 200) {
      getSaleLeadList();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleNavigate = () => {
    let newValue = {
      ...previousInfo,
    };
    navigate(`/mission/Challenges/schedule/addOrUpdate/${params.id}`, {
      state: newValue,
    });
  };

  const handleLessonsQuestions = (value) => {
    let newValue = {
      ...previousInfo,
      ...value,
      vedio_id: params.id,
    };
    navigate(`/mission/challenge/schedules/?created_for_id=${value._id}`, {
      state: newValue,
    });
  };
  const handleQuestionsConfiguration = (value) => {
    navigate(
      `/mission/Challenges/schedule/manage-question-settings/${params.id}/${value._id}`,
      {
        state: value,
      }
    );
  };

  let child_menu_options = [
    {
      label: "Questions Setting",
      icon: "akar-icons:edit",
      handleClick: handleQuestionsConfiguration,
    },
    {
      label: "Manage Questions",
      icon: "akar-icons:edit",
      handleClick: handleLessonsQuestions,
    },
  ];

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
      color: "#da3030",
    },
    {
      label: `${show_proper_words(previousInfo?.type)} Schedule Settings`,
      icon: "akar-icons:edit",
      handleClick: handlesetting,
    },
    {
      label: `${show_proper_words(previousInfo?.type)} Schedule Actions`,
      icon: "akar-icons:edit",
      handleClick: handleManageScadule,
    },
    {
      label: "Notifications Settings",
      icon: "akar-icons:edit",
      handleClick: handleNotificationsSettings,
    },
    {
      label: "Manage Schedule Questions",
      icon: "akar-icons:edit",
      handleClick: handleManageQuestions,
    },
    // {
    //   label: "Questions Configuration",
    //   icon: "akar-icons:edit",
    //   child_options: child_menu_options,
    // },
  ];

  const handleOPen = (value) => {
    setReferredpop(true);
    setLevelsArray(value);
  };
  const TABLE_HEAD = [
    { id: "action", label: "Action", alignRight: false, type: "action" },
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "table_avatar",
      label: "Image",
      alignRight: false,
      type: "thumbnail",
    },
    {
      id: "schedule_image",
      label: "Schedule icon",
      renderData: (row) => {
        return (
          <Avatar src={s3baseUrl + row?.icon?.thumbnail_1} alt={row?.title} />
        );
      },
    },
    {
      id: "title",
      label: "Tab Title",
      alignRight: false,
    },
    {
      id: "main_heading",
      label: "Main Heading",
      alignRight: false,
    },
    {
      id: "type",
      label: "Type",
      renderData: (row) => {
        return <span className="text-capitalize">{previousInfo.type}</span>;
      },
    },
    {
      id: "reward_coins",
      label: "Rewarded Coins",
      alignRight: false,
    },
    {
      id: "number_of_start_days",
      label: "No. Of Start Days",
      alignRight: false,
    },
    {
      id: "number_of_end_days",
      label: "No. Of End Days",
      alignRight: false,
    },
    { id: "default_lock", label: "Default Lock" },
    {
      id: "order",
      label: "Order",
      alignRight: false,
    },
    { id: "status", label: "Status", alignRight: false, type: "row_status" },
  ];
  if (previousInfo?.type === "quest") {
    TABLE_HEAD.splice(6, 0, {
      id: "is_chat_allowed",
      label: "Chat Allowed",
      renderData: (row) => {
        return <span>{row.is_chat_enabled ? "Yes" : "No"}</span>;
      },
    });
  }

  if (previousInfo?.type === "mission") {
    TABLE_HEAD.splice(
      6,
      0,
      {
        id: "is_chat_allowed",
        label: "Chat Allowed",
        renderData: (row) => {
          return <span>{row.is_chat_allowed ? "Yes" : "No"}</span>;
        },
      },

      {
        id: "coins_to_unlock",
        label: "Coins To Unlock",
        alignRight: false,
      }
    );
  }

  let breadCrumbMenu = [
    // {
    //   title: `Mission Levels (${previousInfo?.mission_lavel_info?.title})`,
    //   navigation: `/mission`,
    //   active: false,
    // },
    {
      title: `${previousInfo?.type == "quest" ? "Quest" : "Mission"} (${
        previousInfo?.title
      })`,
      // navigation: `/mission/challenge/${previousInfo?.mission_lavel_info?._id}`,
      navigation: `/mission`,
      active: false,
    },
    {
      title: `${previousInfo?.type == "quest" ? "Quest" : "Mission"} Schedule`,
      navigation: ``,
      active: true,
    },
  ];
  useEffect(() => {
    let search_text = localStorage.getItem("mission_schedule");
    if (search_text) {
      setSearchText(search_text);
      getSaleLeadList(search_text);
    } else {
      setSearchText("");
      getSaleLeadList("");
    }
  }, [page, rowsPerPage]);

  if (isLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="container">
        <div className="row mb-4">
          {previousInfo && (
            <div className="col-12 mb-4">
              <span>
                <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
              </span>
            </div>
          )}
          <div className="col-lg-8 col-sm-12">
            <h2>
              {previousInfo?.type == "quest" ? "Quest" : "Mission"} Schedule
            </h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            <button onClick={handleNavigate} className="small-contained-button">
              Add {previousInfo?.type == "quest" ? "Quest" : "Mission"} Schedule
            </button>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={userList}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          pagePagination={true}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
        />
        <GeneralPopUpModel
          open={open}
          setOpen={setOpen}
          title={"Edit Schedule"}
          componentToPassDown={
            <EditSchedule
              verifyTransaction={selectedObject}
              setChangeStatus={setOpen}
            />
          }
        />
      </div>
    </>
  );
}
