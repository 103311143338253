import { invokeApi } from "../../bl_libs/invokeApi";

export const adminUserList = async (page, limit, search) => {
  const requestObj = {
    path: `/admin_users/get_admin_users`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const Requested_csv_files_api = async () => {
  const requestObj = {
    path: `/csv_file_requests/list_csv_file_requests`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const productCategoryDetailApi = async (slug) => {
  const requestObj = {
    path: `/api/product_category/product_category_detail/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const ReminderUSerList = async (page, limit, data) => {
  const requestObj = {
    path: `/api/event/welcome_events/list?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const AdminNavItems = async (data) => {
  const requestObj = {
    path: `/admin_users/nav_items`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const adminChangePasswordApi = async (data, id) => {
  const requestObj = {
    path: `/admin_users/admin_change_password/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editAdminUser = async (data, id) => {
  const requestObj = {
    path: `/admin_users/update_admin_user/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editReminderUser = async (data, id) => {
  const requestObj = {
    path: `/api/event/welcome_event/update/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const ADDReminderUser = async (data) => {
  const requestObj = {
    path: `/api/event/welcome_reminder/add`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const affirmationCategoryAddApi = async (data) => {
  const requestObj = {
    path: `/api/general_category`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const affirmationCategoryEditApi = async (data, id) => {
  const requestObj = {
    path: `/api/general_category/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const stripe_reader_listing = async (id) => {
  const requestObj = {
    path: `/api/stripe_reader/list_active_stripe_reader/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const manage_reader_access_for_admin = async (data) => {
  const requestObj = {
    path: `/api/stripe_reader/manage_reader_access_for_admin`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

// mission new start

export const addMission = async (data) => {
  const requestObj = {
    path: `/api/mission_level/add_mission_level`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addMissionLevel = async (data) => {
  const requestObj = {
    path: `/api/mission/add_mission`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const road_map_levels_list_api_mission = async () => {
  const requestObj = {
    path: `/api/road_map_levels`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const badges_list_api_mission = async () => {
  const requestObj = {
    path: `/api/badge/list_active_badges`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const level_list_api_mission = async () => {
  const requestObj = {
    path: `/api/road_map_levels/active_road_map_levels`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const get_mission_level_list = async (data, page, limit) => {
  const requestObj = {
    path: `/api/mission_level/list_mission_level?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const get_mission_list = async (data, page, limit) => {
  const requestObj = {
    // path: `/api/mission/list_mission?page=${page}&limit=${limit}`,
    path: `/api/mission/list_mission/v1?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _get_create_mission_branch_code = async (id) => {
  const requestObj = {
    path: `/api/mission/create/mission_branch_code/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const delete_mission_level_list = async (id) => {
  const requestObj = {
    path: `/api/mission_level/delete_mission_level/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const delete_mission_list = async (id) => {
  const requestObj = {
    path: `/api/mission/delete_mission/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const duplicate_mission_list = async (id) => {
  const requestObj = {
    path: `/api/mission/duplicate_mission_and_quest/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const UPdate_mission_Notification_settings = async (data, id) => {
  const requestObj = {
    path: `/api/mission/notifications/update/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const UPdate_mission_start_Notification_settings = async (data) => {
  const requestObj = {
    path: `/api/content_setting/update/start_mission_reminder_settings`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const UPdate_mission_level_list = async (data, id) => {
  const requestObj = {
    path: `/api/mission_level/update_mission_level/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const UPdate_mission_list = async (data, id) => {
  const requestObj = {
    path: `/api/mission/update_mission/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const addMissionScadule = async (data) => {
  const requestObj = {
    path: `/api/mission_schedule/add_mission_schedule`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      // "Content-Type": "multipart/form-data",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const get_mission_scadule_list = async (data, id, page, limit) => {
  const requestObj = {
    path: `/api/mission_schedule/list_mission_schedule/${id}?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const mission_schedule_detail_for_questions = async (id) => {
  const requestObj = {
    path: `/api/mission_schedule/mission_schedule_detail_for_questions/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const question_configuration = async (postData) => {
  const requestObj = {
    path: `/api/mission_schedule/update_questiion_config`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const update_questiion_selection_api = async (postData) => {
  const requestObj = {
    path: `/api/mission_schedule/update_questiion_selection`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};
export const _update_question_selection_v2 = async (postData) => {
  const requestObj = {
    path: `/api/mission_schedule/update_question_selection_v2`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const update_questiion_config_api = async (postData, id) => {
  const requestObj = {
    path: `/api/mission/update_questiion_config/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const mission_schedule_detail_api = async (id) => {
  const requestObj = {
    path: `/api/mission_schedule/mission_schedule_detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const mission_detail_api = async (id) => {
  const requestObj = {
    path: `/api/mission/mission_detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const UPdate_mission_scadule_list = async (data, id) => {
  const requestObj = {
    path: `/api/mission_schedule/update_mission_schedule/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const UPdate_mission_scadule_list_list = async (data) => {
  const requestObj = {
    path: `/api/mission_schedule/update_allowed_actions`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const UPdate_mission_scadule_notification_list = async (data, id) => {
  const requestObj = {
    path: `/api/mission_schedule/notifications/add/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_mission_schadule_list = async (id) => {
  const requestObj = {
    path: `/api/mission_schedule/delete_mission_schedule/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _mission_schadule_setting_list = async (data, id) => {
  const requestObj = {
    path: `/api/mission_schedule/update_content_settings/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _mission_setting_list = async (data, id) => {
  const requestObj = {
    path: `/api/mission/update_content_settings/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const delete_mission_schadule_vedio_list = async (id) => {
  const requestObj = {
    path: `/api/mission_schedule_video/delete_mission_schedule_video/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const addMissionScaduleVedios = async (data) => {
  const requestObj = {
    path: `/api/mission_schedule_video/add_mission_schedule_video`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const get_mission_scadule_vedios_list = async (
  data,
  id,
  page,
  limit
) => {
  const requestObj = {
    path: `/api/mission_schedule_video/list_mission_schedule_video/${id}?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const UPdate_mission_scadule_vedio_list = async (data, id) => {
  const requestObj = {
    path: `/api/mission_schedule_video/update_mission_schedule_video/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const get_mission_members_list = async (data) => {
  const { mission_id, page, rowsPerPage, text } = data;
  const requestObj = {
    path: `/api/mission/members_list/${mission_id}?page=${page}&limit=${rowsPerPage}&search_text=${text}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_mission_badge_list = async (data) => {
  const requestObj = {
    path: `/api/mission/badge_configration/${data}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const update_mission_badge_list = async (data, mission_id) => {
  const requestObj = {
    path: `/api/mission/update_badge_configration/${mission_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_mission_book_Call_button_settings_list = async (
  data,
  mission_id
) => {
  const requestObj = {
    path: `/api/mission/update_book_a_call_settings/${mission_id}?book_a_call_settings=book_a_call_settings`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_mission_upgrade_button_settings_list = async (
  data,
  mission_id
) => {
  const requestObj = {
    path: `/api/mission/update_book_a_call_settings/${mission_id}?type=upgrade_settings`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const manage_badges_to_unlock_api = async (data) => {
  const requestObj = {
    path: `/api/mission/manage_badges_to_unlock`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const mission_days_and_badges = async (data) => {
  const requestObj = {
    path: `/api/mission/membership_levels_and_schedules/list`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

// Manage Membership Level ==============================================
export const _get_membership_levels_mission_detail = async (id) => {
  const requestObj = {
    path: `/api/mission/membership_levels/get`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _update_membership_levels_mission = async (data, id) => {
  const requestObj = {
    path: `/api/mission/membership_levels/update/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _get_book_a_call_sale_pages = async (data) => {
  const requestObj = {
    path: `/api/sale_page/book_a_call/sale_pages`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _get_list_active_badge_levels = async () => {
  const requestObj = {
    path: `/api/membership_level_badge/list_active_badge_levels`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _update_payment_configuration = async (data, id) => {
  const requestObj = {
    path: `/api/mission/update_payment_configuration/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _get_mission_members_list_v2 = async (id, page, limit, data) => {
  const requestObj = {
    path: `/api/mission/members_list/v2/${id}?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _get_module_manage_mission_access = async (id, data) => {
  const requestObj = {
    path: `/api/payment_plan/detail_module/missions/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_module_manage_mission_access = async (id, data) => {
  const requestObj = {
    path: `/api/payment_plan/update_module/missions/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_module_manage_quests_access = async (id, data) => {
  const requestObj = {
    path: `/api/payment_plan/update_module/quests/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _send_mission_reminder = async (data) => {
  const requestObj = {
    path: `/api/mission/send_mission_reminder`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _get_notification_detail = async (id) => {
  const requestObj = {
    path: `/api/mission/get_notification_detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
