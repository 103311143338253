import { invokeApi } from "../../bl_libs/invokeApi";
export const paymentPlansListBySalePageApi = async (id) => {
  const requestObj = {
    path: `/api/payment_plan/list_payment_plan_by_sale_page/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const paymentPlansListApi = async (id) => {
  let api_path = `/api/payment_plan/list_payment_plan`;
  if (id) {
    api_path = `/api/payment_plan/list_payment_plan_by_sale_page/${id}`;
  }
  const requestObj = {
    path: api_path,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const detailPaymentPlanApi = async (id) => {
  const requestObj = {
    path: `/api/payment_plan/detail_payment_plan/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const detailPaymentPlanConfigurationApi = async (id) => {
  const requestObj = {
    path: `/api/payment_plan/get_commission_info/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const editPaymentPlanApi = async (data, id) => {
  const requestObj = {
    path: `/api/payment_plan/edit_payment_plan/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addPaymentPlanApi = async (data) => {
  const requestObj = {
    path: `/api/payment_plan/add_payment_plan`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deletePaymentPlanApi = async (id) => {
  const requestObj = {
    path: `/api/payment_plan/delete_payment_plan/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const member_listing_by_page_plan = async (
  page,
  limit,
  search,
  data
) => {
  const requestObj = {
    path: `/api/event_subscriber/list_subscriber_with/plan_id/page_id?page=${page}&limit=${limit}&search_text=${
      search == undefined || search == "" ? "" : search
    }`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const programme_list_with_plan_api = async (plan_id) => {
  const requestObj = {
    path: `/api/payment_plan/payment_plan_detail_with_products/${
      plan_id ? plan_id : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const options_plan_api = async (plan_id) => {
  const requestObj = {
    path: `/api/payment_plan/detail/plan_options/${plan_id ? plan_id : ""}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const Badge_list_api = async (road_map_level) => {
  const requestObj = {
    path: `/api/road_map_levels/badge_configration/detail/${road_map_level}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const Badge_list_api_new_add = async () => {
  const requestObj = {
    path: `/api/badge/list_badges_for_selection`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const product_list_with_plan_api = async (plan_id) => {
  const requestObj = {
    path: `/api/payment_plan/get_client_programs_for_plan/${
      plan_id ? plan_id : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const program_event_list_with_plan_api = async (plan_id, type) => {
  const requestObj = {
    path: `/api/payment_template/get_programs_and_event_data/${
      plan_id ? plan_id : ""
    }?list_data_type=${type}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const country_list = async () => {
  const requestObj = {
    path: `/api/default_setting/get/currencies`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const program_event_list_with_plan_api_request = async (
  plan_id,
  type
) => {
  const requestObj = {
    path: `/api/payment_request/get_programs_and_event_data/${
      plan_id ? plan_id : ""
    }?list_data_type=${type}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const update_porgrams_for_plan_api = async (plan_id, data) => {
  const requestObj = {
    path: `/api/payment_plan/update_porgrams_for_plan/${plan_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_currency = async (data) => {
  const requestObj = {
    path: `/api/default_setting/manage/currencies`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_porgrams_for_request_api = async (plan_id, data, type) => {
  const requestObj = {
    path: `/api/payment_template/update_event_or_program_data/${plan_id}?update_type=${type}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_porgrams_for_api = async (plan_id, data, type) => {
  const requestObj = {
    path: `/api/payment_request/update_event_or_program_data/${plan_id}?update_type=${type}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_poduct_for_plan_api = async (plan_id, data) => {
  const requestObj = {
    path: `/api/payment_plan/update_dynamite_products_for_plan/${plan_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_options_for_plan_api = async (plan_id, data) => {
  const requestObj = {
    path: `/api/payment_plan/update/plan_options/${plan_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_Badge_config_api = async (plan_id, data) => {
  const requestObj = {
    path: `/api/road_map_levels/manage_badge/configration/${plan_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const get_protal_list_for_plan_api = async (plan_id) => {
  const requestObj = {
    path: `/api/payment_plan/get_protal_list_for_plan/${
      plan_id ? plan_id : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const update_portals_for_plan_api = async (plan_id, data) => {
  const requestObj = {
    path: `/api/payment_plan/update_portals_for_plan/${plan_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_Badge_config_api_new = async (plan_id, data) => {
  const requestObj = {
    path: `/api/membership_level_pyramid/manage_badge/configration/${plan_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const bonus_content_category_list = async (plan_id) => {
  const requestObj = {
    path: `/api/payment_plan/bonus_content_category/get/${plan_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const update_bonus_content_category_api = async (plan_id, data) => {
  const requestObj = {
    path: `/api/payment_plan/bonus_content_category/update/${plan_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const Badge_list_api_new = async (road_map_level) => {
  const requestObj = {
    path: `/api/membership_level_pyramid/badge_configration/detail/${road_map_level}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
};

export const email_notification_sequence_api = async (data, plan_id) => {
  const requestObj = {
    path: `/api/payment_plan/email_notification_sequence/update/${plan_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const duplicate_payment_plan_api = async (id) => {
  const requestObj = {
    path: `/api/payment_plan/duplicate/payment_plan/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_quest_missions_for_plan_api = async (plan_id) => {
  const requestObj = {
    path: `/api/payment_plan/detail_payment_plan/quest_missions/${
      plan_id || ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const update_quest_missions_for_plan_api = async (plan_id, data) => {
  const requestObj = {
    path: `/api/payment_plan/manage_payment_plan/quest_missions/${plan_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
