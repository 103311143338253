import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import GeneralCkeditor from "../GeneralComponents/GeneralCkeditor";

export default function GpsSetting(props) {
  const { handleSubmit, inputs, handleChange, setInputs } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12 col-md-6 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label9">
              Show on Mission Control
            </InputLabel>
            <Select
              labelId="demo-simple-select-label9"
              id="demo-simple-select"
              name="show_setting"
              required
              value={inputs.show_setting}
              label="Show on Mission Control"
              onChange={handleChange}
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
        </div>

        {inputs.show_setting && (
          <>
            <div className="col-12 col-md-6 mt-4">
              <TextField
                id="outlined-basic"
                label="Number Of Times To Show on Mission Control"
                variant="outlined"
                fullWidth
                required
                type="number"
                name="number_of_views"
                value={inputs.number_of_views}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 col-md-6 mt-4">
              <TextField
                id="outlined-basic"
                label="Event Portal Button Text"
                variant="outlined"
                fullWidth
                name="event_portal_text"
                value={inputs.event_portal_text}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 col-md-6 mt-4">
              <TextField
                id="outlined-basic"
                label="Event Portal Button Link"
                variant="outlined"
                fullWidth
                name="event_portal_link"
                value={inputs.event_portal_link}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 col-md-6 mt-4">
              <TextField
                id="outlined-basic"
                label="Download App Button Text"
                variant="outlined"
                fullWidth
                name="download_app_text"
                value={inputs.download_app_text}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 col-md-6 mt-4">
              <TextField
                id="outlined-basic"
                label="Download App Button Link"
                variant="outlined"
                fullWidth
                name="download_app_link"
                value={inputs.download_app_link}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 col-md-6 mt-4">
              <TextField
                id="outlined-basic"
                type="color"
                label="Buttons Text Color"
                variant="outlined"
                fullWidth
                name="button_text_color"
                value={inputs.button_text_color}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 col-md-6 mt-4">
              <TextField
                id="outlined-basic"
                type="color"
                label="Buttons Background Color"
                variant="outlined"
                fullWidth
                name="button_bg_color"
                value={inputs.button_bg_color}
                onChange={handleChange}
              />
            </div>

            <div className="col-12 mt-4">
              <h4>Banner Description</h4>
              <GeneralCkeditor
                name="banner_description"
                setInputs={setInputs}
                inputs={inputs}
                editorHeight={320}
              />
            </div>
            <div className="col-12 mt-4">
              <h4>Video Description</h4>
              <GeneralCkeditor
                name="video_description"
                setInputs={setInputs}
                inputs={inputs}
                editorHeight={320}
              />
            </div>
            <div className="col-12 mt-4">
              <h4>Event Schedule Description</h4>
              <GeneralCkeditor
                name="event_schedule_description"
                setInputs={setInputs}
                inputs={inputs}
                editorHeight={320}
              />
            </div>
          </>
        )}
      </div>
      <div className="text-end mt-4" id="fixedbutton">
        <button className="small-contained-button">Submit</button>
      </div>
    </form>
  );
}
