import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { list_member_replies_against_member } from "src/DAL/GeneralQuestions/GeneralQuestions";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import moment from "moment";
import { replace_created_for } from "src/utils/constant";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import PageLoader from "src/components/GeneralComponents/PageLoader";

export default function QuestionsAnswersForUser({ user_type }) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isLoading, setIsLoading] = useState(true);
  const [answersData, setAnswersData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [useInfo, setuseInfo] = useState();
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const member_id = new URLSearchParams(location.search).get("member_id");

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPageCount(1);
  };

  const getAnswersListing = async () => {
    setIsLoading(true);
    const result = await list_member_replies_against_member(
      page,
      rowsPerPage,
      member_id,
      user_type
    );
    if (result.code == 200) {
      const answers = result?.questionnaire_list?.map((answer, index) => {
        return {
          ...answer,
          title: answer?.created_for_id?.title
            ? answer?.created_for_id?.title
            : "N/A",
          answered_date: moment(answer.reply_date).format("DD-MM-YYYY"),
          created_for_string: replace_created_for(answer.created_for),
        };
      });
      setAnswersData(answers);
      setuseInfo(result.delegate);
      setTotalCount(result.toal_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleNavigate = (value) => {
    let created_for_id = "";
    if (value.created_for_id?._id) {
      created_for_id = value.created_for_id._id;
    }

    let check_user = false;
    if (value.created_for == "self_image") {
      check_user = true;
    }

    navigate(
      `${window.location.pathname}/answer-detail?member_id=${member_id}&created_for_id=${created_for_id}&created_for=${value.created_for}&check_user=${check_user}`
    );
  };

  const MENU_OPTIONS = [
    {
      label: "Answers Detail",
      icon: "akar-icons:edit",
      handleClick: handleNavigate,
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  useEffect(() => {
    getAnswersListing();
  }, [rowsPerPage, page]);

  const TABLE_HEAD = [
    { id: "action", label: "Action", alignRight: false, type: "action" },
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "created_for_string",
      label: "Questions Created For",
      alignRight: false,
      className: "text-capitalize cursor-pointer",
      handleClick: handleNavigate,
    },
    { id: "title", label: "Module Title", alignRight: false },
    {
      id: "answered_date",
      label: "Answered Date",
      calignRight: false,
    },
  ];

  let breadCrumbMenu = [
    {
      title: "Delegates",
      navigation: -1,
      active: false,
    },
    {
      title:
        useInfo?.first_name +
        " " +
        useInfo?.last_name +
        " (" +
        useInfo?.email +
        ")",
      active: true,
    },
  ];

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          {user_type == "delegate" ? (
            <div className="col-12 mb-3">
              <span>
                <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
              </span>
            </div>
          ) : (
            <div className="col-12 display-flex">
              <IconButton
                className="back-screen-button"
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon />
              </IconButton>
            </div>
          )}
          <div className="col-lg-8 col-sm-8 mb-3">
            <h2>Questions Answers List</h2>
          </div>
        </div>

        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={answersData}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
      </div>
    </>
  );
}
