import { Avatar } from "@mui/material";
import React from "react";

const CustomAvatarColorCode = ({ alt, src, colorCode }) => {
  return (
    <>
      <Avatar
        className="avatar-adjust"
        sx={{
          width: 40,
          height: 40,
          border: `3px solid ${colorCode}`,
        }}
        alt={alt}
        src={src}
      />
    </>
  );
};

export default CustomAvatarColorCode;
