import React, { useState } from "react";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import WhatsappPopUpModel from "src/components/GeneralComponents/WhatsappPopUpModel";
import AddOrUpdateManageRewards from "./AddOrUpdateManageRewards";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { useParams } from "react-router-dom";
import { _remove_member_badge } from "src/DAL/member/member";
import { s3baseUrl } from "src/config/config";
import { useSnackbar } from "notistack";
import { Avatar } from "@mui/material";

const MyReward = ({
  tabData,
  badges,
  setBadges,
  totalBadges,
  setTotalBadges,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const member_id = useParams();
  const [openManageRewards, setOpenManageRewards] = useState(false);
  const [deleteDoc, setDeleteDoc] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);

  let TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    {
      id: "title",
      label: "Reward",
      renderData: (row) => {
        return (
          <div className="d-flex ">
            <div className="d-flex align-items-center">
              <Avatar
                src={s3baseUrl + row?.after_earned_icon?.thumbnail_2}
                alt=""
                className="me-2 badge-image"
              />
              <span className="badge-title">{row?.title}</span>
            </div>
            <span className="badges-count ms-2">{row?.no_of_badges}x</span>
          </div>
        );
      },
    },
  ];

  const openManageRewardsPopup = (value) => {
    setOpenManageRewards(true);
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    let postData = {
      member_id: member_id?.id,
      badge_id: deleteDoc?._id,
    };
    const result = await _remove_member_badge(postData);

    if (result.code === 200) {
      let historyData = [result?.history, ...totalBadges];
      const newData = result?.badges.map((badge) => {
        return {
          ...badge,
          table_avatar: {
            src: s3baseUrl + badge?.after_earned_icon?.thumbnail_1,
            alt: badge.title,
          },
        };
      });

      setTotalBadges(historyData);
      setBadges(newData ?? []);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleMenu = (row) => {
    const MENU_OPTIONS = [];

    MENU_OPTIONS.push({
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
      color: "#da3030",
    });

    return MENU_OPTIONS;
  };

  return (
    <>
      <CustomConfirmation
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete this reward ?"}
        handleAgree={handleDelete}
      />

      <div className=" text-end d-flex justify-content-end mb-3">
        <div>
          <button
            onClick={() => openManageRewardsPopup()}
            className="small-contained-button"
          >
            Manage Rewards
          </button>
        </div>
      </div>

      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        data={badges}
        className="card-with-background"
        hide_search={true}
        pagePagination={false}
        is_hide={true}
        hide_footer_pagination={true}
        handle_menus={handleMenu}
      />

      <WhatsappPopUpModel
        open={openManageRewards}
        setOpen={setOpenManageRewards}
        title={"Manage Rewards"}
        className="rewards_popup"
        titleClassName="reward-title"
        componentToPassDown={
          <>
            <AddOrUpdateManageRewards
              tabData={tabData}
              setBadges={setBadges}
              data={badges}
              setOpenManageRewards={setOpenManageRewards}
              totalBadges={totalBadges}
              setTotalBadges={setTotalBadges}
            />
          </>
        }
      />
    </>
  );
};

export default MyReward;
