import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { project_mode } from "src/config/config";
import { memberDetailProfileApi } from "src/DAL/member/member";
import { useSnackbar } from "notistack";
import { dd_date_format, show_proper_words } from "src/utils/constant";
import moment from "moment";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import MemberTransactionsFilter from "./MemberTransactionsFilter";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import MemberTabOptions from "src/components/MembersList/MemberTabOptions";
import MemberTopStats from "src/components/MembersList/MemberTopStats";
import MemberBasicInfoCard from "src/components/MembersList/MemberBasicInfoCard";
import PageLoader from "src/components/GeneralComponents/PageLoader";

let status_object = [
  "programmes",
  "events",
  "transactions",
  "subscriptions",
  "member_subscriptions",
  "booking_list",
  "badge_level_history",
  "bonus_content_category",
  "rewards",
  "groups",
  "calendar_events",
  "pods",
  "diary",
  "client_notes",
  "to_do_tracker",
  "question_answers",
  "nav_items",
  "coins_history",
  "order_history",
  "badges_history",
  "resource_access_history",
  "sales",
  "members",
  "challenge",
  "welcome_reminder",
  "ninty_day_plan",
  "self_image_history",
  "login_sessions",
  "missions_and_quests",
];

const ALTER_FILTER = {
  transaction_status: "",
  payment_made_by: "",
};

const EMPTY_FILTER = {
  start_date: null,
  end_date: null,
  date: null,
  payment_made_by: "all",
  sale_page: null,
  payment_plan: null,
  filter_by_plateform: null,
  payment_request: [],
  transaction_type: "all",
  transaction_for: "all",
  user_id: null,
  transaction_status: "succeeded",
  transaction_mode: project_mode == "dev" ? ["sandbox"] : ["live"],
};

const transaction_filter = "member_payment_transactions_filter";

const handle_transaction_filter = (filter_data) => {
  const postData = { ...filter_data };
  if (postData.transaction_type == "sale_page") {
    postData.sale_page = filter_data.sale_page
      ? [filter_data.sale_page._id]
      : [];
  } else {
    postData.sale_page = [];
  }

  if (postData.payment_plan) {
    postData.payment_plan = filter_data.payment_plan._id;
  }

  if (postData.transaction_type == "payment_request") {
    postData.payment_request = filter_data.payment_request.map((item) => {
      return item._id;
    });
  }

  if (postData.start_date) {
    postData.start_date = moment(filter_data.start_date).format("YYYY-MM-DD");
  }

  if (postData.end_date) {
    postData.end_date = moment(filter_data.end_date).format("YYYY-MM-DD");
  }

  if (postData.filter_by_plateform) {
    postData.filter_by_plateform = filter_data.filter_by_plateform.chip_value;
  }

  if (postData.transaction_type !== "sale_page") {
    postData.sale_page = null;
    postData.payment_plan = null;
  }
  if (postData.transaction_type !== "payment_request") {
    postData.payment_request = [];
  }
  if (postData.payment_made_by == "all") {
    postData.payment_made_by = "";
  }

  if (postData.transaction_for == "delegates") {
    postData.user_type = "delegate_user";
  } else if (postData.transaction_for == "public") {
    postData.user_type = "public_user";
  } else {
    postData.user_type = postData.transaction_for;
  }

  if (postData.user_id) {
    postData.user_id = postData.user_id?._id;
  }
  return postData;
};

const MemberProfileNew = () => {
  const { state } = useLocation();
  const member_id = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isTabLoading, setIsTabLoading] = useState(false);
  const [memberDetail, setMemberDetail] = useState({});
  const [cardDetail, setCardDetail] = useState({});
  const [tabValue, setTabValue] = useState(0);
  const [tabData, setTabData] = useState([]);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [isAllDates, setIsAllDates] = useState(false);
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [filterStateUpdated, setFilterStateUpdated] = useState(EMPTY_FILTER);
  const [defaultTimeZone, setDefaultTimeZone] = useState("");
  const [value, setValue] = React.useState("1");
  // ------------------------ filter start
  const searchFunction = () => {
    if (filterData.transaction_for === "all") {
      filterData.user_id = null;
    }
    memberProfileInfo(filterData);
    localStorage.setItem(transaction_filter, JSON.stringify(filterData));
    handleCloseFilterDrawer();
  };

  const handleChangeOthers = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const handleClearFilter = () => {
    let clear_data = {
      ...EMPTY_FILTER,
      transaction_mode: [],
      transaction_status: "",
    };
    setFilterData(clear_data);
    memberProfileInfo(clear_data);
    localStorage.removeItem(transaction_filter);
    handleCloseFilterDrawer();
  };

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };

  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const handleDeleteChip = (data) => {
    if (!data.date) {
      delete data.date;
      data.start_date = null;
      data.end_date = null;
      setIsAllDates(false);
    }
    if (data.transaction_for === "all") {
      data.user_id = null;
    }
    memberProfileInfo(data);
    setFilterData(data);
    setFilterStateUpdated(data);
    localStorage.setItem(transaction_filter, JSON.stringify(data));
  };

  // --------------------------end
  const memberProfileInfo = async (filter_data) => {
    setIsTabLoading(true);
    let tab_name = status_object[tabValue];

    if (tab_name == "notification_schedules") {
      setIsLoading(false);
      setIsTabLoading(false);
      return;
    }

    if (tab_name == "programmes") {
      tab_name = "";
    }
    let postData = {};
    if (tab_name == "transactions") {
      postData = handle_transaction_filter(filter_data);
    } else if (tab_name == "sales") {
      postData.type = value == "1" ? "credit" : "paid";
    }
    const result = await memberDetailProfileApi(
      member_id.id,
      tab_name,
      postData
    );
    if (result.code == 200) {
      let chipData = {};
      if (tab_name == "transactions") {
        chipData = { ...filter_data };
        if (chipData.start_date) {
          let date = `Start Date : ${dd_date_format(
            chipData.start_date
          )} End Date :  ${dd_date_format(chipData.end_date)}`;
          chipData.date = {
            chip_label: date,
            chip_value: date,
          };
          delete chipData.start_date;
          delete chipData.end_date;
        }
        if (chipData.transaction_type == "all") {
          delete chipData.transaction_type;
        }
        if (chipData.transaction_for == "all") {
          delete chipData.transaction_for;
          delete chipData.user_id;
        }
        if (chipData.transaction_for == "public") {
          chipData.transaction_for = "Public User";
        }
        if (chipData.transaction_for == "delegates") {
          chipData.transaction_for = "Delegates User";
        }
        if (chipData.transaction_type !== "sale_page") {
          delete chipData.sale_page;
          delete chipData.payment_plan;
        }
        if (chipData.transaction_type !== "payment_request") {
          delete chipData.payment_request;
        }

        setFilterStateUpdated(chipData);
      }
      if (!tab_name) {
        setCardDetail(result.commission);
        setMemberDetail(result.member_info);
      }
      setTabData(result.tab_data);

      setDefaultTimeZone(result?.time_zone);
      setIsLoading(false);

      setIsTabLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsTabLoading(false);
    }
  };

  const handleChange = (event, newValue) => {
    setTabData([]);
    setTabValue(newValue);
  };

  const handleUpdateclientNote = (values) => {
    setTabData(values.personal_note);
  };

  useEffect(() => {
    if (tabValue !== 15) {
      if (tabValue == 2) {
        let filter_data = filterData;
        let find_filter = localStorage.getItem(transaction_filter);
        if (find_filter) {
          filter_data = JSON.parse(find_filter);
        }
        setFilterData(filter_data);
        setFilterStateUpdated(filter_data);
        memberProfileInfo(filter_data);
      } else {
        memberProfileInfo();
      }
    }
  }, [tabValue]);

  if (isLoading === true) {
    return <PageLoader />;
  }

  let breadCrumbMenu = [
    {
      title: state?.move_from_page_title
        ? show_proper_words(state?.move_from_page_title)
        : "Members",
      navigation: state?.move_from_page_path
        ? state?.move_from_page_path
        : `/member`,
      active: false,
    },
    {
      title:
        memberDetail?.first_name +
        " " +
        memberDetail?.last_name +
        " (" +
        memberDetail?.email +
        ")",
      active: true,
    },
  ];

  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col-12">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
        <div className="col-12 mt-3">
          <h2>Member Profile</h2>
        </div>
        <MemberTopStats cardDetail={cardDetail} />
        <MemberBasicInfoCard
          memberDetail={memberDetail}
          setIsLoading={setIsLoading}
          setMemberDetail={setMemberDetail}
        />
        <div className="col-12 mt-5">
          <MemberTabOptions
            tabValue={tabValue}
            handleChange={handleChange}
            isTabLoading={isTabLoading}
            tabData={tabData}
            memberDetail={memberDetail}
            filterStateUpdated={filterStateUpdated}
            filterData={filterData}
            EMPTY_FILTER={EMPTY_FILTER}
            ALTER_FILTER={ALTER_FILTER}
            handleDeleteChip={handleDeleteChip}
            handleClearFilter={handleClearFilter}
            handleOpenFilterDrawer={handleOpenFilterDrawer}
            memberProfileInfo={memberProfileInfo}
            defaultTimeZone={defaultTimeZone}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            handleUpdateclientNote={handleUpdateclientNote}
            setTabValue={setTabValue}
            status_object={status_object}
            setTabData={setTabData}
          />
        </div>
      </div>

      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <MemberTransactionsFilter
            filterData={filterData}
            isAllDates={isAllDates}
            setIsAllDates={setIsAllDates}
            handleClearFilter={handleClearFilter}
            handleChangeOthers={handleChangeOthers}
            searchSubmitFilter={searchFunction}
          />
        }
      />
    </div>
  );
};

export default MemberProfileNew;
