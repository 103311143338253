import { useEffect, useState } from "react";
import { Chip } from "@mui/material";
import { useSnackbar } from "notistack";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import AddOrUpdateStatus from "./AddOrUpdateStatus";
import {
  booking_status_list,
  delete_booking_status_api,
} from "src/DAL/booking/book_status";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import PageLoader from "src/components/GeneralComponents/PageLoader";

// ----------------------------------------------------------------------

export default function BookingStatus() {
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [formType, setFormType] = useState("ADD");
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [drawerState, setdrawerState] = useState(false);
  const [editData, setEditData] = useState({});
  const [bookingList, setBookingList] = useState([]);

  const getBookingStatusList = async () => {
    setIsLoading(true);
    const result = await booking_status_list();
    if (result.code == 200) {
      const transactions = result.booking_status?.map((transaction, index) => {
        return {
          ...transaction,

          MENU_OPTIONS: handleMenu(transaction),
        };
      });
      setBookingList(transactions);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_booking_status_api(deleteDoc._id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      getBookingStatusList();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleOpenDrawer = () => {
    setdrawerState(true);
  };

  const handleCloseDrawer = () => {
    setdrawerState(false);
    setFormType("ADD");
  };

  const handleOpenDrawerUpdate = (value) => {
    setFormType("EDIT");
    setEditData(value);
    setdrawerState(true);
  };
  const handleMenu = (row) => {
    const MENU_OPTIONS = [
      {
        label: "Edit",
        icon: "akar-icons:edit",
        handleClick: handleOpenDrawerUpdate,
      },
    ];
    if (row.status_type == "general") {
      MENU_OPTIONS.push({
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: handleAgreeDelete,
      });
    }

    return MENU_OPTIONS;
  };
  const TABLE_HEAD = [
    {
      id: "action",
      label: "Action",
      type: "action",
      MENU_OPTIONS: "MENU_OPTIONS",
    },
    { id: "number", label: "#", type: "number" },
    {
      id: "title",
      label: "Title",
      renderData: (row) => {
        console.log(row, "rowrowrowrowrow");
        if (row) {
          return (
            <Chip
              label={row?.title}
              variant="contained"
              className="px-3"
              style={{
                backgroundColor: row.background_color,
                color: row.text_color,
                height: "22px",
              }}
              size="small"
            />
          );
        }
      },
    },
    {
      id: "status_type",
      label: "Status Type",

      className: "text-capitalize",
    },
    { id: "order", label: "Order" },
    {
      id: "status",
      label: "Status",
      type: "row_status",
    },
  ];
  useEffect(() => {
    getBookingStatusList();
    setFormType("ADD");
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  let extra_filter = ["title"];

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this call type?"}
        handleAgree={handleDelete}
      />
      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-lg-6 col-sm-12">
            <h2>Booking Status</h2>
          </div>
          <div className="col-lg-6 col-sm-12 text-end ">
            <button
              onClick={handleOpenDrawer}
              className="small-contained-button"
            >
              Add Booking Status
            </button>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={bookingList}
          className="card-with-background"
          pagePagination={true}
          extra_filter={extra_filter}
        />
        <CustomDrawer
          isOpenDrawer={drawerState}
          onOpenDrawer={handleOpenDrawer}
          onCloseDrawer={handleCloseDrawer}
          pageTitle={`${formType === "ADD" ? "Add" : "Edit"} Booking Status`}
          componentToPassDown={
            <AddOrUpdateStatus
              handleCloseDrawer={handleCloseDrawer}
              getBookingStatusList={getBookingStatusList}
              formType={formType}
              editData={editData}
            />
          }
        />
      </div>
    </>
  );
}
