import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Stack from "@mui/material/Stack";
import { useEffect } from "react";
import {
  addAdminUSer,
  editReminderUser,
} from "src/DAL/AdminUserApi/AdminUserApi";
import TinyEditor from "src/components/ckeditor/Ckeditor";
import moment from "moment/moment";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function AddOrUpdateReminderUser() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [detailDescriptionCk, setDetailDescriptionCk] = useState("");
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    Status: false,
    notify_time: "00:00",
    date: null,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();

    let postData = {
      notify_date: moment(inputs.date).format("YYYY-MM-DD"),
      notify_time: inputs.notify_time,
      description: detailDescriptionCk,
    };
    const result = params.id
      ? await editReminderUser(postData, params.id)
      : await addAdminUSer(formData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(`/reminder-user`);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleChangeOthers = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    setInputs((prevState) => ({
      ...prevState,
      ["name"]: state.name,
      ["email"]: state.email,
      ["Status"]: state.is_notification_send,
      ["notify_time"]:
        state.notify_schedule.length > 0
          ? state.notify_schedule[0].notify_time
          : "00:00",
      ["date"]:
        state.notify_schedule.length > 0
          ? state.notify_schedule[0].notify_date
          : null,
    }));
    setDetailDescriptionCk(state?.description);
  }, [state]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>

          <h2 className="ms-1">
            {params?.id ? "Edit Reminder " : "Add Reminder "}
          </h2>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Name"
              variant="outlined"
              fullWidth
              required
              disabled
              name="name"
              value={inputs.name}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              fullWidth
              required
              type="email"
              disabled
              name="email"
              value={inputs.email}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="Status"
                value={inputs.Status}
                label="Status"
                disabled
                onChange={handleChange}
              >
                <MenuItem value={true}>Send</MenuItem>
                <MenuItem value={false}>Pending</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-6">
            <TextField
              fullWidth
              variant="outlined"
              id="time"
              label="Notify Time"
              type="time"
              required
              className="mt-3 inputs-fields"
              name="notify_time"
              value={inputs.notify_time}
              onChange={handleChange}
            />
          </div>
          <div className="col-6 mt-3">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack spacing={3}>
                <DesktopDatePicker
                  label="Notify Date"
                  inputFormat="dd-MM-yyyy"
                  name="date"
                  required
                  value={inputs.date}
                  onChange={(e) => {
                    handleChangeOthers("date", e);
                  }}
                  renderInput={(params) => <TextField required {...params} />}
                />
              </Stack>
            </LocalizationProvider>
          </div>
          <div className="col-12 mt-4">
            <h4>Reminder Message *</h4>
            <TinyEditor
              setDetailDescription={setDetailDescriptionCk}
              detailDescriptionCk={detailDescriptionCk}
            />
          </div>
          <div className="text-end mt-4" id={params?.id ? "fixedbutton" : ""}>
            <button className="small-contained-button">
              {params?.id ? "Update" : "Submit"}{" "}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
