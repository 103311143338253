import { invokeApi } from "../../bl_libs/invokeApi";

export const _update_attitude_accessment_setting = async (data) => {
  const requestObj = {
    path: `/api/attitude_assessment_question/assessment_setting`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _update_attitude_accessment_setting_v1 = async (data) => {
  const requestObj = {
    path: `/api/attitude_assessment_question/assessment_setting/update`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_badge_level_settings = async (data, badge_id) => {
  const requestObj = {
    path: `/api/membership_level_badge/update_badge_settings/${badge_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _get_attitude_accessment_setting = async () => {
  const requestObj = {
    path: `/api/attitude_assessment_question/assessment_setting`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_badge_level_setting = async (badge_id, type) => {
  const requestObj = {
    path: `/api/membership_level_badge/get_badge_settings/${badge_id}?type=${type}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _bedge_level_detail = async (id) => {
  const requestObj = {
    path: `/api/membership_level_badge/detail_membership_level_badge_configration/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _get_monthaly_setting = async () => {
  const requestObj = {
    path: `/api/default_setting/get/delegate_report_settings`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _get_website_cookie_setting = async (type) => {
  const requestObj = {
    path: `/api/content_setting/get_website_cockie_setting`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _get_store_house_setting = async () => {
  const requestObj = {
    path: `/api/default_setting/get/storehouse_settings`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _get_mission_popup_settings = async () => {
  const requestObj = {
    path: `/api/content_setting/get/mission_popup_setting`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _get_upgrade_popup_settings = async (id) => {
  const requestObj = {
    path: `/api/membership_level_badge/get_badge_settings/${id}?type=upgrade_popup_settings`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _get_book_a_call_popup_settings = async (id) => {
  const requestObj = {
    path: `/api/membership_level_badge/get_badge_settings/${id}?type=book_a_call_popup_settings`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
