import React from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useState } from "react";
import ImportCsvPopup from "./ImportCsvPopup";
import GeneralPopUpModel from "../GeneralComponents/GeneralPopUpModel";

export default function ImportCSV({ mission }) {
  const [inputs, setInputs] = useState({ start_date: mission.start_date });
  const [changeStatus, setChangeStatus] = useState(false);

  const fileChangedHandler = async (e) => {
    setInputs((old) => ({ ...old, csv: e.target.files[0] }));
    setChangeStatus(true);
  };

  return (
    <>
      <span className={`mb-1`}>
        <input
          color="primary"
          accept=".xlsx,.xls,.csv"
          type="file"
          id="csv-button-file"
          style={{ display: "none" }}
          onChange={(e) => fileChangedHandler(e)}
        />
        <label
          className={`small-contained-button me-2 mt-1 cursor-pointer float-end`}
          htmlFor="csv-button-file"
        >
          Import Csv &nbsp;&nbsp; <CloudUploadIcon />
        </label>
      </span>

      <GeneralPopUpModel
        open={changeStatus}
        setOpen={setChangeStatus}
        title={"Import Csv"}
        componentToPassDown={
          <ImportCsvPopup
            inputs={inputs}
            setOpen={setChangeStatus}
            setInputs={setInputs}
          />
        }
      />
    </>
  );
}
