import { useEffect, useState } from "react";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { useParams } from "react-router-dom";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import { delegate_other_transaction_list } from "src/DAL/consultant/consultant";
import { useSnackbar } from "notistack";
import moment from "moment";
import CircleIcon from "@mui/icons-material/Circle";
import { Typography, Tooltip } from "@mui/material";
import { htmlDecode } from "src/utils/convertHtml";
import { convertCurrencyToSign, show_proper_words } from "src/utils/constant";
import PageLoader from "src/components/GeneralComponents/PageLoader";

export default function RecantAssociatedTransactions() {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [listData, setListData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);

  const getConsultantListing = async () => {
    setIsLoading(true);

    let postData = {
      search_text: searchText,
    };

    const result = await delegate_other_transaction_list(
      id,
      page,
      rowsPerPage,
      postData
    );
    if (result.code == 200) {
      let data = result.transaction.map((item) => {
        let transaction = item.other_info;

        let transaction_amount = "N/A";
        let commission_amount = "N/A";
        let transactionMode = "N/A";
        let transactionDate = "N/A";
        let created_at = "N/A";

        if (transaction.amount) {
          transaction_amount =
            convertCurrencyToSign(transaction?.currency) + transaction.amount;
        } else {
          transaction_amount = convertCurrencyToSign(transaction?.currency) + 0;
        }

        if (transaction.referral_commission) {
          commission_amount =
            convertCurrencyToSign(transaction?.currency) +
            transaction.referral_commission;
        } else {
          commission_amount = convertCurrencyToSign(transaction?.currency) + 0;
        }

        if (transaction.transaction_mode) {
          transactionMode = show_proper_words(transaction.transaction_mode);
        }

        if (transaction.transaction_date) {
          transactionDate = moment(transaction?.transaction_date).format(
            "DD-MM-YYYY"
          );
        }
        if (transaction.createdAt) {
          created_at = moment(transaction?.createdAt).format("DD-MM-YYYY");
        }
        return {
          ...transaction,
          transaction_amount,
          transactionDate,
          transactionMode,
          commission_amount,
          created_at,
        };
      });

      setListData(data);
      setUserName(result.consultant);
      setTotalPages(result?.total_pages);
      setTotalCount(result?.total_member_count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getConsultantListing();
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "name",
      label: "Name",
      renderData: (row, i) => {
        return (
          <Tooltip
            key={i}
            title={
              row?.transaction_status === "succeeded" ? "Succeeded" : "Failed"
            }
            arrow
            className="anchor-style"
          >
            <Typography variant="subtitle2" noWrap>
              <CircleIcon
                className={`${
                  row?.transaction_status === "succeeded"
                    ? "Nurture_online"
                    : "support_notification_sidebar_page"
                }`}
              />
              {htmlDecode(
                row?.member_info
                  ? row?.member_info?.first_name +
                      " " +
                      row?.member_info?.last_name
                  : "N/A"
              )}
            </Typography>
          </Tooltip>
        );
      },
    },
    { id: "transaction_amount", label: "Amount" },
    { id: "commission_amount", label: "Commission Amount" },
    { id: "transactionMode", label: "Transaction Mode" },
    { id: "transactionDate", label: "Transaction Date" },
    { id: "created_at", label: "Updated At" },
  ];

  let breadCrumbMenu = [
    {
      title: "Delegates",
      navigation: -1,
      active: false,
    },
    {
      title: userName
        ? userName?.first_name +
          " " +
          userName?.last_name +
          " (" +
          userName.email +
          ")"
        : "Recant Associated Transactions",
      active: true,
    },
  ];

  useEffect(() => {
    getConsultantListing();
  }, [page, rowsPerPage]);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-12 mb-4">
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </div>
          <div className="col-6">
            <h2>Recent Associated Transactions</h2>
          </div>
        </div>

        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={listData}
          className="card-with-background"
          pagePagination={true}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
        />
      </div>
    </>
  );
}
