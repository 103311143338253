import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import {
  consultantGroupListingApi,
  DeleteConsultantGroupApi,
} from "src/DAL/consultant/consultant";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import MemberProgramPopover from "../MembersList/memberProgramPopOver";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import PageLoader from "src/components/GeneralComponents/PageLoader";

export default function ConsultantGroupsList() {
  const navigate = useNavigate();
  const params = useParams();
  const [useInfo, setuseInfo] = useState();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [program, setprogram] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const opens = Boolean(anchorEl);
  const id = opens ? "simple-popover" : undefined;

  const handleClickPopUP = (event, row) => {
    setprogram(row.program);
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopUp = () => {
    setAnchorEl(null);
  };

  const getConsultantListing = async () => {
    setIsLoading(true);
    const result = await consultantGroupListingApi(params.id);
    if (result.code === 200) {
      setuseInfo(result?.consultant);
      const data = result.group.map((group) => {
        return {
          ...group,
          member_count: group?.member.length,
        };
      });
      setUserList(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleViewDetail = (value) => {
    navigate(`/consultant/view-group-detail/${value.group_slug}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await DeleteConsultantGroupApi(deleteDoc.group_slug);
    if (result.code === 200) {
      getConsultantListing();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getConsultantListing();
  }, []);

  localStorage.setItem("consultant_group_id", params.id);

  const MENU_OPTIONS = [
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "View Detail",
      icon: "fluent:video-recording-20-regular",
      handleClick: handleViewDetail,
    },
  ];

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    { id: "title", label: " Name" },
    {
      id: "program",
      label: "Programme",
      renderData: (row) => {
        const firstFiveprograms = row.program?.slice(0, 5);
        return (
          <div>
            {firstFiveprograms?.length > 0 &&
              firstFiveprograms?.map((program, i) => {
                if (program._id !== null) {
                  return (
                    <>
                      <div key={i}>
                        <p>{program?._id?.title + ","}</p>
                      </div>
                      {i == 4 && (
                        <p
                          className="mb-1 mt-1 view-more"
                          aria-describedby={id}
                          variant="contained"
                          onClick={(e) => handleClickPopUP(e, row)}
                        >
                          view more
                        </p>
                      )}
                    </>
                  );
                }
              })}
          </div>
        );
      },
    },
    { id: "member_count", label: "Member" },
    { id: "status", label: "Status", type: "row_status" },
  ];

  let breadCrumbMenu = [
    {
      title: "Delegates",
      navigation: -1,
      active: false,
    },
    {
      title:
        useInfo?.first_name +
        " " +
        useInfo?.last_name +
        " (" +
        useInfo?.email +
        ")",
      active: true,
    },
  ];

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container-fluid">
        <div className="row">
          <div className="col-12 mb-3">
            <span>
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
          </div>
          <div className="col-lg-8 col-sm-12">
            <h2>Delegates Groups</h2>
          </div>
        </div>
        <div className="mt-3">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            MENU_OPTIONS={MENU_OPTIONS}
            data={userList}
            className="card-with-background"
            pagePagination={true}
          />
        </div>
        <MemberProgramPopover
          handleClick={handleClickPopUP}
          handleClose={handleClosePopUp}
          anchorEl={anchorEl}
          id={id}
          open={opens}
          program={program}
          navigatePage={true}
        />
      </div>
    </>
  );
}
