import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  del_template_configuration_api,
  get_template_configuration_api,
} from "src/DAL/WebsitePages/WebsitePages";
import { show_proper_words } from "src/utils/constant";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const TemplateConfig = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [categoriesData, setCategoriesData] = useState([]);
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState("");
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);

  const getTemplateListing = async (search) => {
    setIsLoading(true);
    const result = await get_template_configuration_api(
      page,
      rowsPerPage,
      search
    );
    if (result.code == 200) {
      setCategoriesData(result.Template_configuration);
      setTotalPages(result.total_pages);
      setTotalCount(result.total_count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageCount(1);
    setPage(0);
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    localStorage.setItem("template_search", searchText);
    getTemplateListing(searchText);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await del_template_configuration_api(
      deleteDoc?.template_slug
    );
    if (result.code === 200) {
      setCategoriesData((prev) =>
        prev.filter((item) => item.template_slug !== deleteDoc.template_slug)
      );
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleEdit = (value) => {
    navigate(`/template-configuration/edit-template/${value?.template_slug}`);
  };

  const handleNavigate = () => {
    navigate(`/template-configuration/add-template`);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    // {
    //   label: "Delete",
    //   icon: "ant-design:delete-twotone",
    //   handleClick: handleAgreeDelete,
    // },
  ];

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    {
      id: "template_name",
      label: "Template Name",
    },
    {
      id: "template_type",
      label: "Template Type",
      renderData: (row) => {
        return show_proper_words(row.template_type);
      },
    },
    {
      id: "template_status",
      label: "Status",
      type: "row_status",
    },
  ];

  useEffect(() => {
    let search_text = localStorage.getItem("template_search");
    if (search_text) {
      setSearchText(search_text);
      getTemplateListing(search_text);
    } else {
      setSearchText("");
      getTemplateListing("");
    }
  }, [page, rowsPerPage]);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-lg-6">
            <h2>Template Configuration</h2>
          </div>
          <div className="col-lg-6 text-end">
            <button
              onClick={handleNavigate}
              className="small-contained-button ms-3"
            >
              Create Template
            </button>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={categoriesData}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          pagePagination={true}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
        />
        <CustomConfirmation
          openDelete={openDelete}
          setOpenDelete={setOpenDelete}
          title={"Are you sure you want to delete this Template?"}
          handleAgree={handleDelete}
        />
      </div>
    </>
  );
};

export default TemplateConfig;
