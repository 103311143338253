import React, { useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { s3baseUrl } from "src/config/config";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { useSnackbar } from "notistack";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";
import { useState } from "react";
import {
  delete_general_gratitude_api,
  gratitude_detail_api,
} from "src/DAL/gratitude/gratitudeApi";
import PageLoader from "src/components/GeneralComponents/PageLoader";

function GratitudeDetail() {
  const { gratitude_id } = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [gratitudeInfo, setGratitudeInfo] = useState({});
  const [openDelete, setOpenDelete] = useState(false);

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete = () => setOpenDelete(false);

  const handleNavigateEdit = (value) => {
    navigate(`/gratitude/edit-gratitude/${gratitudeInfo._id}`, {
      state: gratitudeInfo,
    });
  };

  const handleDelete = async () => {
    handleCloseDelete();
    setIsLoading(true);
    let result = await delete_general_gratitude_api(gratitude_id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      handleNavigateBack();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleDetail = async () => {
    setIsLoading(true);
    let result = await gratitude_detail_api(gratitude_id);
    if (result.code === 200) {
      setGratitudeInfo(result.general_gratitude);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleNavigateBack = () => {
    navigate(`/gratitude`);
  };

  useEffect(() => {
    if (state) {
      setGratitudeInfo(state);
    } else {
      handleDetail();
    }
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  // let breadCrumbMenu = [
  //   {
  //     title: "Gratitude",
  //     navigation: `/gratitude`,
  //     active: false,
  //   },
  //   {
  //     title: state?.general_gratitude_text,
  //     active: true,
  //   },
  // ];

  let MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleNavigateEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleOpenDelete,
    },
  ];

  return (
    <div className="container-fluid">
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this gratitude?"}
        handleAgree={handleDelete}
      />
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(`/gratitude`)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row section-space">
        <div className="col-12 d-flex justify-content-between">
          <h2>Gratitude Detail</h2>
          <CustomPopoverSection menu={MENU_OPTIONS} data={gratitudeInfo} />
        </div>
      </div>

      <div className="row media-margin">
        <div className="col-12">
          <img
            src={
              gratitudeInfo?.general_gratitude_image
                ? s3baseUrl + gratitudeInfo.general_gratitude_image.thumbnail_1
                : ""
            }
            className="w-100"
          />
        </div>
        <div className="col-12 section-space">
          <p>
            <div
              dangerouslySetInnerHTML={{
                __html: gratitudeInfo?.general_gratitude_text,
              }}
            ></div>
          </p>
        </div>
      </div>
    </div>
  );
}

export default GratitudeDetail;
