import React from "react";
import { useParams } from "react-router-dom";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useEffect } from "react";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import {
  _get_mission_delegate_listing,
  _update_mission_details_access,
} from "src/DAL/challenges/challenges";
import PageLoader from "src/components/GeneralComponents/PageLoader";

export default function ManageMissionAccess() {
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [missionsList, setMissionsList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [memberDetail, setMemberDetail] = useState({});
  console.log(params, "paramsparamsparamsparamsparamsparams");

  const getMissionsList = async () => {
    setIsLoading(true);
    const result = await _get_mission_delegate_listing(params.id);
    if (result.code == 200) {
      setMemberDetail(result.consultant);
      setSelected(result?.consultant?.missions ?? []);
      setMissionsList(result.missions_list);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async () => {
    setIsLoadingForm(true);
    let missions = [];
    selected.map((item) => {
      var mission_object = {
        _id: item._id,
      };
      missions.push(mission_object);
    });

    let missionObject = {
      missions: missions,
    };
    const result = await _update_mission_details_access(
      params.id,
      missionObject
    );
    if (result.code == 200) {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const TABLE_HEAD = [
    { id: "title", label: "Title", alignRight: false },
    {
      id: "mission_duration",
      label: "Mission Duration (Days)",
    },
    { id: "status", label: "Status", type: "row_status" },
  ];

  useEffect(() => {
    getMissionsList();
  }, []);

  let breadCrumbMenu = [
    {
      title: "Delegates",
      navigation: -1,
      active: false,
    },
    {
      title: memberDetail
        ? memberDetail.first_name +
          " " +
          memberDetail.last_name +
          " (" +
          memberDetail.email +
          ")"
        : "Manage Mission Access",
      active: true,
    },
  ];

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row mb-4">
        <div className="col-12 mb-3">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
        <div className="col-6">
          <h2>Manage Mission Access</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={missionsList}
            selected={selected}
            setSelected={setSelected}
            checkbox_selection={true}
            pagePagination={true}
          />
        </div>
      </div>
      <div className="ms-auto text-end" id="fixedbutton">
        <button
          className="small-contained-button mt-3 ml-auto"
          onClick={handleSubmit}
        >
          {isLoadingForm ? "Updating..." : "Update"}
        </button>
      </div>
    </div>
  );
}
