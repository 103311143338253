import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import { s3baseUrl } from "src/config/config";
import { useEffect } from "react";
import { add_bedge, update_badge_api } from "src/DAL/HelpVideos/Categories";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const Input = styled("input")({
  display: "none",
});

export default function AddOrUpdateBadges({ type }) {
  const navigate = useNavigate();
  const params = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setProfileImage] = useState();
  const [afterEarnedIcon, setAfterEarnedIcon] = useState(null);
  const [generalIcon, setGeneralIcon] = useState(null);
  const [oldImage, setOldImage] = useState("");
  const [oldAfterEarnedImage, setOldAfterEarnedImage] = useState("");
  const [oldGeneralIcon, setOldGeneralIcon] = useState("");
  const [formType, setFormType] = useState("ADD");
  const [inputs, setInputs] = useState({
    title: "",
    order: 0,
    status: true,
    image: {},
    after_earned_icon: {},
  });

  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };

  const fileChangedAfterEarnedIconHandler = (e) => {
    setAfterEarnedIcon(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["after_earned_icon"]: e.target.files[0],
    });
  };
  const fileChangedGeneralIconHandler = (e) => {
    setGeneralIcon(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["general_icon"]: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("title", inputs.title);
    formData.append("status", inputs.status);

    if (file) {
      formData.append("icon", inputs.image);
    } else if (!file && oldImage == "") {
      setIsLoading(false);
      enqueueSnackbar("Before Earned Icon is required!", {
        variant: "error",
      });

      return;
    }

    if (afterEarnedIcon) {
      formData.append("after_earned_icon", inputs?.after_earned_icon);
    } else if (!afterEarnedIcon && oldAfterEarnedImage == "") {
      setIsLoading(false);
      enqueueSnackbar("After Earned Icon is required!", {
        variant: "error",
      });
      return;
    }

    if (generalIcon) {
      formData.append("general_icon", inputs?.general_icon);
    } else if (!generalIcon && oldAfterEarnedImage == "") {
      setIsLoading(false);
      enqueueSnackbar("Icon is required!", {
        variant: "error",
      });

      return;
    }

    console.log(...formData);
    const result =
      formType === "ADD"
        ? await add_bedge(formData)
        : await update_badge_api(formData, params.slug);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(`/rewards`);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    if (params && params.slug) {
      if (state) {
        setFormType("EDIT");
        setInputs(state);
        setOldImage(state?.icon?.thumbnail_2);
        setOldAfterEarnedImage(state?.after_earned_icon?.thumbnail_2);
        setOldGeneralIcon(state?.general_icon?.thumbnail_2);
      }
    }
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(`/rewards`)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>

          <h2 className="ms-1">{`${
            formType === "ADD" ? "Add" : "Edit"
          } Reward`}</h2>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              fullWidth
              required
              name="title"
              value={inputs.title}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                required
                value={inputs.status}
                label="Status *"
                onChange={handleChange}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          {/* {formType == "EDIT" && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Order"
                variant="outlined"
                fullWidth
                required
                name="order"
                value={inputs.order}
                onChange={handleChange}
              />
            </div>
          )} */}

          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Before Earned Icon *</p>
                <FormHelperText className="pt-0">
                  Image Size(250 X 250) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {file ? (
                  <img src={file} height="50" />
                ) : (
                  oldImage && <img src={s3baseUrl + oldImage} height="50" />
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="image"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.image?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.image?.name}</p>
            )}
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">After Earned Icon *</p>
                <FormHelperText className="pt-0">
                  Image Size(250 X 250) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {afterEarnedIcon ? (
                  <img src={afterEarnedIcon} height="50" />
                ) : (
                  oldAfterEarnedImage && (
                    <img src={s3baseUrl + oldAfterEarnedImage} height="50" />
                  )
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file-after-earned-icon">
                  <Input
                    accept="image/*"
                    id="contained-button-file-after-earned-icon"
                    multiple
                    type="file"
                    name="after_earned_icon"
                    onChange={fileChangedAfterEarnedIconHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.after_earned_icon?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.after_earned_icon?.name}</p>
            )}
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Icon *</p>
                <FormHelperText className="pt-0">
                  Image Size(250 X 250) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {generalIcon ? (
                  <img src={generalIcon} height="50" />
                ) : (
                  oldGeneralIcon && (
                    <img src={s3baseUrl + oldGeneralIcon} height="50" />
                  )
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file-general_icon">
                  <Input
                    accept="image/*"
                    id="contained-button-file-general_icon"
                    multiple
                    type="file"
                    name="general_icon"
                    onChange={fileChangedGeneralIconHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.general_icon?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs?.general_icon?.name}</p>
            )}
          </div>

          <div
            className="text-end mt-4"
            id={formType == "ADD" ? "" : "fixedbutton"}
          >
            <button className="small-contained-button">
              {formType == "ADD" ? "Submit" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
