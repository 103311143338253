import { format } from "date-fns";
import React from "react";
import Chart from "react-apexcharts";

const formatDate = (dateStr) => {
  const date = new Date(
    `${dateStr.slice(0, 4)}-${dateStr.slice(4, 6)}-${dateStr.slice(6, 8)}`
  );
  return format(date, "MMM dd");
};

const transformData = (data, metric, name) => {
  const categories = data.map((item) => formatDate(item?.date));
  const metricData = data.map((item) =>
    parseInt(name ? item[name] : item.activeUsers, 10)
  );
  return {
    categories,
    series: [
      {
        name: metric,
        data: metricData,
        color: "#e4c073",
      },
    ],
  };
};

const AnalyticsChart = ({ data, metric, name }) => {
  const transformedData = transformData(data, metric, name);

  const options = {
    chart: {
      type: "line",
    },
    xaxis: {
      categories: transformedData?.categories,
      labels: {
        style: {
          colors: Array(transformedData?.categories?.length).fill("#fff"),
        },
      },
      tickAmount: 5,
    },
    yaxis: {
      labels: {
        style: {
          colors: ["#fff"],
        },
      },
    },
    stroke: {
      curve: "smooth",
    },
    fill: {
      opacity: 1,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      theme: "dark",
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
  };

  return (
    <Chart
      options={options}
      series={transformedData.series}
      type="line"
      height={340}
    />
  );
};

export default AnalyticsChart;
