import React from "react";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { dd_date_format } from "src/utils/constant";

const BlockedUsers = ({ tabData }) => {
  let blockUsers = [];
  if (tabData) {
    blockUsers = tabData.map((block) => {
      return {
        ...block,
        name: block.first_name + " " + block.last_name,
      };
    });
  }

  let TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    { id: "name", label: "Name" },
    { id: "email", label: "Email" },
    {
      id: "blocked_date_time",
      label: "Blocked Date",
      renderData: (row) => {
        return (
          <p className="mb-1 mt-1">{dd_date_format(row.blocked_date_time)}</p>
        );
      },
    },
  ];

  return (
    <>
      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        data={blockUsers}
        className="card-with-background"
        hide_search={true}
      />
    </>
  );
};

export default BlockedUsers;
