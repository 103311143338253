import { useEffect, useState } from "react";
import { CircularProgress, Card, Button, Tooltip } from "@mui/material";
import { saveAs } from "file-saver";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { s3baseUrl } from "src/config/config";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import RecordNotFound from "src/components/RecordNotFound";
import ImageBox from "src/components/ModelBox/ImageBox";

import {
  addGalleryImageApi,
  deleteGalleryImageApi,
  galleryImageListing,
} from "src/DAL/DynamiteGallery/DynamiteGallery";
import PageLoader from "src/components/GeneralComponents/PageLoader";

// ----------------------------------------------------------------------

export default function DynamiteGallery() {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [value, setValue] = useState();
  const [isLoadingImages, setIsLoadingImages] = useState({});
  const placeholderImageUrl = "";
  // const handleImageLoad = (index) => {
  //   setIsLoadingImages((prevState) => ({
  //     ...prevState,
  //     [index]: false,
  //   }));
  // };

  const handleDownload = (item, index) => {
    setImages((prevImages) => {
      return prevImages.map((image, i) => {
        if (i === index) {
          return {
            ...image,
            isLoadingButton: true,
          };
        }
        return image;
      });
    });

    fetch(s3baseUrl + item)
      .then(async (res) => {
        if (res.status === 200) {
          let blob = await res.blob();
          saveAs(blob, item);

          setImages((prevImages) => {
            return prevImages.map((image, i) => {
              if (i === index) {
                return {
                  ...image,
                  isLoadingButton: false,
                };
              }
              return image;
            });
          });
        } else {
          enqueueSnackbar(res.statusText, { variant: "error" });
        }
      })
      .catch((error) => {
        console.error("Error occurred while downloading the image:", error);
      });
  };

  const handleUpload = (event) => {
    const fileList = event.target.files[0];

    handleSubmit(fileList);
  };
  // const handleSubmit = async (fileList) => {
  //   setIsLoading(true);
  //   const formData = new FormData();
  //   formData.append("image", fileList);
  //   formData.append("width", "1000");
  //   formData.append("height", "1000");
  //   const result = await addGalleryImageApi(formData);
  //   if (result.code === 200) {
  //     setImages([result.dynamite_gallery_images, ...images]);
  //     setIsLoading(false);
  //     enqueueSnackbar(result.message, { variant: "success" });
  //   } else {
  //     enqueueSnackbar(result.message, { variant: "error" });
  //   }
  // };
  const handleSubmit = async (fileList) => {
    setIsLoadingImages((prevState) => ({
      ...prevState,
      [0]: true,
    }));
    const formData = new FormData();
    formData.append("image", fileList);
    formData.append("width", "1000");
    formData.append("height", "1000");

    const result = await addGalleryImageApi(formData);
    if (result.code === 200) {
      const newImage = result.dynamite_gallery_images;

      const updatedImages = [newImage, ...images];
      setImages(updatedImages);
      setIsLoadingImages((prevState) => ({
        ...prevState,
        [0]: false,
      }));
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingImages((prevState) => ({
        ...prevState,
        [0]: false,
      }));
    }
  };

  const handleOpen = (image_path) => {
    setImageUrl(image_path);
    setTimeout(() => {
      setOpen(true);
    }, 100);
  };
  const handleClose = () => {
    setOpen(false);
    setImageUrl("");
  };

  const handleAgreeDelete = (item) => {
    setValue(item);

    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    const data = {
      image: {
        thumbnail_1: value.thumbnail_1,
        thumbnail_2: value.thumbnail_2,
      },
    };

    const result = await deleteGalleryImageApi(data);
    if (result.code === 200) {
      getImagesList();
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const getImagesList = async () => {
    setIsLoading(true);
    const result = await galleryImageListing();
    if (result.code === 200) {
      const modifiedArray = result.dynamite_gallery_images.map((obj) => ({
        ...obj,
        isLoadingButton: false,
      }));
      setImages(modifiedArray);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getImagesList();
  }, []);

  if (isLoading === true) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6 col-sm-12">
          <h2>Dynamite Gallery </h2>
        </div>
        <div className="col-lg-6 col-sm-12 text-end">
          <span>
            <input
              color="primary"
              type="file"
              id="icon-button-file"
              style={{ display: "none" }}
              onChange={handleUpload}
              accept="image/*"
            />
            <label htmlFor="icon-button-file">
              <Button
                component="span"
                className="ms-3 mt-2 small-contained-button"
              >
                Upload
              </Button>
            </label>
          </span>
        </div>
      </div>

      {images.length > 0 ? (
        <div className="row">
          {images.map((item, index) => (
            <div className="col-lg-4 col-md-4 col-sm-12" key={index}>
              <div
                className={`mt-4 pods-cards-shadow cursor image-container ${
                  item.isLoadingButton ? "show-loader" : ""
                }`}
                onClick={() => {
                  handleOpen(s3baseUrl + item.thumbnail_1);
                }}
              >
                {isLoadingImages[index] && (
                  <div className="loader">
                    {" "}
                    <CircularProgress />
                  </div>
                )}
                <img
                  className="rounded"
                  src={
                    isLoadingImages[index]
                      ? placeholderImageUrl
                      : s3baseUrl + item.thumbnail_2
                  }
                  alt="Gallery Image"
                  style={{
                    display: isLoadingImages[index] ? "none" : "block",
                  }}
                />
                {/* <img
                  className="rounded"
                  src={s3baseUrl + item.thumbnail_2}
                  alt="Gallery Image"
                /> */}
                <div className="overlay">
                  <Tooltip title="Delete">
                    <DeleteOutlineIcon
                      className="delete-icon"
                      onClick={(e) => {
                        e.stopPropagation();

                        handleAgreeDelete(item);
                      }}
                    />
                  </Tooltip>
                  {item.isLoadingButton ? (
                    <CircularProgress className="icon-style-loader non-overlay mt-2 me-2" />
                  ) : (
                    <Tooltip title="Download">
                      <DownloadIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDownload(item.thumbnail_1, index);
                        }}
                        className="icon-style-image"
                      />
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="mt-5">
          <RecordNotFound title="Images" />
        </div>
      )}

      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Image ?"}
        handleAgree={handleDelete}
      />
      <ImageBox open={open} handleClose={handleClose} image_url={imageUrl} />
    </div>
  );
}
