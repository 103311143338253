import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  IconButton,
  CircularProgress,
  FormHelperText,
  Button,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  addMissionScadule,
  UPdate_mission_scadule_list,
} from "src/DAL/MissionApi/MissionApiNew";
import { s3baseUrl } from "src/config/config";
import { delete_audio_mission } from "src/DAL/commonApi/commonApi";
import { boolean } from "yup";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function AddOrUpdateScadule() {
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const { state } = useLocation();
  const [formType, setFormType] = useState("ADD");
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setProfileImage] = useState();
  const [iconImage, setIconImage] = useState();
  const [oldImage, setOldImage] = useState("");
  const [oldIconImage, setOldIconImage] = useState("");
  const [oldAudio, setOldAudio] = useState("");
  const [audioFile, setAudioFile] = useState(null);
  const [uploadAudio, setUploadAudio] = useState(false);
  const [missionType, setMissionType] = useState("");

  const [inputs, setInputs] = useState({
    title: "",
    detailed_description: "",
    number_of_start_days: "",
    number_of_end_days: "",
    main_heading: "",
    reward_coins: 0,
    coins_to_unlock: 0,
    short_description: "",
    status: true,
    is_locked: true,
    order: 0,
    video_url: "",
    audio_title: "",
    audio_description: "",
    embed_code: "",
    type: "",
    is_chat_enabled: true,
  });

  const audioFileChange = (e) => {
    setAudioFile(e.target.files[0]);
    setUploadAudio(true);
  };

  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };
  const iconFileChangedHandler = (e) => {
    setIconImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["icon"]: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!file && formType == "ADD") {
      setIsLoading(false);
      enqueueSnackbar("Image is required", { variant: "error" });
      return;
    }
    if (!iconImage && formType == "ADD") {
      setIsLoading(false);
      enqueueSnackbar("Scadule Icon is required", { variant: "error" });
      return;
    }
    const formData = new FormData();
    formData.append("title", inputs.title);
    formData.append("number_of_start_days", inputs.number_of_start_days);
    formData.append("number_of_end_days", inputs.number_of_end_days);
    formData.append("detailed_description", inputs.detailed_description);
    formData.append("status", inputs.status);
    formData.append("is_locked", inputs.is_locked);
    formData.append("main_heading", inputs.main_heading);
    formData.append("reward_coins", inputs.reward_coins);
    formData.append("coins_to_unlock", inputs.coins_to_unlock);
    formData.append("short_description", inputs.short_description);
    formData.append("mission_id", params.mission_id);
    formData.append("video_url", inputs.video_url);
    formData.append("audio_title", inputs.audio_title);
    formData.append("audio_description", inputs.audio_description);
    formData.append("embed_code", inputs.embed_code ?? "");
    formData.append("is_chat_enabled", inputs.is_chat_enabled ?? false);
    if (uploadAudio) {
      formData.append("audio_file", audioFile);
    }
    if (file) {
      formData.append("image", inputs.image);
    }
    if (iconImage) {
      formData.append("icon", inputs.icon);
    }
    if (formType === "EDIT") {
      formData.append("order", inputs.order);
    }
    const result =
      formType === "ADD"
        ? await addMissionScadule(formData)
        : await UPdate_mission_scadule_list(formData, params.id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handldeDeleteAudio = async () => {
    if (params && params.id && oldAudio) {
      let postData = {
        _id: params.id,
        type: "mission_schedule",
      };
      const result = await delete_audio_mission(postData);
      if (result.code == 200) {
        enqueueSnackbar("Audio deleted successfully ", { variant: "success" });
        setAudioFile(null);
        setOldAudio("");
        setUploadAudio(false);
      }
    } else {
      setAudioFile(null);
      setUploadAudio(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  console.log(state, "params params ");

  useEffect(() => {
    if (params && params.id) {
      if (state) {
        setMissionType(state?.type);
        setFormType("EDIT");
        setInputs(state);
        setOldAudio(state.audio_url);
        setOldImage(state?.image?.thumbnail_1);
        setOldIconImage(state?.icon?.thumbnail_1);
      }
    } else {
      setMissionType(state?.type);
    }
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        {/* {params.id && (
            <div className="col-12 mb-3 ms-2">
              <span>
                <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
              </span>
            </div>
          )} */}
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2 className="ms-2">
            {params.id ? "Edit Mission Schedule" : "Add Mission Schedule"}
          </h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row notification-statement">
          <div className="col-6 mt-3">
            <div className="d-flex goal_statement_yes_icons justify-content-between w-100">
              <TextField
                className="text-muted"
                InputLabelProps={{ style: { fontSize: 14 } }}
                id="outlined-basic"
                label="Main Heading"
                variant="outlined"
                fullWidth
                name="main_heading"
                onChange={handleChange}
                value={inputs.main_heading}
                required={true}
              />
            </div>
          </div>
          <div className="col-6 mt-3">
            <div className="d-flex goal_statement_yes_icons justify-content-between w-100">
              <TextField
                className="text-muted"
                InputLabelProps={{ style: { fontSize: 14 } }}
                id="outlined-basic"
                label="Tab Title"
                variant="outlined"
                fullWidth
                name="title"
                onChange={handleChange}
                value={inputs.title}
                required={true}
              />
            </div>{" "}
          </div>
          <div className="col-6 mt-3">
            <div className="d-flex goal_statement_yes_icons justify-content-between w-100">
              <TextField
                className="text-muted"
                InputLabelProps={{ style: { fontSize: 14 } }}
                id="outlined-basic"
                label="No. Of start Days"
                variant="outlined"
                fullWidth
                type="number"
                onChange={handleChange}
                name="number_of_start_days"
                value={inputs.number_of_start_days}
                required={true}
              />
            </div>{" "}
          </div>
          <div className="col-6 mt-3">
            <div className="d-flex goal_statement_yes_icons justify-content-between w-100">
              <TextField
                className="text-muted"
                InputLabelProps={{ style: { fontSize: 14 } }}
                id="outlined-basic"
                label="No. Of End Days"
                variant="outlined"
                fullWidth
                type="number"
                onChange={handleChange}
                name="number_of_end_days"
                value={inputs.number_of_end_days}
                required={true}
              />
            </div>
          </div>
          <div className="col-6 mt-3">
            <div className="d-flex goal_statement_yes_icons justify-content-between w-100">
              <TextField
                className="text-muted"
                InputLabelProps={{ style: { fontSize: 14 } }}
                id="outlined-basic"
                label="Rewarded Coins"
                variant="outlined"
                fullWidth
                type="number"
                onChange={handleChange}
                name="reward_coins"
                value={inputs.reward_coins}
                required={true}
              />
            </div>
          </div>
          {missionType == "mission" && (
            <>
              <div className="col-6 mt-3">
                <div className="d-flex goal_statement_yes_icons justify-content-between w-100">
                  <TextField
                    className="text-muted"
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    id="outlined-basic"
                    label="Coins To Unlock"
                    variant="outlined"
                    fullWidth
                    type="number"
                    onChange={handleChange}
                    name="coins_to_unlock"
                    value={inputs.coins_to_unlock}
                    required={true}
                  />
                </div>
              </div>
            </>
          )}
          <div className="col-6 mt-3">
            <div className="d-flex goal_statement_yes_icons justify-content-between w-100">
              <TextField
                className="text-muted"
                InputLabelProps={{ style: { fontSize: 14 } }}
                id="outlined-basic"
                label="Video Url"
                variant="outlined"
                fullWidth
                type="URL"
                onChange={handleChange}
                name="video_url"
                value={inputs.video_url}
              />
            </div>
          </div>
          {formType === "EDIT" && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Order"
                variant="outlined"
                fullWidth
                name="order"
                value={inputs.order}
                onChange={handleChange}
                required
              />
            </div>
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                value={inputs.status}
                label="Status *"
                onChange={handleChange}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Is Locked</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_locked"
                value={inputs.is_locked}
                label="Is Locked"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          {missionType === "quest" && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Is Chat Enabled*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="is_chat_enabled"
                  value={inputs.is_chat_enabled}
                  label="Is Chat Enabled*"
                  onChange={handleChange}
                  required
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
          <div className="col-6 mt-3">
            <TextField
              className="text-muted"
              InputLabelProps={{ style: { fontSize: 14 } }}
              id="outlined-basic"
              label="Audio Title"
              variant="outlined"
              fullWidth
              name="audio_title"
              onChange={handleChange}
              value={inputs.audio_title}
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Upload Image *</p>
                <FormHelperText className="pt-0">
                  Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {file ? (
                  <img src={file} height="50" />
                ) : oldImage ? (
                  <img src={s3baseUrl + oldImage} height="50" />
                ) : (
                  ""
                )}
              </div>

              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="image"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.image?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.image?.name}</p>
            )}
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0 ">
              <div className="col-5">
                <p className="">Upload Schedule Icon*</p>
                <FormHelperText className="pt-0">
                  Image Size(400 X 400) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {iconImage ? (
                  <img src={iconImage} height="50" />
                ) : oldIconImage ? (
                  <img src={s3baseUrl + oldIconImage} height="50" />
                ) : (
                  ""
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file-icon">
                  <Input
                    accept="image/*"
                    id="contained-button-file-icon"
                    multiple
                    type="file"
                    name="icon_image"
                    onChange={iconFileChangedHandler}
                  />
                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.icon_image?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.icon_image?.name}</p>
            )}
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0 ">
              <div className="col-5">
                <p className="">Upload Audio </p>
                <FormHelperText className="pt-0">
                  Audio mp3 (max 100mb)
                </FormHelperText>
              </div>
              <div className="col-2">
                {(audioFile || oldAudio) && (
                  <Tooltip title="Delete Audio">
                    <DeleteIcon
                      onClick={handldeDeleteAudio}
                      className="mt-3 icon-color"
                    />
                  </Tooltip>
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="audio">
                  <Input
                    accept="audio/mp3,audio/*;capture=microphone"
                    id="audio"
                    multiple
                    name="audio"
                    type="file"
                    onChange={audioFileChange}
                  />
                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            <div className="col-12 mt-1">
              {oldAudio && (
                <audio className="w-100" src={s3baseUrl + oldAudio} controls />
              )}
            </div>
            <p className="text-secondary">{audioFile && audioFile.name}</p>
          </div>
          {missionType === "quest" && (
            <div className="col-12 mt-3">
              <FormControl fullWidth>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Embed Code"
                  multiline
                  rows={6}
                  name="embed_code"
                  value={inputs.embed_code}
                  onChange={handleChange}
                />
              </FormControl>
            </div>
          )}
          <div className="col-12 mt-3">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Audio Description"
                multiline
                rows={4}
                name="audio_description"
                value={inputs.audio_description}
                onChange={handleChange}
              />
            </FormControl>
          </div>
          <div className="col-12 mt-3">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Short Description "
                multiline
                required
                rows={4}
                name="short_description"
                value={inputs.short_description}
                onChange={handleChange}
              />
            </FormControl>
          </div>
          <div className="col-12 ">
            <p className="tracking_code_heading text-white">Description</p>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="detailed_description"
              editorHeight={310}
            />
          </div>
          <div
            className="text-end mt-4"
            id={formType == "ADD" ? "" : "fixedbutton"}
          >
            <button className="small-contained-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
}
