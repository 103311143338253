import React from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Checkbox,
  Chip,
  FormControlLabel,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { client_Url } from "src/config/config";
import { changeDelegateCommissionApi } from "src/DAL/WebsitePages/WebsitePages";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  consultantListingForSalePage,
  consultantListingForSalePageWithId,
} from "src/DAL/consultant/consultant";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import PageLoader from "src/components/GeneralComponents/PageLoader";

export default function ManageCommissionAccessFromPages() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [consultantData, setConsultantData] = useState([]);
  const [salePageDetail, setSalePageDetail] = useState();
  const [isAllFiltered, setIsAllFiltered] = useState(false);
  const [useInfo, setuseInfo] = useState();
  const handleCopiedUrl = () => {
    enqueueSnackbar("Preview Url copied to clipboard", { variant: "success" });
  };

  const handleChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    setConsultantData((consultantData) =>
      consultantData.map((obj) => {
        if (obj._id === name) {
          return { ...obj, commission_percentage: value };
        }
        return obj;
      })
    );

    setSelected((consultantData) =>
      consultantData.map((obj) => {
        if (obj._id === name) {
          return { ...obj, commission_percentage: value };
        }
        return obj;
      })
    );
  };

  const handle_click_check_box = async (e, row, index) => {
    let is_checked = e.target.checked;
    setConsultantData((old) =>
      old.map((obj) => {
        if (obj._id === row._id) {
          return { ...obj, is_mc_filter: is_checked };
        }
        return obj;
      })
    );
  };

  const handleChangeAll = async (e, row, index) => {
    let is_checked = e.target.checked;
    setIsAllFiltered(is_checked);
    setConsultantData((old) =>
      old.map((obj) => {
        return { ...obj, is_mc_filter: is_checked };
      })
    );
  };

  const TABLE_HEAD = [
    { id: "name", label: "Delegate Name", alignRight: false },
    {
      id: "is_mc_filter",
      label: (
        <FormControlLabel
          control={<Checkbox checked={isAllFiltered} />}
          label=" &nbsp; MC Filter Access"
          onChange={handleChangeAll}
        />
      ),
      type: "checkbox",
      handleClick: (e, row, index) => {
        handle_click_check_box(e, row, index);
      },
    },
    {
      label: "Default Commission in %",
      alignRight: false,
      renderData: (row) => {
        let is_selected = selected.filter((object) => row._id === object._id);

        let co_commission_percentage =
          is_selected.length > 0
            ? is_selected[0].commission_percentage
            : row.commission_percentage;

        return (
          <TextField
            id="outlined-basic"
            variant="outlined"
            fullWidth
            size="small"
            type="number"
            required
            name={row._id}
            className="default_consultant_commission_input"
            value={co_commission_percentage}
            InputProps={{
              endAdornment: <span>%</span>,
              inputProps: { min: 1, max: 100 },
            }}
            sx={{
              "& fieldset": { border: "none" },
            }}
            onChange={handleChange}
          />
        );
      },
    },
    {
      id: "preview_link_data",
      label: "URL",
      renderData: (row) => {
        console.log(row.preview_link_data, " row.preview_link_data");
        return (
          <a
            className="text-white text-decoration-none"
            href={row.preview_link_data}
            target="_blank"
          >
            Preview
          </a>
        );
      },
    },
  ];

  TABLE_HEAD.splice(2, 0, {
    label: "Copy URL",
    alignRight: false,
    renderData: (row) => {
      return (
        <CopyToClipboard
          text={
            client_Url +
            salePageDetail.sale_page_title_slug +
            "/" +
            row.affiliate_url_name
          }
          onCopy={() => handleCopiedUrl(true)}
        >
          <Tooltip title="Click to copy Preview URL">
            <Chip label="Copy URL" color="primary" variant="outlined" />
          </Tooltip>
        </CopyToClipboard>
      );
    },
  });

  const getTemplateConfig = async () => {
    const result = await consultantListingForSalePageWithId(params.page_id);
    if (result.code === 200) {
      let result_array = [];
      result.delegates.map((delegate) => {
        let is_selected = false;
        let is_filter = delegate.page_acces_for_mission_control.find(
          (item) => item == params.page_id
        );
        if (is_filter) {
          is_selected = true;
        }

        result_array.push({
          ...delegate,
          name:
            delegate.first_name +
            " " +
            delegate.last_name +
            " (" +
            delegate.email +
            ")",
          preview_link_data:
            client_Url +
            result?.sale_page?.sale_page_title_slug +
            "/" +
            delegate.affiliate_url_name,
          commission_percentage: 0,
          is_mc_filter: is_selected,
        });
      });
      setConsultantData(result_array);

      let selected_array = [];
      result?.delegates?.map((delegateObj) => {
        let filter = delegateObj.sales_commission.find(
          (selected) => selected.sale_page == params.page_id
        );
        if (filter) {
          selected_array.push({
            _id: delegateObj._id,
            commission_percentage: filter.commission_percentage,
          });
        }
      });
      setSelected(selected_array);
      setSalePageDetail(result.sale_page);
      setIsLoading(false);
      setuseInfo(result.sale_page);
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    let selectedConsultants = [];
    selected.map((consultant) => {
      selectedConsultants.push({
        consultant_id: consultant._id,
        commission_percentage: consultant.commission_percentage
          ? consultant.commission_percentage
          : 0,
      });
    });
    let page_acces_for_mission_control = [];
    consultantData.map((delegate) => {
      if (delegate.is_mc_filter == true) {
        page_acces_for_mission_control.push({
          _id: delegate._id,
        });
      }
    });

    const postData = {
      page_id: params.page_id,
      consultant_comission: selectedConsultants,
      page_acces_for_mission_control: page_acces_for_mission_control,
    };

    const result = await changeDelegateCommissionApi(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const websitePages = window.location.pathname.includes("/website-pages");

  useEffect(() => {
    getTemplateConfig();
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  let breadCrumbMenu = [
    {
      title: websitePages ? "Pages" : "Template Pages",
      navigation: `${websitePages ? `/website-pages` : `/template-pages`}`,
      active: false,
    },
    {
      title: useInfo?.sale_page_title,
      active: true,
    },
  ];

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex  mb-2">
        <div className="col-12 mb-3">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-6">
          <h2>Manage Commission Access</h2>
        </div>
      </div>
      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        data={consultantData}
        selected={selected}
        setSelected={setSelected}
        checkbox_selection={true}
        pagePagination={true}
      />
      <div className="ms-auto text-end" id="fixedbutton">
        <button
          className="small-contained-button mt-3 ml-auto"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
}
