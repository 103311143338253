import React from "react";
import { Avatar, Chip, Tooltip } from "@mui/material";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { useState } from "react";
import { useEffect } from "react";
import { s3baseUrl } from "src/config/config";
import arrowCircleDownFill from "@iconify/icons-eva/arrow-circle-down-fill";
import { Icon } from "@iconify/react";
import CircleIcon from "@mui/icons-material/Circle";
import moment from "moment";
import Label from "src/components/Label";
import { get_kmb_number } from "src/utils/formatNumber";
import { dd_date_format, replace_created_for } from "src/utils/constant";
import MemberProgramPopover from "../MembersList/memberProgramPopOver";
import { download_csv_file } from "src/utils/convertHtml";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import GeneralRequestForCsv from "../MembersList/GeneralRequestForCsv";
import PageLoader from "src/components/GeneralComponents/PageLoader";
import { useNavigate } from "react-router-dom";

let member_options = [
  {
    label: "First name",
    value: "first_name",
    is_checked: true,
    is_disabled: true,
  },
  {
    label: "Last name",
    value: "last_name",
    is_checked: true,
    is_disabled: true,
  },
  { label: "Email", value: "email", is_checked: true, is_disabled: true },
  {
    label: "Phone number",
    value: "contact_number",
    is_checked: true,
    is_disabled: true,
  },
  { label: "Delegate", value: "consultant", is_checked: false },
  { label: "Referred User", value: "affliliate", is_checked: false },
  { label: "Nurture", value: "nurture", is_checked: false },
  {
    label: "Marketing affliliate",
    value: "marketing_affliliate",
    is_checked: false,
  },
  // {
  //   label: "Event subscriber count",
  //   value: "event_subscriber_count",
  //   is_checked: false,
  // },
  // {
  //   label: "Dynamite diary coins count",
  //   value: "dynamite_diary_coins_count",
  //   is_checked: false,
  // },
  // {
  //   label: "Attitude assessment coins count",
  //   value: "attitude_assessment_coins_count",
  //   is_checked: false,
  // },
  // {
  //   label: "Visioneering challange coins count",
  //   value: "visioneering_challange_coins_count",
  //   is_checked: false,
  // },
  // {
  //   label: "Dynamite gratitude coins count",
  //   value: "dynamite_gratitude_coins_count",
  //   is_checked: false,
  // },
  // {
  //   label: "Wheel of life completed date",
  //   value: "wheel_of_life_completed_date",
  //   is_checked: false,
  // },
  // {
  //   label: "Meditation coins count",
  //   value: "meditation_coins_count",
  //   is_checked: false,
  // },
  // { label: "Register url", value: "register_url", is_checked: false },
  {
    label: "Last login activity",
    value: "last_login_activity",
    is_checked: false,
  },
  {
    label: "Total commission",
    value: "total_commission",
    is_checked: false,
  },
  {
    label: "Commission paid",
    value: "commission_paid",
    is_checked: false,
  },
  {
    label: "Commission due",
    value: "commission_due",
    is_checked: false,
  },

  {
    label: "Membership  expire",
    value: "membership_purchase_expiry",
    is_checked: false,
  },
];

export default function MemberHistory({
  tabData,
  searchFunction,
  searchText,
  setSearchText,
  pageCount,
  totalPages,
  handleChangePages,
  custom_pagination,
  filterQuery,
}) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [membersData, setMembersData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [program, setprogram] = useState([]);
  const [showExportcsvFile, setShowExportcsvFile] = useState(false);
  const showPopUPcsv = () => {
    setShowExportcsvFile(true);
  };
  const handleClickPopUP = (event, row) => {
    setprogram(row.program);
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopUp = () => {
    setAnchorEl(null);
  };

  const moveToMemberProfile = (value) => {
    navigate(`/member/member-profile/${value._id}`, {
      state: {
        ...value,
        move_from_page_title: "History",
        move_from_page_path: "/history",
      },
    });
  };

  const getProgramsList = () => {
    setIsLoading(true);
    const members = tabData?.map((member, index) => {
      //Getting Referred User
      let affiliate_name = "Master Link";
      let affiliate_info = member.affliliate?.affiliate_user_info;
      if (affiliate_info) {
        affiliate_name =
          affiliate_info.first_name + " " + affiliate_info.last_name;
      }
      if (member.affliliate?.affiliate_url_name) {
        //Attach affiliate_url_name
        affiliate_name =
          affiliate_name + " (" + member.affliliate.affiliate_url_name + ")";
      }

      // Getting Delegate name
      let delegate_name = "N/A";
      let delegate = member.consultant;
      if (delegate) {
        delegate_name = delegate.first_name + " " + delegate.last_name;
      }

      let goal_statement_status = member.goal_statement_completed_status;
      let goal_statement_text = "Incomplete";
      if (goal_statement_status && member.save_and_close_status) {
        goal_statement_text =
          "Responded" + " (" + dd_date_format(member.save_and_close_date) + ")";
      } else if (goal_statement_status && !member.save_and_close_status) {
        goal_statement_text =
          "Completed" +
          " (" +
          dd_date_format(member.goal_statement_completed_date) +
          ")";
      }

      let wheel_of_life_enable = "No";
      if (member.is_wheel_of_life_enable) {
        wheel_of_life_enable = "Yes";
      }

      let replace_user_type = "IOS";
      if (member.member_created_by_platform !== "ios") {
        replace_user_type = replace_created_for(
          member.member_created_by_platform
        );
      }

      return {
        ...member,
        name: member.first_name + " " + member.last_name,
        replace_user_type: replace_user_type,
        register_url: member.register_url ? member.register_url : "N/A",
        affiliate_name: affiliate_name,
        delegate_name: delegate_name,
        nurture: member.nurture
          ? member.nurture.first_name + " " + member.nurture.last_name
          : "N/A",
        completeNurture: member.nurture,
        goal_statement_text: goal_statement_text,
        created_at: dd_date_format(member.createdAt),
        last_login_activity: member.last_login_activity
          ? moment(member.last_login_activity).format("DD-MM-YYYY hh:mm A")
          : "N/A",
        user_coins: get_kmb_number(member.coins_count),
        diary_coins_count: get_kmb_number(member.dynamite_diary_coins_count),
        meditation_coins: get_kmb_number(member.meditation_coins_count),
        wheel_of_life_enable: wheel_of_life_enable,
        phone: member.contact_number ? member.contact_number : "N/A",
        assessment_coins_count: get_kmb_number(
          member.attitude_assessment_coins_count
        ),
        gratitude_coins: get_kmb_number(member.dynamite_gratitude_coins_count),
        membership_expire: member?.membership_purchase_expiry
          ? dd_date_format(member?.membership_purchase_expiry)
          : "N/A",
        table_avatar: {
          src: member.profile_image ? s3baseUrl + member.profile_image : "/",
          alt: member.first_name,
        },
        lead_status_title: member.lead_status?.title,
      };
    });
    setMembersData(members);
    setIsLoading(false);
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },

    {
      id: "table_avatar",
      label: "Image",
      alignRight: false,
      type: "thumbnail",
    },
    {
      id: "name",
      label: "Name",

      renderData: (row) => {
        return (
          <p
            className="mb-0"
            style={{ cursor: "pointer" }}
            onClick={() => moveToMemberProfile(row)}
          >
            {row?.name ? row?.name : "N/A"}
          </p>
        );
      },
    },
    {
      id: "email",
      label: "Email",
      renderData: (row) => {
        return (
          <p
            className="mb-0"
            style={{ cursor: "pointer" }}
            onClick={() => moveToMemberProfile(row)}
          >
            {row?.email ? row?.email : "N/A"}
          </p>
        );
      },
    },
    { id: "phone", label: "Phone Number", alignRight: false },
    {
      id: "affiliate_name",
      label: "Referred User",
      renderData: (row) => {
        return (
          <p className="mb-0">
            {row.affiliate_name ? row.affiliate_name : "N/A"}
          </p>
        );
      },
      alignRight: false,
    },
    {
      id: "nurture",
      label: "Nurture",
      renderData: (row) => {
        return <p className="mb-0">{row.nurture ? row.nurture : "N/A"}</p>;
      },
      alignRight: false,
    },
    {
      id: "delegate_name",
      label: "Delegate",
      renderData: (row) => {
        return (
          <p className="mb-0">
            {row.delegate_name ? row.delegate_name : "N/A"}
          </p>
        );
      },
      alignRight: false,
    },
    {
      id: "program",
      label: "Programmes",
      renderData: (row) => {
        const firstFiveprograms = row.program?.slice(0, 5);
        return firstFiveprograms && firstFiveprograms.length > 0 ? (
          firstFiveprograms?.map((program, i) => {
            return (
              <>
                <Tooltip
                  key={i}
                  title={
                    program.expiry_date ? (
                      <div>
                        Start Date:{" "}
                        {dd_date_format(program?.purchase_date_time)}
                        <br />
                        Expiry: {dd_date_format(program?.expiry_date)}
                        <br />
                        Status:{" "}
                        {program?._id?.status === true ? "Active" : "InActive"}
                      </div>
                    ) : (
                      <div>
                        Start Date:{" "}
                        {dd_date_format(program?.purchase_date_time)}
                        <br />
                        No Expiry
                        <br />
                        Status:{" "}
                        {program?._id?.status === true ? "Active" : "InActive"}
                      </div>
                    )
                  }
                  arrow
                >
                  <p
                    className="mb-1 program-hover"
                    onClick={() => handleChangeProgramDetail(program)}
                  >
                    {program._id.title}
                  </p>
                </Tooltip>
                {i == 4 && (
                  <p
                    className="mb-1 mt-1 view-more"
                    aria-describedby={id}
                    variant="contained"
                    onClick={(e) => handleClickPopUP(e, row)}
                  >
                    view more
                  </p>
                )}
              </>
            );
          })
        ) : (
          <p>N/A</p>
        );
      },
      alignRight: false,
    },
    {
      label: "Badge Level",
      alignRight: false,
      className: "text-capitalize",
      renderData: (row) => {
        return (
          <p className="mb-0">
            {row?.membership_level_badge_info
              ? row?.membership_level_badge_info?.membership_level_badge_title
              : "N/A"}
          </p>
        );
      },
    },
    {
      id: "wheel_of_life_enable",
      label: "Wheel Of Life Enable",
      alignRight: false,
      className: "text-capitalize",
    },

    {
      id: "goal_statement_text",
      label: "Goal Statement",
      renderData: (row) => {
        let goal_statement_text = "Incomplete";
        let goal_statement_status = row.goal_statement_completed_status;
        if (goal_statement_status && row.save_and_close_status) {
          goal_statement_text =
            "Responded" + " (" + dd_date_format(row.save_and_close_date) + ")";
        } else if (goal_statement_status && !row.save_and_close_status) {
          goal_statement_text =
            "Completed" +
            " (" +
            dd_date_format(row.goal_statement_completed_date) +
            ")";
        }
        return <p className="mb-0">{goal_statement_text}</p>;
      },
    },

    {
      id: "created_at",
      label: "Created At",
      alignRight: false,
    },
    {
      id: "membership_expire",
      label: "Membership Expire",
      alignRight: false,
    },

    {
      id: "replace_user_type",
      label: "Created By Platform",
      alignRight: false,
      className: "text-capitalize",
    },
    {
      id: "leadtitle",
      label: "Lead Satus",
      renderData: (row) => {
        let lead_status = row.lead_status;
        return (
          <p className="mb-0">
            {lead_status ? (
              <>
                <Chip
                  label={lead_status.title}
                  style={{
                    backgroundColor: lead_status.background_color,
                    color: lead_status.text_color,
                    height: "25px",
                  }}
                />
              </>
            ) : (
              ""
            )}
          </p>
        );
      },
      alignRight: false,
    },
    { id: "register_url", label: "Registration Url", alignRight: false },
    {
      id: "status",
      label: "Member Status",
      alignRight: false,
      type: "row_status",
    },
  ];
  const headers = [
    { label: "Email", key: "email" },
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name" },
    { label: "Contact Number", key: "contact_number" },
    { label: "Nurture", key: "nurture" },
    { label: "Delegate", key: "delegate_name" },
    { label: "Delegate", key: "delegate_name" },
    { label: "Reffered User", key: "affiliate_name" },
    { label: "Membership Expire", key: "membership_expire" },
    { label: "Created By Platform", key: "replace_user_type" },
    { label: "Register Url", key: "register_url" },
  ];
  const handleExportClick = () => {
    download_csv_file(headers, membersData);
  };
  useEffect(() => {
    getProgramsList();
  }, [tabData]);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  if (isLoading == true) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="">
        <div className="button-history">
          <button
            className="small-contained-button me-2 mt-1 mb-4"
            onClick={showPopUPcsv}
          >
            Request For Csv &nbsp;&nbsp; <Icon icon={arrowCircleDownFill} />
          </button>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={membersData}
          className="card-with-background mt-3"
          custom_pagination={custom_pagination}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
          // is_hide={true}
        />
        <MemberProgramPopover
          handleClick={handleClickPopUP}
          handleClose={handleClosePopUp}
          anchorEl={anchorEl}
          id={id}
          open={open}
          program={program}
        />
        <GeneralPopUpModel
          open={showExportcsvFile}
          setOpen={setShowExportcsvFile}
          title="Export CSV file request"
          componentToPassDown={
            <GeneralRequestForCsv
              options_array={member_options}
              collection_name={"users_history"}
              filter_data={filterQuery}
              setShowExportcsvFile={setShowExportcsvFile}
            />
          }
        />
      </div>
    </>
  );
}
