import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { useSnackbar } from "notistack";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@mui/styles";
import { get_root_value } from "src/utils/domUtils";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import dayjs from "dayjs";

import {
  _event_detail_specific_iteration,
  _update_notify_schedule,
  memberAndGroupsListApi,
} from "src/DAL/member/member";
import {
  add_new_event_api,
  eventDetailApi,
  update_event_iteration_api,
  update_member_event_api,
} from "src/DAL/Events/events";
import { get_date_with_admin_time_zone } from "src/utils/constant";
import { delegateAddDetailApi } from "src/DAL/delegatesPods/delegatePods";
import { Icon } from "@iconify/react";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams } from "react-router-dom";
import { usePGIMode } from "src/Hooks/PGIModeContext";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import FullPagePopupForTitle from "src/pages/GeneralSetting/FullPagePopupForTitle";
import EmailNotificationSetting from "./Components/EmailNotificationSetting";
import MessageNotificationSetting from "./Components/MessageNotificationSetting";
import WhatsappNotificationSetting from "./Components/WhatsappNotificationSetting";
import PushNotificationSetting from "./Components/PushNotificationSetting";
import SMSNotificationSetting from "./Components/SMSNotificationSetting";
import DateAndTimeUpdate from "./Components/DateAndTimeUpdate";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const useStyles = makeStyles(() => ({
  paper: {
    background: get_root_value("--popup-background-color"),
    color: get_root_value("--input-text-color"),
  },
}));

export default function NotificationScheduleDataUpdate() {
  const member_id = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { defaultTimeZone } = usePGIMode();
  const classes = useStyles();
  const navigate = useNavigate();
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [EmailNotification, setEmailNotification] = useState(false);
  const [drawerState, setDrawerState] = useState("");
  const [selectedObject, setSelectedObject] = useState({});
  const [tabData, setTabData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [selectedID, setSelectedID] = useState("");

  const [state, setState] = useState({
    description: "",
    title: "",
    color: "#000",
    recurring_type: "daily",
    notify_before_unit: "minutes",
    notification_send_type: [],
    notify_before_time: 30,
    start_date: dayjs(new Date()).$d,
    weekday: [new Date().getDay()],
    end_date: dayjs(new Date()).$d,
    start_time: dayjs(new Date()).$d,
    end_time: dayjs(new Date()).$d,
    status: true,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  const handleClose = () => {
    setDrawerState("");
    setSelectedObject({});
    setSelectedID("");
    setEmailNotification(false);
  };

  const get_start_end_date = (date) => {
    return get_date_with_admin_time_zone(date, "YYYY-MM-DD", defaultTimeZone);
  };

  const get_start_end_time = (date) => {
    return moment.utc(date).format("YYYY-MM-DD HH:mm");
  };

  const handle_data = (data) => {
    if (data) {
      setState({
        ...data,
        start_date: dayjs(get_start_end_date(data.start_date_time)).$d,
        end_date: dayjs(get_start_end_date(data.end_date_time)).$d,
        start_time: get_start_end_time(data.start_date_time),
        end_time: get_start_end_time(data.end_date_time),
        weekday: data.weekday ? data.weekday : [new Date().getDay()],
      });
    }
  };

  const handleClosePopup = () => {
    setSelectedID("");
    setSelectedObject({});
  };

  const handleOpen = (data, name, _id) => {
    setSelectedID(_id);
    setSelectedObject(data);
    if (name == "email_notification_access") {
      setEmailNotification(true);
    } else {
      setDrawerState(name);
    }
  };

  let drawer_data = {
    onCloseDrawer: handleClose,
    data: selectedObject,
    _id: selectedID,
    tabData: tabData,
    setTabData: setTabData,
    row: selectedRowData,
    setRow: setSelectedRowData,
  };

  const handle_drawer = () => {
    if (drawerState === "message_notification_access") {
      return {
        label: "Message Notification Setting",
        component: <MessageNotificationSetting {...drawer_data} />,
      };
    } else if (drawerState === "whatsapp_notification_access") {
      return {
        label: "Whatsapp Notification Setting",
        component: <WhatsappNotificationSetting {...drawer_data} />,
      };
    } else if (drawerState === "push_notification_access") {
      return {
        label: "Push Notification Setting",
        component: <PushNotificationSetting {...drawer_data} />,
      };
    } else if (drawerState === "sms_notification_access") {
      return {
        label: "SMS Notification Setting",
        component: <SMSNotificationSetting {...drawer_data} />,
      };
    } else if (drawerState === "date_and_time_update") {
      return {
        label: "Date And Time Setting Update",
        component: <DateAndTimeUpdate {...drawer_data} />,
      };
    }
  };

  const getEventDetailSpecificIteration = async () => {
    setIsLoadingPage(true);

    let postData = {
      member_id: member_id.id,
      iteration_id: member_id.schedule_id,
      notification_status: false,
    };

    const result = await _event_detail_specific_iteration(postData);

    if (result.code == 200) {
      setSelectedRowData(result?.iteration);
      setIsLoadingPage(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingPage(false);
    }
  };

  useEffect(() => {
    getEventDetailSpecificIteration();
  }, []);

  useEffect(() => {
    handle_data(selectedRowData);
  }, [selectedRowData]);

  if (isLoadingPage == true) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="container-fluid full-page-popup">
        <div className="pt-3 ps-2">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className="row px-10">
          <div className="col-12 col-md-6 col-lg-4">
            <TextField
              className="mt-3"
              id="outlined-basic"
              label="Title"
              variant="outlined"
              name="title"
              value={state.title}
              required={true}
              onChange={handleChange}
              disabled
            />
          </div>
          <div
            className={`col-12 col-md-6 ${
              state.recurring_type == "weekly" ? "col-lg-3" : "col-lg-4"
            }`}
          >
            <TextField
              id="color"
              type="color"
              label="Color"
              name="color"
              className="mt-3"
              variant="outlined"
              required={true}
              value={state.color}
              onChange={handleChange}
              disabled
            />
          </div>

          <div
            className={`col-12 col-md-6 ${
              state.recurring_type == "weekly" ? "col-lg-2" : "col-lg-4"
            }`}
          >
            <FormControl variant="outlined" className="mt-3">
              <InputLabel id="demo-simple-select-outlined-label">
                Recurring Type
              </InputLabel>
              <Select
                disabled
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={state.recurring_type}
                onChange={(e) => handleChange(e)}
                label="recurring Type"
                name="recurring_type"
                className="inputs-fields svg-color"
                MenuProps={{
                  classes: {
                    paper: classes.paper,
                  },
                }}
                sx={{
                  color: get_root_value("--input-text-color"),
                }}
              >
                <MenuItem value="daily">Daily</MenuItem>
                <MenuItem value="weekly">Weekly</MenuItem>
                <MenuItem value="monthly">Monthly</MenuItem>
              </Select>
            </FormControl>
          </div>
          {state.recurring_type == "weekly" && (
            <div className={`col-12 col-md-6 col-lg-3`}>
              <FormControl variant="outlined" className="mt-3">
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Day
                </InputLabel>
                <Select
                  disabled
                  multiple
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={state.weekday}
                  onChange={(e) => handleChange(e)}
                  label="Select Day"
                  name="weekday"
                  className="inputs-fields svg-color"
                  MenuProps={{
                    classes: {
                      paper: classes.paper,
                    },
                  }}
                  sx={{
                    color: get_root_value("--input-text-color"),
                  }}
                >
                  <MenuItem value={0}>Sunday</MenuItem>
                  <MenuItem value={1}>Monday</MenuItem>
                  <MenuItem value={2}>Tuesday</MenuItem>
                  <MenuItem value={3}>Wednesday</MenuItem>
                  <MenuItem value={4}>Thursday</MenuItem>
                  <MenuItem value={5}>Friday</MenuItem>
                  <MenuItem value={6}>Saturday</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}

          <div className="col-12 col-md-6 col-lg-3">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                disabled
                label="Start Date"
                name="start_date"
                inputFormat="DD-MM-YYYY"
                value={state.start_date}
                format="DD-MM-YYYY"
                className="mt-3"
                renderInput={(params) => (
                  <TextField {...params} required={true} />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                disabled
                label="Start Time"
                name="start_time"
                value={state.start_time}
                sx={{ color: "#fff" }}
                className="mt-3"
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                disabled
                label="End Date"
                name="end_date"
                inputFormat="DD-MM-YYYY"
                value={state.end_date}
                format="DD-MM-YYYY"
                className="mt-3"
                renderInput={(params) => (
                  <TextField {...params} required={true} />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                disabled
                label="End Time"
                name="end_time"
                value={state.end_time}
                sx={{ color: "#fff" }}
                className="mt-3"
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>

          <div className="col-12 mt-3 mb-4">
            <h5 className="sale-page-title ms-0">Schedule Detail</h5>
            <hr />
            <div className="mt-4">
              <h4 className="heading-calender">Notifications Setting</h4>
            </div>
            <div className="row">
              {selectedRowData &&
                selectedRowData?.notify_schedule.length > 0 &&
                selectedRowData.notify_schedule.map((notification, index) => {
                  let notify_before_unit =
                    selectedRowData?.notify_before[index]?.notify_before_unit;

                  let notify_before_time =
                    selectedRowData?.notify_before[index]?.notify_before_time;

                  return (
                    <>
                      <div className="col-11 col-md-5 col-lg-7 mt-4">
                        {notification.notification_send_type.map((notify) => {
                          return (
                            <span className="position-relative color-white">
                              <Icon
                                fontSize="18"
                                style={{
                                  color: "var(--portal-theme-primary)",
                                }}
                                className="me-2 setIconPosition"
                                icon="circum:edit"
                                onClick={() =>
                                  handleOpen(
                                    notification,
                                    notify?.name,
                                    notification?._id
                                  )
                                }
                              />

                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={Boolean(notification)}
                                    className="p-1"
                                  />
                                }
                                label={notify.label}
                                className="me-3"
                              />
                            </span>
                          );
                        })}
                      </div>
                      <div className="col-11 col-md-6 col-lg-2 ">
                        <FormControl variant="outlined" className="mt-3">
                          <InputLabel id="demo-simple-select-outlined-label">
                            Notify Before
                          </InputLabel>
                          <Select
                            disabled
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={notify_before_unit}
                            label="Notify Before"
                            name="notify_before_unit"
                            className="inputs-fields svg-color"
                            MenuProps={{
                              classes: {
                                paper: classes.paper,
                              },
                            }}
                            sx={{
                              color: get_root_value("--input-text-color"),
                            }}
                          >
                            <MenuItem value="days">Days</MenuItem>
                            <MenuItem value="hours">Hours</MenuItem>
                            <MenuItem value="minutes">Minutes</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-11 col-md-5 col-lg-2">
                        <TextField
                          disabled
                          className="mt-3"
                          id="outlined-basic"
                          label={`${
                            notify_before_unit == "days"
                              ? "Days"
                              : notify_before_unit == "hours"
                              ? "Hours"
                              : "Minutes"
                          }`}
                          variant="outlined"
                          name="notify_before_time"
                          type="number"
                          value={notify_before_time}
                          required={true}
                          inputProps={{
                            min: 0,
                            max:
                              notification.notify_before_unit == "days"
                                ? 100
                                : notification.notify_before_unit == "hours"
                                ? 24
                                : 60,
                          }}
                        />
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </div>

      <CustomDrawer
        isOpenDrawer={Boolean(drawerState)}
        onCloseDrawer={handleClose}
        pageTitle={handle_drawer()?.label}
        componentToPassDown={handle_drawer()?.component}
        zIndex={1300}
      />

      <FullPagePopupForTitle
        open={EmailNotification}
        setOpen={setEmailNotification}
        title={selectedObject}
        handleClosePopup={handleClosePopup}
        componentToPassDown={
          <>
            <EmailNotificationSetting {...drawer_data} />
          </>
        }
      />
    </>
  );
}
