import * as React from "react";
import { useState } from "react";
import Select from "@mui/material/Select";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { manage_page_acceess_api } from "src/DAL/WebsitePages/templateConfig";
import { detailPageApi } from "src/DAL/WebsitePages/WebsitePages";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import PageLoader from "src/components/GeneralComponents/PageLoader";

export default function ManagePageOptions() {
  const navigate = useNavigate();
  const params = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [manageAcceess, setManageAcceess] = React.useState([]);
  const [selected, setSelected] = useState([]);
  const [pagetitle, setPagetitle] = useState("");

  const get_page_detail = async () => {
    setIsLoading(true);
    const result = await detailPageApi(params.page_slug);
    if (result.code == 200) {
      console.log("result", result);
      setManageAcceess(result.page_options_access);
      formatData(result);
      setPagetitle(result.sale_page.sale_page_title);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const formatData = (data) => {
    let selected_array = [];
    data?.sale_page?.page_options_access?.map((item) => {
      if (item.is_access) {
        selected_array.push(item);
      }
    });
    setSelected(selected_array);
    setIsLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let array = manageAcceess.map((item) => {
      let is_selected = selected.some((object) => item.label === object.label);
      if (is_selected) {
        return {
          ...item,
          is_access: true,
        };
      } else {
        return { ...item, is_access: false };
      }
    });

    const formData = {
      page_options_access: array,
      sale_page_title_slug: params.page_slug,
    };

    const result = await manage_page_acceess_api(formData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      // navigate(`/template-pages`);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    get_page_detail();
  }, []);

  const TABLE_HEAD = [{ id: "name", label: "All Options", alignRight: false }];

  const websitePages = window.location.pathname.includes("/website-pages");

  let breadCrumbMenu = [
    {
      title: websitePages ? "Pages" : "Template Pages",
      navigation: `${websitePages ? `/website-pages` : `/template-pages`}`,
      active: false,
    },
    {
      title: pagetitle,
      active: true,
    },
  ];
  if (isLoading) {
    return <PageLoader />;
  }
  return (
    <div className="container-fluid">
      <div className="row mobile-margin ">
        <div className="col-12 mb-3">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-6">
          <h2>Manage Page Options</h2>
        </div>
      </div>
      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        data={manageAcceess}
        selected={selected}
        setSelected={setSelected}
        checkbox_selection={true}
        selected_by="label"
        pagePagination={true}
      />
      <div className="ms-auto text-end">
        <button
          className="small-contained-button mt-3 ml-auto"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
}
