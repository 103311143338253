import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React from "react";

function CustomConfirmation({
  openDelete,
  setOpenDelete,
  handleAgree,
  title,
  open,
  setOpen,
  title_html,
  description,
  BUTTON_OPTIONS,
}) {
  const handleCloseDialog = () => {
    setOpenDelete(false);
  };
  const handleCloseDialog1 = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={openDelete}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
          },
        }}
      >
        {title_html ? (
          title_html
        ) : (
          <>
            <DialogTitle>
              {title ? title : "Are you sure you want to take this action?"}
            </DialogTitle>
          </>
        )}

        <DialogActions>
          {BUTTON_OPTIONS ? (
            BUTTON_OPTIONS.map((button) => {
              return (
                <Button
                  className="model-button-hover"
                  onClick={button.handleClick}
                >
                  {button.label}
                </Button>
              );
            })
          ) : (
            <>
              <Button
                className="model-button-hover"
                onClick={handleCloseDialog}
              >
                Cancel
              </Button>
              <Button
                className="model-button-hover"
                autoFocus
                onClick={handleAgree}
              >
                Agree
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      {/* New to solve delete issue  */}
      <Dialog
        open={open}
        onClose={handleCloseDialog1}
        PaperProps={{
          style: {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
          },
        }}
      >
        {title_html ? (
          title_html
        ) : (
          <>
            <DialogTitle>
              {title ? title : "Are you sure you want to take this action?"}
              {description && (
                <p
                  style={{
                    fontSize: "14px",
                    color: "white",
                    marginBottom: "0px",
                  }}
                >
                  {description}
                </p>
              )}
            </DialogTitle>
          </>
        )}
        <DialogActions>
          {BUTTON_OPTIONS ? (
            BUTTON_OPTIONS.map((button) => {
              return (
                <Button
                  className="model-button-hover"
                  onClick={() => button.handleClick(button)}
                  autoFocus={button.autoFocus}
                >
                  {button.label}
                </Button>
              );
            })
          ) : (
            <>
              <Button
                className="model-button-hover"
                onClick={handleCloseDialog1}
              >
                Cancel
              </Button>
              <Button
                className="model-button-hover"
                autoFocus
                onClick={handleAgree}
              >
                Agree
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CustomConfirmation;
