import React, { useEffect, useState } from "react";
import MemberMissions from "./Components/MemberMissions";
import MemberQuests from "./Components/MemberQuests";
import { show_proper_words } from "src/utils/constant";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";

const MissionsTab = ({ tabData }) => {
  const [missionList, setMissionList] = useState([]);
  const [questList, setQuestList] = useState([]);
  let tab_value = 0;
  let find_tab = localStorage.getItem("tab_store_value_of_mission_quest");
  if (find_tab) {
    tab_value = JSON.parse(find_tab);
  }
  const [tabValue, setTabValue] = useState(+tab_value);

  const handleChangeTabs = (event, newValue) => {
    setTabValue(newValue);
    localStorage.setItem(
      "tab_store_value_of_mission_quest",
      JSON.stringify(newValue)
    );
  };

  const getMissionList = async () => {
    const missions_data = tabData?.missions.map((mission) => {
      return {
        ...mission,
        title: mission?.mission_info?.title,
        mission_status: mission?.mission_status
          ? show_proper_words(mission?.mission_status)
          : "N/A",
        badge_level_info:
          mission?.membership_level_info?.badge_level_info?.title ?? "N/A",
      };
    });

    const quests_data = tabData?.quests.map((quest) => {
      return {
        ...quest,
        title: quest?.mission_info?.title,
        mission_status: quest?.mission_status
          ? show_proper_words(quest?.mission_status)
          : "N/A",
        badge_level_info:
          quest?.membership_level_info?.badge_level_info?.title ?? "N/A",
      };
    });
    setMissionList(missions_data);
    setQuestList(quests_data);
  };

  useEffect(() => {
    getMissionList();
  }, [tabData]);

  const TABS_OPTIONS = [
    {
      title: "Missions",
      component: <MemberMissions missionList={missionList} />,
    },
    {
      title: "Quests",
      component: (
        <MemberQuests questList={questList} setQuestList={setQuestList} />
      ),
    },
  ];

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 mt-2">
          <MUICustomTabs
            data={TABS_OPTIONS}
            value={tabValue}
            handleChange={handleChangeTabs}
          />
        </div>
      </div>
    </div>
  );
};

export default MissionsTab;
