import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useParams } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import {
  AddAssetDocumentApi,
  EditAssetApi,
  digital_asset_detail_api,
} from "src/DAL/digitalAssetsApi/assets";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { delegates_and_groups_and_assest_categories } from "src/DAL/digitalAssetsApi/categories";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { s3baseUrl } from "src/config/config";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function AddOrUpdateDigitalAsset() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { asset_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [delegatesList, setDelegatesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [groupsList, setGroupsList] = useState([]);
  const [assetCategories, setAssetCategories] = useState([]);
  const [audioFile, setAudio] = useState();
  const [oldAudio, setOldAudio] = useState("");
  const [selectedDelegates, setSelectedDelegates] = useState([]);
  const [groupsName, setGroupsName] = useState([]);

  const [inputs, setInputs] = useState({
    title: "",
    status: "true",
    file_type: "image",
    image: {},
    image_thumbnail: {},
    otherDocument: {},
    short_description: "",
    detailed_description: "",
    category: null,
    video_url: "",
    short: " ",
  });

  const [documentFile, setDocumentFile] = useState({
    image: "",
    image_thumbnail: "",
    otherDocument: "",
  });

  const [oldDocumentFile, setOldDocumentFile] = useState({
    image: "",
    image_thumbnail: "",
    otherDocument: "",
  });

  const getAddMemberData = async () => {
    const result = await delegates_and_groups_and_assest_categories();
    if (result.code === 200) {
      let delegates = result.delegates.map((delegate) => {
        return {
          ...delegate,
          full_name: delegate.first_name + " (" + delegate.email + ")",
        };
      });
      setDelegatesList(delegates);
      setAssetCategories(result.digital_asset_categories);
      setGroupsList(result.groups);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const getAssetDetail = async () => {
    const result = await digital_asset_detail_api(asset_id);
    if (result.code == 200) {
      handleFormatData(result.asset);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const fileChangedHandler = (e) => {
    const { name, files } = e.target;
    let preview = URL.createObjectURL(e.target.files[0]);
    setDocumentFile({ ...documentFile, [name]: preview });
    setInputs({ ...inputs, [name]: files[0] });
  };

  const audioFileChange = (e) => {
    setAudio(e.target.files[0]);
  };

  const handldeDeleteAudio = () => {
    setAudio();
    setOldAudio("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let group_array = [];
    if (groupsName.length > 0) {
      group_array = groupsName.map((group) => {
        return {
          group_slug: group.delegate_group_slug,
        };
      });
    }
    let selected_member_array = [];
    let selected_member_object = {};
    selectedDelegates.map((member) => {
      selected_member_object = {
        consultant_id: member._id,
      };
      selected_member_array.push(selected_member_object);
    });
    // end
    if (inputs.short_description.length > 500) {
      enqueueSnackbar("Short Description Must Be Less Than 500 Characters", {
        variant: "error",
      });
      setIsLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("title", inputs.title);
    formData.append("short_description", inputs.short_description);
    formData.append("status", inputs.status);
    formData.append("file_type", inputs.file_type);
    formData.append("detailed_description", inputs.detailed_description);
    formData.append("group", JSON.stringify(group_array));
    formData.append("consultant", JSON.stringify(selected_member_array));
    if (documentFile.image_thumbnail) {
      formData.append("thumbnail", inputs.image_thumbnail);
    }
    if (inputs.file_type === "url") {
      formData.append("video_url", inputs.video_url);
    } else {
      let { file_type } = inputs;
      if (file_type == "audio" && audioFile) {
        formData.append("asset_file", audioFile);
      } else if (file_type == "image" && documentFile.image) {
        formData.append("asset_file", inputs.image);
      } else if (file_type == "other_assets" && documentFile.otherDocument) {
        formData.append("asset_file", inputs.otherDocument);
      } else {
        if (!asset_id) {
          enqueueSnackbar(file_type + " is reuired", {
            variant: "error",
          });
          setIsLoading(false);
          return;
        }
      }
    }

    if (inputs.category) {
      formData.append("category", inputs.category._id);
    }

    const result = asset_id
      ? await await EditAssetApi(formData, asset_id)
      : await AddAssetDocumentApi(formData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(`/digital-assets`);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeOthers = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleFormatData = (data) => {
    let consultants = [];
    data.consultant.map((consultant) => {
      let user = consultant._id;
      if (user) {
        user.full_name = user.first_name + " (" + user.email + ")";
        consultants.push(user);
      }
    });
    setSelectedDelegates(consultants);

    setOldDocumentFile({
      image: data.assets_images_url?.thumbnail_1,
      image_thumbnail: data.assets_thumbnail,
      otherDocument: data.assets_file_url,
    });
    setOldAudio(data.assets_file_url);

    let groups = data.group.map(({ _id }) => _id);
    setGroupsName(groups);

    setInputs(data);
    setIsLoading(false);
  };

  useEffect(() => {
    getAddMemberData();
    if (asset_id) {
      setIsLoading(true);
      getAssetDetail();
    }
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(`/digital-assets`)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h2>{`${asset_id ? "Edit" : "Add"} Asset`}</h2>
        </div>
      </div>
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Asset Title"
            variant="outlined"
            fullWidth
            name="title"
            value={inputs.title}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">Asset Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="file_type"
              value={inputs.file_type}
              label="Asset Type"
              onChange={handleChange}
              required
            >
              <MenuItem value="image">Image</MenuItem>
              <MenuItem value="audio">Audio</MenuItem>
              <MenuItem value="url">URL</MenuItem>
              <MenuItem value="other_assets">Other</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">Asset Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="status"
              value={inputs.status}
              label="Asset Status"
              onChange={handleChange}
              required
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        {inputs.file_type === "url" && (
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="URL"
              variant="outlined"
              fullWidth
              name="video_url"
              value={inputs.video_url}
              onChange={handleChange}
              required
            />
          </div>
        )}
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <MUIAutocomplete
            inputLabel="Asset Categories"
            selectedOption={inputs.category}
            setSelectedOption={(value) => {
              handleChangeOthers("category", value);
            }}
            optionsList={assetCategories}
            name="title"
            required
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <MUIAutocomplete
            inputLabel="Groups"
            selectedOption={groupsName}
            setSelectedOption={setGroupsName}
            optionsList={groupsList}
            multiple
            name="title"
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <MUIAutocomplete
            inputLabel="Delegates"
            selectedOption={selectedDelegates}
            setSelectedOption={setSelectedDelegates}
            optionsList={delegatesList}
            multiple
            name="full_name"
          />
        </div>

        {inputs.file_type === "audio" && (
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Upload Audio *</p>
                <FormHelperText className="pt-0">
                  Allowed Formats is "Mp3",200Mb
                </FormHelperText>
              </div>
              <div className="col-2">
                {oldAudio && (
                  <DeleteIcon
                    onClick={handldeDeleteAudio}
                    className="mt-3 icon-color"
                  />
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="audio">
                  <Input
                    accept="audio/mp3,audio/*;capture=microphone"
                    id="audio"
                    multiple
                    name="audio"
                    type="file"
                    onChange={audioFileChange}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            <p className="text-secondary">{audioFile && audioFile.name}</p>
          </div>
        )}
        {inputs.file_type == "image" && (
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Upload Image *</p>
                <FormHelperText className="pt-0">
                  Image Size( 1000*670) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {documentFile.image ? (
                  <img src={documentFile.image} height="50" />
                ) : oldDocumentFile.image ? (
                  <img src={s3baseUrl + oldDocumentFile.image} height="50" />
                ) : (
                  ""
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="image"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.image?.name && (
              <p className="text-secondary">{inputs.image.name}</p>
            )}
          </div>
        )}
        {inputs.file_type == "other_assets" && (
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Upload Other Document *</p>
                <FormHelperText className="pt-0">
                  Other Document *("PDF", "DOC", "XLSX", "DOCX","CSV","200MB")
                </FormHelperText>
              </div>
              <div className="col-2"></div>
              <div className="col-5 text-end pt-2">
                {oldDocumentFile.otherDocument && (
                  <a
                    href={s3baseUrl + oldDocumentFile.otherDocument}
                    target="_blank"
                    className="me-2 small-contained-button"
                  >
                    View File
                  </a>
                )}
                <label htmlFor="contained-button-file-other">
                  <Input
                    accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf,.png,.csv"
                    id="contained-button-file-other"
                    multiple
                    type="file"
                    name="otherDocument"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.otherDocument?.name && (
              <p className="text-secondary">{inputs.otherDocument.name}</p>
            )}
          </div>
        )}

        {inputs.file_type !== "url" && (
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Upload Asset Thumbnail </p>
                <FormHelperText className="pt-0">
                  Image Size (100*100) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {documentFile.image_thumbnail ? (
                  <img src={documentFile.image_thumbnail} height="50" />
                ) : oldDocumentFile.image_thumbnail ? (
                  <img
                    src={s3baseUrl + oldDocumentFile.image_thumbnail}
                    height="50"
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file-thumbnail">
                  <Input
                    accept="image/*"
                    id="contained-button-file-thumbnail"
                    multiple
                    type="file"
                    name="image_thumbnail"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.image_thumbnail?.name && (
              <p className="text-secondary">{inputs.image_thumbnail.name}</p>
            )}
          </div>
        )}

        <div className="col-12 mt-4">
          <FormControl fullWidth>
            <TextField
              id="outlined-multiline-flexible"
              label="Short Description "
              multiline
              rows={6}
              name="short_description"
              value={inputs.short_description}
              onChange={handleChange}
            />
            <FormHelperText>Maximum limit 500 characters</FormHelperText>
          </FormControl>
        </div>
        <div className="col-12 mt-4">
          <h4>Detail Description</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="detailed_description"
            editorHeight={320}
          />
        </div>
        <div className="text-end mt-4" id={asset_id ? "fixedbutton" : ""}>
          <button className="small-contained-button">{`${
            asset_id ? "Update" : "Submit"
          }`}</button>
        </div>
      </form>
    </div>
  );
}
