import { useEffect, useState } from "react";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import moment from "moment";
import arrowCircleDownFill from "@iconify/icons-eva/arrow-circle-down-fill";
import { Icon } from "@iconify/react";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import Label from "src/components/Label";
import { dd_date_format, show_proper_words } from "src/utils/constant";
import { download_csv_file } from "src/utils/convertHtml";
import FilteredChip from "src/components/FilteredChip";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import GeneralRequestForCsv from "../MembersList/GeneralRequestForCsv";
import PageLoader from "src/components/GeneralComponents/PageLoader";
import CustomPopover from "src/components/GeneralComponents/CustomPopover";
import SubscriptionDetail from "../SubscriptionList/SubscriptionDetail";
import { Tooltip } from "@mui/material";
import CopyToClipboard from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

let member_options = [
  {
    label: "First name",
    value: "first_name",
    is_checked: true,
    is_disabled: true,
  },
  {
    label: "Last name",
    value: "last_name",
    is_checked: true,
    is_disabled: true,
  },
  { label: "Email", value: "email", is_checked: true, is_disabled: true },
  {
    label: "Phone number",
    value: "contact_number",
    is_checked: true,
    is_disabled: true,
  },
  {
    label: "Product",
    value: "product",
    is_checked: false,
  },
  {
    label: "Installment Amount",
    value: "installment_amount",
    is_checked: false,
  },
  {
    label: "Subscription Date",
    value: "subscription_date",
    is_checked: false,
  },
  {
    label: "Next Invoice Date",
    value: "next_invoice_date",
    is_checked: false,
  },
];

export default function MemberSubscriptionsHistory({
  tabData,
  searchFunction,
  searchText,
  setSearchText,
  pageCount,
  totalPages,
  handleChangePages,
  custom_pagination,
  handleOpenFilterDrawer,
  filterStateUpdated,
  filterData,
  EMPTY_FILTER,
  ALTER_FILTER,
  handleDeleteChip,
  handleClearFilter,
  filterQuery,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionsData, setSubscriptionsData] = useState([]);
  const [showExportcsvFile, setShowExportcsvFile] = useState(false);
  const [showPop, setshowPop] = useState(false);
  const [value, setValue] = useState({});

  const showPopUPcsv = () => {
    setShowExportcsvFile(true);
  };

  const handleOpenPopup = (row) => {
    setValue(row);
    setshowPop(true);
  };

  const getMembersList = () => {
    setIsLoading(true);
    const members = tabData?.map((event, index) => {
      let member_name = "N/A";
      if (event.member_info) {
        member_name =
          event.member_info.first_name +
          " " +
          event.member_info.last_name +
          " (" +
          event.member_info.email +
          ")";
      }
      let created_at = "N/A";
      if (event.createdAt) {
        created_at = moment.utc(event.createdAt).format("DD-MM-YYYY");
      }

      let product = "N/A";
      if (event.page_info) {
        product = `Sale Page (${event.page_info.sale_page_title} | ${event?.plan_info?.plan_title})`;
      } else {
        if (event.payment_request_info) {
          product = `Payment Request (${show_proper_words(
            event.payment_request_info.request_title +
              " | " +
              event.payment_request_info.request_type
          )})`;
        }
      }
      let invoice_date = "N/A";
      if (event.next_invoice_date) {
        invoice_date = dd_date_format(event.next_invoice_date);
      }

      return {
        ...event,
        member_name: member_name,
        product,
        created_at,
        installment_amount: "£" + event.installment_amount.toFixed(2),
        invoice_date,
        statusDate: event.subscription_status,
      };
    });
    setSubscriptionsData(members);
    setIsLoading(false);
  };

  const handleCopiedUrl = () => {
    enqueueSnackbar("Subscription Id Copied to clipboard", {
      variant: "success",
    });
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "member_name",
      label: "Member",
      renderData: (row) => {
        return (
          <>
            <span
              className={`${
                row.stripe_subscription_id ? "highlight_div pointer_cursor" : ""
              }`}
              onClick={() => {
                if (!!row.stripe_subscription_id) {
                  handleOpenPopup(row);
                }
              }}
            >
              {row.member_name}
            </span>
          </>
        );
      },
    },
    { id: "product", label: "Product" },
    { id: "stripe_mode", label: "Subscription Mode" },
    { id: "installment_amount", label: "Installment Amount" },
    {
      id: "created_at",
      label: "Subscription Date",
    },
    { id: "invoice_date", label: "Next Invoice Date" },
    {
      id: "member_name",
      label: "Subscription ID",
      renderData: (row) => {
        return (
          <>
            {row?.stripe_subscription_id ? (
              <span>
                <CopyToClipboard
                  className="pointer me-2"
                  text={row?.stripe_subscription_id}
                  onCopy={() => handleCopiedUrl(true)}
                >
                  <Tooltip title="Copy Subscription ID">
                    <ContentCopyIcon
                      style={{
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </CopyToClipboard>
                {row?.stripe_subscription_id}
              </span>
            ) : (
              "N/A"
            )}
          </>
        );
      },
    },
    {
      id: "statusDate",
      label: "Status",
      renderData: (row) => {
        return (
          <Label
            variant="ghost"
            color={row.statusDate === false ? "error" : "success"}
          >
            {row.statusDate === false ? "Expired" : "Active"}
          </Label>
        );
      },
    },
  ];
  const headers = [
    { label: "Member", key: "member_name" },
    { label: "Product", key: "product" },
    { label: "Subscription Mode", key: "stripe_mode" },
    { label: "Installment Amount", key: "installment_amount" },
    { label: "Subscription Date", key: "created_at" },
    { label: "Next Invoice Date", key: "invoice_date" },
  ];
  const handleExportClick = () => {
    download_csv_file(headers, subscriptionsData);
  };

  useEffect(() => {
    getMembersList();
  }, [tabData]);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="mt-3">
        <div className="button-history">
          <button
            className="small-contained-button me-2 mt-1 mb-4"
            onClick={showPopUPcsv}
          >
            Request For CSV &nbsp;&nbsp; <Icon icon={arrowCircleDownFill} />
          </button>
          <button
            className="small-contained-button ms-1 mt-1 mb-4"
            onClick={handleOpenFilterDrawer}
          >
            Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
          </button>
        </div>
        <FilteredChip
          data={filterStateUpdated}
          tempState={filterData}
          EMPTY_FILTER={EMPTY_FILTER}
          ALTER_FILTER={ALTER_FILTER}
          onDeleteChip={handleDeleteChip}
          onClear={handleClearFilter}
        />
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={subscriptionsData}
          className="card-with-background"
          custom_pagination={custom_pagination}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
        <GeneralPopUpModel
          open={showExportcsvFile}
          setOpen={setShowExportcsvFile}
          title="Export CSV file request"
          componentToPassDown={
            <GeneralRequestForCsv
              options_array={member_options}
              collection_name={"subscriptions_history"}
              filter_data={filterQuery}
              setShowExportcsvFile={setShowExportcsvFile}
            />
          }
        />
      </div>

      <CustomPopover
        isOpenPop={showPop}
        isClosePop={setshowPop}
        heigh="200px"
        title={value?.member_name ?? "Detail"}
        componentToPassDown={<SubscriptionDetail value={value} />}
      />
    </>
  );
}
