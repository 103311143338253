import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import { useEffect } from "react";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import {
  add_programme_section_api,
  edit_programme_section_api,
  programme_section_detail_api,
} from "src/DAL/Programme/ProgrammeSections/ProgrammeSections";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const Input = styled("input")({
  display: "none",
});

export default function AddOrUpdateProgrammeSection() {
  const navigate = useNavigate();
  const params = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [formType, setFormType] = useState("ADD");
  const [inputs, setInputs] = useState({
    title: "",
    button_text: "",
    button_url: "",
    video_url: "",
    order: 0,
    status: true,
    image: {},
    short_description: "",
    detailed_description: "",
  });

  const handleNavigate = () => {
    navigate(`/programmes/sections/${params.programme_slug}`);
  };

  const getSectionData = async () => {
    setIsLoading(true);
    let result = await programme_section_detail_api(params.section_slug);
    if (result.code == 200) {
      setFormType("EDIT");
      setInputs(result.program_section);
      setIsLoading(false);
    } else {
      handleNavigate();
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("title", inputs.title);
    formData.append("status", inputs.status);
    formData.append("button_text", inputs.button_text);
    formData.append("button_url", inputs.button_url);
    formData.append("video_url", inputs.video_url);
    formData.append("detailed_description", inputs.detailed_description);

    if (formType === "EDIT") {
      formData.append("order", inputs.order);
    } else {
      formData.append("program_slug", params.programme_slug);
    }
    const result =
      formType === "ADD"
        ? await add_programme_section_api(formData)
        : await edit_programme_section_api(formData, params.section_slug);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      handleNavigate();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    if (params && params.section_slug) {
      if (state) {
        setFormType("EDIT");
        setInputs(state);
      } else {
        getSectionData();
      }
    }
  }, []);

  if (isLoading === true) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={handleNavigate}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2>{`${formType === "ADD" ? "Add" : "Edit"} Section`}</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Section Title"
              variant="outlined"
              fullWidth
              required
              name="title"
              value={inputs.title}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Main Video Url"
              variant="outlined"
              fullWidth
              name="video_url"
              value={inputs.video_url}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Button Text"
              variant="outlined"
              fullWidth
              name="button_text"
              value={inputs.button_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Button Url"
              variant="outlined"
              fullWidth
              name="button_url"
              value={inputs.button_url}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                required
                value={inputs.status}
                label="Status *"
                onChange={handleChange}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          {formType == "EDIT" && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Order"
                variant="outlined"
                fullWidth
                required
                name="order"
                value={inputs.order}
                onChange={handleChange}
              />
            </div>
          )}
          <div className="col-12 mt-4">
            <h4>Detail Description</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="detailed_description"
              editorHeight={320}
            />
          </div>
          <div
            className="text-end mt-4"
            id={formType == "ADD" ? "" : "fixedbutton"}
          >
            <button className="small-contained-button">
              {formType == "ADD" ? "Submit" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
