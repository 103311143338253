import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { s3baseUrl } from "src/config/config";
import ImageBox from "src/components/ModelBox/ImageBox";
import {
  Member_Delete_Request,
  badge_list_api,
  card_list_api,
  delete_bedge_api,
  delete_card_api,
  delete_category_api,
  delete_member_account_api,
  help_video_categories_list_api,
} from "src/DAL/HelpVideos/Categories";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { dd_date_format } from "src/utils/constant";
import PageLoader from "src/components/GeneralComponents/PageLoader";

// ----------------------------------------------------------------------

export default function AccountTerminationRequests() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [openRevert, setOpenRevert] = useState(false);
  const [action, setAction] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [categoriesData, setCategoriesData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState("");
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const getCategoriesListing = async (text) => {
    setIsLoading(true);
    const result = await Member_Delete_Request(page, rowsPerPage, text);
    if (result.code == 200) {
      const categories = result.member.map((item) => {
        return {
          ...item,
          table_avatar: {
            src: s3baseUrl + item.profile_image,
            alt: item.first_name,
          },
          user_name: item.first_name + " " + item.last_name,
          delete_date: item.delete_requested_date
            ? dd_date_format(item.delete_requested_date)
            : "N/A",
        };
      });
      setCategoriesData(categories);
      setTotalPages(result.total_pages);
      setTotalCount(result.total_member_count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageCount(1);
    setPage(0);
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
    setAction("delete");
  };
  const handleAgreeRevert = (value) => {
    setDeleteDoc(value);
    setOpenRevert(true);
    setAction("revert");
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    setOpenRevert(false);
    let postData = {
      action: action,
    };
    const result = await delete_member_account_api(deleteDoc._id, postData);
    if (result.code === 200) {
      setCategoriesData((categoriesData) => {
        return categoriesData.filter((data) => data._id !== deleteDoc._id);
      });
      setAction("");
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleEdit = (value) => {
    navigate(`/card-of-the-day/edit-card/${value._id}`, {
      state: value,
    });
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    localStorage.setItem("delete_account_search", searchText);

    getCategoriesListing(searchText);
  };
  useEffect(() => {
    let search_text = localStorage.getItem("delete_account_search");
    if (search_text) {
      setSearchText(search_text);
      getCategoriesListing(search_text);
    } else {
      setSearchText("");
      getCategoriesListing("");
    }
  }, [page, rowsPerPage]);
  const handleOpen = (image_path) => {
    setImageUrl(image_path);
    setTimeout(() => {
      setOpen(true);
    }, 100);
  };
  const handleClose = () => {
    setOpen(false);
    setImageUrl("");
  };
  const TABLE_HEAD = [
    { id: "action", label: "Action", alignRight: false, type: "action" },
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "Image",
      label: "Profile Image",
      renderData: (row) => {
        return (
          <>
            <Tooltip title={"View Image"}>
              <span style={{ cursor: "pointer" }}>
                <Avatar
                  onClick={() => {
                    handleOpen(row.table_avatar.src);
                  }}
                  src={row.table_avatar.src}
                  alt={row.table_avatar.src}
                />
              </span>
            </Tooltip>
          </>
        );
      },
    },
    {
      id: "user_name",
      label: "Name",
      alignRight: false,
    },
    {
      id: "email",
      label: "Email",
      alignRight: false,
    },
    {
      id: "delete_date",
      label: "Termination Requests Date",
      alignRight: false,
    },
  ];

  const MENU_OPTIONS = [
    {
      label: "Revert Termination Request",
      icon: "akar-icons:edit",
      handleClick: handleAgreeRevert,
    },
    {
      label: "Permanent  Delete Account",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  if (isLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Account Permanently?"}
        handleAgree={handleDelete}
      />{" "}
      <CustomConfirmation
        open={openRevert}
        setOpen={setOpenRevert}
        title={"Are you sure you want to revert this  request?"}
        handleAgree={handleDelete}
      />
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-lg-6 col-sm-12">
            <h2>Account Termination Requests</h2>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={categoriesData}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pagePagination={true}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
        />
      </div>
      {open && imageUrl && (
        <ImageBox open={open} handleClose={handleClose} image_url={imageUrl} />
      )}
    </>
  );
}
