import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { s3baseUrl } from "src/config/config";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  delete_mentorship_category_api,
  list_all_mediatation_category_api,
} from "src/DAL/Mentorship/MentorshipApi";
import { show_proper_words } from "src/utils/constant";
import PageLoader from "src/components/GeneralComponents/PageLoader";

export default function MentorshipCategories() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [categoriesData, setCategoriesData] = useState([]);

  const getCategoriesListing = async () => {
    setIsLoading(true);
    const result = await list_all_mediatation_category_api();
    if (result.code == 200) {
      const categories = result.mentorship_category.map((category) => {
        let salepage = "N/A";
        if (category.sale_page) {
          salepage = category.sale_page.sale_page_title;
        }
        let payemtPlan = "N/A";
        if (category.plan) {
          payemtPlan = category.plan.plan_title;
        }
        return {
          ...category,
          table_avatar: {
            src: s3baseUrl + category.image.thumbnail_2,
            alt: category.title,
          },
          category_status: category.status,
          show_category: show_proper_words(category.show_category_to),
          salepage,
          payemtPlan,
        };
      });
      setCategoriesData(categories);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_mentorship_category_api(
      deleteDoc.mentorship_category_slug
    );
    if (result.code === 200) {
      getCategoriesListing();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleEdit = (value) => {
    navigate(
      `/mentorship-category/edit-category/${value.mentorship_category_slug}`,
      {
        state: value,
      }
    );
  };

  const handleNavigate = () => {
    navigate(`/mentorship-category/add-category`);
  };

  useEffect(() => {
    getCategoriesListing();
  }, []);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const TABLE_HEAD = [
    {
      id: "action",
      label: "Action",
      type: "action",
    },
    { id: "number", label: "#", type: "number" },
    { id: "title", label: "Title" },
    {
      id: "table_avatar",
      label: "Image",
      type: "thumbnail",
    },
    {
      id: "salepage",
      label: "Sale Page",
    },
    {
      id: "payemtPlan",
      label: "Payemt Plan",
    },
    { id: "order", label: "Order" },
    { id: "show_category", label: "Show Category To" },
    {
      id: "category_status",
      label: "Status",
      type: "row_status",
    },
  ];

  if (isLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this category?"}
        handleAgree={handleDelete}
      />

      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-lg-6 col-sm-12">
            <h2>Bonus Content Categories</h2>
          </div>
          <div className="col-lg-6 col-sm-12 text-end ">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Bonus Content Category
            </button>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          MENU_OPTIONS={MENU_OPTIONS}
          data={categoriesData}
          className="card-with-background"
          pagePagination={true}
        />
      </div>
    </>
  );
}
