import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import "react-tagsinput/react-tagsinput.css";
import { Icon } from "@iconify/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useState } from "react";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { useEffect } from "react";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import EmailNotifySetting from "./EmailNotifySetting";
import PushNotifySetting from "./PushNotifySetting";
import WhatsappNotifySetting from "./WhatsappNotifySetting";
import {
  AddOrRemoveNotificationActionApi,
  NotificationActionListingApi,
} from "src/DAL/NotificationAction/NotificationSetting";
import FullPagePopupForTitle from "./FullPagePopupForTitle";
import MessageSetting from "./MessageSetting";
import { NOTIFICATIONS_ARRAY_WITH_SMS } from "src/utils/constant";
import { infoImages } from "src/assets";
import ManageNotificationInfo from "./ManageNotificationInfo";
import SMSSetting from "./SMSSetting";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ManageNotifications() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [EmailNotification, setEmailNotification] = useState(false);
  const [drawerState, setDrawerState] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedObject, setSelectedObject] = useState({});
  const [array, setarray] = useState([]);

  const getNotificationActionListing = async () => {
    setIsLoading(true);
    const result = await NotificationActionListingApi();
    if (result.code == 200) {
      const ListData = result.notification.map((items) => {
        items.MENU_OPTIONS = handleMenu(items);
        return items;
      });
      setarray(ListData);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setDrawerState("");
    setSelectedObject({});
    setEmailNotification(false);
  };
  const handleClickPopUP = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedObject(row);
  };
  const handleClosePopUp = () => {
    setAnchorEl(null);
  };

  const handleOpen = (data, name) => {
    setSelectedObject(data);
    if (name == "email_notification_access") {
      setEmailNotification(true);
    } else {
      setDrawerState(name);
    }
  };

  const handleMenu = (row) => {
    const MENU_OPTIONS = [];
    NOTIFICATIONS_ARRAY_WITH_SMS.map((item) => {
      if (row[item.name]) {
        MENU_OPTIONS.push({
          label: `Update ${item.label} Setting`,
          icon: "akar-icons:edit",
          handleClick: (e) => handleOpen(e, item.name),
        });
      }
    });

    return MENU_OPTIONS;
  };

  const handleNavigate = (row) => {
    navigate(`/manage-notifications/preview/${row.notification_action_slug}`);
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "title",
      label: "Notification Action Title",
      renderData: (row, index) => {
        return (
          <>
            {row.notification_action_description && (
              <Tooltip title={"Info"}>
                <img
                  id="dont-show"
                  src={infoImages}
                  style={{
                    filter:
                      "invert(55%) sepia(50%) saturate(1500%) hue-rotate(20deg)",
                    width: "17px",
                    display: "inline-block",
                    marginRight: "5px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => handleClickPopUP(e, row)}
                />
              </Tooltip>
            )}

            <span className="mb-0">{row.title}</span>
          </>
        );
      },
    },
    {
      id: "checkboxes",
      label: "Access",
      renderData: (row, index) => {
        return (
          <>
            {NOTIFICATIONS_ARRAY_WITH_SMS.map((notification) => {
              let is_show_option = true;

              if (notification.name == "email_notification_access") {
                is_show_option = row?.is_email_send;
              }
              if (notification.name == "push_notification_access") {
                is_show_option = row?.is_push_send;
              }
              if (notification.name == "whatsapp_notification_access") {
                is_show_option = row?.is_whatsapp_send;
              }
              if (notification.name == "message_notification_access") {
                is_show_option = row?.is_message_send;
              }
              if (notification.name == "sms_notification_access") {
                is_show_option = row?.is_sms_send;
              }

              return (
                <span
                  className={`position-relative ${
                    is_show_option ? "" : "cursor-block"
                  }`}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() =>
                          is_show_option
                            ? handleCheckboxChange(
                                row._id,
                                notification.name,
                                row
                              )
                            : null
                        }
                        checked={row[notification.name]}
                        className={is_show_option ? "me-1" : "cursor-block"}
                        disabled={!is_show_option}
                      />
                    }
                    label={notification.label}
                    className="me-5"
                  />
                  {is_show_option &&
                    notification.show_preview &&
                    row.email_notification_info && (
                      <Tooltip title="Preview Email">
                        <Icon
                          icon="fluent:mail-template-24-regular"
                          className="me-2 setIconPositionEmail"
                          onClick={() => handleNavigate(row)}
                        />
                      </Tooltip>
                    )}
                  {is_show_option && (
                    <Icon
                      fontSize="18"
                      style={{
                        color: "var(--portal-theme-primary)",
                      }}
                      className="me-2 setIconPosition"
                      icon="circum:edit"
                      onClick={() => handleOpen(row, notification.name)}
                    />
                  )}
                </span>
              );
            })}
          </>
        );
      },
    },
    { id: "notification_action_for", label: "Notification Action For" },
    {
      id: "action",
      label: "Action",
      type: "action",
      MENU_OPTIONS: "MENU_OPTIONS",
    },
  ];

  const handleSubmit = async (Data, slug) => {
    let postData = Data;
    const result = await AddOrRemoveNotificationActionApi(slug, postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  function handleCheckboxChange(_id, name, data) {
    const arrayCopy = [...array];

    const index = arrayCopy.findIndex((item) => item._id === _id);

    if (index === -1) return;

    const selectedArray = arrayCopy[index];
    selectedArray[name] = !selectedArray[name];
    let SpacificData = !selectedArray[name];

    let postData = {
      action_type: SpacificData ? "remove" : "add",
      action: name,
    };

    handleSubmit(postData, data.notification_action_slug);

    const arrayUpdated = arrayCopy.map((item) => ({
      ...item,
      MENU_OPTIONS: handleMenu(item),
    }));

    setarray(arrayUpdated);
  }

  useEffect(() => {
    getNotificationActionListing();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  let drawer_data = {
    onCloseDrawer: handleClose,
    data: selectedObject,
    listing: getNotificationActionListing,
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handle_drawer = () => {
    if (drawerState === "message_notification_access") {
      return {
        label: "Message Notification Setting",
        component: <MessageSetting {...drawer_data} />,
      };
    } else if (drawerState === "whatsapp_notification_access") {
      return {
        label: "Whatsapp Notification Setting",
        component: <WhatsappNotifySetting {...drawer_data} />,
      };
    } else if (drawerState === "push_notification_access") {
      return {
        label: "Push Notification Setting",
        component: <PushNotifySetting {...drawer_data} />,
      };
    } else if (drawerState === "sms_notification_access") {
      return {
        label: "SMS Notification Setting",
        component: <SMSSetting {...drawer_data} />,
      };
    }
  };

  return (
    <div className="container-fluid">
      <div className="row mb-4">
        <div className="col-6">
          <h2>Notifications Action</h2>
        </div>
      </div>
      <CustomMUITable TABLE_HEAD={TABLE_HEAD} data={array} />
      <FullPagePopupForTitle
        open={EmailNotification}
        setOpen={setEmailNotification}
        title={selectedObject}
        componentToPassDown={<EmailNotifySetting {...drawer_data} />}
      />

      <CustomDrawer
        isOpenDrawer={Boolean(drawerState)}
        onCloseDrawer={handleClose}
        pageTitle={handle_drawer()?.label}
        componentToPassDown={handle_drawer()?.component}
      />
      <ManageNotificationInfo
        handleClick={handleClickPopUP}
        handleClose={handleClosePopUp}
        anchorEl={anchorEl}
        id={id}
        open={open}
        text={selectedObject}
      />
    </div>
  );
}
