import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { useEffect } from "react";
import { uploadImageOns3 } from "src/DAL/commonApi/commonApi";
import { styled } from "@mui/material/styles";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { s3baseUrl } from "src/config/config";
import {
  _get_badge_level_setting,
  _update_badge_level_settings,
} from "src/DAL/AttitudeAccessmentQuestions/AttitudeAccessmentSetting";
import { useParams } from "react-router-dom";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const Input = styled("input")({
  display: "none",
});

export default function BadgeLevelFeedSetting() {
  const { badge_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [badgeTitle, setBadgeTitle] = useState("");

  const [inputs, setInputs] = useState({
    like_point: 0,
    tip_point: 0,
    stick_point: 0,
    coins_count: 0,
    bonus_count: 0,
    maximum_like_count: 0,
    coin_description: "",
    gratitude_description: "",
    like_description: "",
    stick_description: "",
    notice_board: "",
    show_level_filter: true,
    enable_mention_user: false,
    enable_poll_feed: false,
    poll_winner_description: "",
    enable_live_meeting_feed: true,
    enable_live_stream_feed: false,
    enable_survey_feed: false,
    color_code_for_delegate_feed: "#000",
    icon_for_delegate_feed: "",
  });

  const getFeedSetting = async () => {
    setIsLoading(true);
    const result = await _get_badge_level_setting(badge_id, "feed_settings");
    if (result.code == 200) {
      let feed_setting = result.membership_level_badge.feed_settings;
      setBadgeTitle(result.membership_level_badge.title);
      if (feed_setting) {
        setInputs(feed_setting);
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);

    const formData = {
      settings: inputs,
      type: "feed_settings",
    };

    const result = await _update_badge_level_settings(formData, badge_id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const dynamiteFileChangedHandler = async (e) => {
    const { name, files } = e.target;
    const formData = new FormData();
    formData.append("image", files[0]);
    formData.append("width", "300");
    const imageUpload = await uploadImageOns3(formData);
    if (imageUpload.code == 200) {
      setInputs({
        ...inputs,
        [name]: imageUpload.image_path,
      });
    }
  };

  let breadCrumbMenu = [
    {
      title: `Badge Level (${badgeTitle})`,
      navigation: "/badge-level",
      active: false,
    },
    {
      title: "Feed Setting",
      active: true,
    },
  ];

  useEffect(() => {
    getFeedSetting();
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin">
        <div className="col-12">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
        <div className="col-12 mt-3">
          <h2>Feed Setting</h2>
        </div>
      </div>
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
          <TextField
            id="outlined-basic"
            label="Like Coins"
            variant="outlined"
            fullWidth
            required
            name="like_point"
            value={inputs.like_point}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
          <TextField
            id="outlined-basic"
            label="Gratitude Coins"
            variant="outlined"
            fullWidth
            required
            name="tip_point"
            value={inputs.tip_point}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Coins Count"
            variant="outlined"
            fullWidth
            required
            name="coins_count"
            value={inputs.coins_count}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Maximum Like Count"
            variant="outlined"
            fullWidth
            required
            name="maximum_like_count"
            value={inputs.maximum_like_count}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Bonus Coins"
            variant="outlined"
            fullWidth
            required
            name="bonus_count"
            value={inputs.bonus_count}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            type="color"
            id="outlined-basic"
            label="Color Code For Delegate Feed"
            variant="outlined"
            fullWidth
            required
            name="color_code_for_delegate_feed"
            value={inputs.color_code_for_delegate_feed}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Enable mention users in feeds and comments
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="enable_mention_user"
              required
              value={inputs.enable_mention_user}
              label="Enable mention users in feeds and comments"
              onChange={handleChange}
            >
              <MenuItem value={false}>No</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label-21">
              Enable Poll in Feeds
            </InputLabel>
            <Select
              labelId="demo-simple-select-label-21"
              id="demo-simple-select-21"
              name="enable_poll_feed"
              required
              value={inputs.enable_poll_feed}
              label="Enable Poll in Feeds"
              onChange={handleChange}
            >
              <MenuItem value={false}>No</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label-21">
              Enable Survey in Feeds
            </InputLabel>
            <Select
              labelId="demo-simple-select-label-21"
              id="demo-simple-select-21"
              name="enable_survey_feed"
              value={inputs.enable_survey_feed}
              label="Enable Survey in Feeds"
              onChange={handleChange}
            >
              <MenuItem value={false}>No</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Icon For Delegate Feed</p>
              <FormHelperText className="pt-0">
                Image Size(90 X 90) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-2">
              {inputs.icon_for_delegate_feed && (
                <img
                  src={s3baseUrl + inputs.icon_for_delegate_feed}
                  height="50"
                />
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor={`contained-button-file-0`}>
                <Input
                  accept="image/*"
                  id={`contained-button-file-0`}
                  type="file"
                  name={`icon_for_delegate_feed`}
                  onChange={dynamiteFileChangedHandler}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          {inputs.icon_for_delegate_feed == "" ? (
            ""
          ) : (
            <p className="text-secondary">{inputs.icon_for_delegate_feed}</p>
          )}
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Coin Description"
            variant="outlined"
            fullWidth
            required
            multiline
            rows={4}
            name="coin_description"
            value={inputs.coin_description}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Like Description"
            variant="outlined"
            fullWidth
            required
            multiline
            rows={4}
            name="like_description"
            value={inputs.like_description}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Stick Description"
            variant="outlined"
            fullWidth
            required
            multiline
            rows={4}
            name="stick_description"
            value={inputs.stick_description}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Gratitude Description"
            variant="outlined"
            fullWidth
            required
            multiline
            rows={4}
            name="gratitude_description"
            value={inputs.gratitude_description}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <h4>Notice Board</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="notice_board"
            editorHeight={320}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <h4>Poll Winner Description</h4>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="poll_winner_description"
            editorHeight={320}
          />
        </div>
        <div className="text-end mt-4" id="fixedbutton">
          <button className="small-contained-button">
            {isLoadingForm ? "Updating..." : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
