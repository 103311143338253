import { useEffect, useState } from "react";
import { Chip } from "@mui/material";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import moment from "moment";
import arrowCircleDownFill from "@iconify/icons-eva/arrow-circle-down-fill";
import { Icon } from "@iconify/react";
import Label from "src/components/Label";
import {
  convertCurrencyToSign,
  dd_date_format,
  show_proper_words,
} from "src/utils/constant";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { download_csv_file } from "src/utils/convertHtml";
import WhatsappPopUpModel from "src/components/GeneralComponents/WhatsappPopUpModel";
import ShopOrderDetail from "../Shop/ShopOrderDetail";
import FilteredChip from "src/components/FilteredChip";
import PageLoader from "src/components/GeneralComponents/PageLoader";

export default function OrderHistory({
  tabData,
  searchFunction,
  searchText,
  setSearchText,
  pageCount,
  totalPages,
  handleChangePages,
  custom_pagination,
  handleOpenFilterDrawer,
  filterStateUpdated,
  filterData,
  EMPTY_FILTER,
  ALTER_FILTER,
  handleDeleteChip,
  handleClearFilter,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionsData, setSubscriptionsData] = useState([]);
  const [changeStatusOpen, setChangeStatusOpen] = useState(false);

  const [openDelete, setOpenDelete] = useState(false);
  const [leadStatusData, setleadStatusData] = useState({});
  const show_history = (value) => {
    setleadStatusData(value);
    setChangeStatusOpen(true);
  };

  const getMembersList = () => {
    setIsLoading(true);
    const members = tabData.map((member) => {
      return {
        ...member,
        full_name: member.user_info.first_name
          ? member.user_info.first_name + " " + member.user_info.last_name
          : "N/A",
        email: member.user_info.email ? member.user_info.email : "N/A",
        sub_total:
          convertCurrencyToSign(member.currency) + member.sub_total.toFixed(2),
        total: convertCurrencyToSign(member.currency) + member.total.toFixed(2),
        total_discount: `£${member.discount.toFixed(2)}`,
        createdAt: moment(member.createdAt).format("DD-MM-YYYY"),
      };
    });
    setSubscriptionsData(members);
    setIsLoading(false);
  };
  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "full_name",
      label: "Name",
      renderData: (row) => {
        return (
          <p
            className="mb-0"
            style={{ cursor: "pointer" }}
            onClick={() => show_history(row)}
          >
            {row.full_name}
          </p>
        );
      },
    },
    {
      id: "email",
      label: "Email",
      renderData: (row) => {
        return (
          <p
            className="mb-0"
            style={{ cursor: "pointer" }}
            onClick={() => show_history(row)}
          >
            {row.email}
          </p>
        );
      },
    },
    { id: "sub_total", label: "Sub Total" },
    { id: "total_discount", label: "Discount" },
    { id: "total", label: "Total Amount" },
    { id: "createdAt", label: "Date" },
    {
      id: "status",
      label: "Status",
      alignRight: false,
      renderData: (row) => {
        let chipColor;
        let chipTextcolor;
        switch (row.order_status) {
          case "pending":
            chipColor = "#ff9800";
            chipTextcolor = "#fff";
            break;
          case "processing":
            chipColor = "#c19a6b";
            chipTextcolor = "#fff";
            break;
          case "dispatched":
            chipColor = "#0288d1";
            chipTextcolor = "#fff";
            break;
          case "completed":
            chipColor = "#4caf50";
            chipTextcolor = "#fff";
            break;
          case "delivered":
            chipColor = "#4caf50";
            chipTextcolor = "#fff";

            break;
          case "cancelled":
            chipColor = "rgb(211, 47, 47)";
            chipTextcolor = "#fff";

            break;
          default:
            chipColor = "#ff9800";
            chipTextcolor = "#fff";

            break;
        }
        return (
          <Chip
            label={row.order_status}
            style={{
              backgroundColor: chipColor,
              color: chipTextcolor,
              textTransform: "capitalize",
            }}
          ></Chip>
        );
      },
    },
  ];
  const headers = [
    { label: "Member", key: "member_name" },
    { label: "Product", key: "product" },
    { label: "Subscription Mode", key: "stripe_mode" },
    { label: "Subscription Date", key: "created_at" },
    { label: "Next Invoice Date", key: "invoice_date" },
  ];
  const handleExportClick = () => {
    download_csv_file(headers, subscriptionsData);
  };

  useEffect(() => {
    getMembersList();
  }, [tabData]);

  if (isLoading == true) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="mt-3">
        <div className="button-history">
          {/* <button
            className="small-contained-button me-2 mt-1 mb-4"
            onClick={handleExportClick}
          >
            Export Csv &nbsp;&nbsp; <Icon icon={arrowCircleDownFill} />
          </button> */}
        </div>
        <div className="col-12 text-end mb-3">
          <button
            className="small-contained-button me-2"
            onClick={handleOpenFilterDrawer}
          >
            Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
          </button>
        </div>
        <FilteredChip
          data={filterStateUpdated}
          tempState={filterData}
          EMPTY_FILTER={EMPTY_FILTER}
          ALTER_FILTER={ALTER_FILTER}
          onDeleteChip={handleDeleteChip}
          onClear={handleClearFilter}
        />
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={subscriptionsData}
          className="card-with-background"
          custom_pagination={custom_pagination}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
        <WhatsappPopUpModel
          open={changeStatusOpen}
          setOpen={setChangeStatusOpen}
          title={"Order Detail"}
          show_date_and_income={true}
          componentToPassDown={
            <ShopOrderDetail leadStatusData={leadStatusData} />
          }
        />
      </div>
    </>
  );
}
