export const formatFirebaseDuration = (seconds) => {
  if (!seconds || seconds <= 0) {
    return `0m 0s`;
  }
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}m ${remainingSeconds}s`;
};

export const DEFAULT_DATES = {
  start_date: new Date(new Date().setDate(new Date().getDate() - 28)),
  end_date: new Date(new Date().setDate(new Date().getDate() - 1)),
  range_type: "last_28_days",
};

export const filterFirebaseData = (data, name = "eventName") => {
  return data.filter(
    (item) =>
      item[name] !== "(not set)" &&
      item[name] !== "" &&
      item[name] !== "undefined"
  );
};
