import * as React from "react";
import { useState } from "react";
import { useSnackbar } from "notistack";
import "react-tagsinput/react-tagsinput.css";
import TextareaAutosize from "react-textarea-autosize";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { _update_notify_schedule } from "src/DAL/member/member";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import dayjs from "dayjs";
import { TextField } from "@mui/material";
import { get_date_with_admin_time_zone } from "src/utils/constant";
import { usePGIMode } from "src/Hooks/PGIModeContext";

export default function DateAndTimeUpdate({
  onCloseDrawer,
  data,
  _id,
  setRow,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const member_id = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { defaultTimeZone } = usePGIMode();

  const [state, setState] = useState({
    notify_date: dayjs(new Date()).$d,
    notify_time: dayjs(new Date()).$d,
  });

  const handleChangeDate = (name, event) => {
    setState((prevState) => {
      return {
        ...prevState,
        [name]: event.$d,
      };
    });
  };

  const updateListObject = (newItem) => {
    setRow(newItem);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let postData = {
      member_id: member_id.id,
      notify_date: moment(state.notify_date).format("YYYY-MM-DD"),
      notify_time: moment(state.notify_time).format("HH:mm"),
    };

    console.log("postData  ___postData", postData);
    const result = await _update_notify_schedule(postData, _id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      updateListObject(result?.event);
      onCloseDrawer();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const get_start_end_date = (date) => {
    return get_date_with_admin_time_zone(date, "YYYY-MM-DD", defaultTimeZone);
  };

  const get_start_end_time = (date) => {
    return moment.utc(date).format("YYYY-MM-DD HH:mm");
  };

  const handle_data = (data) => {
    setState({
      ...data,
      notify_date: dayjs(get_start_end_date(data.notify_date_time)).$d,
      notify_time: get_start_end_time(data.notify_date_time),
    });
  };

  useEffect(() => {
    handle_data(data);
  }, [data]);

  return (
    <div className="container-fluid new-memories">
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-12">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Date"
              name="notify_date"
              inputFormat="DD-MM-YYYY"
              value={state.notify_date}
              format="DD-MM-YYYY"
              className="mt-3"
              onChange={(e) => handleChangeDate("notify_date", e)}
              renderInput={(params) => (
                <TextField {...params} required={true} />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="col-12">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label="Time"
              name="notify_time"
              value={state.notify_time}
              sx={{ color: "#fff" }}
              className="mt-3"
              onChange={(e) => handleChangeDate("notify_time", e)}
              renderInput={(params) => (
                <TextField {...params} required={true} />
              )}
            />
          </LocalizationProvider>
        </div>

        <div className="text-end mt-4">
          <button type="submit" className="small-contained-button">
            {isLoading ? "Updating" : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
