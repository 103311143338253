import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { Icon } from "@iconify/react";
import { download_csv_file } from "src/utils/convertHtml";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { afflicateChangeid, member_login_api } from "src/DAL/member/member";
import { delegate_Url, s3baseUrl } from "src/config/config";
import {
  change_nurture_to_seld,
  commission_sync,
  consultant_listing_with_filter,
  consultant_listing_with_filter_new,
  consultant_listing_with_pagination,
  DeleteConsultantApi,
  syncCommissionConsultantApi,
} from "src/DAL/consultant/consultant";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import AffliateConfirmationPopUp from "src/components/ModalPopover/AffliateConfirmationPopUp";
import { CSVLink } from "react-csv";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import DelegateFilterDateBase from "./ConsultantFilterDateBase";
import moment from "moment";
import FilteredChip from "src/components/FilteredChip";
import ChallangeCustomConfirmationLoad from "./ChangeNurtureCustomConfirmationLoad";
import ChangeNurtureCustomConfirmationLoad from "./ChangeNurtureCustomConfirmationLoad";
import { dd_date_format, show_proper_words } from "src/utils/constant";
import ProgramPopOver from "./ProgramPopOver";
import GeneralRequestForCsv from "../MembersList/GeneralRequestForCsv";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const headers = [
  { label: "Name", key: "first_name" },
  { label: "Email", key: "email" },
  { label: "Total Commission", key: "total_commission" },
  { label: "Commission Paid", key: "commission_paid" },
  { label: "Commission Due", key: "commission_due" },
  { label: "Total Leads", key: "total_leads" },
  { label: "Type", key: "type" },
];

let member_options = [
  {
    label: "First name",
    value: "first_name",
    is_checked: true,
    is_disabled: true,
  },
  {
    label: "Last name",
    value: "last_name",
    is_checked: true,
    is_disabled: true,
  },
  { label: "Email", value: "email", is_checked: true, is_disabled: true },
  {
    label: "Contact Number",
    value: "contact_number",
    is_checked: true,
    is_disabled: true,
  },
  {
    label: "Total commission",
    value: "total_commission",
    is_checked: false,
  },
  {
    label: "Commission paid",
    value: "commission_paid",
    is_checked: false,
  },
  {
    label: "Commission due",
    value: "commission_due",
    is_checked: false,
  },
  {
    label: "Last login activity",
    value: "last_login_activity",
    is_checked: false,
  },
];

const TODAY_DATE = new Date();

function getDayNumber(startDateString) {
  const startDate = new Date(startDateString);
  const currentDate = new Date();
  if (currentDate < startDate) {
    return 0;
  }
  const diffInTime = currentDate.getTime() - startDate.getTime();
  const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24)); // Convert milliseconds to days
  return diffInDays + 1; // Days are 1-based, not 0-based
}

export default function ConsultantList({
  type,
  heading,
  start_route,
  csv_type,
}) {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [inputs, setInputs] = useState({
    name: "",
    affiliate_url_name: "",
  });
  const [csvData, setCsvData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [openAffliatePopup, setopenAffliatePopup] = useState(false);
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [openSyn, setOpenSyn] = useState(false);
  const [openSynCommsion, setOpenSynCommsion] = useState(false);
  const [openChangeNurture, setOpenChangeNurture] = useState(false);
  const [openChangeNurtureName, setOpenChangeNurtureName] = useState("");
  const [affliateValue, setaffliateValue] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [programsList, setProgramsList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [program, setprogram] = useState([]);
  const [showExportcsvFile, setShowExportcsvFile] = useState(false);
  const [filterQuery, setFilterQuery] = useState({});
  const showPopUPcsv = () => {
    setShowExportcsvFile(true);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [input, setInput] = useState({
    change_for_call_type: false,
    change_for_members: false,
    switch_to: "self",
    other_delegates: [],
    change_for_nurture_members: false,
  });

  const EMPTY_FILTER = {
    program: [],
    start_date: null,
    end_date: null,
    // role: null,
    commission_text: null,
    search_by_commission: false,
    commission_from: 0,
    commission_to: 0,
    status: "all",
  };

  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [filterUpdated, setFilterUpdated] = useState(EMPTY_FILTER);

  const handleChangeOthers = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };
  const handleChangeOthersChange = (name, value) => {
    setInput((values) => ({ ...values, [name]: value }));
  };

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };
  const handlePayCommission = () => {
    navigate(`${start_route}/pay-commission`);
  };

  const handleCommissionAgreement = () => {
    commission_sync_api();
  };

  const handleCommissionSync = () => {
    setOpenSynCommsion(true);
  };

  const handleCommissionSyncSingle = (row) => {
    setDeleteDoc(row);
    setOpenSyn(true);
  };
  const handleChangeNurture = (row) => {
    setDeleteDoc(row);
    setOpenChangeNurtureName(
      row.first_name + " " + row.last_name + " " + "(" + row.email + ")"
    );
    setOpenChangeNurture(true);
  };

  const handleChangeAffiliate = (event) => {
    let value = event.target.value;
    let name = event.target.name;

    value = value.toLowerCase().replace(/\s+/g, "-");

    setInputs((values) => ({ ...values, [name]: value }));
  };
  const commission_sync_api = async () => {
    setIsLoading(true);
    const result = await commission_sync();
    if (result.code === 200) {
      getConsultantListingFilter();
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
      setOpenSynCommsion(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setOpenSynCommsion(false);
    }
  };
  const commission_sync_single_api = async () => {
    setIsLoading(true);
    const result = await commission_sync();
    if (result.code === 200) {
      getConsultantListingFilter();
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const getConsultantListingFilter = async (filter_data) => {
    setIsLoading(true);
    let postData = { ...filter_data };
    postData.commission_from = +postData.commission_from;
    postData.commission_to = +postData.commission_to;

    if (postData.program.length > 0) {
      postData.program = postData.program.map((item) => item._id);
    }
    // if (postData.role) {
    //   postData.role = postData.role.value;
    // }
    if (postData.start_date) {
      postData.start_date = moment(postData.start_date).format("YYYY-MM-DD");
    }
    if (postData.end_date) {
      postData.end_date = moment(postData.end_date).format("YYYY-MM-DD");
    }
    postData.team_type = type;

    let search_keyword = "";
    if (searchText) {
      search_keyword = searchText;
    } else if (localStorage.getItem(type)) {
      search_keyword = localStorage.getItem(type);
      setSearchText(localStorage.getItem(type));
    }

    const result = await consultant_listing_with_filter_new(
      page,
      rowsPerPage,
      search_keyword,
      postData
    );
    if (result.code === 200) {
      setTotalPages(result.total_pages);
      const data = result.consultant_list.map((consultant) => {
        let due_amount = consultant.commission_due.toFixed(2);
        let total_amount = consultant.total_commission.toFixed(2);

        let isChatAllow = "No";
        let start_date_string = "N/A";
        let isSuperDelegate = "No";

        if (consultant.is_chat_allow) {
          isChatAllow = "Yes";
        }

        if (consultant.is_super_delegate) {
          isSuperDelegate = "Yes";
        }

        if (consultant.start_date) {
          start_date_string = `${dd_date_format(consultant.start_date)} `;
          if (getDayNumber(consultant.start_date) > 0) {
            start_date_string += `(${getDayNumber(
              consultant.start_date
            )} days)`;
          }
        }

        return {
          ...consultant,
          isChatAllow,
          start_date_string,
          isSuperDelegate,
          name: consultant.first_name + " " + consultant.last_name,
          table_avatar: {
            src: s3baseUrl + consultant.image.thumbnail_1,
            alt: consultant.first_name,
          },
          member: "View Members",
          subscriber: "View Subscriber",
          MENU_OPTIONS: handleMenu(consultant),
          id: consultant._id,
          lastName: consultant.last_name,
          status: consultant.status,
          profile: consultant.image.thumbnail_1,
          type: consultant.team_type,
          total_leads: consultant.total_leads,
          memberCount: consultant.member_count,
          selected_address: consultant.address,
          selected_city: consultant.city,
          selected_contact_number: consultant.contact_number,
          main_heading: consultant.main_heading,
          selected_state: consultant.state,
          last_login_activity: consultant.last_login_activity
            ? moment(consultant.last_login_activity).format(
                "DD-MM-YYYY hh:mm A"
              )
            : "N/A",
          total_leads: consultant.total_leads,
          selected_team_type: consultant.team_type,
          selected_time_zone: consultant.time_zone,
          contactNumber: consultant.contact_number,
          refUser: consultant.affiliate_url_name,
          consultant_object: consultant,
          total_amount: "£" + total_amount,
          due_amount: "£" + due_amount,
          commission_paid: "£" + consultant.commission_paid,
          // rank: consultant.rank,
        };
      });

      const chipData = { ...filter_data };
      let commission_text = "";
      if (chipData.search_by_commission) {
        commission_text = `Due Commission From ${chipData.commission_from} To ${chipData.commission_to}`;
        chipData.commission_text = commission_text;
        delete chipData.search_by_commission;
        delete chipData.commission_from;
        delete chipData.commission_to;
      }
      setFilterData(() => {
        return {
          ...filter_data,
          commission_text,
        };
      });

      setFilterUpdated(chipData);
      setUserList(data);
      setTotalCount(result.total_count);
      setFilterQuery(result.query_consultant);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleClearFilter = () => {
    getConsultantListingFilter(EMPTY_FILTER);
    localStorage.removeItem(type + "delegate_filter_data");
    setFilterDrawerState(false);
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getConsultantListingFilter(filterData);
    localStorage.setItem(type, searchText);
    localStorage.setItem(
      type + "delegate_filter_data",
      JSON.stringify(filterData)
    );
    handleCloseFilterDrawer();
  };

  const handleDeleteChip = (data) => {
    if (!data.commission_text) {
      data.commission_from = 0;
      data.commission_to = 0;
      data.search_by_commission = false;
    }
    setPage(0);
    setPageCount(1);
    getConsultantListingFilter(data);
    handleCloseFilterDrawer();
    localStorage.setItem("delegate_filter_data", JSON.stringify(data));
  };

  const handleMembersList = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${start_route}/members-list/${value._id}`);
  };
  const handleSubscriber = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${start_route}/subscriber/${value._id}`);
  };

  const handleEdit = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${start_route}/edit-consultant/${value._id}`, {
      state: value,
    });
  };
  const handleCommsionHistory = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${start_route}/commission-history/${value._id}`, {
      state: value,
    });
  };
  const handleChangePassword = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${start_route}/change-password/${value._id}`, {
      state: value,
    });
  };
  const handleDelegates90DaysQuestions = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${start_route}/delegate-90-days-questions/${value._id}`, {
      state: value,
    });
  };
  const handleDelegatesTrackingHistory = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${start_route}/tracking-history/${value._id}`, {
      state: value,
    });
  };

  const handleToDoTracker = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${start_route}/to-do-tracker/${value._id}`, {
      state: value,
    });
  };
  const handleChangeGroup = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${start_route}/groups/${value._id}`, {
      state: value,
    });
  };
  const handleChangeEvents = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${start_route}/events/${value._id}`, {
      state: value,
    });
  };
  const handleChangePods = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${start_route}/pods-list/${value._id}`, {
      state: value,
    });
  };
  const handleChangeMember = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${start_route}/member-list/${value._id}`, {
      state: value,
    });
  };
  const handleChangeGoalStatement = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${start_route}/goal-statement-list/${value._id}`, {
      state: value,
    });
  };
  const ManageTemplateAccess = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${start_route}/manage-template-access/${value._id}`, {
      state: value,
    });
  };
  const ManageNavItemsAccess = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${start_route}/manage-navitems-access/${value._id}`, {
      state: value,
    });
  };
  const ManageCommissionAccess = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${start_route}/manage-commission-access/${value._id}`, {
      state: value,
    });
  };
  const ManageAssessmentQuestion = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${start_route}/assessment-study/${value._id}`, {
      state: value,
    });
  };
  const ManageAccessDelegates = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${start_route}/manage-access/${value._id}`, {
      state: value,
    });
  };

  const LoginAsDelegate = async (value) => {
    setIsLoading(true);
    const data = {
      email: value.email,
      user_type: "consultant",
    };
    const result = await member_login_api(data);
    if (result.code === 200) {
      window.open(`${delegate_Url}login-by-admin/${result.token}`);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handlechangeAffiliate = async () => {
    setopenAffliatePopup(false);
    setIsLoading(true);
    const postData = {
      affiliate_url_name: inputs.affiliate_url_name,
      user_type: "consultant",
    };

    const result = await afflicateChangeid(postData, deleteDoc.id);
    if (result.code === 200) {
      setIsLoading(false);
      getConsultantListingFilter(filterData);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setopenAffliatePopup(true);
    }
  };
  const handlechangeAffiliateID = (value) => {
    setDeleteDoc(value);
    setaffliateValue(value);
    setInputs((inputs) => ({
      ...inputs,
      ["affiliate_url_name"]: value?.affiliate_url_name
        ? value?.affiliate_url_name
        : "",
      ["name"]: value?.first_name + " " + value?.last_name,
    }));
    setopenAffliatePopup(true);
  };
  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await DeleteConsultantApi(deleteDoc.id);
    if (result.code === 200) {
      getConsultantListingFilter(filterData);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handlesyncCommsion = async () => {
    setOpenSyn(false);
    setIsLoading(true);
    const result = await syncCommissionConsultantApi(deleteDoc._id);
    if (result.code === 200) {
      getConsultantListingFilter(filterData);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangeNurtureSelf = async () => {
    if (input.switch_to == "other") {
      if (input.other_delegates.length <= 0) {
        enqueueSnackbar("please select atleast one nurture", {
          variant: "error",
        });
        return;
      }
    }

    const delegare_list = input.other_delegates.map((item) => ({
      _id: item._id,
    }));

    setIsLoading(true);
    let postData = {
      consultant_id: deleteDoc._id,
      change_for_call_type: input.change_for_call_type,
      change_for_members: input.change_for_members,
      switch_to: input.switch_to,
      other_delegates: delegare_list,
      change_for_nurture_members: input.change_for_nurture_members,
    };
    const result = await change_nurture_to_seld(postData);
    if (result.code === 200) {
      getConsultantListingFilter(filterData);
      setInput({
        change_for_call_type: false,
        change_for_members: false,
        switch_to: "self",
        other_delegates: [],
        change_for_nurture_members: false,
      });
      setOpenChangeNurture(false);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPageCount(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  const handleNavigate = () => {
    navigate(`${start_route}/add-consultant`);
  };
  const handlePayNow = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${start_route}/add-transaction/${value._id}`, {
      state: value,
    });
  };

  const handleManageProgramAccess = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${start_route}/programme-access/${value._id}`, {
      state: value,
    });
  };
  const handleManageMissionAccess = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${start_route}/mission-access/${value._id}`, {
      state: value,
    });
  };

  const handleManageMAinPortalProgramAccess = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${start_route}/main-portalprogramme-access/${value._id}`, {
      state: value,
    });
  };
  const handleManageEventAccess = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${start_route}/event-access/${value._id}`, {
      state: value,
    });
  };
  const handleManageEventTemplateAccess = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${start_route}/template-event-access/${value._id}`, {
      state: value,
    });
  };
  const handleCallTypeConfig = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${start_route}/call-type-configuration/${value._id}`, {
      state: value,
    });
  };
  const handleTeamTypeAccess = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${start_route}/manage-delegates-settings/${value._id}`, {
      state: value,
    });
  };

  const handleRecantAssociatedTransactions = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${start_route}/recant-associated-transactions/${value._id}`, {
      state: value,
    });
  };

  const handleCopyReferralId = async (e) => {
    await navigator.clipboard.writeText(e.affiliate_url_name);
    enqueueSnackbar("Referral Id Copied to clipboard", { variant: "success" });
  };
  const handleCopyAppReferralId = async (e) => {
    await navigator.clipboard.writeText(e.affiliate_link);
    enqueueSnackbar("App Referral  Id Copied to clipboard", {
      variant: "success",
    });
  };

  const handleQuestionsAnswers = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`${start_route}/questions-answers?member_id=${value.id}`, {
      state: value,
    });
  };

  const handleMenu = (row) => {
    let MENU_OPTIONS = [
      {
        label: "Edit",
        icon: "akar-icons:edit",
        handleClick: handleEdit,
      },
      {
        label: "Login As Delegate",
        icon: "ant-design:lock",
        handleClick: LoginAsDelegate,
      },
      {
        label: "Questions Answers",
        icon: "ant-design:lock",
        handleClick: handleQuestionsAnswers,
      },
      {
        label: "Change Password",
        icon: "ant-design:lock",
        handleClick: handleChangePassword,
      },
      {
        label: "Change Affiliate Id",
        icon: "ant-design:lock",
        handleClick: handlechangeAffiliateID,
      },
      {
        label: "Pay Now",
        icon: "ant-design:dollar-circle-outlined",
        handleClick: handlePayNow,
      },
      {
        label: "Delegates 90 Days Questions",
        icon: "akar-icons:edit",
        handleClick: handleDelegates90DaysQuestions,
      },
      {
        label: "Tracking History",
        icon: "akar-icons:edit",
        handleClick: handleDelegatesTrackingHistory,
      },
      {
        label: "Delegates Assessment/Study",
        icon: "akar-icons:edit",
        handleClick: ManageAssessmentQuestion,
      },
      {
        label: "To Do Tracker",
        icon: "akar-icons:edit",
        handleClick: handleToDoTracker,
      },
      {
        label: "Manage Program Access",
        icon: "akar-icons:edit",
        handleClick: handleManageProgramAccess,
      },
      {
        label: "Manage Mission Access",
        icon: "akar-icons:edit",
        handleClick: handleManageMissionAccess,
      },
      {
        label: "Manage Main Portal Program Access",
        icon: "akar-icons:edit",
        handleClick: handleManageMAinPortalProgramAccess,
      },
      {
        label: "Sync Delegate Commission",
        icon: "tdesign:money",
        handleClick: handleCommissionSyncSingle,
      },

      {
        label: "Commission History",
        icon: "akar-icons:edit",
        handleClick: handleCommsionHistory,
      },
      {
        label: "Manage Event Access",
        icon: "akar-icons:edit",
        handleClick: handleManageEventAccess,
      },
      {
        label: "Manage  Event Template Access",
        icon: "akar-icons:edit",
        handleClick: handleManageEventTemplateAccess,
      },
      {
        label: "Call Type Configuration",
        icon: "akar-icons:edit",
        handleClick: handleCallTypeConfig,
      },
      {
        label: "Manage Access Settings",
        icon: "akar-icons:edit",
        handleClick: ManageAccessDelegates,
      },

      {
        label: "Switch nurture ",
        icon: "akar-icons:edit",
        handleClick: handleChangeNurture,
      },
      {
        label: "Manage Nav Items Access",
        icon: "fluent:video-recording-20-regular",
        handleClick: ManageNavItemsAccess,
      },
    ];
    if (row.team_type !== "team") {
      MENU_OPTIONS.push(
        {
          label: "View Groups",
          icon: "fluent:video-recording-20-regular",
          handleClick: handleChangeGroup,
        },
        {
          label: "View Pods",
          icon: "fluent:video-recording-20-regular",
          handleClick: handleChangePods,
        },
        {
          label: "View Members",
          icon: "fluent:video-recording-20-regular",
          handleClick: handleChangeMember,
        },
        {
          label: "View Events",
          icon: "fluent:video-recording-20-regular",
          handleClick: handleChangeEvents,
        },
        {
          label: "View Goal Statement",
          icon: "fluent:video-recording-20-regular",
          handleClick: handleChangeGoalStatement,
        },
        {
          label: "Manage Template Access",
          icon: "fluent:video-recording-20-regular",
          handleClick: ManageTemplateAccess,
        }
      );
    }

    MENU_OPTIONS.push(
      {
        label: "Manage Commission Access",
        icon: "fluent:video-recording-20-regular",
        handleClick: ManageCommissionAccess,
      },
      {
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: handleAgreeDelete,
      }
    );

    if (row.affiliate_url_name) {
      if (row.affiliate_url_name) {
        MENU_OPTIONS.push({
          label: "Copy Referral Id",
          icon: "eva:eye-fill",
          handleClick: handleCopyReferralId,
        });
      }
    }
    if (row.affiliate_link) {
      if (row.affiliate_link) {
        MENU_OPTIONS.push({
          label: "Copy App Referral Id",
          icon: "eva:eye-fill",
          handleClick: handleCopyAppReferralId,
        });
      }
    }
    MENU_OPTIONS.push({
      label: "Recent Associated Transactions",
      icon: "mdi:recycle",
      handleClick: handleRecantAssociatedTransactions,
    });

    return MENU_OPTIONS;
  };

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem(type + "delegate_filter_data");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
    }
    setFilterData(filter_data);
    setFilterUpdated(filter_data);
    let search_keyword =
      localStorage.getItem(type) == null
        ? searchText
        : localStorage.getItem(type);
    if (search_keyword) {
      setSearchText(search_keyword);
    } else {
      setSearchText("");
    }
    getConsultantListingFilter(filter_data);
  }, [rowsPerPage, page, type]);
  useEffect(() => {
    if (!openChangeNurture) {
      setInput({
        change_for_call_type: false,
        change_for_members: false,
        switch_to: "self",
        other_delegates: [],
        change_for_nurture_members: false,
      });
    }
  }, [openChangeNurture]);

  useEffect(() => {
    const csvExportData = userList?.map((member) => {
      return {
        ...member,
        first_name: member.name,
        email: member.email,
        total_commission: member.total_commission,
        commission_paid: member.commission_paid,
        commission_due: member.commission_due,
        total_leads: member.total_leads,
        type: member.team_type,
      };
    });

    setCsvData(csvExportData);
  }, [userList]);

  const handleExportClick = () => {
    download_csv_file(headers, csvData);
  };

  const handleChangeData = (event, valueName) => {
    if (valueName == "switch_to") {
      const value = event.target.value;
      setInput((values) => ({ ...values, [valueName]: value }));
    } else {
      const value = event.target.checked;
      setInput((values) => ({ ...values, [valueName]: value }));
    }
  };

  const handleChangeProgramDetail = (value) => {
    navigate(`/programmes/programmes-detail/${value._id.program_slug}`, {
      state: value._id,
    });
  };

  const handleClickPopUP = (event, row) => {
    let programs = [];
    setprogram(row.program);
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopUp = () => {
    setAnchorEl(null);
  };

  // useEffect(() => {
  //   localStorage.removeItem(type);
  //   localStorage.removeItem(type + "delegate_filter_data");
  //   setPage(0);
  //   setPageCount(1);
  //   setSearchText("");
  // }, [type]);

  const TABLE_HEAD = [
    {
      id: "action",
      label: "Action",
      type: "action",
      MENU_OPTIONS: "MENU_OPTIONS",
    },
    { id: "number", label: "#", type: "number" },
    { id: "table_avatar", label: "Profile", type: "thumbnail" },
    { id: "name", label: " Name", alignRight: false },
    { id: "email", label: "Email", alignRight: false },
    type === "all" && {
      id: "team_type",
      label: "Team Type",
      renderData: (row) => {
        return <>{row.team_type && show_proper_words(row.team_type)}</>;
      },
    },
    { id: "total_amount", label: "Total Commission", alignRight: false },
    { id: "commission_paid", label: "Paid Commission", alignRight: false },
    { id: "due_amount", label: "Due Commission", alignRight: false },
    { id: "start_date_string", label: "Start Date" },
    {
      id: "program",
      label: "Programmes",
      renderData: (row) => {
        const firstFiveprograms = row.program?.slice(0, 5);
        return firstFiveprograms && firstFiveprograms.length > 0 ? (
          firstFiveprograms?.map((program, i) => {
            let is_active = false;
            let start_date = dd_date_format(program?.assign_date_time);
            let expiry_date = dd_date_format(program?.expiry_date);
            if (
              !program?.expiry_date ||
              (TODAY_DATE >= new Date(program?.assign_date_time) &&
                TODAY_DATE <= new Date(program?.expiry_date))
            ) {
              is_active = true;
            }

            return (
              <>
                <div
                  className="d-flex align-items-center"
                  onClick={() => handleChangeProgramDetail(program)}
                >
                  <Tooltip title="Currenty Active">
                    <div
                      className={`program-dot ${
                        is_active ? "active-program" : ""
                      }`}
                    ></div>
                  </Tooltip>
                  <Tooltip
                    key={i}
                    title={
                      program.expiry_date ? (
                        <div>
                          Start Date: {start_date}
                          <br />
                          Expiry: {expiry_date}
                          <br />
                          Status:
                          {program._id?.status === true ? "Active" : "InActive"}
                        </div>
                      ) : (
                        <div>
                          Start Date: {start_date}
                          <br />
                          No Expiry
                          <br />
                          Status:{" "}
                          {program._id?.status === true ? "Active" : "InActive"}
                        </div>
                      )
                    }
                    arrow
                  >
                    <p className="mb-2 program-hover">{program._id?.title}</p>
                  </Tooltip>
                </div>

                {i == 4 && (
                  <p
                    className="mb-1 ms-3 view-more"
                    aria-describedby={id}
                    variant="contained"
                    onClick={(e) => handleClickPopUP(e, row)}
                  >
                    view more
                  </p>
                )}
              </>
            );
          })
        ) : (
          <p>N/A</p>
        );
      },
      alignRight: false,
    },
    { id: "total_leads", label: "Total Leads", alignRight: false },
    {
      id: "member",
      label: "Member",
      className: "view-delegates-members",
      handleClick: handleMembersList,
    },
    {
      id: "subscriber",
      label: "View Subscribers",
      className: "view-delegates-members",
      handleClick: handleSubscriber,
    },

    {
      id: "last_login_activity",
      label: "Last Login Activity",
      alignRight: false,
    },
    { id: "isChatAllow", label: "Is Chat Allow" },
    { id: "isSuperDelegate", label: "Is Super Delegate" },
    { id: "status", label: "Status", type: "row_status" },
  ];
  let MENU_OPTIONS_MAIN = [
    // {
    //   label: "Export Csv",
    //   icon: "ic:round-cloud-download",
    //   handleClick: handleExportClick,
    // },
    {
      label: "Request For Csv",
      icon: "ic:round-cloud-download",
      handleClick: showPopUPcsv,
    },
    {
      label: " Pay Commission",
      icon: "tdesign:money",
      handleClick: handlePayCommission,
    },
    {
      label: "Commission Sync",
      icon: "tdesign:money",
      handleClick: handleCommissionSync,
    },
  ];

  if (isLoading == true) {
    return <PageLoader />;
  }

  return (
    <>
      <CustomConfirmation
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />{" "}
      <CustomConfirmation
        openDelete={openSyn}
        setOpenDelete={setOpenSyn}
        title={"Are you sure you want to Sync Commission ?"}
        handleAgree={handlesyncCommsion}
      />
      <CustomConfirmation
        openDelete={openSynCommsion}
        setOpenDelete={setOpenSynCommsion}
        title={"Are you sure you want to Sync Commission ?"}
        handleAgree={handleCommissionAgreement}
      />
      <CustomConfirmation
        openDelete={openChangeNurture}
        setOpenDelete={setOpenChangeNurture}
        title={"Are you sure you want to Sync Commission ?"}
        handleAgree={handlesyncCommsion}
      />
      {openChangeNurture && (
        <ChangeNurtureCustomConfirmationLoad
          open={openChangeNurture}
          setOpen={setOpenChangeNurture}
          title={`Are you sure you want to change ${openChangeNurtureName}'s nurture?`}
          handleAgree={handleChangeNurtureSelf}
          handleChangeData={handleChangeData}
          input={input}
          handleChangeOthersChange={handleChangeOthersChange}
        />
      )}
      <AffliateConfirmationPopUp
        open={openAffliatePopup}
        setOpen={setopenAffliatePopup}
        value={affliateValue}
        title={"Are you sure you want to Affliate id?"}
        handleChange={handleChangeAffiliate}
        handleAgree={handlechangeAffiliate}
        inputs={inputs}
      />
      <div className="container-fluid">
        <div className="row mb-3 mt-2">
          <div className="col-lg-6 col-sm-12">
            <h2>{heading}</h2>
          </div>
          <div className="col-lg-6 col-sm-12 text-end d-flex justify-content-end">
            <div>
              <button
                className="small-contained-button me-2 mt-1 mb-4"
                onClick={handleOpenFilterDrawer}
              >
                Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
              </button>
              <button
                onClick={handleNavigate}
                className="small-contained-button"
              >
                Add {heading}
              </button>
            </div>
            <CustomPopoverSection menu={MENU_OPTIONS_MAIN} />
          </div>
          {/* <div className="col-lg-10 col-sm-12 text-end">
            <button className="small-contained-button me-2">
              <CSVLink
                data={csvData}
                headers={headers}
                filename="Transaction List"
                className="csvButton me-2"
              >
                Export Csv
                <Icon icon={arrowCircleDownFill} height={20} className="ms-2" />
              </CSVLink>
            </button>
            <button
              className="small-contained-button me-2 mt-1 mb-4"
              onClick={handlePayCommission}
            >
              Pay Commission
            </button>
            <button
              className="small-contained-button me-2 mt-1 mb-4"
              onClick={handleCommissionSync}
            >
              Commission Sync
            </button>
          </div> */}
        </div>
        <FilteredChip
          data={filterUpdated}
          tempState={filterData}
          EMPTY_FILTER={EMPTY_FILTER}
          onDeleteChip={handleDeleteChip}
          onClear={handleClearFilter}
        />

        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={userList}
          className="card-with-background"
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
      </div>
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <DelegateFilterDateBase
            filterData={filterData}
            setFilterData={setFilterData}
            searchSubmitFilter={searchFunction}
            handleChangeOthers={handleChangeOthers}
            handleClearFilter={handleClearFilter}
            setProgramsList={setProgramsList}
            programsList={programsList}
          />
        }
      />
      <GeneralPopUpModel
        open={showExportcsvFile}
        setOpen={setShowExportcsvFile}
        title={"Export CSV file request  "}
        componentToPassDown={
          <GeneralRequestForCsv
            options_array={member_options}
            collection_name={csv_type}
            filter_data={filterQuery}
            setShowExportcsvFile={setShowExportcsvFile}
          />
        }
      />
      <ProgramPopOver
        handleClick={handleClickPopUP}
        handleClose={handleClosePopUp}
        anchorEl={anchorEl}
        id={id}
        open={open}
        program={program}
      />
    </>
  );
}
