import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { UpdateBadgeLevelConfiguration } from "src/DAL/ClientSetting/ClientSetting";
import { useEffect } from "react";
import {
  _bedge_level_detail,
  _get_attitude_accessment_setting,
} from "src/DAL/AttitudeAccessmentQuestions/AttitudeAccessmentSetting";
import { useParams } from "react-router-dom";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import PageLoader from "src/components/GeneralComponents/PageLoader";

export default function BadgeLevelConfiguration({ type }) {
  const { id, badge_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [badgeLevelList, setBadgeLevelList] = useState([]);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [badgeTitle, setBadgeTitle] = useState("");

  const [inputs, setInputs] = React.useState({
    upgrade_button_text: "",
    upgrade_button_url: "",
    auto_unlock_next_badge_level: false,
    next_badge_level_id: "",
    next_level_description: "",
    poll_winner_description: "",
    upgrade_for: "membership",
    completed_mission_count: 0,
    require_missions_for_unlock_this_level: 0,
  });

  const bedgeDetail = async () => {
    const result = await _bedge_level_detail(badge_id);
    if (result.code == 200) {
      if (result?.membership_level_badge_configration?.configration) {
        setInputs({
          ...result?.membership_level_badge_configration?.configration,
          is_membership_required:
            result?.membership_level_badge_configration?.configration
              ?.is_membership_required ?? false,
        });
      }
      setBadgeTitle(result?.membership_level_badge_configration);
      setBadgeLevelList(result.membership_level_badges);

      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);
    let data_obj = inputs;
    let postData = {
      configration: data_obj,
      membership_level_badge_id: badge_id,
    };
    const result = await UpdateBadgeLevelConfiguration(postData);
    if (result.code === 200) {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name == "completed_mission_count") {
      setInputs((values) => ({
        ...values,
        [name]: name === "completed_mission_count" && value < 0 ? 0 : value,
      }));
    } else {
      setInputs((values) => ({ ...values, [name]: value }));
    }
  };
  console.log(type, "typetypetype");
  let breadCrumbMenu = [
    {
      title:
        type === "badge-level-listing"
          ? `Badge Level (${badgeTitle.title})`
          : `Membership Level`,
      navigation:
        type === "badge-level-listing" ? "/badge-level" : `/membership-level`,
      active: false,
    },
    {
      title: "Configuration",
      active: true,
    },
  ];

  if (type !== "badge-level-listing") {
    breadCrumbMenu.splice(1, 0, {
      title: `Badge Level (${badgeTitle.title})`,
      navigation: `/membership-level/${id}/membership-level-badges`,
      active: false,
    });
  }

  useEffect(() => {
    bedgeDetail();
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin">
        <div className="col-12 mb-3">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
        <div className="col-12">
          <h2>Badge Level Configuration</h2>
        </div>
      </div>
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Upgrade Button Text"
            variant="outlined"
            fullWidth
            name="upgrade_button_text"
            value={inputs?.upgrade_button_text}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Upgrade Button Url"
            variant="outlined"
            fullWidth
            type="url"
            name="upgrade_button_url"
            value={inputs?.upgrade_button_url}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Is Membership Required
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="is_membership_required"
              value={inputs.is_membership_required}
              label="is_membership_required"
              onChange={handleChange}
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">Upgrade For</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="upgrade_for"
              value={inputs.upgrade_for}
              label="Upgrade For"
              onChange={handleChange}
            >
              <MenuItem value="membership">Membership</MenuItem>
              <MenuItem value="sale_page">Sale Page</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Auto Unlock Next Badge Level
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="auto_unlock_next_badge_level"
              value={inputs.auto_unlock_next_badge_level}
              label="Auto Unlock Next Badge Level"
              onChange={handleChange}
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            type="number"
            id="outlined-basic"
            label="Require Missions For Unlock This Level"
            variant="outlined"
            fullWidth
            name="require_missions_for_unlock_this_level"
            value={inputs?.require_missions_for_unlock_this_level}
            onChange={handleChange}
            required
          />
        </div>
        {inputs.auto_unlock_next_badge_level && (
          <>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Completed Mission Count"
                variant="outlined"
                fullWidth
                type="number"
                required
                inputProps={{
                  min: 0,
                }}
                name="completed_mission_count"
                value={inputs?.completed_mission_count}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">
                  Next Badge Level To Unlock
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="next_badge_level_id"
                  value={inputs.next_badge_level_id}
                  label="Next Badge Level To Unlock"
                  onChange={handleChange}
                >
                  {badgeLevelList.map((item, index) => {
                    return <MenuItem value={item._id}>{item.title}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </div>
          </>
        )}
        <div className="text-end mt-4" id="fixedbutton">
          <button className="small-contained-button">
            {isLoadingForm ? "Updating" : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
