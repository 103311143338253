import { format } from "date-fns";
import React from "react";
import Chart from "react-apexcharts";
import ActiveUsersChartHeader from "./ActiveUsersChartHeader";
import { useState } from "react";
import { get_kmb_number } from "src/utils/formatNumber";

const formatDate = (dateStr) => {
  const date = new Date(
    `${dateStr.slice(0, 4)}-${dateStr.slice(4, 6)}-${dateStr.slice(6, 8)}`
  );
  return format(date, "MMM dd");
};

const transformData = (data) => {
  const { counts } = data;

  const categories = data.graph.map((item) => formatDate(item.date));
  const totalMap = new Map();
  data.graph.forEach((item) => {
    totalMap.set(formatDate(item.date), parseInt(item.activeUsers, 10));
  });

  const dailyMap = new Map();
  data["last_1_day_active_user_graph"].forEach((item) => {
    dailyMap.set(formatDate(item.date), parseInt(item.activeUsers, 10));
  });

  const weeklyMap = new Map();
  data["last_7_days_active_user_graph"].forEach((item) => {
    weeklyMap.set(formatDate(item.date), parseInt(item.active7DayUsers, 10));
  });

  const monthlyMap = new Map();
  data["last_30_days_active_user_graph"].forEach((item) => {
    monthlyMap.set(formatDate(item.date), parseInt(item.active28DayUsers, 10));
  });

  // Ensure categories match
  const total = categories.map((date) => totalMap.get(date) || 0);
  const daily = categories.map((date) => {
    return dailyMap.has(date) ? dailyMap.get(date) : 0; // Set null for missing values
  });
  const weekly = categories.map((date) => {
    return weeklyMap.has(date) ? weeklyMap.get(date) : 0; // Set null for missing values
  });
  const monthly = categories.map((date) => {
    return monthlyMap.has(date) ? monthlyMap.get(date) : 0; // Set null for missing values
  });

  return {
    categories,
    series: [
      {
        name: `Total Active Users (${get_kmb_number(
          counts["active_users_count"]
        )})`,
        data: total,
        color: "#e4c073",
      },
      {
        name: `1 Day (${get_kmb_number(counts["1_day_active_users"])})`,
        data: daily,
        color: "rgb(114, 7, 150)",
      },
      {
        name: `7 Days (${get_kmb_number(counts["7_day_active_users"])})`,
        data: weekly,
        color: "rgb(71, 71, 235)",
      },
      {
        name: `30 Days (${get_kmb_number(counts["30_day_active_users"])})`,
        data: monthly,
        color: "rgb(26, 115, 232)",
      },
    ],
  };
};

const ActiveUsersChart = ({ data, name }) => {
  const initialArray = ["total", "1day", "7days", "30days"];
  const [selectedItems, setSelectedItems] = useState(initialArray);

  const toggleNumber = (item) => {
    const newSelectedItems = [...selectedItems];
    if (newSelectedItems.includes(item)) {
      let filtered = newSelectedItems.filter((i) => i !== item);
      setSelectedItems(filtered);
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const transformedData = transformData(data, name);
  const maxYValue = Math.max(
    ...transformedData.series.flatMap((s) => s.data) // Flatten all series data and find the max
  );

  let lineChartWin = {
    options: {
      chart: {
        type: "line",
      },
      grid: {
        show: true,
        borderColor: "gray",
        strokeDashArray: 4,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#c6c2c2",
          },
        },
        axisBorder: {
          show: true,
          color: "gray",
          dashArray: 4,
        },
        max: maxYValue || 0,
        tickAmount: 8,
      },
      xaxis: {
        labels: {
          style: {
            colors: "#c6c2c2",
          },
          axisTicks: {
            show: false,
          },
        },
        categories: transformedData?.categories,
        tickAmount: 5,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      markers: {
        size: 0,
      },
      fill: {
        colors: ["#f6bd4b"],
      },
      // legend: {
      //   show: false,
      // },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          if (val === 0) {
            return "";
          }
          return val;
        },
      },
    },
    series: transformedData.series,
  };

  return (
    <>
      <ActiveUsersChartHeader data={data} toggleNumber={toggleNumber} />
      <Chart
        options={lineChartWin.options}
        series={lineChartWin.series}
        type="line"
        height={340}
      />
    </>
  );
};

export default ActiveUsersChart;
