import React from "react";
import MUICustomTabs from "../GeneralComponents/MUICustomTabs";
import ManageProgrammeAccess from "src/pages/MembersList/ProfileTabs/ManageProgrammeAccess";
import Events from "src/pages/MembersList/ProfileTabs/Events";
import MemberTransactions from "src/pages/MembersList/ProfileTabs/MemberTransactions";
import MemberSubscribers from "src/pages/MembersList/ProfileTabs/MemberSubscribers";
import MemberSubscriptions from "src/pages/MembersList/ProfileTabs/MemberSubscriptions";
import BookingListTab from "src/pages/MembersList/ProfileTabs/BookingListTab";
import ManageLevelAccess from "src/pages/MembersList/ProfileTabs/ManageLevelAccess";
import BonusContentAccess from "src/pages/MembersList/ProfileTabs/BonusContentAccess";
import ManageRewardAccess from "src/pages/MembersList/ProfileTabs/ManageRewardAccess";
import MembersGroupList from "src/pages/MembersList/ProfileTabs/MembersGroupList";
import MemberCalender from "src/pages/MembersList/ProfileTabs/MemberCalender";
import MemberDiaryList from "src/pages/MembersList/ProfileTabs/MemberDiaryList";
import MemberPersonalNote from "src/pages/MembersList/ProfileTabs/MemberPersonalNote";
import ToDoTracker from "src/pages/MembersList/ProfileTabs/ToDoTracker";
import QuestionsAnswersForUser from "src/pages/MembersList/ProfileTabs/QuestionsAnswersForUser";
import Navitems from "src/pages/MembersList/ProfileTabs/Navitems";
import CoinsHistory from "src/pages/MembersList/CoinsHistory";
import OrderHistoryProfile from "src/pages/MembersList/ViewBillings/OrderHistoryProfile";
import BadgesHistory from "src/pages/MembersList/ProfileTabs/BadgesHistory";
import ResourceHistory from "src/pages/MembersList/ProfileTabs/ResourceHistory";
import CommissionTabs from "src/pages/MembersList/ProfileTabs/CommissionTabs";
import DelegateMembers from "src/pages/MembersList/ProfileTabs/DelegateMembers";
import ChllengesMembers from "src/pages/MembersList/ProfileTabs/ChllengesMembers";
import WellcomeReminderUser from "src/pages/MembersList/ViewBillings/WellcomeReminderUser";
import SelfImageHistory from "./SelfImageHistory";
import LoginSession from "src/pages/MembersList/ProfileTabs/LoginSession";
import MemberPods from "src/pages/MembersList/ProfileTabs/MemberPods";
import NinetyDayHistory from "src/pages/MembersList/NinetyDayHistory";
import DetailQuestion from "src/pages/MembersList/ProfileTabs/DetailQuestion";
import MemberGoalstatementList from "src/pages/MembersList/ProfileTabs/MemberGoalstatementList";
import NotificationScheduleList from "src/pages/MembersList/NotificationScheduleList";
import BlockedUsers from "src/pages/MembersList/ProfileTabs/BlockedUsers";
import PageLoader from "../GeneralComponents/PageLoader";
import MissionsTab from "src/pages/MembersList/ProfileTabs/MissionsTab";

export default function MemberTabOptions(props) {
  const {
    isTabLoading,
    tabValue,
    handleChange,
    tabData,
    memberDetail,
    filterStateUpdated,
    filterData,
    EMPTY_FILTER,
    ALTER_FILTER,
    handleDeleteChip,
    handleClearFilter,
    handleOpenFilterDrawer,
    memberProfileInfo,
    defaultTimeZone,
    isLoading,
    setIsLoading,
    handleUpdateclientNote,
    setTabValue,
    status_object,
    setTabData,
  } = props;

  const TABS_OPTIONS = [
    {
      title: "Programmes",
      component: isTabLoading ? (
        <PageLoader />
      ) : (
        <ManageProgrammeAccess tabData={tabData} memberDetail={memberDetail} />
      ),
    },
    {
      title: "Portals",
      component: isTabLoading ? <PageLoader /> : <Events tabData={tabData} />,
    },
    {
      title: "Transactions",
      component: isTabLoading ? (
        <PageLoader />
      ) : (
        <MemberTransactions
          tabData={tabData}
          filterStateUpdated={filterStateUpdated}
          filterData={filterData}
          EMPTY_FILTER={EMPTY_FILTER}
          ALTER_FILTER={ALTER_FILTER}
          handleDeleteChip={handleDeleteChip}
          handleClearFilter={handleClearFilter}
          handleOpenFilterDrawer={handleOpenFilterDrawer}
        />
      ),
    },
    {
      title: "Subscribers",
      component: isTabLoading ? (
        <PageLoader />
      ) : (
        <MemberSubscribers tabData={tabData} />
      ),
    },
    {
      title: "Subscriptions ",
      component: isTabLoading ? (
        <PageLoader />
      ) : (
        <MemberSubscriptions tabData={tabData} />
      ),
    },
    {
      title: "Bookings",
      component: isTabLoading ? (
        <PageLoader />
      ) : (
        <BookingListTab tabData={tabData} />
      ),
    },

    {
      title: "badge level history",
      component: isTabLoading ? (
        <PageLoader />
      ) : (
        <ManageLevelAccess tabData={tabData} />
      ),
    },
    {
      title: "Bonus Content",
      component: isTabLoading ? (
        <PageLoader />
      ) : (
        <BonusContentAccess tabData={tabData} />
      ),
    },
    {
      title: "Rewards",
      component: isTabLoading ? (
        <PageLoader />
      ) : (
        <ManageRewardAccess tabData={tabData} />
      ),
    },
    {
      title: "Groups",
      component: isTabLoading ? (
        <PageLoader />
      ) : (
        <MembersGroupList tabData={tabData} />
      ),
    },
    {
      title: "Calender Events",
      component: isTabLoading ? (
        <PageLoader />
      ) : (
        <MemberCalender
          memberProfileInfo={memberProfileInfo}
          defaultTimeZone={defaultTimeZone}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          tabData={tabData}
        />
      ),
    },
    {
      title: "Pods",
      component: isTabLoading ? (
        <PageLoader />
      ) : (
        <MemberPods tabData={tabData} />
      ),
    },
    {
      title: "Diary",
      component: isTabLoading ? (
        <PageLoader />
      ) : (
        <MemberDiaryList tabData={tabData} />
      ),
    },
    {
      title: "Client Notes",
      component: isTabLoading ? (
        <PageLoader />
      ) : (
        <MemberPersonalNote
          tabData={tabData}
          handleUpdateclientNote={handleUpdateclientNote}
        />
      ),
    },
    {
      title: "To Do Tracker",
      component: isTabLoading ? (
        <PageLoader />
      ) : (
        <ToDoTracker tabData={tabData} />
      ),
    },
    {
      title: "Questions Answers",
      component: isTabLoading ? (
        <PageLoader />
      ) : (
        <QuestionsAnswersForUser tabData={tabData} />
      ),
    },
    {
      title: "Nav Items",
      component: isTabLoading ? <PageLoader /> : <Navitems tabData={tabData} />,
    },
    {
      title: "Coins History",
      component: isTabLoading ? <PageLoader /> : <CoinsHistory />,
    },
    {
      title: "Order History",
      component: isTabLoading ? (
        <PageLoader />
      ) : (
        <OrderHistoryProfile tabData={tabData} />
      ),
    },
    {
      title: "Reward History",
      component: isTabLoading ? (
        <PageLoader />
      ) : (
        <BadgesHistory tabData={tabData} memberDetail={memberDetail} />
      ),
    },
    {
      title: "Resource History",
      component: isTabLoading ? (
        <PageLoader />
      ) : (
        <ResourceHistory tabData={tabData} memberDetail={memberDetail} />
      ),
    },
    {
      title: "Commission Detail",
      component: isTabLoading ? (
        <PageLoader />
      ) : (
        <CommissionTabs
          tabData={tabData}
          memberDetail={memberDetail}
          value={tabValue}
          setValue={setTabValue}
        />
      ),
    },
    {
      title: "Affiliated Members",
      component: isTabLoading ? (
        <PageLoader />
      ) : (
        <DelegateMembers tabData={tabData} memberDetail={memberDetail} />
      ),
    },
    {
      title: "challenges",
      component: isTabLoading ? (
        <PageLoader />
      ) : (
        <ChllengesMembers tabData={tabData} />
      ),
    },
    {
      title: "Welcome Reminders message ",
      component: isTabLoading ? (
        <PageLoader />
      ) : (
        <WellcomeReminderUser tabData={tabData} />
      ),
    },
    {
      title: "90 Day History",
      component: isTabLoading ? (
        <PageLoader />
      ) : (
        <NinetyDayHistory tabData={tabData} userInfo={memberDetail} />
      ),
    },
    {
      title: "Self Image History",
      component: isTabLoading ? (
        <PageLoader />
      ) : (
        <SelfImageHistory tabData={tabData} userInfo={memberDetail} />
      ),
    },
    {
      title: "Login Sessions",
      component: isTabLoading ? (
        <PageLoader />
      ) : (
        <LoginSession tabData={tabData} />
      ),
    },
    {
      title: "Missions",
      component: isTabLoading ? (
        <PageLoader />
      ) : (
        <MissionsTab tabData={tabData} />
      ),
    },
  ];

  if (memberDetail?.is_wheel_of_life_enable && memberDetail?.is_wheel_of_life) {
    status_object.push("wheel_of_life");
    TABS_OPTIONS.push({
      title: "Wheel of Life",
      component: isTabLoading ? (
        <PageLoader />
      ) : (
        <DetailQuestion tabData={tabData} setTabData={setTabData} />
      ),
    });
  }
  if (memberDetail?.goal_statement_status == true) {
    status_object.push("goal_statement");
    TABS_OPTIONS.push({
      title: "Goal Statement",
      component: isTabLoading ? (
        <PageLoader />
      ) : (
        <MemberGoalstatementList tabData={tabData} setTabData={setTabData} />
      ),
    });
  }

  if (memberDetail) {
    status_object.push("notification_schedules", "blocked_users");
    TABS_OPTIONS.push(
      {
        title: "Notification Schedule",
        component: isTabLoading ? (
          <PageLoader />
        ) : (
          <NotificationScheduleList type="detail" />
        ),
      },
      {
        title: "Blocked Users",
        component: isTabLoading ? (
          <PageLoader />
        ) : (
          <BlockedUsers tabData={tabData} memberDetail={memberDetail} />
        ),
      }
    );
  }

  return (
    <>
      <MUICustomTabs
        data={TABS_OPTIONS}
        value={tabValue}
        handleChange={handleChange}
      />
    </>
  );
}
