import { Icon } from "@iconify/react";
import { useRef, useState } from "react";
import personFill from "@iconify/icons-eva/person-fill";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { get_root_value } from "src/utils/domUtils";
import unlockFill from "@iconify/icons-eva/unlock-fill";
import { alpha } from "@mui/material/styles";
import {
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  Avatar,
  IconButton,
} from "@mui/material";
import MenuPopover from "../../components/MenuPopover";
import account from "../../_mocks_/account";
import { s3baseUrl } from "src/config/config";
import { usePGIMode } from "src/Hooks/PGIModeContext";
import ChangePassword from "src/components/_dashboard/ChangePassword";
import { useEffect } from "react";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import QRCodeDetail from "src/components/ModalPopover/QRcodeDetails";
import ConfirmPassword from "src/components/ModalPopover/ConfirmPassword";
import { admin_logut_api } from "src/DAL/SiteSetting/siteSetting";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Edit Profile",
    icon: personFill,
    linkTo: "/profile",
  },
];

export default function AccountPopover() {
  const { profileNewImage } = usePGIMode();
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [scanDetail, setScanDetail] = useState();
  const [openPasswordModal, setopenPasswordModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedValue, setSelectedValue] = useState("this_device");

  const { socket } = useContentSetting();

  const handleShowQR = (value) => {
    setScanDetail(value);
    setOpenDelete(true);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleProfile = (value) => {
    navigate(value);
    handleClose();
  };

  const handleLogout = () => {
    setOpenConfirm(true);
  };

  const admin_logut = async () => {
    setIsLoading(true);
    const result = await admin_logut_api(selectedValue);
    if (result.code === 200) {
      localStorage.clear();
      navigate("/login");
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    socket.on("qrcode_scan_event_reciever", (data) => {
      if (data?.data?.code == 200) {
        handleShowQR(data);
      }
    });
  }, [socket]);

  useEffect(() => {
    setTimeout(() => {
      const is_path_includes = (path) => {
        return pathname.toString().includes(path);
      };
      if (!is_path_includes("/calendar-groups")) {
        localStorage.removeItem("group_filter_data");
        localStorage.removeItem("group_filter_data_page");
        localStorage.removeItem("group_filter_data_rowsPerPage");
      }
      if (!is_path_includes("/done-listing")) {
        localStorage.removeItem("done_member_filter_data");
        localStorage.setItem("member_page_number", 1);
      }
      if (!is_path_includes("/member")) {
        localStorage.removeItem("member_payment_transactions_filter");
      }
      if (!is_path_includes("/programmes")) {
        localStorage.setItem("view_mode_progamme", "grid");
      }
      if (!is_path_includes("/calendar-pod/call-pods")) {
        localStorage.removeItem("call_pods_search_text");
      }
      if (!is_path_includes("/calendar-pods")) {
        localStorage.removeItem("pods_search_text");
      }
      if (!is_path_includes("/dynamite-events")) {
        localStorage.removeItem("dynamite_events_list_search");
      }
      if (!is_path_includes("/rewards")) {
        localStorage.removeItem("bedge_search");
      }
      if (!is_path_includes("/member")) {
        localStorage.removeItem("member_filter_data");
        localStorage.removeItem("notification_schedule_tab_value");
        localStorage.removeItem("member_tab_value");
        localStorage.setItem("member_page_number", 1);
      }
      if (!is_path_includes("/consultant")) {
        localStorage.setItem("consultant_search_text", "");
      }
      if (!is_path_includes("/wheel-of-life-member-list")) {
        localStorage.setItem("wheel_of_life_search_text", "");
      }
      if (!is_path_includes("/call-stats")) {
        localStorage.removeItem("booking_list_filter_stat");
      }
      if (!is_path_includes("/dynamite-events")) {
        localStorage.setItem("event_member_search_text", "");
      }
      if (!is_path_includes("/support-ticket")) {
        localStorage.setItem("support_ticket_filter", "");
        localStorage.setItem("support_dep_filter", "");
      }
      if (is_path_includes("/trash-support-ticket")) {
        localStorage.setItem("support_ticket_filter", "");
      }
      if (!is_path_includes("/members-answers-list")) {
        localStorage.removeItem("question_answers_filter_data");
      }
      if (!is_path_includes("/support-ticket")) {
        localStorage.removeItem("support_tickets_data");
      }
      if (!is_path_includes("/member")) {
        localStorage.removeItem("member_filter_data");
        localStorage.removeItem("tab_store_value_of_mission_quest");
      }
      if (!is_path_includes("/pending-commissions")) {
        localStorage.removeItem("pending-commissions");
      }
      if (!is_path_includes("/paid-commissions")) {
        localStorage.removeItem("paid-commissions");
      }
      if (!is_path_includes("/consultant/pay-commission")) {
        localStorage.removeItem("delegate_py_commission_filter");
      }
      if (!is_path_includes("/calendar-groups/group-detail")) {
        localStorage.removeItem("calendar_search_text");
      }
      if (!is_path_includes("/done-listing")) {
        localStorage.removeItem("done_member_filter_data");
      }
      if (!is_path_includes("/delegate-answers-list")) {
        localStorage.removeItem("delegate_question_answers_filter_data");
      }
      if (!is_path_includes("/members-answers-list")) {
        localStorage.removeItem("question_answers_filter_data");
      }
      if (!is_path_includes("/consultant")) {
        localStorage.removeItem("delegate_filter_data");
      }
      if (!is_path_includes("/delegate-support-ticket")) {
        localStorage.removeItem("delegate-support-ticket");
      }
      if (!is_path_includes("/self-image-answers")) {
        localStorage.removeItem("questions_answers_search");
      }
      if (!is_path_includes("/fire-payment-transactions")) {
        localStorage.removeItem("fire-payment_transactions_filter");
      }
      if (!is_path_includes("/payment-transactions")) {
        localStorage.removeItem("payment_transactions_filter");
      }
      if (!is_path_includes("/bookings")) {
        localStorage.removeItem("booking_list_filter");
      }
      if (!is_path_includes("/feeds")) {
        localStorage.removeItem("feed_list_data");
      }
      if (!is_path_includes("/reminder-user")) {
        localStorage.removeItem("reminder_user");
      }
      if (!is_path_includes("/payment-request")) {
        localStorage.removeItem("payment_request_data");
        localStorage.removeItem("paymentReq_search_text");
      }
      if (
        !is_path_includes("/membership-level") &&
        !is_path_includes("/membership-level/add-membership-level") &&
        !is_path_includes("/membership-level/add-membership-level/")
      ) {
        localStorage.removeItem("membership_level_page");
        localStorage.removeItem("membership_level_rowsPerPage");
        localStorage.removeItem("membership_level_search_text");
      }
      if (
        !is_path_includes("/membership-level-badges") &&
        !is_path_includes("/membership-level-badges/add-badge") &&
        !is_path_includes("/membership-level-badges/edit-badge/")
      ) {
        localStorage.removeItem("membership_level_badge__page");
        localStorage.removeItem("membership_level_badge__rowsPerPage");
        localStorage.removeItem("membership_level_badge__search_text");
      }
      if (!is_path_includes("/badge-level")) {
        localStorage.removeItem("badge_level_list_searchText");
        localStorage.removeItem("badge_level_list_page");
        localStorage.removeItem("level_badge_list_rowsPerPage");
      }
      if (!is_path_includes("/progress-category")) {
        localStorage.removeItem("Progress_search_text");
      }
      if (!is_path_includes("/payment-template")) {
        localStorage.removeItem("payment_template_data");
        localStorage.removeItem("payment_search_text");
      }
      if (!is_path_includes("/billing-tickets")) {
        localStorage.removeItem("billing_tickets_data");
      }
      if (!is_path_includes("/stripe-products")) {
        localStorage.removeItem("Stripe_product_data");
      }
      if (!is_path_includes("/checked-in")) {
        localStorage.removeItem("checkin_tickets_data");
      }
      if (!is_path_includes("/source-session-recordings")) {
        localStorage.removeItem("source_sessions_data");
      }
      if (!is_path_includes("/session-recording")) {
        localStorage.removeItem("sessions_data");
      }
      if (!is_path_includes("/subscription-list")) {
        localStorage.removeItem("subscription_list_data");
      }
      if (!is_path_includes("/stripe-transactions")) {
        localStorage.removeItem("transactions_list_data");
      }
      if (!is_path_includes("/website-pages")) {
        localStorage.removeItem("general_pages_filter_data");
      }
      if (!is_path_includes("/template-pages")) {
        localStorage.removeItem("template_pages_filter_data");
      }
      if (!is_path_includes("/self-image-responded-answers")) {
        localStorage.removeItem("responded");
      }
      if (!is_path_includes("/self-image-incompleted-answers")) {
        localStorage.removeItem("incompleted");
      }
      if (!is_path_includes("/self-image-completed-answers")) {
        localStorage.removeItem("completed");
      }
      if (!is_path_includes("/affiliate-transactions")) {
        localStorage.removeItem("affiliate_search_text");
      }
      if (!is_path_includes("/goalstament-completed")) {
        localStorage.removeItem("complete");
      }
      if (!is_path_includes("/goalstament-incompleted")) {
        localStorage.removeItem("incomplete");
      }
      if (!is_path_includes("/goalstament-responded")) {
        localStorage.removeItem("responded");
      }
      if (!is_path_includes("/calendar-pods")) {
        localStorage.removeItem("pods_filter_data");
        localStorage.removeItem("pods_search_text");
      }
      if (!is_path_includes("/book-call-pods")) {
        localStorage.removeItem("pods_bookcall_filter_data");
      }
      if (!is_path_includes("/shop-products")) {
        localStorage.removeItem("shop_filter_data");
      }
      if (!is_path_includes("/road-map-levels")) {
        localStorage.removeItem("road_map_levels");
      }
      if (!is_path_includes("/calendar-groups")) {
        localStorage.removeItem("calender_group_searh");
      }
      if (!is_path_includes("/website-pages")) {
        localStorage.removeItem("webpages_page_number");
      }
      if (!is_path_includes("/template-pages")) {
        localStorage.removeItem("template_page_number");
      }
      if (!is_path_includes("/quarters")) {
        localStorage.removeItem("quarter_filter");
      }
      if (!is_path_includes("/mentorship-video")) {
        localStorage.removeItem("mentorship_videos_filter");
      }
      if (!is_path_includes("/daily-messages")) {
        localStorage.removeItem("dynamite_diary_search");
        localStorage.removeItem("meditation_search");
        localStorage.removeItem("the_source_search");
        localStorage.removeItem("dynamite_gratitude_search");
      }
      if (!is_path_includes("/card")) {
        localStorage.removeItem("card_search");
      }
      if (!is_path_includes("/goal-statement/detail-question")) {
        localStorage.removeItem("detail_question_search_text");
      }
      if (!is_path_includes("/self-image-questions/users-detail")) {
        localStorage.removeItem("general_search_text");
      }
      if (!is_path_includes("/consultant")) {
        localStorage.removeItem("delegate");
        localStorage.removeItem("delegatedelegate_filter_data");
      }
      if (!is_path_includes("/delegate")) {
        localStorage.removeItem("consultant");
        localStorage.removeItem("consultantdelegate_filter_data");
      }
      if (!is_path_includes("/marketing")) {
        localStorage.removeItem("marketing");
        localStorage.removeItem("marketingdelegate_filter_data");
      }
      if (!is_path_includes("/inner-circle")) {
        localStorage.removeItem("inner_circle");
        localStorage.removeItem("inner_circledelegate_filter_data");
      }
      if (!is_path_includes("/dynamite-lifestyle-team-members")) {
        localStorage.removeItem("all");
        localStorage.removeItem("alldelegate_filter_data");
      }
      if (!is_path_includes("/deleted-members")) {
        localStorage.removeItem("delete_account_search");
      }
      if (!is_path_includes("/mission")) {
        localStorage.removeItem("mission_levels_search");
      }
      if (!is_path_includes("/mission")) {
        localStorage.removeItem("mission_challenge");
        localStorage.removeItem("mission_challenge_filter_data");
      }
      if (!is_path_includes("/schedules")) {
        localStorage.removeItem("schedule_search");
      }
      if (!is_path_includes("/mission/challenge/schedules/vedios")) {
        localStorage.removeItem("schedule_search");
      }
      if (!is_path_includes("/mission/challenge/schedules")) {
        localStorage.removeItem("mission_schedule");
      }
      if (!is_path_includes("/dynamite-events")) {
        localStorage.removeItem("dynamite_event_search_text");
        localStorage.removeItem("dynamite_filter_data");
      }
      if (!is_path_includes("/short-links")) {
        localStorage.removeItem("short_link_search_text");
      }
      if (!is_path_includes("/engagements/")) {
        localStorage.removeItem("enegagements_filter");
      }
      if (!is_path_includes("/sub-team")) {
        sessionStorage.removeItem("tabsale");
      }
      if (!is_path_includes("/template-configuration")) {
        localStorage.removeItem("template_search");
      }
      if (!is_path_includes("/analytics-overview")) {
        localStorage.removeItem("analytics_filter_overview");
        localStorage.removeItem("search_text_report");
      }
      if (!is_path_includes("/analytics-event/event-detail")) {
        localStorage.removeItem("analytics_filter_detail");
        localStorage.removeItem("search_text_detail");
      }
      if (!is_path_includes("/analytics-event")) {
        localStorage.removeItem("search_text_event");
      }
      if (!is_path_includes("/analytics-country")) {
        localStorage.removeItem("analytics_filter_country");
        localStorage.removeItem("search_text_country");
      }
      if (!is_path_includes("/funnel-exploration")) {
        localStorage.removeItem("funnel_step_search");
      }
      if (!is_path_includes("/analytics-country")) {
        localStorage.removeItem("analytics_filter_country");
        localStorage.removeItem("search_text_country");
      }
      if (!is_path_includes("/analytics-report")) {
        localStorage.removeItem("funnal_filter");
      }
      if (
        !is_path_includes(
          "/calendar-events/event-list" && "/calendar-events/event-detail"
        )
      ) {
        localStorage.removeItem("event_list_search");
      }
      if (
        !is_path_includes("/meeting-content") &&
        !is_path_includes("/meeting-content/add-meeting-content") &&
        !is_path_includes("/meeting-content/edit-meeting-content/")
      ) {
        localStorage.removeItem("meeting_content_page_number");
        localStorage.removeItem("meeting_content_rowsPerPage");
        localStorage.removeItem("meeting_content_search_text");
      }
      if (!is_path_includes("/attitude-assessment-questions")) {
        localStorage.removeItem("assessment_filter_data_questions");
      }
      if (!is_path_includes("/self-image-responded-answers")) {
        localStorage.removeItem("responded_filter_data");
      }

      if (!is_path_includes("/history") && !is_path_includes("/member")) {
        localStorage.removeItem("updates_filter_data");
        localStorage.removeItem("updates_vertical_tab_value");
      }
      if (
        !is_path_includes("/missions-members/") &&
        !is_path_includes("/member")
      ) {
        localStorage.removeItem("mission_members");
      }
    }, 1000);
  }, [pathname]);

  return (
    <>
      <QRCodeDetail
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Billing Ticket Detail"}
        data={scanDetail}
      />
      <ChangePassword
        openPasswordModal={openPasswordModal}
        setopenPasswordModal={setopenPasswordModal}
      />
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <Avatar
          src={
            localStorage.getItem("image") == ""
              ? account.photoURL
              : profileNewImage && s3baseUrl + profileNewImage
          }
          alt="photoURL"
        />
      </IconButton>
      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {localStorage.getItem("name")}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {localStorage.getItem("email")}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            onClick={() => handleProfile(option.linkTo)}
            sx={{ typography: "body2", py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />

            {option.label}
          </MenuItem>
        ))}
        <MenuItem
          key=""
          onClick={() => {
            handleClose();
            setopenPasswordModal(true);
          }}
          sx={{ typography: "body2", py: 1, px: 2.5 }}
        >
          <Box
            component={Icon}
            icon={unlockFill}
            sx={{
              mr: 2,
              width: 24,
              height: 24,
              color: get_root_value("--portal-theme-primary"),
            }}
          />
          Change Password
        </MenuItem>

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={() => handleLogout()}
          >
            Logout
          </Button>
        </Box>
      </MenuPopover>
      <ConfirmPassword
        open={openConfirm}
        setOpen={setOpenConfirm}
        selected={selectedValue}
        setSelected={setSelectedValue}
        handleAgree={admin_logut}
        isLoading={isLoading}
      />
    </>
  );
}
