import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import {
  DeleteRecording,
  RecordingListingApi,
} from "src/DAL/Recording/RecordingsApi";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import moment from "moment";
import { s3baseUrl } from "src/config/config";
import PageLoader from "src/components/GeneralComponents/PageLoader";

export default function RecordingsList() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [recordingData, setRecordingData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [deleteDoc, setDeleteDoc] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const getRecordingListing = async () => {
    setIsLoading(true);
    const result = await RecordingListingApi(page, rowsPerPage, searchText);
    if (result.code == 200) {
      const recordings = result?.recording?.map((recording, index) => {
        return {
          ...recording,
          program_title: recording.program?.title,
          date: recording.recording_date
            ? moment(recording.recording_date).format("DD-MM-YYYY")
            : "N/A",
          table_avatar: {
            src: s3baseUrl + recording.recording_image.thumbnail_2,
            alt: recording.title,
          },
        };
      });

      setRecordingData(recordings);
      setTotalCount(result.total_recording_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getRecordingListing();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (value) => {
    navigate(`/recording-list/edit-recording/${value.recording_slug}`, {
      state: value,
    });
  };
  const handleDetail = (value) => {
    navigate(`/recording-list/recording-detail/${value.recording_slug}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await DeleteRecording(deleteDoc.recording_slug);
    if (result.code === 200) {
      setRecordingData((prev) => {
        return prev.filter(
          (data) => data.recording_slug !== deleteDoc.recording_slug
        );
      });
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "Detail",
      icon: "akar-icons:eye-open",
      handleClick: handleDetail,
    },
  ];

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", alignRight: false, type: "action" },
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "table_avatar",
      label: "Image",
      alignRight: false,
      type: "thumbnail",
    },
    {
      id: "title",
      label: " Name",
      className: "pointer",
      handleClick: handleDetail,
    },
    { id: "program_title", label: "Programme", alignRight: false },
    { id: "date", label: "Date", alignRight: false },
    { id: "status", label: "Status", alignRight: false, type: "row_status" },
  ];

  useEffect(() => {
    getRecordingListing();
  }, [rowsPerPage, page]);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 mb-4">
            <h2>Programme Recordings</h2>
          </div>
          <div className="col-6 mb-4">
            <button
              className="small-contained-button float-end mt-1"
              onClick={() => navigate(`/recording-list/add-recording`)}
            >
              Add New Recording
            </button>
          </div>
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          MENU_OPTIONS={MENU_OPTIONS}
          data={recordingData}
          className="card-with-background"
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
      </div>
    </>
  );
}
