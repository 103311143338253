import React, { useEffect, useState } from "react";
import { Chip } from "@mui/material";
import { useSnackbar } from "notistack";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import moment from "moment";
import { member_goal_statement_list_api } from "src/DAL/MemberGoalStatement/MemberGoalStatement";
import { Icon } from "@iconify/react";
import arrowCircleDownFill from "@iconify/icons-eva/arrow-circle-down-fill";
import { download_csv_file } from "src/utils/convertHtml";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import GeneralRequestForCsv from "../MembersList/GeneralRequestForCsv";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const headers = [
  { label: "User", key: "user_name" },
  { label: "Nurture", key: "nurture_name" },
  { label: "Completed Date", key: "completed_date" },
  { label: "Goal Statement Status", key: "goal_statement_text" },
];

export default function MemberGoalStatement({ type, date_type }) {
  const { enqueueSnackbar } = useSnackbar();
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isLoading, setIsLoading] = useState(true);
  const [membersData, setMembersData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [filterQuery, setFilterQuery] = useState({});
  const [showExportcsvFile, setShowExportcsvFile] = useState(false);
  const showPopUPcsv = () => {
    setShowExportcsvFile(true);
  };
  let member_options = [
    {
      label: "First name",
      value: "first_name",
      is_checked: true,
      is_disabled: true,
    },
    {
      label: "Last name",
      value: "last_name",
      is_checked: true,
      is_disabled: true,
    },
    { label: "Email", value: "email", is_checked: true, is_disabled: true },
    {
      label: "Contact Number",
      value: "contact_number",
      is_checked: true,
      is_disabled: true,
    },
    {
      label: "Nurture",
      value: "nurture",
      is_checked: true,
      is_disabled: true,
    },
    {
      label: date_type,
      value: type + "_date",
      is_checked: true,
      is_disabled: true,
    },
  ];
  if (type === "incomplete") {
    member_options.pop();
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPageCount(1);
  };
  const getGoalStatementListing = async (search_text) => {
    setIsLoading(true);
    let postData = search_text ? search_text : "";

    const result = await member_goal_statement_list_api(
      page,
      rowsPerPage,
      type,
      postData
    );
    if (result.code == 200) {
      const members = result?.member_array?.map((member, index) => {
        let nurture_name = "N/A";
        if (member.nurture?.first_name) {
          nurture_name =
            member.nurture?.first_name +
            " " +
            member.nurture?.last_name +
            " (" +
            member.nurture?.email +
            ")";
        }

        return {
          ...member,
          goal_statement_text: member.goal_statement_status ? "Unlock" : "Lock",
          user_name:
            member?.first_name +
            " " +
            member?.last_name +
            " (" +
            member?.email +
            ")",
          nurture_name: nurture_name,
          user_email: member?.email,
          incompleted_date:
            member.goal_statement_incompleted_date == "Invalid date" ||
            member.goal_statement_incompleted_date == ""
              ? "N/A"
              : moment(member.goal_statement_incompleted_date).format(
                  "DD-MM-YYYY"
                ),
          completed_date:
            member.goal_statement_completed_date == "Invalid date" ||
            member.goal_statement_completed_date == ""
              ? "N/A"
              : moment(member.goal_statement_completed_date).format(
                  "DD-MM-YYYY"
                ),
          save_and_close_date_: member.save_and_close_date
            ? moment(member.save_and_close_date).format("DD-MM-YYYY")
            : "",

          table_avatar: {
            src: s3baseUrl + member?.profile_image,
            alt: member.first_name,
          },
        };
      });
      setFilterQuery(result.query);
      setMembersData(members);
      setTotalCount(result.toal_count);
      setTotalPages(result.total_page);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getGoalStatementListing(searchText);
    localStorage.setItem(type, searchText);
  };

  const handleExportClick = () => {
    download_csv_file(headers, membersData);
  };

  useEffect(() => {
    let find_search = localStorage.getItem(type);
    if (find_search) {
      setSearchText(find_search);
    } else {
      setSearchText("");
    }
    getGoalStatementListing(find_search);
  }, [rowsPerPage, page, type]);

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "table_avatar",
      label: "User Profile",
      type: "thumbnail",
    },
    { id: "user_name", label: "User" },
    {
      id: "nurture_name",
      label: "Nurture",
    },
    {
      id: "goal_statement_status",
      label: "Goal Statement Status",
      renderData: (row) => {
        return (
          <Chip
            width="140px"
            label={row.goal_statement_status === true ? "Unlock" : "Lock"}
            variant="contained"
            color={row.goal_statement_status === true ? "success" : "error"}
            size="small"
          />
        );
      },
    },
  ];

  if (type == "complete") {
    TABLE_HEAD.splice(4, 0, {
      id: "completed_date",
      label: "Completed Date",
      calignRight: false,
    });
  } else if (type == "incomplete") {
    TABLE_HEAD.splice(4, 0, {
      id: "incompleted_date",
      label: "Incompleted Date",
      calignRight: false,
    });
  } else if (type == "responded") {
    TABLE_HEAD.splice(4, 0, {
      id: "save_and_close_date_",
      label: "Responded Date",
      calignRight: false,
    });
  }

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8 col-sm-8 mb-3">
            <h2>Member Goal Statements ( {type} )</h2>
          </div>
          <div className="col-4 text-end">
            <button
              className="small-contained-button me-2 mt-1 mb-4"
              onClick={showPopUPcsv}
            >
              Request For Csv &nbsp;&nbsp; <Icon icon={arrowCircleDownFill} />
            </button>
          </div>
        </div>

        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={membersData}
          className="card-with-background"
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />

        <GeneralPopUpModel
          open={showExportcsvFile}
          setOpen={setShowExportcsvFile}
          title={"Export CSV file request  "}
          componentToPassDown={
            <GeneralRequestForCsv
              options_array={member_options}
              collection_name={"goal_statement_" + type}
              filter_data={filterQuery}
              setShowExportcsvFile={setShowExportcsvFile}
            />
          }
        />
      </div>
    </>
  );
}
