import React from "react";
import CustomPopoverSection from "../MenuOption/CustomPopoverSection";
import {
  DeleteMemberApi,
  member_login_api,
  reset_assement_api,
} from "src/DAL/member/member";
import CustomConfirmation from "../ModalPopover/Confirmation";
import { useNavigate } from "react-router-dom";
import { clientPortalUrl } from "src/config/config";
import { useSnackbar } from "notistack";
import GeneralPopUpModel from "../GeneralComponents/GeneralPopUpModel";
import ChangeLeadStatus from "src/pages/MembersList/ChangeLeadStatus";
import { useState } from "react";
import ChangePassword from "./ChangePassword";
import ChangeAffiliateID from "src/pages/MembersList/ProfileTabs/ChangeAffiliateID";

export default function MemberMenuOptions(props) {
  const {
    memberDetail,
    setIsLoading,
    handleUpdateStatus,
    handleUpdateAffiliate,
    onMemberDelete,
    list_type,
  } = props;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [changeStatus, setChangeStatus] = useState(false);
  const [openResetAssessment, setOpenResetAssessment] = useState(false);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const [changeAffiliateOpen, setChangeAffiliateOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const done_member = window.location.pathname.includes("/done-listing");
  let route_name = "/member/";
  if (done_member) {
    route_name = "/done-listing/";
  }

  const LoginAsMember = async () => {
    setIsLoading(true);
    const data = {
      email: memberDetail.email,
      user_type: "client",
    };
    const result = await member_login_api(data);
    if (result.code === 200) {
      window.open(`${clientPortalUrl}login-by-admin/${result.token}`);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleResetAssement = async () => {
    setOpenResetAssessment(false);
    const result = await reset_assement_api(memberDetail._id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await DeleteMemberApi(memberDetail._id);
    if (result.code === 200) {
      onMemberDelete?.(memberDetail);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleManageMembershiplevel = (value) => {
    navigate(`${route_name}manage-membership-levels/${value._id}`, {
      state: value,
    });
  };

  const handleQuestionsAnswers = (value) => {
    navigate(`${route_name}questions-answers?member_id=${value._id}`, {
      state: value,
    });
  };

  const handleMemberHistory = (value) => {
    navigate(`${route_name}member-history/${value._id}`, {
      state: value,
    });
  };

  const handleStripeSubscriptions = (value) => {
    navigate(`${route_name}stripe-subscriptions`, {
      state: value,
    });
  };

  const handleStripeTransactions = (value) => {
    navigate(`${route_name}stripe-transactions`, {
      state: value,
    });
  };

  const handleEdit = (value) => {
    navigate(`${route_name}edit-member/${value._id}`, {
      state: value,
    });
  };

  const handleChangeAccess = (value) => {
    navigate(`${route_name}programme-access/${value._id}`, {
      state: value,
    });
  };

  const handleQuestAccess = (value) => {
    navigate(`${route_name}quest-access/${value._id}`, {
      state: value,
    });
  };
  const handleMissionAccess = (value) => {
    navigate(`${route_name}mission-access/${value._id}`, {
      state: value,
    });
  };

  const handleBonusContentAccess = (value) => {
    navigate(`${route_name}bonus-content-access/${value._id}`, {
      state: value,
    });
  };

  const ManageNavItemsAccess = (value) => {
    navigate(`${route_name}manage-navitems-access/${value._id}`, {
      state: value,
    });
  };

  const handleDiary = (value) => {
    navigate(`${route_name}member-diary/${value._id}`, {
      state: value,
    });
  };

  const handleChangeEvents = (value) => {
    navigate(`${route_name}events/${value._id}`, {
      state: value,
    });
  };

  const handleChangePods = (value) => {
    navigate(`${route_name}pods/${value._id}`, {
      state: value,
    });
  };

  const handleMemberSubscribers = (value) => {
    navigate(`${route_name}member-subscribers/${value._id}`, {
      state: value,
    });
  };

  const handleChangeAllTransaction = (value) => {
    navigate(`${route_name}all-transaction/${value._id}`, {
      state: value,
    });
  };

  const handleChangeBilling = (value) => {
    navigate(`${route_name}view-billing-list/${value._id}`, {
      state: value,
    });
  };

  const handleNotesNavigate = (value) => {
    navigate(`${route_name}member-personal-notes/${value._id}`, {
      state: value,
    });
  };

  const handleEventAccess = (value) => {
    navigate(`${route_name}event-access/${value._id}`, {
      state: value,
    });
  };

  const handleNotificationSchedule = (value) => {
    navigate(`${route_name}notification-schedule/${value._id}`, {
      state: value,
    });
  };

  const handleProfile = (value) => {
    navigate(`${route_name}member-profile/${value._id}`, {
      state: value,
    });
  };

  const change_lead_status = (value) => {
    setChangeStatus(true);
  };

  const handleChangePasswordOpen = (value) => {
    setChangePasswordOpen(true);
  };

  const handleChangeAffiliateOpen = (value) => {
    setChangeAffiliateOpen(true);
  };

  const handleAgreeResetAssessment = (value) => {
    setOpenResetAssessment(true);
  };

  const handleCopyReferralId = async (e) => {
    await navigator.clipboard.writeText(e.affiliate_url_name);
    enqueueSnackbar("Referral Id Copied to clipboard", { variant: "success" });
  };

  const handleAgreeDelete = (value) => {
    setOpenDelete(true);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit Profile",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Login As Member",
      icon: "ant-design:lock",
      handleClick: LoginAsMember,
    },
    {
      label: "Manage Programme Access",
      icon: "ant-design:key",
      handleClick: handleChangeAccess,
    },
    {
      label: "Manage Quest Access",
      icon: "ant-design:key",
      handleClick: handleQuestAccess,
    },
    {
      label: "Manage Mission Access",
      icon: "ant-design:key",
      handleClick: handleMissionAccess,
    },
    {
      label: "Manage Bonus Content Access",
      icon: "ant-design:key",
      handleClick: handleBonusContentAccess,
    },
    {
      label: "Manage Dynamite Event Access",
      icon: "ant-design:key",
      handleClick: handleEventAccess,
    },
    {
      label: "Manage Nav Items Access",
      icon: "fluent:video-recording-20-regular",
      handleClick: ManageNavItemsAccess,
    },
    {
      label: "View Diary",
      icon: "eva:eye-fill",
      handleClick: handleDiary,
    },
    {
      label: "View Events",
      icon: "fluent:video-recording-20-regular",
      handleClick: handleChangeEvents,
    },
    {
      label: "View Pods",
      icon: "fluent:video-recording-20-regular",
      handleClick: handleChangePods,
    },
    {
      label: "View Subscriptions",
      icon: "eva:eye-fill",
      handleClick: handleMemberSubscribers,
    },
    {
      label: "All Transactions",
      icon: "icon-park-outline:transaction-order",
      handleClick: handleChangeAllTransaction,
    },
    {
      label: "View Billings",
      icon: "icon-park-outline:transaction-order",
      handleClick: handleChangeBilling,
    },
    {
      label: "Client Notes",
      icon: "icon-park-outline:transaction-order",
      handleClick: handleNotesNavigate,
    },
    {
      label: "Manage Membership level",
      icon: "akar-icons:edit",
      handleClick: handleManageMembershiplevel,
    },
    {
      label: "Change Lead Status",
      icon: "akar-icons:edit",
      handleClick: change_lead_status,
    },
    {
      label: "Change Password",
      icon: "ant-design:lock",
      handleClick: handleChangePasswordOpen,
    },
    {
      label: "Questions Answers",
      icon: "ant-design:lock",
      handleClick: handleQuestionsAnswers,
    },
    {
      label: "Change Affiliate ID",
      icon: "ant-design:lock",
      handleClick: handleChangeAffiliateOpen,
    },
    {
      label: "Member History",
      icon: "eva:eye-fill",
      handleClick: handleMemberHistory,
    },
    {
      label: "Reset Attitude Assessment",
      icon: "ant-design:lock",
      handleClick: handleAgreeResetAssessment,
    },
    {
      label: "Stripe Subscriptions",
      icon: "ant-design:lock",
      handleClick: handleStripeSubscriptions,
    },
    {
      label: "Stripe Transactions",
      icon: "ant-design:lock",
      handleClick: handleStripeTransactions,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "Notification Schedule",
      icon: "ant-design:lock",
      handleClick: handleNotificationSchedule,
    },
  ];

  if (memberDetail.affiliate_url_name) {
    MENU_OPTIONS.push({
      label: "Copy Referral ID",
      icon: "eva:eye-fill",
      handleClick: handleCopyReferralId,
    });
  }

  if (list_type === "members_list") {
    MENU_OPTIONS.unshift({
      label: "View Profile",
      icon: "iconoir:profile-circled",
      handleClick: handleProfile,
    });
  }

  return (
    <>
      <CustomPopoverSection menu={MENU_OPTIONS} data={memberDetail} />
      <CustomConfirmation
        open={openResetAssessment}
        setOpen={setOpenResetAssessment}
        title={"Are you sure you want to reset Assessment History?"}
        handleAgree={handleResetAssement}
      />
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this member?"}
        handleAgree={handleDelete}
      />
      <GeneralPopUpModel
        open={changeStatus}
        setOpen={setChangeStatus}
        title={"Change Lead Status"}
        componentToPassDown={
          <ChangeLeadStatus
            selectedObject={memberDetail}
            handleUpdateStatus={handleUpdateStatus}
            setOpen={setChangeStatus}
          />
        }
      />
      <GeneralPopUpModel
        open={changePasswordOpen}
        setOpen={setChangePasswordOpen}
        title={"Change Password"}
        componentToPassDown={
          <ChangePassword
            selectedObject={memberDetail}
            setOpen={setChangePasswordOpen}
          />
        }
      />

      <GeneralPopUpModel
        open={changeAffiliateOpen}
        setOpen={setChangeAffiliateOpen}
        title={"Change Affiliate ID"}
        componentToPassDown={
          <ChangeAffiliateID
            selectedObject={memberDetail}
            memberDetailInfo={handleUpdateAffiliate}
            setOpen={setChangeAffiliateOpen}
          />
        }
      />
    </>
  );
}
