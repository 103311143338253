import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Typography, Paper } from "@mui/material";
import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { UPdate_mission_scadule_list_list } from "src/DAL/MissionApi/MissionApiNew";
import { dynamite } from "src/assets";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import PageLoader from "src/components/GeneralComponents/PageLoader";

const Input = styled("input")({
  display: "none",
});

export default function ManageScadualeActions() {
  const navigate = useNavigate();
  const params = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [scheduleDetails, setScheduleDetails] = useState({
    mission_Title: "",
    title: "",
  });
  const [inputList, setInputList] = useState([
    // {
    //   action_statement: "",
    //   second_action_statement: "",
    //   is_required: false,
    //   action_type: "general",
    //   tool: "dynamite_dairy",
    // },
  ]);
  const handleAddClick = (index) => {
    setInputList([
      ...inputList.slice(0, index),
      {
        action_statement: "",
        second_action_statement: "",
        is_required: false,
        action_type: "general",
        tool: "dynamite_dairy",
      },
      ...inputList.slice(index),
    ]);
  };
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    console.log(name, value, index, "namevalue");

    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let postData = {
      allowed_actions: inputList,
      mission_schedule_id: params.id,
    };
    console.log("postData  __postData", postData);
    const result = await UPdate_mission_scadule_list_list(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  let breadCrumbMenu = [
    {
      title: `Mission (${scheduleDetails?.mission_Title})`,
      navigation: `/mission`,
      active: false,
    },
    {
      title: `(${scheduleDetails?.title})`,
      navigation: `/mission/challenge/schedules/${params?.mission_id}`,
      active: false,
    },
    {
      title: "Mission Schedule Actions",
      navigation: ``,
      active: true,
    },
  ];

  useEffect(() => {
    if (params && params.id) {
      if (state) {
        setScheduleDetails({
          mission_Title: state?.mission_info?.title,
          title: state.title,
        });
        setInputList(
          state.allowed_actions.length > 0
            ? state.allowed_actions.map((item) => {
                return {
                  ...item,
                  tool: item.tool || "dynamite_dairy",
                  action_type: item.action_type || "general",
                };
              })
            : [
                // {
                //   action_statement: "",
                //   second_action_statement: "",
                //   is_required: false,
                //   action_type: "general",
                //   tool: "dynamite_dairy",
                // },
              ]
        );
      }
    }
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-lg-8 col-sm-12">
          {scheduleDetails.title && (
            <div className="col-12 mb-4 ms-2">
              <span>
                <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
              </span>
            </div>
          )}
        </div>
        <div className="col-lg-4 col-sm-12 text-end">
          {inputList.length == 0 && (
            <>
              <button
                onClick={() => handleAddClick(1)}
                className="small-contained-button"
              >
                Add Action
              </button>
            </>
          )}
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row notification-statement">
          <div className="Container mt-3">
            {inputList.map((item, index) => {
              const findedGrothTool = inputList.find(
                (item) => item.action_type === "growth_tool"
              );
              return (
                <div className="setting-card mb-2 " key={index}>
                  <div className="row  ">
                    <div className="col-11">
                      <div className="row">
                        <div className="col-12">
                          <TextField
                            id="outlined-basic"
                            label="Action Statement"
                            variant="outlined"
                            fullWidth
                            required
                            type="text"
                            name="action_statement"
                            value={item.action_statement}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                        </div>{" "}
                        <div className="col-6 mt-3">
                          <FormControl fullWidth required>
                            <InputLabel id="demo-simple-select-label">
                              Is Required?
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name="is_required"
                              value={item.is_required}
                              label=" Is Required?"
                              onChange={(e) => handleInputChange(e, index)}
                            >
                              <MenuItem value={true}>Yes</MenuItem>
                              <MenuItem value={false}>No</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <div className="col-6 mt-3 ps-1">
                          <FormControl fullWidth required>
                            <InputLabel id="demo-simple-select-label">
                              Action Type
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name="action_type"
                              value={item.action_type}
                              label="Action Type"
                              onChange={(e) => handleInputChange(e, index)}
                            >
                              <MenuItem value={"general"}>General</MenuItem>
                              {(!findedGrothTool ||
                                item.action_type === "growth_tool") && (
                                <MenuItem value={"growth_tool"}>
                                  Growth Tool
                                </MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </div>
                        {item.action_type === "growth_tool" && (
                          <>
                            <div className="col-6 mt-3">
                              <FormControl fullWidth required>
                                <InputLabel id="demo-simple-select-label">
                                  Growth Tool
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  name="tool"
                                  value={item.tool}
                                  label="Growth Tool"
                                  onChange={(e) => handleInputChange(e, index)}
                                >
                                  <MenuItem value={"dynamite_dairy"}>
                                    Dynamite Dairy
                                  </MenuItem>
                                  {/* <MenuItem value={"gratitude_daily"}>
                                  Gratitude Daily
                                </MenuItem> */}
                                </Select>
                              </FormControl>
                            </div>

                            <div className="col-6 mt-3">
                              <TextField
                                id="outlined-basic"
                                label="Gratitude Action Statement"
                                variant="outlined"
                                fullWidth
                                required
                                type="text"
                                name="second_action_statement"
                                value={item?.second_action_statement}
                                onChange={(e) => handleInputChange(e, index)}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-1">
                      <AddCircleOutlineIcon
                        className="question-survey-update"
                        onClick={() => handleAddClick(index + 1)}
                      />
                      {/* {inputList.length !== 1 && ( */}
                      <RemoveCircleOutlineIcon
                        className="question-survey-cross-update"
                        onClick={() => handleRemoveClick(index)}
                      />
                      {/* )} */}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {inputList.length == 0 && (
            <div className="container ">
              <Paper className="py-5">
                <Typography variant="h5" align="center">
                  No Data Exist
                </Typography>
              </Paper>
            </div>
          )}

          <div className="text-end mt-4" id="fixedbutton">
            <button className="small-contained-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
}
